import React from 'react'
import axios from 'axios'
import { get } from 'lodash'
import { baseUrl } from '~/services/api'
import { processAxiosResultForForm } from '~/utils/errorMessages'
import useClientId from './useClientId'
import type { AttrErrors, EntityErrors } from '~/form-brain2'

interface UseSignUp {
  resetState: () => void
  onSignUp: (email: string) => void
  isProcessing: boolean
  successEmail?: string
  errors?: AttrErrors
  token?: string
}

interface UseSignUpState {
  isProcessing: boolean
  token?: string
  signUpErrors?: EntityErrors
  successEmail?: string
}

const
  useSignUp = (userType: string): UseSignUp => {
    const
      { clientId } = useClientId(),
      [state, setState] = React.useState<UseSignUpState>({ isProcessing: false }),
      { isProcessing, signUpErrors, token, successEmail } = state,
      emailErrors = (signUpErrors?.base ?? signUpErrors?.email) as AttrErrors,
      resetState = (): void => {
        setState({ isProcessing: false })
      },
      onSignUp = (email: string): void => {
        setState({ isProcessing: true })

        processAxiosResultForForm(axios({
          method: 'post',
          url: `${baseUrl}users`,
          data: {
            email,
            clientId,
            isTeacher: userType === 'teacher',
            testmode: email.includes('example.com')
          }
        }))
          .then(({ data }) => {
            setState({
              isProcessing: false,
              token: get(data, 'meta.mailInfo.token') as string | undefined,
              successEmail: get(data, 'meta.email') as string | undefined
            })
          })
          .catch(({ errors }) => {
            setState({
              isProcessing: false,
              signUpErrors: errors
            })
          })
      }

    return {
      onSignUp,
      isProcessing,
      resetState,
      successEmail,
      errors: emailErrors,
      token
    }
  }

export default useSignUp
