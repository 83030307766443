import { flatten, mapValues, sortBy, uniq } from 'lodash'
import { isAllEmpty, isEmptyValue, isNumeric } from '~/utils/testers'
import { floatString, symbolSafeStartCase } from '~/utils/formatters'
import { stringifyValue } from '~/components/molecules/SavePoint/helpers'
import { getCalculatedValue } from '~/features/experiment/calculatedValues'
import { getEntitiesAcrossResponses } from '~/features/worksheetResponse/helpers'
import {
  experimentFormResolvers,
  ExperimentHelpers,
  formBodyForSpecialSection,
  getDataPoints,
  getGroupedValues,
  getSummaryStats,
  getValueAcrossEntities,
  interpolateStringInForm,
  SystemFieldNames
} from '~/features'
import { getRowsForBodyElement } from './getRowsForBodyElement'
import { FormRenderer } from '~/components/molecules'
import { getEntrySummaries, getHeadingForEntry } from '~/components/organisms/ListStep/helpers'
import { anovaTestIntro, tTestIntro } from '~/components/organisms/SpecialContentArea/StatsTestSummary'

import type { ContextAndResolvers, EntityObject } from '~/form-brain2'
import type { PageElement, PagedWorksheet, Experiment, ListWorksheet, ListSectionElement, SpecialListSectionElement, ListEntry, OpeningSectionElement, ClosingSectionElement } from '~/features'
import type { SavePointDataSection, SavePointFileFormat, SavePointRow, SavePointValue } from '~/components/molecules/SavePoint'
import type { DownloadableContentItem } from './getDownloadOptions'

type GetValuesFn = (item: DownloadableContentItem, format: SavePointFileFormat, experiment: Experiment) => SavePointDataSection[]

const
  missingStepSection = {
    data: [['Missing step']]
  },
  missingResponseSection = {
    data: [['No response found for this step']]
  },
  emptyResponseSection = {
    data: [['Step was left blank']]
  },
  baseContextAndResolvers: ContextAndResolvers = {
    ...experimentFormResolvers,
    values: {},
    schema: {},
    formOptions: { RootFormRenderer: FormRenderer },
    initialValues: {},
    scope: '',
    formStatus: 'clean',
    errors: {},
    result: {},
    showingAllErrors: false,
    showingAllMissing: false
  },
  getFallbackValues: GetValuesFn = (item, format, experiment) => {
    return []
  },
  getPageNotesValues: GetValuesFn = (item, format, experiment) => {
    const
      response = item.response,
      worksheet = item.worksheet as PagedWorksheet

    if (!worksheet) { return [missingStepSection] }
    if (!response) { return [missingResponseSection] }

    const
      sections: SavePointDataSection[] = [],
      pageContextAndResolvers = {
        ...baseContextAndResolvers,
        values: response.responses as EntityObject,
        initialValues: response.responses as EntityObject,
        formOptions: {
          ...baseContextAndResolvers.formOptions,
          dynamicLabels: experiment.dynamicLabels,
          otherEntities: getEntitiesAcrossResponses(item.responses ?? [response])
        }
      }

    worksheet.sections.forEach(section => {
      const
        { title } = section,
        pages: PageElement[] = section.pages,
        rows: SavePointRow[] = []

      pages.forEach(page => {
        const
          { heading, body } = page,
          contextAndResolvers: ContextAndResolvers = {
            ...pageContextAndResolvers,
            schema: ExperimentHelpers.getSchema(experiment, page.body)
          },
          interpolatedHeading = typeof heading === 'string'
            ? interpolateStringInForm(heading, contextAndResolvers)
            : null,
          pageRows: SavePointRow[] = []

        body.forEach(bodyElement => {
          const elementRows = getRowsForBodyElement(bodyElement, contextAndResolvers, format)

          if (!isAllEmpty(elementRows)) { pageRows.push(...elementRows) }
        })

        if (interpolatedHeading && !isAllEmpty(pageRows)) { rows.push({ h3: interpolatedHeading }) }

        rows.push(...pageRows)
      })

      if (isAllEmpty(rows)) {
        // skip
      } else {
        sections.push({
          _section: title,
          data: rows
        })
      }
    })

    if (sections.length === 1) {
      sections[0]._section = undefined
    }

    if (isAllEmpty(sections)) { return [emptyResponseSection] }

    return sections
  },
  getListNotesValues: GetValuesFn = (item, format, experiment) => {
    const
      response = item.response,
      worksheet = item.worksheet as ListWorksheet | undefined

    if (!worksheet) { return [missingStepSection] }
    if (!response) { return [missingResponseSection] }

    const
      // worksheetSectionsById = getSectionsById(worksheet),
      entrySummaries = getEntrySummaries({ entries: item.entries ?? [], worksheet, currentSelection: undefined }),
      otherEntities = getEntitiesAcrossResponses(item.responses ?? [response]),
      resultSections: SavePointDataSection[] = []

    entrySummaries.forEach(entrySummary => {
      const
        section = entrySummary.section as ListSectionElement,
        header = getHeadingForEntry({ ...entrySummary, otherEntities, experiment }),
        body = (section as SpecialListSectionElement)?.specialSectionId
          ? formBodyForSpecialSection(section as SpecialListSectionElement)
          : section?.body,
        entryRows: SavePointRow[] = [],
        contextAndResolvers = {
          ...baseContextAndResolvers,
          values: entrySummary.entry as EntityObject,
          initialValues: entrySummary.entry as EntityObject,
          schema: ExperimentHelpers.getSchema(experiment, body),
          formOptions: {
            ...baseContextAndResolvers.formOptions,
            dynamicLabels: experiment.dynamicLabels,
            otherEntities
          }
        }

      body.forEach(bodyElement => {
        const elementRows = getRowsForBodyElement(bodyElement, contextAndResolvers, format)

        if (!isAllEmpty(elementRows)) { entryRows.push(...elementRows) }
      })

      if (isAllEmpty(entryRows)) {
        // skip
      } else {
        resultSections.push({
          _section: header,
          data: entryRows
        })
      }
    })

    if (isAllEmpty(resultSections)) { return [emptyResponseSection] }

    return resultSections
  },
  getChecklistValues: GetValuesFn = (item, format, experiment) => {
    const
      response = item.response,
      worksheet = item.worksheet as ListWorksheet | undefined

    if (!worksheet) { return [missingStepSection] }
    if (!response) { return [missingResponseSection] }

    const
      entrySummaries = getEntrySummaries({ entries: item.entries ?? [], worksheet, currentSelection: undefined }),
      otherEntities = getEntitiesAcrossResponses(item.responses ?? [response]),
      checklistRows: SavePointRow[] = [],
      headers: Array<[string, SavePointValue]> = [
        ['checked', 'Done?']
      ]

    entrySummaries.forEach(entrySummary => {
      const
        section = entrySummary.section as ListSectionElement,
        body = (section as SpecialListSectionElement)?.specialSectionId
          ? formBodyForSpecialSection(section as SpecialListSectionElement)
          : section?.body,
        contextAndResolvers = {
          ...baseContextAndResolvers,
          values: entrySummary.entry as EntityObject,
          initialValues: entrySummary.entry as EntityObject,
          schema: ExperimentHelpers.getSchema(experiment, body),
          formOptions: {
            ...baseContextAndResolvers.formOptions,
            dynamicLabels: experiment.dynamicLabels,
            otherEntities
          }
        },
        checkedValue = entrySummary.entry?.checked ? 'Yes' : '',
        valueObject: SavePointRow = {
          checked: checkedValue
        }

      let valueStringArray = [checkedValue]

      /* Currently checklists are available only as special content so
        the developer controls what uses the checklist format - the below assume
        that all entries are the same section type */
      body.forEach((bodyElement, i) => {
        const
          elementRow = getRowsForBodyElement(bodyElement, contextAndResolvers, format)[0],
          key = i + 1,
          label = typeof elementRow === 'object' && !Array.isArray(elementRow) ? elementRow?.label : undefined,
          content = Array.isArray(elementRow)
            ? elementRow.join('\n')
            : typeof elementRow === 'object'
              ? [stringifyValue(elementRow.value, 'docx'), stringifyValue(elementRow.unit, 'docx')].filter(o => !isEmptyValue(o)).join(' ')
              : elementRow

        if (!isEmptyValue(content)) {
          if (format === 'csv') {
            headers[key] = [key.toString(), label ?? '']
            valueObject[key.toString()] = content
          } else {
            valueStringArray = i === 0 ? [valueStringArray[0] + ' ' + content] : [...valueStringArray, ...content.split('\n').map(s => '\u0009' + s)]
          }
        }
      })

      if (isAllEmpty(valueObject)) {
        // skip
      } else if (format === 'csv') {
        checklistRows.push(valueObject)
      } else {
        checklistRows.push(valueStringArray)
      }
    })

    if (isAllEmpty(checklistRows)) { return [emptyResponseSection] }

    return [{
      data: checklistRows,
      headers
    }]
  },
  getDataValues: GetValuesFn = (item, format, experiment) => {
    if (format !== 'csv') { return [] } /* This should never happen */

    const
      response = item.response,
      worksheet = item.worksheet as ListWorksheet | undefined

    if (!worksheet) { return [missingStepSection] }
    if (!response) { return [missingResponseSection] }

    const
      entrySummaries = getEntrySummaries({ entries: item.entries ?? [], worksheet, currentSelection: undefined }),
      otherEntities = getEntitiesAcrossResponses(item.responses ?? [response]),
      rows: SavePointRow[] = [],
      headers: Array<[string, SavePointValue]> = []

    entrySummaries.forEach(entrySummary => {
      const
        section = entrySummary.section as ListSectionElement,
        body = (section as SpecialListSectionElement)?.specialSectionId
          ? formBodyForSpecialSection(section as SpecialListSectionElement)
          : section?.body,
        contextAndResolvers = {
          ...baseContextAndResolvers,
          values: entrySummary.entry as EntityObject,
          initialValues: entrySummary.entry as EntityObject,
          schema: ExperimentHelpers.getSchema(experiment, body),
          formOptions: {
            ...baseContextAndResolvers.formOptions,
            dynamicLabels: experiment.dynamicLabels,
            otherEntities
          }
        },
        valueObject: SavePointRow = {}

      /* Currently this format is only used for special content so the developer
        controls what uses the this format - the below assume that all entries are
        the same section type and every body element is an input with a fieldName */
      body.forEach((bodyElement, i) => {
        const elementRow = getRowsForBodyElement(bodyElement, contextAndResolvers, format)[0]

        if (!elementRow) { return }

        const
          value = Array.isArray(elementRow)
            ? elementRow.join(' ')
            : elementRow.value,
          key = contextAndResolvers.resolveKey({ bodyElement, contextAndResolvers }),
          baseLabel = typeof elementRow === 'object' && !Array.isArray(elementRow) ? elementRow?.label : undefined,
          label = !Array.isArray(elementRow) && elementRow.unit
            ? [stringifyValue(baseLabel ?? '', 'docx'), '(' + stringifyValue(elementRow.unit, 'docx') + ')'].filter(o => !isEmptyValue(o)).join(' ')
            : baseLabel

        headers[i] = [key, label ?? '']
        valueObject[key] = value
      })

      if (isAllEmpty(valueObject)) {
        // skip
      } else {
        rows.push(valueObject)
      }
    })

    if (isAllEmpty(rows)) { return [emptyResponseSection] }

    return [{
      data: rows,
      headers
    }]
  },
  getAnalysisValues: GetValuesFn = (item, format, experiment) => {
    const
      response = item.response,
      worksheet = item.worksheet as ListWorksheet | undefined

    if (!worksheet) { return [missingStepSection] }
    if (!response) { return [missingResponseSection] }

    const
      entrySummaries = getEntrySummaries({ entries: item.entries ?? [], worksheet, currentSelection: undefined }),
      otherEntities = getEntitiesAcrossResponses(item.responses ?? [response]),
      resultSections: SavePointDataSection[] = []

    entrySummaries.forEach(entrySummary => {
      if (!entrySummary.section || !entrySummary.entry) { /* skip */ }

      const
        entry = entrySummary.entry as ListEntry,
        section = entrySummary.section as SpecialListSectionElement,
        specialSectionId = section.specialSectionId

      if (specialSectionId === 'SUMMARY_STATS') {
        const
          { dataPoints, omittedMeasurements, subjectsById } = getDataPoints(otherEntities, experiment),
          treatmentKeys = uniq(sortBy(Object.values(subjectsById), 'treatmentIndex').map((o) => o.treatment)),
          includedStats = section.includedSummaryStats ?? [],
          outlierSubjectIds = (entrySummary.entry?.outlierSubjectIds ?? []) as string[],
          measurementUnit = (getValueAcrossEntities(SystemFieldNames.measurementUnit, otherEntities) ?? '') as string,
          { valueSetsByTreatment } = getGroupedValues(dataPoints, experiment, otherEntities, subjectsById, outlierSubjectIds),
          summaryStats = mapValues(valueSetsByTreatment, (vs) => getSummaryStats(vs, includedStats)),
          allValueSummaryStats = getSummaryStats(flatten(Object.values(valueSetsByTreatment)), includedStats),
          title = symbolSafeStartCase(section.entryNameSingular ?? (section as unknown as OpeningSectionElement | ClosingSectionElement).title),
          subjectNotes = (entry.subjectNotes as Record<string, string> | undefined) ?? {},
          rows = [],
          headers: Array<[string, SavePointValue]> = [
            ['treatment', 'Treatment'],
            ...(includedStats.map(statName => [statName, statName.match(/^[^a-z]+$/) ? statName : symbolSafeStartCase(statName)]) as Array<[string, SavePointValue]>),
            ['notes', section.detailsLabel ?? 'Notes']
          ]

        if (!dataPoints.length) {
          resultSections.push({
            _section: title,
            specialSectionId,
            data: [['No Data']]
          })
          return
        }

        rows.push({
          treatment: 'All Treatments',
          ...includedStats.reduce((memo, statName) => {
            return { ...memo, [statName]: `${allValueSummaryStats[statName]} ${measurementUnit}` }
          }, {})
        })

        treatmentKeys.forEach(treatmentName => {
          rows.push({
            treatment: treatmentName,
            notes: subjectNotes[treatmentName] ?? '',
            ...includedStats.reduce((memo, statName) => {
              return {
                ...memo,
                [statName]: summaryStats[treatmentName]?.[statName]
                  ? `${summaryStats[treatmentName][statName]} ${measurementUnit}`
                  : ''
              }
            }, {})
          })
        })

        if (omittedMeasurements.length) {
          rows.push([`${omittedMeasurements.length} measurements were unable to be processed and were omitted.`])
        }

        if (outlierSubjectIds.length) {
          rows.push([`${outlierSubjectIds.length} value${outlierSubjectIds.length > 1 ? 's' : ''} marked as ${outlierSubjectIds.length > 1 ? 'outliers have' : 'an outlier has'} been omitted from this analysis.`])
        }

        resultSections.push({
          _section: title,
          data: rows,
          headers,
          specialSectionId
        })
      }

      if (specialSectionId === 'STATS_TEST') {
        const
          title = getHeadingForEntry({ ...entrySummary, experiment, otherEntities }),
          { testType, groupA, groupB } = entrySummary.entry as ListEntry,
          dependentVariableText = getCalculatedValue('dependentVariableResultDescription', otherEntities) ?? 'the dependent variable'

        if (testType === 't-test') {
          const
            { dof, meanA, meanB, tValue, pValue, alpha } = entry,
            groupALabel = (groupA as string | undefined) ?? 'First Group',
            groupBLabel = (groupB as string | undefined) ?? 'Second Group',
            hasTestResult = isNumeric(pValue),
            isSignificant = hasTestResult && isNumeric(tValue) && isNumeric(alpha)
              ? (pValue as number) < (alpha as number)
              : undefined

          if (!hasTestResult) { return /* skip */ }

          resultSections.push({
            _section: title,
            specialSectionId,
            data: [
              [tTestIntro],
              [`The base assumption (null hypothesis) is that the difference between ${dependentVariableText} for **${groupALabel}** and for **${groupBLabel}** is **0**.`],
              [`You are testing for the possibility (alternative hypothesis) that the difference between ${dependentVariableText} for **${groupALabel}** and for **${groupBLabel}** is **greater or less than 0**`],
              { result: `The difference in ${dependentVariableText} between treatments **${groupALabel}** (Mean = ${floatString(meanA as number, { places: 2 }) as string}) and **${groupBLabel}** (Mean = ${floatString(meanB as number, { places: 2 }) as string})) was **${isSignificant ? 'significant' : 'not significant'}** (t(${dof as number}) = ${floatString(tValue as number) as string}; p = ${floatString(pValue as number) as string}, α = ${floatString(alpha as number) as string}).` }
            ]
          })
        }

        if (testType === 'ANOVA') {
          const
            { dfw, dfb, fValue, pValue, alpha } = entry,
            hasTestResult = isNumeric(pValue),
            isSignificant = hasTestResult && isNumeric(fValue) && isNumeric(alpha)
              ? (pValue as number) < (alpha as number)
              : undefined

          if (!hasTestResult) { return /* skip */ }

          resultSections.push({
            _section: title,
            specialSectionId,
            data: [
              [anovaTestIntro],
              [`The base assumption (null hypothesis) is that the mean ${dependentVariableText} for the groups are **all equal**.`],
              ['You are testing for the possibility (alternative hypothesis) that the mean of **at least one of the groups is different** from the others in a statistically significant way'],
              { result: `There ${isSignificant ? 'is' : 'are'} **${isSignificant ? 'at least one statistically significant difference' : 'no statistically significant differences'}** in ${dependentVariableText} between treatments. (f(${dfb as number},${dfw as number}) = ${floatString(fValue as number) as string}; p = ${floatString(pValue as number) as string}, α = ${floatString(alpha as number) as string}).` }
            ]
          })
        }
      }

      /* Else skip */
    })

    if (isAllEmpty(resultSections)) { return [emptyResponseSection] }

    return resultSections
  }

export { getFallbackValues, getPageNotesValues, getListNotesValues, getChecklistValues, getDataValues, getAnalysisValues }
