import React from 'react'

const useFocusOnToggle = (toggleableVal: boolean): React.MutableRefObject<any> => {
  const
    focusElementRef = React.useRef<HTMLElement | null>(null),
    prevValRef = React.useRef(toggleableVal)

  React.useEffect(() => {
    if (toggleableVal !== prevValRef.current && focusElementRef.current) {
      focusElementRef.current.focus()
    }
    prevValRef.current = toggleableVal
  }, [toggleableVal])

  return focusElementRef
}

export default useFocusOnToggle
