import * as React from 'react'
import styled from 'styled-components'
import { SubheadingTwo, HintText } from '~/components/atoms'

interface BlurbProps {
  content: React.ReactNode
  icon?: React.ReactNode
  heading?: string
  withBackground?: boolean
  className?: string
}

interface BlurbOuterProps {
  $withBackground?: boolean
}

const
  BlurbOuter = styled.div<BlurbOuterProps>`
    display: flex;
    flex-direction: row;
    min-height: 5.9em;

    gap: ${p => p.theme.emBaseSpacing * 1.25}em;
    padding: ${p => p.theme.emSmallSpacing}em ${p => p.theme.emSmallSpacing * 1.5}em ${p => p.theme.emSmallSpacing * 1.5}em ${p => p.theme.emSmallSpacing}em;
    background: ${p => p.$withBackground ? p.theme.colors.containerAccentBg : p.theme.colors.attentionText}
  `,
  TextWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    
    * {
      line-height: 1.4em;
    }
  `,
  IconWrapper = styled.div`
    flex: 0 0 ${p => p.theme.pxIconLarge}px;
  `,
  Content = styled(HintText)`
    text-align: left;
    font-size: 1.1rem;
    white-space: pre-line;
  `,
  Heading = styled(SubheadingTwo)`
    margin-top: 2px;
  `,
  Blurb: React.FC<BlurbProps> = ({ content, icon, heading, withBackground, className }) => {
    return <BlurbOuter $withBackground={withBackground} className={className} data-cy="blurb">
      <IconWrapper>{icon}</IconWrapper>
      <TextWrapper>
       {heading ? <Heading>{heading}</Heading> : null}
        <Content color={'default'}>{content}</Content>
      </TextWrapper>
    </BlurbOuter>
  }

export default Blurb
