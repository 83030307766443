import { forEach, mapValues, sortBy } from 'lodash'
import { isAllEmpty } from '~/utils/testers'
import { checkConditions } from '~/utils/conditions'
import { getEntitiesAcrossResponses } from '~/features/worksheetResponse/helpers'
import { interpolateString } from '~/features/experiment/calculatedValues'
import { getSetUpDefault, getScheduleDefault, scheduleSortFn } from '~/features/experiment/specialSections'

import type { EmptyObject } from '~/types/utilities'
import type { ListQuestionnaireDefault } from '~/features/questionnaire/types'
import type { EntityObject, FormOptions } from '~/form-brain2'
import type { ListWorksheet } from '~/features/worksheet/types'
import type { ListEntry, WorksheetResponse } from '~/features/worksheetResponse/types'

export const
  getDefaultEntriesForWorksheet = ({ worksheet, responses }: { worksheet: ListWorksheet, responses: WorksheetResponse[] }): ListEntry[] => {
    const
      otherEntities = getEntitiesAcrossResponses(responses),
      fauxFormContext = { formOptions: { otherEntities } as unknown as FormOptions, values: {} },
      firstSectionId = worksheet.repeatingSections[0].id,
      specifiedDefaults = (worksheet.defaults ?? []).filter(o => checkConditions({ contextAndResolvers: fauxFormContext, conditions: o.visibleWhen, fallback: true })),
      result: ListEntry[] = []

    forEach(specifiedDefaults, (obj) => {
      const
        automaticContent = getListSpecificValues(obj, worksheet, otherEntities),
        customContent = getCustomDefaultContent(obj, worksheet, otherEntities),
        content = Array.isArray(automaticContent)
          ? automaticContent.map(o => ({
            ...o,
            ...customContent
          }))
          : [{
              ...automaticContent,
              ...customContent
            }]

      if (!isAllEmpty(content)) {
        result.push(...content.map(o => ({
          sectionId: obj.sectionId ?? firstSectionId,
          ...o
        })))
      }
    })

    return worksheet.specialLayoutType === 'SCHEDULE'
      ? sortBy(result, scheduleSortFn) as ListEntry[]
      : result
  }

const
  getCustomDefaultContent = (obj: ListQuestionnaireDefault, worksheet: ListWorksheet, otherEntities: EntityObject[]): Partial<ListEntry> | EmptyObject => {
    const
      { entryValues } = obj,
      content = mapValues(entryValues, v => interpolateString(v, otherEntities))

    return content
  },
  getListSpecificValues = (obj: ListQuestionnaireDefault, worksheet: ListWorksheet, otherEntities: EntityObject[]): Partial<ListEntry> | Array<Partial<ListEntry>> | EmptyObject => {
    // const { id, text, label, details, timing, day } = obj

    if (worksheet.specialLayoutType === 'SET_UP') {
      return getSetUpDefault(obj, worksheet, otherEntities)
    }

    if (worksheet.specialLayoutType === 'SCHEDULE') {
      return getScheduleDefault(obj, worksheet, otherEntities)
    }

    return {}
  }
