import * as React from 'react'
import styled from 'styled-components'
import { Button, type ButtonProps } from '~/components/atoms'
import DialogueContainer from '~/components/molecules/DialogueContainer'
import ModalOverlay from '~/components/molecules/ModalOverlay'
import ErrorMessage from '~/components/molecules/ErrorMessage'

interface Props {
  onConfirm: (e: any) => void
  confirmationBody: string
  buttonProps: Omit<ButtonProps, 'onClick'>
  cancelLabel: string
  confirmLabel: string
  subheading?: string
  errorMessage?: string
  hasSucceeded?: boolean
}

const
  StyledDialogueContainer = styled(DialogueContainer)`
    width: ${p => p.theme.emMediumContentWidth}em;
    maxWidth: 100%;
  `,
  ButtonWithConfirm: React.FC<Props> = ({ onConfirm, confirmationBody, buttonProps, cancelLabel, confirmLabel, subheading, errorMessage, hasSucceeded }) => {
    const
      [isConfirming, setIsConfirming] = React.useState(false),
      prevHasSucceeded = React.useRef(hasSucceeded),
      isDisabled = !!buttonProps.isDisabled || !!buttonProps.isLoading,
      confirmModal = <ModalOverlay wholePage>
        <StyledDialogueContainer
          subheading={subheading}
          heading="Are you sure?"
          body={
            errorMessage
              ? <ErrorMessage>{errorMessage}</ErrorMessage>
              : confirmationBody
          }
          footerContent={
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Button
                label={cancelLabel}
                isDisabled={buttonProps.isLoading}
                onClick={() => { setIsConfirming(false) }}
                color="back"
                size="medium"
              />
              <Button
                label={confirmLabel}
                isLoading={buttonProps.isLoading}
                onClick={onConfirm}
                color="caution"
                size="medium"
              />
            </div>
          }
        />
      </ModalOverlay>

    React.useEffect(() => {
      if (hasSucceeded && !prevHasSucceeded.current) {
        setIsConfirming(false)
      }

      prevHasSucceeded.current = hasSucceeded
    }, [hasSucceeded, setIsConfirming])

    return <>
      <Button
        onClick={
          isDisabled
            ? undefined
            : () => { setIsConfirming(true) }
        }
        {...(buttonProps ?? {})}
      />
      {isConfirming ? confirmModal : null}
    </>
  }

export default ButtonWithConfirm
