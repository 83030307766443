import * as React from 'react'
import { sortBy, values } from 'lodash'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { TemplateSetHelpers } from '~/features'
import { useAppSelector, useEntityData } from '~/hooks'
import { selectTemplateSets } from '~/features/entity/selectors'
import {
  EmptyContentPlaceholder,
  ErrorMessage,
  HeadingWithAdder
} from '~/components/molecules'
import {
  SizedLoadingIcon,
  ListItem,
  ListItemCell,
  HintText
} from '~/components/atoms'
import { isAllEmpty } from '~/utils/testers'
import { RelativeWrapper } from '../ListStep/StackingSections'
import NewTemplateSetForm from './NewTemplateSetForm'

import type { TemplateSet } from '~/features'

const
  NameCell = styled(ListItemCell)`
    flex: 1 0 auto;
  `,
  TemplateSetList: React.FC = () => {
    const
      navigate = useNavigate(),
      { dataStatus, isLoading } = useEntityData('lazy'),
      [isAdding, setIsAdding] = React.useState(false),
      templateSetsById = useAppSelector(selectTemplateSets),
      templateSets = sortBy(
        values(templateSetsById).filter(o => !o.systemTemplate),
        (o: TemplateSet) => [o.archivedAt ? 3 : o.publishedAt ? 0 : 1, o.title]
      )

    if (dataStatus === 'initializing' || dataStatus === 'uninitialized') {
      return <SizedLoadingIcon />
    }

    if (dataStatus === 'error') {
      return <ErrorMessage>
        There was a problem retrieving template data. Please try again later and contact support if the problem persists.
      </ErrorMessage>
    }

    if (isLoading) { // TODO do we need to check for empty here?
      return <SizedLoadingIcon />
    }

    return <>
      {isAdding
        ? <NewTemplateSetForm
            onCancel={() => { setIsAdding(false) }}
          />
        : <HeadingWithAdder
          heading="Template Sets"
          pointerLabel="Add a new template set"
          onClick={() => {
            setIsAdding(true)
          }}
        />}
      {isAllEmpty(templateSets)
        ? <RelativeWrapper>
          <EmptyContentPlaceholder colorKey="bodyBg">
            <span>There are no templates.</span>
            <br />
            <span>Use the plus button, above, to add a template.</span>
          </EmptyContentPlaceholder>
        </RelativeWrapper>
        : templateSets.map(templateSet => (
          <ListItem
            $clickable
            $understate={!!templateSet.archivedAt}
            $emphasize={!!templateSet.publishedAt && !templateSet.archivedAt}
            key={templateSet.id}
            data-cy="template-set-list-item"
            title={`Click to go to settings for ${templateSet.title}`}
            onClick={() => { navigate(`/template-sets/${templateSet.id}`) }}
            >
            <NameCell>
              {templateSet.title}
            </NameCell>
            <ListItemCell>
              <HintText color='default'>
                {TemplateSetHelpers.getTemplateStatusString(templateSet)}
              </HintText>
            </ListItemCell>
          </ListItem>
        ))}
    </>
  }

export default TemplateSetList
