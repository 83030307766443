import * as React from 'react'
import styled from 'styled-components'

export type DashedLineOrientation = 'horizontal' | 'vertical'

interface DashedLineProps {
  thickness?: number
  spacing?: number
  orientation?: DashedLineOrientation
  color?: string
  className?: string
}

const
  DashedLineOuter = styled.svg<{ $thickness: number, $isHorizontal: boolean }>`
    height: ${p => p.$isHorizontal ? `${p.$thickness}px` : '100%'};
    width: ${p => p.$isHorizontal ? '100%' : `${p.$thickness}px`};
  `,
  DashedLine: React.FC<DashedLineProps> = ({ className, thickness, spacing, orientation = 'horizontal', color = 'black' }) => {
    const
      isHorizontal = orientation === 'horizontal',
      targetThickness = thickness ?? (isHorizontal ? 1.5 : 3), // 1.5 | 1
      targetSpacing = spacing ?? (isHorizontal ? 4 : 6), // 4 | 2
      remainder = targetThickness - Math.floor(targetThickness), // 0.5 | 0
      distanceToInteger = remainder > 0
        ? 1 - remainder
        : 0, // 0.5 | 0
      requiredSpacing = distanceToInteger / 2, // 0.25 | 0
      multiplier = requiredSpacing !== 0 // 4 | 1
        ? 1 / requiredSpacing
        : 1,
      strokeWidth = targetThickness * multiplier, // 6 | 1
      verticalOffset = (strokeWidth / 2) + (requiredSpacing * multiplier), // 6 or 4 | 0.5
      patternThickness = verticalOffset * 2, // 12 or 8 | 1
      patternSpacing = targetSpacing * multiplier, // 16 | 2
      patternLength = patternSpacing * 2 * multiplier, // 128 | 4

      finalLength = patternLength / multiplier, // 32 | 4
      finalThickness = patternThickness / multiplier, // 3 | 1

      lineCoordinates = isHorizontal
        ? [{ x: 0, y: verticalOffset }, { x: patternLength, y: verticalOffset }]
        : [{ x: verticalOffset, y: 0 }, { x: verticalOffset, y: patternLength }],
      viewBox = isHorizontal
        ? `0 0 ${patternLength} ${patternThickness}`
        : `0 0 ${patternThickness} ${patternLength}`

    return (
      <DashedLineOuter
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-cy="dashed-line"
        $thickness={finalThickness}
        $isHorizontal={isHorizontal}>
        <rect
          fill={`url(#dashed-hr-${color}-${targetThickness}-${targetSpacing}-${orientation})`}
          x="0"
          y="0"
          height={isHorizontal ? finalThickness : '100%'}
          width={isHorizontal ? '100%' : finalThickness }/>
        <defs>
          <pattern
            id={`dashed-hr-${color}-${targetThickness}-${targetSpacing}-${orientation}`}
            viewBox={viewBox}
            width={isHorizontal ? finalLength : finalThickness}
            height={isHorizontal ? finalThickness : finalLength}
            patternUnits="userSpaceOnUse">
            <line
              stroke={color}
              x1={lineCoordinates[0].x}
              y1={lineCoordinates[0].y}
              x2={lineCoordinates[1].x}
              y2={lineCoordinates[1].y}
              strokeWidth={strokeWidth}
              strokeDasharray={`${patternSpacing} ${patternSpacing}`}
            />
          </pattern>
        </defs>
      </DashedLineOuter>
    )
  }

export default DashedLine
