import React from 'react'
import styled from 'styled-components'
import * as Sentry from '@sentry/react'
import { ErrorMessage } from '~/components/molecules'

const
  StyledErrorMessage = styled(ErrorMessage)`
    [data-cy="alert-text"] {
      max-width: ${p => p.theme.emMediumContentWidth}em;

      p {
        margin-bottom: ${p => p.theme.emSmallSpacing}em;
      }
    }
  `,
  PreviewFallbackRenderer: React.FC<{ error: Error }> = ({ error /*, resetErrorBoundary */ }) => {
    Sentry.captureException(error)

    return (
      <StyledErrorMessage>
        <p>There was a problem generating this preview.</p>
        <p>The error has been automatically reported. Try changing the content or removing the page or section, then contact support if the problem persists.</p>
        {error.message
          ? <p>Error: <i>{error.message}</i></p>
          : null}
      </StyledErrorMessage>
    )
  }

export default PreviewFallbackRenderer
