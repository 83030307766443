import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import { FERTILIZER_TIMING_REFERENCES } from './fertilizerTimingReference'

import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Fertilizer timing (value)',
  description = 'The number of days, weeks, etc after which fertilizer will be applied (counting from the start of the selected period).',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.fertilizerTimingValue,
    fieldType: 'NUMBER',
    visibleWhen: [
      {
        valueName: SystemFieldNames.fertilizerTimingReference,
        conditionType: 'present',
        conditionScope: 'ownScope'
      },
      {
        valueName: SystemFieldNames.fertilizerTimingReference,
        conditionType: 'noneOf',
        testValue: [
          FERTILIZER_TIMING_REFERENCES.AT_PLANTING,
          FERTILIZER_TIMING_REFERENCES.AT_GERMINATION
        ],
        conditionScope: 'ownScope'
      }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.fertilizerTimingValue,
    variableGroup: SystemFieldGroups.CONTROL_VARIABLES,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
