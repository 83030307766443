import type { SpecialListSectionElement, AnyPlainOrInputElement } from '~/features/questionnaire/types'

export const
  getMaterialsListItemForm = (section: SpecialListSectionElement): AnyPlainOrInputElement[] => [
    {
      id: 'material-1',
      elementVariety: 'SHORT_ANSWER',
      fieldName: 'description',
      label: section.whatLabel ?? 'What?',
      requiredWhen: [{ conditionType: 'always' }]
    },
    {
      id: 'material-2',
      elementVariety: 'LONG_ANSWER',
      fieldName: 'details',
      label: section.detailsLabel ?? 'Notes:'
    }
  ]
