import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Irrigation timing (value)',
  description = 'This is a numeric value indicating how often a crop will be irrigated or checked for the need to irrigate. When this is on its own, it will be applied to all subjects. When part of a treatment, it will be applied to subjects receiving that treatment.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.irrigationTimingValue,
    fieldType: 'NUMBER'
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.irrigationTimingValue,
    variableGroup: SystemFieldGroups.CONTROL_VARIABLES,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
