import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { HintText } from '~/components/atoms'
import { useClickOnKeyPress } from '~/hooks'

interface InfoTileProps {
  label: string
  accessibleDescription: string
  content: string | React.ReactNode
  isOpen: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
  icon?: React.ReactNode
  tabAlignment?: TabAlignment
  className?: string
}

interface InfoTileChildProps {
  $tabAlignment?: TabAlignment
  $isOpen: boolean
}

type TabAlignment = 'left' | 'right' | 'center'

const
  InfoTileOuter = styled.div`
    position: relative;
    height: 30px;
    width: 135px;

    padding-top: 6px;
  `,
  TileTab = styled.div<InfoTileChildProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    height: 24px;
    width: 100%;
    z-index: ${p => p.theme.zLocalTop};

    border-radius: ${p => p.theme.pxBorderRadius}px;
    box-shadow: ${p => !p.$isOpen ? '1.18016481399536135px 1.5735530853271485px 3.933882713317871px 0px #00000026' : 'none'};

    background: ${p => p.theme.colors.containerAsideBg};

    &:hover {
      cursor: pointer;
    }
  `,
  Text = styled.div`
    flex: 1 0 auto;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;

    font-size: 0.75rem;
    text-transform: uppercase;
    ${p => p.theme.headingFont}
    color: ${p => p.theme.colors.back}
  `,
  Icon = styled.div`
    flex: 0 0 26px;
    align-self: flex-start;

    > * {
      margin-top: -6px;
      margin-left: -2px;
    }
  `,
  InfoPopup = styled.div<InfoTileChildProps>`
    position: absolute;
    width: 300px;
    top: 0px;
    left: ${p => p.$tabAlignment === 'left' ? '0px' : p.$tabAlignment === 'center' ? '-82.5px' : '-165px'};
  `,
  BridgePanel = styled.div<InfoTileChildProps>`
    position: relative;
    height: 29px;
    width: 135px;
    margin-top: 11px;
    margin-left: ${p => p.$tabAlignment === 'left'
      ? '0px'
      : p.$tabAlignment === 'center'
        ? '82.5px'
        : '165px'};
    margin-right: ${p => p.$tabAlignment === 'left'
      ? 'auto'
      : p.$tabAlignment === 'center'
        ? '82.5px'
        : '0px'};

    background: ${p => p.theme.colors.containerAsideBg};
  `,
  leftJoinPath = 'M9.5 14V0.5C9.01234 8.96682 6.22856 13.0431 0.5 14H9.5Z', // "M9.5 14V0.5C7.833 8.07587 5.0758 12.1726 0.5 14H9.5Z",
  LeftJoin = styled.svg`
    position: absolute;
    bottom: 0;
    left: -9px;
    width: 10px;
  `,
  rightJoinPath = 'M0.5 14V0.5C0.987662 8.96682 3.77144 13.0431 9.5 14H0.5Z', // "M0.5 14V0.5C2.167 8.07587 4.9242 12.1726 9.5 14H0.5Z",
  RightJoin = styled.svg`
    position: absolute;
    bottom: 0;
    right: -9px;
    width: 10px;
  `,
  PopupBody = styled.div<InfoTileChildProps>`
    width: 100%;

    border-radius: ${p => p.theme.pxBorderRadius}px;
    border-top-left-radius: ${p => p.$tabAlignment === 'left' ? 0 : p.theme.pxBorderRadius}px;
    border-top-right-radius: ${p => p.$tabAlignment === 'right' ? 0 : p.theme.pxBorderRadius}px;
    padding: 9px 12px;

    background: ${p => p.theme.colors.containerAsideBg};
    box-shadow: 1px 1.5px 4px 0px ${p => p.theme.colors.shadow};

    cursor: pointer;
  `,
  InfoTile: React.FC<InfoTileProps> = ({ label, accessibleDescription, content, isOpen, onClick, icon, tabAlignment, className }) => {
    const
      THEME = useTheme(),
      clickableRef = useClickOnKeyPress()

    return <InfoTileOuter aria-label={accessibleDescription} className={className}>
      <TileTab onClick={onClick} ref={clickableRef} tabIndex={0} $isOpen={isOpen}>
        <Text>{label}</Text>
        {icon ? <Icon>{icon}</Icon> : null}
      </TileTab>
      {isOpen
        ? <InfoPopup $tabAlignment={tabAlignment} $isOpen={isOpen}>
          <BridgePanel $tabAlignment={tabAlignment} $isOpen={isOpen}>
            {tabAlignment !== 'left'
              ? <LeftJoin viewBox="0 0 10 14"><path d={leftJoinPath} fill={THEME.colors.containerAsideBg} /></LeftJoin>
              : null}
            {tabAlignment !== 'right'
              ? <RightJoin viewBox="0 0 10 14"><path d={rightJoinPath} fill={THEME.colors.containerAsideBg} /></RightJoin>
              : null}
          </BridgePanel>
          <PopupBody onClick={onClick} $tabAlignment={tabAlignment} $isOpen={isOpen}>
            {typeof content === 'string'
              ? <HintText color="default">
                {content}
              </HintText>
              : content}
          </PopupBody>
        </InfoPopup>
        : null}
    </InfoTileOuter>
  }

export default InfoTile
