import * as React from 'react'
import { isAllEmpty } from '~/utils/testers'
import FauxCheckbox from '~/components/atoms/FauxCheckbox'
import FormFieldWrapper from '~/components/molecules/FormElements/FormFieldWrapper'
import type { InputProps } from '~/types/forms'
import { inputIdFromBodyElement } from '~/utils/formatters'
import type { CheckboxInput } from '~/features'

const
  Checkbox: React.FC<InputProps & { className?: string }> = (inputProps) => {
    const
      { name, currentValue, updateAttr, errors, showError, isReadOnly, isDisabled, className, pathToAttr } = inputProps,
      isInoperable = !!isReadOnly || !!isDisabled,
      inputId = inputIdFromBodyElement(inputProps.bodyElement as CheckboxInput, pathToAttr),
      onChange: React.ChangeEventHandler<HTMLInputElement> = e => { if (!isInoperable) { updateAttr(e.currentTarget.checked) } },
      hasError = showError && !isAllEmpty(errors),
      wrapperInputProps = {
        ...inputProps,
        bodyElement: { ...inputProps.bodyElement, inlineLabel: true }
      }

    return <FormFieldWrapper className={className} inputProps={wrapperInputProps} inputId={inputId} noWrapper>
      <FauxCheckbox
        name={name}
        id={inputId}
        onChange={onChange}
        disabled={isDisabled}
        readOnly={isReadOnly}
        defaultChecked={!!currentValue}
        $hasError={hasError}
      />
    </FormFieldWrapper>
  }

export default Checkbox
