import React from 'react'
import { useDispatch } from 'react-redux'
import { baseUrl } from '~/services/api'
import { entitiesRemoved } from '~/features/entity/entitySlice'
import useSession from './useSession'

import type { EntityErrors, ResultObject } from '~/form-brain2'
import type { EntityType } from '~/features/entity/entitySlice'
import type { ObjectId } from '~/types/utilities'
import { apiPathLookup } from './useAddEntity'

interface UseDeleteEntity {
  deleteEntity: ({ type, id }: { type: EntityType, id: ObjectId }) => Promise<ResultObject>
  isDeleting?: boolean
  isSuccess?: boolean
  errors?: EntityErrors
}

interface UseDeleteEntityState {
  isDeleting?: boolean
  isSuccess?: boolean
  errors?: EntityErrors
}

const
  useDeleteEntity = (): UseDeleteEntity => {
    const
      dispatch = useDispatch(),
      { activeToken, makeRequestWithSession } = useSession(),
      [state, setState] = React.useState<UseDeleteEntityState>({}),
      { isDeleting, isSuccess, errors } = state,
      deleteEntity: UseDeleteEntity['deleteEntity'] = ({ type, id }) => {
        setState({ isDeleting: true })
        const path = `${apiPathLookup[type]}/${id}`

        return makeRequestWithSession({
          method: 'delete',
          url: `${baseUrl}${path}`,
          headers: {
            Authorization: `Bearer ${activeToken as string}`
          }
        })
          .then((params) => {
            dispatch(entitiesRemoved([{ type, id }]))
            setState({ isSuccess: true })

            return params
          })
          .catch((params) => {
            setState({ errors: params.errors })
            return Promise.reject(params)
          })
      }

    return {
      isDeleting,
      isSuccess,
      errors,
      deleteEntity
    }
  }

export { apiPathLookup }
export default useDeleteEntity
