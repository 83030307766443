import React from 'react'
import styled from 'styled-components'
import { isEmptyValue, isAllEmpty } from '~/utils/testers'
import { useLogIn } from '~/hooks'
import { Button, ErrorText, Link } from '~/components/atoms'
import { FormRenderer, TextInput } from '~/components/molecules'

import type { InputProps } from '~/types/forms'
import type { TextInput as TextInputType } from '~/features'
import { uniq } from 'lodash'

const
  LogInWrapper = styled.div`
    padding: ${p => p.theme.emBaseSpacing * 2}em ${p => p.theme.emSmallSpacing}em;
  `,
  Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 10px;
  `,
  StyledButton = styled(Button)`
    margin-top: 3em;
  `,
  StyledTextInput = styled(TextInput)<{ $hasErrors?: boolean }>`
    width: unset;

    input {
      min-width: 200px;
      ${p => p.$hasErrors ? '' : `border-color: ${p.theme.colors.borderAlt};`}
    }
  `,
  SuccessMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items; center;
    text-align: center;
    margin-top: ${p => p.theme.emBaseSpacing}em;

    p {
      margin-bottom: ${p => p.theme.emBaseSpacing}em;
    }

    .request-account-text {
      margin-top: ${p => p.theme.emBaseSpacing}em;
    }
  `,
  Small = styled.div`
    margin: 0;
    font-size: 0.85rem;
  `,
  formOptions = { RootFormRenderer: FormRenderer },
  emailInputClassName = '_log-in-email-input',
  inputBodyElement: TextInputType = {
    id: 'teacherEmail',
    fieldName: 'email',
    elementVariety: 'TEXT_INPUT',
    hideErrorMessage: true
  },
  TeacherLogInForm: React.FC<{ pageError?: string }> = ({ pageError: initialPageError }) => {
    const
      [email, setEmail] = React.useState<string>(''),
      [pageError, setPageError] = React.useState<string | undefined>(initialPageError),
      { onLogIn, resetState, isLoggingIn, errors, token, successEmail } = useLogIn(),
      hasErrors = !isAllEmpty(errors) || !isAllEmpty(pageError),
      onChange: InputProps['updateAttr'] = (val) => {
        resetState(); setPageError(undefined)
        setEmail(val as string)
      },
      handleKeyPress = React.useCallback((e: KeyboardEvent) => {
        if (document.activeElement?.closest(`.${emailInputClassName}`) && email?.length && e && e.key === 'Enter') {
          onLogIn(email)
        }
      }, [onLogIn, email]),
      onClick: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        onLogIn(email)
      },
      requestAccountText = <p className="request-account-text">
        You can <Link to="/request-account">request an account</Link> if you do not have one
      </p>

    React.useEffect(() => {
      document.addEventListener('keyup', handleKeyPress)

      return () => {
        document.removeEventListener('keyup', handleKeyPress)
      }
    }, [handleKeyPress])

    return <LogInWrapper>
      <Row>
        <StyledTextInput
          $hasErrors={!isEmptyValue(errors)}
          name="email"
          pathToAttr="email"
          currentValue={email}
          errors={errors}
          updateAttr={onChange}
          bodyElement={inputBodyElement}
          formOptions={formOptions}
          isRequired
          isReadOnly={isLoggingIn}
          isDirty={!isEmptyValue(email)}
          showLabel
          showError
          label="Email:"
          className={emailInputClassName}
          placeholder="Enter your email"
        />
        <StyledButton
          label="Log In With Email"
          color="back"
          size="small"
          isLoading={isLoggingIn}
          onClick={onClick}
        />
      </Row>
      {token
        ? <p>
            The requested email belongs to a test account. You can log in to the test account with <Link to={`/access/${token}`}>this link</Link>
          </p>
        : successEmail
          ? <SuccessMessage>
              <p>An email with a log in link will have been sent to <br/><strong>{successEmail}</strong> <br/> if there is an account under that email.</p>
              <Small>Trouble finding the message? Check your spam folder.</Small>
              <Small>Typo? Enter the correct email above and try again.</Small>
              {requestAccountText}
            </SuccessMessage>
          : hasErrors
            ? <ErrorText>{pageError ?? uniq(pageError ?? errors).join(', ')}</ErrorText>
            : requestAccountText}
    </LogInWrapper>
  }

export default TeacherLogInForm
export { SuccessMessage }
