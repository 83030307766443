import * as React from 'react'
import styled from 'styled-components'
import { SizedLoadingIcon } from '~/components/atoms/Icons'
import { AlertText } from '~/components/atoms/Text'
import { BotanicalBackground, type OverlayColorKey } from './BotanicalOverlay'

interface EmptyContentPlaceholderProps {
  isLoading?: boolean
  children?: React.ReactNode
  colorKey?: OverlayColorKey
  opacity?: number
  className?: string
}

const
  PlaceholderWrapper = styled.div`
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
  `,
  StyledAlertText = styled(AlertText)`
    width: ${p => p.theme.emSmallContentWidth}em;
    max-width: 100%;
    margin: auto;
  `,
  EmptyContentPlaceholder: React.FC<EmptyContentPlaceholderProps> = ({ isLoading, children, colorKey, opacity, className }) => {
    return <>
      <BotanicalBackground colorKey={colorKey} opacity={opacity} />
      <PlaceholderWrapper className={className}>
        {isLoading
          ? <SizedLoadingIcon color="accent" />
          : <StyledAlertText color="accent">
            {children ?? <span>{'There\'s nothing here yet!'}</span>}
          </StyledAlertText>}
      </PlaceholderWrapper>
    </>
  }

export default EmptyContentPlaceholder
