import { keyBy } from 'lodash'
import { SystemFieldNames, SystemFieldGroups, getControlGroupTitle, getControlGroupDescription, INDEPENDENT_VARIABLES } from './systemFieldConstants'
import { fieldDetails as irrigationScheme } from './irrigationScheme'
import { fieldDetails as irrigationRateValue } from './irrigationRateValue'
import { fieldDetails as irrigationRateUnit } from './irrigationRateUnit'

import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'
import type { IRRIGATION_SCHEMES } from './irrigationScheme'

export type IrrigationRateTreatmentFormat = {
  irrigationScheme: typeof IRRIGATION_SCHEMES.SATURATION
} | {
  irrigationScheme: string
  irrigationRateValue: number
  irrigationRateUnit: string
}

const
  independentVariable = INDEPENDENT_VARIABLES.IRRIGATION_RATE,
  schemaEntry: QField = {
    fieldName: SystemFieldNames.irrigationRateControlGroup,
    fieldType: 'SCOPE',
    visibleWhen: [{
      valueName: SystemFieldNames.independentVariable,
      conditionType: 'matches',
      testValue: independentVariable
    }],
    format: keyBy([
      irrigationScheme.schemaEntry,
      irrigationRateValue.schemaEntry,
      irrigationRateUnit.schemaEntry
    ], 'fieldName')
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.irrigationRateControlGroup,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    shouldPrecede: [SystemFieldNames.irrigationRateTreatmentGroups],
    isRequired: false,
    title: getControlGroupTitle(independentVariable),
    description: getControlGroupDescription(independentVariable),
    controlGroupFor: independentVariable,
    schemaEntry
  }

export {
  fieldDetails
}
