import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { Page } from '~/components/layouts/LayoutWidgets'
import { Button, WoodGrainPattern } from '~/components/atoms'

export type Orientation = 'portrait' | 'landscape'

interface PrintablePageProps {
  children: React.ReactNode
  backPath: string
  documentTitle: string
  orientation?: Orientation
  className?: string
}

interface PrintablePageBodyProps {
  $orientation?: Orientation
}

const
  PrintablePageOuter = styled(Page)`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    min-height: 100%;

    background: ${p => p.theme.colors.brandMed};
  `,
  PrintablePageBody = styled.div<PrintablePageBodyProps>`
    flex: 1 0 auto;
    width: ${p => p.$orientation === 'landscape' ? p.theme.pxLandscapePrintWidth : p.theme.pxPortraitPrintWidth}px;
    margin: auto;
    z-index: ${p => p.theme.zLocalTop};

    background: ${p => p.theme.colors.bodyBg};
  `,
  ButtonWrapper = styled.a`
    @media print {
      display: none;
    }

    position: absolute;
    top: 1.3em;
    left: 1.4em;
  `,
  PageBackground = styled(WoodGrainPattern)`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    @media print {
      display: none;
    }
  `,
  PrintablePage: React.FC<PrintablePageProps> = ({ documentTitle, children, backPath, orientation, className = '' }) => {
    const THEME = useTheme()

    return <PrintablePageOuter {...{ className, documentTitle }} dataCy="printable-page">
      <PageBackground color={THEME.colors.brandLight} />
      <PrintablePageBody $orientation={orientation}>
        {children}
      </PrintablePageBody>
      <ButtonWrapper href={backPath}>
        <Button color="back" size="medium" label='Go Back' />
      </ButtonWrapper>
    </PrintablePageOuter>
  }

export default PrintablePage
