import React from 'react'
import type { FormContextType } from './internalTypes'
import { FORM_STATUSES, type FormResolvers, type ResolveLookupFn, type StrictResolveLookupFn } from './types'

export const
  blankObject = {},
  noopFn = (): void => {},
  defaultResolveLookupFn: ResolveLookupFn = () => undefined,
  defaultResolvers: FormResolvers = {
    resolveList: () => [],
    resolveLabel: defaultResolveLookupFn,
    resolveHint: defaultResolveLookupFn,
    resolveKey: (defaultResolveLookupFn as unknown as StrictResolveLookupFn),
    resolveUnit: defaultResolveLookupFn,
    resolvePlaceholder: defaultResolveLookupFn,
    resolveCriteria: () => false,
    validateObject: () => blankObject
  },
  FormContext = React.createContext<FormContextType>({
    values: blankObject,
    initialValues: blankObject,
    scope: '',
    schema: blankObject,
    formOptions: blankObject,

    formStatus: FORM_STATUSES.CLEAN,
    errors: blankObject,
    result: blankObject,
    submissionSource: undefined,

    showingAllMissing: false,
    showingAllErrors: false,

    toggleShowAllMissing: noopFn,
    toggleShowAllErrors: noopFn,
    triggerFormSubmission: noopFn,

    ...defaultResolvers
  })

export { FORM_STATUSES }
