import * as React from 'react'
import type { BasicIconProps } from '~/types/utilities'

const
  baseSize = 47,
  baseThickness = 10,
  borderSize = 3,
  outerSize = baseSize + borderSize * 2,
  outerThickness = baseThickness + borderSize * 2,
  outerRX = outerThickness / 2,
  outerOffset = (baseSize - baseThickness) / 2,
  innerRX = baseThickness / 2,
  innerOffset = outerOffset + borderSize,
  BasicCross: React.FC<BasicIconProps> = ({ className, color = 'black', outlineColor }) => {
    return (
      <svg
        className={className}
        viewBox={`0 0 ${outerSize} ${outerSize}`}
        xmlns="http://www.w3.org/2000/svg"
        data-cy="basic-cross">
        {outlineColor
          ? <>
            <rect x="0" y={outerOffset} width={outerSize} height={outerThickness} rx={outerRX} fill={outlineColor} />
            <rect x={outerOffset} y="0" width={outerThickness} height={outerSize} rx={outerRX} fill={outlineColor} />
          </>
          : null}
        <rect x={borderSize} y={innerOffset} width={baseSize} height={baseThickness} rx={innerRX} fill={color} />
        <rect x={innerOffset} y={borderSize} width={baseThickness} height={baseSize} rx={innerRX} fill={color} />
      </svg>
    )
  }

export default BasicCross
