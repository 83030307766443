import { isEqual, isInteger } from 'lodash'
import { isEmptyValue, isNumeric, isAllEmpty } from '~/utils/testers'
import { parseDate, valueStringForError } from '~/utils/formatters'
import {
  IndependentConditions,
  TextConditions,
  TextToTextConditions,
  TextToIntConditions,
  TextToArrayConditions,
  NumberConditions,
  DateConditions,
  ArrayConditions
} from '~/features/questionnaire/types/conditionTypes'
import { getCalculatedValue, getValueAcrossEntities } from '~/features/experiment/calculatedValues'
import { CALCULATED_VALUE_NAMES } from '~/types/constants'

import type { AttrVal, EntityObject, FormOptions } from '~/form-brain2'
import type { VisibilityCondition, ConditionType } from '~/features/questionnaire/types/conditionTypes'

interface CARish { // like ContextAndResolvers
  // formOptions?: {
  //   otherEntities?: EntityObject[]
  // }
  formOptions?: FormOptions
  values: EntityObject
}

interface CheckConditionsParams {
  contextAndResolvers: CARish
  conditions?: VisibilityCondition[]
  fallback: boolean
  callerName?: string
  callerScopeValues?: EntityObject
}

interface CheckSingleConditionParams {
  contextAndResolvers: CARish
  condition: VisibilityCondition
  fallback: boolean
  callerName?: string
  callerScopeValues?: EntityObject
}

interface GetConditionValuesParams {
  condition: VisibilityCondition
  callerName?: string
}

interface ConditionValues {
  valueKey?: string
  testValueKey?: string
}

const
  checkConditions = ({ contextAndResolvers, conditions, fallback, callerName, callerScopeValues }: CheckConditionsParams): boolean => {
    if (!conditions || conditions.length === 0) {
      return fallback
    }

    // if (condition.valueName === '') {
    //  console.log('checkConditions', conditions)
    // }

    return conditions.every(condition => {
      const val = checkSingleCondition({ contextAndResolvers, condition, callerName, fallback, callerScopeValues })

      return val
    })
  },
  checkSingleCondition = ({ contextAndResolvers, condition, callerName, fallback, callerScopeValues }: CheckSingleConditionParams): boolean => {
    const
      { valueKey, testValueKey } = getConditionValueKeys({ condition, callerName }),
      { conditionType, conditionScope } = condition,
      formOptionsKey = valueKey?.match(/formOptions\./ig)
        ? valueKey.split('.')[1]
        : undefined,
      entities = conditionScope === 'ownScope' && callerScopeValues
        ? [callerScopeValues]
        : conditionScope === 'rootScope'
          ? [contextAndResolvers.values]
          : [
              contextAndResolvers.values,
              ...(contextAndResolvers.formOptions?.otherEntities ?? [])
            ],
      value = formOptionsKey
        ? contextAndResolvers.formOptions?.[formOptionsKey as keyof FormOptions] as AttrVal
        : CALCULATED_VALUE_NAMES.some(s => s === valueKey)
          ? getCalculatedValue(valueKey as typeof CALCULATED_VALUE_NAMES[number], entities)
          : getValueAcrossEntities(valueKey, entities, true)

    // if (valueKey === 'isRepeating') {
    // if (callerName === 'mascotVariant') {
    // if (conditionType === 'wordLengthAtLeast') {
    //   console.log({ valueKey, value, conditionType, entities, callerScopeValues })
    // }

    if (conditionScope === 'ownScope' && !callerScopeValues) { return fallback }

    if (([...IndependentConditions] as ConditionType[]).includes(conditionType)) {
      switch (conditionType) {
        case 'always':
          return true
        case 'never':
          return false
        case 'falsy':
          return !value || !!(typeof value === 'string' && (value.match(/^false$/i) ?? value.match(/^no$/i) ?? value.match(/ no /i)))
        case 'truthy':
          return !!value && !(typeof value === 'string' && (value.match(/^false$/i) ?? value.match(/^no$/i) ?? value.match(/ no /i)))
        case 'present':
          return !isAllEmpty(value)
        case 'absent':
          return isAllEmpty(value)
        case 'integer':
          return isNumeric(value)
            ? isInteger(Number(value))
            : fallback
        case 'notInteger':
          return isNumeric(value)
            ? !isInteger(Number(value))
            : fallback
        case '%':
          return isNumeric(value)
            ? (Number(value) >= 0) && (Number(value) <= 100)
            : fallback
        case 'year':
          return isNumeric(value)
            ? isInteger(Number(value)) && (Number(value) >= 0)
            : fallback
        default:
          throw new Error(`Independent condition "${conditionType}" not implemented`)
      }
    }

    if (value !== 0 && typeof value !== 'boolean' && !value) { // This check is for Typescript
      return fallback
    } else if (isEmptyValue(value)) {
      return fallback
    }

    if (!('testValue' in condition) && !('testValueKey' in condition)) {
      throw new Error(`Dependent value condition "${conditionType}" specified without test value`)
    }

    const testValue = 'testValue' in condition
      ? condition.testValue
      : getValueAcrossEntities(testValueKey, entities, true)

    if (([...TextConditions] as ConditionType[]).includes(conditionType)) {
      if (typeof value !== 'string' && typeof testValue !== 'boolean') {
        throw new Error(`Text condition "${conditionType}" specified with non-text value: ${valueStringForError(value)}`)
      }

      if (([...TextToTextConditions] as ConditionType[]).includes(conditionType)) {
        if (testValue != null && (typeof testValue !== 'string' && typeof testValue !== 'boolean')) {
          throw new Error(`Text and text condition "${conditionType}" specified with non-text test value: ${valueStringForError(testValue)}`)
        }

        switch (conditionType) {
          case 'equals':
            return isEqual(value, testValue)
          case 'notEquals':
            return !isEqual(value, testValue)
          case 'matches':
            return typeof testValue !== 'string' || typeof value !== 'string'
              ? testValue === value
              : new RegExp(testValue ?? '', 'ig').test(value)
          case 'notMatches':
            return typeof testValue !== 'string' || typeof value !== 'string'
              ? testValue !== value
              : !(new RegExp(testValue ?? '', 'ig').test(value))
          default:
            throw new Error(`Text and text condition ${conditionType} not implemented`)
        }
      }

      if (([...TextToIntConditions] as ConditionType[]).includes(conditionType)) {
        if (testValue != null && !isInteger(Number(testValue))) {
          throw new Error(`Text and number condition "${conditionType}" specified with non-numeric test value: ${valueStringForError(testValue)}`)
        }

        if (typeof value !== 'string') { return fallback }

        switch (conditionType) {
          case 'wordLengthAtLeast':
            return value.split(' ').length >= Number(testValue)
          case 'wordLengthLessThan':
            return value.split(' ').length < Number(testValue)
          case 'characterLengthAtLeast':
            return value.length >= Number(testValue)
          case 'characterLengthLessThan':
            return value.length < Number(testValue)
          default:
            throw new Error(`Text and number condition ${conditionType} not implemented`)
        }
      }

      if (([...TextToArrayConditions] as ConditionType[]).includes(conditionType)) {
        if (testValue != null && (
          !Array.isArray(testValue) ||
          (testValue.length && testValue.some(o => typeof o !== 'string'))
        )) {
          throw new Error(`Text and array condition "${conditionType}" specified with non-array or non-string test value: ${valueStringForError(testValue)}`)
        }

        switch (conditionType) {
          case 'anyOf':
            return ((testValue ?? []) as Array<string | number | boolean>).includes(value)
          case 'noneOf':
            return !((testValue ?? []) as Array<string | number | boolean>).includes(value)
          default:
            throw new Error(`Text and array condition "${conditionType}" not implemented`)
        }
      }

      throw new Error(`Text condition "${conditionType}" not implemented`)
    }

    if (([...NumberConditions] as ConditionType[]).includes(conditionType)) {
      if (!isNumeric(value)) {
        throw new Error(`Numeric condition "${conditionType}" specified with non-numeric value: ${valueStringForError(value)}`)
      }

      if (testValue != null && !isNumeric(testValue)) {
        throw new Error(`Numeric condition "${conditionType}" specified with non-numeric test value: ${valueStringForError(testValue)}`)
      }

      if (testValue == null) {
        throw new Error(`Numeric condition "${conditionType}" specified without a test value`)
      }

      const
        numValue = Number(value),
        numTestValue = Number(testValue)

      switch (conditionType) {
        case 'equalsNumber':
          return isEqual(numValue, numTestValue)
        case 'notEqualsNumber':
          return !isEqual(numValue, numTestValue)
        case 'lt':
          return numValue < numTestValue
        case 'gt':
          return numValue > numTestValue
        case 'lto':
          return numValue <= numTestValue
        case 'gto':
          return numValue >= numTestValue
      }

      throw new Error(`Numeric condition "${conditionType}" not implemented`)
    }

    if (([...DateConditions] as ConditionType[]).includes(conditionType)) {
      const dateValue = typeof value === 'string'
        ? parseDate(value)
        : undefined

      if (!dateValue?.isValid) {
        throw new Error(`Date condition "${conditionType}" specified for an unrecognized date value: ${valueStringForError(value)}`)
      }

      const dateTestValue = typeof testValue === 'string'
        ? parseDate(testValue)
        : undefined

      if (!dateTestValue?.isValid) {
        throw new Error(`Date condition "${conditionType}" specified with an unrecognized date test value: ${valueStringForError(testValue)}`)
      }

      switch (conditionType) {
        case 'sameMonthAs':
          return dateValue.hasSame(dateTestValue, 'month')
        case 'sameDateAs':
          return dateValue.hasSame(dateTestValue, 'day')
        case 'before':
          return dateValue < dateTestValue
        case 'after':
          return dateValue > dateTestValue
      }

      throw new Error(`Date condition "${conditionType}" not implemented`)
    }

    if (([...ArrayConditions] as ConditionType[]).includes(conditionType)) {
      if (!Array.isArray(value)) {
        throw new Error(`Array condition "${conditionType}" specified with non-array value: ${valueStringForError(value)}`)
      }

      const arrayValue = value as unknown[]

      if (['includes', 'notIncludes'].includes(conditionType)) {
        if (testValue == null) {
          throw new Error(`Array condition "${conditionType}" specified with empty test value: ${valueStringForError(testValue)}`)
        }

        switch (conditionType) {
          case 'includes':
            return arrayValue.includes(testValue as unknown)
          case 'notIncludes':
            return !arrayValue.includes(testValue as unknown)
        }
      }

      if (['lengthAtLeast', 'lengthLessThan'].includes(conditionType)) {
        if (!isNumeric(testValue)) {
          throw new Error(`Array to number condition "${conditionType}" specified with non-numeric test value: ${valueStringForError(testValue)}`)
        }

        const numTestValue = Number(testValue)

        switch (conditionType) {
          case 'lengthAtLeast':
            return arrayValue.length >= numTestValue
          case 'lengthLessThan':
            return arrayValue.length < numTestValue
        }
      }

      throw new Error(`Array condition "${conditionType}" not implemented`)
    }

    throw new Error(`Unrecognized condition "${conditionType}" in value check`)
  },
  getConditionValueKeys = ({ condition, callerName }: GetConditionValuesParams): ConditionValues => {
    const
      valueKey = 'valueName' in condition
        ? condition.valueName
        : callerName,
      testValueKey = 'testValueName' in condition
        ? condition.testValueName
        : undefined

    return {
      valueKey,
      testValueKey
    }
  }

export { checkConditions }
