import * as React from 'react'
import styled from 'styled-components'
import { FormContext, FormScope } from '~/form-brain2'
import { isAllEmpty } from '~/utils/testers'
import { addEmphasisToString, formBodyForSpecialSection, interpolateStringInForm } from '~/features'
import { HeadingThree, SubheadingTwo } from '~/components/atoms'
import {
  ErrorNotice,
  ExpandedRow,
  FormElement,
  FormRenderer,
  ErrorMessage,
  BlurbBuilder,
  EmptyContentPlaceholder
} from '~/components/molecules'
import IconForEntry from '~/components/organisms/ListStep/IconForEntry'
import { getEntrySummaries, getHeadingForEntry } from '~/components/organisms/ListStep/helpers'
import SummaryStatsTable from '~/components/organisms/SpecialContentArea/SummaryStatsTable'
import StatsTestSummary from '~/components/organisms/SpecialContentArea/StatsTestSummary'

import type { FormRendererProps } from '~/form-brain2'
import type {
  ListResponse,
  InputElement,
  PagedSectionElement,
  ListWorksheet,
  SpecialListSectionElement,
  Worksheet,
  Experiment
} from '~/features'
import type { PersistedListEntrySummary } from '~/components/organisms/ListStep/types'

const
  /** These two plain element renderers are in this verbose format to avoid passing props from FormScope directly to DOM elements */
  OverviewForm: React.FC<{ id: string, onSubmit: React.FormEventHandler, className?: string, children?: React.ReactNode }> =
    ({ className, children, id, onSubmit }) => {
      return <form {...{ className, id, onSubmit }}>{children}</form>
    },
  ScopeRenderer: React.FC<{ className?: string, children?: React.ReactNode }> =
    ({ className, children }) => {
      return <div className={className}>{children}</div>
    },
  P = styled.p`
    margin-top: 20px;
  `,
  PagedOverviewSection = styled.fieldset`
    margin-top: 0.5em;
    border: none;

    @media(min-width: ${p => p.theme.pxFullScreenCutoffWidth}px) {
      margin: 1em 0.5em;
    }
  `,
  PagedOverviewPage = styled.div`
    margin-bottom: 1em;
    margin-top: 0.5em;

    @media(min-width: ${p => p.theme.pxFullScreenCutoffWidth}px) {
      margin: 1em 0.5em;
    }

    &:first-child {
      margin-top: 0;
    }
  `,
  getOverviewFormRenderer = (worksheet: Worksheet, pageHeadings: Record<string, string | undefined>, experiment: Experiment): React.FC<FormRendererProps> => {
    const OverviewFormRenderer: React.FC<FormRendererProps> = ({ formId, className, formBody }) => {
      let content: React.ReactNode
      const
        formContext = React.useContext(FormContext),
        worksheetFormat = worksheet.format,
        { showingAllErrors, errors, triggerFormSubmission, values } = formContext

      if (worksheetFormat === 'LIST') {
        const
          listValues = values as unknown as ListResponse,
          listResponses = listValues.responses.entries,
          entrySummaries = getEntrySummaries({ entries: listResponses, worksheet: worksheet as ListWorksheet, currentSelection: undefined }),
          otherEntities = formContext.formOptions.otherEntities ?? []

        content = <>
          {entrySummaries.length === 0
            ? <EmptyContentPlaceholder>
              <P>The list for this response was left blank.</P>
            </EmptyContentPlaceholder>
            : undefined}
          {entrySummaries.map((entrySummary, i) => {
            const
              { section, entry, actualIndex, nthOfKind } = entrySummary as PersistedListEntrySummary,
              name = `responses.entries[${actualIndex}]`,
              formBody = (section as SpecialListSectionElement)?.specialSectionId
                ? formBodyForSpecialSection(section as SpecialListSectionElement)
                : section?.body

            return (
              <ExpandedRow
                key={i}
                isInline
                heading={getHeadingForEntry({ nthOfKind, section, entry, otherEntities, experiment })}
                headerIcon={<IconForEntry {...{ entry, section, actualIndex, nthOfKind }} />}
                neutralBackground={formContext.formOptions.isReadOnly}>
                {(section as SpecialListSectionElement).specialSectionId === 'SUMMARY_STATS'
                  ? <SummaryStatsTable
                      section={section as SpecialListSectionElement}
                      response={listValues}
                      isReadOnly={formContext.formOptions.isReadOnly}
                      {...{ experiment, entry, otherEntities }} />
                  : (section as SpecialListSectionElement).specialSectionId === 'STATS_TEST'
                      ? <StatsTestSummary {...{ entry, otherEntities }} />
                      : <FormScope
                        name={name}
                        scopeOptions={{
                          ...section,
                          formBody,
                          fieldName: name,
                          elementVariety: 'SCOPE'
                        }}
                        formBody={formBody}
                        ScopeRenderer={ScopeRenderer}
                        ScopeEntryRenderer={FormRenderer}
                      />}
              </ExpandedRow>
            )
          })}
        </>
      } else if (worksheetFormat === 'PAGED') {
        content = <>
          {(formBody as PagedSectionElement[]).map((section, sectionIndex) => {
            const sectionHeading = formBody.length > 1 && section.title
              ? interpolateStringInForm(section.title, formContext)
              : `Section ${sectionIndex + 1}`

            return <PagedOverviewSection key={`${section.id}-${sectionIndex}`}>
              {sectionHeading
                ? <HeadingThree>{sectionHeading}</HeadingThree>
                : null}
              {section.pages.map((page, pageIndex) => {
                const
                  pageHeadingBlurb = page.heading && typeof page.heading === 'object' && page.heading.elementVariety === 'BLURB' && !formContext.formOptions.withoutBlurbs
                    ? <BlurbBuilder
                        {...page.heading}
                        noWrapper
                        withBackground
                        paragraphs={
                          page.heading.paragraphs.map(str =>
                            addEmphasisToString(interpolateStringInForm(str, formContext)) ?? [str]
                          )
                        } />
                    : undefined,
                  rawHeading = typeof page.heading === 'object'
                    ? undefined
                    : pageHeadings[`${section.id}.${page.id}`],
                  pageHeadingText = interpolateStringInForm(rawHeading, formContext),
                  pageBody = rawHeading
                    ? [...page.body].map(el => (
                        rawHeading === (el as InputElement).label
                          ? { ...el, hideLabel: true }
                          : el
                      ))
                    : page.body

                return (
                  <PagedOverviewPage key={`${page.id}-${pageIndex}`}>
                    {pageHeadingText
                      ? <SubheadingTwo>{pageHeadingText}</SubheadingTwo>
                      : pageHeadingBlurb}
                    {pageBody.map((o, i) => (
                      <FormElement
                        key={`${i}-${(o as InputElement).fieldName ?? o.id}`}
                        bodyElement={{ ...o, parentId: `${section.id}.${page.id}` }}
                        elementIndex={i} />
                    ))}
                  </PagedOverviewPage>
                )
              })}
            </PagedOverviewSection>
          })}
        </>
      }

      if (!content) {
        return <ErrorMessage>
          There was a problem displaying this form. Please try again later and contact support if the problem persists.
        </ErrorMessage>
      }

      return <OverviewForm id={formId} className={className} onSubmit={triggerFormSubmission}>
        {showingAllErrors && !isAllEmpty(errors)
          ? <ErrorNotice errors={formContext.errors} />
          : null}
        {content}
      </OverviewForm>
    }

    return OverviewFormRenderer
  }

export { getOverviewFormRenderer }
