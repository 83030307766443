import * as React from 'react'
import styled from 'styled-components'

interface Props {
  color?: string
  borderColor?: string
  withShadow?: boolean
  className?: string
}

const
  BackgroundRect = styled.rect<{ $withShadow?: boolean }>`
    ${p => p.$withShadow ? `filter: drop-shadow(1px 1px 2.5px ${p.theme.colors.shadow});` : ''}
  `,
  BackgroundCircle = styled.circle<{ $withShadow?: boolean }>`
    ${p => p.$withShadow ? `filter: drop-shadow(1px 1px 2.5px ${p.theme.colors.shadow});` : ''}
  `,
  backgroundRectProps = { y: '25.485', width: '125.57px', height: '37.84px', rx: '8' },
  foregroundRectProps = { x: '3.55', y: '29', width: '118.47px', height: '30.74px', rx: '5.52' },
  StreetSign: React.FC<Props> = ({ className, color = 'black', borderColor = 'white', withShadow }) => {
    return (
      <svg className={className} viewBox="0 0 125.57 63.325" data-cy="street-sign" style={withShadow ? { overflow: 'visible' } : {}}>
        {/* border */}
        <BackgroundRect {...backgroundRectProps} $withShadow={withShadow} fill={borderColor} />
        <BackgroundCircle $withShadow={withShadow} cx="62.785" cy="23" r="23" fill={borderColor} />
        {/* inner */}
        <rect {...foregroundRectProps} fill={color} />
        <circle cx="62.785" cy="23" r="19.45" fill={color} />
      </svg>
    )
  },
  RectStreetSign: React.FC<Props> = ({ className, color = 'black', borderColor = 'white', withShadow }) => {
    return (
      <svg className={className} preserveAspectRatio="none" viewBox="0 0 125.57 63.325" data-cy="street-sign" style={withShadow ? { overflow: 'visible' } : {}}>
        <BackgroundRect {...backgroundRectProps} $withShadow={withShadow} fill={borderColor} />
        <rect {...foregroundRectProps} fill={color} />
      </svg>
    )
  }

export { RectStreetSign }
export default StreetSign
