import { map, mapValues } from 'lodash'
import { DYNAMIC_LABELS, DYNAMIC_LABEL_DETAILS } from '~/features/experiment/types'
import type { EntitySchema, SchemaEntry } from '~/form-brain2'
import type { DynamicLabels, Experiment } from '~/features/experiment/types'
import type { AnyPlainOrInputElement } from '~/features/questionnaire/types/plainElementTypes'

export const
  projectSchema: EntitySchema = {
    name: {
      fieldName: 'name',
      fieldType: 'STRING',
      requiredWhen: [{ conditionType: 'always' }]
    },
    basedOnType: {
      fieldName: 'basedOnType',
      fieldType: 'STRING'
      // requiredWhen: [{ conditionType: 'always' }]
    },
    basedOnId: {
      fieldName: 'basedOnId',
      fieldType: 'STRING',
      // requiredWhen: [{ conditionType: 'always' }]
      requiredWhen: [{ conditionType: 'present', valueName: 'basedOnType' }]
    },
    experimentStyle: {
      fieldName: 'experimentStyle',
      fieldType: 'STRING',
      requiredWhen: [{ conditionType: 'always' }]
    },
    startedAt: {
      fieldName: 'startedAt',
      fieldType: 'DATE'
    },
    closedAt: {
      fieldName: 'closedAt',
      fieldType: 'DATE'
    },
    // startedAt
    // closedAt
    // testMode
    progressBasis: {
      fieldName: 'progressBasis',
      fieldType: 'ENUM',
      options: [
        { optionValue: 'approval', optionLabel: 'When the previous step is approved' },
        { optionValue: 'submission', optionLabel: 'When the previous step is submitted' },
        { optionValue: 'manual', optionLabel: 'When the previous step has been started' }
      ] as Array<{ optionValue: Experiment['progressBasis'], optionLabel: string }>,
      requiredWhen: [{ conditionType: 'always' }]
    },
    // randomnessSeed
    dynamicLabels: {
      fieldName: 'dynamicLabels',
      fieldType: 'SCOPE',
      format: mapValues(DYNAMIC_LABELS, (val, key) => ({
        fieldName: key,
        fieldType: 'STRING'
      })) as Record<keyof DynamicLabels, SchemaEntry>
    }
  },
  newProjectFormBody: AnyPlainOrInputElement[] = [
    {
      id: 'projectName',
      elementVariety: 'TEXT_INPUT',
      label: 'Name:',
      placeholder: 'Enter a name',
      hint: 'Some examples: the classroom or period name, a name chosen by the learners in a project group, or the name of a single learner doing a solo project. You can change this later if needed.',
      fieldName: 'name'
    },
    {
      id: 'projectBasedOnType',
      elementVariety: 'SELECT_INPUT',
      label: 'Start from:',
      fieldName: 'basedOnType',
      hint: 'The format of the project and settings for the group will be based on the template or group you choose here. You can change this later if needed, but only until you open the project to the learners. After the project is opened to learners, this cannot be changed.',
      placeholder: 'Choose one',
      invalidates: ['basedOnId'],
      list: [{
        optionValue: 'TemplateSet',
        optionLabel: 'A template'
      }, {
        optionValue: 'Experiment',
        optionLabel: 'One of your existing groups'
      }],
      blankOption: 'Start from scratch'
    },
    {
      id: 'projectTemplate',
      elementVariety: 'SELECT_INPUT',
      fieldName: 'basedOnId',
      placeholder: 'Choose a project template',
      missingValueLabel: '(Selected template unavailable)',
      visibleWhen: [
        { conditionType: 'equals', testValue: 'TemplateSet', valueName: 'basedOnType' },
        { conditionType: 'present', valueName: 'basedOnType' }
      ]
    },
    {
      id: 'projectBasedOnExperiment',
      elementVariety: 'SELECT_INPUT',
      fieldName: 'basedOnId',
      placeholder: 'Choose a previous group',
      missingValueLabel: '(Selected group unavailable)',
      visibleWhen: [
        { conditionType: 'equals', testValue: 'Experiment', valueName: 'basedOnType' },
        { conditionType: 'present', valueName: 'basedOnType' }
      ]
    },
    {
      id: 'projectStyle',
      elementVariety: 'SELECT_INPUT',
      fieldName: 'experimentStyle',
      label: 'Learners in this group will...',
      hint: 'When learners run one experiment together, they will design the experiment as a group and collect one set of data. If working as a group, students may overwrite each other’s answers: the last response submitted for each question is the one that is saved. For individual experiments, each student in the group can have a different design and will collect their own data.',
      list: [{
        optionValue: 'group',
        optionLabel: 'run one experiment together as a group'
      }, {
        optionValue: 'individual',
        optionLabel: 'each run their own experiments'
      }],
      readOnlyWhen: [{ conditionType: 'present', valueName: 'startedAt' }]
    }
  ],
  projectSettingsFormBody: AnyPlainOrInputElement[] = [
    ...newProjectFormBody,
    {
      id: 'projectProgressBasis',
      elementVariety: 'SELECT_INPUT',
      label: 'When should a new step become available to each learner?',
      fieldName: 'progressBasis',
      noSort: true,
      showHintAlways: true,
      hint: 'This determines when **individual learners** will have access to **individual steps**'
    },
    {
      id: 'projectAccessBlurb',
      elementVariety: 'BLURB',
      iconColor: 'attention',
      iconContent: '!',
      noWrapper: true,
      withBackground: true,
      paragraphs: ['In order for a learner to access a step, the **project must be open**, the **step must be published**, and the **learner\'s previous step** must either be started, submitted, or approved depending on your choice above.']
    }
  ],
  projectLabelsFormBody: AnyPlainOrInputElement[] = [
    {
      id: 'projectDynamicLabels',
      fieldName: 'dynamicLabels',
      elementVariety: 'SCOPE',
      formBody: map(DYNAMIC_LABEL_DETAILS, (value, key) => {
        const { label, hint } = value

        return {
          id: `dynamicLabel${key}`,
          elementVariety: 'longAnswer' in value && value.longAnswer ? 'LONG_ANSWER' : 'TEXT_INPUT',
          fieldName: key,
          label,
          hint,
          placeholder: DYNAMIC_LABELS[key as keyof typeof DYNAMIC_LABEL_DETAILS]
        }
      })
    }
  ]
