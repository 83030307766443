import { useOutletContext } from 'react-router-dom'
import type { MascotVariant } from '~/components/molecules'
import type { Experiment, Learner, Worksheet, WorksheetResponse } from '~/features'

export interface StepMascotState {
  mascotVariant?: MascotVariant
  hintText?: string
}

export interface LearnerStepContextType {
  setMascotState: React.Dispatch<React.SetStateAction<StepMascotState>>
  learner: Learner
  worksheet: Worksheet
  worksheets: Worksheet[]
  experiment: Experiment
  response: WorksheetResponse
  responses: WorksheetResponse[]
  isLoadingData: boolean
  isFetchingData: boolean
}

const useLearnerStepContext = (): LearnerStepContextType => {
  return useOutletContext<LearnerStepContextType>()
}

export default useLearnerStepContext
