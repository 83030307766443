import * as React from 'react'
import { getLongRandomId } from '~/utils/strings'
import { isAllEmpty } from '~/utils/testers'
import SystemFieldDefaultCollection from '~/components/molecules/FormAternates/SystemFieldDefaultCollection'
import Blurb from '~/components/molecules/Blurb'
import TextInput from '~/components/molecules/FormElements/TextInput'
import Textarea from '~/components/molecules/FormElements/Textarea'
import Select from '~/components/molecules/FormElements/Select'
import MultiSelect from '~/components/molecules/FormElements/MultiSelect'
import Checkbox from '~/components/molecules/FormElements/Checkbox'
import Combobox from '~/components/molecules/FormElements/Combobox'
import ColorPicker from '~/components/molecules/FormElements/ColorPicker'
import ListMaker from '~/components/molecules/FormElements/ListMaker'

import { FormContext, type FormFieldRendererProps, type FullOptionList } from '~/form-brain2'
import { SYSTEM_FIELDS, type ColorPickerInput, type InputElement, type MultiTextInput, type SelectInput, type SystemFieldName } from '~/features'

const
  FormFieldRenderer: React.FC<FormFieldRendererProps> = (props) => {
    const
      { formOptions, values } = React.useContext(FormContext),
      bodyElement = props.bodyElement as InputElement,
      showLabel = !bodyElement.hideLabel,
      showUnit = !bodyElement.hideUnit,
      showError = props.showError && !bodyElement.hideError,
      showHint = !!(props.showHint && !bodyElement.hideHint) || bodyElement.showHintAlways,
      isDisabled = props.formOptions.unsubmittable,
      inputProps = {
        ...props,
        showLabel,
        showUnit,
        showError,
        showHint,
        isDisabled
      },
      iconContentKey = (bodyElement as ColorPickerInput).contentKey,
      { label, name, currentValue, updateAttr } = props

    React.useEffect(() => {
      if (label && bodyElement.label && showLabel && formOptions.setParentHeading) {
        formOptions.setParentHeading(bodyElement.label, bodyElement.parentId)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [label, bodyElement.label, showLabel, formOptions.setParentHeading])

    React.useEffect(() => {
      if (name === 'id' && formOptions.automaticIdFields && !currentValue) {
        updateAttr(getLongRandomId())
      }
    }, [name, formOptions.automaticIdFields, currentValue, updateAttr])

    if (!props.isVisible) { return null }
    if (props.name === 'id' && formOptions.automaticIdFields) { return null }

    if (props.name === 'invalidates' && bodyElement.elementVariety === 'MULTI_TEXT_INPUT') {
      const
        element = bodyElement as MultiTextInput,
        siblingFieldName = values?.fieldName as SystemFieldName | undefined,
        relevantSystemField = siblingFieldName ? SYSTEM_FIELDS[siblingFieldName] : undefined,
        relevantSystemValues = (
          relevantSystemField
            ? relevantSystemField.schemaEntry?.invalidates
            : undefined
        )

      if (relevantSystemField && relevantSystemValues && !isAllEmpty(relevantSystemValues)) {
        return <SystemFieldDefaultCollection
          bodyElement={element}
          inputProps={inputProps}
          systemField={relevantSystemField}
          systemFieldCollectionValues={relevantSystemValues}
        />
      }
    }

    switch (bodyElement.elementVariety) {
      case 'TEXT_INPUT':
      case 'DATE_INPUT':
      case 'NUMBER_INPUT':
        return <TextInput {...inputProps} />
      case 'SHORT_ANSWER':
      case 'LONG_ANSWER':
      case 'PARAGRAPHS':
        return <Textarea {...inputProps} />
      case 'CHECKBOX_INPUT':
        return <Checkbox {...inputProps} />
      case 'SELECT_INPUT':
        return (bodyElement as SelectInput).customOption
          ? <Combobox {...inputProps} list={inputProps.list as FullOptionList} />
          : <Select {...inputProps} list={inputProps.list as FullOptionList} />
      case 'MULTI_SELECT_INPUT':
        return <MultiSelect {...inputProps} list={inputProps.list as FullOptionList} />
      case 'MULTI_TEXT_INPUT':
        return <ListMaker {...inputProps} list={inputProps.list as FullOptionList} />
      case 'COLOR_PICKER':
        return <ColorPicker {...inputProps} iconContentName={iconContentKey ? values[iconContentKey] as string | undefined : undefined} />
      default:
        return <Blurb content={<pre>{JSON.stringify(bodyElement, null, 2)}</pre>} heading="Form Error: Unknown input type" />
    }
  }

export default FormFieldRenderer
