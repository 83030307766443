import type { ListEntry, ListSectionElement, SpecialListSectionElement, SpecialSectionId } from '~/features'
import type { EntityObject } from '~/form-brain2'

interface SectionsAndResponsesForSpecialSectionId {
  specialSectionId: SpecialSectionId
  responsesBySection: ResponsesForSection[]
}

interface ResponsesForSection {
  section: SpecialListSectionElement
  responseEntries: ListEntry[]
}

const
  includedSections: SpecialSectionId[] = ['SUMMARY_STATS', 'STATS_TEST'],
  getResultSectionsAndResponses = (sections: ListSectionElement[], responseEntities: EntityObject[]): SectionsAndResponsesForSpecialSectionId[] => {
    return includedSections.reduce<SectionsAndResponsesForSpecialSectionId[]>((memo, specialSectionId) => {
      return [
        ...memo,
        {
          specialSectionId,
          responsesBySection: sections.filter(
            o => 'specialSectionId' in o && (o as SpecialListSectionElement).specialSectionId === specialSectionId
          ).reduce<ResponsesForSection[]>((innerMemo, section) => {
            const newEntry: ResponsesForSection = {
              section: section as SpecialListSectionElement,
              responseEntries: responseEntities.filter(o => o.sectionId === section.id) as ListEntry[]
            }
            return [...innerMemo, newEntry]
          }, [])
        }
      ]
    }, [])
  }

export default getResultSectionsAndResponses
