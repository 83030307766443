import { isAllEmpty, isEmptyValue } from '~/utils/testers'
import { stringifyValue, type SavePointDataSection, type SavePointValue } from './helpers'
import { AlignmentType, BorderStyle, Document, HeadingLevel, Packer, Paragraph, ShadingType, Table, TableCell, TableRow, TextRun, WidthType } from 'docx'

export function docFromSavePointData (section: SavePointDataSection[], title: string | undefined, stringOnly: true): Promise<null | Uint8Array>
export function docFromSavePointData (section: SavePointDataSection[], title: string | undefined): Promise<null | Blob>

export function docFromSavePointData (section: SavePointDataSection[], title: string | undefined, stringOnly?: boolean): Promise<null | Blob | Uint8Array> {
  if (isEmptyValue(section)) return Promise.resolve(null)

  const docParagraphs: Array<Paragraph | Table> = []

  if (title && !isAllEmpty(title)) {
    docParagraphs.push(new Paragraph({
      text: stringifyValue(title, 'docx'),
      heading: HeadingLevel.HEADING_1
    }))
  }

  section.forEach(({ data, headers, _section, specialSectionId }) => {
    if (_section && !isEmptyValue(_section)) {
      docParagraphs.push(new Paragraph({
        text: stringifyValue(_section, 'docx'),
        heading: HeadingLevel.HEADING_2
      }))
    }

    if (specialSectionId === 'SUMMARY_STATS' && data.some(o => !Array.isArray(o))) {
      const
        dataRows = data.filter(row => !Array.isArray(row)),
        textRows = data.filter(row => Array.isArray(row))

      docParagraphs.push(new Table({
        rows: [
          new TableRow({
            tableHeader: true,
            children: headers?.map(([k, v]) => new TableCell({
              children: [new Paragraph({
                children: [new TextRun({ text: stringifyValue(v, 'docx'), color: '#727272' })],
                alignment: k === 'notes' ? AlignmentType.LEFT : AlignmentType.CENTER
              })],
              margins: { bottom: 50, top: 50, left: 150, right: 150 },
              width: k === 'notes' ? { size: 24, type: WidthType.PERCENTAGE } : undefined
            })) ?? []
          }),
          ...dataRows.map(row => new TableRow({
            children: headers?.map(([k, v], i) => new TableCell({
              children: [new Paragraph({
                children: [new TextRun({
                  text: stringifyValue((row as Record<string, SavePointValue>)[k], 'docx'),
                  color: i === 0 ? '#727272' : undefined
                })],
                alignment: k === 'notes' ? AlignmentType.LEFT : AlignmentType.CENTER
              })],
              margins: { bottom: 50, top: 50, left: 150, right: 150 },
              width: k === 'notes' ? { size: 24, type: WidthType.PERCENTAGE } : undefined
            })) ?? []
          }))
        ]
      }))

      data = textRows
    }

    if (specialSectionId === 'STATS_TEST') {
      data.forEach(row => {
        if (Array.isArray(row) && row[0]) {
          docParagraphs.push(new Paragraph({
            children: (row[0] as string).split('**').map((s, i) => new TextRun({
              text: s,
              bold: i % 2 !== 0 // Is even
            }))
          }))
        }

        const border = {
          style: BorderStyle.SINGLE,
          color: '#f5f5f5',
          size: 2,
          space: 10
        }

        if ('result' in row) {
          docParagraphs.push(new Paragraph({
            children: (row.result as string).split('**').map((s, i) => new TextRun({
              text: s,
              color: i % 2 !== 0 ? '#4075AE' : undefined, // Accent color when even
              bold: i % 2 !== 0 // Is even
            })),
            border: { top: border, bottom: border, left: border, right: border },
            shading: {
              type: ShadingType.SOLID,
              color: '#f5f5f5'
              // fill: "FF0000",
            }
          }))
        }
      })

      data = []
    }

    data.forEach(row => {
      if (Array.isArray(row)) {
        if (!isEmptyValue(row)) {
          docParagraphs.push(new Paragraph({
            children: row.map((v, i) => new TextRun({
              text: stringifyValue(v, 'docx'),
              break: i === 0 ? 0 : 1
            }))
          }))
        }
      } else if (row.h1 && !isEmptyValue(row.h1)) {
        docParagraphs.push(new Paragraph({
          text: stringifyValue(row.h1, 'docx'),
          heading: HeadingLevel.HEADING_1
        }))
      } else if (row.h2 && !isEmptyValue(row.h2)) {
        docParagraphs.push(new Paragraph({
          text: stringifyValue(row.h2, 'docx'),
          heading: HeadingLevel.HEADING_2
        }))
      } else if (row.h3 && !isEmptyValue(row.h3)) {
        docParagraphs.push(new Paragraph({
          text: stringifyValue(row.h3, 'docx'),
          heading: HeadingLevel.HEADING_3
        }))
      } else if (row.h4 && !isEmptyValue(row.h4)) {
        docParagraphs.push(new Paragraph({
          text: stringifyValue(row.h4, 'docx'),
          heading: HeadingLevel.HEADING_4
        }))
      } else if ((row.label || row.value) && !isAllEmpty([row.label, row.value])) {
        const
          labelRun = row.label && !isEmptyValue(row.label)
            ? new TextRun({
              text: stringifyValue(row.label, 'docx') + ' ',
              ...(row.inline ? { bold: true } : { color: '#727272' })
            })
            : null,
          valueRun = row.value && !isEmptyValue(row.value)
            ? new TextRun({
              text: stringifyValue(row.value, 'docx'),
              ...(row.inline || !labelRun ? {} : { break: 1 })
            })
            : null,
          unitRun = row.unit && !isEmptyValue(row.unit) && valueRun
            ? new TextRun({
              text: ' ' + stringifyValue(row.unit, 'docx')
            })
            : null

        docParagraphs.push(new Paragraph({
          children: [
            ...(labelRun ? [labelRun] : []),
            ...(valueRun ? [valueRun] : []),
            ...(unitRun ? [unitRun] : [])
          ],
          spacing: row.inline ? {} : { line: 276 * 1.1 }
        }))
      }
    })
  })

  const doc = new Document({
    sections: [{
      children: docParagraphs
    }],
    styles: {
      default: {
        document: {
          paragraph: {
            spacing: {
              line: 276,
              before: 20 * 72 * 0.1,
              after: 20 * 72 * 0.05
            }
          },
          run: {
            font: {
              name: 'Nunito'
            }
          }
        },
        heading2: {
          paragraph: {
            spacing: {
              before: 20 * 72 * 0.2
            }
          }
        },
        heading3: {
          paragraph: {
            spacing: {
              before: 20 * 72 * 0.15
            }
          }
        }
      }
    }
  })

  return stringOnly
    ? Packer.toBuffer(doc)
    : Packer.toBlob(doc)
}

export default docFromSavePointData
