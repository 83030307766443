import React from 'react'
import { get } from 'lodash'
import { useDispatch } from 'react-redux'
import { baseUrl } from '~/services/api'
import { entitiesReceived } from '~/features/entity/entitySlice'
import { apiPathLookup } from './useAddEntity'
import useSession from './useSession'

import type { EntityErrors, ResultObject } from '~/form-brain2'
import type { AnyEntity } from '~/features/entity/entitySlice'
import type { AnyObject, APIEntity } from '~/types/utilities'

interface UseUpdateEntity {
  updateEntity: (params: { entity: AnyEntity, newValues: Partial<AnyEntity>, sourceOfUpdate?: string, customPath?: string }) => Promise<ResultObject>
  isUpdating?: boolean
  sourceOfUpdate?: string
  sourceOfErrors?: string
  isSuccess?: boolean
  errors?: EntityErrors
  meta?: AnyObject
}

interface UseUpdateEntityState {
  isUpdating?: boolean
  sourceOfUpdate?: string
  sourceOfErrors?: string
  isSuccess?: boolean
  errors?: EntityErrors
  meta?: AnyObject
}

const
  useUpdateEntity = (): UseUpdateEntity => {
    const
      dispatch = useDispatch(),
      { activeToken, makeRequestWithSession } = useSession(),
      [state, setState] = React.useState<UseUpdateEntityState>({}),
      { isUpdating, sourceOfUpdate, /* sourceOfErrors, */ isSuccess, errors, meta } = state,
      updateEntity: UseUpdateEntity['updateEntity'] = ({ entity, newValues, sourceOfUpdate, customPath }) => {
        setState({ isUpdating: true, sourceOfUpdate })
        const
          body = customPath === 'current_user'
            ? newValues
            : { [entity.type]: { ...newValues, id: entity.id } }, // Put a value in there to make sure it passes params.required even with only nexted values
          path = customPath ?? `${apiPathLookup[entity.type]}/${entity.id}`

        return makeRequestWithSession({
          method: 'patch',
          url: `${baseUrl}${path}`,
          headers: {
            Authorization: `Bearer ${activeToken as string}`
          },
          data: body
        })
          .then((params) => {
            const entities = get(params, 'data.data') as Array<APIEntity<AnyEntity>>

            dispatch(entitiesReceived(entities))
            setState({ isSuccess: true, meta: get(params, 'data.meta') as AnyObject | undefined })

            return params
          })
          .catch((params) => {
            setState({ errors: params.errors, sourceOfErrors: sourceOfUpdate })
            return Promise.reject(params)
          })
      }

    return {
      isUpdating,
      sourceOfUpdate,
      isSuccess,
      errors,
      meta,
      updateEntity
    }
  }

export default useUpdateEntity
