import * as React from 'react'
import styled from 'styled-components'
import { useLearnerStepContext } from '~/hooks'
import { CheckmarkIcon } from '~/components/atoms'
import { Blurb, BlurbBuilder } from '~/components/molecules'
import { ExperimentHelpers } from '~/features'
import getDownloadOptions from './helpers/getDownloadOptions'
import DownloadsTable from './DownloadsTable'

import type { DownloadsWorksheet } from '~/features'

const
  Outer = styled.div`
    flex: 1 0 ${p => p.theme.emMaxReadingWidth}em;
    display: flex;
    flex-direction: column;
  `,
  BottomBlurb = styled(Blurb)`
    margin-top: auto;
  `,
  DownloadsStep: React.FC = () => {
    const
      stepContext = useLearnerStepContext(),
      { experiment, worksheets, responses, setMascotState } = stepContext,
      worksheet = stepContext.worksheet as DownloadsWorksheet,
      availableDownloads = getDownloadOptions(worksheets, responses)

    React.useEffect(() => {
      setMascotState({
        mascotVariant: worksheet.mascotVariant,
        hintText: worksheet.pageHint
      })
    }, [worksheet.mascotVariant, worksheet.pageHint, setMascotState])

    return <Outer>
      {worksheet.leader
        ? <BlurbBuilder {...worksheet.leader} noWrapper />
        : null}
      <DownloadsTable
        {...{
          experiment,
          responses,
          worksheets,
          availableDownloads
        }}
      />
      <BottomBlurb
        content={ExperimentHelpers.getDynamicString(experiment, 'nothingToTurnIn')}
        icon={<CheckmarkIcon />}
        // withBackground
      />
    </Outer>
  }

export default DownloadsStep
export { BottomBlurb }
