import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DOCUMENT_TITLES, HEADER_TEXTS } from '~/utils/strings'
import { isAllEmpty } from '~/utils/testers'
import { useMagicLink } from '~/hooks'
import { ContactUsMessage, ErrorPage, LeadIn, Li, Ul } from '~/components/pages/NotFoundPage'
import { BrandedPage } from '~/components/layouts'

const
  ProjectAccessHandler: React.FC = () => {
    const
      navigate = useNavigate(),
      { token } = useParams(),
      { redeemToken, isSuccess, errors, isInitializing, isProcessing } = useMagicLink('learner'),
      hasError = !isAllEmpty(errors)

    React.useEffect(() => {
      if (isSuccess) {
        navigate('/project-home')
      }
    }, [navigate, isSuccess])

    React.useEffect(() => {
      if (token && !isInitializing && !hasError) {
        redeemToken(token)
      }
    }, [isInitializing, hasError, redeemToken, token])

    if (!!isInitializing || !!isProcessing || isSuccess) {
      return (
        <BrandedPage
          documentTitle={DOCUMENT_TITLES.loading}
          headerText={HEADER_TEXTS.teaching}
          whichHome="teaching"
          showLoader
        />
      )
    } else {
      return <ErrorPage errorTitle="Account Not Found" message="Unable to Verify Access Key">
        <LeadIn>What next?</LeadIn>
        <Ul>
          <Li>Check the web address for typos</Li>
          <Li>Contact your teacher for an up-to-date access link</Li>
          <Li><ContactUsMessage question="Still having issues?" /></Li>
        </Ul>
      </ErrorPage>
    }
  }

export default ProjectAccessHandler
