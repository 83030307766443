import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Irrigation timing scheme',
  description = 'How will the timing of irrigation be determined: will it be on a schedule, based on an observation, or something else?. When this is on its own, it will be applied to all subjects. When part of a treatment, it will be applied to subjects receiving that treatment.',
  SCHEDULED_IRRIGATION_TIMING_CONDITION_MATCH = 'schedule',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.irrigationTimingCondition,
    fieldType: 'STRING',
    options: [
      { optionValue: `on a ${SCHEDULED_IRRIGATION_TIMING_CONDITION_MATCH}` },
      { optionValue: 'if soil is dry' },
      { optionValue: 'if plants are wilting' }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.irrigationTimingCondition,
    variableGroup: SystemFieldGroups.CONTROL_VARIABLES,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails,
  SCHEDULED_IRRIGATION_TIMING_CONDITION_MATCH
}
