import { debounce } from 'lodash'
import React from 'react'

const
  debouncedCountDown = debounce((
    setWiggle: () => void,
    clearTimeouts: () => void,
    setReturnBool: React.Dispatch<React.SetStateAction<boolean>>,
    countdownRef: React.MutableRefObject<NodeJS.Timeout | undefined>,
    delay?: number
  ) => {
    clearTimeouts()
    countdownRef.current = setTimeout(setWiggle, delay ?? 30000)
    setReturnBool(false)
  }, 300),
  useCountdownToTrue = (delay?: number, eventName?: keyof DocumentEventMap): [boolean, () => void] => {
    const
      [returnBool, setReturnBool] = React.useState(false),
      countdownRef = React.useRef<ReturnType<typeof setTimeout>>(),
      clearTimeouts = React.useCallback(() => {
        if (countdownRef.current) { clearTimeout(countdownRef.current) }
        countdownRef.current = undefined
      }, [countdownRef]),
      setWiggle = React.useCallback(() => {
        clearTimeouts()
        setReturnBool(true)
      }, [clearTimeouts, setReturnBool]),
      countDown = React.useCallback(() => {
        debouncedCountDown(setWiggle, clearTimeouts, setReturnBool, countdownRef, delay)
      }, [clearTimeouts, delay, setWiggle])

    React.useEffect(() => {
      document.addEventListener(eventName ?? 'mouseup', countDown)

      return () => {
        document.removeEventListener(eventName ?? 'mouseup', countDown)
        clearTimeouts()
      }
    }, [clearTimeouts, countDown, eventName])

    return [
      returnBool,
      clearTimeouts
    ]
  }

export default useCountdownToTrue
