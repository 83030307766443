import * as React from 'react'
import styled from 'styled-components'
import { WorksheetHelpers, WorksheetResponseHelpers } from '~/features'
import { CheckmarkIcon, Icon, BasicTriangle } from '~/components/atoms'
import CardHeader from '~/components/molecules/CardHeader'

import type { Experiment, Learner, Worksheet, WorksheetResponse, WorksheetResponseStatus } from '~/features'

interface Props {
  statusForTeacher?: WorksheetResponseStatus
  response: WorksheetResponse
  learners: Learner[]
  worksheets: Worksheet[]
  experiment?: Experiment
}

const
  StyledCard = styled(CardHeader)`
    margin-bottom: 1em;
    box-shadow: 1px 1.5px 4px 0px ${p => p.theme.colors.shadow};
  `,
  ResponseStatusIcon: React.FC<{
    response: WorksheetResponse
    experiment?: Experiment
    worksheet?: Worksheet
    statusForTeacher?: WorksheetResponseStatus
    allWorksheets: Worksheet[]
  }> = ({ experiment, worksheet, statusForTeacher }) => {
    let
      color: 'forward' | 'attention' | 'disabled' = 'disabled',
      responseRequired: boolean = false,
      complete: boolean = false,
      description: string = 'Step has been started' // if there is a response, we can at least conclude that the step has been started

    if (!experiment || !worksheet) {
      // no op
    } else if (worksheet.format === 'DOWNLOADS') {
      color = 'forward'
    } else if (worksheet.format === 'HUB') {
      if (!statusForTeacher) {
        // Hub has been visited but none of its steps have been started
      } else if (statusForTeacher === 'approved') {
        // Hub with all steps approved
        color = 'forward'
        description = 'All nested step(s) were approved'
        complete = true
      } else {
        // Hub has been started and its steps have a mix of statuses
        color = 'forward'
      }
    } else if (statusForTeacher === 'approved') {
      complete = true
      description = 'Response was approved'
    } else if (statusForTeacher === 'studentRequestsReopen') {
      responseRequired = true
      color = 'attention'
      description = 'Learner requested to make changes'
    } else if (statusForTeacher === 'submitted') {
      responseRequired = true
      color = experiment.progressBasis === 'approval'
        ? 'attention'
        : 'forward'
      description = 'Response is ready to review'
    } else {
      color = WorksheetHelpers.getDoesWorksheetRequireResponse(worksheet)
        ? 'disabled'
        : 'forward'
      description = statusForTeacher === 'revising'
        ? 'Response is being revised'
        : experiment.progressBasis === 'approval'
          ? 'Response is in progress'
          : 'Step has been started'
    }

    return complete
      ? <CheckmarkIcon accessibleDescription={description} />
      : <Icon
        color={color}
        frameStyle="circle"
        content={responseRequired ? '?' : BasicTriangle}
        degreesRotation={responseRequired ? 0 : 90}
        accessibleDescription={description}
      />
  },
  ResponseRow: React.FC<Props> = ({ response, statusForTeacher, worksheets, experiment, learners }) => {
    const
      step = worksheets?.find(worksheet => (
        worksheet.id === response.worksheetId
      )),
      skipSubmission = !WorksheetHelpers.getDoesWorksheetRequireResponse(step),
      stepName = step?.name ?? 'Loading...',
      parentStep = worksheets.find(o => o.id === step?.parentWorksheetId),
      author = response.learnerId
        ? learners?.find(learner => (
          learner.id === response.learnerId
        ))
        : undefined,
      authorName = author
        ? author.displayId ?? 'Loading...'
        : 'Whole Group'

    /** TODO DEV
     * At this point, the status system could use
     * a major overhaul.
     *
     * The cases are slightly different in each of
     * the following different contexts (and possibly others):
     *  - When determining step availability
     *  - When determining the StepSign parameters on learner home
     *  - When determining the pooled status on the ProjectList
     *  - When determining what should appear here in the list of responses
     *
     * As a result, there are now more exceptions than straight cases
     * and a comprehensive system is needed to ensure uniform and accurate
     * status representation throughout the applicaiton.
     */
    return (
      <StyledCard
        key={response.id}
        icon={
          <ResponseStatusIcon
            {...{ experiment, response, statusForTeacher }}
            worksheet={step}
            allWorksheets={worksheets}
          />
        }
        heading={parentStep ? `${parentStep.name}: ${stepName}` : stepName}
        subheading={authorName}
        content={
          skipSubmission
            ? '(This step does not need to be submitted)'
            : statusForTeacher
              ? WorksheetResponseHelpers.statusLabelByTeacherStatus[statusForTeacher]
              : 'Status unknown'
        }
        linkProps={{ to: `/responses/${response.id}` }}
        accessibleDescription={`View response for ${stepName} by ${authorName}`}
      />
    )
  }

export default ResponseRow
