import * as React from 'react'
import styled from 'styled-components'
import { Outlet, useMatches, useParams } from 'react-router-dom'
import { DOCUMENT_TITLES, HEADER_TEXTS } from '~/utils/strings'
import { ExperimentHelpers } from '~/features'
import { useAppSelector, useEntityData } from '~/hooks'
import { ErrorPage, Ul, Li, LeadIn, ContactUsMessage } from '~/components/pages/NotFoundPage'
import { BrandedPage, FlexibleMain } from '~/components/layouts'
import { TabGroup, ErrorMessage } from '~/components/molecules'
import { Button, Tab, HeadingOne, Link, SubheadingOne, SizedLoadingIcon, SettingsContentWrapper } from '~/components/atoms'
import { selectExperimentById } from '~/features/entity/selectors'

const
  Main = styled(FlexibleMain)`
    min-height: 500px;
  `,
  TeacherProjectPage: React.FC = () => {
    let content
    const
      { id } = useParams(),
      matches = useMatches(),
      { dataStatus, isLoading } = useEntityData(),
      experiment = useAppSelector(selectExperimentById(id))

    if (dataStatus === 'initializing' || dataStatus === 'uninitialized') {
      return <BrandedPage documentTitle={DOCUMENT_TITLES.loading} headerText={HEADER_TEXTS.teaching} whichHome="teaching" showLoader />
    } else if (!experiment && dataStatus !== 'error') {
      return <ErrorPage errorTitle="Group Not Found" message="Group Not Found">
        <LeadIn>What happened?</LeadIn>
        <Ul>
          <Li>Check the web address for typos</Li>
          <Li>The group you are looking for may have been deleted</Li>
          <Li>You may not have access to this group</Li>
        </Ul>

        <LeadIn>Return to <Link to="/projects">All Groups</Link></LeadIn>
        <ContactUsMessage />
      </ErrorPage>
    }

    const
      experimentName = experiment ? experiment.name : undefined,
      timingString = experiment ? ExperimentHelpers.getTimingString(experiment) : undefined,
      isSettings = matches.some(({ pathname }) => pathname.match(/settings/)),
      isLabels = matches.some(({ pathname }) => pathname.match(/labels/)),
      isLearners = matches.some(({ pathname }) => pathname.match(/learners/)),
      isResponses = matches.some(({ pathname }) => pathname.match(/responses/))

    if (dataStatus === 'error') {
      content = <ErrorMessage>
        There was a problem retrieving the group. Please try again later and contact support if the problem persists.
      </ErrorMessage>
    } else if (isLoading) {
      content = <SizedLoadingIcon />
    } else {
      content = <Outlet />
    }

    return (
      <BrandedPage
        documentTitle={`${experimentName as string} | AgConnect`}
        whichHome="teaching"
        headerText={HEADER_TEXTS.teaching}>
        <Main
          heading={<HeadingOne>{experimentName}</HeadingOne>}
          subheading={<SubheadingOne>{timingString}</SubheadingOne>}
          topActions={<Button label="All Groups" withIcon="arrowL" color="back" size="medium" linkProps={{ to: '/projects' }} />}
          >
          <TabGroup>
            <Tab
              label="Responses"
              tabRole={isResponses ? 'active' : 'inactive'}
              {...(isResponses
                ? {}
                : { linkProps: { to: `/projects/${id as string}/responses` } })}
            />
            <Tab
              label="Learners"
              tabRole={isLearners ? 'active' : 'inactive'}
              {...(isLearners
                ? {}
                : { linkProps: { to: `/projects/${id as string}/learners` } })}
            />
            <Tab
              label="Settings"
              tabRole={isSettings ? 'active' : 'inactive'}
              {...(isSettings
                ? {}
                : { linkProps: { to: `/projects/${id as string}/settings` } })}
            />
            <Tab
              label="Labels"
              tabRole={isLabels ? 'active' : 'inactive'}
              {...(isLabels
                ? {}
                : { linkProps: { to: `/projects/${id as string}/labels` } })}
            />
          </TabGroup>
          <SettingsContentWrapper>
            {content}
          </SettingsContentWrapper>
        </Main>
      </BrandedPage>
    )
  }

export default TeacherProjectPage
