import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { useFormVitalsSubscription } from '~/hooks'
import { ArrowStraight, FormLabel, HintText } from '~/components/atoms'
import StepSettingsForm from './StepSettingsForm'

import type { ListWorksheet, PagedWorksheet, Experiment, Worksheet, AnyWorksheet } from '~/features'
import FormatWizard from '../QuestionnaireManagement/FormatWizard'

interface Props {
  worksheet: AnyWorksheet
  experiment: Experiment
  allWorksheets: Worksheet[]
}

const
  InactiveRow = styled.div<{ $disabled?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${p => p.theme.emBaseSpacing}em;

    background: ${p => p.theme.colors.containerAccentBg};
    padding: 1em;

    opacity: ${p => p.$disabled ? '0.5' : '1'};
    cursor: ${p => p.$disabled ? 'not-allowed' : 'pointer'};

    &:not(&:first-child) {
      margin-top: ${p => p.theme.emBaseSpacing}em;
    }

    &:first-child {
      margin-bottom: ${p => p.theme.emSmallSpacing}em;
    }
  `,
  ForwardArrow = styled(ArrowStraight)`
    height: 1.2rem;
  `,
  RowDescriptor = styled(HintText)`
    margin-left: auto;
  `,
  StepSettings: React.FC<Props> = ({ worksheet, experiment, allWorksheets }) => {
    const
      THEME = useTheme(),
      { formStatus, subscribeToFormStatus } = useFormVitalsSubscription(),
      hasFormat = ['PAGED', 'LIST'].includes(worksheet.format),
      [activeSection, setActiveSection] = React.useState<'settings' | 'format'>(hasFormat ? 'format' : 'settings'),
      formIsClean = !formStatus || formStatus === 'clean' || formStatus === 'cleanAfterSuccess'

    return <>
      {hasFormat
        ? activeSection === 'format'
          ? <FormatWizard
            questionnaire={worksheet as PagedWorksheet | ListWorksheet}
            allQuestionnaires={allWorksheets}
            questionnaireGroup={experiment}
            {...{ formStatus, subscribeToFormStatus }}
          />
          : <InactiveRow onClick={formIsClean ? () => { setActiveSection('format') } : undefined} $disabled={!formIsClean}>
            <FormLabel
              label="Step Format"
              showLabel
              asHeading
            />
            {formIsClean
              ? null
              : <HintText color="default">Save changes to settings first</HintText>}
            <RowDescriptor color="default">Click here to edit the content of the step</RowDescriptor>
            {formIsClean
              ? <ForwardArrow direction="right" color={THEME.colors.back} />
              : null}
          </InactiveRow>
        : null}
      {activeSection === 'settings'
        ? <StepSettingsForm {...{ worksheet, experiment, formStatus, subscribeToFormStatus, allWorksheets }} />
        : <InactiveRow onClick={formIsClean ? () => { setActiveSection('settings') } : undefined} $disabled={!formIsClean}>
          <FormLabel
            label="Step Settings"
            showLabel
            asHeading
          />
          {formIsClean
            ? null
            : <HintText color="default">Save changes to format first</HintText>}
          <RowDescriptor color="default">Click here for general step settings</RowDescriptor>
          {formIsClean
            ? <ForwardArrow direction="right" color={THEME.colors.back} />
            : null}
        </InactiveRow>}
    </>
  }

export default StepSettings
