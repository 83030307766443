import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import sessionReducer from '~/features/session/sessionSlice'
import entityReducer from '~/features/entity/entitySlice'
import adminReducer from '~/features/admin/adminSlice'

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    entity: entityReducer,
    admin: adminReducer
  },
  middleware: (getDefaultMiddleware) => (
    process.env.NODE_ENV !== 'production'
      ? getDefaultMiddleware().concat(logger)
      : getDefaultMiddleware()
  )
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// eslint-disable-next-line @typescript-eslint/unbound-method
export const getState = store.getState
