import { isEqual, uniqWith } from 'lodash'
import { ELEMENT_TYPES } from '~/utils/strings'
import { SPECIAL_CONTENT_ID_LIST } from '~/features/worksheet/optionLists'
import { SYSTEM_FIELDS, SystemFieldNames } from '../experiment/systemFields'
import { isInputElement } from './types'

import type { AnyPlainElement, AnyInputElement, SpecialElementId, AnyPlainOrInputElement, InputElementVariety, AnyQuestionnaire, ScopeElement, CollectionElement } from './types'
import type { FieldName } from '~/types/utilities'
import type { SystemFieldName } from '../experiment/systemFields'

interface FieldDescriptor {
  fieldName: FieldName
  description?: string
  inputVariety?: InputElementVariety
  element: AnyInputElement | ScopeElement | CollectionElement
}

const
  getBodyElementDescriptor = (element: AnyPlainElement | AnyInputElement): string => {
    if (element.elementVariety === 'SPECIAL') {
      const item = SPECIAL_CONTENT_ID_LIST.find(o => (o.optionValue as SpecialElementId) === element.specialElementId)

      return item?.optionLabel ?? 'Special Eleent'
    }

    switch (element.elementVariety) {
      case 'BLURB':
        return `Blurb${element.title ? `: ${element.title}` : ''}`
      case 'CHECKBOX_INPUT':
        return element.label ?? 'Checkbox'
      case 'COLLAPSIBLE':
        return element.expandButtonLabel ?? 'Collapsible Item'
      case 'COLLECTION':
        return element.label ?? element.entryNamePlural ?? 'Item List'
      case 'COLOR_PICKER':
        return element.label ?? 'Icon Color Picker'
      case 'DATE_INPUT':
        return element.label ?? 'Date Picker'
      case 'FILL_IN_THE_BLANK':
        return element.label ?? 'Fill-In-The-Blank'
      case 'HEADING':
        return element.text ?? 'Heading'
      case 'INSTRUCTIONS':
        return element.paragraphs?.[0] ?? 'Instructions'
      case 'LONG_ANSWER':
        return element.label ?? 'Long Answer'
      case 'MULTI_SELECT_INPUT':
        return element.label ?? 'Multi-Select Picker'
      case 'MULTI_TEXT_INPUT':
        return element.label ?? 'Text List'
      case 'NUMBER_INPUT':
        return element.label ?? 'Number Question'
      case 'PARAGRAPHS':
        return element.label ?? 'Long Answer'
      case 'SCOPE':
        return element.label ?? 'Question Group'
      case 'SELECT_INPUT':
        return element.label ?? 'Dropdown'
      case 'SHORT_ANSWER':
        return element.label ?? 'Short Answer'
      case 'SUBHEADING':
        return element.text ?? 'Sub-heading'
      case 'TABLE':
        return element.label ?? 'Table'
      case 'TEXT':
        return element.text ?? 'Text'
      case 'TEXT_INPUT':
        return element.label ?? 'Question'
      case 'VALUE':
        return element.label ?? 'Value'
      case 'FIELDSET':
        return ELEMENT_TYPES.FIELDSET
      // Not included
      // case 'CLOSER_SECTION':
      // case 'LIST_CLOSER_SECTION':
      // case 'LIST_ENTRY_SECTION':
      // case 'LIST_OPENER_SECTION':
      // case 'OPENER_SECTION':
      // case 'PAGE':
      // case 'SECTION':
      default:
        return 'Unkown Item'
    }
  },
  allFieldsFromFormBody = (body: AnyPlainOrInputElement[], includeNested?: boolean): FieldDescriptor[] => {
    return uniqWith(
      body.reduce<FieldDescriptor[]>((memo, bodyElement) => {
        if (isInputElement(bodyElement)) {
          const relatedSystemField = bodyElement.fieldName in SYSTEM_FIELDS
            ? SYSTEM_FIELDS[bodyElement.fieldName as SystemFieldName]
            : undefined

          return [
            ...memo,
            {
              fieldName: bodyElement.fieldName,
              description: bodyElement.label ?? relatedSystemField?.title,
              inputVariety: bodyElement.elementVariety,
              element: bodyElement
            }
          ]
        } else if (bodyElement.elementVariety === 'FILL_IN_THE_BLANK') {
          const fillInDescription = bodyElement.label
            ? `"${bodyElement.label}" fill in the blank`
            : 'fill in the blank'

          return [
            ...memo,
            ...allFieldsFromFormBody(bodyElement.segments).map(o => ({
              ...o,
              description: o.description ?? `${o.fieldName} (from ${fillInDescription})`
            }))
          ]
        } else if (bodyElement.elementVariety === 'FIELDSET' || bodyElement.elementVariety === 'COLLAPSIBLE') {
          return [
            ...memo,
            ...allFieldsFromFormBody(bodyElement.body)
          ]
        }

        /** TODO add nested? (collections, scopes, etc) */

        return memo
      }, []),
      isEqual
    )
  },
  allFieldsFromQuestionnaire = (questionnaire: AnyQuestionnaire): FieldDescriptor[] => {
    return questionnaire.format === 'PAGED'
      ? questionnaire.sections.flatMap(section => section.pages.flatMap(page => allFieldsFromFormBody(page.body, true)))
      : questionnaire.format === 'LIST'
        ? [
            questionnaire.openingSection,
            ...questionnaire.repeatingSections || [],
            questionnaire.closingSection
          ].flatMap(section => section ? allFieldsFromFormBody(section.body, true) : [])
        : []
  },
  doesQuestionnaireHaveSystemFields = (questionnaire: AnyQuestionnaire): boolean => {
    const
      fields = allFieldsFromQuestionnaire(questionnaire),
      systemFieldNames = [...Object.values(SystemFieldNames)] as string[]

    return fields.some(o => systemFieldNames.includes(o.fieldName))
  }

export { getBodyElementDescriptor, allFieldsFromFormBody, allFieldsFromQuestionnaire, doesQuestionnaireHaveSystemFields }
