import React from 'react'
import styled from 'styled-components'
import { WorksheetHelpers } from '~/features'
import {
  Icon,
  BasicSquare,
  BasicTriangle,
  Tab
} from '~/components/atoms'
import { TabGroup, availableIcons } from '~/components/molecules'

import type { TabRole, TabIconColor } from '~/components/atoms'
import type { Worksheet, IconName, PagedWorksheet, PagedSectionElement } from '~/features'

interface Props {
  worksheet: Worksheet
  sectionIndex: number
  farthestReachedSectionIndex?: number
  readOnly?: boolean
  onTabClick?: (sectionId?: string) => void
}

type TabIconType = React.ComponentProps<typeof Tab>['IconComponent']

const
  SectionTab = styled(Tab)`
    @media(min-width: ${p => p.theme.pxFullScreenCutoffWidth}px) {
      padding-left: ${p => p.theme.emBaseSpacing}em;
      padding-right: ${p => p.theme.emBaseSpacing}em;

      [data-cy="icon"] {
        margin-left: -2px;
      }
    }
  `,
  TabIconBuilder = ({ section }: { section: PagedSectionElement }): TabIconType => {
    const TabIcon: TabIconType = ({ color }: { color?: TabIconColor }) => {
      return section.iconContent
        ? <Icon
            color={color ?? section.iconColor}
            content={availableIcons[section.iconContent as IconName] ?? section.iconContent}
          />
        : section.elementVariety === 'OPENER_SECTION'
          ? <Icon
              color={color ?? 'hero'}
              content={BasicTriangle}
              degreesRotation={90}
            />
          : section.elementVariety === 'CLOSER_SECTION'
            ? <Icon color={color ?? 'hero'} content={BasicSquare} />
            : null
    }

    return TabIcon
  },
  StepSectionTabs: React.FC<Props> = (props) => {
    const
      { sectionIndex, farthestReachedSectionIndex, readOnly, onTabClick } = props,
      worksheet = props.worksheet as PagedWorksheet,
      sections = worksheet.sections

    if (sections.length <= 1) {
      return null
    }

    return <TabGroup>
      {sections.map((section, index) => {
        const
          tabRole: TabRole = sectionIndex === index
            ? 'active'
            : !readOnly && (index > (farthestReachedSectionIndex ?? sectionIndex))
                ? 'disabled'
                : 'inactive',
          hasIcon = (
            !!(section.iconContent) ||
            section.elementVariety !== 'SECTION'
          ),
          iconComponent = hasIcon
            ? TabIconBuilder({ section })
            : undefined,
          linkProps = tabRole !== 'disabled' && !readOnly && !onTabClick
            ? { to: WorksheetHelpers.getPathForPage(worksheet.id, index, 0) }
            : undefined

        return (
          <SectionTab
            key={index}
            IconComponent={iconComponent}
            label={section.title ?? `Section ${index + 1}`}
            tabRole={tabRole}
            {...(
              onTabClick
                ? { onClick: () => { onTabClick(section.id) } }
                : linkProps
                  ? { linkProps }
                  : {})}
          />
        )
      })}
    </TabGroup>
  }

export default StepSectionTabs
