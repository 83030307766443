import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Fertilizer name or description',
  description = 'A label for the fertilizer that will be used. When this is on its own, it will be used for all subjects. When part of a treatment, it will be used for subjects receiving that treatment.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.fertilizerName,
    fieldType: 'STRING'
    // visibleWhen: ivEqualityCondition(variableName, exclude)
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.fertilizerName,
    variableGroup: SystemFieldGroups.CONTROL_VARIABLES,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
