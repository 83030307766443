import * as React from 'react'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import { DOCUMENT_TITLES, HEADER_TEXTS } from '~/utils/strings'
import { useMagicLink, useSession } from '~/hooks'
import { BrandedPage } from '~/components/layouts'
import { isAllEmpty } from '~/utils/testers'

const
  MagicLinkLoginPage: React.FC = () => {
    const
      navigate = useNavigate(),
      { token } = useParams(),
      { redeemToken, isSuccess, errors, isInitializing } = useMagicLink('teacher'), // or researcher
      { isTeacher, isAdmin } = useSession(),
      hasError = !isAllEmpty(errors)

    React.useEffect(() => {
      if (isSuccess) {
        isTeacher
          ? navigate('/projects', { replace: true })
          : isAdmin
            ? navigate('/account', { replace: true })
            : window.location.replace(`${window.location.origin}/r/access/${token}`) /** Force reload from server */
      }
    }, [navigate, isSuccess, isAdmin, isTeacher, token]) /** Last three irrelevant */

    React.useEffect(() => {
      if (hasError) {
        navigate({
          pathname: '/educate',
          search: `?${createSearchParams({ errors: errors as string[] }).toString()}`
        })
      }
    }, [navigate, hasError, errors])

    React.useEffect(() => {
      if (token && !isInitializing && !hasError) {
        redeemToken(token)
      }
    }, [isInitializing, hasError, redeemToken, token])

    return <>
      <BrandedPage
        documentTitle={DOCUMENT_TITLES.loading}
        whichHome="all"
        headerText={HEADER_TEXTS.public}
        showLoader
      />
    </>
  }

export default MagicLinkLoginPage
