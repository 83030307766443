import * as React from 'react'
import { isEqual } from 'lodash'
import styled from 'styled-components'
import { useLearnerStepContext } from '~/hooks'
import { WorksheetResponseHelpers } from '~/features'
import { getAdderStringFromRepeatingSections } from '~/features/worksheet/helpers'
import { getElegibleRepeatingSections } from './helpers'
import { BasicCross, Icon } from '~/components/atoms'
import { ActionPointer } from '~/components/molecules'
import ListEntryRow from './ListEntryRow'
import ListActions from './ListActions'

import type { ListEntry, ListResponse, ListWorksheet, WorksheetResponse, Experiment } from '~/features'
import type { ListEntrySummary, ListStepSelection } from './types'

interface Props {
  entrySummaries: ListEntrySummary[]
  setCurrentSelection: React.Dispatch<React.SetStateAction<ListStepSelection>> | ((el: ListStepSelection) => void)
  withInlineForm?: boolean
  currentSelection?: ListStepSelection
}

interface PresentationalProps extends Props {
  entries: ListEntry[]
  worksheet: ListWorksheet
  response: ListResponse
  responses: WorksheetResponse[]
  experiment: Experiment
}

const
  EntryListWrapper = styled.div`
    display: flex;
    flex-direction: column;
  `,
  Adder = styled(ActionPointer)`
    margin: 1em auto;
  `,
  ListStepEntryList: React.FC<Props> = ({ entrySummaries, withInlineForm, currentSelection, setCurrentSelection }) => {
    const
      stepContext = useLearnerStepContext(),
      entries = (stepContext.response.responses.entries as ListEntry[] | undefined) ?? [],
      worksheet = stepContext.worksheet as ListWorksheet,
      response = stepContext.response as ListResponse,
      responses = stepContext.responses,
      experiment = stepContext.experiment

    return <ListStepEntryListPresentational {...{
      entrySummaries,
      withInlineForm,
      currentSelection,
      setCurrentSelection,
      entries,
      worksheet,
      response,
      responses,
      experiment
    }} />
  },
  ListStepEntryListPresentational: React.FC<PresentationalProps> = (props) => {
    const
      { entries, worksheet, response, responses, experiment, entrySummaries, withInlineForm, setCurrentSelection, currentSelection } = props,
      [summaries, setSummaries] = React.useState(entrySummaries),
      elegibleRepeatingSections = getElegibleRepeatingSections(worksheet, entries),
      { wtStep } = WorksheetResponseHelpers.getActiveWalkthroughDetails(worksheet, response),
      hasActiveWalkthrough = !!wtStep,
      prevHasActiveWalkthrough = React.useRef<boolean>(hasActiveWalkthrough),
      rowBaseProps = {
        withInlineForm: !!withInlineForm,
        setCurrentSelection,
        currentSelection,
        otherEntities: WorksheetResponseHelpers.getEntitiesAcrossResponses(responses)
      },
      adderAtTop = worksheet.reverseStartAndEnd,
      adder = elegibleRepeatingSections.length > 0 && !currentSelection
        ? <Adder
            key="adder"
            label={getAdderStringFromRepeatingSections(elegibleRepeatingSections)}
            icon={
              <Icon
                content={BasicCross}
                color="back"
                frameStyle="circle"
                onClick={() => { setCurrentSelection({ segment: 'LIST_ENTRY_SECTION' }) }}
                accessibleDescription={getAdderStringFromRepeatingSections(elegibleRepeatingSections)}
            />}
            orientation="up"
          />
        : null

    React.useEffect(() => {
      setSummaries(entrySummaries)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelection])

    React.useEffect(() => {
      if (!currentSelection && !isEqual(summaries, entrySummaries)) {
        setSummaries(entrySummaries)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelection, JSON.stringify(entrySummaries)])

    React.useEffect(() => {
      if (prevHasActiveWalkthrough.current && !hasActiveWalkthrough) {
        setCurrentSelection(undefined)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasActiveWalkthrough])

    return <EntryListWrapper>
      {summaries.length === 0
        ? adder
        : null}
      {summaries.map((entrySummary, i) => {
        const
          isInitial = i === 0,
          isFinal = i === summaries.length - 1,
          isCloser = entrySummary.section?.elementVariety === 'LIST_CLOSER_SECTION',
          isOpener = entrySummary.section?.elementVariety === 'LIST_OPENER_SECTION',
          showAdderAbove = adderAtTop
            ? isInitial && !isOpener
            : isFinal && isCloser,
          showAdderBelow = adderAtTop
            ? isInitial && isOpener
            : isFinal && !isCloser

        return [
          showAdderAbove ? adder : null,
          <ListEntryRow
            key={entrySummary.actualIndex ?? `noindex-${i}`}
            {...rowBaseProps}
            hasActiveWalkthrough={hasActiveWalkthrough}
            entrySummary={entrySummary}
            experiment={experiment}
          />,
          showAdderBelow && !isCloser ? adder : null
        ]
      })}
      {hasActiveWalkthrough
        ? undefined
        : <ListActions {...{ entries, response, worksheet, experiment, currentSelection }} />}
    </EntryListWrapper>
  }

export default ListStepEntryList
export { EntryListWrapper, Adder }
