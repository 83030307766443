import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Timing of final measurement (if taking multiple measurements)',
  description = 'This allows learners to specify when they will stop taking measurements. This is used to build a schedule for the experiment and to calculate information like the total number of measurements that will be taken during the experiment.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.measurementEndValue,
    fieldType: 'NUMBER'
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.measurementEndValue,
    variableGroup: SystemFieldGroups.SCHEDULE,
    shouldFollow: [SystemFieldNames.measurementStartValue],
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
