import * as React from 'react'
import styled, { css } from 'styled-components'
import { Link, type LinkProps } from 'react-router-dom'
import { useClickOnKeyPress } from '~/hooks'
import { HintText } from '~/components/atoms'

interface CoreProps {
  icon?: React.ReactNode
  heading: string | React.ReactNode
  subheading?: string
  content?: string
  children?: React.ReactNode
  className?: string
  id?: string
}

interface OnClickProps {
  accessibleDescription: string
  onClick: React.MouseEventHandler<HTMLDivElement>
}

interface WithLinkProps {
  accessibleDescription: string
  linkProps: LinkProps
}

type CardHeaderProps = CoreProps | (CoreProps & OnClickProps) | (CoreProps & WithLinkProps)

const
  horizontalGap = 10,
  paddingMultiplier = 1.25,
  emBaseCardHeaderHeight = 3.07,
  getTopRowHeight = (emSmallSpacing: number): number => emBaseCardHeaderHeight - (emSmallSpacing * paddingMultiplier * 2),
  outerStyles = css`
    padding: ${p => p.theme.emSmallSpacing * paddingMultiplier}em;

    background: ${p => p.theme.colors.containerBg};
  `,
  CardHeaderOuter = styled.div`
    ${outerStyles}
    ${p => p.onClick
      ? `
        user-select: none;
        &:hover {
           cursor: pointer;
        }
      `
      : ''}
  `,
  CardHeaderOuterLink = styled(Link)`
    ${outerStyles}
    display: block;
    user-select: none;
    &:hover { cursor: pointer; }
  `,
  TopRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: ${p => getTopRowHeight(p.theme.emSmallSpacing)}em;
    gap: ${horizontalGap}px;
  `,
  IconWrapper = styled.div`
    flex: 0 0 ${p => p.theme.pxIconLarge}px; 
    height: ${p => p.theme.pxIconLarge}px;
  `,
  HeadingWrapper = styled.div`
    flex: 0 1 auto;
    min-width: 0px;
    flex-direction: column;
    align-items: flex-start;
  `,
  Heading = styled.div`
    font-size: 1rem; // 15px / 15px
    line-height: 1;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    color: ${p => p.theme.colors.headingText};
    ${p => p.theme.headingFont};
  `,
  Subheading = styled(Heading)`
    font-size: 0.7rem;
    margin-bottom: 4px;
  `,
  UpperHintText = styled(HintText)`
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    text-align: right;
    padding-right: 4px;
  `,
  LowerHintText = styled(HintText)`
    text-align: left;
    padding-left: ${p => p.theme.pxIconLarge + horizontalGap}px;
    margin-top: 2px;
    margin-bottom: ${p => p.theme.emSmallSpacing * paddingMultiplier * 0.8}em;
  `,
  CardHeader: React.FC<CardHeaderProps> = ({ icon, heading, subheading, content, children, className, id, ...otherProps }) => {
    const
      elementRef = useClickOnKeyPress(),
      isClickable = ('onClick' in otherProps) || ('linkProps' in otherProps),
      innerContent = <>
        <TopRow>
          {icon ? <IconWrapper color='blue'>{icon}</IconWrapper> : null}
          <HeadingWrapper>
            {heading && typeof heading !== 'string'
              ? heading
              : <>
                {subheading
                  ? <Subheading color="default" className="card-header__heading">
                      {subheading}
                    </Subheading>
                  : null}
                <Heading className="card-header__heading">{heading}</Heading>
              </>
            }
          </HeadingWrapper>
          {content ? <UpperHintText color="default">{content}</UpperHintText> : null}
        </TopRow>
        {children ? <LowerHintText color="default">{children}</LowerHintText> : null}
      </>

    if ('linkProps' in otherProps) {
      return (
        <CardHeaderOuterLink
          {...otherProps.linkProps}
          id={id}
          className={className}
          data-cy="card-header"
          aria-label={otherProps.accessibleDescription}>
          {innerContent}
        </CardHeaderOuterLink>
      )
    } else {
      return (
        <CardHeaderOuter
          id={id}
          ref={isClickable ? elementRef : undefined}
          tabIndex={isClickable ? 0 : -1}
          onClick={'onClick' in otherProps ? (otherProps.onClick as React.MouseEventHandler) : undefined}
          aria-label={'accessibleDescription' in otherProps ? (otherProps.accessibleDescription as string) : undefined}
          className={className}
          data-cy="card-header">
          {innerContent}
        </CardHeaderOuter>
      )
    }
  }

export { Heading as CardHeaderHeading, emBaseCardHeaderHeight }
export default CardHeader
