import * as React from 'react'
import styled from 'styled-components'

interface ModalOverlayProps {
  children: React.ReactNode
  wholePage?: boolean
  className?: string
}

interface ModalOverlayOuterProps {
  $wholePage?: boolean
}

const
  ModalOverlayOuter = styled.div<ModalOverlayOuterProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: ${p => p.$wholePage ? 'fixed' : 'absolute'};
    background: ${p => p.theme.colors.translucentDark};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${p => p.theme.zGlobalTop};
  `,
  ModalOverlay: React.FC<ModalOverlayProps> = ({ children, wholePage, className }) => {
    return <ModalOverlayOuter $wholePage={wholePage} className={className} data-cy="modal-overlay">
      {children}
    </ModalOverlayOuter>
  }

export default ModalOverlay
