import * as React from 'react'

interface Props {
  className?: string
}

const
  Button: React.FC<Props> = ({ className }) => {
    return (
      <svg className={className} preserveAspectRatio="none" viewBox="0 0 193 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-cy="button-background">
        <path d="M0.364574 14.9995C0.42966 6.71553 7.19794 0.0527621 15.482 0.117847L177.445 1.39034C185.729 1.45543 192.391 8.22371 192.326 16.5077L192.104 44.8488C192.039 53.0288 185.433 59.6489 177.253 59.7302L15.2679 61.3401C6.87957 61.4234 0.0533544 54.6114 0.11926 46.223L0.364574 14.9995Z" fill="#2EA66C"/>
      </svg>
    )
  }

export default Button
