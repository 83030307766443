import React from 'react'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { clear401Error } from '~/features/session/sessionSlice'
import { useAppDispatch, useSession } from '~/hooks'

const SessionWatcher: React.FC = () => {
  const
    { pathname } = useLocation(),
    prevPathname = React.useRef<string>(pathname),
    navigate = useNavigate(),
    dispatch = useAppDispatch(),
    { isSessionValid, hasSession, isLearner, isTeacher, isAdmin, isParticipant, isResearcher, received401ForSessionType } = useSession(),
    isOnPublicPage = !!pathname.match(/\/join\/?$/) || ['/', '/educate', '/request-account'].includes(pathname) || !!pathname.match(/^\/(project-)?access\//),
    isOnEduPage = pathname.match(/((projects)|(responses)|(template-sets)|(templates)|(project-))/ig)

  React.useEffect(() => {
    if (!isOnPublicPage && (!hasSession || !isSessionValid || received401ForSessionType)) {
      if (!!isLearner || received401ForSessionType === 'learner') {
        navigate({
          pathname: '/educate',
          search: `?${createSearchParams({ errors: ['Your session has expired. If you have your original login link, you can use that to get back in. Otherwise, ask your teacher for a new link.'] }).toString()}`
        })
      } else if (!!isTeacher || !!isAdmin || received401ForSessionType === 'teaching' || isOnEduPage) {
        navigate({
          pathname: '/educate',
          search: `?${createSearchParams({ errors: ['Your session has expired. Please log in again.'] }).toString()}`
        })
      } else if (!!isParticipant || !!isResearcher || received401ForSessionType === 'research') {
        navigate({
          pathname: '/participate',
          search: `?${createSearchParams({ errors: ['Your session has expired. Please log in again.'] }).toString()}`
        })
      } else {
        navigate({
          pathname: '/'
        })
      }
    }

    if (isOnPublicPage && received401ForSessionType) {
      dispatch(clear401Error())
    }
  }, [isSessionValid, hasSession, isOnPublicPage, isOnEduPage, received401ForSessionType, dispatch, navigate, isLearner, isTeacher, isAdmin, isParticipant, isResearcher])

  React.useEffect(() => {
    if (pathname !== prevPathname.current) {
      window.scrollTo(0, 0)
    }
    prevPathname.current = pathname
  }, [pathname])

  return null
}

export default SessionWatcher
