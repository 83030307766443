import { DateTime } from 'luxon'
import { floatString } from '~/utils/formatters'
import { isEmptyValue } from '~/utils/testers'
import type { SpecialSectionId } from '~/features'

export interface SavePointTriggerProps {
  doSave: () => void
  isLoading: boolean
  error?: string
  warning?: string
}

export interface FileBuilderFn {
  (section: SavePointDataSection[], title: string | undefined): Promise<null | Blob>
  (section: SavePointDataSection[], title: string | undefined, stringOnly: true): Promise<null | string | Uint8Array>
}

export type SavePointFileFormat = 'csv' | 'docx' | 'png'
export type SavePointValue = string | number | typeof DateTime

export type SavePointRow = Record<string, SavePointValue> | SavePointValue[]

export interface SavePointDataSection {
  data: SavePointRow[]
  headers?: Array<[string, SavePointValue]>
  _section?: string
  specialSectionId?: SpecialSectionId
}

export interface SavePointFileContent {
  format: SavePointFileFormat
  sections: SavePointDataSection[]
  filename?: string
}

interface SavePointZipContent {
  format: 'zip'
  files: SavePointFileContent[]
  filename?: string
}

export type SavePointContent = SavePointFileContent | SavePointZipContent

export const
  stringifyValue = (v: SavePointValue, fileFormat: SavePointFileFormat): string => {
    const stringVal = DateTime.isDateTime(v)
      ? v.toFormat('MM/DD/YYYY')
      : typeof v === 'string'
        ? v
        : Array.isArray(v)
          ? v.map(el => stringifyValue(el, fileFormat)).join(', ')
          : typeof v === 'number'
            ? floatString(v) as string
            : ''

    return fileFormat === 'csv' && !isEmptyValue(stringVal) && !Array.isArray(v)
      ? `"${stringVal}"`
      : stringVal
  }
