import React from 'react'
import { map } from 'lodash'
import { baseUrl } from '~/services/api'
import useSession from './useSession'

import type { APIEntity } from 'cypress/support/types'
import type { EntityErrors, ResultObject } from '~/form-brain2'
import type { User } from '~/features'

interface UseAdminUserSearch {
  users?: User[]
  searchForUsers: (emailSearchTerm: string) => Promise<ResultObject>
  isSearching?: boolean
  errors?: EntityErrors
}

const
  useAdminUserSearch = (): UseAdminUserSearch => {
    const
      { activeToken, makeRequestWithSession } = useSession(),
      [state, setState] = React.useState<Partial<Pick<UseAdminUserSearch, 'users' | 'isSearching' | 'errors'>>>({}),
      { users, isSearching, errors } = state,
      searchForUsers: UseAdminUserSearch['searchForUsers'] = (emailSearchTerm) => {
        setState({ isSearching: true })

        return makeRequestWithSession({
          method: 'get',
          url: `${baseUrl}users?email=${emailSearchTerm}`,
          headers: {
            Authorization: `Bearer ${activeToken as string}`
          }
        })
          .then(({ data }) => {
            const entities = data?.data as Array<APIEntity<User>> | undefined

            setState({
              isSearching: false,
              users: map(entities ?? [], 'attributes')
            })

            return {}
          })
          .catch(({ errors }) => {
            setState({
              isSearching: false,
              errors
            })

            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({ errors })
          })
      }

    return {
      users,
      searchForUsers,
      isSearching: !!isSearching,
      errors
    }
  }

export default useAdminUserSearch
