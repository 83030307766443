import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField, VisibilityCondition } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  dvMatches = (dvToMatch: string): VisibilityCondition[] => [{
    valueName: SystemFieldNames.dependentVariable,
    conditionType: 'matches',
    testValue: dvToMatch
  }],
  title = 'Measurment type (what)',
  description = 'What expression of the dependent variable will be measured? This choice does not change anything about how the experient works. It is used to give instructions and label data recording forms.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.measurementType,
    fieldType: 'STRING',
    options: [
      {
        optionValue: 'weight',
        visibleWhen: dvMatches('yield')
      },
      {
        optionValue: 'count',
        visibleWhen: dvMatches('yield')
      },
      {
        optionValue: 'number of leaves',
        visibleWhen: dvMatches('growth')
      },
      {
        optionValue: 'height',
        visibleWhen: dvMatches('growth')
      },
      {
        optionValue: 'amount of cover',
        visibleWhen: dvMatches('growth')
      },
      {
        optionValue: 'soil moisture meter reading',
        visibleWhen: dvMatches('soil')
      },
      {
        optionValue: 'soil moisture level by eye',
        visibleWhen: dvMatches('soil')
      },
      {
        optionValue: 'soil moisture level by touch',
        visibleWhen: dvMatches('soil')
      },
      {
        optionValue: 'soil temperature',
        visibleWhen: dvMatches('soil')
      },
      {
        optionValue: 'soil pH',
        visibleWhen: dvMatches('soil')
      },
      {
        optionValue: 'soil CEC',
        visibleWhen: dvMatches('soil')
      },
      {
        optionValue: 'soil infiltration rate',
        visibleWhen: dvMatches('soil')
      },
      {
        optionValue: 'soil organic matter',
        visibleWhen: dvMatches('soil')
      }
      // TODO consider adding soil nutrients
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.measurementType,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    isRequired: false,
    includeOtherByDefault: true,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
