import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Expected end (days after start of experiment)',
  description = 'This is used to build a schedule for the experiment.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.endDay,
    fieldType: 'NUMBER'
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.endDay,
    variableGroup: SystemFieldGroups.SCHEDULE,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
