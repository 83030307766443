import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { ArrowStraight, ArrowCurved, HintText } from '~/components/atoms'

export type ActionPointerOrientation = 'up' | 'down' | 'left' | 'right'

interface ActionPointerProps {
  label: string
  color?: React.ComponentProps<typeof HintText>['color']
  icon?: React.ReactNode
  orientation?: ActionPointerOrientation
  className?: string
}

interface ImageProps {
  $variantString: VariantString
}

interface TextProps {
  $variantString: VariantString
  $contentString: string
}

type VariantString = 'upWithIcon' | 'upWithoutIcon' | 'downWithIcon' | 'downWithoutIcon'

const
  emLineHeight = 1.1,
  outerDimensionLookup: Record<VariantString, string> = {
    upWithIcon: 'min-width: 140px; height: 85px;',
    upWithoutIcon: 'min-width: 140px; height: 75px;',
    downWithIcon: 'min-width: 140px; height: 90px;',
    downWithoutIcon: 'min-width: 140px; height: 75px;'
  },
  ActionPointerOuter = styled.div<{ $variantString: VariantString }>`
    position: relative;
    ${p => outerDimensionLookup[p.$variantString]}
  `,
  HorizontalPointerOuter = styled.div<{ $orientation: 'left' | 'right' }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    > :last-child {
      ${p => p.$orientation === 'left' ? 'margin-right: auto;' : null}
    }

    > :first-child {
      ${p => p.$orientation === 'right' ? 'margin-left: auto;' : null}
    }
  `,
  Icon = styled.div<ImageProps>`
    width: 50px;
    position: absolute;
    ${p => p.$variantString.includes('up')
      ? 'top: 0px; left: 0px;'
      : 'bottom: 0px; right: 0px;'}
  `,
  HorizontalIcon = styled.div`
    width: 1.5em;
  `,
  arrowRotationLookup: Record<VariantString, string> = {
    upWithIcon: 'rotate(-121deg)',
    upWithoutIcon: 'rotate(70.85deg)',
    downWithIcon: 'rotate(86deg)',
    downWithoutIcon: 'rotate(-110.85deg)'
  },
  arrowPositionLookup: Record<VariantString, string> = {
    upWithIcon: 'top: 30px; left: 55px;',
    upWithoutIcon: 'top: 0px; right: 18px;',
    downWithIcon: 'bottom: 40px; right: 45px;',
    downWithoutIcon: 'bottom: 0px; left: 18px;'
  },
  Arrow = styled(ArrowCurved)<ImageProps>`
    position: absolute;
    height: 61.5px;
    width: 35.5px;

    transform: ${p => arrowRotationLookup[p.$variantString]};
    ${p => arrowPositionLookup[p.$variantString]}
  `,
  HorizontalArrow = styled(ArrowStraight)<{ $hasIcon: boolean }>`
    height: 1.25em;
    transform: rotate(${p => p.direction === 'left' ? 3 : -3}deg);
    margin-left: ${p => p.direction === 'left' ? (p.$hasIcon ? 10 : 0) : 5}px;
    margin-right: ${p => p.direction === 'left' ? 5 : (p.$hasIcon ? 10 : 0)}px;
  `,
  hintTextSpacingLookup: Record<VariantString, string> = {
    upWithIcon: 'margin-top: 10px; margin-left: 70px;',
    upWithoutIcon: 'margin-top: 28px; margin-right: 55px;',
    downWithIcon: 'margin-top: 18px; margin-right: 55px;',
    downWithoutIcon: 'margin-bottom: 28px; margin-left: 65px;'
  },
  Text = styled(HintText)<TextProps>`
    height: ${emLineHeight * 2}em;
    width: ${p => p.$contentString.length / (p.theme.accentFont.includes('Patrick') ? 4.15 : 3.4)}em;

    line-height: ${emLineHeight}em;
    white-space: wrap;
    
    ${p => hintTextSpacingLookup[p.$variantString]}
  `,
  HorizontalText = styled(HintText)`
    flex: 0 0 auto;
    min-width: 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
  ActionPointer: React.FC<ActionPointerProps> = ({ label, icon, color, orientation = 'up', className }) => {
    const
      THEME = useTheme(),
      arrowColor = color === 'accent' ? THEME.colors.bodyAccentText : THEME.colors.understateLight

    if (['left', 'right'].includes(orientation)) {
      return <HorizontalPointerOuter className={className} $orientation={(orientation as 'left' | 'right')} data-cy="action-pointer">
        {orientation === 'left'
          ? <>
            {icon ? <HorizontalIcon>{icon}</HorizontalIcon> : null}
            <HorizontalArrow direction="left" color={arrowColor} $hasIcon={!!icon} />
          </>
          : null}
        <HorizontalText color={color ?? 'default'}>{label}</HorizontalText>
        {orientation === 'right'
          ? <>
            <HorizontalArrow direction="right" color={arrowColor} $hasIcon={!!icon} />
            {icon ? <HorizontalIcon>{icon}</HorizontalIcon> : null}
          </>
          : null}
      </HorizontalPointerOuter>
    }

    const
      isUp = orientation === 'up',
      withIcon = !!icon,
      arrowDirection = withIcon ? 'clockwise' : 'counterClockwise',
      lookupKey = isUp
        ? (withIcon ? 'upWithIcon' : 'upWithoutIcon')
        : (withIcon ? 'downWithIcon' : 'downWithoutIcon')

    return <ActionPointerOuter className={className} $variantString={lookupKey} data-cy="action-pointer">
      <Arrow
        direction={arrowDirection}
        color={arrowColor}
        $variantString={lookupKey}
      />
      <Text
        color={color ?? 'default'}
        $contentString={label}
        $variantString={lookupKey}
      >
        {label}
      </Text>
      <Icon $variantString={lookupKey}>{icon}</Icon>
    </ActionPointerOuter>
  }

export default ActionPointer
