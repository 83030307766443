import React from 'react'
import styled from 'styled-components'
import { isEmptyValue, isNumeric } from '~/utils/testers'
import BlankPreview from './BlankPreview'
import ListSectionEditor from './List/ListSectionEditor'
import PagedSectionEditor from './Paged/PagedSectionEditor'
import PageEditor from './Paged/PageEditor'

import type {
  ClosingSectionElement,
  Experiment,
  ListResponse,
  ListSectionElement,
  ListWorksheet,
  OpeningSectionElement,
  PageElement,
  PagedSectionElement,
  PagedWorksheet,
  RepeatingSectionElement,
  WorksheetResponse
} from '~/features'

interface Props {
  worksheet: ListWorksheet | PagedWorksheet
  allWorksheets: Array<ListWorksheet | PagedWorksheet>
  experiment: Experiment
  selectedSection?: string
  setSelectedSection: (sectionId?: string) => void
  selectedPage?: number
  setSelectedPage: (pageIndex?: number) => void
  response: WorksheetResponse
  otherResponses: WorksheetResponse[]
  updateResponse: (updatedResponse: WorksheetResponse) => void
  updateWorksheet: (updatedWorksheet: ListWorksheet | PagedWorksheet) => void
}

type AnySectionElement = RepeatingSectionElement | PagedSectionElement | OpeningSectionElement | ClosingSectionElement

const
  Wrapper = styled.div`
    flex: 1 1 0;
    padding: ${p => p.theme.emSmallSpacing}em;
    border: 2px solid ${p => p.theme.colors.borderAccentAlt};
  `,
  StepFormatEditor: React.FC<Props> = ({
    worksheet,
    allWorksheets,
    experiment,
    selectedSection,
    setSelectedSection,
    selectedPage,
    setSelectedPage,
    response,
    otherResponses,
    updateResponse,
    updateWorksheet
  }) => {
    const
      isList = worksheet.format === 'LIST',
      mainSections: AnySectionElement[] = (
        isList ? worksheet.repeatingSections : worksheet.sections
      ),
      section: AnySectionElement | undefined = isList
        ? [
            worksheet.openingSection,
            ...mainSections,
            worksheet.closingSection
          ].find(o => o && o.id === selectedSection) ?? undefined
        : mainSections.find(o => o.id === selectedSection),
      page: PageElement | undefined = section && section.elementVariety === 'SECTION' && isNumeric(selectedPage)
        ? section.pages[selectedPage]
        : undefined,
      prevSectionInfo = React.useRef<[string | undefined, AnySectionElement | undefined]>([selectedSection, section]),
      sectionWasRemoved = selectedSection && selectedSection === prevSectionInfo.current[0] && prevSectionInfo.current[1] && !section,
      prevPageInfo = React.useRef<[number | undefined, PageElement | undefined]>([selectedPage, page]),
      pageWasRemoved = (
        (selectedSection && selectedSection === prevSectionInfo.current[0]) &&
        !isEmptyValue(selectedPage) &&
        (selectedPage === prevPageInfo.current[0]) &&
        prevPageInfo.current[1] &&
        (prevPageInfo.current[1].id !== page?.id)
      )

    React.useEffect(() => {
      if (sectionWasRemoved) {
        prevSectionInfo.current = [undefined, undefined]
        setSelectedSection()
      } else {
        prevSectionInfo.current = [selectedSection, section]
      }
    }, [sectionWasRemoved, setSelectedSection, section, selectedSection])

    React.useEffect(() => {
      if (pageWasRemoved) {
        prevPageInfo.current = [undefined, undefined]
        setSelectedPage()
      } else {
        prevPageInfo.current = [selectedPage, page]
      }
    }, [pageWasRemoved, setSelectedPage, page, selectedPage])

    if (!selectedSection || sectionWasRemoved) {
      return <Wrapper>
        <BlankPreview worksheet={worksheet} setSelectedSection={setSelectedSection} />
      </Wrapper>
    }

    if ((!section && !prevSectionInfo.current[1]) || pageWasRemoved) {
      return <Wrapper>
        <BlankPreview worksheet={worksheet} notFound />
      </Wrapper>
    }

    const commonProps = {
      experiment,
      allWorksheets,
      updateWorksheet,
      setSelectedPage,
      setSelectedSection,
      response,
      otherResponses,
      updateResponse
    }

    return isList
      ? <ListSectionEditor {...commonProps} section={section as ListSectionElement} worksheet={worksheet} response={response as ListResponse} />
      : selectedPage === undefined || isEmptyValue(selectedPage)
        ? <PagedSectionEditor {...commonProps} section={section as PagedSectionElement} worksheet={worksheet} />
        : <PageEditor {...commonProps} section={section as PagedSectionElement} worksheet={worksheet} selectedPage={selectedPage} />
  }

export default StepFormatEditor
