import * as React from 'react'
import { values } from 'lodash'
import { Navigate, useParams } from 'react-router-dom'
import { ExperimentHelpers } from '~/features'
import { selectExperimentById, selectWorksheetResponses } from '~/features/entity/selectors'
import { useAppSelector } from '~/hooks'

const
  ProjectLandingHandler: React.FC = () => {
    const
      { id } = useParams(),
      worksheetResponsesById = useAppSelector(selectWorksheetResponses),
      experiment = useAppSelector(selectExperimentById(id)),
      responsesThisExperiment = values(worksheetResponsesById).filter(response => response.experimentId === id),
      experimentStatus = experiment
        ? ExperimentHelpers.getStatusFromResponses(responsesThisExperiment, experiment)
        : undefined

    if (experimentStatus && experimentStatus === 'urgent') {
      return <Navigate to="responses" replace />
    } else {
      return <Navigate to="settings" replace />
    }
  }

export default ProjectLandingHandler
