// import * as React from 'react'
import styled from 'styled-components'
import { DashedLine } from './Shapes'
import { HintText } from './Text'

export const
  SettingsTitleRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `,
  SettingsContentWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 250px;
    background: ${p => p.theme.colors.containerAccentBg};
    padding: 1em;
  `,
  SettingsSectionDivider = styled(DashedLine)`
    margin: ${p => p.theme.emBaseSpacing * 2}em 0;
  `,
  StatusText = styled(HintText)`
    text-align: left;
    margin-bottom: ${p => p.theme.emBaseSpacing}em;
  `
