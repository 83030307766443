import React from 'react'
import { isEqual } from 'lodash'
import styled from 'styled-components'
import { FORM_STATUSES } from '~/form-brain2/types'
import { useUpdateEntity } from '~/hooks'
import { FormLabel } from '~/components/atoms'
import { SubmitButtonComplex } from '~/components/molecules'
import StepFormatEditor from '~/components/organisms/StepManagement/StepFormatEditor'

import type { EntityType } from '~/types/utilities'
import type { FormStatus, SubscribeToFormStatusFn } from '~/form-brain2'
import type { Experiment, ListTemplate, ListWorksheet, PagedSectionElement, PagedTemplate, PagedWorksheet, Template, TemplateSet, Worksheet, WorksheetResponse } from '~/features'
import { getExampleResponses } from '~/features/worksheetResponse/helpers'
import ListFormatNavigation from './ListFormatNavigation'
import PagedFormatNavigation from './PagedFormatNavigation'
import { isEmptyValue } from '~/form-brain2/testers'
import { doesQuestionnaireHaveSystemFields } from '~/features/questionnaire/helpers'

interface CoreProps {
  formStatus: FormStatus | undefined
  subscribeToFormStatus: SubscribeToFormStatusFn
}

interface PropWithWorksheet extends CoreProps {
  questionnaire: ListWorksheet | PagedWorksheet
  allQuestionnaires: Worksheet[]
  questionnaireGroup: Experiment
}

interface PropsWithTemplate extends CoreProps {
  questionnaire: ListTemplate | PagedTemplate
  allQuestionnaires: Template[]
  questionnaireGroup: TemplateSet
}

type Props = PropWithWorksheet | PropsWithTemplate

const
  Outer = styled.div`
    padding: 0 0.5em;
  `,
  HeadingRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `,
  Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: ${p => p.theme.emBaseSpacing}em;
  `,
  isChangeToPreviousSection = (sections: PagedSectionElement[], oldSectionId?: string, newSectionId?: string): boolean => {
    const
      indexOfOld = sections.findIndex(o => o.id === oldSectionId),
      indexOfNew = sections.findIndex(o => o.id === newSectionId)

    return isEmptyValue(indexOfOld) || isEmptyValue(indexOfNew)
      ? false
      : indexOfNew === (indexOfOld - 1)
  },
  isChangeToNextSection = (sections: PagedSectionElement[], oldSectionId?: string, newSectionId?: string): boolean => {
    const
      indexOfOld = sections.findIndex(o => o.id === oldSectionId),
      indexOfNew = sections.findIndex(o => o.id === newSectionId)

    return isEmptyValue(indexOfOld) || isEmptyValue(indexOfNew)
      ? false
      : indexOfNew === (indexOfOld + 1)
  },
  FormatWizard: React.FC<Props> = ({ questionnaire, allQuestionnaires, questionnaireGroup, formStatus, subscribeToFormStatus }) => {
    const
      [state, setState] = React.useState<{ selectedSection?: string, selectedPage?: number }>({}),
      { selectedSection, selectedPage } = state,
      setSelectedSection = (newSectionId?: string): void => {
        setState({
          selectedSection: newSectionId,
          selectedPage: questionnaire.format === 'PAGED' && isChangeToPreviousSection(questionnaire.sections, selectedSection, newSectionId)
            ? (questionnaire.sections.find(o => o.id === newSectionId)?.pages?.length ?? 1) - 1
            : questionnaire.format === 'PAGED' && isChangeToNextSection(questionnaire.sections, selectedSection, newSectionId)
              ? 0
              : undefined
        })
      },
      setSelectedPage = (newPageNumber?: number): void => { setState({ ...state, selectedPage: newPageNumber }) },
      [values, setValues] = React.useState<typeof questionnaire>(questionnaire),
      { updateEntity, isUpdating, isSuccess } = useUpdateEntity(),
      saveChanges: React.MouseEventHandler = (e) => {
        if (e) { e.stopPropagation(); e.preventDefault() }

        const questionnaireWithUpdatedFixedRank = {
          ...questionnaire,
          fixedRank: doesQuestionnaireHaveSystemFields(questionnaire)
        }

        void updateEntity({
          entity: questionnaireWithUpdatedFixedRank,
          newValues: values
        })
      },
      allExampleResponses = getExampleResponses({ questionnaires: allQuestionnaires, questionnaireGroup }),
      response = {
        worksheetId: questionnaire.id,
        experimentId: questionnaireGroup.id,
        responses: values.exampleResponses ?? (values.format === 'LIST' ? { entries: [] } : {}),
        eventLog: [],
        id: 'fake',
        type: 'worksheetResponse' as EntityType,
        createdAt: '',
        updatedAt: ''
      },
      otherResponses = allExampleResponses.filter(o => o.worksheetId !== questionnaire.id),
      setResponse = (newResponse: WorksheetResponse): void => {
        setValues({
          ...values,
          exampleResponses: newResponse.responses
        })
      },
      isDirty = !isEqual(values, questionnaire)

    React.useEffect(() => {
      if (questionnaire.updatedAt !== values.updatedAt) {
        setValues(questionnaire)
      }
    }, [questionnaire, values.updatedAt])

    React.useEffect(() => {
      const newStatus = isUpdating
        ? FORM_STATUSES.PROCESSING
        : isDirty
          ? FORM_STATUSES.DIRTY
          : isSuccess
            ? FORM_STATUSES.CLEAN_AFTER_SUCCESS
            : FORM_STATUSES.CLEAN

      if (formStatus !== newStatus) {
        subscribeToFormStatus({ formStatus: newStatus })
      }
    }, [isUpdating, isDirty, isSuccess, subscribeToFormStatus, formStatus])

    if ('releasedAt' in questionnaire && questionnaire.releasedAt) { return null }

    return <Outer data-cy="step-format-wizard">
      <HeadingRow>
        <FormLabel
          label="Step Format"
          showLabel
          asHeading
        />
        <SubmitButtonComplex label="Save Changes" formStatus={formStatus} onClick={saveChanges} />
      </HeadingRow>
      <Wrapper>
        {values.format === 'LIST'
          ? <ListFormatNavigation { ...{ questionnaire: values, updateQuestionnaire: setValues, selectedSection, setSelectedSection } } />
          : <PagedFormatNavigation { ...{ questionnaire: values, updateQuestionnaire: setValues, selectedSection, setSelectedSection, selectedPage, setSelectedPage } } />}
        <StepFormatEditor {...{
          /** TODO - Improve type system and/or generalize tree from here down to handle templates */
          experiment: questionnaireGroup as Experiment,
          worksheet: values as ListWorksheet | PagedWorksheet,
          allWorksheets: allQuestionnaires as Array<ListWorksheet | PagedWorksheet>,
          selectedSection,
          setSelectedSection,
          selectedPage,
          setSelectedPage,
          updateWorksheet: setValues,
          response,
          otherResponses,
          updateResponse: setResponse
        }} />
      </Wrapper>
    </Outer>
  }

export default FormatWizard
