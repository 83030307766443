import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  Outlet,
  RouterProvider
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { ThemeProvider } from 'styled-components'
import { Provider as StoreProvider } from 'react-redux'
import { store } from '~/services/store'
import TeachingTheme from '~/components/TeachingTheme'
import {
  ProjectLandingHandler,
  ProjectSettings,
  ProjectGroup,
  ProjectResponses,
  NewProjectForm,
  ProjectAccessHandler,
  LearnerStepLandingHandler,
  LearnerStepReview,
  LearnerStepSubmitted,
  PagedStep,
  ListStep,
  HubStep,
  DownloadsStep,
  SessionWatcher,
  AccountSettings,
  TemplateSetList,
  AdminDashboard
} from '~/components/organisms'
import { // hygen new page imports here
  LearnerProjectPage,
  TeacherProjectPage,
  GlobalHomePage,
  NotFoundPage,
  ProjectListPage,
  LearnerProjectStepPage,
  TeacherResponsePage,
  TeachingHomePage,
  MagicLinkLoginPage,
  RequestAccountPage,
  AccountSettingsPage,
  TeacherStepPage,
  TemplateSetPage,
  TemplatePage
} from '~/components/pages'
import '~/index.css'

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    release: process.env.SENTRY_RELEASE
  })
}

const
  root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement),
  RootComponent: React.FC = () => <>
    <SessionWatcher />
    <Outlet />
  </>,
  router = createBrowserRouter([
    {
      path: '/',
      element: <RootComponent />,
      errorElement: <NotFoundPage />,
      children: [
        {
          index: true,
          element: <GlobalHomePage />
        },
        {
          path: 'educate',
          element: <TeachingHomePage />
        },
        {
          path: 'request-account',
          element: <RequestAccountPage />
        },
        {
          path: 'access/:token',
          element: <MagicLinkLoginPage />
        },
        {
          path: 'account',
          element: <AccountSettingsPage />,
          children: [{
            index: true,
            element: <AccountSettings />
          }, {
            path: 'templates',
            element: <TemplateSetList />
          }, {
            path: 'admin-tools',
            element: <AdminDashboard />
          }]
        },
        {
          path: 'projects',
          element: <ProjectListPage />,
          children: [{
            path: 'new',
            element: <NewProjectForm />
          }]
        },
        {
          path: 'projects/:id',
          element: <TeacherProjectPage />,
          children: [{
            index: true,
            element: <ProjectLandingHandler />
          }, {
            path: 'learners',
            element: <ProjectGroup />
          }, {
            path: 'responses',
            element: <ProjectResponses />
          }, {
            path: 'settings',
            element: <ProjectSettings segment="settings" />
          }, {
            path: 'labels',
            element: <ProjectSettings segment="labels" />
          }]
        },
        {
          path: 'steps/:id',
          element: <TeacherStepPage />
        },
        {
          path: 'responses/:id',
          element: <TeacherResponsePage />
        },
        {
          path: 'template-sets/:id',
          element: <TemplateSetPage />
        },
        {
          path: 'templates/:id',
          element: <TemplatePage />
        },
        {
          path: 'project-access/:token',
          element: <ProjectAccessHandler />
        },
        {
          path: 'project-home',
          element: <LearnerProjectPage />
        },
        {
          path: 'project-step/:stepId',
          element: <LearnerProjectStepPage />,
          children: [{
            index: true,
            element: <LearnerStepLandingHandler />
          }, {
            path: 'all',
            element: <ListStep />
          }, {
            path: 'hub',
            element: <HubStep />
          }, {
            path: 'download',
            element: <DownloadsStep />
          }, {
            path: 'review',
            element: <LearnerStepReview />
          }, {
            path: 'response',
            element: <LearnerStepSubmitted />
          }, {
            path: 'section/:sectionIndex/page/:pageIndex',
            element: <PagedStep />
          }]
        } // hygen new routes here
      ]
    }
  ])

root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <ThemeProvider theme={TeachingTheme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </StoreProvider>
  </React.StrictMode>
)
