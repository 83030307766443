import { difference, intersection, isEqual, uniqWith } from 'lodash'
import { ArrayToIntConditions, ArrayToSingletonConditions, ComparativeConditions, ConditionTypes, DateConditions, IndependentConditions, NumberConditions, TextConditions, TextToArrayConditions, TextToIntConditions } from './types'
import { SYSTEM_FIELDS, SystemFieldNames } from '~/features/experiment/systemFields'

import type { CollectionOptions, FullOptionList } from '~/form-brain2'
import type { FormContextType } from '~/form-brain2/internalTypes'
import type { allFieldsFromExperiment } from '~/features/experiment/helpers'
import type { SystemFieldName } from '~/features/experiment/systemFields'
import type { AnyInputElement, AnyPlainOrInputElement, CollectionElement, ConditionType, InputElementVariety, ListOption, ScopeElement, SelectInput, VisibilityCondition } from './types'

export type ValueType = 'array' | 'number' | 'date' | 'text' | 'bool' | 'obj' | 'objArray'
export type ConditionCategory = 'absolute' | 'independent' | 'explicit' | 'referenced'

export interface ConditionDetails {
  condition: Partial<VisibilityCondition>
  referencedValueType: ValueType | undefined
  referencedElement: AnyInputElement | CollectionElement | ScopeElement | undefined
  permittedConditionTypes: ConditionType[]
  permittedTestValueType: ValueType | undefined
  permittedReferencedValueTypes: ValueType[]
  testValueType: ValueType | undefined
  testValueElement: AnyInputElement | CollectionElement | ScopeElement | undefined
  testValueOptions: FullOptionList | undefined
}

const
  conditionTypeText: Record<ConditionType, string> = {
    '%': 'is a valid percentage',
    absent: 'is missing or blank',
    after: 'is a date later than',
    always: '(always)',
    anyOf: 'is one of the following',
    before: 'is a date earlier than',
    characterLengthAtLeast: 'is text with at least this many characters',
    characterLengthLessThan: 'is text with fewer than this many characters',
    equals: 'is exactly this text or option',
    equalsNumber: 'is exactly this number',
    falsy: 'is FALSE, "no", or a similar value',
    gt: 'is a number greater than',
    gto: 'is a number greater than or equal to',
    includes: 'is a list which includes the following',
    integer: 'is a whole number',
    lengthAtLeast: 'is a list with at least this many selections',
    lengthLessThan: 'is a least with fewer than this many selections',
    lt: 'is a number less than',
    lto: 'is a number less than or equal to',
    matches: 'contains this text or option',
    never: '(never)',
    noneOf: 'is NOT one of the following',
    notEquals: 'is NOT this text or option',
    notEqualsNumber: 'is a number not equal to',
    notIncludes: 'is a list which does NOT include the following',
    notInteger: 'is NOT a whole number',
    notMatches: 'does NOT contain this text or option',
    sameDateAs: 'is exactly this date',
    sameMonthAs: 'is a date in the same month as',
    truthy: 'is TRUE, "yes" or a similar value',
    wordLengthAtLeast: 'is text with at least this many words',
    wordLengthLessThan: 'is text with fewer than this many words',
    year: 'is a number that is a valid year',
    present: 'has been answered (is not blank)'
  },
  valueTypeByElementVariety: Record<InputElementVariety | 'SCOPE' | 'COLLECTION', ValueType> = {
    CHECKBOX_INPUT: 'bool',
    COLLECTION: 'objArray',
    COLOR_PICKER: 'text',
    DATE_INPUT: 'date',
    LONG_ANSWER: 'text',
    MULTI_SELECT_INPUT: 'array',
    MULTI_TEXT_INPUT: 'array',
    NUMBER_INPUT: 'number',
    PARAGRAPHS: 'array',
    SCOPE: 'obj',
    SELECT_INPUT: 'text',
    SHORT_ANSWER: 'text',
    TEXT_INPUT: 'text'
  },
  conditionTypesByCategory: Record<ConditionCategory, ConditionType[]> = {
    absolute: ['always', 'never'],
    referenced: [...ComparativeConditions],
    explicit: [...ComparativeConditions],
    independent: difference([...IndependentConditions], ['always', 'never'])
  },
  conditionTypesByReferenceValueType: Record<ValueType, ConditionType[]> = {
    bool: ['truthy', 'falsy'],
    obj: ['present', 'absent'],
    objArray: ['present', 'absent', ...ArrayToIntConditions],
    date: ['present', 'absent', ...DateConditions, 'truthy', 'falsy'],
    number: ['present', 'absent', ...NumberConditions, 'integer', 'notInteger', '%', 'year', 'truthy', 'falsy'],
    array: ['present', 'absent', ...ArrayToIntConditions, ...ArrayToSingletonConditions],
    text: ['present', 'absent', ...TextConditions]
  },
  valueTypes: ValueType[] = Object.keys(conditionTypesByReferenceValueType) as ValueType[],
  getPermittedTestValueTypeForConditionType = (conditionType?: ConditionType): ValueType | undefined => {
    return !conditionType
      ? undefined
      : ([...TextToArrayConditions] as ConditionType[]).includes(conditionType)
          ? 'array'
          : ([...TextToIntConditions, ...NumberConditions, ...ArrayToIntConditions] as ConditionType[]).includes(conditionType)
              ? 'number'
              : ([...DateConditions] as ConditionType[]).includes(conditionType)
                  ? 'date'
                  : 'text'
  },
  getPermittedReferencedValueTypesForConditionType = (conditionType?: ConditionType): ValueType[] => {
    if (!conditionType) { return valueTypes }

    return valueTypes.reduce<ValueType[]>((memo, valueType) => {
      if (conditionTypesByReferenceValueType[valueType].includes(conditionType)) {
        return [...memo, valueType]
      } else {
        return memo
      }
    }, [])
  },
  // baseValueNameElement: SelectInput = {
  //   id: 'visibilityCriteriaValueName',
  //   elementVariety: 'SELECT_INPUT',
  //   blankOption: 'this question/answer',
  //   customOption: 'enter another question/answer ID',
  //   fieldName: 'valueName',
  //   noSort: true,
  //   naturalWidth: true,
  //   errNarrow: true,
  //   visibleWhen: [
  //     { conditionType: 'noneOf', valueName: 'conditionType', testValue: ['always', 'never'] }
  //   ]
  // },
  baseConditionScopeElement: SelectInput = {
    id: 'visibilityCriteriaScope',
    elementVariety: 'SELECT_INPUT',
    placeholder: 'in any step',
    fieldName: 'conditionScope',
    noSort: true,
    naturalWidth: true,
    list: [
      { optionValue: 'rootScope', optionLabel: 'this step' },
      { optionValue: 'ownScope', optionLabel: 'this section' },
      { optionValue: 'all', optionLabel: 'anywhere' }
    ],
    visibleWhen: [
      { conditionType: 'present', valueName: 'valueName' }
    ]
  },
  getFieldTypeForElement = (formElement: AnyInputElement | CollectionElement | ScopeElement | undefined): ValueType | undefined => {
    return !formElement
      ? undefined
      : valueTypeByElementVariety[formElement.elementVariety]
      /*
      formElement.elementVariety === 'CHECKBOX_INPUT'
        ? 'bool'
        : formElement.elementVariety === 'DATE_INPUT'
          ? 'date'
          : formElement.elementVariety === 'NUMBER_INPUT'
            ? 'number'
            : formElement.elementVariety === 'MULTI_SELECT_INPUT'
              ? 'array'
              : 'text'
              */
  },
  getFieldNameOptions = (allFieldDetails: ReturnType<typeof allFieldsFromExperiment>, permittedValueTypes: ValueType[]): ListOption[] => {
    const
      includedFieldDetails = allFieldDetails.filter(({ element }) => {
        return permittedValueTypes.includes(valueTypeByElementVariety[element.elementVariety])
      }),
      fieldNameOptions = uniqWith(includedFieldDetails.map(({ fieldName, description }) => {
        const systemField = SYSTEM_FIELDS[fieldName as SystemFieldName]
        return systemField
          ? {
              optionValue: fieldName,
              optionLabel: `${systemField.title} *`
            }
          : {
              optionValue: fieldName,
              optionLabel: `${fieldName} "${description}"`
            }
      }), isEqual)

    return fieldNameOptions
  },
  getConditionTypeElement = (conditionCategory: ConditionCategory, permittedConditionTypes: ConditionType[]): SelectInput => {
    const
      typesInCategory = conditionTypesByCategory[conditionCategory],
      conditionTypes = intersection(
        typesInCategory,
        permittedConditionTypes
      )

    return {
      id: 'visibilityCriteriaConditionType',
      elementVariety: 'SELECT_INPUT',
      fieldName: 'conditionType',
      noSort: true,
      naturalWidth: true,
      list: conditionTypes.map((value) => ({
        optionValue: value,
        optionLabel: conditionTypeText[value]
      }))
    }
  },
  getTestValueElement = (permittedTestvalueType: ValueType, testValueOptions?: FullOptionList): AnyInputElement => {
    if (permittedTestvalueType === 'number') {
      return {
        id: 'visibilityCriteriaTestValueNumber',
        elementVariety: 'NUMBER_INPUT',
        fieldName: 'testValue'
        // visibleWhen: [
        //   { conditionType: 'present', valueName: 'conditionType' },
        //   { conditionType: 'anyOf', valueName: 'conditionType', testValue: [...TextToIntConditions, ...NumberConditions, ...ArrayToIntConditions] }
        // ]
      }
    }

    if (permittedTestvalueType === 'date') {
      return {
        id: 'visibilityCriteriaTestValueDate',
        elementVariety: 'DATE_INPUT',
        fieldName: 'testValue'
        // visibleWhen: [
        //   { conditionType: 'present', valueName: 'conditionType' },
        //   { conditionType: 'anyOf', valueName: 'conditionType', testValue: [...DateConditions] }
        // ]
      }
    }

    if (permittedTestvalueType === 'array') {
      return {
        id: 'visibilityCriteriaTestValueList',
        elementVariety: 'MULTI_TEXT_INPUT',
        fieldName: 'testValue',
        list: testValueOptions
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          ? testValueOptions.map(o => ({
            optionValue: o.value,
            optionLabel: o.label as string | undefined
          } as ListOption))
          : undefined
        // visibleWhen: [
        //   { conditionType: 'present', valueName: 'conditionType' },
        //   { conditionType: 'anyOf', valueName: 'conditionType', testValue: [...TextToArrayConditions] }
        // ]
      }
    }

    return testValueOptions
      ? {
          id: 'visibilityCriteriaTestValueText',
          elementVariety: 'SELECT_INPUT',
          customOption: 'enter your own value',
          fieldName: 'testValue',
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          list: testValueOptions.map(o => ({
            optionValue: o.value,
            optionLabel: o.label as string | undefined
          } as ListOption))
          // visibleWhen: [
          //   { conditionType: 'present', valueName: 'conditionType' },
          //   { conditionType: 'anyOf', valueName: 'conditionType', testValue: [...TextToTextConditions, ...ArrayToSingletonConditions] }
          // ]
        }
      : {
          id: 'visibilityCriteriaTestValueText',
          elementVariety: 'TEXT_INPUT',
          fieldName: 'testValue'
          // visibleWhen: [
          //   { conditionType: 'present', valueName: 'conditionType' },
          //   { conditionType: 'anyOf', valueName: 'conditionType', testValue: [...TextToTextConditions, ...ArrayToSingletonConditions] }
          // ]
        }
  }

export const
  getConditionDetails = ({ condition, allFieldDetails, formContext }: {
    condition: Partial<VisibilityCondition>
    allFieldDetails: ReturnType<typeof allFieldsFromExperiment>
    formContext: FormContextType
  }): ConditionDetails => {
    const
      selectedFieldDetails = condition.valueName
        ? allFieldDetails.find(o => o.fieldName === condition.valueName)
        : undefined,
      referencedElement = selectedFieldDetails?.element && 'fieldName' in selectedFieldDetails.element
        ? selectedFieldDetails.element
        : undefined,
      referencedSystemField = referencedElement && referencedElement.fieldName in SYSTEM_FIELDS
        ? SYSTEM_FIELDS[referencedElement.fieldName as SystemFieldName]
        : undefined,
      referencedValueType = getFieldTypeForElement(referencedElement),
      selectedFieldDetailsOptions = referencedElement && ['SELECT_INPUT', 'MULTI_SELECT_INPUT', 'MULTI_TEXT_INPUT'].includes(referencedElement.elementVariety)
        ? formContext.resolveList({
          contextAndResolvers: formContext,
          bodyElement: referencedElement,
          schemaEntry: referencedSystemField
            ? referencedSystemField.schemaEntry
            : undefined
        })
        : undefined,
      permittedTestValueType = getPermittedTestValueTypeForConditionType(condition.conditionType),
      testValueFieldDetails = 'testValueName' in condition && condition.testValueName
        ? allFieldDetails.find(o => o.fieldName === condition.testValueName)
        : undefined,
      testValueElement = testValueFieldDetails?.element && 'fieldName' in testValueFieldDetails.element
        ? testValueFieldDetails.element
        : undefined,
      testValueType = getFieldTypeForElement(testValueElement)

    return {
      condition,
      referencedElement,
      referencedValueType,
      permittedConditionTypes: referencedValueType
        ? conditionTypesByReferenceValueType[referencedValueType]
        : [...ConditionTypes],
      permittedTestValueType,
      permittedReferencedValueTypes: getPermittedReferencedValueTypesForConditionType(condition.conditionType),
      testValueType,
      testValueElement,
      testValueOptions: referencedSystemField?.fieldName === SystemFieldNames.dependentVariable || referencedSystemField?.fieldName === SystemFieldNames.independentVariable
        ? selectedFieldDetailsOptions?.map((o) => ({ ...o, label: o.value }))
        : selectedFieldDetailsOptions
    }
  },
  getConditionFormBody = ({ conditionCategory, conditionDetails, collectionOptions, allFieldDetails, includeScope }: {
    conditionCategory: ConditionCategory
    conditionDetails: ConditionDetails
    collectionOptions?: CollectionOptions
    allFieldDetails: ReturnType<typeof allFieldsFromExperiment>
    includeScope?: boolean
  }): AnyPlainOrInputElement[] => {
    const
      isFirstEntry = collectionOptions?.entryIndex === 0,
      formBody: AnyPlainOrInputElement[] = []

    if (conditionCategory !== 'absolute') {
      formBody.push(
        {
          id: 'conditionItemText',
          elementVariety: 'TEXT',
          text: isFirstEntry ? (collectionOptions?.firstEntryLeadIn ?? 'When') : 'and'
        },
        {
          id: 'conditionItemValueName',
          elementVariety: 'SELECT_INPUT',
          placeholder: 'this question/section',
          blankOption: 'this question/section',
          customOption: 'enter another question/answer ID',
          fieldName: 'valueName',
          noSort: true,
          naturalWidth: true,
          errNarrow: true,
          list: getFieldNameOptions(allFieldDetails, conditionDetails.permittedReferencedValueTypes)
        }
      )

      if (includeScope) {
        formBody.push(baseConditionScopeElement)
      }
    }

    formBody.push(getConditionTypeElement(
      conditionCategory,
      conditionDetails.permittedConditionTypes
    ))

    if (conditionCategory === 'explicit' || conditionCategory === 'referenced') {
      formBody.push(
        {
          id: 'conditionItemColon',
          elementVariety: 'TEXT',
          text: ':'
        }
      )
    }

    if (conditionCategory === 'explicit' && conditionDetails.permittedTestValueType) {
      formBody.push(getTestValueElement(conditionDetails.permittedTestValueType, conditionDetails.testValueOptions))
    }

    if (conditionCategory === 'referenced' && conditionDetails.permittedTestValueType) {
      formBody.push(
        {
          id: 'visibilityCriteriaTestValueName',
          elementVariety: 'SELECT_INPUT',
          customOption: 'enter another question/answer ID',
          fieldName: 'testValueName',
          noSort: true,
          naturalWidth: true,
          errNarrow: true,
          list: getFieldNameOptions(allFieldDetails, [conditionDetails.permittedTestValueType])
        })
    }

    return formBody
    // return [
    //   {
    //     id: 'conditionConfiguration',
    //     elementVariety: 'FILL_IN_THE_BLANK',
    //     segments: [
    //       ...formBody,
    //       {
    //         id: 'conditionItemColon',
    //         elementVariety: 'TEXT',
    //         text: ':',
    //         visibleWhen: [
    //           { conditionType: 'present', valueName: 'conditionType' },
    //           { conditionType: 'noneOf', valueName: 'conditionType', testValue: [...IndependentConditions] }
    //         ]
    //       }
    //     ]
    //   },
    //   ...baseTextValueElements,
    // ]
  }
