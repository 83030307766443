import { getSemiRandomString } from '~/utils/strings'
import type { ListWorksheet } from '../types'
import type { SpecialListSectionElement } from '~/features/questionnaire/types'

export const
  getDefaultScheduledActivitySection = (sectionId?: string): SpecialListSectionElement => {
    return {
      id: sectionId ?? `schedule-activity-${getSemiRandomString()}`,
      elementVariety: 'LIST_ENTRY_SECTION',
      entryNamePlural: 'scheduled activities',
      entryNameSingular: 'scheduled activity',
      specialSectionId: 'PLAN_ACTIVITY',
      whatLabel: 'What activity will you do?',
      whenLabel: 'When?',
      entryIconContent: 'A',
      body: []
    }
  },
  getDefaultScheduledMeasurementSection = (sectionId?: string): SpecialListSectionElement => {
    return {
      id: sectionId ?? `schedule-measurement-${getSemiRandomString()}`,
      elementVariety: 'LIST_ENTRY_SECTION',
      entryNamePlural: 'scheduled measurements',
      entryNameSingular: 'scheduled measurement',
      specialSectionId: 'PLAN_MEASUREMENT',
      whatLabel: 'What measurement(s) will you take?',
      whenLabel: 'When?',
      entryIconContent: 'M',
      body: []
    }
  },
  defaultsForScheduleLayout = (
    activitySectionId?: string,
    measurementSectionId?: string
  ): Pick<ListWorksheet, 'repeatingSections' | 'defaults'> => {
    const
      activitySection = getDefaultScheduledActivitySection(activitySectionId),
      measurementSection = getDefaultScheduledMeasurementSection(measurementSectionId)

    return {
      repeatingSections: [
        activitySection,
        measurementSection
      ],
      defaults: [
        {
          sectionId: activitySection.id,
          entryValues: {
            description: 'Make a list of materials ahead of time',
            startDay: '0'
          }
        },
        {
          sectionId: activitySection.id,
          entryValues: {
            description: 'Set up the experiment',
            startDay: '1'
          }
        },
        {
          sectionId: activitySection.id,
          automaticEntryKey: 'plant'
        },
        {
          sectionId: activitySection.id,
          automaticEntryKey: 'apply treatments'
        },
        {
          sectionId: activitySection.id,
          automaticEntryKey: 'water'
        },
        {
          sectionId: activitySection.id,
          automaticEntryKey: 'germination',
          entryValues: {
            description: 'Check for germination'
          }
        },
        {
          sectionId: activitySection.id,
          automaticEntryKey: 'thin'
        },
        {
          sectionId: activitySection.id,
          automaticEntryKey: 'fertilize'
        },
        {
          sectionId: activitySection.id,
          automaticEntryKey: 'harvest'
        },
        {
          sectionId: measurementSection.id,
          automaticEntryKey: 'measure'
        },
        {
          sectionId: activitySection.id,
          automaticEntryKey: 'end'
        }
      ]
    }
  }
