import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Other treatment label',
  description = 'This allows learners who are using a custom independent variable to describe their treatments. Unlike other treatment fields which can also appear in the control variables section, this should only be used in the context of a treatment group.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.customVariableTreatmentLabel,
    fieldType: 'STRING'
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.customVariableTreatmentLabel,
    variableGroup: SystemFieldGroups.OTHER,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
