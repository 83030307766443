import * as React from 'react'
import styled from 'styled-components'
import { AlertText, Icon } from '~/components/atoms'

const
  ErrorWrapper = styled.div`
    max-width: 90%;
    margin: 5% auto;

    > .error-message-icon {
      width: 2em;
      margin: auto;
      margin-bottom: 1em;
    }
  `,
  StyledAlertText = styled(AlertText)`
    margin: auto;
    width: ${p => p.theme.emSmallContentWidth}em;
  `,
  ErrorMessage: React.FC<{ children: React.ReactNode, className?: string }> = ({ children, className }) => {
    return <ErrorWrapper className={className}>
      <Icon content="!" color="caution" className="error-message-icon" />
      <StyledAlertText color="caution">
        {children}
      </StyledAlertText>
    </ErrorWrapper>
  }

export default ErrorMessage
