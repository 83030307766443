import * as React from 'react'
import styled from 'styled-components'
import { Button, CheckmarkIcon, HintText } from '../atoms'
import type { FormStatus } from '~/form-brain2'

interface CoreProps {
  formId?: string
  onClick?: React.MouseEventHandler
  label: string
  isLoading?: boolean
  isDisabled?: boolean
  formStatus?: FormStatus
  className?: string
}

type Props = CoreProps & { formId: string } | CoreProps & { onClick: React.MouseEventHandler }

interface PresentationProps {
  button: React.ReactNode
  messageText?: string
  icon?: React.ReactNode
  swapButtonAndMessage?: boolean
  className?: string
}

const
  ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${p => p.theme.emSmallSpacing}em;
  `,
  StyledCheckmarkIcon = CheckmarkIcon,
  // StyledCheckmarkIcon = styled(CheckmarkIcon)`
  //   width: 1.5em;
  // `,
  IconSpan = styled.span`
    width: 1.5em;
  `,
  ButtonWithMessage: React.FC<PresentationProps> = ({ className, button, messageText, swapButtonAndMessage, icon }) => {
    return <ButtonRow className={className}>
      {messageText && swapButtonAndMessage
        ? <>
          {icon ? <IconSpan>{icon}</IconSpan> : null}
          <HintText color="default">{messageText}</HintText>
        </>
        : null}
      {button}
      {messageText && !swapButtonAndMessage
        ? <>
          <HintText color="default">{messageText}</HintText>
          {icon ? <IconSpan>{icon}</IconSpan> : null}
        </>
        : null}
    </ButtonRow>
  },
  SubmitButtonComplex: React.FC<Props> = ({ label, className, formStatus, formId, onClick, isLoading, isDisabled }) => {
    isDisabled = (!!formStatus && ['clean', 'cleanAfterSuccess'].includes(formStatus)) || isDisabled

    const
      isSuccess = formStatus && ['success', 'cleanAfterSuccess'].includes(formStatus),
      note = formStatus === 'clean'
        ? 'No changes to save'
        : isSuccess
          ? 'Your changes have been saved'
          : undefined,
      submissionProps = isDisabled
        ? { onClick: undefined }
        : formId
          ? { form: formId, type: 'submit' }
          : { onClick },
      button = <Button size="medium" color="forward" label={label} {...submissionProps} isLoading={!!isLoading || formStatus === 'processing'} isDisabled={isDisabled} />

    return (
      <ButtonWithMessage
        className={className}
        button={button}
        messageText={note}
        icon={isSuccess ? <StyledCheckmarkIcon /> : null}
      />
    )
  }

export default SubmitButtonComplex
export { ButtonWithMessage }
