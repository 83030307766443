import * as React from 'react'
import styled from 'styled-components'
import { Outlet, useMatches } from 'react-router-dom'
import { HEADER_TEXTS } from '~/utils/strings'
import { useAppSelector, useEntityData, useSession } from '~/hooks'
import { BrandedPage, FlexibleMain } from '~/components/layouts'
import { ActionPointer, ErrorMessage, EmptyContentPlaceholder } from '~/components/molecules'
import { Icon, HeadingOne, SubheadingOne, BasicCross, SizedLoadingIcon, Link } from '~/components/atoms'
import { ProjectList } from '~/components/organisms'
import { selectExperiments } from '~/features/entity/selectors'
import { isAllEmpty } from '~/form-brain2/testers'
import { ContactUsMessage, ErrorPage, LeadIn, Li, Ul } from './NotFoundPage'

const
  Main = styled(FlexibleMain)`
    min-height: 500px;
  `,
  Adder = styled(ActionPointer)`
    position: absolute;
    top: ${p => p.theme.emBaseSpacing}em;
    right: ${p => p.theme.emBaseSpacing}em;
  `,
  ProjectListPage: React.FC = () => {
    let content
    const
      { dataStatus, isLoading } = useEntityData('lazy'),
      { isTeacher, hasSession } = useSession(),
      experimentsById = useAppSelector(selectExperiments),
      matches = useMatches(),
      addingNew = matches.some(({ pathname }) => pathname.match(/new/)),
      adderIcon = (
        <Icon
          content={BasicCross}
          color="back"
          frameStyle="circle"
          linkProps={{ to: 'new' }}
          accessibleDescription="Add a new group"
        />
      ),
      projectAdder = (
        <Adder
          label="Add a new group"
          icon={adderIcon}
          orientation="down"
        />
      )

    if (dataStatus === 'initializing' || dataStatus === 'uninitialized') {
      return <BrandedPage
        documentTitle="Your Groups | AgConnect"
        whichHome="teaching"
        headerText={HEADER_TEXTS.teaching}
        showLoader
      />
    }

    if (hasSession && !isTeacher) {
      return <ErrorPage errorTitle="Project Not Found" message="You are not logged in as a teacher" bareMessage>
      <LeadIn>What next?</LeadIn>
      <Ul>
        <Li>Check your account page for details about what your account has access to</Li>
        <Li>Try logging out and logging back in or refreshing the page</Li>
        <Li><ContactUsMessage question="Think you are seeing this message in error?" /></Li>
      </Ul>
      <LeadIn>Return to your <Link to="/educate">home page</Link></LeadIn>
    </ErrorPage>
    } else if (dataStatus === 'error') {
      content = <ErrorMessage>
        There was a problem retrieving your groups. Please try again later and contact support if the problem persists.
      </ErrorMessage>
    } else if (isAllEmpty(experimentsById) && !addingNew) {
      content = <EmptyContentPlaceholder>
        <span>Welcome!</span>
        <br />
        <span>You can use the plus button to add your first group.</span>
      </EmptyContentPlaceholder>
    } else if (isLoading) {
      content = <SizedLoadingIcon />
    } else {
      content = <>
        <Outlet />
        <ProjectList />
      </>
    }

    return (
      <BrandedPage
        documentTitle="Your Groups | AgConnect"
        whichHome="teaching"
        headerText={HEADER_TEXTS.teaching}>
        <Main
          absoluteContent={addingNew ? undefined : projectAdder}
          heading={<HeadingOne>Your Groups</HeadingOne>}
          subheading={<SubheadingOne>Teacher Dashboard:</SubheadingOne>}>
          {content}
        </Main>
      </BrandedPage>
    )
  }

export default ProjectListPage
