import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useSession } from '~/hooks'
import { learnerLoggedOut, userLoggedOut } from '~/features/session/sessionSlice'
import {
  LoadingIcon,
  Button,
  WoodGrainPattern
} from '~/components/atoms'
import { Page } from '~/components/layouts/LayoutWidgets'
import Logo from '~/assets/brand.png'

export type whichHome = 'all' | 'research' | 'teaching'

interface BaseProps {
  documentTitle: string
  headerText: string[]
  whichHome: whichHome
  accountMenu?: React.ReactNode
  className?: string
}

interface LoadedProps {
  showLoader?: false
  children: React.ReactNode
}

interface LoadingProps {
  showLoader: true
  children?: React.ReactNode
}

type BrandedPageProps = (BaseProps & LoadedProps) | (BaseProps & LoadingProps)

const
  pxBorderWidth = 5,
  pxHeaderVerticalPadding = 7.5,
  BrandedPageOuter = styled(Page)`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
  `,
  HeaderOuter = styled.header`
    height: ${p => p.theme.pxHeaderHeight}px;
    padding: ${pxHeaderVerticalPadding}px 0;
    border-bottom: ${pxBorderWidth}px solid ${p => p.theme.colors.brandLight};

    background: ${p => p.theme.colors.brandDark};
  `,
  HeaderInner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: ${p => p.theme.pxMaxContentWidth}px;
    margin: auto;
  `,
  Heading = styled.hgroup`
    flex: 1 1 auto;
    align-self: center;
    min-width: 0px;
    color: ${p => p.theme.colors.brandText};
    font-size: 15px; // convert to rem
  `,
  HeadingText = styled.div`
    line-height: 1.4;
    font-family: Merriweather, palatino, serif;
    color: ${p => p.theme.colors.brandText};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  Brand = styled(Link)`
    flex: 0 0 ${p => p.theme.pxHeaderHeight - pxBorderWidth - pxHeaderVerticalPadding * 2}px;
    height: ${p => p.theme.pxHeaderHeight - pxBorderWidth - pxHeaderVerticalPadding * 2}px;
    img {
      max-height: 100%;
      margin: auto;
    }
  `,
  BrandedPageBody = styled.div`
    position: relative;
    flex: 1 0 auto;
    padding: 36px 13.5px;
    background: ${p => p.theme.colors.brandMed};
  `,
  PageBackground = styled(WoodGrainPattern)`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  `,
  ContentWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: ${p => p.theme.pxMaxContentWidth}px;
    margin: auto;
    z-index: ${p => p.theme.zLocalTop};
  `,
  BigLoader = styled(LoadingIcon)`
    width: 75px;
    margin: auto;
    margin-top: 50px;
  `,
  LogoutButton = styled(Button)`
    margin-right: ${p => p.theme.emBaseSpacing}em;
  `,
  BrandedPage: React.FC<BrandedPageProps> = ({ children, documentTitle, headerText, whichHome, accountMenu, showLoader, className }) => {
    const
      THEME = useTheme(),
      dispatch = useAppDispatch(),
      navigate = useNavigate(),
      { learnerId, userId } = useSession(),
      logOut: React.MouseEventHandler<HTMLButtonElement> = () => {
        if (learnerId) {
          dispatch(learnerLoggedOut())
        }
        if (userId) {
          dispatch(userLoggedOut())
        }
        navigate(whichHome === 'teaching' ? '/educate' : '/')
      },
      homePath = learnerId
        ? '/project-home'
        : whichHome === 'teaching'
          ? userId ? '/projects' : '/educate'
          : '/'

    return (
      <BrandedPageOuter {...{ className, documentTitle }} dataCy="branded-page">
        <HeaderOuter>
          <HeaderInner>
            <Brand to={homePath}>
              <img src={Logo} alt="Brand logo" />
            </Brand>
            <Heading>
              {headerText.map((el) =>
                <HeadingText key={el}>{el}</HeadingText>
              )}
            </Heading>
            {userId
              ? <LogoutButton linkProps={{ to: '/account' }} label="Account" size="medium" color="forward" />
              : null}
            {!!learnerId || userId
              ? <LogoutButton onClick={logOut} label="Log Out" size="medium" color="forward" />
              : accountMenu}
          </HeaderInner>
        </HeaderOuter>
        <BrandedPageBody>
          <PageBackground color={THEME.colors.brandLight} />
          <ContentWrapper>
            {showLoader
              ? <BigLoader />
              : children}
          </ContentWrapper>
        </BrandedPageBody>
      </BrandedPageOuter>
    )
  }

export default BrandedPage
