import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Irrigation timing unit',
  description = 'The unit (days, weeks, etc.) for the irrigation timing value.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.irrigationTimingUnit,
    fieldType: 'STRING',
    options: [
      { optionValue: 'day(s)' },
      { optionValue: 'week(s)' }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.irrigationTimingUnit,
    variableGroup: SystemFieldGroups.CONTROL_VARIABLES,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
