import * as React from 'react'
import styled from 'styled-components'
import CardHeader, { emBaseCardHeaderHeight } from '~/components/molecules/CardHeader'
import CardFooter from '~/components/molecules/CardFooter'

interface Props {
  heading: React.ReactNode
  headerIcon: React.ReactNode
  children?: React.ReactNode
  leftButton?: React.ReactNode
  rightButton?: React.ReactNode
  headerOnClick?: React.MouseEventHandler
  isInline?: boolean
  neutralBackground?: boolean
  naturalHeight?: boolean
  /* Actual place in the rendered list - not the index of the entry */
  functionalIndex?: number
}

const
  emSpaceBetween = 1,
  ExpandedRowOuter = styled.div<{ $isInline?: boolean, $naturalHeight?: boolean, $hasFooter?: boolean, $neutralBackground?: boolean, $functionalIndex?: number }>`
    display: flex;
    flex-direction: column;
    min-height: ${p => p.$naturalHeight ? 'unset' : `${p.theme.emMediumContentWidth * 0.75}em`};
    background: ${p => p.$neutralBackground ? p.theme.colors.containerBg : p.theme.colors.containerAccentBg};
    ${p => p.$isInline ? `box-shadow: 1px 1.5px 4px 0px ${p.theme.colors.shadow};` : ''}

    ${p => p.$isInline && p.$hasFooter ? `margin-bottom: ${emSpaceBetween + 1}em;` : p.$isInline ? `margin-bottom: ${emSpaceBetween}em;` : `margin-bottom: ${p.theme.emBaseSpacing * 1.5}em;`}

    ${p => !!p.$isInline || p.$functionalIndex === undefined
        ? ''
        : `align-self: flex-start; margin-top: ${(emBaseCardHeaderHeight + emSpaceBetween) * p.$functionalIndex}em;`} // This becomes slightly misaligned starting about 75 entries down the list - not a problem right now
  `,
  FormCardHeader = styled(CardHeader)<{ $neutralBackground?: boolean }>`
    background: ${p => p.$neutralBackground ? p.theme.colors.containerBg : p.theme.colors.containerAccentBg};

    .card-header__heading {
      white-space: normal;
    }
  `,
  CardBody = styled.div<{ $isInline?: boolean }>`
    flex: ${p => p.$isInline ? 1 : 0} 0 auto;
    padding: 1em;
    padding-top: 0.45em;
    display: flex;
    flex-direction: column;
  `,
  StyledCardFooter = styled(CardFooter)`
    overflow: visible;
    flex: 0 0 auto;
    padding: 15px;
    margin-bottom: -27px;

    > * {
      overflow: visible;
    }
  `,
  ExpandedRow: React.FC<Props> = ({ children, leftButton, rightButton, heading, headerIcon, isInline, neutralBackground, naturalHeight, headerOnClick, functionalIndex }) => {
    const hasFooter = !!(leftButton ?? rightButton)

    return <ExpandedRowOuter $isInline={isInline} $naturalHeight={!!naturalHeight || !children} $hasFooter={hasFooter} $neutralBackground={neutralBackground} $functionalIndex={functionalIndex} data-cy="open-card">
      <FormCardHeader
        $neutralBackground={neutralBackground}
        onClick={headerOnClick}
        icon={headerIcon}
        heading={heading}
      />
      {children
        ? <>
            <CardBody $isInline={isInline}>
              {children}
            </CardBody>
            {hasFooter
              ? <StyledCardFooter
                  contentLeft={leftButton}
                  contentRight={rightButton}
                />
              : null}
          </>
        : null}
    </ExpandedRowOuter>
  }

export default ExpandedRow
export { ExpandedRowOuter, CardBody, StyledCardFooter }
