import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'
import { MEASUREMENT_TIMING_SCHEMES } from './measurementTimingScheme'

const
  MEASUREMENT_REPEAT_AFTER_WATERING = 'hours after watering',
  title = 'Unit for timing interval between measurements',
  description = 'Provides a reference point for the measurement interval so that it can be accurately incorporated into the schedule.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.measurementRepeatUnit,
    fieldType: 'ENUM',
    options: [
      {
        optionValue: MEASUREMENT_REPEAT_AFTER_WATERING,
        visibleWhen: [{
          valueName: SystemFieldNames.dependentVariable,
          conditionType: 'matches',
          testValue: 'soil'
        }]
      },
      {
        optionValue: 'day(s)',
        visibleWhen: [{
          valueName: SystemFieldNames.measurementTimingScheme,
          conditionType: 'equals',
          testValue: MEASUREMENT_TIMING_SCHEMES.REPEATING
        }]
      },
      {
        optionValue: 'week(s)',
        visibleWhen: [{
          valueName: SystemFieldNames.measurementTimingScheme,
          conditionType: 'equals',
          testValue: MEASUREMENT_TIMING_SCHEMES.REPEATING
        }]
      }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.measurementRepeatUnit,
    variableGroup: SystemFieldGroups.SCHEDULE,
    shouldFollow: [SystemFieldNames.measurementTimingScheme],
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails,
  MEASUREMENT_REPEAT_AFTER_WATERING
}
