import { SystemFieldNames, SystemFieldGroups, getTreatmentGroupsTitle, getTreatmentGroupsDescription, INDEPENDENT_VARIABLES } from './systemFieldConstants'
import { fieldDetails as irrigationRateControlGroup } from './irrigationRateControlGroup'

import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  independentVariable = INDEPENDENT_VARIABLES.IRRIGATION_RATE,
  schemaEntry: QField = {
    fieldName: SystemFieldNames.irrigationRateTreatmentGroups,
    fieldType: 'COLLECTION',
    visibleWhen: irrigationRateControlGroup.schemaEntry.visibleWhen,
    format: irrigationRateControlGroup.schemaEntry.format
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.irrigationRateTreatmentGroups,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    isRequired: true,
    title: getTreatmentGroupsTitle(independentVariable),
    description: getTreatmentGroupsDescription(independentVariable),
    treatmentGroupsFor: independentVariable,
    schemaEntry
  }

export {
  fieldDetails
}
