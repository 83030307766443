import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { userLoggedOut } from '../session/sessionSlice'

export interface AdminSummary {
  numUsers?: number
  numResearchers?: number
  numTeachers?: number
  numUsersActiveLastSixMonths?: number
  numResearchersActiveLastSixMonths?: number
  numTeachersActiveLastSixMonths?: number
  numNewUsersLastSixMonths?: number
  numNewResearchersLastSixMonths?: number
  numNewTeachersLastSixMonths?: number
  numProjects?: number
  numParticipants?: number
  numSurveyResponses?: number
  numExperiments?: number
  numLearners?: number
  numWorksheetResponses?: number
}

interface AdminState {
  hasInitializedAdminData: boolean
  summary: AdminSummary
}

const
  initialState: AdminState = {
    hasInitializedAdminData: false,
    summary: {}
  },
  adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
      adminSummaryReceived (state, action: PayloadAction<AdminSummary>) {
        state.summary = action.payload
        state.hasInitializedAdminData = true
      }
    },
    extraReducers: (builder) => {
      builder.addCase(userLoggedOut, (state) => {
        return initialState
      })
    }
  })

export const {
  adminSummaryReceived
} = adminSlice.actions
export default adminSlice.reducer
