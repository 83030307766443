import * as React from 'react'
import styled from 'styled-components'
import { isAllEmpty, isEmptyValue } from '~/utils/testers'
import { inputIdFromBodyElement } from '~/utils/formatters'
import FormFieldWrapper from '~/components/molecules/FormElements/FormFieldWrapper'
import FauxSelect from '~/components/atoms/FauxSelect'

import type { FullOptionList, ListValue } from '~/form-brain2'
import type { InputProps } from '~/types/forms'
import type { SelectInput as SelectInputType } from '~/features'

interface SelectProps extends InputProps {
  list: FullOptionList
  bare?: boolean
  className?: string
}

const
  SelectInput = styled(FauxSelect)`
    flex: 0 1 auto;
    min-width: 175px;
  `,
  Select: React.FC<SelectProps> = (inputProps) => {
    const
      { name, currentValue, updateAttr, errors, showError, isDisabled, isReadOnly, list, placeholder, className, pathToAttr, bare } = inputProps,
      bodyElement = inputProps.bodyElement as SelectInputType,
      inputId = inputIdFromBodyElement(bodyElement, pathToAttr),
      selected = isEmptyValue(currentValue)
        ? null
        : currentValue as ListValue,
      hasError = showError && !isAllEmpty(errors),
      /* DEV: Changing something here? Consider changing it in Combobox too */
      options = list.map(o => (
        o.value === currentValue && o.hidden
          ? { ...o, hidden: false, disabled: true, label: `${o.label ?? o.value ?? ''} (unavailable)` } // If current value would be hidden, show but disable it
          : o
      )),
      inputElement = (
        <SelectInput
          name={name}
          id={inputId}
          options={options}
          selected={selected as unknown as ListValue}
          placeholder={placeholder}
          onSelect={updateAttr}
          hasError={hasError}
          naturalWidth={!!bodyElement.naturalWidth}
          errNarrow={!!bodyElement.errNarrow}
          disabled={!!isDisabled || !list?.length}
          readOnly={isReadOnly}
        />
      )

    return bare
      ? inputElement
      : <FormFieldWrapper className={className} inputProps={inputProps} inputId={inputId} noWrapper>
        {inputElement}
      </FormFieldWrapper>
  }

export default Select
