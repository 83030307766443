import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { mapValues } from 'lodash'
import styled, { useTheme } from 'styled-components'
import { Form } from '~/form-brain2'
import {
  defaultListWorksheetValues,
  defaultPagedWorksheetValues,
  defaultHubOrDownloadsWorksheetValues,
  newStepSchema,
  getNewStepFormBody
} from '~/features/worksheet/formHelpers'
import { useAddEntity, useFormVitalsSubscription, useTemplateFormBody } from '~/hooks'
import { experimentFormResolvers } from '~/features'
import { Button, DashedLine, FormLabel } from '~/components/atoms'
import { FormRenderer } from '~/components/molecules'

import type { FormOnSubmitFn } from '~/form-brain2'
import type { Template, TemplateSet, Worksheet } from '~/features'
import type { ObjectId } from '~/types/utilities'
import type { EntityObject, FormOnSuccessFn } from '~/form-brain2/types'

interface NewStepFormProps {
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void
  templateSet: TemplateSet
}

const
  formName = 'new-template-form',
  NewStepFormOuter = styled.div`
    margin-bottom: ${p => p.theme.emBaseSpacing}em;
  `,
  FormActions = styled.div`
    display: flex;
    flex-direction: row;

    > :first-child {
      margin-right: auto;
    }
  `,
  HR = styled(DashedLine)`
    margin: ${p => p.theme.emBaseSpacing * 2}em 0;
  `,
  newTemplateFormBody = getNewStepFormBody(true),
  newTemplateSchema = mapValues(
    newStepSchema,
    o => ({
      ...o,
      requiredWhen: o.requiredWhen?.map(o => o.valueName === 'basedOnType' ? { ...o, valueName: 'basedOnId' } : o),
      visibleWhen: o.visibleWhen?.map(o => o.valueName === 'basedOnType' ? { ...o, valueName: 'basedOnId' } : o)
    })
  ),
  getDefaultEntity = (templateSetId: ObjectId): Partial<Template> => ({
    templateSetId
  }),
  NewStepForm: React.FC<NewStepFormProps> = ({ onCancel, templateSet }) => {
    const
      THEME = useTheme(),
      navigate = useNavigate(),
      { addEntity } = useAddEntity(),
      { formStatus, subscribeToFormStatus } = useFormVitalsSubscription(),
      { formBody } = useTemplateFormBody(newTemplateFormBody, templateSet.id),
      onSubmit: FormOnSubmitFn = ({ values }) => {
        return addEntity({
          type: 'template',
          values: values.format === 'LIST'
            ? { ...values, ...defaultListWorksheetValues(templateSet) }
            : values.format === 'PAGED'
              ? { ...values, ...defaultPagedWorksheetValues(templateSet) }
              : values.format === 'DOWNLOADS' || values.format === 'HUB'
                ? { ...values, ...defaultHubOrDownloadsWorksheetValues }
                : values
        })
      },
      onSuccess: FormOnSuccessFn = (result) => {
        const newStep = result.payload as Worksheet | undefined

        if (newStep) {
          navigate(`/templates/${newStep.id}`)
        }
      }

    return <NewStepFormOuter>
      <HR orientation="horizontal" color={THEME.colors.borderAccent} />
      <FormLabel
        label="New Step Template"
        showLabel
        asHeading
      />
      <Form
        formId={formName}
        formBody={formBody}
        entitySchema={newTemplateSchema}
        initialValues={getDefaultEntity(templateSet.id) as unknown as EntityObject}
        onSubmitCallback={onSubmit}
        onSuccessCallback={onSuccess}
        FormRenderer={FormRenderer}
        subscribeToFormStatus={subscribeToFormStatus}
        formOptions={{ RootFormRenderer: FormRenderer }}
        {...experimentFormResolvers}
      />
      <FormActions>
        <Button onClick={onCancel} label="Cancel" color="back" size="small" />
        {!formStatus || ['clean', 'cleanAfterSuccess'].includes(formStatus)
          ? null
          : <Button
            type="submit"
            isLoading={formStatus === 'processing'}
            form={formName}
            label="Continue"
            color="forward"
            size="small"
          />}
      </FormActions>
    </NewStepFormOuter>
  }

export default NewStepForm
