import React from 'react'
import styled from 'styled-components'
import { isEqual } from 'lodash'
import { Form } from '~/form-brain2'
import { WorksheetHelpers, experimentFormResolvers } from '~/features'
import { getSectionList } from '~/features/worksheet/helpers'
import { listSectionFormBody, specialListSectionFormBody } from '~/features/worksheet/sectionElementFormDetails'
import { ButtonWithConfirm, FormRenderer } from '~/components/molecules'
import type { EntityObject, SubscribeToFormStatusFn } from '~/form-brain2'
import type { Experiment, ListEntry, ListSectionElement, ListWorksheet, PagedWorksheet, SpecialListSectionElement } from '~/features'
import { HintText } from '~/components/atoms'

interface Props {
  experiment: Experiment
  worksheet: ListWorksheet
  allWorksheets: Array<ListWorksheet | PagedWorksheet>
  section: ListSectionElement
  updateWorksheet: (updatedWorksheet: ListWorksheet) => void
}

const
  StyledForm = styled(Form)`
    background: ${p => p.theme.colors.containerBg};
    padding: ${p => p.theme.emSmallSpacing}em;
  `,
  Outer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: ${p => p.theme.emSmallSpacing}em;
  `,
  ListSectionSettingsForm: React.FC<Props> = ({ experiment, worksheet, allWorksheets, section, updateWorksheet }) => {
    const
      formBody = (section as SpecialListSectionElement).specialSectionId
        ? [
            ...specialListSectionFormBody,
            ...listSectionFormBody
          ]
        : listSectionFormBody,
      updateSection = (nextValues: EntityObject | undefined, otherUpdates?: Partial<ListWorksheet>): void => {
        console.log({ otherUpdates })
        updateWorksheet({
          ...WorksheetHelpers.replaceSectionInWorksheet(worksheet, section, nextValues as unknown as ListSectionElement | undefined),
          ...(otherUpdates ?? {})
        })
      },
      updateUpstream: SubscribeToFormStatusFn = ({ values: nextValues }) => {
        if (!isEqual(section, nextValues)) {
          updateSection(nextValues)
        }
      },
      removeSection: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        const otherUpdates: Partial<ListWorksheet> = {}

        if (worksheet.exampleResponses && worksheet.exampleResponses.entries) {
          otherUpdates.exampleResponses = {
            entries: (worksheet.exampleResponses.entries as ListEntry[]).filter(o => o.sectionId !== section.id)
          }
        }

        if (worksheet.defaults) {
          otherUpdates.defaults = worksheet.defaults.filter(o => o.sectionId !== section.id)
        }

        if (worksheet.walkthrough) {
          otherUpdates.walkthrough = worksheet.walkthrough.filter(o => o.sectionId !== section.id)
        }

        updateSection(undefined, otherUpdates)
      },
      canRemove = getSectionList(worksheet).length > 1

    return <Outer>
      <StyledForm
        {...experimentFormResolvers}
        formId={
          section.elementVariety === 'LIST_OPENER_SECTION'
            ? 'opener-section-settings'
            : section.elementVariety === 'LIST_CLOSER_SECTION'
              ? 'closer-section-settings'
              : 'list-section-settings'
        }
        initialValues={section as unknown as EntityObject}
        FormRenderer={FormRenderer}
        formBody={formBody}
        onSubmitCallback={() => Promise.resolve({})}
        subscribeToFormStatus={updateUpstream}
        formOptions={{ RootFormRenderer: FormRenderer, worksheetsThisExperiment: allWorksheets }}
      />
      {canRemove
        ? <ButtonWithConfirm
          onConfirm={removeSection}
          confirmationBody='This section will not be removed permanently until you save all changes to the step format. If you want this section back, you can leave the page without saving - but you will lose any other changes you have made since you last saved.'
          buttonProps={{ size: 'medium', label: 'Remove Section', color: 'caution' }}
          cancelLabel='Keep Section'
          confirmLabel='Remove Section'
          subheading='Remove section (and related examples and defaults) from draft'
          />
        : <HintText color="default">This is the only section. If you want to remove it, add another section first.</HintText>}
    </Outer>
  }

export default ListSectionSettingsForm
