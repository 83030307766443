import * as React from 'react'
import styled from 'styled-components'
import { interpolateString } from '~/features'
import { symbolSafeStartCase } from '~/utils/formatters'
import { getNewEntryInitialValues } from '../ListStep/helpers'
import { getDynamicString } from '~/features/experiment/helpers'
import { Button, Icon, Notepad } from '~/components/atoms'
import { ExpandedRow } from '~/components/molecules'
import type { Experiment, SpecialListSectionElement, ListEntry, ListResponse } from '~/features'
import SummaryStatsTable from './SummaryStatsTable'

import type { FormProps, EntityObject } from '~/form-brain2'

interface Props {
  experiment: Experiment
  section: SpecialListSectionElement
  response: ListResponse
  otherEntities: EntityObject[]
  entry: ListEntry
  onClose?: React.MouseEventHandler
  isNewNonWalkthrough?: boolean
  isUpdating?: boolean
  onSubmitCallback: FormProps['onSubmitCallback']
}

const
  WideButton = styled(Button)`
    min-width: 110px;
  `,
  SummaryStatsCard: React.FC<Props> = ({ experiment, section, response, entry, otherEntities, isNewNonWalkthrough, onSubmitCallback, isUpdating, onClose }) => {
    const
      heading = interpolateString('Summary Statistics: --dependentVariable--', otherEntities) ?? 'Summary Statistics',
      addEntry: React.MouseEventHandler = (e) => {
        if (e) { e.stopPropagation(); e.preventDefault() }

        onSubmitCallback({ values: getNewEntryInitialValues(section, response.responses.entries, otherEntities) })
          .then(() => { if (onClose) { onClose(e) } })
          .catch(() => { /* no-op, errors are handled up a level */ })
      },
      leftButton = onClose
        ? <Button
          onClick={onClose}
          color="caution"
          size="small"
          label={isNewNonWalkthrough ? 'Cancel' : 'Close'}
          isDisabled={isUpdating} />
        : undefined,
      rightButton = isNewNonWalkthrough
        ? <WideButton
            color="forward"
            withIcon="checkmark"
            size="small"
            onClick={addEntry}
            isLoading={isUpdating}
            label={getDynamicString(experiment, 'listItemSave')}
          />
        : undefined

    return (
      <ExpandedRow
        isInline
        heading={symbolSafeStartCase(heading)}
        headerIcon={<Icon content={Notepad} degreesRotation={16} color="hero" />}
        leftButton={leftButton}
        rightButton={rightButton}
        neutralBackground>
        <SummaryStatsTable {...{ experiment, section, response, entry, otherEntities }} />
      </ExpandedRow>
    )
  }

export default SummaryStatsCard
