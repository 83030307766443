import * as React from 'react'
import styled from 'styled-components'
import { isEmptyValue, isAllEmpty } from '~/utils/testers'
import { inputIdFromBodyElement } from '~/utils/formatters'
import { useFocusOnToggle } from '~/hooks'
import { FauxSelect, BaseInput, BasicCross, Icon, WithUnderline } from '~/components/atoms'
import FormFieldWrapper from '~/components/molecules/FormElements/FormFieldWrapper'

import type { AttrVal, FullOptionList, ListValue } from '~/form-brain2'
import type { SelectInput as SelectInputElement } from '~/features'
import type { InputProps } from '~/types/forms'

interface SelectProps extends InputProps {
  list: FullOptionList
  bare?: boolean
  className?: string
}

const
  SelectInput = styled(FauxSelect)`
    flex: 0 1 auto;
    min-width: 175px;
  `,
  Input = styled(BaseInput)<{ $hasError?: boolean }>`
    flex: 0 1 auto;
    min-width: ${p => 225 * Math.max(((p.value as string | string[] | undefined)?.length ?? 0) / 30, 1)}px;
    width: auto;
  `,
  XIcon = styled(Icon)`
    position: absolute;
    width: 1em;
    right: 0.75em;
    top: 1em;
  `,
  CUSTOM_OPTION_VALUE = '__custom',
  getInputHasCustomValue = ({ baseOptions, currentValue }: {
    baseOptions?: FullOptionList | null
    currentValue: ListValue
  }): boolean | undefined => {
    const values = (baseOptions ?? []).map(o => o.value)

    return !!currentValue && !values.includes(currentValue)
  },
  Combobox: React.FC<SelectProps> = (inputProps) => {
    const
      { name, currentValue: uncastCurrentValue, updateAttr, errors, showError, isDisabled, isReadOnly, list, placeholder, className, pathToAttr, bare } = inputProps,
      bodyElement = inputProps.bodyElement as SelectInputElement,
      customOptionLabel = bodyElement.customOption,
      hasActiveValues = list.some(o => !isEmptyValue(o.value) && !o.hidden && !o.disabled),
      currentValue = (uncastCurrentValue as ListValue),
      hasCustomValue = getInputHasCustomValue({ baseOptions: list, currentValue }),
      [showInput, setShowInput] = React.useState(!!hasCustomValue || !hasActiveValues),
      inputRef = useFocusOnToggle(showInput),
      inputId = inputIdFromBodyElement(bodyElement, pathToAttr),
      hasError = showError && !isAllEmpty(errors),
      /* DEV: Changing something here? Consider changing it in Select too */
      options = [
        ...(list ?? []),
        {
          value: CUSTOM_OPTION_VALUE,
          label: customOptionLabel ?? 'Other',
          disabled: false
        }
      ],
      optionsFlagged = options.map(o => (
        o.value === currentValue && o.hidden
          ? { ...o, hidden: false, disabled: true, label: `${o.label ?? o.value ?? ''} (unavailable)` } // If current value would be hidden, show but disable it
          : o
      )),
      onSelect = (newVal: AttrVal): void => {
        setShowInput(newVal === CUSTOM_OPTION_VALUE)
        updateAttr(newVal === CUSTOM_OPTION_VALUE ? '' : newVal)
      },
      inputOnChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        updateAttr(e.currentTarget.value)
      },
      hideInput: React.MouseEventHandler = e => {
        if (e) { e.preventDefault(); e.stopPropagation() }
        setShowInput(false)
        updateAttr(undefined)
      },
      inputElement = (
        showInput
          ? <>
              <Input
                name={name}
                ref={inputRef}
                id={inputId}
                $hasError={hasError}
                value={currentValue ?? undefined}
                onChange={inputOnChange}
                readOnly={isReadOnly}
                disabled={isDisabled}
                placeholder={bodyElement.customPlaceholder ?? 'Type here'}
                data-cy="combobox"
              />
              {/* This works because FormFieldWrapper puts a position: relative wrapper around the input in order to position the dotted line */}
              {!!isReadOnly || isDisabled
                ? null
                : <XIcon content={BasicCross} degreesRotation={45} frameStyle="plain" color="understate" onClick={hideInput} />}
            </>
          : <SelectInput
            name={name}
            id={inputId}
            innerRef={inputRef}
            options={optionsFlagged}
            selected={currentValue}
            placeholder={placeholder}
            onSelect={onSelect}
            hasError={hasError}
            naturalWidth={!!bodyElement.naturalWidth}
            errNarrow={!!bodyElement.errNarrow}
            disabled={isDisabled}
            readOnly={isReadOnly}
          />
      )

    return bare
      ? showInput
        ? <WithUnderline>{inputElement}</WithUnderline>
        : inputElement
      : <FormFieldWrapper inputProps={inputProps} className={className} inputId={inputId} noWrapper={!showInput}>
        {inputElement}
      </FormFieldWrapper>
  }

export default Combobox
