import React from 'react'
import styled from 'styled-components'
import { AlertText } from '~/components/atoms'
import { BotanicalFrame } from '~/components/molecules'
import type { Experiment, SpecialContentId } from '~/features'
import type { EntityObject } from '~/form-brain2'
import ResultsExplorer from '../SpecialContentArea/ResultsExplorer'

interface Props {
  experiment: Experiment
  contentId: SpecialContentId
  responseEntities: EntityObject[]
}

const
  PlaceholderAlertText = styled(AlertText)`
    position: absolute;
    width: 100%;
    top: 46%;
  `,
  SpecialContentArea: React.FC<Props> = ({ experiment, contentId, responseEntities }) => {
    if (contentId === 'RESULTS_EXPLORER') {
      return <ResultsExplorer experiment={experiment} responseEntities={responseEntities} />
    }

    return <div style={{ position: 'relative' }}>
      <BotanicalFrame />
      <PlaceholderAlertText color="accent">Coming soon!</PlaceholderAlertText>
    </div>
  }

export default SpecialContentArea
