import * as React from 'react'
import styled from 'styled-components'

interface HeadingGroupProps {
  heading: React.ReactNode
  subheading?: React.ReactNode
  fullWidth?: boolean
  className?: string
}

const
  HeadingGroupOuter = styled.hgroup<{ $headingOnly: boolean, $fullWidth?: boolean }>`
    width: 100%;
    margin-left: 4px;
    margin-top: ${p => p.theme.emSmallSpacing * 1.5}em; // 18px
    padding-right: ${p => p.$fullWidth ? 0 : (p.theme.emBaseSpacing * 5.7)}em; // 77px
    margin-bottom: ${p => p.theme.emSmallSpacing * 1.75}em; // 11px

    @media(min-width: ${p => p.theme.pxFullScreenCutoffWidth}px) {
      margin-top: ${p => p.theme.emSmallSpacing * (p.$headingOnly ? 2.3 : 2.7)}em; // 18px
      margin-bottom: ${p => p.theme.emBaseSpacing * 1.4}em; // 18.5px
    }
  `,
  HeadingGroup: React.FC<HeadingGroupProps> = ({ heading, subheading, fullWidth, className }) => {
    return <HeadingGroupOuter className={className} $headingOnly={!subheading} $fullWidth={fullWidth}>
      {subheading}
      {heading}
    </HeadingGroupOuter>
  }

export default HeadingGroup
