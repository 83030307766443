import * as React from 'react'
import { Form } from '~/form-brain2'
import { useAddEntity, useFormVitalsSubscription, useTemplateSetFormBody } from '~/hooks'
import { experimentFormResolvers } from '~/features'
import { FormRenderer, NewEntityFormOuter } from '~/components/molecules'

import type { FormOnSubmitFn } from '~/form-brain2'

interface NewTemplateSetFormProps {
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void
}

const
  NewTemplateSetForm: React.FC<NewTemplateSetFormProps> = ({ onCancel }) => {
    const
      { addEntity } = useAddEntity(),
      { formStatus, subscribeToFormStatus } = useFormVitalsSubscription(),
      onSubmit: FormOnSubmitFn = ({ values }) => {
        return addEntity({ values, type: 'templateSet' })
      },
      { schema, formBody } = useTemplateSetFormBody(),
      formName = 'new-template-set-form'

    return (
      <NewEntityFormOuter
        title="New Project Template"
        formStatus={formStatus}
        formName={formName}
        onCancel={onCancel}
        >
        <Form
          formId={formName}
          entitySchema={schema}
          formBody={formBody}
          onSubmitCallback={onSubmit}
          FormRenderer={FormRenderer}
          subscribeToFormStatus={subscribeToFormStatus}
          formOptions={{ RootFormRenderer: FormRenderer }}
          {...experimentFormResolvers}
        />
      </NewEntityFormOuter>
    )
  }

export default NewTemplateSetForm
