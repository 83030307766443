import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { SessionTypes } from '~/services/api'
import type { ObjectId } from '~/types/utilities'

export interface RaceOperationInfo {
  key: string
  operationId: string
}

interface SessionState {
  loading: boolean
  hasInitializedData: boolean
  received401ForSessionType?: typeof SessionTypes[number]
  accessToken?: string
  refreshToken?: string
  primaryParticipantId?: ObjectId // Set when a non-logged-in user finds a participant from the landing page
  learnerId?: ObjectId // Set when learner authenticates using an access key
  clientId?: string
  userId?: string
  winningOperationIds: Record<string, string | undefined>
}

interface ParsedToken {
  exp?: number
  user_id?: string
  learner_id?: string
  is_admin?: boolean
  is_researcher?: boolean
  is_teacher?: boolean
  is_participant?: boolean
}

type CachedSession = Pick<SessionState, 'clientId' | 'userId' | 'learnerId' | 'accessToken' | 'refreshToken'>

const
  getCachedSession = (): CachedSession => {
    const
      clientId = localStorage.getItem('clientId') ?? undefined,
      userId = localStorage.getItem('userId') ?? undefined,
      learnerId = localStorage.getItem('learnerId') ?? undefined,
      accessToken = localStorage.getItem('accessToken') ?? undefined,
      refreshToken = localStorage.getItem('refreshToken') ?? undefined

    return {
      clientId,
      userId,
      learnerId,
      accessToken,
      refreshToken
    }
  },
  cacheSessionValues = (values: Partial<CachedSession>): void => {
    const { clientId, userId, learnerId, accessToken, refreshToken } = values

    if (clientId) { localStorage.setItem('clientId', clientId) }
    if (userId) { localStorage.setItem('userId', userId) }
    if (learnerId) { localStorage.setItem('learnerId', learnerId) }
    if (accessToken) { localStorage.setItem('accessToken', accessToken) }
    if (refreshToken) { localStorage.setItem('refreshToken', refreshToken) }
  },
  clearCachedSession = (): void => {
    localStorage.removeItem('userId')
    localStorage.removeItem('learnerId')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  },
  getSessionType = (sessionData?: ParsedToken): typeof SessionTypes[number] => {
    if (sessionData?.learner_id) { return 'learner' }
    if (!!sessionData?.is_admin || sessionData?.is_teacher) {
      return 'teaching'
    }
    if (!!sessionData?.is_researcher || sessionData?.is_participant) {
      return 'research'
    }

    return 'other'
  },
  blankState: SessionState = {
    // Important not to reset clientId unneccessarily
    loading: false,
    hasInitializedData: false,
    received401ForSessionType: undefined,
    accessToken: undefined,
    refreshToken: undefined,
    primaryParticipantId: undefined,
    userId: undefined,
    learnerId: undefined,
    winningOperationIds: {}
  },
  initialState: SessionState = {
    ...blankState,
    ...getCachedSession()
  },
  sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
      clientIdReceived (state, action: PayloadAction<string>) {
        cacheSessionValues({ clientId: action.payload })
        state.clientId = action.payload
      },
      learnerAuthenticated (state, action: PayloadAction<{ id: string, accessToken: string, refreshToken?: string }>) {
        const { accessToken, refreshToken, id: learnerId } = action.payload

        cacheSessionValues({ accessToken, refreshToken, learnerId })
        localStorage.removeItem('userId')
        state.userId = undefined
        state.learnerId = learnerId
        state.accessToken = accessToken
        state.refreshToken = refreshToken
        state.hasInitializedData = false
      },
      learnerLoggedOut (state) {
        clearCachedSession()
        return ({
          ...state,
          ...blankState
        })
      },
      userAuthenticated (state, action: PayloadAction<{ id: string, accessToken: string, refreshToken?: string }>) {
        const { accessToken, refreshToken, id: userId } = action.payload

        cacheSessionValues({ accessToken, refreshToken, userId })
        localStorage.removeItem('learnerId')
        state.learnerId = undefined
        state.userId = userId
        state.accessToken = accessToken
        state.refreshToken = refreshToken
        state.hasInitializedData = false
      },
      userLoggedOut (state) {
        clearCachedSession()
        return ({
          ...state,
          ...blankState
        })
      },
      received401Error (state, action: PayloadAction<{ sessionType: typeof SessionTypes[number] }>) {
        clearCachedSession()
        return {
          ...state,
          ...blankState,
          received401ForSessionType: action.payload.sessionType
        }
      },
      clear401Error (state) {
        state.received401ForSessionType = undefined
      },
      tokenRefreshed (state, action: PayloadAction<{ accessToken: string, refreshToken?: string }>) {
        const { accessToken, refreshToken } = action.payload

        cacheSessionValues({ accessToken, refreshToken })
        state.accessToken = accessToken
        state.refreshToken = refreshToken
      },
      dataInitialized (state) {
        state.hasInitializedData = true
      },
      raceOperationStarted (state, action: PayloadAction<RaceOperationInfo>) {
        const { key, operationId } = action.payload

        state.winningOperationIds[key] = operationId
      },
      raceOperationCompleted (state, action: PayloadAction<RaceOperationInfo>) {
        const { key, operationId } = action.payload

        if (state.winningOperationIds[key] === operationId) {
          state.winningOperationIds[key] = undefined
        }
      },
      raceOperationIgnored (state, action: PayloadAction<RaceOperationInfo>) {
        return state
      }
    }
  })

export const {
  userAuthenticated,
  userLoggedOut,
  learnerAuthenticated,
  learnerLoggedOut,
  received401Error,
  clear401Error,
  tokenRefreshed,
  clientIdReceived,
  dataInitialized,
  raceOperationStarted,
  raceOperationCompleted,
  raceOperationIgnored
} = sessionSlice.actions
export default sessionSlice.reducer
export { getSessionType }
export type { ParsedToken }
