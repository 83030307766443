import * as React from 'react'
import styled from 'styled-components'
import { EmptyContentPlaceholder } from '~/components/molecules'

interface StackingSectionsProps {
  soleSection: React.ReactNode
  firstSection: React.ReactNode
  secondSection: React.ReactNode
  canStackSections?: boolean
  dataCy?: string
}

const
  ListStepOuter = styled.div`
    width: 100%;
    padding-bottom: 2em;

    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: ${p => p.theme.emBaseSpacing}em;
    }
  `,
  OuterTwoColumn = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1em;

    > :first-child {
      flex: 1 1 1px;
      min-width: 0px;
    }

    > :last-child {
      flex: 1 1 1px;
      min-width: 0px;
    }
  `,
  RelativeWrapper = styled.div`
    position: relative;
  `,
  EmptyList = styled(EmptyContentPlaceholder)`
    top: 2em;

    p {
      margin-bottom: ${p => p.theme.emSmallSpacing}em;
    }
  `,
  StackingSections: React.FC<StackingSectionsProps> = ({ firstSection, secondSection, soleSection, dataCy, canStackSections }) => {
    return canStackSections
      ? <ListStepOuter data-cy={dataCy ?? 'list-step'}>{soleSection}</ListStepOuter>
      : <OuterTwoColumn data-cy={dataCy ?? 'list-step'}>
        {firstSection}
        {secondSection}
      </OuterTwoColumn>
  }

export { RelativeWrapper, EmptyList, ListStepOuter, OuterTwoColumn }
export default StackingSections
