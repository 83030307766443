import * as React from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { DOCUMENT_TITLES, HEADER_TEXTS } from '~/utils/strings'
import { useAppSelector, useEntityData } from '~/hooks'
import { ErrorPage, Ul, Li, LeadIn, ContactUsMessage } from '~/components/pages/NotFoundPage'
import { BrandedPage, FlexibleMain } from '~/components/layouts'
import { Button, HeadingOne, Link, SizedLoadingIcon, SubheadingOne } from '~/components/atoms'
import { selectExperimentById, selectLearnerById, selectResponsesByLearnerId, selectWorksheetById, selectWorksheetResponseById, selectWorksheetsByExperimentId } from '~/features/entity/selectors'
import { TeacherResponseEventLog } from '~/components/organisms'
import StepOverviewForm from '~/components/organisms/OverviewForms/StepOverviewForm'

import type { ListWorksheet, PagedWorksheet } from '~/features'
import DeleteConfirmationComplex from '../organisms/DeleteConfirmationComplex'
import useDeleteEntity from '~/hooks/useDeleteEntity'
import { isAllEmpty } from '~/utils/testers'
import { EmptyContentPlaceholder } from '../molecules'

const
  Main = styled(FlexibleMain)`
    min-height: 500px;
  `,
  WorksheetPage = styled.div`
    display: flex;
    flex-direction: column;
    min-height: ${p => p.theme.emMediumContentWidth * 0.9}em;
    background: ${p => p.theme.colors.containerDarkBg};
  `,
  P = styled.p`
    margin-top: 20px;
  `,
  TeacherResponsePage: React.FC = () => {
    const
      { id } = useParams(),
      { dataStatus, isLoading } = useEntityData(),
      { deleteEntity, isDeleting } = useDeleteEntity(),
      navigate = useNavigate(),
      response = useAppSelector(selectWorksheetResponseById(id)),
      learner = useAppSelector(selectLearnerById(response?.learnerId)),
      worksheet = useAppSelector(selectWorksheetById(response?.worksheetId)),
      experiment = useAppSelector(selectExperimentById(response?.experimentId)),
      prevExperimentId = React.useRef(experiment?.id),
      worksheets = useAppSelector(selectWorksheetsByExperimentId(response?.experimentId)),
      worksheetIds = worksheets ? worksheets.map(o => o.id) : undefined,
      responses = useAppSelector(selectResponsesByLearnerId(learner?.id, worksheetIds)),
      deleteResponse = (): void => {
        if (!id || !prevExperimentId.current) { return }

        deleteEntity({ type: 'worksheetResponse', id }).then(
          () => { navigate(`/projects/${prevExperimentId.current as string}/responses`) },
          () => {}
        )
      }

    React.useEffect(() => {
      if (experiment?.id && experiment.id !== prevExperimentId.current) {
        prevExperimentId.current = experiment.id
      }
    }, [experiment?.id])

    if (dataStatus === 'initializing' || dataStatus === 'uninitialized') {
      return <BrandedPage documentTitle={DOCUMENT_TITLES.loading} headerText={HEADER_TEXTS.teaching} whichHome="teaching" showLoader />
    }

    if (!response || !!(response.learnerId && !learner) || !worksheet || !experiment) {
      return <ErrorPage errorTitle="Response Not Found" message="Response Not Found">
        <LeadIn>What happened?</LeadIn>
        <Ul>
          <Li>Check the web address for typos</Li>
          <Li>The project or response you are looking for may have been deleted</Li>
          <Li>You may not have access to this response or its project</Li>
        </Ul>

        <LeadIn>Return to <Link to="/projects">All Projects</Link></LeadIn>
        <ContactUsMessage />
      </ErrorPage>
    }

    const
      isList = worksheet.format === 'LIST',
      responseContent = isAllEmpty(response.responses)
        ? <EmptyContentPlaceholder><P>This response is blank</P></EmptyContentPlaceholder>
        : <StepOverviewForm
          readOnly
          withoutBlurbs
          worksheet={worksheet as ListWorksheet | PagedWorksheet}
          response={response}
          experiment={experiment}
          responses={responses}
        />

    return (
      <BrandedPage
        documentTitle={`Response for ${experiment.name} | AgConnect`}
        whichHome="teaching"
        headerText={HEADER_TEXTS.teaching}>
        <Main
          heading={<HeadingOne>Response for: {worksheet.name}</HeadingOne>}
          subheading={<SubheadingOne>Author: {learner ? learner.displayId : experiment.name}</SubheadingOne>}
          topActions={
            <Button
              label="All Responses"
              withIcon="arrowL"
              color="back"
              size="medium"
              linkProps={{ to: `/projects/${experiment.id}/responses` }}
            />
          }>
          {isLoading
            ? <SizedLoadingIcon />
            : <>
              <TeacherResponseEventLog eventLog={response.eventLog} {...{ experiment, response, worksheets, responses, worksheet }} />
              {isList || isAllEmpty(response.responses)
                ? responseContent
                : <WorksheetPage>
                  {responseContent}
                </WorksheetPage>}
              <DeleteConfirmationComplex
                triggerLabel="Delete Response"
                isProcessing={!!isDeleting}
                isDisabled={!!isLoading}
                onConfirmDelete={deleteResponse}
                subheading={`Delete response by ${learner?.displayId ?? 'whole group'}`}
              />
            </>}
        </Main>
      </BrandedPage>
    )
  }

export default TeacherResponsePage
