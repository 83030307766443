import * as React from 'react'
import styled from 'styled-components'

export type HeadingColor = 'default' | 'alt'

interface HeadingOneProps {
  children: React.ReactNode
  color?: HeadingColor
  className?: string
}

const
  HeadingOneWrapper = styled.h1<{ $color?: HeadingColor }>`
    align-items: center;
    font-size: 1.75rem;
    color: ${p => p.$color === 'alt' ? p.theme.colors.headingAccentTextAlt : p.theme.colors.headingAccentText};
    ${p => p.theme.headingFont}

    @media(min-width: ${p => p.theme.pxFullScreenCutoffWidth}px) {
      font-size: 2rem; // 30px
    }
  `,
  HeadingOne: React.FC<HeadingOneProps> = ({ children, color, className }) => {
    return (
      <HeadingOneWrapper className={className} $color={color}>
        {children}
      </HeadingOneWrapper>
    )
  }

export default HeadingOne
