import { groupBy, sortBy, values } from 'lodash'
import useAppSelector from './useAppSelector'
import { selectTemplateSets, selectTemplates } from '~/features/entity/selectors'
import type { FormBody } from '~/form-brain2/types'
import type { AnyPlainOrInputElement, Template } from '~/features'
import type { ObjectId } from '~/types/utilities'

const
  useTemplateFormBody = (body: AnyPlainOrInputElement[], templateSetId: ObjectId): {
    formBody: FormBody
  } => {
    const
      templateSetsById = useAppSelector(selectTemplateSets),
      sortedTemlateSets = sortBy(values(templateSetsById), 'title'),
      templatesById = useAppSelector(selectTemplates),
      templatesBySetId: Record<ObjectId, Template[]> = groupBy(values(templatesById), 'templateSetId'),
      formBody = body.reduce<AnyPlainOrInputElement[]>((memo, el) => {
        if (el.id === 'stepBasedOnTemplate') {
          return [
            ...memo,
            {
              ...el,
              visibleWhen: undefined,
              requiredWhen: undefined,
              blankOption: 'Start from scratch',
              list: sortedTemlateSets.flatMap(o => (templatesBySetId[o.id] ?? []).map((template: Template) => ({
                optionValue: template.id,
                optionLabel: `${o.title}: ${template.name}` + (o.systemTemplate ? ' (system)' : o.publishedAt ? '' : o.authorId ? ' (draft)' : ' (admin-only draft)'),
                hidden: o.archivedAt
              })))
            }
          ]
        }

        if (el.id === 'stepFormat') {
          return [
            ...memo,
            {
              ...el,
              list: [
                { optionValue: 'PAGED', optionLabel: 'Paged' },
                { optionValue: 'LIST', optionLabel: 'List' },
                { optionValue: 'HUB', optionLabel: 'Hub' },
                { optionValue: 'DOWNLOADS', optionLabel: 'Downloads' }
              ]
            }
          ]
        }

        if (el.id === 'stepBasedOnType') {
          return memo
        }

        return [...memo, el]
      }, [])

    return {
      formBody
    }
  }

export default useTemplateFormBody
