// handleFormStatusChange({ onSubmitCallback, onSuccessCallback, onErrorCallback, values, formStatus, result, setState, submissionSource: _state.submissionSource })

import { FORM_STATUSES } from '../FormContext'
import type { FormState } from '../internalTypes'
import type {
  ResultObject,
  FormOnSubmitFn,
  FormOnErrorFn,
  FormOnSuccessFn,
  EntityObject,
  FormStatus
} from '../types'

interface HandleFormStateChangeParams {
  values: EntityObject
  formStatus: FormStatus
  result: ResultObject
  onSubmitCallback: FormOnSubmitFn
  onErrorCallback?: FormOnErrorFn
  onSuccessCallback?: FormOnSuccessFn
  setState: React.Dispatch<React.SetStateAction<FormState>>
  submissionSource?: string
}

export const
  handleFormStatusChange = (params: HandleFormStateChangeParams): void => {
    const { values, formStatus, setState, result, onSubmitCallback, onSuccessCallback, onErrorCallback, submissionSource } = params

    if (formStatus === FORM_STATUSES.PROCESSING) {
      onSubmitCallback({ values, submissionSource }).then(
        (res: ResultObject) => { setState(state => getSuccessContext({ res, state })) },
        (res: ResultObject) => { setState(state => getErrorContext({ res, state })) }
      )
    }

    if (formStatus === FORM_STATUSES.SUCCESS && onSuccessCallback) {
      onSuccessCallback(result, submissionSource)
    }

    if (formStatus === FORM_STATUSES.FAIL && onErrorCallback) {
      onErrorCallback(result, submissionSource)
    }
  }

const
  getErrorContext = ({ res, state }: { res: ResultObject, state: FormState }): FormState => ({
    ...state,
    formStatus: FORM_STATUSES.FAIL,
    errors: res.errors ?? { _base: ['Something went wrong'] },
    result: res,
    showingAllMissing: false,
    showingAllErrors: true
  }),
  getSuccessContext = ({ res, state }: { res: ResultObject, state: FormState }): FormState => ({
    ...state,
    formStatus: FORM_STATUSES.SUCCESS,
    result: res,
    errors: {},
    showingAllMissing: false,
    showingAllErrors: false
  })
