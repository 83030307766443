import { getSemiRandomString } from '~/utils/strings'
import type { ListWorksheet } from '../types'
import type { SpecialListSectionElement } from '~/features/questionnaire/types'
import type { SpecialOpeningSectionElement } from '~/features/questionnaire/types/sectionElementTypes'

export const
  getDefaultSummaryStatsSection = (sectionId?: string): SpecialOpeningSectionElement => {
    return {
      id: sectionId ?? `summary-stats-opener-${getSemiRandomString()}`,
      elementVariety: 'LIST_OPENER_SECTION',
      title: 'summary stats',
      specialSectionId: 'SUMMARY_STATS', /** TODO dev update types to allow special sections in opening and closing */
      includedSummaryStats: ['range', 'mean', 'SD'],
      permitOutlierRemoval: true,
      detailsLabel: 'You can add notes about this group here',
      body: []
    }
  },
  // getDefaultVisSection = (sectionId?: string): SpecialListSectionElement => {
  //   return {
  //     id: sectionId ?? `vis-list-${getSemiRandomString()}`,
  //     elementVariety: 'LIST_ENTRY_SECTION',
  //     entryNamePlural: 'visualizations',
  //     entryNameSingular: 'visualization',
  //     specialSectionId: 'DATA_VISUALIZATION',
  //     includedChartTypes: ['bar', 'line'],
  //     measurementLabel: 'Measurement:',
  //     seriesAggregationTypes: ['total', 'aggregate total', 'average'],
  //     seriesGroupTypes: ['treatment', 'subject', 'date'],
  //     xAxisRangeLabel: '',
  //     yAxisRangeLabel: '',
  //     body: []
  //   }
  // },
  getDefaultStatsTestSection = (sectionId?: string): SpecialListSectionElement => {
    return {
      id: sectionId ?? `stats-test-list-${getSemiRandomString()}`,
      elementVariety: 'LIST_ENTRY_SECTION',
      entryNamePlural: 'statistical tests',
      entryNameSingular: 'statistical test',
      specialSectionId: 'STATS_TEST',
      minimumLength: 1,
      includedTestTypes: ['t-test', 'ANOVA'],
      pageHint: 'You can view your summary statistics after you save your current statistical test.',
      body: []
    }
  },
  defaultsForAnalysisLayout = (
    summarySectionId?: string,
    // visualizationSectionId?: string,
    statsTestSectionId?: string
  ): Pick<ListWorksheet, 'repeatingSections' | 'openingSection'> => {
    const
      summaryStatsSection = getDefaultSummaryStatsSection(summarySectionId),
      // visSection = getDefaultVisSection(visualizationSectionId),
      statsTestSection = getDefaultStatsTestSection(statsTestSectionId)

    return {
      openingSection: summaryStatsSection,
      repeatingSections: [
        // visSection
        statsTestSection
      ]
    }
  }
