import * as React from 'react'
import type { BasicIconProps } from '~/types/utilities'

const
  chainPath = 'M17.6499 22.3518C18.3332 23.0018 18.3332 24.0685 17.6499 24.7185C16.9999 25.3685 15.9332 25.3685 15.2832 24.7185C12.0332 21.4685 12.0332 16.1852 15.2832 12.9352L21.1832 7.03516C24.4332 3.78516 29.7165 3.78516 32.9665 7.03516C36.2165 10.2852 36.2165 15.5685 32.9665 18.8185L30.4832 21.3018C30.4999 19.9352 30.2832 18.5685 29.8165 17.2685L30.5999 16.4685C32.5665 14.5185 32.5665 11.3518 30.5999 9.40182C28.6499 7.43516 25.4832 7.43516 23.5332 9.40182L17.6499 15.2852C15.6832 17.2352 15.6832 20.4018 17.6499 22.3518ZM22.3499 15.2852C22.9999 14.6352 24.0665 14.6352 24.7165 15.2852C27.9665 18.5352 27.9665 23.8185 24.7165 27.0685L18.8165 32.9685C15.5665 36.2185 10.2832 36.2185 7.0332 32.9685C3.7832 29.7185 3.7832 24.4352 7.0332 21.1852L9.51654 18.7018C9.49987 20.0685 9.71654 21.4352 10.1832 22.7518L9.39987 23.5352C7.4332 25.4852 7.4332 28.6518 9.39987 30.6018C11.3499 32.5685 14.5165 32.5685 16.4665 30.6018L22.3499 24.7185C24.3165 22.7685 24.3165 19.6018 22.3499 17.6518C21.6665 17.0018 21.6665 15.9352 22.3499 15.2852Z',
  BasicChain: React.FC<BasicIconProps> = ({ className, color = 'black', outlineColor }) => {
    return (
      <svg
        className={className}
        viewBox={outlineColor ? '2 2 36 36' : '4 4 32 32'}
        xmlns="http://www.w3.org/2000/svg"
        data-cy="basic-chain">
        {outlineColor
          ? <path
            d={chainPath}
            fill={outlineColor}
            stroke={outlineColor}
            strokeWidth="5px"
          />
          : null}
        <path
          d={chainPath}
          fill={color}
          stroke={color}
          strokeWidth="1px"
        />
      </svg>
    )
  }

export default BasicChain
