import * as React from 'react'
import styled from 'styled-components'
import { interpolateStringInForm } from '~/features'
import { FormContext } from '~/form-brain2'
import { BasicCross, Button, ErrorText, FormLabel, Icon } from '~/components/atoms'

import type { ScopeRendererProps } from '~/form-brain2'
import { inputIdFromBodyElement } from '~/utils/formatters'
import ActionPointer from '../ActionPointer'
import { isAllEmpty } from '~/form-brain2/testers'
import ErrorNotice from '../FormElements/ErrorNotice'

const
  Outer = styled.span`
    margin-top: ${p => p.theme.emSmallSpacing}em;
  `,
  ScopeOuter = styled.div<{ $offset?: boolean }>`
    ${p => p.$offset
      ? `
        padding-left: ${p.theme.emBaseSpacing}em;
        border-left: 2px dashed ${p.theme.colors.borderAlt};
        
        margin-left: ${p.theme.emSmallSpacing * 0.66}em;
      `
      : ''}

    // margin-bottom: ${p => p.theme.emBaseSpacing}em;
    display: flex;
    flex-direction: column;
  `,
  StyledActionPointer = styled(ActionPointer)`
    // transform: scale(1.2);
    margin-bottom: ${p => p.theme.emSmallSpacing}em;
  `,
  DeleteButton = styled(Button)`
    // width: 27px;
    margin-left: auto;
    margin-bottom: ${p => p.theme.emBaseSpacing}em;
  `,
  ScopeRenderer: React.FC<ScopeRendererProps> = ({ name, removeEntry, children, scopeOptions, scopeScope }) => {
    const
      formContext = React.useContext(FormContext),
      { formOptions, errors } = formContext,
      scopedErrors = errors[name] ?? {},
      baseError = '_base' in scopedErrors && scopedErrors._base
        ? Array.isArray(scopedErrors._base)
          ? scopedErrors._base.join(', ')
          : scopedErrors._base
        : undefined,
      [showScope, setShowScope] = React.useState(!!scopeOptions?.hasValues || !scopeOptions?.noInitialEntry),
      inputId = scopeOptions
        ? inputIdFromBodyElement(scopeOptions, scopeScope)
        : `scope-${name}`,
      label = interpolateStringInForm(scopeOptions?.label, formContext),
      showAdder = !showScope && !scopeOptions?.hideLabel && label && !scopeOptions?.asRow,
      isRequired = scopeOptions?.requiredWhen
        ? formContext.resolveCriteria({ bodyElement: scopeOptions, contextAndResolvers: formContext, criteria: 'isRequired' })
        : false,
      showRemover = showScope && !isRequired && !formOptions.isReadOnly && !scopeOptions?.isReadOnly && !scopeOptions?.hideDeleteButton,
      setParentHeading = formOptions.setParentHeading

    React.useEffect(() => {
      if (
        label &&
        scopeOptions?.label &&
        !scopeOptions.hideLabel &&
        setParentHeading
      ) {
        setParentHeading(scopeOptions.label, scopeOptions?.parentId)
      }
    }, [label, scopeOptions?.label, scopeOptions?.hideLabel, scopeOptions?.parentId, setParentHeading])

    return <Outer id={inputId} data-cy="scope-renderer">
      {showAdder && !scopeOptions?.showLabelWithAdder
        ? null
        : <FormLabel
          label={label}
          showLabel={!scopeOptions?.hideLabel}
          asHeading={!scopeOptions?.asRow}
        />}
      {label && showAdder
        ? <StyledActionPointer
          label={`Add ${label}`}
          icon={<Icon content={BasicCross} onClick={() => { setShowScope(true) }} />}
          orientation='left'
        />
        : null}
      {showAdder && !isAllEmpty(scopedErrors)
        ? baseError
          ? <ErrorText>{`${label} ${baseError}`}</ErrorText>
          : <ErrorNotice errors={{}} />
        : null}
      {showAdder
        ? null
        : <ScopeOuter $offset={scopeOptions?.offsetContent}>
          {children}
          {showRemover && removeEntry
            ? <DeleteButton
              color="caution"
              size="small"
              label={`Remove ${scopeOptions?.label ?? 'section'}`}
              onClick={() => {
                removeEntry()
                setShowScope(false)
              }}
            />
            : null}
        </ScopeOuter>}
    </Outer>
  }

export default ScopeRenderer
