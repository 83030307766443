import { pick } from 'lodash'

import type { FormContextType } from '../internalTypes'
import type { ContextAndResolvers } from '../types'

export const
  getContextAndResolvers = (formContext: FormContextType): ContextAndResolvers => pick(formContext, [
    'resolveCriteria', 'resolveHint', 'resolveKey', 'resolveList', 'resolveLabel', 'resolvePlaceholder', 'resolveUnit', 'validateObject', 'scopeOptions',
    'values', 'scope', 'schema', 'formOptions', 'formStatus', 'errors', 'result', 'submissionSource', 'showingAllErrors', 'showingAllMissing', 'initialValues'
  ])
