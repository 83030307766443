import { findLast } from 'lodash'
import type { ListEntry, WorksheetHelpers } from '~/features'
import type { ListEntrySummary } from '../types'
import type { EntityObject } from '~/form-brain2'

const
  getStartingSectionId = ({ entrySummary, sectionsById, entries }: {
    entrySummary: ListEntrySummary
    sectionsById: ReturnType<typeof WorksheetHelpers.getSectionsById>
    entries: EntityObject[]
  }): string | undefined => {
    if (entrySummary.section) {
      return entrySummary.section.id
    }

    if (entrySummary.availableSections && entrySummary.availableSections.length === 1) {
      return entrySummary.availableSections[0].id
    }

    const lastRepeatingEntry = findLast(entries, (o: ListEntry) => sectionsById[o.sectionId]?.elementVariety === 'LIST_ENTRY_SECTION') as ListEntry | undefined

    return lastRepeatingEntry?.sectionId ?? undefined
  }

export default getStartingSectionId
