import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Seed variety',
  description = 'What variety will be grown? When this is on its own, it will be applied to all subjects. When part of a treatment, it will be applied to subjects receiving that treatment.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.seedVariety,
    fieldType: 'STRING'
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.seedVariety,
    variableGroup: SystemFieldGroups.CONTROL_VARIABLES,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
