import type * as React from 'react'

export type AnyObject = Record<string, unknown>

/* ==== */
/* ==== The form */
/* ==== */

export type FormProps = CoreFormProps & Partial<FormResolvers> & FormCallbacks & {
  resolveKey: StrictResolveLookupFn
  methodRef?: FormMethodRef
}

export type FormMethodRef = React.MutableRefObject<Pick<FormActions, 'triggerFormSubmission' | 'toggleShowAllMissing' | 'toggleShowAllErrors'> | undefined>

export interface CoreFormProps {
  formId: string
  baseScope?: string
  className?: string
  formOptions?: FormOptions
  formBody: FormBody
  entitySchema?: EntitySchema

  initialValues?: EntityObject
  initialErrors?: EntityErrors

  FormRenderer: FormRendererComponentType
}

export type FormBody = BodyElement[]
export interface BodyElement { /* Please extend */ } // eslint-disable-line @typescript-eslint/no-empty-interface

export type FormRendererComponentType = React.ComponentType<FormRendererProps>
export interface FormRendererProps {
  formId: string
  className?: string
  formBody: FormBody
  isNested?: boolean
}

/** Override: An escape hatch for passing
 * application-specific information for access
 * through the form context */
export interface FormOptions { /* Please extend */ } // eslint-disable-line @typescript-eslint/no-empty-interface

export interface FormCallbacks {
  onSubmitCallback: FormOnSubmitFn
  subscribeToFormStatus?: SubscribeToFormStatusFn
  onChangeCallback?: FormOnChangeFn
  onAttrChangeCallback?: FormOnAttrChangeFn
  beforeSubmitCallback?: FormBeforeSubmitFn
  onSuccessCallback?: FormOnSuccessFn
  onErrorCallback?: FormOnErrorFn
}

/** A group of functions which provide fine-grained control
 * over form content based on form state. This has many uses
 * including internationalization, string interpolation,
 * lookup of units, and generation of options based on attributes
 * other than that of the current form field */
export interface FormResolvers {
  resolveCriteria: ResolveCriteriaFn
  resolveHint: ResolveLookupFn
  resolveKey: StrictResolveLookupFn
  resolveList: ResolveListFn
  resolveLabel: ResolveLookupFn
  resolvePlaceholder: ResolveLookupFn
  resolveUnit: ResolveLookupFn
  validateObject: ValidateObjectFn
}

/* ==== */
/* ==== Nested forms */
/* ==== Scoped elements and collections */
/* ==== */

export interface FormCollectionManagerProps {
  /** The key for the collection in the parent entity */
  name: string
  /** The body of each collection entity */
  formBody: FormBody
  /** Other options specified in the form body element */
  collectionOptions?: CollectionOptions
  /** Receives the parent context */
  CollectionRenderer: CollectionRendererComponentType
  /** Receives a scoped context */
  CollectionEntryRenderer: FormRendererComponentType
}

export type CollectionRendererComponentType = React.ComponentType<CollectionRendererProps>
export interface CollectionRendererProps {
  name: string
  addEntry: (defaultValues?: EntityObject, insertBeforeIndex?: number) => void
  children: React.ReactNode
  collectionOptions?: CollectionOptions
  collectionScope?: string
  entries?: EntityObject[]
}

export interface CollectionOptions {
  /* Please extend */
  defaultEntry?: EntityObject
  defaultEntries?: EntityObject[]
  isEditing?: boolean
  toggleEditing?: () => void
  removeEntry?: () => void
  addEntry?: CollectionRendererProps['addEntry']
  entryIndex?: number
  isEntryNew?: boolean
  collectionLength?: number
}

export interface FormScopeManagerProps {
  /** The key for the scope entity in the parent entity */
  name: string
  /** The body of the scoped entity */
  formBody: FormBody
  /** Other options specified in the form body element */
  scopeOptions: ScopeOptions
  /** Receives the parent context */
  ScopeRenderer: ScopeRendererComponentType
  /** Receives a scoped context */
  ScopeEntryRenderer: FormRendererComponentType
}

export type ScopeRendererComponentType = React.ComponentType<ScopeRendererProps>
export interface ScopeRendererProps {
  name: string
  addEntry: (defaultValues?: EntityObject) => void
  removeEntry: () => void
  children: React.ReactNode
  scopeOptions?: ScopeOptions
  scopeScope?: string
}

export interface ScopeOptions {
  /* Please extend */
  defaultEntry?: EntityObject
}

/* ==== */
/* ==== Form fields */
/* ==== Inputs managed by this package's FormField component */
/* ==== */

// export interface FormFieldManagerProps {
//   name: AttrName
//   inputConfig?: InputConfig
//   // content: ?FormBodyElement[],
//   schemaOverrides?: Partial<SchemaEntry>
//   FormFieldRenderer: FormFieldRendererComponentType
// }

/** Your component which renders an individual form field */
export type FormFieldRendererComponentType = React.ComponentType<FormFieldRendererProps>

/** The props which your form field renderer will
 * receive from the form manager. Includes all
 * FormFieldDetails attributes plus form-control
 * and state values */
export interface FormFieldRendererProps {
  /* Values from manager */
  /** Callback which updates this form field's value in the form's copy of the subject
   * @param newValue The new value for the attribute */
  updateAttr: UpdateValueFn
  /** Callback which shows/hides this form field's hint */
  toggleHint?: ToggleFn
  /** The current value of this attribute in the form's copy of the subject, when set */
  currentValue?: AttrVal | AttrValArray
  /** Error message(s) to be displayed with the form field */
  errors?: AttrErrors
  /** Content to be rendered by this form field, such as nested fields for a compound input */
  // children?: React.ReactNode

  /* Field details */
  /** Programmatic name which identifies this field within the form (HTML attr) */
  name: string
  /** Path from the subject root to this value, with nested keys separated by a dot */
  pathToAttr: string
  /**  */
  bodyElement: BodyElement

  /* Content values */
  /** The user-facing label/name for the form field */
  label?: string
  /** The placeholder for the form field's input, when relevant */
  placeholder?: string
  /** Help text to be displayed with the form field */
  hint?: string
  /** Unit to be displayed with a, usually numeric, form field */
  unit?: string
  /** Select options for form fields which use them */
  list?: FullOptionList

  /* Coniguration values */
  /** When true, the form has changed this field's value in its copy of the subject (unless all changes were silent updates) */
  isDirty?: boolean
  /** When true, the form field should not be editable - determined by your criteria resolver  */
  isReadOnly?: boolean
  /** When false, the form field should not be displayed at all - determined by your criteria resolver */
  isVisible?: boolean
  /** When true, this value is required - determined by your criteria resolver   */
  isRequired?: boolean
  /** When true, this value is not required but omitting it will limit usage - determined by your criteria resolver */
  isRecommended?: boolean

  /** Additional options provided to the parent form */
  formOptions: FormOptions

  /** When false, the hint should not display */
  showHint?: boolean
  /** When false, the label should not display */
  // showLabel?: boolean
  /** When false, the errors should not display */
  showError?: boolean
  /** When false, the unit should not display */
  // showUnit?: boolean
}
export type UpdateValueFn = (newValue: AttrVal | AttrValArray, silentUpdate?: boolean) => void

// TODO DEV remove if unused
// interface FormFieldOptions { // eslint-disable-line @typescript-eslint/consistent-indexed-object-style
//   /* Please extend */
//   [key: string]: unknown
// }

/* ==== */
/* ==== Entity */
/* ==== What the form descibes */
/* ==== */

export interface EntityObject { // eslint-disable-line @typescript-eslint/consistent-indexed-object-style
  [attrName: string]: AttrVal | AttrValArray | EntityObject | EntityCollection
}
export type EntityCollection = EntityObject[]

export const AttrValTypes = ['string', 'number', 'boolean', 'undefined']

export type AttrVal = StringAttrVal | NumericAttrVal | DateAttrVal | EnumAttrVal | BooleanAttrVal
export type AttrValArray = AttrVal[]
type StringAttrVal = string | undefined
type NumericAttrVal = number | undefined
type DateAttrVal = string | undefined
type EnumAttrVal = string | undefined
type BooleanAttrVal = boolean | undefined

/* ==== */
/* ==== Schema */
/* ==== the shape of the entity */
/* ==== */

export interface EntitySchema { // eslint-disable-line @typescript-eslint/consistent-indexed-object-style
  [attrName: string]: SchemaEntry
}

export interface SchemaEntry { /* Please extend */ } // eslint-disable-line @typescript-eslint/no-empty-interface

/* ==== */
/* ==== Callbacks */
/* ==== events in the form life cycle */
/* ==== */

export const FORM_STATUSES = {
  LOADING: 'loading',
  CLEAN: 'clean',
  CLEAN_AFTER_SUCCESS: 'cleanAfterSuccess',
  DIRTY: 'dirty',
  PROCESSING: 'processing',
  SUCCESS: 'success',
  FAIL: 'fail'
} as const

export type FormStatus = typeof FORM_STATUSES[keyof typeof FORM_STATUSES]

/** Callback providing ref-like access to the form status string
 * to allow components outside the form (such as external submit buttons)
 * to react to the form state
 * @param params.formState The current form lifecycle state string
 * @param submissionSource An optional string provided when calling
 * the renderer's submit method to aid in control flow */
export type SubscribeToFormStatusFn = (params: {
  formStatus: FormStatus
  submissionSource?: string
  values?: EntityObject
}) => void

/** Callback for processing the subject between the making of a
 * change and the updating of the form's copy of the subject,
 * for example do a conversion on a specific attribute
 * @param params.prevValues The form's copy of the subject before the change
 * @param params.nextValues A copy of the subject after merging with new attributes
 * @param params.contextAndResolvers Non-private portion of internal form state */
export type FormOnChangeFn = (params: {
  prevValues: EntityObject
  nextValues: EntityObject
  contextAndResolvers: ContextAndResolvers
}) => EntityObject

/** Callback for processing the an attribute between the making of a
 * change and the updating of the form's copy of the subject,
 * for example to clear related values.
 * @param params.prevValues The form's copy of the subject before the change
 * @param params.nextValues A copy of the subject after merging with new attributes
 * @param params.contextAndResolvers Non-private portion of internal form state */
export type FormOnAttrChangeFn = (params: {
  bodyElement: BodyElement
  nextValue: AttrVal | AttrValArray
  nextValues: EntityObject
  contextAndResolvers: ContextAndResolvers
}) => EntityObject

/** Callback for pre-processing the subject before submission -
 * for example to strip out defunct or form-only values
 * @param params.attrs The updated subject slated for submission
 * @param params.contextAndResolvers Non-private portion of internal form state */
export type FormBeforeSubmitFn = (params: {
  values: EntityObject
  contextAndResolvers: ContextAndResolvers
}) => EntityObject

/** Callback for carrying out actual submission when form is submitted
 * @param values The form's copy of the subject to be submitted
 * @param submissionSource An optional string provided when calling
 * the renderer's submit method to aid in control flow */
export type FormOnSubmitFn = (params: {
  values: EntityObject
  submissionSource?: string // TODO DEV consider a keyof SubmissionSourcesObject version for extendability
}) => Promise<ResultObject>

/** Callback when promise returned by onSubmit rejects
 * @param submissionResult The object resulting from the promise your onSubmit callback
 * @param submissionSource An optional string provided when calling
 * the renderer's submit method to aid in control flow */
export type FormOnErrorFn = (submissionResult: ResultObject, submisssionSource?: string) => void

/** Callback when promise returned by onSubmit resolves
 * @param submissionResult The object resulting from the promise your onSubmit callback
 * @param submissionSource An optional string provided when calling
 * the renderer's submit method to aid in control flow */
export type FormOnSuccessFn = (submissionResult: ResultObject, submissionSource?: string) => void

/* ==== */
/* ==== Results */
/* ==== communicating with the container about sucess and failure */
/* ==== */

export interface ResultObject<T = EntityObject> {
  errors?: EntityErrors
  status?: FormStatus
  message?: string
  payload?: T
  data?: AnyObject
  submissionSource?: string
}

export type AttrErrors = string[] | undefined
export interface EntityErrors {
  _base?: AttrErrors
  [attrName: string]: AttrErrors | EntityErrors | CollectionErrors
}
type CollectionErrors = EntityErrors[]

/* ==== */
/* ==== Renderers */
/* ==== */

export interface ContextAndResolvers extends FormContext, FormResolvers {}

export interface FormContext {
  values: EntityObject
  initialValues: EntityObject
  scope: string
  schema: EntitySchema
  formOptions: FormOptions
  scopeOptions?: ScopeOptions | CollectionOptions

  formStatus: FormStatus
  errors: EntityErrors
  result: ResultObject
  submissionSource?: string

  showingAllErrors: boolean
  showingAllMissing: boolean
}

export interface FormActions {
  updateValues?: UpdateValuesFn
  updateErrors?: UpdateErrorsFn
  onAttrChangeCallback?: FormOnAttrChangeFn
  toggleShowAllMissing: ToggleFn
  toggleShowAllErrors: ToggleFn
  triggerFormSubmission: FormSubmitFunction
}

/** Calling this function will cause the form to be submitted */
export type FormSubmitFunction = (
  event?: React.FormEvent,
  submissionSource?: string
) => void

/** Update the form's copy of the subject by shallowly merging the given into it
 * @param options.newValues The attrubtes to update
 * @param options.silentUpdate When true, the update
 * will not change the form status. This can be useful
 * for things like setting default values on initialize */
export type UpdateValuesFn = (options: { newValues: EntityObject, silentUpdate?: boolean }) => void
export type UpdateErrorsFn = (options: { newErrors: EntityErrors }) => void

/** Any callback which reverses a boolean value */
export type ToggleFn = () => void

/* ==== */
/* ==== Resolvers */
/* ==== */

/** Transform the option value provided in body or schema into
 * the value used by the form field */
export type ResolveListFn = (params: {
  contextAndResolvers: ContextAndResolvers
  bodyElement: BodyElement
  schemaEntry?: SchemaEntry
}) => FullOptionList

/** A list of option objects for a select input */
export type FullOptionList = ListItem[]
/** Complete specification of one option for a select input or similar form field */
export interface ListItem {
  /** The underlying value for this option */
  value: ListValue
  /** A unique identifying key for this option */
  key?: string
  /** The value to display to the user for this option */
  label?: string | number
  /** Set to true if the option should be shown but not selectable */
  disabled?: boolean
  /** Set to true if the option should not be shown */
  hidden?: boolean
}
export type ListValue = string | undefined

/** Function for transforming string values for
 * form fields (label, hint, unit, placeholder) */
export type ResolveLookupFn = (params: {
  contextAndResolvers: ContextAndResolvers
  bodyElement: BodyElement
  schemaEntry?: SchemaEntry
}) => string | undefined
export type StrictResolveLookupFn = (params: {
  contextAndResolvers: ContextAndResolvers
  bodyElement: BodyElement
}) => string

/** Evaluate criteria for boolean values like isRequired
 * and isVisible using custom logic  */
export type ResolveCriteriaFn = (params: {
  contextAndResolvers: ContextAndResolvers
  bodyElement: BodyElement
  schemaEntry?: SchemaEntry
  criteria: 'isReadOnly' | 'isVisible' | 'isRequired' | 'isRecommended' | 'hintDefaultVisible'
}) => boolean

/** Validate an entire object using custom logic
 * and return error string(s) if validation fails
 * @param params.contextAndResolvers Non-private portion of internal form state
 * @param params.schemaEntry The schema entry for the attribute if present
 * @param params.values The object to validate
 * @param params.validationOptions Options which modify the behavior
 * of the validator */
export type ValidateObjectFn = (params: {
  values: EntityObject
  contextAndResolvers: ContextAndResolvers
  schema?: EntitySchema
  validationOptions: ValidationOptions
}) => EntityErrors

export type ValidationSource = 'SUBMIT' | 'CHANGE'
export interface ValidationOptions {
  /* Please extend */
  triggeredBy: ValidationSource
}
