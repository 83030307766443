import * as React from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { useLearnerStepContext, useUpdateEntity } from '~/hooks'
import {
  WorksheetHelpers,
  WorksheetResponseHelpers,
  ExperimentHelpers
} from '~/features'
import { Blurb, ErrorMessage } from '~/components/molecules'
import { CheckmarkIcon } from '~/components/atoms'

import type { PagedWorksheet, WorksheetResponse } from '~/features'
import type { FormProps } from '~/form-brain2'
import PageForm, { type PagedFormSubmissionSource } from './PageForm'

const
  IntroBlurb = styled(Blurb)`
    margin-top: 0em;

    @media(min-width: ${p => p.theme.pxPortraitPrintWidth}px) {
      margin-top: 1em;

      [data-cy="hint-text"] {
         font-size: 1.3rem; 
      }
    }
  `,
  BottomBlurb = styled(Blurb)`
    margin-top: auto;
    min-height: unset;
  `,
  { addSubmissionLog, updateProgressIndices } = WorksheetResponseHelpers,
  { getDynamicString } = ExperimentHelpers,
  PagedStep: React.FC = () => {
    const
      navigate = useNavigate(),
      { sectionIndex: sectionIndexString, pageIndex: pageIndexString } = useParams(),
      stepContext = useLearnerStepContext(),
      {
        experiment,
        response,
        responses,
        isLoadingData,
        isFetchingData,
        setMascotState
      } = stepContext,
      worksheet = stepContext.worksheet as PagedWorksheet,

      sectionIndex = parseInt(sectionIndexString as string),
      pageIndex = parseInt(pageIndexString as string),
      section = worksheet.sections[sectionIndex],
      page = section.pages[pageIndex],
      pageId = `${worksheet.id}-${sectionIndex}-${pageIndex}-form`,

      { updateEntity } = useUpdateEntity(),

      navDetailsForPage = WorksheetHelpers.getNavDetailsForPage({ worksheet, sectionIndex, pageIndex }),
      {
        backNav,
        forwardNav,
        submitOnForward,
        showSkipSubmissionMessage,
        nextPageIndex,
        nextSectionIndex
      } = navDetailsForPage,
      onSubmitCallback: FormProps['onSubmitCallback'] = ({ values, submissionSource }) => {
        const
          updatedResponse = { ...response, responses: values },
          valuesToSubmit = submitOnForward && submissionSource === 'SUBMIT'
            ? addSubmissionLog(updatedResponse as unknown as WorksheetResponse)
            : submissionSource === 'FORWARDS'
              ? updateProgressIndices(updatedResponse, { nextPageIndex, nextSectionIndex })
              : updatedResponse

        return updateEntity({
          entity: response,
          newValues: valuesToSubmit
        })
      },
      onSuccessCallback: FormProps['onSuccessCallback'] = (result, submissionSource) => {
        navigate(
          (submissionSource as PagedFormSubmissionSource) === 'BACKWARDS'
            ? backNav.to
            : forwardNav.to
        )
      }

    React.useEffect(() => {
      if (page?.mascotVariant && page.pageHint) {
        setMascotState({
          mascotVariant: page.mascotVariant,
          hintText: page.pageHint
        })
      } else {
        setMascotState({})
      }
    }, [page, setMascotState])

    if (!page) {
      return <ErrorMessage>
        {`This page does not exist. Plase return to the ${getDynamicString(experiment, 'learnerProjectHome')} and try again.`}
      </ErrorMessage>
    }

    return <>
      <PageForm
        key={pageId}
        {...{
          isFetchingData,
          isLoadingData,
          onSubmitCallback,
          onSuccessCallback,
          responses,
          response,
          experiment,
          worksheet,
          section,
          sectionIndex,
          page,
          pageIndex,
          pageId,
          navDetailsForPage
        }}
      />
      {showSkipSubmissionMessage
        ? <BottomBlurb
            content={ExperimentHelpers.getDynamicString(experiment, 'nothingToTurnIn')}
            icon={<CheckmarkIcon />}
            withBackground
          />
        : null}
      {sectionIndex === 0 && (pageIndex === 0 && !submitOnForward)
        ? <IntroBlurb
            icon={<CheckmarkIcon />}
            content={getDynamicString(experiment, 'pagedIntroBlurb')}
          />
        : null}
      {sectionIndex === 1 && pageIndex === 0
        ? <IntroBlurb
            icon={<CheckmarkIcon />}
            content={getDynamicString(experiment, 'sectionIntroBlurb')}
          />
        : null}
    </>
  }

export default PagedStep
