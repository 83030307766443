import { blurbFormBodyWithLocation, collectionElementFormBody, customTextInputFormBody, fieldsetFormBody, headingFormBody, instructionsFormBody, madLibElementFormBody, multiSelectFormBody, scopeElementFormBody, selectInputFormBody, subheadingFormBody, textFormBody, textInputFormBody } from './elementFormBodies'
import { baseElementSchema, blurbElementSchema, collectionElementSchema, customTextInputSchema, fieldsetElementSchema, headingSchema, instructionsSchema, madLibElementSchema, multiSelectInputSchema, scopeElementSchema, selectInputSchema, subheadingSchema, textInputSchema, textSchema } from './elementSchemas'

import type { EntitySchema } from '~/form-brain2'
import type { AnyPlainOrInputElement, BlurbElement, CheckboxInput, CollectionElement, CustomInput, HeadingElement, InstructionsElement, ListOption, MadLibElement, MultiSelectInput, ScopeElement, SelectInput, SubheadingElement, TextElement, TextInput } from '~/features/questionnaire/types'
import { ELEMENT_TYPES } from '~/utils/strings'
import { SYSTEM_FIELDS } from '../experiment/systemFields'

export const ElegibleVarieties = [
  'TEXT_INPUT',
  'SHORT_ANSWER',
  'LONG_ANSWER',
  'NUMBER_INPUT',
  'DATE_INPUT',
  'SELECT_INPUT',
  'MULTI_SELECT_INPUT',
  'CHECKBOX_INPUT',
  'FILL_IN_THE_BLANK',
  'INSTRUCTIONS',
  'HEADING',
  'SUBHEADING',
  'TEXT',
  'BLURB',
  'FIELDSET',
  'SCOPE',
  'COLLECTION'
] as const

export type AnyElegibleElement = (
  TextInput |
  CustomInput |
  SelectInput |
  MultiSelectInput |
  CheckboxInput |
  MadLibElement |
  InstructionsElement |
  HeadingElement |
  SubheadingElement |
  TextElement |
  BlurbElement |
  ScopeElement |
  CollectionElement
)

export type ElegibleVariety = typeof ElegibleVarieties[number]

export const
  elementSchemas: Record<ElegibleVariety, EntitySchema> = {
    BLURB: blurbElementSchema,
    HEADING: headingSchema,
    INSTRUCTIONS: instructionsSchema,
    SUBHEADING: subheadingSchema,
    TEXT: textSchema,
    FIELDSET: fieldsetElementSchema,
    TEXT_INPUT: textInputSchema,
    NUMBER_INPUT: textInputSchema,
    DATE_INPUT: textInputSchema,
    SHORT_ANSWER: customTextInputSchema,
    LONG_ANSWER: customTextInputSchema,
    CHECKBOX_INPUT: textInputSchema,
    SELECT_INPUT: selectInputSchema,
    MULTI_SELECT_INPUT: multiSelectInputSchema,
    FILL_IN_THE_BLANK: madLibElementSchema,
    COLLECTION: collectionElementSchema,
    SCOPE: scopeElementSchema
  },
  elementFormBodies: Record<ElegibleVariety, AnyPlainOrInputElement[]> = {
    BLURB: blurbFormBodyWithLocation,
    HEADING: headingFormBody,
    INSTRUCTIONS: instructionsFormBody,
    SUBHEADING: subheadingFormBody,
    TEXT: textFormBody,
    FIELDSET: fieldsetFormBody,
    TEXT_INPUT: textInputFormBody,
    NUMBER_INPUT: textInputFormBody,
    DATE_INPUT: textInputFormBody,
    SHORT_ANSWER: customTextInputFormBody,
    LONG_ANSWER: customTextInputFormBody,
    CHECKBOX_INPUT: textInputFormBody,
    SELECT_INPUT: selectInputFormBody,
    MULTI_SELECT_INPUT: multiSelectFormBody,
    FILL_IN_THE_BLANK: madLibElementFormBody,
    COLLECTION: collectionElementFormBody,
    SCOPE: scopeElementFormBody
  },
  elementVarietyOptionList: ListOption[] = ElegibleVarieties.map(elementVariety => ({ optionLabel: ELEMENT_TYPES[elementVariety], optionValue: elementVariety })),
  elementVarietyElement: SelectInput = {
    id: 'elementVarietyPicker',
    fieldName: 'elementVariety',
    elementVariety: 'SELECT_INPUT',
    noSort: true,
    list: elementVarietyOptionList,
    requiredWhen: [{ conditionType: 'always' }]
  },
  builtInFieldNameElement: SelectInput = {
    id: 'BIVPicker',
    fieldName: 'fieldName',
    elementVariety: 'SELECT_INPUT',
    noSort: true,
    list: Object.values(SYSTEM_FIELDS).map(({ fieldName, title, schemaEntry }) => ({
      optionValue: fieldName,
      optionLabel: title,
      _fieldType: schemaEntry.fieldType
    })),
    requiredWhen: [{ conditionType: 'always' }]
  }

export { baseElementSchema }
