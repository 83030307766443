import * as React from 'react'
import styled from 'styled-components'
import { difference, upperFirst } from 'lodash'
import { defaultClosingSection, defaultOpeningSection, defaultRepeatingSection } from '~/features/worksheet/defaultValues'
import { SpecialSectionIdsBySpecialLayoutType } from '~/features'
import { BasicCross, Button, Icon } from '~/components/atoms'
import { NavAdder, NavWrapper, PageOrListSectionLine } from './NavWidgets'

import type { ListSectionElement, ListTemplate, ListWorksheet, RepeatingSectionElement, SpecialClosingSectionElement, SpecialListSectionElement, SpecialOpeningSectionElement, SpecialSectionId } from '~/features'

interface Props {
  selectedSection?: string
  setSelectedSection: (sectionId?: string) => void
  questionnaire: ListTemplate | ListWorksheet
  // questionnaireGroup: TemplateSet | Experiment
  updateQuestionnaire: (questionnaire: ListWorksheet | ListTemplate) => void
}

const
  ButtonWithSpace = styled(Button)`
    margin-bottom: ${p => (p.theme.emBaseSpacing + p.theme.emSmallSpacing) / 2}em;
  `,
  ListFormatNavigation: React.FC<Props> = ({ questionnaire, updateQuestionnaire, selectedSection, setSelectedSection }) => {
    const
      repeatingSections: Array<RepeatingSectionElement | SpecialListSectionElement> = questionnaire.repeatingSections ?? [],
      specialRepeatingSections: SpecialListSectionElement[] = repeatingSections.filter(o => 'specialSectionId' in o && o.specialSectionId) as SpecialListSectionElement[],
      mainSections: RepeatingSectionElement[] = difference(repeatingSections, specialRepeatingSections),
      availableSpecialSections = questionnaire.specialLayoutType
        ? SpecialSectionIdsBySpecialLayoutType[questionnaire.specialLayoutType]
        : undefined,
      addOpeningSection = (specialSectionId?: SpecialSectionId): React.MouseEventHandler => (e) => {
        if (e) { e.stopPropagation(); e.preventDefault() }
        updateQuestionnaire({
          ...questionnaire,
          openingSection: defaultOpeningSection(specialSectionId)
        })
      },
      addClosingSection = (specialSectionId?: SpecialSectionId): React.MouseEventHandler => (e) => {
        if (e) { e.stopPropagation(); e.preventDefault() }
        updateQuestionnaire({
          ...questionnaire,
          closingSection: defaultClosingSection(specialSectionId)
        })
      },
      addRepeatingSection = (specialSectionId?: SpecialSectionId): React.MouseEventHandler => (e) => {
        if (e) { e.stopPropagation(); e.preventDefault() }

        updateQuestionnaire({
          ...questionnaire,
          repeatingSections: [
            ...(questionnaire as ListWorksheet).repeatingSections,
            defaultRepeatingSection(specialSectionId)
          ]
        })
      },
      selectSectionOnClick = (sectionId: string): React.MouseEventHandler => (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }
        setSelectedSection(sectionId)
      },
      clearSelection: React.MouseEventHandler = (e) => {
        if (e) { e.stopPropagation(); e.preventDefault() }
        setSelectedSection()
      }

    return (
      <NavWrapper data-cy="step-format-navigation">
        {selectedSection
          ? <ButtonWithSpace label="All Sections" withIcon="arrowL" size="small" color="back" onClick={clearSelection} />
          : null}
        {questionnaire.openingSection
          ? <PageOrListSectionLine
            title={(questionnaire.openingSection as SpecialOpeningSectionElement).specialSectionId
              ? `Special Opening:
${upperFirst(questionnaire.openingSection.title)}`
              : `Opening Section - ${questionnaire.openingSection.title}`}
            onClick={selectSectionOnClick(questionnaire.openingSection.id)}
            isSelected={selectedSection === questionnaire.openingSection.id}
            pageOrListSection={questionnaire.openingSection}
          />
          : <>
            {availableSpecialSections?.LIST_OPENER_SECTION
              ? <NavAdder
                label={`Add ${availableSpecialSections.LIST_OPENER_SECTION.adderLabel}`}
                orientation="left"
                icon={<Icon content={BasicCross} color="forward" frameStyle="circle" onClick={addOpeningSection(availableSpecialSections.LIST_OPENER_SECTION.specialSectionId)} />}
              />
              : null}
            <NavAdder
              label="Add Opening Section"
              orientation="left"
              icon={<Icon content={BasicCross} color="forward" frameStyle="circle" onClick={addOpeningSection()} />}
            />
          </>}
        {(availableSpecialSections?.LIST_ENTRY_SECTION ?? []).map(({ specialSectionId, adderLabel }) => {
          const section = specialRepeatingSections.find(o => o.specialSectionId === specialSectionId)

          return section
            ? <PageOrListSectionLine
              key={section.id}
              title={
                section.entryNameSingular
                  ? `Special Section:
${upperFirst(section.entryNameSingular)}`
                  : upperFirst(adderLabel)
              }
              onClick={selectSectionOnClick(section.id)}
              isSelected={selectedSection === section.id}
              pageOrListSection={section as ListSectionElement} />
            : <NavAdder
                key={specialSectionId}
                label={`Add ${adderLabel}`}
                orientation="left"
                icon={<Icon content={BasicCross} color="back" frameStyle="circle" onClick={addRepeatingSection(specialSectionId)} />}
              />
        })}
        {mainSections.map((section, index) => (
          <PageOrListSectionLine
            key={section.id}
            title={
              section.entryNameSingular
                ? `List Entry - ${upperFirst(section.entryNameSingular)}`
                : `List Entry ${index + 1}`
            }
            onClick={selectSectionOnClick(section.id)}
            isSelected={selectedSection === section.id}
            pageOrListSection={section as ListSectionElement} />
        ))}
        <NavAdder
          label={availableSpecialSections?.LIST_ENTRY_SECTION ? 'Add Other Entry' : 'Add List Entry'}
          orientation="left"
          icon={<Icon content={BasicCross} color="back" frameStyle="circle" onClick={addRepeatingSection()} />}
        />
        {questionnaire.closingSection
          ? <PageOrListSectionLine
            title={(questionnaire.closingSection as SpecialClosingSectionElement).specialSectionId
              ? `Special Closing:
${upperFirst(questionnaire.closingSection.title)}`
              : `Closing Section - ${questionnaire.closingSection.title}`}
            onClick={selectSectionOnClick(questionnaire.closingSection.id)}
            isSelected={selectedSection === questionnaire.closingSection.id}
            pageOrListSection={questionnaire.closingSection}
          />
          : <>
            {availableSpecialSections?.LIST_CLOSER_SECTION
              ? <NavAdder
                label={`Add ${availableSpecialSections.LIST_CLOSER_SECTION.adderLabel}`}
                orientation="left"
                icon={<Icon content={BasicCross} color="forward" frameStyle="circle" onClick={addOpeningSection(availableSpecialSections.LIST_CLOSER_SECTION.specialSectionId)} />}
              />
              : null}
            <NavAdder
              label="Add Closing Section"
              orientation="left"
              icon={<Icon content={BasicCross} color="forward" frameStyle="circle" onClick={addClosingSection()} />}
            />
          </>}
      </NavWrapper>
    )
  }

export default ListFormatNavigation
