import type { AttrErrors, AttrVal, ContextAndResolvers, BodyElement, FormFieldRendererProps } from '../types'

interface Params {
  bodyElement: BodyElement
  contextAndResolvers: ContextAndResolvers
}

type IncludedKey = (
  'hint' | 'label' | 'placeholder' | 'unit' | 'list' | 'isReadOnly' |
  'isVisible' | 'isRequired' | 'isRecommended' | 'name' | 'pathToAttr' |
  'bodyElement' | 'currentValue' | 'errors'
)

interface FormFieldDetails extends Pick<FormFieldRendererProps, IncludedKey> {
  hintDefaultVisible?: boolean
}

export const getFormFieldDetails = ({ contextAndResolvers, bodyElement }: Params): FormFieldDetails => {
  const
    c = contextAndResolvers,
    valueKey = c.resolveKey({ bodyElement, contextAndResolvers }),
    schemaEntry = contextAndResolvers.schema[valueKey],
    inputTrappings = {
      hint: c.resolveHint({ bodyElement, contextAndResolvers, schemaEntry }),
      label: c.resolveLabel({ bodyElement, contextAndResolvers, schemaEntry }),
      placeholder: c.resolvePlaceholder({ bodyElement, contextAndResolvers, schemaEntry }),
      unit: c.resolveUnit({ bodyElement, contextAndResolvers, schemaEntry }),
      list: c.resolveList({ bodyElement, contextAndResolvers, schemaEntry }),
      isReadOnly: c.resolveCriteria({ bodyElement, contextAndResolvers, schemaEntry, criteria: 'isReadOnly' }),
      isVisible: c.resolveCriteria({ bodyElement, contextAndResolvers, schemaEntry, criteria: 'isVisible' }),
      isRequired: c.resolveCriteria({ bodyElement, contextAndResolvers, schemaEntry, criteria: 'isRequired' }),
      isRecommended: c.resolveCriteria({ bodyElement, contextAndResolvers, schemaEntry, criteria: 'isRecommended' })
    },
    hintDefaultVisible = c.resolveCriteria({ bodyElement, contextAndResolvers, schemaEntry, criteria: 'hintDefaultVisible' }),
    currentValue = c.values[valueKey] as AttrVal,
    currentErrors = c.errors[valueKey] as AttrErrors,
    pathToAttr = c.scope ? `${c.scope}.${valueKey}` : valueKey

  // if (valueKey === 'treatmentLabel') {
  //   console.log({inputTrappings, schemaEntry, bodyElement})
  // }

  return {
    bodyElement,
    name: valueKey,
    pathToAttr,
    currentValue,
    errors: currentErrors,
    hintDefaultVisible,
    ...inputTrappings
  }
}
