import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { Button, HeadingTwo, DashedLine } from '~/components/atoms'

import type { FormStatus } from '~/form-brain2'

interface NewTemplateSetFormProps {
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void
  title: string
  formName: string
  formStatus: FormStatus | undefined
  children: React.ReactNode
}

const
  NewTemplateSetFormOuter = styled.div`
    margin-bottom: ${p => p.theme.emBaseSpacing}em;
  `,
  FormActions = styled.div`
    display: flex;
    flex-direction: row;

    > :first-child {
      margin-right: auto;
    }
  `,
  HR = styled(DashedLine)`
    margin-top: 0.5em;
  `,
  NewEntityFormOuter: React.FC<NewTemplateSetFormProps> = ({ onCancel, title, children, formStatus, formName }) => {
    const THEME = useTheme()

    React.useEffect(() => {
      if (formStatus === 'success') {
        onCancel()
      }
    }, [formStatus, onCancel])

    return <NewTemplateSetFormOuter>
      <HeadingTwo>{title}</HeadingTwo>
      {children}
      <FormActions>
        <Button onClick={onCancel} label="Cancel" color="back" size="small" />
        {!formStatus || ['clean', 'cleanAfterSuccess'].includes(formStatus)
          ? null
          : <Button
            type="submit"
            isLoading={formStatus === 'processing'}
            form={formName}
            label="Continue"
            color="forward"
            size="small"
          />}
      </FormActions>
      <HR orientation="horizontal" color={THEME.colors.borderAccent} />
    </NewTemplateSetFormOuter>
  }

export default NewEntityFormOuter
