import styled, { css } from 'styled-components'
import {
  minWidthBySize,
  lineHeightBySize,
  fontSizeByButtonSize,
  textColorKeys,
  standaloneTextColorKeys
} from '~/components/atoms/Button/buttonConstants'
import type {
  ButtonColor,
  ButtonSize
} from '~/components/atoms/Button/buttonConstants'
import { Link } from 'react-router-dom'

interface ButtonVariantProps {
  $color: ButtonColor
  $size: ButtonSize
  $isLoading?: boolean
  $isDisabled?: boolean
}

/*
  small - should have background color and border radius and should rotate on hover
  medium - should have image and should rotate on hover also should have text transform uppercase
  large - should have image and should rotate on hover
  giant - should have background color and only the text should rotate on hover also should have text transform uppercase
*/

const
  sharedStyle = css<ButtonVariantProps>`
    display: inline-block; // override defaults for <a>

    position: relative;
    padding: 0;
    border-radius: ${p => p.$size === 'small' ? 0.5 : 0}em;
    min-width: ${p => minWidthBySize[p.$size] ? `${minWidthBySize[p.$size] ?? ''}em` : 'unset'}; // nullish check for lint
    line-height: ${p => lineHeightBySize[p.$size] ? lineHeightBySize[p.$size] : 2}em;
    cursor: ${p => p.$isDisabled ? 'not-allowed' : p.$isLoading ? 'wait' : 'pointer'};
    user-select: none;

    ${p => p.theme.bodyFont}
    font-size: ${p => fontSizeByButtonSize[p.$size] ? `${fontSizeByButtonSize[p.$size]}em` : 'unset'};
    text-transform: ${p => p.$size !== 'small' ? 'uppercase' : 'none'};
    color: ${p =>
      p.$isLoading
        ? p.theme.colors.understateDark
        : p.$size !== 'large'
          ? p.theme.colors[textColorKeys[p.$color]]
          : p.theme.colors[standaloneTextColorKeys[p.$color]]
    };    
    background: ${p =>
      p.$size === 'medium'
        ? 'transparent'
        : p.$isLoading
          ? p.theme.colors.containerBg
          : (p.$size === 'small'
            ? p.theme.colors[p.$color]
            : p.theme.colors.containerBg)};   

    .button-background-svg path {
      fill: ${p => p.$isLoading ? p.theme.colors.containerBg : p.theme.colors[p.$color]};
    }

    transform: ${p => p.$size === 'medium' ? 'rotate(0.4deg)' : (p.$size === 'small' ? 'matrix(1, 0.01, -0.01, 1, 0, 0)' : '')};

    &:hover {
      ${p => p.$size !== 'large' && !p.$isLoading ? 'transform: rotate(-1.4deg) scale(1.05);' : ''}

      .button-content-wrapper {
        ${p => p.$size !== 'large' || p.$isLoading ? '' : 'transform: rotate(-1.4deg) scale(1.1);'}
      }
    }
  `,
  StyledActualButton = styled.button`
    ${sharedStyle as any /* this is just to suppress a type error */}
  `,
  StyledLinkButton = styled(Link)`
    ${sharedStyle as any /* this is just to suppress a type error */}
  `

export { StyledActualButton, StyledLinkButton }
