import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField, VisibilityCondition } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  mtMatches = (measurementTypeToMatch: string): VisibilityCondition[] => [{
    valueName: SystemFieldNames.measurementType,
    conditionType: 'matches',
    testValue: measurementTypeToMatch
  }],
  title = 'Measurement unit',
  description = 'What unit the measurement be taken in. This choice does not change anything about how the experiment works. It is used to give instructions and label data recording forms.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.measurementUnit,
    fieldType: 'STRING',
    options: [
      {
        optionValue: 'lbs',
        optionLabel: 'pounds (lbs)',
        visibleWhen: mtMatches('weight')
      },
      {
        optionValue: 'oz',
        optionLabel: 'ounces (oz)',
        visibleWhen: mtMatches('weight')
      },
      {
        optionValue: 'g',
        optionLabel: 'grams (g)',
        visibleWhen: mtMatches('weight')
      },
      {
        optionValue: 'kg',
        optionLabel: 'kilograms (kg)',
        visibleWhen: mtMatches('weight')
      },
      {
        optionValue: 'inches',
        visibleWhen: mtMatches('height')
      },
      {
        optionValue: 'cm',
        optionLabel: 'centimeters (cm)',
        visibleWhen: mtMatches('height')
      },
      {
        optionValue: '%',
        visibleWhen: mtMatches('cover')
      },
      {
        optionValue: '°F',
        visibleWhen: mtMatches('temp')
      },
      {
        optionValue: '°C',
        visibleWhen: mtMatches('temp')
      },
      {
        optionValue: 'inches per hour',
        visibleWhen: mtMatches('infiltration')
      },
      {
        optionValue: 'meq/100g',
        visibleWhen: mtMatches('CEC')
      },
      {
        optionValue: '%',
        visibleWhen: mtMatches('organic')
      }
    ],
    visibleWhen: [
      {
        valueName: SystemFieldNames.measurementType,
        conditionType: 'notMatches',
        testValue: 'count'
      },
      {
        valueName: SystemFieldNames.measurementType,
        conditionType: 'notMatches',
        testValue: 'number'
      },
      {
        valueName: SystemFieldNames.measurementType,
        conditionType: 'notMatches',
        testValue: 'pH'
      }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.measurementUnit,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    shouldFollow: [SystemFieldNames.measurementType],
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
