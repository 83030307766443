import { isAllEmpty, isEmptyValue } from '~/utils/testers'
import { comparableInputVarieties, comparableInputLikeVarieties, experimentFormResolvers, getValueAcrossEntities, interpolateStringInForm } from '~/features'
import type { InputElement, HeadingElement, SubheadingElement, TextElement, BlurbElement, InstructionsElement, MadLibElement, ScopeElement, CollectionElement, SelectInput, MultiSelectInput, AnyPlainOrInputElement } from '~/features'
import type { SavePointFileFormat, SavePointRow, SavePointValue } from '~/components/molecules/SavePoint'
import type { AttrValArray, BodyElement, ContextAndResolvers, EntityObject } from '~/form-brain2'

type GetRowFn<T extends BodyElement> = (bodyElement: T, contextAndResolvers: ContextAndResolvers, format: SavePointFileFormat) => SavePointRow[]
type StaticContentElement = HeadingElement | SubheadingElement | TextElement | BlurbElement | InstructionsElement
type CompoundInputElement = MadLibElement | ScopeElement | CollectionElement

const
  getRowForInput: GetRowFn<InputElement> = (bodyElement, contextAndResolvers, _) => {
    const
      valueKey = contextAndResolvers.resolveKey({ bodyElement, contextAndResolvers }),
      schemaEntry = contextAndResolvers.schema[valueKey] ?? {},
      showLabel = !bodyElement.hideLabel,
      label = showLabel
        ? interpolateStringInForm(experimentFormResolvers.resolveLabel({ bodyElement, contextAndResolvers, schemaEntry }), contextAndResolvers)
        : undefined,
      showUnit = !bodyElement.hideUnit,
      unit = showUnit
        ? interpolateStringInForm(experimentFormResolvers.resolveUnit({ bodyElement, contextAndResolvers, schemaEntry }), contextAndResolvers)
        : undefined,
      value = contextAndResolvers.values[valueKey],
      isVisible = isEmptyValue(bodyElement.visibleWhen) || contextAndResolvers.resolveCriteria({ bodyElement, contextAndResolvers, criteria: 'isVisible' })

    if (!isVisible) { return [] }

    if (bodyElement.elementVariety === 'CHECKBOX_INPUT' || typeof value === 'boolean') {
      return [{
        // label: value ? '\u2611' : '\u2610', // Ballot box icon, checked and unchecked
        label: value ? 'Yes' : '',
        ...(label === undefined ? {} : { value: label }),
        inline: 1
      }]
    }

    if (bodyElement.elementVariety === 'SELECT_INPUT' || bodyElement.elementVariety === 'MULTI_SELECT_INPUT') {
      const fallbackValue = (bodyElement as SelectInput).blankOption ?? (bodyElement as MultiSelectInput).selectNoneOption

      if (value === undefined && !fallbackValue) { return [] }

      const
        options = contextAndResolvers.resolveList({ contextAndResolvers, bodyElement, schemaEntry }),
        selectedValueS = value as string | string[],
        valueLabel = isAllEmpty(selectedValueS)
          ? fallbackValue as string
          : Array.isArray(selectedValueS)
            ? (selectedValueS as AttrValArray).map(v => options.find(o => o.value === v)?.label ?? v).join(', ')
            : options.find(o => o.value === selectedValueS)?.label ?? selectedValueS

      return [{
        ...(label === undefined ? {} : { label }),
        value: valueLabel,
        ...(unit === undefined ? {} : { unit })
      }]
    }

    if (value === undefined || isAllEmpty(value) || Array.isArray(value) || typeof value === 'object') { return [] }

    // textElementVarieties: InputElementVariety[] = ['TEXT_INPUT', 'DATE_INPUT', 'NUMBER_INPUT', 'LONG_ANSWER', 'SHORT_ANSWER']
    return [{
      ...(label === undefined ? {} : { label }),
      value,
      ...(unit === undefined ? {} : { unit })
    }]
  },
  getStringFromSegments = (segments: AnyPlainOrInputElement[], contextAndResolvers: ContextAndResolvers, format: SavePointFileFormat): SavePointValue | undefined => {
    const values = segments.map(el => ({
      isInput: el.elementVariety !== 'TEXT',
      content: el.elementVariety === 'TEXT'
        ? getStaticContentRows(el, contextAndResolvers, format)
        : getRowForInput(el as InputElement, contextAndResolvers, format)
    }))

    if (isAllEmpty(values.filter(o => o.isInput).map(o => o.content))) {
      return undefined
    } else {
      const
        flattenedRows = values.map(o => o.content).flat(2),
        valueOnlyRows = flattenedRows.map(r => {
          return typeof r !== 'object'
            ? r
            : [r.value, r.unit].filter(o => !isEmptyValue(o)).join(' ')
        }),
        rowsWithoutBlanks = valueOnlyRows.filter(o => o)

      return rowsWithoutBlanks.join(' ')
    }
  },
  getRowsForCompoundInput: GetRowFn<CompoundInputElement> = (bodyElement, contextAndResolvers, format) => {
    const isVisible = isEmptyValue(bodyElement.visibleWhen) || contextAndResolvers.resolveCriteria({ bodyElement, contextAndResolvers, criteria: 'isVisible' })

    if (!isVisible) {
      return []
    }

    if (bodyElement.elementVariety === 'FILL_IN_THE_BLANK') {
      const
        segments = bodyElement.segments,
        label = interpolateStringInForm(experimentFormResolvers.resolveLabel({ bodyElement, contextAndResolvers }), contextAndResolvers),
        value = getStringFromSegments(segments, contextAndResolvers, format)

      if (!value) {
        return []
      } else {
        return [{
          ...(label === undefined ? {} : { label }),
          value
        }]
      }
    } else {
      const
        schemaEntry = contextAndResolvers.schema[bodyElement.fieldName] ?? {},
        label = interpolateStringInForm(experimentFormResolvers.resolveLabel({ bodyElement, contextAndResolvers, schemaEntry }), contextAndResolvers),
        fieldContents = getValueAcrossEntities(bodyElement.fieldName, contextAndResolvers.formOptions.otherEntities, true) as undefined | EntityObject | EntityObject[],
        valueSets = Array.isArray(fieldContents) ? fieldContents : [fieldContents],
        scopeBody = bodyElement.formBody

      if (isAllEmpty(valueSets)) {
        return []
      }

      const
        scopedContextAndResolvers = {
          ...contextAndResolvers,
          // scope: bodyElement.fieldName,
          schema: schemaEntry.format ?? {}
        }

      if (!!(bodyElement.elementVariety === 'COLLECTION' && bodyElement.editInline) || (bodyElement.elementVariety === 'SCOPE' && bodyElement.asRow)) {
        const scopeRow = (valueSets as EntityObject[]).reduce<SavePointValue[]>((memo, scopedValues) => {
          const thisValue = getStringFromSegments(scopeBody, { ...scopedContextAndResolvers, values: scopedValues }, format)
          return thisValue ? [...memo, thisValue] : memo
          // scopeBody.map(el => getRowsForBodyElement(el, { ...scopedContextAndResolvers, values: scopedValues })).flat(1)
        }, [])

        return format === 'csv'
          ? [{
              ...(label ? { label } : {}),
              value: scopeRow.join('\n')
            }]
          : [
              ...(label ? [{ label }] : []),
              scopeRow
            ]
      } else {
        const scopeRows = (valueSets as EntityObject[]).reduce<SavePointRow[]>((memo, scopedValues) => {
          const rowsThisMember = scopeBody.map(el => getRowsForBodyElement(el, { ...scopedContextAndResolvers, values: scopedValues }, format)).flat(1)
          return [
            ...memo,
            ...rowsThisMember
          ]
          // scopeBody.map(el => getRowsForBodyElement(el, { ...scopedContextAndResolvers, values: scopedValues })).flat(1)
        }, [])

        return [
          ...(label ? [{ label }] : []),
          ...scopeRows
        ]
      }
    }
  },
  getStaticContentRows: GetRowFn<StaticContentElement> = (bodyElement, contextAndResolvers, _) => {
    const
      { visibleWhen } = bodyElement,
      isVisible = isEmptyValue(visibleWhen) || contextAndResolvers.resolveCriteria({ bodyElement, contextAndResolvers, criteria: 'isVisible' })

    if (!isVisible) {
      return []
    }

    if (bodyElement.elementVariety === 'HEADING' && !isEmptyValue(bodyElement.text)) {
      const heading = interpolateStringInForm(bodyElement.text, contextAndResolvers)

      return heading ? [{ h2: heading }] : []
    }

    if (bodyElement.elementVariety === 'SUBHEADING' && !isEmptyValue(bodyElement.text)) {
      const heading = interpolateStringInForm(bodyElement.text, contextAndResolvers)

      return heading ? [{ h3: heading }] : []
    }

    if (bodyElement.elementVariety === 'TEXT' && !isEmptyValue(bodyElement.text)) {
      return bodyElement.text.split('\n').reduce((memo: SavePointRow[], s) => {
        const paragraph = interpolateStringInForm(s, contextAndResolvers)

        return paragraph ? [...memo, [paragraph]] : memo
      }, [])
    }

    // These are the only plain elements that currently print - blurb and instructions don't

    return []
  },
  getRowsForBodyElement: GetRowFn<AnyPlainOrInputElement> = (bodyElement, contextAndResolvers, format) => {
    const
      { elementVariety } = bodyElement,
      isInput = comparableInputVarieties.includes(elementVariety),
      isCompoundInput = comparableInputLikeVarieties.includes(elementVariety),
      isLearnerContent = isCompoundInput || isInput

    return isCompoundInput
      ? getRowsForCompoundInput(bodyElement as CompoundInputElement, contextAndResolvers, format)
      : isLearnerContent
        ? getRowForInput(bodyElement as InputElement, contextAndResolvers, format)
        : getStaticContentRows(bodyElement as StaticContentElement, contextAndResolvers, format)
  }

export { getRowsForBodyElement }
