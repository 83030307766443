import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Seeds per plant (pre-thinning)',
  description = 'This allows learners to specify that they will plant more than one seed for each plant and then thin to one seedling later.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.seedsPerPlant,
    fieldType: 'NUMBER',
    visibleWhen: [{
      valueName: SystemFieldNames.plantingFormat,
      conditionType: 'present'
    }, {
      valueName: SystemFieldNames.plantSpacingUnit,
      conditionType: 'notMatches',
      testValue: 'seed'
    }]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.seedsPerPlant,
    variableGroup: SystemFieldGroups.WHAT_WHERE,
    shouldFollow: [SystemFieldNames.plantingFormat],
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
