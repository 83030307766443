import * as React from 'react'
import { Form } from '~/form-brain2'
import {
  experimentFormResolvers,
  ExperimentHelpers,
  WorksheetHelpers,
  WorksheetResponseHelpers
} from '~/features'
import { getOverviewFormRenderer } from './StepOverviewFormRenderer'
import { FormRenderer } from '~/components/molecules'

import type {
  FormOnSubmitFn,
  FormProps,
  FormOnSuccessFn,
  EntityObject,
  SubscribeToFormStatusFn
} from '~/form-brain2'
import type {
  ListWorksheet,
  PagedWorksheet,
  WorksheetResponse,
  PagedSectionElement,
  Experiment
} from '~/features'

interface BaseProps {
  worksheet: PagedWorksheet | ListWorksheet
  response: WorksheetResponse
  experiment: Experiment
  responses: WorksheetResponse[]
  withoutBlurbs?: boolean
}
interface PropsForForm {
  formId: string
  formRef: FormProps['methodRef']
  onSubmitCallback: FormOnSubmitFn
  onSuccessCallback: FormOnSuccessFn
  subscribeToFormStatus?: SubscribeToFormStatusFn
  // TODO onError
}
interface PropsForReadonly {
  readOnly: true
}

type Props = (BaseProps & PropsForForm) | (BaseProps & PropsForReadonly)

type HeadingRecord = Record<string, string | undefined>

const
  StepOverviewForm: React.FC<Props> = (props) => {
    const
      { worksheet, response, responses, experiment, withoutBlurbs, ...otherProps } = props,
      readOnly = 'readOnly' in otherProps,
      formBody = worksheet.format === 'LIST'
        ? WorksheetHelpers.getSectionList(worksheet)
        : worksheet.sections,
      schema = worksheet.format === 'LIST'
        ? undefined
        : ExperimentHelpers.getSchema(experiment, formBody),
      initialValues = worksheet.format === 'LIST'
        ? response as unknown as EntityObject
        : response.responses as EntityObject,
      backdropColorKey = worksheet.format === 'LIST'
        ? readOnly ? 'containerBg' : 'containerAccentBg'
        : 'containerDarkBg',
      [pageHeadings, setPageHeadings] = React.useState<HeadingRecord>(
        worksheet.format === 'LIST'
          ? {}
          : (formBody as PagedSectionElement[]).reduce<HeadingRecord>((memo, section) => {
              return section.pages.reduce<HeadingRecord>((innerMemo, page) => {
                return typeof page.heading === 'string'
                  ? { ...innerMemo, [`${section.id}.${page.id}`]: page.heading }
                  : innerMemo
              }, memo)
            }, {})
      ),
      setParentHeading = (newHeading: string, key?: string): void => {
        if (key && !pageHeadings[key]) { setPageHeadings({ ...pageHeadings, [key]: newHeading }) }
      },
      OverviewFormRenderer = getOverviewFormRenderer(worksheet, pageHeadings, experiment)

    if (readOnly) {
      return <Form
        formId={`${response.id}-readonly`}
        initialValues={initialValues}
        formBody={formBody}
        entitySchema={schema}
        FormRenderer={OverviewFormRenderer}
        formOptions={{
          experiment,
          dynamicLabels: experiment.dynamicLabels,
          otherEntities: WorksheetResponseHelpers.getEntitiesAcrossResponses(responses),
          backdropColorKey,
          setParentHeading,
          withoutBlurbs,
          isSummary: true,
          isReadOnly: true,
          RootFormRenderer: FormRenderer
        }}
        onSubmitCallback={() => Promise.resolve({})}
        {...experimentFormResolvers}
      />
    } else {
      const { formId, onSubmitCallback, onSuccessCallback, subscribeToFormStatus, formRef } = otherProps

      return <Form
        {...{ formId }}
        methodRef={formRef}
        initialValues={initialValues}
        formBody={formBody}
        entitySchema={schema}
        FormRenderer={OverviewFormRenderer}
        formOptions={{
          experiment,
          dynamicLabels: experiment.dynamicLabels,
          otherEntities: WorksheetResponseHelpers.getEntitiesAcrossResponses(responses),
          backdropColorKey,
          setParentHeading,
          withoutBlurbs,
          isSummary: true,
          RootFormRenderer: FormRenderer
        }}
        onSubmitCallback={onSubmitCallback}
        onSuccessCallback={onSuccessCallback}
        subscribeToFormStatus={subscribeToFormStatus}
        {...experimentFormResolvers}
      />
    }
  }

export default StepOverviewForm
