import * as React from 'react'
import styled from 'styled-components'
import { Button } from '~/components/atoms'
import { useLearnerStepContext, useUpdateEntity } from '~/hooks'
import { getDefaultEntriesForWorksheet } from '~/features'
import { EmptyList, ListStepOuter } from '~/components/organisms/ListStep/StackingSections'

import type { ListWorksheet } from '~/features'

interface Props {
  message: string
  buildButtonLabel: string
  setStartingFromScratch: (val: boolean) => void
  isDisabled?: boolean
}

const
  Actions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: ${p => p.theme.emBaseSpacing * 1.5}em;
  `,
  ActionButton = styled(Button)`
    font-size: 1.2rem;
  `,
  ListInitChoices: React.FC<Props> = ({ message, buildButtonLabel, setStartingFromScratch, isDisabled }) => {
    const
      { response, worksheet, responses } = useLearnerStepContext(),
      { updateEntity, isUpdating: isProcessingUpdate } = useUpdateEntity(),
      onBuildFromDefaults: React.MouseEventHandler = () => {
        const defaultEntries = getDefaultEntriesForWorksheet({ worksheet: worksheet as ListWorksheet, responses })

        void updateEntity({
          entity: response,
          newValues: {
            ...response,
            responses: {
              entries: defaultEntries
            }
          }
        })
      }

    return <ListStepOuter>
      <EmptyList>
        <p>{'There\'s nothing here!'}</p>
        <p>{message}</p>
        <Actions>
          <ActionButton size="small" label={buildButtonLabel} onClick={onBuildFromDefaults} isLoading={isProcessingUpdate} isDisabled={isDisabled} />
          <ActionButton size="small" label="Start from Scratch" isDisabled={!!isDisabled || isProcessingUpdate} onClick={() => { setStartingFromScratch(true) }} />
        </Actions>
      </EmptyList>
    </ListStepOuter>
  }

export default ListInitChoices
