import { getSemiRandomString } from '~/utils/strings'
import { EXPERIMENT_LOCATIONS, PLANTING_FORMATS, SystemFieldNames } from '~/features/experiment/systemFields'
import type { ListWorksheet } from '../types'
import type { SpecialListSectionElement } from '~/features/questionnaire/types'

export const
  getDefaultSetUpStepSection = (sectionId?: string): SpecialListSectionElement => {
    return {
      id: sectionId ?? `setup-list-${getSemiRandomString()}`,
      elementVariety: 'LIST_ENTRY_SECTION',
      entryNamePlural: 'set-up steps',
      entryNameSingular: 'set-up step',
      specialSectionId: 'SET_UP_STEP',
      whatLabel: 'What will you do to get started?',
      detailsLabel: 'You can add more information here, if needed.',
      pageHint: 'You can check off the items on your list as you do them!',
      mascotVariant: 'recording',
      checkList: true,
      body: []
    }
  },
  defaultsForSetUpLayout = (setUpSectionId?: string): Pick<ListWorksheet, 'repeatingSections' | 'defaults'> => {
    const
      setUpStepSection = getDefaultSetUpStepSection(setUpSectionId),
      sectionId = setUpStepSection.id

    return {
      repeatingSections: [setUpStepSection],
      defaults: [
        /** MATERIALS & TREATMENTS */
        {
          // id: 'materials',
          sectionId,
          entryValues: {
            description: 'Collect materials.'
          }
        },
        {
          sectionId,
          automaticEntryKey: 'apply treatments'
        },

        /** PLANTING PREP */
        {
          // id: 'fill containers',
          sectionId,
          entryValues: {
            description: 'Fill each container with soil.'
          },
          visibleWhen: [{ valueName: SystemFieldNames.location, conditionType: 'equals', testValue: EXPERIMENT_LOCATIONS.CONTAINER }]
        },
        {
          // id: 'labels for individuals in containers',
          sectionId,
          entryValues: {
            description: 'Number labels 1 to --sampleSize-- and place them in/on the containers, one for each plant.'
          },
          visibleWhen: [{ valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.INDIVIDUAL_CONTAINER }]
        },
        {
          // id: 'labels for individuals in ground',
          sectionId,
          entryValues: {
            description: 'Number labels 1 to --sampleSize--, one for each plant.'
          },
          visibleWhen: [{ valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.INDIVIDUAL_GROUND }]
        },
        {
          // id: 'labels for groups in ground',
          sectionId,
          entryValues: {
            description: 'Number labels 1 to --sampleSize--, one for each --subjectGroupName--.'
          },
          visibleWhen: [{ valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.GROUP_GROUND }]
        },
        {
          // id: 'labels for plain groups in containers',
          sectionId,
          entryValues: {
            description: 'Number labels 1 to --sampleSize-- and place one in/on each of the --containerType--.'
          },
          visibleWhen: [
            { valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.GROUP_CONTAINER },
            { valueName: SystemFieldNames.plantSpacingArea, conditionType: 'matches', testValue: 'container' },
            { valueName: SystemFieldNames.plantSpacingArea, conditionType: 'present' }
          ]
        },
        {
          // id: 'labels for custom groups in containers',
          sectionId,
          entryValues: {
            description: 'Number labels 1 to --sampleSize-- and place them in/on the --containerType--, one for each --subjectGroupName--.'
          },
          visibleWhen: [
            { valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.GROUP_CONTAINER },
            { valueName: SystemFieldNames.plantSpacingArea, conditionType: 'notMatches', testValue: 'container' }
          ]
        },
        {
          // id: 'prep ground for individuals',
          sectionId,
          entryValues: {
            description: 'Prepare your growing space. Decide where you want each plant to grow, and place the labels. Make sure plants are spaced evenly and have enough room.'
          },
          visibleWhen: [{ valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.INDIVIDUAL_GROUND }]
        },
        {
          // id: 'prep ground for group',
          sectionId,
          entryValues: {
            description: 'Prepare your growing space. Decide where you want each --subjectGroupName-- to be. Mark the areas and place the labels.'
          },
          visibleWhen: [{ valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.GROUP_GROUND }]
        },

        /** SEEDING */
        {
          // id: 'seed individuals',
          sectionId,
          entryValues: {
            description: 'Sow --calculatedSeedsPerPlant-- seed(s) for each plant. Follow the instructions that came with the seeds for how deep to plant the seeds.'
          },
          visibleWhen: [{ valueName: SystemFieldNames.plantingFormat, conditionType: 'anyOf', testValue: [PLANTING_FORMATS.INDIVIDUAL_GROUND, PLANTING_FORMATS.INDIVIDUAL_CONTAINER] }]
        },
        {
          // id: 'seed groups of plants in containers',
          sectionId,
          entryValues: {
            description: 'According to your plan, you will grow --plantsPerSubjectGroup-- plants per --subjectGroupName--. Sow --calculatedSeedsPerPlant-- seed(s) for each plant. Follow the instructions that came with the seeds for how deep to plant the seeds.'
          },
          visibleWhen: [
            { valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.GROUP_CONTAINER },
            { valueName: SystemFieldNames.plantSpacingUnit, conditionType: 'matches', testValue: 'plant' }
          ]
        },
        {
          // id: 'seed custom groups of seeds in containers',
          sectionId,
          entryValues: {
            description: 'Sow --seedsPerGroup-- --calculatedSeedUnit-- for each --subjectGroupName--. Follow the instructions that came with the seeds for how to plant them.'
          },
          visibleWhen: [
            { valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.GROUP_CONTAINER },
            { valueName: SystemFieldNames.plantSpacingUnit, conditionType: 'matches', testValue: 'seed' }
          ]
        },
        {
          // id: 'seed group of plants in ground',
          sectionId,
          entryValues: {
            description: 'According to your plan, you will grow --plantsPerSubjectGroup-- plants per --subjectGroupName--. Sow --calculatedSeedsPerPlant-- seed(s) for each plant. Follow the instructions that came with the seeds for how deep to plant the seeds.'
          },
          visibleWhen: [
            { valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.GROUP_GROUND },
            { valueName: SystemFieldNames.plantSpacingUnit, conditionType: 'matches', testValue: 'plant' }
          ]
        },
        {
          // id: 'seed group of seeds in ground',
          sectionId,
          entryValues: {
            description: 'Sow --seedsPerGroup-- --calculatedSeedUnit-- for each --subjectGroupName--. Follow the instructions that came with the seeds for how to plant them.'
          },
          visibleWhen: [
            { valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.GROUP_GROUND },
            { valueName: SystemFieldNames.plantSpacingUnit, conditionType: 'matches', testValue: 'seed' }
          ]
        },
        {
          // id: 'seed any other groups',
          sectionId,
          entryValues: {
            description: 'Sow seeds for each subject. Follow the instructions that came with the seeds for how to plant them.'
          },
          visibleWhen: [
            { valueName: SystemFieldNames.plantingFormat, conditionType: 'anyOf', testValue: [PLANTING_FORMATS.GROUP_CONTAINER, PLANTING_FORMATS.GROUP_GROUND] },
            { valueName: SystemFieldNames.plantSpacingUnit, conditionType: 'notMatches', testValue: 'seed' },
            { valueName: SystemFieldNames.plantSpacingUnit, conditionType: 'notMatches', testValue: 'plant' }
          ]
        },

        /** FINAL PREP */
        {
          // id: 'container layout',
          sectionId,
          entryValues: {
            description: 'Place the containers on a stable surface where they will each get a similar amount of sunlight.'
          },
          visibleWhen: [{ valueName: SystemFieldNames.location, conditionType: 'equals', testValue: 'containers' }]
        },
        {
          sectionId,
          automaticEntryKey: 'watering in'
        },
        {
          // id: 'schedule',
          sectionId,
          entryValues: {
            description: 'Review the project schedule for your next steps.'
          }
        }
      ]
    }
  }
