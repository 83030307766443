import React from 'react'
import type { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types'

interface BoundingBoxHookFormat<T extends HTMLElement> {
  width: number
  outerElementRef: React.MutableRefObject<T>
  isResizing: boolean
}

const
  getBoundingBox = <T extends HTMLElement>(element?: T): number => element?.getBoundingClientRect()?.width ?? 0,
  useBoundingBox = <T extends HTMLElement>(): BoundingBoxHookFormat<T> => {
    const
      outerElementRef = React.useRef<T>(),
      timeoutRef = React.useRef<TimeoutId | undefined>(),
      [width, setWidth] = React.useState<number>(getBoundingBox()),
      [isResizing, setIsResizing] = React.useState<boolean>(false),
      [hasRemeasured, setHasRemeasured] = React.useState<boolean>(false)

    React.useEffect(() => {
      const
        setResizeTimeout = (): void => {
          clearTimeouts()
          timeoutRef.current = setTimeout(() => { setIsResizing(false) }, 500)
          setIsResizing(true)
          setHasRemeasured(false)
        },
        clearTimeouts = (): void => {
          if (timeoutRef.current) { clearTimeout(timeoutRef.current) }
        }

      window.addEventListener('resize', setResizeTimeout)

      return () => {
        window.removeEventListener('resize', setResizeTimeout)
        clearTimeouts()
      }
    }, [timeoutRef, setIsResizing, setHasRemeasured])

    React.useEffect(() => {
      if (!isResizing) {
        setWidth(getBoundingBox(outerElementRef.current))
        setHasRemeasured(true)
      }
    }, [isResizing])

    return {
      width,
      outerElementRef: outerElementRef as React.MutableRefObject<T>,
      isResizing: isResizing || !hasRemeasured
    }
  }

export default useBoundingBox
