import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  MEASUREMENT_END_UNITS = {
    AFTER_PLANTING: 'days after planting',
    AFTER_GERMINATION: 'days after germination',
    AFTER_FIRST_MEASUREMENT: 'days after first measurement'
  } as const,
  title = 'Unit for timing of final measurement',
  description = 'Provides a reference point for the timing of the final measurement so that it can be accurately incorporated into the schedule.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.measurementEndUnit,
    fieldType: 'ENUM',
    options: [
      { optionValue: MEASUREMENT_END_UNITS.AFTER_PLANTING },
      { optionValue: MEASUREMENT_END_UNITS.AFTER_GERMINATION },
      { optionValue: MEASUREMENT_END_UNITS.AFTER_FIRST_MEASUREMENT }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.measurementEndUnit,
    variableGroup: SystemFieldGroups.SCHEDULE,
    shouldFollow: [SystemFieldNames.measurementTimingScheme],
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails,
  MEASUREMENT_END_UNITS
}
