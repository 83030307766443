import React from 'react'
import styled from 'styled-components'
import { Form } from '~/form-brain2'
import { experimentFormResolvers } from '~/features'
import { useUpdateEntity } from '~/hooks'
import { Button } from '~/components/atoms'
import { DialogueContainer, FormRenderer, ModalOverlay } from '~/components/molecules'

import type { EntitySchema, ToggleFn, FormOnSubmitFn, EntityObject } from '~/form-brain2'
import type { TextInput, InputElement, User, SubheadingElement, InstructionsElement } from '~/features'

interface Props {
  user: User
  onClose: ToggleFn
  onSuccess: ToggleFn
}

const
  StyledDialogueContainer = styled(DialogueContainer)`
    width: ${p => p.theme.emMediumContentWidth}em;
    maxWidth: 100%;
  `,
  formName = 'adminUpdateUser',
  schema: EntitySchema = {
    unconfirmedEmail: {
      fieldName: 'unconfirmedEmail',
      fieldType: 'STRING'
    }
  },
  body: Array<InputElement | TextInput | SubheadingElement | InstructionsElement> = [{
    id: 'subheading',
    elementVariety: 'SUBHEADING',
    text: 'Account email'
  }, {
    id: 'oldEmail',
    elementVariety: 'TEXT_INPUT',
    fieldName: 'email',
    inputType: 'email',
    label: 'Current Email:',
    readOnlyWhen: [{ conditionType: 'always' }]
  }, {
    id: 'newEmail',
    elementVariety: 'TEXT_INPUT',
    fieldName: 'unconfirmedEmail',
    inputType: 'email',
    label: 'New Email:',
    visibleWhen: [{ conditionType: 'equals', valueName: 'testmode', testValue: false, conditionScope: 'rootScope' }]
  }, {
    id: 'newEmailNote',
    elementVariety: 'INSTRUCTIONS',
    paragraphs: ['This is a test account. Log in to the account to change its email.'],
    visibleWhen: [{ conditionType: 'equals', valueName: 'testmode', testValue: true, conditionScope: 'rootScope' }]
  }, {
    id: 'subheading',
    elementVariety: 'SUBHEADING',
    text: 'Account roles'
  }, {
    id: 'teacher',
    elementVariety: 'CHECKBOX_INPUT',
    fieldName: 'isTeacher',
    label: 'Teacher'
  }, {
    id: 'researcher',
    elementVariety: 'CHECKBOX_INPUT',
    fieldName: 'isResearcher',
    label: 'Researcher'
  }, {
    id: 'admin',
    elementVariety: 'CHECKBOX_INPUT',
    fieldName: 'isAdmin',
    label: 'Admin'
  }],
  AccountManagementModal: React.FC<Props> = ({ user, onClose, onSuccess }) => {
    const
      { updateEntity, /*, errors, meta, */ isUpdating } = useUpdateEntity(),
      onSubmit: FormOnSubmitFn = ({ values }) => updateEntity({ entity: user, newValues: values })

    // return <div>{user.id}</div>
    return <ModalOverlay wholePage>
      <StyledDialogueContainer
        heading="Manage User Account"
        body={
          <Form
            formId={formName}
            entitySchema={schema}
            formBody={body}
            initialValues={user as unknown as EntityObject}
            onSubmitCallback={onSubmit}
            onSuccessCallback={onSuccess ?? onClose}
            FormRenderer={FormRenderer}
            formOptions={{ RootFormRenderer: FormRenderer }}
            // subscribeToFormStatus={subscribeToFormStatus}
            {...experimentFormResolvers}
          />
        }
        footerContent={
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Button
              label="Close"
              isDisabled={isUpdating}
              onClick={() => { onClose() }}
              color="caution"
              size="medium"
            />
            <Button
              label="Save Changes"
              isLoading={isUpdating}
              // isLoading={formStatus === 'processing'}
              form={formName}
              type="submit"
              color="forward"
              size="medium"
            />
          </div>
        }
      />
    </ModalOverlay>
  }

export default AccountManagementModal
