import * as React from 'react'
import type { BasicIconProps } from '~/types/utilities'

const
  BasicStar: React.FC<BasicIconProps> = ({ className, color = 'black', outlineColor }) => {
    return (
      <svg
        className={className}
        viewBox="-1.5 -1.5 30 30"
        xmlns="http://www.w3.org/2000/svg"
        data-cy="basic-star">
        <path
          fill={color}
          stroke={outlineColor}
          strokeWidth="1.8px"
          d="M21.1603 26.9309C20.8351 26.9309 20.5115 26.8283 20.233 26.6238L13.6846 21.7936L7.13621 26.6238C6.57837 27.0344 5.82976 27.0344 5.27513 26.6171C4.72051 26.204 4.48707 25.4704 4.69475 24.8006L7.13702 16.6935L0.647387 11.99C0.0959859 11.5719 -0.133429 10.8367 0.0774717 10.1661C0.289983 9.4971 0.895317 9.04256 1.57954 9.03759L9.61712 9.02517L12.1761 1.11922C12.3918 0.44942 12.9996 -0.000976562 13.6846 -0.000976562C14.3696 -0.000976562 14.9774 0.450248 15.1939 1.11922L17.7094 9.02517L25.7881 9.03759C26.4747 9.04256 27.0808 9.49792 27.2909 10.1661C27.5026 10.8367 27.2732 11.5719 26.721 11.99L20.2314 16.6935L22.6737 24.8006C22.8829 25.4704 22.6471 26.204 22.0941 26.6171C21.8148 26.8274 21.4879 26.9309 21.1603 26.9309Z"
        />
      </svg>
    )
  }

export default BasicStar
