import * as React from 'react'
import type { BasicIconProps } from '~/types/utilities'

const
  BasicSquare: React.FC<BasicIconProps> = ({ className, color = 'black', outlineColor }) => {
    return (
      <svg
        className={className}
        viewBox="-1.5 -1.5 53 53"
        xmlns="http://www.w3.org/2000/svg"
        data-cy="basic-square">
        <rect
          width="50"
          height="50"
          fill={color}
          stroke={outlineColor}
          strokeWidth="3px"
          strokeLinejoin="round"
        />
      </svg>
    )
  }

export default BasicSquare
