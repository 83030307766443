import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Number of replicates (per treatment)',
  description = 'If growing groups of plants, this is the nuber of different groups receiving the same treatment. If growing individual plants, this is the number of different plants receiving the same treatment.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.replicateCount,
    fieldType: 'NUMBER'
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.replicateCount,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
