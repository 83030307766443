import { groupBy, mapValues, reduce, sortBy, sumBy } from 'lodash'
import { getValueAcrossEntities } from '../calculatedValues'

import type { Experiment } from '~/features/experiment/types'
import type { EntityObject } from '~/form-brain2'
import type { SubjectObject } from '../fieldSelectors/treatmentSelectors'
import type { ExperimentDataPoint } from './getDataPoints'

export interface SubjectValue {
  name: string
  id: string
  value: number
  isOutlier: boolean
}

const
  getTotal = (vs: ExperimentDataPoint[]): number => {
    return sumBy(vs, 'value')
  },
  getAverage = (vs: ExperimentDataPoint[]): number => {
    return sumBy(vs, 'value') / vs.length
  },
  getRate = (vs: ExperimentDataPoint[]): number => {
    const dateSortedValues = sortBy(vs, 'date')

    if (dateSortedValues.length < 2) {
      return 0
    }

    const
      lastValue = dateSortedValues[dateSortedValues.length - 1],
      firstValue = dateSortedValues[0],
      differenceInDays = lastValue.date.diff(firstValue.date).as('days'),
      valueDifference = lastValue.value - firstValue.value

    return differenceInDays <= 0
      ? 0
      : valueDifference / differenceInDays
  },
  getAggregatedValues = (dataPoints: ExperimentDataPoint[], experiment: Experiment, entities: EntityObject[]): Record<string, number> => {
    const
      pointsBySubject = groupBy(dataPoints, 'subjectId'),
      measurementRelationship = getValueAcrossEntities('measurementTiming.measurementRelationship', entities),
      aggregatedValues = mapValues(pointsBySubject, measurementRelationship === 'total' ? getTotal : measurementRelationship === 'rate' ? getRate : getAverage)

    return aggregatedValues
  },
  getGroupedValues = (
    dataPoints: ExperimentDataPoint[],
    experiment: Experiment,
    entities: EntityObject[],
    subjectsById: Record<string, SubjectObject>,
    outlierSubjectIds: string[]
  ): { valuesBySubject: Record<string /* subjectId */, number>, valueSetsByTreatment: Record<string /* treatment */, SubjectValue[]> } => {
    const
      aggregatedValues = getAggregatedValues(dataPoints, experiment, entities),
      groupedValues = reduce(aggregatedValues, (memo, val, subjectId) => {
        const
          subject = subjectsById[subjectId],
          newVal: SubjectValue = {
            name: subject.subjectName,
            value: val,
            id: subjectId,
            isOutlier: outlierSubjectIds.includes(subjectId)
          }

        return {
          ...memo,
          [subject.treatment]: [
            ...(memo[subject.treatment] ?? []),
            newVal
          ]
        }
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      }, {} as Record<string, SubjectValue[]>)

    return {
      valuesBySubject: aggregatedValues,
      valueSetsByTreatment: groupedValues
    }
  }

export default getAggregatedValues
export { getGroupedValues }
