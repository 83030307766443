import * as React from 'react'
import type { BasicIconProps } from '~/types/utilities'

const
  BasicAsterisk: React.FC<BasicIconProps> = ({ className, color = 'black', outlineColor }) => {
    return (
      <svg
        className={className}
        viewBox="-0.5 -1 25 25"
        xmlns="http://www.w3.org/2000/svg"
        data-cy="basic-asterisk">
        <path
          fill={color}
          stroke={outlineColor}
          strokeWidth="1.5px"
          d="M11.0305 0.836834C11.2849 -0.169847 12.7151 -0.16985 12.9695 0.836832L14.1929 5.67818C14.3456 6.28241 15.0078 6.60131 15.5754 6.34395L20.1232 4.28187C21.0689 3.8531 21.9607 4.97135 21.3322 5.79788L18.3098 9.77287C17.9326 10.269 18.0962 10.9855 18.6513 11.2688L23.099 13.5388C24.0239 14.0108 23.7056 15.4053 22.6675 15.4293L17.6754 15.5446C17.0523 15.559 16.594 16.1337 16.7187 16.7443L17.717 21.637C17.9246 22.6544 16.636 23.2749 15.97 22.4783L12.7672 18.6472C12.3675 18.1691 11.6325 18.1691 11.2328 18.6472L8.03 22.4783C7.36403 23.2749 6.07537 22.6544 6.28297 21.637L7.28135 16.7443C7.40595 16.1337 6.9477 15.559 6.32464 15.5446L1.33246 15.4293C0.294412 15.4053 -0.0238619 14.0108 0.900976 13.5388L5.34872 11.2688C5.90383 10.9855 6.06737 10.269 5.69016 9.77287L2.6678 5.79789C2.03934 4.97135 2.93112 3.8531 3.87677 4.28187L8.42464 6.34395C8.99225 6.60131 9.65443 6.28241 9.80712 5.67818L11.0305 0.836834Z"
        />
      </svg>
    )
  }

export default BasicAsterisk
