import * as React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { DOCUMENT_TITLES, HEADER_TEXTS } from '~/utils/strings'
import { getSortedTopLevelWorksheets } from '~/features/worksheet/helpers'
import { selectTemplateSetById, selectTemplateById, selectTemplatesBySetId } from '~/features/entity/selectors'
import { useAppSelector, useEntityData, useDeleteEntity } from '~/hooks'
import BASE_THEME from '~/components/BaseTheme'
import { Button, HeadingOne, Link, SubheadingOne, SizedLoadingIcon } from '~/components/atoms'
import { ErrorMessage } from '~/components/molecules'
import { QuestionnaireSettings } from '~/components/organisms'
import { BrandedPage, FlexibleMain } from '~/components/layouts'
import { ErrorPage, Ul, Li, LeadIn, ContactUsMessage } from '~/components/pages/NotFoundPage'
import DeleteConfirmationComplex from '~/components/organisms/DeleteConfirmationComplex'

import type { ListTemplate, PagedTemplate, TemplateSet } from '~/features'

const
  Main = styled(FlexibleMain)`
    min-height: 500px;
    margin-bottom: 500px;
  `,
  StyledLoadingIcon = styled(SizedLoadingIcon)`
    margin-top: 20px;
  `,
  UpperRightDeleteComplex = styled(DeleteConfirmationComplex)`
    position: absolute;
    top: ${p => p.theme.emBaseSpacing * 1.55}em; // 17px
    right: ${p => p.theme.emBaseSpacing * 1.55}em; // 17px
  `,
  TemplatePage: React.FC = () => {
    const
      { id } = useParams(),
      navigate = useNavigate(),
      { dataStatus, isLoading } = useEntityData(),
      { deleteEntity, isDeleting } = useDeleteEntity(),
      template = useAppSelector(selectTemplateById(id)),
      templateSet = useAppSelector(selectTemplateSetById(template?.templateSetId)),
      parentTemplate = useAppSelector(selectTemplateById(template?.parentTemplateId)),
      allTemplates = useAppSelector(selectTemplatesBySetId(templateSet?.id)),
      sortedTopLevelTemplates = getSortedTopLevelWorksheets(allTemplates),
      stepNumber = template
        ? sortedTopLevelTemplates.indexOf(template) + 1
        : '?',
      prevTemplateSetId = React.useRef(templateSet?.id),
      templateSetTitle = templateSet ? templateSet.title : undefined,
      templateSetPath = templateSet ? `/template-sets/${templateSet?.id}` : undefined,
      deleteTemplate = (): void => {
        if (!id || !prevTemplateSetId.current) { return }

        deleteEntity({ type: 'template', id }).then(
          () => { navigate(`/template-sets/${prevTemplateSetId.current as string}`) },
          () => {}
        )
      }

    React.useEffect(() => {
      if (templateSet?.id && templateSet.id !== prevTemplateSetId.current) {
        prevTemplateSetId.current = templateSet.id
      }
    }, [templateSet?.id])

    if (dataStatus === 'initializing' || dataStatus === 'uninitialized') {
      return <BrandedPage documentTitle={DOCUMENT_TITLES.loading} headerText={HEADER_TEXTS.teaching} whichHome="teaching" showLoader />
    } else if ((!templateSet || !template) && dataStatus !== 'error') {
      return <ErrorPage errorTitle="Template Not Found" message="Template Not Found">
        <LeadIn>What happened?</LeadIn>
        <Ul>
          <Li>Check the web address for typos</Li>
          <Li>The group you are looking for may have been deleted</Li>
          <Li>You may not have access to this group</Li>
        </Ul>

        <LeadIn>Return to <Link to="/account/templates">All Template Sets</Link></LeadIn>
        <ContactUsMessage />
      </ErrorPage>
    }

    return <ThemeProvider theme={BASE_THEME}>
      <BrandedPage
        documentTitle={`Template Settings${template?.name ? ` | ${template.name}` : ''} | AgConnect`}
        whichHome="teaching"
        headerText={HEADER_TEXTS.teaching}>
        <Main
          heading={!template
            ? <HeadingOne>Step Template</HeadingOne>
            : parentTemplate
              ? <HeadingOne>{parentTemplate.name} Step: {template.name}</HeadingOne>
              : <HeadingOne>Step {stepNumber}: {template.name}</HeadingOne>}
          subheading={<SubheadingOne>{templateSetTitle}</SubheadingOne>}
          topActions={templateSetPath ? <Button label="Template Set" withIcon="arrowL" color="back" size="medium" linkProps={{ to: templateSetPath }} /> : undefined}
          absoluteContent={
            template && !isLoading && !templateSet?.publishedAt
              ? <UpperRightDeleteComplex
                triggerLabel="Delete Template"
                subheading={`Delete: ${template.name}`}
                onConfirmDelete={deleteTemplate}
                isDisabled={false}
                isProcessing={!!isDeleting}
                overrideButtonProps={{ size: 'medium' }}
              />
              : undefined
          }
          >
          {dataStatus === 'error'
            ? <ErrorMessage>
              There was a problem retrieving the template. Please try again later and contact support if the problem persists.
            </ErrorMessage>
            : isLoading
              ? <StyledLoadingIcon />
              : templateSet?.publishedAt
                ? <ErrorMessage>The template set to which this template belongs has been published and cannot be edited.</ErrorMessage>
                : <QuestionnaireSettings
                  questionnaire={template as ListTemplate | PagedTemplate}
                  questionnaireGroup={templateSet as TemplateSet}
                  allQuestionnaires={allTemplates}
                />}
        </Main>
      </BrandedPage>
    </ThemeProvider>
  }

export default TemplatePage
