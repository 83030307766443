import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { cloneDeep, merge } from 'lodash'
import { isEmptyValue } from '~/utils/testers'
import { WorksheetHelpers } from '~/features'
import { Button } from '~/components/atoms'
import PageForm from '~/components/organisms/PagedStep/PageForm'

import type { FormOnSubmitFn } from '~/form-brain2'
import type { Experiment, PageNavDetails, PagedSectionElement, PagedWorksheet, WorksheetResponse } from '~/features'
import PreviewFallbackRenderer from '../PreviewErrorBoundary'
import { TabGroup } from '~/components/molecules'
import StepSectionTabs from '~/components/organisms/PagedStep/StepSectionTabs'

interface Props {
  experiment: Experiment
  worksheet: PagedWorksheet
  section: PagedSectionElement
  selectedPage: number
  setSelectedPage: (page?: number) => void
  setSelectedSection: (sectionId?: string) => void
  response: WorksheetResponse
  otherResponses: WorksheetResponse[]
  updateResponse: (updatedResponse: WorksheetResponse) => void
}

const
  PagePreview: React.FC<Props> = ({ experiment, worksheet, section, selectedPage, setSelectedPage, setSelectedSection, response, otherResponses, updateResponse }) => {
    const
      currentPage = section.pages[selectedPage],
      sectionIndex = worksheet.sections.indexOf(section),
      navDetailsForPage = WorksheetHelpers.getNavDetailsForPage({
        worksheet,
        pageIndex: selectedPage,
        sectionIndex
      }),
      updateExampleResponse: FormOnSubmitFn = ({ values, submissionSource }) => {
        updateResponse({
          ...response,
          responses: merge(cloneDeep(response.responses), values)
        })
        return Promise.resolve({ submissionSource })
      },
      resetResponse = (): void => {
        updateResponse({ ...response, responses: {} })
      },
      tweakedNavDetails: PageNavDetails = selectedPage === section.pages.length - 1
        ? {
            ...navDetailsForPage,
            submitOnForward: false,
            forwardNav: {
              ...navDetailsForPage.forwardNav,
              ...(sectionIndex === worksheet.sections.length - 1
                ? {
                    labelKey: '_saveExampleData',
                    withIcon: 'checkmark'
                  }
                : {}
              )
            }
          }
        : navDetailsForPage

    return (
      <ErrorBoundary fallbackRender={PreviewFallbackRenderer}>
        <TabGroup>
          <StepSectionTabs onTabClick={setSelectedSection} {...{ worksheet, sectionIndex, farthestReachedSectionIndex: sectionIndex }} />
        </TabGroup>
        <PageForm
          key={`${worksheet.id}-${sectionIndex}-${selectedPage}-form`}
          navDetailsForPage={tweakedNavDetails}
          hideSectionTabs
          permitMissingInterpolation
          {...{
            experiment,
            worksheet,
            section,
            response
          }}
          isFetchingData={false}
          isLoadingData={false}
          onSubmitCallback={updateExampleResponse}
          onSuccessCallback={(result, submissionSource) => {
            if (submissionSource === 'FORWARDS' && section.pages[selectedPage + 1]) {
              setSelectedPage(selectedPage + 1)
            } else if (submissionSource === 'FORWARDS' && worksheet.sections[sectionIndex + 1]) {
              setSelectedSection(worksheet.sections[sectionIndex + 1].id)
            }

            if (submissionSource === 'BACKWARDS' && section.pages[selectedPage - 1]) {
              setSelectedPage(selectedPage - 1)
            } else if (submissionSource === 'BACKWARDS' && worksheet.sections[sectionIndex - 1]) {
              setSelectedSection(worksheet.sections[sectionIndex - 1].id)
            }
          }}
          responses={[...otherResponses, response]}
          page={currentPage}
          pageIndex={selectedPage}
          sectionIndex={sectionIndex}
          pageId={currentPage.id}
        />
        {isEmptyValue(response.responses)
          ? null
          : <Button size="small" label="Reset Example Responses" onClick={resetResponse} />}
      </ErrorBoundary>
    )
  }

export default PagePreview
