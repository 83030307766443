import React from 'react'
import styled from 'styled-components'
import { ErrorText, FauxSelect } from '~/components/atoms'
import { ActionPointer, BotanicalFrame } from '~/components/molecules'
import type { Experiment, ListWorksheet, SpecialSectionId } from '~/features'
import { selectWorksheetsByExperimentId } from '~/features/entity/selectors'
import { getSectionList } from '~/features/worksheet/helpers'
import type { EntityObject, FullOptionList } from '~/form-brain2'
import { useAppSelector } from '~/hooks'
import { getHeadingForEntry } from '../ListStep/helpers'
import getResultSectionsAndResponses from './helpers/getResultSectionsAndResponses'
import SummaryStatsTable from './SummaryStatsTable'
import StatsTestSummary from './StatsTestSummary'

interface Props {
  experiment: Experiment
  responseEntities: EntityObject[]
}

const
  actionPointerLabel = `You can look at
your results using
this dropdown`,
  StyledActionPointer = styled(ActionPointer)`
    position: absolute;
    width: 58%;
    left: 21%;
    top: 10%;

    [data-cy="hint-text"] {
      font-size: 1.3rem;
      white-space: pre-line;
    }
  `,
  DropdownBackground = styled.div`
    border: 12.66px solid ${p => p.theme.colors.containerBg};
    border-bottom: none;
  `,
  ContentBackground = styled.div`
    border: 12.66px solid ${p => p.theme.colors.containerBg};
    background: ${p => p.theme.colors.containerBg};
    padding: 0px ${p => p.theme.emSmallSpacing}em;

    > :first-child {
      margin-top: 0;
    }
  `,
  ResultsExplorer: React.FC<Props> = ({ experiment, responseEntities }) => {
    const
      worksheets = useAppSelector(selectWorksheetsByExperimentId(experiment.id)),
      worksheetSections = worksheets.filter(o => o.format === 'LIST').flatMap(o => getSectionList(o as ListWorksheet)),
      relevantSectionsAndResponses = getResultSectionsAndResponses(worksheetSections, responseEntities),
      availableResults: FullOptionList = relevantSectionsAndResponses.flatMap(({ specialSectionId, responsesBySection }) => (
        responsesBySection.flatMap(({ section, responseEntries }) => (
          responseEntries.flatMap((listEntry, i) => ({
            value: `${specialSectionId}-${section.id}-${i}`,
            label: getHeadingForEntry({ section, entry: listEntry, otherEntities: responseEntities, experiment })
          }))
        ))
      )),
      [selectedResult, setSelectedResult] = React.useState<string | undefined>(),
      [specialSectionId, sectionId, resultIndex] = selectedResult ? (selectedResult.split('-') as [SpecialSectionId, string, number]) : [],
      selectedSectionAndResponses = specialSectionId && sectionId
        ? (relevantSectionsAndResponses.find(o => o.specialSectionId === specialSectionId) as typeof relevantSectionsAndResponses[number]).responsesBySection?.find(o => o.section.id === sectionId)
        : undefined,
      resultEntry = selectedSectionAndResponses && resultIndex !== undefined
        ? selectedSectionAndResponses.responseEntries[resultIndex]
        : undefined

    return <div data-cy="results-explorer">
      <DropdownBackground>
        <FauxSelect name="_result" id="selected-result-" placeholder="Choose a result to review" selected={selectedResult} onSelect={setSelectedResult} options={availableResults} />
      </DropdownBackground>
      {selectedResult && selectedSectionAndResponses && resultEntry
        ? <ContentBackground>
          {specialSectionId === 'SUMMARY_STATS'
            ? <SummaryStatsTable
              isReadOnly
              shrinkFinalColumn
              experiment={experiment}
              section={selectedSectionAndResponses.section}
              otherEntities={responseEntities}
              entry={resultEntry} />
            : <StatsTestSummary
              entry={resultEntry}
              otherEntities={responseEntities}
              />}
        </ContentBackground>
        : <div style={{ position: 'relative' }}>
          <BotanicalFrame />
          {selectedResult
            ? <ErrorText>The selected result was not found. Please reload your page and try again.</ErrorText>
            : <StyledActionPointer color="accent" label={actionPointerLabel} orientation="up" />}
        </div>}
    </div>
  }

export default ResultsExplorer
