import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { HintText } from '~/components/atoms/Text'
import { useClickOnKeyPress } from '~/hooks'
import { mergeRefs } from '~/hooks/utils'
import {
  outerTriangle,
  innerTriangle,
  pxBorderWidth,
  pxOverallWidth,
  pxRectangleWidth,
  pxRectangleMinHeight,
  pxTriangleLeft,
  pxTriangleTop,
  pxTriangleOuterHeight,
  pxTriangleOuterWidth
} from './speechBubbleGeometry'

interface SpeechBubbleProps {
  content: string
  accessibilityDescription: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
  innerRef?: React.MutableRefObject<any>
  className?: string
}

const
  speechBubbleWidth = pxOverallWidth,
  SpeechBubbleOuter = styled.div`
    position: relative;
    width: ${pxOverallWidth}px;
  `,
  TextOuter = styled.div<{ $withArrow: boolean, $clickable?: boolean }>`
    display: flex;
    flex: 0 0 auto;
    ${p => p.$withArrow ? '' : 'margin-left: auto;'} 

    background-color: ${p => p.theme.colors.containerAsideBg};
    flex-direction: row;
    align-items: center;
    width: ${pxRectangleWidth}px;
    min-height: ${pxRectangleMinHeight}px;
    border-radius: 17px;
    padding: ${p => p.theme.emSmallSpacing * 1.4}em ${p => p.theme.emSmallSpacing * 0.9}em;    
    border: ${pxBorderWidth}px solid ${p => p.theme.colors.borderSticker};
    box-shadow: 1px 1.5px 4px 0px ${p => p.theme.colors.shadow};

    ${p => p.$clickable ? 'cursor: pointer;' : ''}

    [data-cy=hint-text] {
      font-size: 1rem !important;
    }           
  `,
  BorderTriangle = styled.polygon`
    fill: ${p => p.theme.colors.borderSticker};
    // filter: drop-shadow(1px 1px 2.5px ${p => p.theme.colors.shadow});
  `,
  InnerTriangle = styled.polygon`
    fill: ${p => p.theme.colors.containerAsideBg};
  `,
  TriangleOuter = styled.div<{ $withArrow: boolean }>`
    position: absolute;
    left: ${pxTriangleLeft}px;    
    top: ${pxTriangleTop}px;
  `,
  SpeechBubble: React.FC<SpeechBubbleProps> = ({ className, content, onClick, innerRef, accessibilityDescription }) => {
    const
      THEME = useTheme(),
      elementRef = useClickOnKeyPress(),
      withArrow = THEME.showMascot

    return (
      <SpeechBubbleOuter
        aria-label={accessibilityDescription}
        ref={onClick ? mergeRefs(elementRef, innerRef) : innerRef}
        tabIndex={onClick ? 0 : -1}
        onClick={onClick}
        className={className}
        data-cy="speech-bubble">
        <TextOuter $withArrow={withArrow} $clickable={!!onClick}>
          <HintText color={'default'}>
            {content}
          </HintText>
        </TextOuter>
        <TriangleOuter $withArrow={withArrow}>
          {withArrow
            ? <svg height={pxTriangleOuterHeight} width={pxTriangleOuterWidth}>
                <BorderTriangle points={`${outerTriangle.topLeft.join(',')} ${outerTriangle.bottomLeft.join(',')} ${outerTriangle.right.join(',')}`} />
                <InnerTriangle points={`${innerTriangle.topLeft.join(',')} ${innerTriangle.bottomLeft.join(',')} ${innerTriangle.right.join(',')}`} />
              </svg>
            : null
          }
        </TriangleOuter>
      </SpeechBubbleOuter>
    )
  }

export default SpeechBubble
export { speechBubbleWidth }
