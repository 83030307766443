import * as React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { DOCUMENT_TITLES, HEADER_TEXTS } from '~/utils/strings'
import { useAppSelector, useEntityData } from '~/hooks'
import { ErrorPage, Ul, Li, LeadIn, ContactUsMessage } from '~/components/pages/NotFoundPage'
import { BrandedPage, FlexibleMain } from '~/components/layouts'
import { ErrorMessage } from '~/components/molecules'
import { Button, HeadingOne, Link, SizedLoadingIcon } from '~/components/atoms'
import { selectTemplateSetById } from '~/features/entity/selectors'
import { TemplateSetSettings } from '~/components/organisms'

import type { TemplateSet } from '~/features'

const
  Main = styled(FlexibleMain)`
    min-height: 500px;
  `,
  TemplateSetPage: React.FC = () => {
    let content
    const
      { id } = useParams(),
      { dataStatus, isLoading } = useEntityData(),
      templateSet = useAppSelector(selectTemplateSetById(id))

    if (dataStatus === 'initializing' || dataStatus === 'uninitialized') {
      return <BrandedPage documentTitle={DOCUMENT_TITLES.loading} headerText={HEADER_TEXTS.teaching} whichHome="teaching" showLoader />
    } else if (!templateSet && dataStatus !== 'error') {
      return <ErrorPage errorTitle="Template Set Not Found" message="Template Set Not Found">
        <LeadIn>What happened?</LeadIn>
        <Ul>
          <Li>Check the web address for typos</Li>
          <Li>The template set you are looking for may have been deleted</Li>
          <Li>You may not have access to this template set</Li>
        </Ul>

        <LeadIn>Return to <Link to="/projects">All Template Sets</Link></LeadIn>
        <ContactUsMessage />
      </ErrorPage>
    }

    if (dataStatus === 'error') {
      content = <ErrorMessage>
        There was a problem retrieving the template set. Please try again later and contact support if the problem persists.
      </ErrorMessage>
    } else if (isLoading) {
      content = <SizedLoadingIcon />
    } else {
      content = <TemplateSetSettings templateSet={templateSet as TemplateSet} />
    }

    const templateSetTitle = templateSet ? templateSet.title : undefined

    return (
      <BrandedPage
        documentTitle={`${templateSetTitle as string} | AgConnect`}
        whichHome="teaching"
        headerText={HEADER_TEXTS.teaching}>
        <Main
          heading={<HeadingOne>{templateSetTitle}</HeadingOne>}
          topActions={<Button label="All Templates" withIcon="arrowL" color="back" size="medium" linkProps={{ to: '/account/templates' }} />}
          >
          {content}
        </Main>
      </BrandedPage>
    )
  }

export default TemplateSetPage
