import * as React from 'react'
import { findLast, values } from 'lodash'
import { isEmptyValue } from '~/utils/testers'
import { WorksheetHelpers } from '~/features'
import { useAddWorksheetResponse, useAppSelector } from '~/hooks'
import {
  ScientificMethodCycle,
  ErrorMessage
} from '~/components/molecules'

import type { Experiment, Learner, Worksheet } from '~/features'
import { selectWorksheetResponses, selectWorksheets } from '~/features/entity/selectors'
import { getBaseErrorString } from '~/utils/formatters'
import { useNavigate } from 'react-router-dom'

interface ProjectHomeProps {
  learner: Learner
  experiment: Experiment
  setInfoPanelContent: (content: { title?: string, body?: string[], showWarning?: boolean }) => void
  setShowWaitForTeacherMessage: (shouldShow: boolean) => void
}

const
  ProjectHome: React.FC<ProjectHomeProps> = ({ learner, experiment, setInfoPanelContent, setShowWaitForTeacherMessage }) => {
    const
      navigate = useNavigate(),
      worksheetsById = useAppSelector(selectWorksheets),
      { addWorksheetResponse, isAdding } = useAddWorksheetResponse(),
      worksheets = values(worksheetsById).filter(o => o.experimentId === experiment.id),
      repsonsesById = useAppSelector(selectWorksheetResponses),
      responses = values(repsonsesById).filter(o => isEmptyValue(o.learnerId) || (o.learnerId === learner.id)),
      sortedVisibleWorksheets = WorksheetHelpers.getSortedTopLevelWorksheets(worksheets ?? []),
      worksheetStatusById = worksheets && responses
        ? WorksheetHelpers.getStudentFacingWorksheetStatuses(worksheets, responses, experiment.progressBasis)
        : {},
      stepSummaries = sortedVisibleWorksheets.map((worksheet: Worksheet, index: number) => {
        const
          status = worksheetStatusById[worksheet.id],
          { name } = worksheet,
          hasResponse = responses.some(o => o.worksheetId === worksheet.id),
          buildResponse = status !== 'closed' && !hasResponse
            ? () => {
                void addWorksheetResponse(worksheet, learner, experiment)().then((result) => {
                  if (result.errors) {
                    alert(getBaseErrorString(result.errors, 'Unable to start a response for this worksheet. Please try again and contact support if the problem persists.'))
                  } else {
                    navigate(`/project-step/${worksheet.id}`)
                  }
                })
              }
            : undefined

        return {
          label: name,
          buildResponse,
          isBuilding: !!(buildResponse && isAdding),
          isGroup: worksheet.fixedRank
            ? experiment.experimentStyle === 'group'
            : worksheet.submitAsGroup,
          index,
          status,
          worksheetId: worksheet.id
        }
      }),
      currentStepSummary = findLast(stepSummaries, o => o.status !== 'closed'),
      currentStepId = currentStepSummary?.worksheetId,
      nextStepClosed = currentStepSummary?.status === 'submitted' || currentStepSummary?.status === 'approved'
        ? !!stepSummaries[stepSummaries.indexOf(currentStepSummary) + 1]
        : false,
      currentStepText = currentStepId && worksheets
        ? worksheets.find(o => o.id === currentStepId)?.homePageText
        : undefined,
      currentStepLabel = currentStepId
        ? stepSummaries.find(o => o.worksheetId === currentStepId)?.label
        : undefined,
      currentStepIsGroup = !!currentStepSummary?.isGroup

    React.useEffect(() => {
      if (!!currentStepText || currentStepLabel) {
        setInfoPanelContent({
          body: typeof currentStepText === 'string' ? [currentStepText] : currentStepText,
          title: `${currentStepLabel as string}: Helpful Information`,
          showWarning: currentStepIsGroup
        })
      } else {
        setInfoPanelContent({ showWarning: currentStepIsGroup })
      }
    }, [currentStepText, currentStepLabel, currentStepIsGroup, setInfoPanelContent])

    React.useEffect(() => {
      if (nextStepClosed) {
        setShowWaitForTeacherMessage(true)
      }
    }, [nextStepClosed, setShowWaitForTeacherMessage])

    if (!worksheets || !responses) {
      return <ErrorMessage>
        There was a problem retrieving the project. Please try again later and contact support if the problem persists.
      </ErrorMessage>
    }

    return <ScientificMethodCycle stepSummaries={stepSummaries} />
  }

export default ProjectHome
