import React from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { get } from 'lodash'
import { getSemiRandomString } from '~/utils/strings'
import { processAxiosResultForForm } from '~/utils/errorMessages'
import { baseUrl } from '~/services/api'
import { getState } from '~/services/store'
import { learnerAuthenticated, raceOperationCompleted, raceOperationIgnored, raceOperationStarted, userAuthenticated } from '~/features/session/sessionSlice'
import { selectIsWinningOperation, selectWinningOperationId } from '~/features/session/selectors'
import { entitiesReceived } from '~/features/entity/entitySlice'
import useClientId from './useClientId'

import type { AttrErrors, EntityErrors } from '~/form-brain2'
import type { User } from '~/features'
import type { APIEntity } from 'cypress/support/types'

interface UseMagicLink {
  redeemToken: (token: string) => void
  isInitializing?: boolean
  isProcessing?: boolean
  isSuccess?: boolean
  errors?: AttrErrors
}

interface UseMagicLinkState {
  isInitializing?: boolean
  isProcessing?: boolean
  isSuccess?: boolean
  rawErrors?: EntityErrors
}

const
  useMagicLink = (variant: 'teacher' | 'learner'): UseMagicLink => {
    const
      dispatch = useDispatch(),
      { clientId } = useClientId(),
      [state, setState] = React.useState<UseMagicLinkState>({ isInitializing: !clientId }),
      { isInitializing, isProcessing, isSuccess, rawErrors } = state,
      errors = rawErrors?.base as AttrErrors,
      path = variant === 'teacher'
        ? 'sessions'
        : 'learner_sessions',
      operationKey = `POST:${path}`,
      actionOnAuthenticate = variant === 'teacher'
        ? userAuthenticated
        : learnerAuthenticated,
      redeemToken = React.useCallback((token: string): void => {
        if (selectWinningOperationId(operationKey)(getState())) { return }

        const
          operationId = getSemiRandomString(),
          requestInfo = { key: operationKey, operationId }

        setState({ isProcessing: true })
        dispatch(raceOperationStarted(requestInfo))

        processAxiosResultForForm(axios({
          method: 'post',
          url: `${baseUrl}${path}`,
          data: {
            clientId,
            loginToken: token,
            // clientNickname,
            withRefresh: true
          }
        }))
          .then(({ data }) => {
            const
              entities = get(data, 'data') as [APIEntity<User>],
              { accessToken, refreshToken } = get(data, 'meta', {}) as ({ accessToken: string, refreshToken?: string }),
              isWinningOperation = selectIsWinningOperation(requestInfo)(getState())

            if (isWinningOperation) {
              dispatch(entitiesReceived(entities))
              dispatch(actionOnAuthenticate({ accessToken, refreshToken, id: entities[0].id }))
              dispatch(raceOperationCompleted(requestInfo))
            } else {
              dispatch(raceOperationIgnored(requestInfo))
            }

            setState({
              isProcessing: false,
              isSuccess: true
            })
          })
          .catch(({ errors }) => {
            setState({
              isProcessing: false,
              rawErrors: errors
            })
          })
      }, [operationKey, dispatch, path, clientId, actionOnAuthenticate])

    React.useEffect(() => {
      if (clientId && isInitializing) {
        setState(state => ({ ...state, isInitializing: false }))
      }
    }, [clientId, setState, isInitializing])

    return {
      redeemToken,
      isInitializing,
      isProcessing,
      isSuccess,
      errors
    }
  }

export default useMagicLink
