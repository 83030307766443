import * as React from 'react'
import styled from 'styled-components'
import { Navigate, useNavigate } from 'react-router-dom'
import { ExperimentHelpers, WorksheetHelpers, WorksheetResponseHelpers } from '~/features'
import { useLearnerStepContext, useFormVitalsSubscription, useUpdateEntity } from '~/hooks'
import { Button, Icon, SizedLoadingIcon, BasicStar } from '~/components/atoms'
import { ContentFooter, ContentHeader, ResponseEventLog } from '~/components/molecules'
import SubmitConfirmationComplex from '~/components/organisms/SubmitConfirmationComplex'
import StepOverviewForm from '~/components/organisms/OverviewForms/StepOverviewForm'

import type { FormOnSubmitFn, FormOnSuccessFn } from '~/form-brain2'
import type {
  ListWorksheet,
  PagedWorksheet,
  WorksheetResponse,
  PlainResponse
} from '~/features'
import { getBaseErrorString } from '~/utils/formatters'

type ReviewFormSubmissionSource = 'CONFIRM_SUBMIT' | 'WAIT_TO_SUBMIT' | 'ESCAPE_HATCH'

const
  { getDynamicString } = ExperimentHelpers,
  noop: React.MouseEventHandler = (e) => { if (e) { e.preventDefault(); e.stopPropagation() } },
  WorksheetPage = styled.div`
    display: flex;
    flex-direction: column;
    min-height: ${p => p.theme.emMediumContentWidth * 0.9}em;
    background: ${p => p.theme.colors.containerDarkBg};
  `,
  StyledContentHeader = styled(ContentHeader)`
    flex: 0 0 auto;
  `,
  ContentBody = styled.div`
    flex: 1 0 auto;
  `,
  StyledContentFooter = styled(ContentFooter)`
    flex: 0 0 auto;

    @media(max-width: 500px) {
      flex-wrap: wrap;
      height: auto;
    }
  `,
  ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  EscapeHatchButton = styled(Button)`
    font-size: 1.1em;
    align-self: center;
    margin-bottom: ${p => p.theme.emBaseSpacing * 1.5}em;
  `,
  LearnerStepReview: React.FC = () => {
    const
      navigate = useNavigate(),
      { worksheet, response, responses, experiment, setMascotState, isFetchingData, isLoadingData } = useLearnerStepContext(),
      { updateEntity, errors, isUpdating } = useUpdateEntity(),
      { isProcessing, formSubmissionSource, subscribeToFormStatus, formMethodsRef } = useFormVitalsSubscription<ReviewFormSubmissionSource>(),
      responseStatus = WorksheetResponseHelpers.getResponseStatus(response),
      isList = worksheet.format === 'LIST',
      skipSubmission = worksheet.skipSubmission,
      parsedError = getBaseErrorString(
        errors,
        'Unable to add a response for this step. Please try again later and contact support if the problem continues.'
      ),
      submitFromSource = (source: ReviewFormSubmissionSource): React.MouseEventHandler => {
        const handler: React.MouseEventHandler = (e) => {
          if (formMethodsRef.current?.triggerFormSubmission) {
            formMethodsRef.current.triggerFormSubmission(e, source)
          } else {
            noop(e)
          }
        }

        return handler
      },
      onSubmitCallback: FormOnSubmitFn = ({ values, submissionSource }) => {
        const
          updatedResponse = 'responses' in values
            ? values
            : { ...response, responses: values },
          valuesToSubmit = submissionSource === 'CONFIRM_SUBMIT'
            ? WorksheetResponseHelpers.addSubmissionLog(updatedResponse as unknown as WorksheetResponse)
            : updatedResponse

        return updateEntity({
          newValues: valuesToSubmit,
          entity: response
        })
      },
      onSuccessCallback: FormOnSuccessFn = (result, submissionSource) => {
        if ((submissionSource as ReviewFormSubmissionSource) === 'WAIT_TO_SUBMIT') {
          navigate('/project-home')
        } else if ((submissionSource as ReviewFormSubmissionSource) === 'ESCAPE_HATCH') {
          navigate(
            isList
              ? `/project-step/${worksheet.id}/all`
              : WorksheetHelpers.getPathForPage(
                worksheet.id,
                (response as PlainResponse).mostRecentSectionIndex ?? 0,
                (response as PlainResponse).mostRecentPageIndex ?? 0
              )
          )
        }
      },
      footerContent = [
        skipSubmission
          ? <Button
            key="back"
            isDisabled={isProcessing && formSubmissionSource !== 'ESCAPE_HATCH'}
            isLoading={isProcessing && formSubmissionSource === 'ESCAPE_HATCH'}
            onClick={submitFromSource('ESCAPE_HATCH')}
            color="back" size="large"
            label={getDynamicString(experiment, isList ? 'returnToListFromReview' : 'returnToPageFromReview')}
          />
          : <Button
            key="back"
            isDisabled={isProcessing && formSubmissionSource !== 'WAIT_TO_SUBMIT'}
            isLoading={isProcessing && formSubmissionSource === 'WAIT_TO_SUBMIT'}
            onClick={submitFromSource('WAIT_TO_SUBMIT')}
            color="back" size="large"
            label={getDynamicString(experiment, 'waitToSubmitStep')}
          />,
        skipSubmission
          ? <Button
            key="submit"
            isDisabled={isProcessing && formSubmissionSource !== 'WAIT_TO_SUBMIT'}
            isLoading={isProcessing && formSubmissionSource === 'WAIT_TO_SUBMIT'}
            onClick={submitFromSource('WAIT_TO_SUBMIT')}
            color="forward" size="large"
            withIcon="checkmark"
            label={getDynamicString(experiment, 'saveAndReturn')}
          />
          : <SubmitConfirmationComplex
            key="submit"
            closeOnSubmit
            errorMessage={errors ? parsedError : undefined}
            isDisabled={isProcessing && formSubmissionSource !== 'CONFIRM_SUBMIT'}
            isLoading={isProcessing && formSubmissionSource === 'CONFIRM_SUBMIT'}
            isUpdating={!!isUpdating}
            onConfirmSubmit={submitFromSource('CONFIRM_SUBMIT')}
          />
      ],
      formId = `worksheet-${worksheet.id}`,
      content = (isLoadingData || isFetchingData) && !formMethodsRef.current
        ? <SizedLoadingIcon />
        : <>
            <ContentBody>
              <StepOverviewForm
                formRef={formMethodsRef}
                formId={formId}
                worksheet={worksheet as ListWorksheet | PagedWorksheet}
                response={response}
                experiment={experiment}
                responses={responses}
                onSubmitCallback={onSubmitCallback}
                onSuccessCallback={onSuccessCallback}
                subscribeToFormStatus={subscribeToFormStatus}
              />
              {skipSubmission
                ? null
                : <ButtonWrapper>
                  <EscapeHatchButton
                    color="back"
                    size="small"
                    label={getDynamicString(
                      experiment,
                      isList ? 'returnToListFromReview' : 'returnToPageFromReview'
                    )}
                    isDisabled={isProcessing && formSubmissionSource !== 'ESCAPE_HATCH'}
                    isLoading={isProcessing && formSubmissionSource === 'ESCAPE_HATCH'}
                    onClick={submitFromSource('ESCAPE_HATCH')}
                  />
                </ButtonWrapper>}
            </ContentBody>
            <StyledContentFooter items={footerContent} />
            {responseStatus === 'submitted' || responseStatus === 'approved' || responseStatus === 'studentRequestsReopen'
              ? <Navigate to={`/project-step/${worksheet.id}/response`} replace />
              : null}
          </>

    React.useEffect(() => {
      setMascotState({
        mascotVariant: worksheet.reviewStepMascotVariant ?? 'knapsack',
        hintText: worksheet.reviewStepHintText ?? 'Review your work and then turn it in. Or save for later and you can come back some other time.'
      })
    }, [worksheet.reviewStepHintText, worksheet.reviewStepMascotVariant, setMascotState])

    return <>
      <ResponseEventLog eventLog={response.eventLog} {...{ experiment }} />
      {isList
        ? content
        : <WorksheetPage>
          <StyledContentHeader
            heading="Review Your Answers"
            icon={<Icon content={BasicStar} color="hero" />}
          />
          {content}
        </WorksheetPage>}
     </>
  }

export default LearnerStepReview
