import { get } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import { adminSummaryReceived } from '~/features/admin/adminSlice'
import { selectAdminSummary, selectHasInitializedAdminData } from '~/features/admin/selectors'
import { selectIsWinningOperation, selectWinningOperationId } from '~/features/session/selectors'
import { raceOperationCompleted, raceOperationIgnored, raceOperationStarted } from '~/features/session/sessionSlice'
import { baseUrl } from '~/services/api'
import { getState } from '~/services/store'
import { getSemiRandomString } from '~/utils/strings'
import useAppSelector from './useAppSelector'
import useSession from './useSession'

import type { AdminSummary } from '~/features/admin/adminSlice'

const
  operationKey = 'GET:adminSummary',
  useAdminSummary = (): { adminSummary: AdminSummary } => {
    const
      dispatch = useDispatch(),
      { activeToken, makeRequestWithSession } = useSession(),
      adminSummary = useAppSelector(selectAdminSummary),
      hasInitializedAdminData = useAppSelector(selectHasInitializedAdminData),
      fetchData = React.useCallback((): void => {
        const
          operationId = getSemiRandomString(),
          requestInfo = { key: operationKey, operationId }

        dispatch(raceOperationStarted(requestInfo))
        makeRequestWithSession({
          method: 'get',
          url: `${baseUrl}entities/summary`,
          headers: {
            Authorization: `Bearer ${activeToken as string}`
          }
        })
          .then(({ data }) => {
            const
              summary = get(data, 'data') as AdminSummary,
              isWinningOperation = selectIsWinningOperation(requestInfo)(getState())

            if (isWinningOperation) {
              dispatch(adminSummaryReceived(summary))
              dispatch(raceOperationCompleted(requestInfo))
            } else {
              dispatch(raceOperationIgnored(requestInfo))
            }
          })
          .catch(({ errors }) => {
            // const isWinningOperation = selectIsWinningOperation(requestInfo)(getState())
            dispatch(raceOperationCompleted(requestInfo))
            console.log('Could not get admin summary', errors)
          })
      }, [dispatch, activeToken, makeRequestWithSession])

    React.useEffect(() => {
      if (
        !hasInitializedAdminData &&
        !selectWinningOperationId(operationKey)(getState())
      ) {
        fetchData()
      }
    }, [fetchData, hasInitializedAdminData])

    return {
      adminSummary
    }
  }

export default useAdminSummary
