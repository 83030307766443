import * as React from 'react'
import type { BasicIconProps } from '~/types/utilities'

const
  backLegPath = 'M23.8823 49.4757C23.9098 50.2966 23.2545 50.9408 22.4196 50.9169C21.5847 50.893 20.8848 50.2101 20.8572 49.3892L20.44 36.9571C20.4124 36.1362 21.0677 35.492 21.9026 35.5159C22.7376 35.5398 23.4374 36.2227 23.465 37.0436L23.8823 49.4757Z',
  flaskBodyPath = 'M22.7473 42.2091C34.3359 42.5406 43.4209 33.5894 43.0391 22.2159C42.6574 10.8424 32.9535 1.35368 21.3649 1.02215C9.77626 0.690628 0.6913 9.64189 1.07304 21.0154C1.45479 32.3888 11.1587 41.8776 22.7473 42.2091Z',
  flaskNeckPath = 'M29.0895 2.78056C29.0895 2.78056 47.2611 9.47115 49.4286 10.2094C55.1224 12.1504 52.992 18.0563 49.0743 16.9921C41.3229 14.8855 27.1112 10.331 25.5563 10.2865C24.0015 10.242 29.0895 2.78056 29.0895 2.78056Z',
  flaskOpeningPath = 'M51.3971 14.4748C51.6634 13.4247 51.2383 12.3808 50.4476 12.1432C49.6569 11.9056 48.7999 12.5642 48.5336 13.6143C48.2672 14.6644 48.6923 15.7083 49.483 15.9459C50.2738 16.1835 51.1307 15.5249 51.3971 14.4748Z',
  liquidBodyPath = 'M4.20801 21.8672C4.87449 31.2796 12.9845 39.0275 22.6509 39.304C32.3174 39.5805 39.9224 32.2822 39.9577 22.8899L4.20801 21.8672Z',
  liquidSurfacePath = 'M22.1641 24.7968C32.0361 25.0793 40.0026 24.2256 39.9578 22.8901C39.913 21.5546 31.8738 20.243 22.0018 19.9606C12.1298 19.6782 4.16329 20.5319 4.20812 21.8674C4.25294 23.2029 12.2921 24.5144 22.1641 24.7968Z',
  standRingPath = 'M44.5405 34.6807C44.5955 36.3195 43.285 37.6108 41.6152 37.563L3.80244 36.4813C2.13263 36.4335 0.732779 35.0647 0.677773 33.4259C0.622767 31.7871 1.93333 30.4958 3.60314 30.5436L41.4159 31.6253C43.0857 31.6731 44.4855 33.0419 44.5405 34.6807Z',
  outerLegsPath = 'M3.69021 50.1325C3.43484 50.8978 2.58314 51.2739 1.78737 50.9752C0.991593 50.6766 0.553204 49.8158 0.808622 49.052L5.36415 35.4467C5.61956 34.6829 6.47122 34.3053 7.26699 34.6039C8.06276 34.9025 8.50266 35.7634 8.24573 36.5272L3.69021 50.1325ZM42.6479 51.247C42.9537 52.0284 43.8338 52.4539 44.6095 52.2003C45.3868 51.9467 45.7681 51.1093 45.4609 50.3294L39.9148 36.312C39.609 35.5307 38.7289 35.1051 37.9531 35.3572C37.1759 35.6108 36.7945 36.4482 37.1017 37.2281L42.6479 51.247Z',
  standHighlightPath = 'M42.9784 33.1527C43.0059 33.9736 42.3521 34.6178 41.5157 34.5939L3.70298 33.5121C2.86807 33.4882 2.1682 32.8053 2.14064 31.9844C2.11309 31.1635 2.76842 30.5194 3.60333 30.5433L41.416 31.625C42.2525 31.6489 42.9508 32.3318 42.9784 33.1527Z',
  RetortFlask: React.FC<BasicIconProps> = ({ className, color = 'black', outlineColor, colorClass = 'back' }) => {
    const
      glassColor = '#E1E8ED',
      flaskOpeningColor = '#AAB8C2',
      standHighlightColor = '#9AAAB4',
      standColor = ['understate', 'disabled'].includes(colorClass) ? standHighlightColor : '#67757F',
      liquidBodyColor = ['understate', 'disabled'].includes(colorClass) ? '#CCD6DD' : '#50A5E6',
      liquidSurfaceColor = ['understate', 'disabled'].includes(colorClass) ? '#BBC7D0' : '#3B94D9',
      coloredPaths = (extraProps?: { stroke?: string, strokeWidth?: number }): React.ReactNode => {
        return <>
          <path d={backLegPath} fill={standColor} {...extraProps} />
          <path d={flaskBodyPath} fill={glassColor} {...extraProps} />
          <path d={flaskNeckPath} fill={glassColor} {...extraProps} />
          <path d={flaskOpeningPath} fill={flaskOpeningColor} />
          <path d={liquidBodyPath} fill={liquidBodyColor} />
          <path d={liquidSurfacePath} fill={liquidSurfaceColor} />
          <path d={standRingPath} fill={standColor} {...extraProps} />
          <path d={outerLegsPath} fill={standColor} {...extraProps} />
          <path d={standHighlightPath} fill={standHighlightColor} />
        </>
      }

    return (
      <svg
        className={className}
        viewBox={outlineColor ? '-4 -4.5 62 62' : '-4 -2.5 58 58'}
        xmlns="http://www.w3.org/2000/svg"
        data-cy="retort-flask">
        {outlineColor
          ? coloredPaths({
            stroke: outlineColor,
            strokeWidth: 8
          })
          : null}
        {coloredPaths()}
      </svg>
    )
  }

export default RetortFlask
