import * as React from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { ExperimentHelpers, WorksheetResponseHelpers } from '~/features'
import { useLearnerStepContext, useUpdateEntity } from '~/hooks'
import { Button, SizedLoadingIcon } from '~/components/atoms'
import { ContentFooter, ResponseEventLog } from '~/components/molecules'
import StepOverviewForm from './OverviewForms/StepOverviewForm'

import type { ListWorksheet, PagedWorksheet, ResponseEventLog as EventLogEntry } from '~/features'

const
  { getDynamicString } = ExperimentHelpers,
  TempReopenWrapper = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
  WorksheetPage = styled.div`
    display: flex;
    flex-direction: column;
    min-height: ${p => p.theme.emMediumContentWidth * 0.9}em;
    background: ${p => p.theme.colors.containerDarkBg};
  `,
  ContentBody = styled.div`
    flex: 1 0 auto;
  `,
  StyledContentFooter = styled(ContentFooter)`
    flex: 0 0 auto;

    @media(max-width: 500px) {
      flex-wrap: wrap;
      height: auto;
    }
  `,
  LearnerStepSubmitted: React.FC = () => {
    const
      navigate = useNavigate(),
      { stepId } = useParams(),
      { worksheet, response, experiment, responses, setMascotState, isFetchingData } = useLearnerStepContext(),
      { updateEntity, isUpdating } = useUpdateEntity(),
      responseStatus = WorksheetResponseHelpers.getResponseStatus(response),
      isList = worksheet.format === 'LIST',
      onTestmodeReopen: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        updateEntity({ entity: response, newValues: { eventLog: [] } }).then(
          () => { navigate('/') },
          () => { alert('could not reopen') }
        )
      },
      onRequestReopen: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        const newLog: EventLogEntry = { eventType: 'studentRequestsReopen', eventOccurredAt: new Date().toISOString() }

        updateEntity({ entity: response, newValues: { eventLog: [...response.eventLog, newLog] } }).then(
          () => { /** No op */ },
          () => { alert('There was a problem completing this request. Please try again later and contact support if the problem continues.') }
        )
      },
      footerContent = responseStatus !== 'approved' && responseStatus !== 'studentRequestsReopen'
        ? [
            <Button
              key="solo"
              isDisabled={isFetchingData}
              isLoading={isUpdating}
              onClick={onRequestReopen}
              color="caution" size="large"
              label={getDynamicString(experiment, 'requestToMakeChanges')}
            />
          ]
        : null,
      content = isFetchingData
        ? <SizedLoadingIcon />
        : <>
            <ContentBody>
              <StepOverviewForm
                readOnly
                worksheet={worksheet as ListWorksheet | PagedWorksheet}
                response={response}
                experiment={experiment}
                responses={responses}
              />
            </ContentBody>
            {footerContent
              ? <StyledContentFooter items={footerContent} />
              : null}
          </>

    React.useEffect(() => {
      if (!isFetchingData && responseStatus && (responseStatus === 'inProgress' || responseStatus === 'revising')) {
        navigate(`/project-step/${stepId as string}`, { replace: true })
      }
    }, [responseStatus, isFetchingData, navigate, stepId])

    React.useEffect(() => {
      setMascotState({
        mascotVariant: worksheet.responsePageMascotVariant ?? 'recording',
        hintText: worksheet.responsePageHintText ?? `You are all set! Use the "${getDynamicString(experiment, 'backToHome')}" button to go back to the ${getDynamicString(experiment, 'learnerProjectHome')}.`
      })
    }, [worksheet, setMascotState, experiment])

    return <>
      <ResponseEventLog eventLog={response.eventLog} experiment={experiment} />
      {isList
        ? content
        : <WorksheetPage>
          {content}
        </WorksheetPage>}
      {worksheet.testmode
        ? <TempReopenWrapper>
            <Button
              size="medium"
              color="caution"
              label="Re-open (Test mode only)"
              isLoading={isUpdating}
              onClick={onTestmodeReopen}
            />
         </TempReopenWrapper>
        : null}
    </>
  }

export default LearnerStepSubmitted
