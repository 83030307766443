import * as React from 'react'
import { cloneDeep, get, set } from 'lodash'
import { FormContext } from './FormContext'
import type { EntityErrors, EntitySchema, UpdateValuesFn, FormOptions, FormScopeManagerProps, EntityObject } from './types'
import type { FormContextType } from './internalTypes'

const
  DEFAULT_ENTRY: EntityObject = {},
  FormScope: React.FC<FormScopeManagerProps> = (props) => {
    const
      // Form context and state
      formContext = React.useContext(FormContext),
      { updateValues, schema, errors, values, formOptions } = formContext,

      // Props
      { name, scopeOptions, formBody, ScopeEntryRenderer, ScopeRenderer } = props,
      defaultEntry = scopeOptions?.defaultEntry ?? DEFAULT_ENTRY,

      // Scope state
      schemaEntry = React.useMemo(() => (
        get(schema, name) ?? { type: 'scope', format: {} }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ), [formContext, name]),
      childSchema = schemaEntry.format as EntitySchema,
      scopedErrors = (get(errors, name) ?? {}) as EntityErrors,
      currentValue = (get(values, name) ?? defaultEntry) as EntityObject,
      initialValues = (get(formContext.initialValues, name) ?? defaultEntry) as EntityObject,

      // Scoped context
      updateScope: UpdateValuesFn = React.useCallback(({ newValues, silentUpdate }) => {
        const valuesWithNewScope = set(cloneDeep(values), name, { ...currentValue, ...newValues })

        if (updateValues) { updateValues({ newValues: valuesWithNewScope }) }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [currentValue, updateValues, name]),
      addScope = (): void => { updateScope({ newValues: defaultEntry }) },
      removeScope = (): void => { if (updateValues) { updateValues({ newValues: { ...values, [name]: undefined } }) } },
      scopedFormContext: FormContextType = React.useMemo(() => ({
        ...formContext,
        initialValues,
        values: currentValue,
        scope: formContext.scope ? `${formContext.scope}.${name}` : name,
        schema: schemaEntry.dynamicFormatByFieldValue && schemaEntry.dynamicFormatFieldName
          ? schemaEntry.dynamicFormatByFieldValue[currentValue[schemaEntry.dynamicFormatFieldName] as string] ?? childSchema
          : childSchema,
        errors: scopedErrors,
        updateValues: updateScope,
        formOptions: (get(formOptions, name) ?? formOptions) as FormOptions,
        scopeOptions
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }), [childSchema, currentValue, formContext, name, scopedErrors, updateScope])

    return (
      <ScopeRenderer
        name={name}
        addEntry={addScope}
        removeEntry={removeScope}
        scopeScope={scopedFormContext.scope}
        scopeOptions={scopeOptions}>
        <FormContext.Provider value={scopedFormContext}>
          <ScopeEntryRenderer formId={scopedFormContext.scope} formBody={formBody} isNested />
        </FormContext.Provider>
      </ScopeRenderer>
    )
  }

export default FormScope
