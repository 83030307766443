import { getSubjectObjects } from '~/features/experiment/fieldSelectors/treatmentSelectors'
import { getValueAcrossEntities } from '~/features/experiment/calculatedValues'
import { SystemFieldNames } from '~/features/experiment/systemFields'
import { getCoreSchedule } from './schedule'

import type { EntityObject } from '~/form-brain2'
import type { EmptyObject } from '~/types/utilities'
import type { SpecialListSectionElement, ListQuestionnaireDefault, AnyPlainOrInputElement } from '~/features/questionnaire/types'
import type { ListWorksheet } from '~/features/worksheet/types'
import type { ListEntry } from '~/features/worksheetResponse/types'

export const
  getSetUpStepForm = (section: SpecialListSectionElement): AnyPlainOrInputElement[] => ([
    {
      id: 'setup-1',
      elementVariety: 'SHORT_ANSWER',
      fieldName: 'description',
      label: section.whatLabel ?? 'What do you need to do?',
      requiredWhen: [{ conditionType: 'always' }]
    },
    {
      id: 'setup-2',
      elementVariety: 'LONG_ANSWER',
      label: section.detailsLabel ?? 'Notes:',
      fieldName: 'details'
    }
  ]),
  setUpAutomaticEntryKeyLabels = {
    'apply treatments': 'Apply Treatments',
    'watering in': 'Initial Watering In'
  },
  getSetUpDefault = (obj: ListQuestionnaireDefault, worksheet: ListWorksheet, otherEntities: EntityObject[]): Partial<ListEntry> | EmptyObject => {
    const
      { automaticEntryKey } = obj,
      coreSchedule = getCoreSchedule(otherEntities)

    // if (id === 'planting' && (typeof coreSchedule.planting !== 'number' || coreSchedule.planting === 1)) {
    //   return getPlantingSetUpStep(otherEntities)
    // }

    if (automaticEntryKey === 'watering in' && (typeof coreSchedule.planting !== 'number' || coreSchedule.planting === coreSchedule.setUp)) {
      return getWateringInSetUpStep(otherEntities)
    }

    if (automaticEntryKey === 'apply treatments') {
      return getTreatmentSetUpStep(otherEntities)
    }

    return {}
  }

const
  // getPlantingSetUpStep = (entities: EntityObject[]): Partial<ListEntry> | EmptyObject => {
  //   const
  //     plantsPerContainer = getCalculatedValue('calculatedPlantsPerContainer', entities),
  //     seedsPerPlant = getCalculatedValue('calculatedSeedsPerPlant', entities)

  //   if (isEmptyValue(plantsPerContainer) || isEmptyValue(seedsPerPlant)) { return {} }

  //   return {
  //     description: 'Plant seeds in each container',
  //     details: `According to your plan, you will grow ${plantsPerContainer as string} plant(s) in each container. Decide where you want each plant to grow and place ${seedsPerPlant as string} seeds there. Follow the instructions that came with the seeds for how deep to plant the seeds.`
  //   }
  // },
  getWateringInSetUpStep = (entities: EntityObject[]): Partial<ListEntry> | EmptyObject => {
    return {
      description: 'Water in all of your seeds so they get off to a good start'
    }
  },
  getTreatmentSetUpStep = (entities: EntityObject[]): Partial<ListEntry> | EmptyObject => {
    const
      blindness = getValueAcrossEntities(SystemFieldNames.blindnessMethod, entities) as string | undefined,
      subjects = getSubjectObjects(entities),
      description = 'Make a plan for when and how to apply each treatment',
      blindnessReminder = blindness
        ? `

As you plan, remember to take your blindness method (${blindness}) into account so that you do not know which subject got which treatment until the end.`
        : '',
      details = `Use the list below to match subjects to treatments. You may want to copy it and print it out for later.${blindnessReminder}

If your treatments are spread out over time, your schedule can tell you when to apply each one. If you will apply your treatments as part of set up, add an item to this list that describes what you will do.

${subjects.map(o => `${o.subjectName} - Treatment ${o.treatment}, Replicate ${o.replicateNumber.toString()}`).join('\n')}`

    return {
      description,
      details
    }
  }
