import * as React from 'react'
import styled from 'styled-components'
import { Navigate } from 'react-router-dom'
import { useAddEntity, useFormVitalsSubscription, useProjectFormBody } from '~/hooks'
import { Button, HeadingTwo } from '~/components/atoms'
import { FormRenderer } from '~/components/molecules'
import { experimentFormResolvers } from '~/features'
import { Form } from '~/form-brain2'

import type { Experiment } from '~/features'
import type {
  EntityObject,
  FormOnSubmitFn
} from '~/form-brain2'

const
  NewProjectFormOuter = styled.div`
    background: ${p => p.theme.colors.containerAccentBg};
    border-radius: ${p => p.theme.pxBorderRadius}px;
    padding: 1em;
    margin-bottom: ${p => p.theme.emBaseSpacing}em;
  `,
  FormActions = styled.div`
    display: flex;
    flex-direction: row;

    > :first-child {
      margin-right: auto;
    }
  `,
  getDefaultEntity = (): Partial<Experiment> => ({
    dynamicLabels: {}
  }),
  NewProjectForm: React.FC = () => {
    const
      { formStatus, subscribeToFormStatus } = useFormVitalsSubscription(),
      { addEntity } = useAddEntity(),
      onSubmitCallback: FormOnSubmitFn = ({ values }) => {
        return addEntity({ values, type: 'experiment' })
      },
      formName = 'new-project-form',
      { formBody, schema } = useProjectFormBody('new')

    return <NewProjectFormOuter>
      <HeadingTwo>Add a New Group</HeadingTwo>
      <Form
        formId={formName}
        formBody={formBody}
        entitySchema={schema}
        initialValues={getDefaultEntity() as EntityObject}
        onSubmitCallback={onSubmitCallback}
        FormRenderer={FormRenderer}
        subscribeToFormStatus={subscribeToFormStatus}
        formOptions={{ RootFormRenderer: FormRenderer }}
        {...experimentFormResolvers}
      />
      <FormActions>
        <Button linkProps={{ to: '/projects' }} label="Cancel" color="back" size="small" />
        {!formStatus || ['clean', 'cleanAfterSuccess'].includes(formStatus)
          ? null
          : <Button type="submit" isLoading={formStatus === 'processing'} form={formName} label="Continue" color="forward" size="small" />}
      </FormActions>
      {formStatus === 'success'
        ? <Navigate to="/projects" />
        : null}
    </NewProjectFormOuter>
  }

export default NewProjectForm
