import { get, replace } from 'lodash'
import { AttrValTypes } from '~/form-brain2'
import { isAllEmpty, isEmptyValue, isNumeric } from '~/utils/testers'
import { MISSING_INTERPOLATION_PLACEHOLDER } from '~/utils/strings'
import { MEASUREMENT_TIMING_SCHEMES, PLANTING_FORMATS, SystemFieldNames } from '~/features/experiment/systemFields'
import { getSubjectObjects } from '~/features/experiment/fieldSelectors/treatmentSelectors'
import { getCoreSchedule } from '~/features/experiment/specialSections/schedule'

import type { AttrVal, EntityObject } from '~/form-brain2'
import type { CalculatedValueName } from '~/types/utilities'
import type { MeasurementTimingScheme } from '~/features/experiment/systemFields'

export const
  interpolateString = (testString?: string, entities?: EntityObject[], permitMissingInterpolation?: boolean): string | undefined => {
    if (!testString?.includes('--')) {
      return testString
    }

    let missingValue = false

    const interpolatedString = replace(testString, /--(\w+)--/g, (m, valueName) => {
      let value = getValueAcrossEntities(valueName, entities)

      if (isEmptyValue(value)) {
        value = getCalculatedValue(valueName, entities)
      }

      if (isEmptyValue(value)) {
        missingValue = true
      }

      return typeof value === 'number' || typeof value === 'boolean'
        ? value.toString()
        : value ?? MISSING_INTERPOLATION_PLACEHOLDER
    })

    if (missingValue) {
      /* keep log */ console.log('Missing interpolation value', testString, interpolatedString)
    }

    return missingValue && !permitMissingInterpolation
      ? undefined
      : interpolatedString
  },
  getValueAcrossEntities = (fieldName?: string, entities?: EntityObject[], anyType?: boolean): AttrVal => {
    if (!fieldName || !entities || isEmptyValue(entities)) { return undefined }

    let value: AttrVal

    entities.find((entity) => {
      const val = get(entity, fieldName)

      if (
        isEmptyValue(val) ||
        (Array.isArray(val) && !anyType) ||
        (!AttrValTypes.includes(typeof val) && !anyType)
      ) {
        return false
      } else {
        value = val as AttrVal
        return true
      }
    })

    return value
  },
  getCalculatedValue = (valueName?: CalculatedValueName, entities?: EntityObject[]): string | undefined => {
    if (valueName === 'containerType') {
      const containerType = getValueAcrossEntities(SystemFieldNames.containerType, entities) as string | undefined

      return containerType ?? 'containers'
    }

    if (valueName === 'fertilizerDescription') {
      const name = getValueAcrossEntities(SystemFieldNames.fertilizerName, entities) as string ?? ''

      if (!name) { return undefined }

      const rateValues = [
        getValueAcrossEntities(SystemFieldNames.fertilizerRateValue, entities),
        getValueAcrossEntities(SystemFieldNames.fertilizerRateUnit, entities),
        getValueAcrossEntities(SystemFieldNames.fertilizerRateArea, entities)
      ]

      return isAllEmpty(rateValues)
        ? name
        : `${name} at ${rateValues.filter(o => o).join(' ')}`
    }

    if (valueName === 'measurementUnitDescription') {
      const
        measurementTarget = getValueAcrossEntities(SystemFieldNames.measurementTarget, entities),
        measurementType = getValueAcrossEntities(SystemFieldNames.measurementType, entities),
        measurementUnit = getValueAcrossEntities(SystemFieldNames.measurementUnit, entities),
        segments = [
          measurementType,
          measurementTarget ? `of ${measurementTarget}` : undefined,
          measurementUnit ? `in ${measurementUnit}` : undefined
        ].filter(s => !isEmptyValue(s))

      return measurementType && segments.length
        ? segments.join(' ')
        : undefined
    }

    if (valueName === 'dependentVariableResultDescription') {
      const
        dependentVariable = getValueAcrossEntities(SystemFieldNames.dependentVariable, entities),
        measurementRelationship = getValueAcrossEntities(SystemFieldNames.measurementRelationship, entities),
        description = [
          measurementRelationship === 'total' ? 'total' : measurementRelationship === 'repeat' ? 'average' : null,
          dependentVariable,
          measurementRelationship === 'rate' ? 'rate' : null
        ].filter(o => o)

      return isEmptyValue(description)
        ? 'the dependent variable'
        : description.join(' ')
    }

    if (valueName === 'calculatedTotalNumberOfMeasurements') {
      if (!entities) { return undefined }

      const
        schedule = getCoreSchedule(entities),
        { measurementStart, measurementRepeat, measurementEnd, lastDay } = schedule,
        measTimingScheme = getValueAcrossEntities(SystemFieldNames.measurementTimingScheme, entities, true) as MeasurementTimingScheme | undefined,
        endDays: number[] = [measurementEnd, lastDay].filter((n): n is number => isNumeric(n)),
        functionalEndDay = Math.min(...endDays),
        isCalculable = isNumeric(measurementStart) && isNumeric(measurementRepeat) && isNumeric(functionalEndDay)

      if (measTimingScheme === MEASUREMENT_TIMING_SCHEMES.ONCE) { return '1' }

      if (!isCalculable) { return undefined }

      const
        measurementWindow = functionalEndDay - measurementStart,
        rawRepeat = Math.floor(measurementWindow / measurementRepeat),
        countInclusive = rawRepeat + 1

      return countInclusive < 1
        ? '0'
        : countInclusive.toFixed()
    }

    if (valueName === 'sampleSize') {
      if (!entities) { return undefined }

      const subjects = getSubjectObjects(entities)

      return subjects
        ? subjects.length.toString()
        : undefined
    }

    if (valueName === 'plantsPerSubjectGroup') {
      if (!entities) { return undefined }

      const
        format = getValueAcrossEntities(SystemFieldNames.plantingFormat, entities) as string | undefined,
        groupFormats: Array<string | undefined> = [PLANTING_FORMATS.GROUP_CONTAINER, PLANTING_FORMATS.GROUP_GROUND],
        plantSpacingUnit = getValueAcrossEntities(SystemFieldNames.plantSpacingUnit, entities) as string ?? '',
        plantSpacing = getValueAcrossEntities(SystemFieldNames.plantSpacingValue, entities) as string | undefined,
        numPlantSpacing = plantSpacing && isNumeric(plantSpacing) ? parseInt(plantSpacing) : undefined

      return groupFormats.includes(format) && plantSpacingUnit.includes('plant')
        ? (numPlantSpacing ?? 1).toString()
        : undefined
    }

    if (valueName === 'subjectGroupName') { // singular
      if (!entities) { return undefined }

      const
        format = getValueAcrossEntities(SystemFieldNames.plantingFormat, entities) as string | undefined,
        groupFormats: Array<string | undefined> = [PLANTING_FORMATS.GROUP_CONTAINER, PLANTING_FORMATS.GROUP_GROUND]

      if (!groupFormats.includes(format)) { return undefined }

      const plantSpacingArea = getValueAcrossEntities(SystemFieldNames.plantSpacingArea, entities) as string | undefined

      return plantSpacingArea ?? 'group' // was 'subject'
    }

    if (valueName === 'calculatedSeedUnit') {
      if (!entities) { return undefined }

      const
        format = getValueAcrossEntities(SystemFieldNames.plantingFormat, entities) as string | undefined,
        groupFormats: Array<string | undefined> = [PLANTING_FORMATS.GROUP_CONTAINER, PLANTING_FORMATS.GROUP_GROUND],
        plantSpacingUnit = getValueAcrossEntities(SystemFieldNames.plantSpacingUnit, entities) as string | undefined

      return groupFormats.includes(format) && (plantSpacingUnit ?? '').includes('seed')
        ? plantSpacingUnit
        : undefined
    }

    if (valueName === 'calculatedSeedsPerPlant') {
      const
        seedsPerPlant = getValueAcrossEntities(SystemFieldNames.seedsPerPlant, entities),
        numSeedsPerPlant = isNumeric(seedsPerPlant) ? parseInt(seedsPerPlant as string) : undefined

      return (numSeedsPerPlant ?? 1).toString()
    }

    if (valueName === 'seedsPerGroup') {
      if (!entities) { return undefined }

      const
        format = getValueAcrossEntities(SystemFieldNames.plantingFormat, entities) as string | undefined,
        groupFormats: Array<string | undefined> = [PLANTING_FORMATS.GROUP_CONTAINER, PLANTING_FORMATS.GROUP_GROUND],
        plantSpacingUnit = getValueAcrossEntities(SystemFieldNames.plantSpacingUnit, entities) as string | undefined,
        plantSpacing = getValueAcrossEntities(SystemFieldNames.plantSpacingValue, entities) as string | undefined,
        numPlantSpacing = plantSpacing && isNumeric(plantSpacing) ? parseFloat(plantSpacing) : undefined

      return groupFormats.includes(format) && (plantSpacingUnit ?? '').includes('seed')
        ? (numPlantSpacing ?? 1).toString()
        : undefined
    }

    if (valueName === 'calculatedSeedsNeeded') {
      const
        format = getValueAcrossEntities(SystemFieldNames.plantingFormat, entities) as string | undefined,
        groupFormats: Array<string | undefined> = [PLANTING_FORMATS.GROUP_CONTAINER, PLANTING_FORMATS.GROUP_GROUND],
        sampleSize = getCalculatedValue('sampleSize', entities),
        numSampleSize = isNumeric(sampleSize) ? parseInt(sampleSize) : undefined,
        seedsPerPlant = getCalculatedValue('calculatedSeedsPerPlant', entities),
        numSeedsPerPlant = isNumeric(seedsPerPlant) ? parseInt(seedsPerPlant) : undefined

      if (!numSampleSize) { return undefined }

      if (!groupFormats.includes(format)) {
        return numSeedsPerPlant
          ? (numSampleSize * numSeedsPerPlant).toString()
          : undefined
      }

      const
        plantsPerGroup = getCalculatedValue('plantsPerSubjectGroup', entities),
        numPlantsPerGroup = plantsPerGroup && isNumeric(plantsPerGroup)
          ? parseInt(plantsPerGroup)
          : undefined,
        seedsPerGroup = getCalculatedValue('seedsPerGroup', entities),
        numSeedsPerGroup = seedsPerGroup && isNumeric(seedsPerGroup)
          ? parseFloat(seedsPerGroup)
          : undefined

      return numPlantsPerGroup
        ? (numSampleSize * (numSeedsPerPlant ?? 1) * numPlantsPerGroup).toString()
        : numSeedsPerGroup
          ? (numSampleSize * numSeedsPerGroup).toString()
          : undefined
    }

    return undefined
  }
