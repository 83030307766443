import { omit } from 'lodash'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { userLoggedOut, learnerLoggedOut } from '../session/sessionSlice'

import type { EntityType, ObjectId } from '~/types/utilities'
import type { Experiment } from '../experiment/types'
import type { Learner } from '../learner/types'
import type { Template, TemplateSet } from '../template/types'
import type { AnyWorksheet } from '../worksheet/types'
import type { WorksheetResponse } from '../worksheetResponse/types'
import type { User } from '../session/types'
import type { APIEntity } from 'cypress/support/types'

interface EntityState {
  updatedAt?: number
  user: Record<ObjectId, User>
  templateSet: Record<ObjectId, TemplateSet>
  template: Record<ObjectId, Template>
  experiment: Record<ObjectId, Experiment>
  learner: Record<ObjectId, Learner>
  worksheet: Record<ObjectId, AnyWorksheet>
  worksheetResponse: Record<ObjectId, WorksheetResponse>
}

export type AnyEntity = User | TemplateSet | Template | Experiment | Learner | AnyWorksheet | WorksheetResponse

const
  initialState: EntityState = {
    updatedAt: undefined,
    user: {},
    templateSet: {},
    template: {},
    experiment: {},
    learner: {},
    worksheet: {},
    worksheetResponse: {}
  },
  entitySlice = createSlice({
    name: 'entities',
    initialState,
    reducers: {
      entitiesReceived (state, action: PayloadAction<Array<APIEntity<AnyEntity>>>) {
        action.payload.forEach(o => {
          state[o.type as EntityType][o.id] = o.attributes
        })

        state.updatedAt = new Date().valueOf()
      },
      entitiesRemoved (state, action: PayloadAction<Array<{ id: ObjectId, type: EntityType }>>) {
        action.payload.forEach(o => {
          state[o.type] = omit(state[o.type], o.id)
        })

        state.updatedAt = new Date().valueOf()
      }
    },
    extraReducers: (builder) => {
      builder.addCase(userLoggedOut, (state) => {
        return initialState
      })

      builder.addCase(learnerLoggedOut, (state) => {
        return initialState
      })
    }
  })

export type { EntityType }
export const { entitiesReceived, entitiesRemoved } = entitySlice.actions
export default entitySlice.reducer
