import * as React from 'react'
import styled from 'styled-components'
import { isEmpty, values } from 'lodash'
import { isAllEmpty } from '~/utils/testers'
import { unexpectedErrorMessage } from '~/utils/errorMessages'
import { DOCUMENT_TITLES, HEADER_TEXTS } from '~/utils/strings'
import {
  useAppSelector,
  useEntityData,
  useSession,
  useUpdateEntity
} from '~/hooks'
import { ExperimentHelpers, WorksheetHelpers } from '~/features'
import { ErrorPage, Ul, Li, LeadIn, ContactUsMessage } from '~/components/pages/NotFoundPage'
import { BrandedPage, FlexibleMain } from '~/components/layouts'
import {
  AlertText,
  HeadingOne,
  SubheadingOne,
  Button,
  Icon,
  BasicCheckmark,
  ErrorTextOnly,
  SizedLoadingIcon
} from '~/components/atoms'
import { InfoPanel, BotanicalBackground, PageHint, Blurb, ActionPointer } from '~/components/molecules'
import ProjectHome from '~/components/organisms/ProjectHome'

import { selectExperimentById, selectLearnerById, selectWorksheets } from '~/features/entity/selectors'

const
  StyledBackground = styled(BotanicalBackground)`
    position: absolute;
    width: 100%;
  `,
  P = styled.p`
    margin-bottom: 2em;
  `,
  pxMascotHeight = 170,
  NaturalMascot = styled(PageHint)`
    position: static;
    margin-auto;
    height: ${pxMascotHeight}px;
    width: 90%;
    min-width: 290px;
  `,
  FloatingPointer = styled(ActionPointer)`
    position: relative;
    left: -33%;
    bottom: -${pxMascotHeight * 0.25}px;
    transform: rotate(12deg);
  `,
  WelcomeWrapper = styled.div`
    padding-top: 1.5em;
    width: 100%;
    max-width: ${p => p.theme.emMediumContentWidth * 1.1}em;
    margin: auto;
  `,
  StyledAlertText = styled(AlertText)`
    position: relative;
    z-index: ${p => p.theme.zLocalTop};
    width: 90%;
    margin: auto;
  `,
  LetsGoButton = styled(Button)`
    position: relative;
    font-size: 1.4rem;
    display: block;
    margin: 0 auto 2em;
    z-index: ${p => p.theme.zLocalTop};
  `,
  TryAgainBlurb = styled(Blurb)`
    position: relative;
    width: 90%;
    margin: auto;
    margin-bottom: ${p => p.theme.emBaseSpacing * 3}em;
    z-index: ${p => p.theme.zLocalTop};
  `,
  Error = styled(ErrorTextOnly)`
    position: relative;
    margin: auto;
    text-align: center;
    width: 90%;
    z-index: ${p => p.theme.zLocalTop};
    margin-bottom: ${p => p.theme.emBaseSpacing}em;
  `,
  StyledInfoPanel = styled(InfoPanel)`
    &:not(:first-child) {
      margin-top: ${p => p.theme.emSmallSpacing}em;
    }
  `,
  { getDynamicString } = ExperimentHelpers,
  defaultInfoPanelText: string[] = ['Choose any open step from the scientific method cycle to continue working on or review your project!'],
  noStepsInfoPanelText: string[] = ['You will be able to start your project when your teacher opens the first step. Check back soon!', 'If this doesn\'t seem right, check with your teacher.'],
  LearnerProjectPage: React.FC = () => {
    const
      [showWaitForTeacherMessage, setShowWaitForTeacherMessage] = React.useState(false),
      { dataStatus, isLoading } = useEntityData(),
      { learnerId, hasSession, isLearner } = useSession(),
      { updateEntity, isUpdating } = useUpdateEntity(),

      [startLearnerError, setStartLearnerError] = React.useState<string | void>(),

      learner = useAppSelector(selectLearnerById(learnerId)),
      experiment = useAppSelector(selectExperimentById(learner?.experimentId)),
      worksheetsById = useAppSelector(selectWorksheets),
      worksheets = values(worksheetsById).filter(o => o.experimentId === experiment?.id),

      firstWorksheet = WorksheetHelpers.getSortedTopLevelWorksheets(worksheets)[0],
      [infoPanelContent, setInfoPanelContent] = React.useState<{ title?: string, body?: string[], showWarning?: boolean }>({}),

      startLearner = React.useCallback(() => {
        if (!learner) { return }
        setStartLearnerError()

        updateEntity({
          entity: learner,
          newValues: { startedAt: new Date().toISOString() }
        }).catch(() => { setStartLearnerError(unexpectedErrorMessage) })
      }, [updateEntity, learner, setStartLearnerError]),

      learnerName = learner?.displayId,
      learnerStarted = learner?.startedAt,
      experimentName = experiment?.name

    if (dataStatus === 'initializing' || dataStatus === 'uninitialized') {
      return <BrandedPage documentTitle={DOCUMENT_TITLES.loading} whichHome="teaching" headerText={HEADER_TEXTS.teaching} showLoader />
    }

    if (!experiment || !learner) {
      return <ErrorPage errorTitle="Project Not Found" bareMessage={hasSession && !isLearner} message={hasSession && !isLearner ? 'You are not logged in as a learner' : 'Project or Account Not Found'}>
        <LeadIn>What next?</LeadIn>
        <Ul>
          <Li>If you got here from a bookmark or by typing the web address, try using your access link instead</Li>
          <Li>{'If that does not work, contact your teacher for an up-to-date access link'}</Li>
          <Li>The project you are looking for may be over or may have been deleted</Li>
          <Li><ContactUsMessage question="Still having issues?" /></Li>
        </Ul>
      </ErrorPage>
    }

    const mainProps = (isEmpty(worksheets) || !experiment.startedAt || !learnerStarted)
      ? {
          children: <span style={{ overflow: 'hidden', position: 'relative' }}>
            <StyledBackground orientation="column" opacity={0.75} />
            <WelcomeWrapper>
              <StyledAlertText color="accent">
                <P>Welcome to the {getDynamicString(experiment, 'learnerProjectHome')}!<br />This is your home page on AgConnect.</P>
                <FloatingPointer label="Try clicking on the pig" orientation="right" />
                <NaturalMascot
                  content={`Hi! My name is ${getDynamicString(experiment, 'mascotName')} and I will be coming along on your journey. Whenever you see me, you can click on me for a helpful hint.`}
                  mascotVariant='pointing'
                  // openByDefault
                />
                <P>{`You can return to the ${getDynamicString(experiment, 'learnerProjectHome')} any time by clicking the ${getDynamicString(experiment, 'brandIconDescriptor')} next to the word "AgConnect" at the top of the page.`}</P>
              </StyledAlertText>
              {experiment.startedAt && !isEmpty(worksheets)
                ? <>
                  {startLearnerError ? <Error>{startLearnerError}</Error> : null}
                  <LetsGoButton
                    isLoading={isUpdating}
                    size="medium"
                    color="back"
                    label="Let's Go!"
                    onClick={startLearner}
                  />
                </>
                : <TryAgainBlurb
                  withBackground
                  icon={<Icon color="forward" content={BasicCheckmark} />}
                  heading="That's all for now!"
                  content="There will be more to do here when your project starts, so come back soon."
                />}
            </WelcomeWrapper>
          </span>
        }
      : {
          primaryContent: (
            isLoading
              ? <SizedLoadingIcon />
              : <ProjectHome
                learner={learner}
                experiment={experiment}
                setInfoPanelContent={setInfoPanelContent}
                setShowWaitForTeacherMessage={setShowWaitForTeacherMessage}
              />
          ),
          secondaryContent: (
            <>
              <StyledInfoPanel heading={infoPanelContent.title ?? 'Additional Information'}>
                {isLoading
                  ? <SizedLoadingIcon />
                  : (
                      infoPanelContent.body && !isAllEmpty(infoPanelContent.body)
                        ? infoPanelContent.body
                        : firstWorksheet?.releasedAt
                          ? defaultInfoPanelText
                          : noStepsInfoPanelText
                    ).map((s, i) => {
                      return [
                        ...(i !== 0 ? [<br key={`break-${i}`} />] : []),
                      <div key={`content-${i}`}>{s}</div>
                      ]
                    })
                }
              </StyledInfoPanel>
              {infoPanelContent.showWarning
                ? <StyledInfoPanel><b>You will be working on this step as a group.</b> Only the most recent answer for each question is saved. If two people are working at the same time on different devices, you might write over each other&apos;s work.</StyledInfoPanel>
                : null}
            </>
          ),
          absoluteContent: showWaitForTeacherMessage
            ? <PageHint
              content="Nice job! You'll be able to move on when the next step is available."
              mascotVariant="pointing"
              openByDefault />
            : null
        }

    return (
      <BrandedPage
        documentTitle={`${experimentName as string} | AgConnect`}
        whichHome="teaching"
        headerText={['AgConnect', experimentName as string]}>
        <FlexibleMain
          heading={<HeadingOne>{getDynamicString(experiment, 'learnerProjectHome')}</HeadingOne>}
          subheading={<SubheadingOne>{`${learnerName as string}'s`}</SubheadingOne>}
          {...mainProps}
        />
      </BrandedPage>
    )
  }

export default LearnerProjectPage
