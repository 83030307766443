import { upperFirst } from 'lodash'
import { formatDate, parseDate, symbolSafeStartCase } from '~/utils/formatters'
import { getScheduleTimingString } from '~/features/experiment/specialSections'
import { getSubjectObjects } from '~/features/experiment/fieldSelectors/treatmentSelectors'
import { getValueAcrossEntities, interpolateString } from '~/features/experiment/calculatedValues'
import { SystemFieldNames, type ClosingSectionElement, type Experiment, type ListEntry, type ListSectionElement, type OpeningSectionElement, type RepeatingSectionElement, type SpecialListSectionElement, type SpecialSectionId } from '~/features'
import type { EntityObject } from '~/form-brain2'
import type { FieldName } from '~/types/utilities'

const
  namingKeysBySpecialSectionId: Record<SpecialSectionId, FieldName> = {
    PLAN_ACTIVITY: 'description',
    PLAN_MEASUREMENT: 'description',
    MATERIAL: 'description',
    SET_UP_STEP: 'description',
    OBSERVATION_RECORD: 'details',
    ACTIVITY_RECORD: 'description',
    // DATA_VISUALIZATION: 'visualization_type',
    STATS_TEST: '',
    MEASUREMENT_RECORD: '',
    SUMMARY_STATS: ''
  },
  getHeadingForEntry = ({ nthOfKind, section, entry, otherEntities, experiment }: { section?: ListSectionElement, nthOfKind?: number, entry?: ListEntry, otherEntities?: EntityObject[], experiment: Experiment }): string => {
    if (section && (section as SpecialListSectionElement).specialSectionId) {
      const specialSectionId = (section as SpecialListSectionElement).specialSectionId

      if (specialSectionId === 'OBSERVATION_RECORD') {
        if (!entry) { return upperFirst((section as RepeatingSectionElement).entryNameSingular) }

        const
          date = parseDate(entry?.date as string | undefined),
          description = entry?.details

        return `${formatDate(date, 'LLL. dd')} - ${description as string}`
      }

      if (specialSectionId === 'ACTIVITY_RECORD') {
        if (!entry) { return upperFirst((section as RepeatingSectionElement).entryNameSingular) }

        const
          date = parseDate(entry?.date as string | undefined),
          description = entry?.description

        return `${formatDate(date, 'LLL. dd')} - ${description as string}`
      }

      if (specialSectionId === 'MEASUREMENT_RECORD') {
        if (!otherEntities || !entry) { return upperFirst((section as RepeatingSectionElement).entryNameSingular) }

        const
          specialSection = section as SpecialListSectionElement,
          date = parseDate(entry?.date as string | undefined),
          unit = getValueAcrossEntities(SystemFieldNames.measurementUnit, otherEntities),
          value = entry?.measurementValue,
          subject = entry?.measurementSubject,
          includedSegments = specialSection.measurementEntryTitleSegments?.length
            ? specialSection.measurementEntryTitleSegments
            : ['date', 'subject', 'treatment', 'measurement'],
          subjectList = getSubjectObjects(otherEntities, experiment),
          subjectObject = subject
            ? subjectList.find(o => o.subjectId.toString() === subject)
            : undefined,
          subjectName = subjectObject?.subjectName,
          treatmentDescription = subjectObject?.treatmentDescription,
          fullDescription = subjectObject?.label,
          parts = [
            entry?.date && includedSegments.includes('date') ? formatDate(date, 'LLL. dd') : undefined,
            (includedSegments.includes('subject') && includedSegments.includes('treatment')
              ? fullDescription
              : includedSegments.includes('subject')
                ? subjectName
                : includedSegments.includes('treatment')
                  ? treatmentDescription
                  : undefined),
            value && includedSegments.includes('measurement') ? `${value as number} ${(unit ?? '').toString()}` : undefined
          ]

        return parts.filter(o => o).join(' - ')
      }

      if (specialSectionId === 'SUMMARY_STATS') {
        return symbolSafeStartCase(interpolateString('Summary Statistics: --dependentVariable--', otherEntities) ?? 'Summary Statistics')
      }

      if (specialSectionId === 'STATS_TEST') {
        if (entry?.testType === 't-test') {
          return `Student's T-Test: ${entry.groupA as string} and ${entry.groupB as string}`
        }

        if (entry?.testType === 'ANOVA') {
          return 'Analysis of Variance (ANOVA)'
        }

        return 'Statistical Test'
      }

      const
        namingKey = namingKeysBySpecialSectionId[specialSectionId],
        heading = entry?.[namingKey] as string | undefined

      if (heading) { return heading }

      if ((!entry || !heading) && section.elementVariety === 'LIST_ENTRY_SECTION') { return upperFirst((section as RepeatingSectionElement).entryNameSingular) }

      return 'Entry'
    }

    if (section?.elementVariety === 'LIST_OPENER_SECTION' || section?.elementVariety === 'LIST_CLOSER_SECTION') {
      return (section as OpeningSectionElement | ClosingSectionElement).title
    }

    const
      namingKey = section
        ? (section as RepeatingSectionElement).entryLabelField
        : undefined,
      entryLabel = namingKey
        ? entry?.[namingKey]
        : undefined

    return entryLabel
      ? upperFirst(entryLabel as string)
      : upperFirst(`${(section as RepeatingSectionElement | undefined)?.entryNameSingular ?? 'Entry'} ${(nthOfKind as number + 1).toString()}`)
  },
  getSubheadingForEntry = ({ nthOfKind, section, entry }: { section?: ListSectionElement, nthOfKind?: number, entry?: ListEntry }): string | undefined => {
    if (entry && (((section as SpecialListSectionElement).specialSectionId === 'PLAN_ACTIVITY') || (section as SpecialListSectionElement).specialSectionId === 'PLAN_MEASUREMENT')) {
      return getScheduleTimingString(entry)
    }
  }

export { getSubheadingForEntry }
export default getHeadingForEntry
