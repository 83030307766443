import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Blindness options',
  description = 'If any blindness method is selected here, the generated subject list will not include the treatment names explicitly. Instead, a code letter will be shown (and a key provided for which letter corresponds with which treatment).',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.blindnessMethod,
    fieldType: 'STRING',
    options: [
      { optionValue: 'Hide the treatment labels' },
      { optionValue: 'Have someone else apply the treatments' }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.blindnessMethod,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
