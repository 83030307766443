import type { AttrVal } from '~/form-brain2'
import type { ValueName } from '~/types/utilities'

interface IndependentValueCondition {
  conditionType: typeof IndependentConditions[number]
  conditionScope?: typeof ConditionScopes[number]
  valueName?: ValueName
}

interface ExplicitValueCondition {
  conditionType: typeof ComparativeConditions[number]
  conditionScope?: typeof ConditionScopes[number]
  valueName?: ValueName
  testValue: AttrVal | AttrVal[]
}
interface ReferencedValueCondition {
  conditionType: typeof ComparativeConditions[number]
  conditionScope?: typeof ConditionScopes[number]
  valueName?: ValueName
  testValueName: ValueName
}

export type VisibilityCondition = (
  IndependentValueCondition |
  ExplicitValueCondition |
  ReferencedValueCondition
)

export type ValidationCondition = VisibilityCondition // Will never have valueName?

export type ConditionType = typeof ConditionTypes[number]
export const
  /** Condition scopes:
   *  - 'all' is default behavior, search all provided entites
   *  - 'rootScope' search only the form's own entity
   *  - 'ownScope' search only within the scope of the caller's parent (the object in which caller is a key) */
  ConditionScopes = ['all', 'rootScope', 'ownScope'] as const,
  IndependentConditions = ['always', 'never', 'truthy', 'falsy', 'present', 'absent', 'integer', 'notInteger', '%', 'year'] as const,

  TextToTextConditions = ['matches', 'notMatches', 'equals', 'notEquals'] as const,
  TextToIntConditions = ['wordLengthAtLeast', 'wordLengthLessThan', 'characterLengthAtLeast', 'characterLengthLessThan'] as const,
  TextToArrayConditions = ['anyOf', 'noneOf'] as const,
  TextConditions = [...TextToTextConditions, ...TextToIntConditions, ...TextToArrayConditions] as const,

  NumberConditions = ['equalsNumber', 'notEqualsNumber', 'lt', 'gt', 'lto', 'gto'] as const,

  DateConditions = ['sameMonthAs', 'sameDateAs', 'before', 'after'] as const,

  ArrayToSingletonConditions = ['includes', 'notIncludes'] as const,
  ArrayToIntConditions = ['lengthAtLeast', 'lengthLessThan'] as const,
  ArrayConditions = [...ArrayToSingletonConditions, ...ArrayToIntConditions] as const,

  ComparativeConditions = [...TextConditions, ...NumberConditions, ...DateConditions, ...ArrayConditions] as const,
  ConditionTypes = [...IndependentConditions, ...ComparativeConditions] as const
