import { SystemFieldNames, SystemFieldGroups, getTreatmentGroupsTitle, getTreatmentGroupsDescription, INDEPENDENT_VARIABLES } from './systemFieldConstants'
import { fieldDetails as fertilizerRateControlGroup } from './fertilizerRateControlGroup'

import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  independentVariable = INDEPENDENT_VARIABLES.FERTILIZER_RATE,
  schemaEntry: QField = {
    fieldName: SystemFieldNames.fertilizerRateTreatmentGroups,
    fieldType: 'COLLECTION',
    visibleWhen: fertilizerRateControlGroup.schemaEntry.visibleWhen,
    format: fertilizerRateControlGroup.schemaEntry.format
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.fertilizerRateTreatmentGroups,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    isRequired: true,
    title: getTreatmentGroupsTitle(independentVariable),
    description: getTreatmentGroupsDescription(independentVariable),
    treatmentGroupsFor: independentVariable,
    schemaEntry
  }

export {
  fieldDetails
}
