import React from 'react'

const useClickOnKeyPress = <T extends HTMLElement = HTMLDivElement>(otherRef?: React.MutableRefObject<T | null>): React.MutableRefObject<T | null> => {
  const elementRef = React.useRef<T | null>(null)

  React.useEffect(() => {
    const
      handleKeyPress = (e: KeyboardEvent): void => {
        // console.log('You rang?', document.activeElement)
        if (elementRef.current?.contains(document.activeElement)) {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()
            e.stopPropagation()
            elementRef.current.click()
          }
        }
      }

    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [elementRef])

  return otherRef
    ? ((el: T) => { otherRef.current = el; elementRef.current = el }) as unknown as React.MutableRefObject<T | null>
    : elementRef
}

export default useClickOnKeyPress
