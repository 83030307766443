import * as React from 'react'
import { camelCase, last, upperFirst } from 'lodash'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { addEmphasisToString, ExperimentHelpers, interpolateString, WorksheetHelpers, WorksheetResponseHelpers } from '~/features'
import { useFormVitalsSubscription, useLearnerStepContext, useUpdateEntity } from '~/hooks'
import { getSelectionBreakdown, getStartingSectionId } from './helpers'
import { Button, FauxSelect } from '~/components/atoms'
import { BlurbBuilder, CardHeaderHeading } from '~/components/molecules'
import IndependentCheckpoint from '~/components/organisms/ListStep/IndependentCheckpoint'
import SummaryStatsCard from '~/components/organisms/SpecialContentArea/SummaryStatsCard'
import StatsTestCard from '~/components/organisms/SpecialContentArea/StatsTestCard'

import type { ListEntrySummary, ListStepSelection } from './types'
import type { SpecialListSectionElement, ListEntry, ListWorksheet, ListResponse, WalkthroughStep } from '~/features'
import type { FormProps } from '~/form-brain2'
import { isEmptyValue } from '~/form-brain2/testers'
import ListEntryFormContent from './ListEntryFormContent'

interface Props {
  entrySummary: ListEntrySummary
  setCurrentSelection: React.Dispatch<React.SetStateAction<ListStepSelection>> | ((el: ListStepSelection) => void)
  withInlineForm?: boolean
  currentSelection?: ListStepSelection
}

type ListEntrySubmissionSource = 'SAVE' | 'DELETE'

const
  { getDynamicString } = ExperimentHelpers,
  WideButton = styled(Button)`
    min-width: 110px;
  `,
  WalkthroughWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
  `,
  noop: React.MouseEventHandler = (e) => { if (e) { e.preventDefault(); e.stopPropagation() } },
  getIndexForEntryUpdate = (currentSelection: ListStepSelection, worksheet: ListWorksheet, entries: ListEntry[]): number => {
    const { selectedIndex, isAddingOpener, isAddingCloser, isAddingRepeater } = getSelectionBreakdown(currentSelection)

    if (isAddingOpener) {
      return 0
    }

    if (isAddingCloser) {
      return entries.length
    }

    if (isAddingRepeater) {
      return last(entries)?.sectionId === worksheet.closingSection?.id ? entries.length - 1 : entries.length
    }

    return selectedIndex as number
  },
  ListEntryForm: React.FC<Props> = ({ entrySummary, currentSelection, withInlineForm, setCurrentSelection }) => {
    const
      navigate = useNavigate(),
      { updateEntity, isUpdating } = useUpdateEntity(),
      { responses, response, experiment, ...otherStepContext } = useLearnerStepContext(),
      { isProcessing, formSubmissionSource, subscribeToFormStatus, formMethodsRef, formStatus } = useFormVitalsSubscription(),
      entries = (response.responses?.entries ?? []) as ListEntry[],
      worksheet = otherStepContext.worksheet as ListWorksheet,
      sectionsById = WorksheetHelpers.getSectionsById(worksheet),
      { wtStep, wtSection, wtStop } = WorksheetResponseHelpers.getActiveWalkthroughDetails(worksheet, response),
      showIntroCheckpointOnly = wtStep?.intro && wtStep.intro.elementVariety === 'PAGE' && !response.walkthroughProgress?.[camelCase(wtStep.sectionId)],
      [sectionId, setSectionId] = React.useState<string | undefined>(
        wtSection
          ? wtSection.id
          : getStartingSectionId({ entrySummary, sectionsById, entries })
      ),
      section = sectionId ? sectionsById[sectionId] : undefined,
      otherEntities = WorksheetResponseHelpers.getEntitiesAcrossResponses(responses),
      { entry, actualIndex } = entrySummary,
      [formId, setFormId] = React.useState<string>(`${worksheet.id}-${actualIndex ?? 'new'}-${sectionId ?? 'repeatingElement'}`),
      isListReversed = !withInlineForm && worksheet.reverseStartAndEnd,

      // See useEffect below
      newStartingSectionId = getStartingSectionId({ entrySummary, sectionsById, entries }),

      // Callbacks and elements
      onCancel = (): void => { setCurrentSelection(undefined) },
      onSubmitCallback: FormProps['onSubmitCallback'] = ({ values }) => {
        let newValues = WorksheetResponseHelpers.addEntryToListResponse(
          response,
          values as ListEntry,
          !entrySummary.entry,
          getIndexForEntryUpdate(currentSelection, worksheet, entries)
        )

        if (wtStep) {
          newValues = WorksheetResponseHelpers.updateWalkthroughProgress({
            currentStop: wtStop,
            response: newValues as ListResponse,
            walkthroughSectionId: wtStep.sectionId,
            walkthrough: worksheet.walkthrough as WalkthroughStep[]
          })
        }

        return updateEntity({ newValues, entity: response })
      },
      onDeleteCallback = (): void => {
        const newValues = WorksheetResponseHelpers.removeEntryFromListResponse(
          response,
          entrySummary.actualIndex as number
        )

        updateEntity({ newValues, entity: response })
          .then(() => { onCancel() })
          .catch((e) => { /* keep log */ console.log(e) })
      },
      submitFromSource = (source: ListEntrySubmissionSource): React.MouseEventHandler => {
        const handler: React.MouseEventHandler = (e) => {
          if (formMethodsRef.current?.triggerFormSubmission) {
            formMethodsRef.current.triggerFormSubmission(e, source)
          } else {
            noop(e)
          }
        }

        return handler
      },
      onSuccessCallback: FormProps['onSuccessCallback'] = () => {
        if (section?.elementVariety === 'LIST_CLOSER_SECTION') {
          navigate(`/project-step/${worksheet.id}/review`)
        } else {
          onCancel()
        }
      },
      cancelButton = entries.length && !wtStep
        ? <WideButton
            onClick={onCancel}
            isDisabled={isProcessing}
            color="caution"
            size="small"
            label={getDynamicString(experiment, 'listItemCancel')}
          />
        : null,
      saveButton = !!entry || (formStatus !== 'clean' && formStatus !== 'cleanAfterSuccess')
        ? <WideButton
            color="forward"
            withIcon="checkmark"
            size="small"
            onClick={submitFromSource('SAVE')}
            isLoading={isProcessing && formSubmissionSource === 'SAVE'}
            label={formId.includes('preview')
              ? getDynamicString(experiment, '_addExampleData')
              : section?.elementVariety === 'LIST_CLOSER_SECTION'
                ? getDynamicString(experiment, 'listItemSaveAndReview')
                : getDynamicString(experiment, 'listItemSave')}
          />
        : null,
      headerPicker = entrySummary.availableSections && entrySummary.availableSections.length > 1 && !entry
        ? <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
            <CardHeaderHeading>Add a new </CardHeaderHeading>
            <FauxSelect
              name="sectionSelect"
              selected={sectionId}
              onSelect={id => { setSectionId(id) }}
              options={entrySummary.availableSections.map(section => {
                return { value: section.id, label: upperFirst(section.entryNameSingular) }
              })}
              defaultOpen={!sectionId}
            />
          </div>
        : undefined,
      introElement = !wtStep?.intro
        ? undefined
        : wtStep.intro.elementVariety === 'BLURB'
          ? <BlurbBuilder
              {...wtStep.intro}
              {...(withInlineForm ? {} : { withBackground: true, withBottomDivider: true })}
              naturalWidth
              paragraphs={wtStep.intro.paragraphs.map(str => addEmphasisToString(interpolateString(str, otherEntities)) ?? [str])}
            />
          : wtStep.intro.elementVariety === 'PAGE' && wtStop === 'intro'
            ? <IndependentCheckpoint
                checkpoint={wtStep.intro}
                entities={otherEntities}
                response={response as ListResponse}
                walkthroughSectionId={wtStep.sectionId}
                currentStop={wtStop}
              />
            : undefined,
      outroElement = !wtStep?.outro
        ? undefined
        : wtStep.outro.elementVariety === 'BLURB'
          ? <BlurbBuilder
              {...wtStep.outro}
              {...(withInlineForm ? {} : { withBackground: true })}
              naturalWidth
              paragraphs={wtStep.outro.paragraphs.map(str => addEmphasisToString(interpolateString(str, otherEntities)) ?? [str])}
            />
          : wtStep.outro.elementVariety === 'PAGE' && (wtStop === 'outro' || (wtStop === 'body' && wtStep.outro.showWithPrevious))
            ? <IndependentCheckpoint
                checkpoint={wtStep.outro}
                entities={otherEntities}
                response={response as ListResponse}
                walkthroughSectionId={wtStep.sectionId}
                currentStop={wtStop}
              />
            : undefined,
      mainContent = showIntroCheckpointOnly
        ? undefined
        : section && (section as SpecialListSectionElement).specialSectionId === 'SUMMARY_STATS'
          ? <SummaryStatsCard
              {...{
                otherEntities,
                response: response as ListResponse,
                section: section as SpecialListSectionElement,
                experiment,
                entry: entry as ListEntry,
                onSubmitCallback,
                isUpdating
              }}
              isNewNonWalkthrough={!wtStep && !entrySummary.entry}
              onClose={wtStep?.sectionId !== section.id && entries.length ? () => { setCurrentSelection(undefined) } : undefined}
            />
          : section && (section as SpecialListSectionElement).specialSectionId === 'STATS_TEST'
            ? <StatsTestCard
              {...{
                otherEntities,
                response: response as ListResponse,
                section: section as SpecialListSectionElement,
                experiment,
                entrySummary,
                onSubmitCallback,
                isUpdating: !!isUpdating
              }}
              onClose={wtStep?.sectionId !== section.id ? () => { setCurrentSelection(undefined) } : undefined}
            />
            : <ListEntryFormContent
                {...{
                  formId,
                  formMethodsRef,
                  formSubmissionSource,
                  subscribeToFormStatus,
                  entrySummary,
                  experiment,
                  section,
                  headerPicker,
                  otherEntities,
                  onSubmitCallback,
                  onSuccessCallback,
                  isProcessing,
                  onDeleteCallback,
                  entries
                }}
                isInline={withInlineForm}
                neutralBackground={worksheet.specialLayoutType === 'ANALYSIS'}
                leftButton={cancelButton}
                rightButton={saveButton}
                permitDelete={entry && section?.elementVariety === 'LIST_ENTRY_SECTION' && !wtStep}
                functionalIndex={
                  isEmptyValue(actualIndex)
                    ? isListReversed ? 0 : Math.max(entries.length - 1, 0)
                    : isListReversed ? Math.max((entries.length - 1 - (actualIndex as number)), 0) : actualIndex}
              />

    React.useEffect(() => {
      setFormId(`${worksheet.id}-${actualIndex ?? 'new'}-${sectionId ?? 'repeatingElement'}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [worksheet, sectionId, currentSelection])

    React.useEffect(() => {
      setSectionId(newStartingSectionId)
    }, [currentSelection, newStartingSectionId])

    return introElement ?? outroElement
      ? <WalkthroughWrapper>
          {introElement}
          {mainContent}
          {outroElement}
        </WalkthroughWrapper>
      : mainContent
  }

export default ListEntryForm
export { WideButton }
