import React from 'react'
import { pick } from 'lodash'
import { getBaseErrorString } from '~/utils/formatters'
import { getIncompleteRepeatingSections } from './helpers'
import { useUpdateEntity } from '~/hooks'
import { Button } from '~/components/atoms'
import { ContentFooter } from '~/components/molecules'
import SubmitConfirmationComplex from '~/components/organisms/SubmitConfirmationComplex'
import { ExperimentHelpers, WorksheetHelpers, WorksheetResponseHelpers } from '~/features'

import type { ListStepSelection } from './types'
import type { Experiment, ListResponse, ListEntry, ListWorksheet } from '~/features'

interface Params {
  response: ListResponse
  experiment: Experiment
  worksheet: ListWorksheet
  entries: ListEntry[]
  currentSelection: ListStepSelection
}

const
  showReviewButtonForListWhen = ({ worksheet, entries, currentSelection }: Omit<Params, 'response' | 'experiment'>): boolean => {
    const
      sectionsById = WorksheetHelpers.getSectionsById(worksheet),
      incompleteRepeatingSections = getIncompleteRepeatingSections(worksheet, entries)

    return (
      !worksheet.skipSubmission &&
      incompleteRepeatingSections.length === 0 &&
      (!worksheet.closingSection || entries.some(o => sectionsById[o.sectionId].elementVariety === 'LIST_CLOSER_SECTION')) &&
      (!currentSelection)
    )
  },
  ListActions: React.FC<Params> = ({ response, experiment, worksheet, entries, currentSelection }) => {
    const
      showReviewButtons = showReviewButtonForListWhen({ worksheet, entries, currentSelection }),
      { updateEntity, isUpdating, errors } = useUpdateEntity(),
      parsedError = getBaseErrorString(
        errors,
        'Unable to submit response. Please try again later and contact support if the problem continues.'
      ),
      onSubmitCallback: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        updateEntity({
          entity: response,
          newValues: pick(WorksheetResponseHelpers.addSubmissionLog(response), 'eventLog')
        })
      }

    if (!showReviewButtons) { return null }

    const
      backButton = <Button
        key="back"
        linkProps={{ to: '/project-home' }}
        color="back" size="large"
        label={ExperimentHelpers.getDynamicString(experiment, 'waitToSubmitStep')}
      />,
      forwardButton = worksheet.includeReviewStep
        ? <Button
            key="forward"
            linkProps={{ to: `/project-step/${worksheet.id}/review` }}
            withIcon="checkmark" color="forward" size="large"
            label={ExperimentHelpers.getDynamicString(experiment, 'listItemReviewAll')}
          />
        : <SubmitConfirmationComplex
            key="submit"
            isLoading={false}
            isDisabled={false}
            errorMessage={errors ? parsedError : undefined}
            isUpdating={!!isUpdating}
            onConfirmSubmit={onSubmitCallback}
          />

    return <ContentFooter items={[backButton, forwardButton]} />
  }

export default ListActions
