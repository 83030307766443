import * as React from 'react'
import styled from 'styled-components'
import { HEADER_TEXTS } from '~/utils/strings'
import { useSession } from '~/hooks'
import { ErrorPage, Ul, Li, LeadIn, ContactUsMessage } from '~/components/pages/NotFoundPage'
import { Button, Link, HeadingOne, Tab, SettingsContentWrapper } from '~/components/atoms'
import { TabGroup } from '~/components/molecules'
import { AccountSettings } from '~/components/organisms'
import { BrandedPage, FlexibleMain } from '~/components/layouts'
import { Outlet, useMatches } from 'react-router-dom'

const
  Main = styled(FlexibleMain)`
    min-height: 500px;
  `,
  AccountSettingsPage: React.FC = () => {
    const
      { user, isSessionValid, isLearner, isAdmin, isTeacher, isResearcher, isParticipant } = useSession(),
      matches = useMatches(),
      isTemplates = matches.some(({ pathname }) => pathname.match(/templates/)),
      isAdminSummary = matches.some(({ pathname }) => pathname.match(/admin-tools/)),
      currentTab = isAdmin && isTemplates
        ? 'templates'
        : isAdmin && isAdminSummary
          ? 'admin'
          : 'account',
      overrideOutlet = (isTemplates || isAdminSummary) && !isAdmin,
      tabs = [
        { key: 'account', label: 'Account', path: '/account' },
        isAdmin /* || isTeacher */ ? { key: 'templates', label: 'Manage Templates', path: '/account/templates' } : null,
        isAdmin ? { key: 'admin', label: 'Admin Tools', path: '/account/admin-tools' } : null
      ].filter(o => o) as Array<{ key: string, label: string, path: string }>

    if (!!isLearner || !isSessionValid) {
      return <ErrorPage errorTitle="Page Not Found" message="You must be logged in to view this page">
        <LeadIn>What next?</LeadIn>
        <Ul>
          <Li>Check the web address for typos</Li>
          <Li>Return to the <Link to="/">AgConnect home page</Link> and log in</Li>
          <Li><ContactUsMessage /></Li>
        </Ul>
      </ErrorPage>
    }

    const
      sharedIconProps: Partial<React.ComponentProps<typeof Button>> = {
        withIcon: 'arrowL',
        color: 'back',
        size: 'small'
      },
      homeButton = isTeacher
        ? <Button {...sharedIconProps} label="Back to Projects" linkProps={{ to: '/projects' }} />
        : !!isResearcher || isParticipant
            ? <Button {...sharedIconProps} label="Back to Dashboard" linkProps={{ to: isResearcher ? '/r/projects' : '/participant-projects' }} />
            : <Button {...sharedIconProps} label="Home" linkProps={{ to: '/' }} />

    return (
      <BrandedPage
        documentTitle="Your Account | AgConnect"
        whichHome="teaching"
        showLoader={!user}
        headerText={HEADER_TEXTS.public}>
        <Main
          heading={<HeadingOne>Manage Your Account</HeadingOne>}
          topActions={homeButton}>
          <TabGroup className='tab-group'>
            {tabs.map(({ key, label, path }) => (
              <Tab
                key={key}
                label={label}
                linkProps={{ to: path }}
                tabRole={currentTab === key ? 'active' : undefined}
              />
            ))}
          </TabGroup>
          <SettingsContentWrapper>
            {overrideOutlet
              ? <AccountSettings />
              : <Outlet />}
          </SettingsContentWrapper>
        </Main>
      </BrandedPage>
    )
  }

export default AccountSettingsPage
