import { isEqual } from 'lodash'
import React from 'react'
import type { FormStatus, SubscribeToFormStatusFn, FormProps, FormMethodRef } from '~/form-brain2'

const useFormVitalsSubscription = <RelevantSubmissionSource extends string>(): {
  formStatus: FormStatus | undefined
  isProcessing: boolean
  formSubmissionSource: RelevantSubmissionSource | undefined
  subscribeToFormStatus: SubscribeToFormStatusFn
  formMethodsRef: FormMethodRef
} => {
  const
    [
      { isProcessing, formSubmissionSource, formStatus },
      setFormVitals
    ] = React.useState<{
      formStatus?: FormStatus
      isProcessing: boolean
      formSubmissionSource: RelevantSubmissionSource | undefined
    }>({ isProcessing: false, formSubmissionSource: undefined }),
    formMethodsRef: FormProps['methodRef'] = React.useRef(),
    subscribeToFormStatus: SubscribeToFormStatusFn = (newVitals) => {
      if (
        !isEqual(isProcessing, newVitals.formStatus === 'processing') ||
        formSubmissionSource !== newVitals.submissionSource ||
        formStatus !== newVitals.formStatus
      ) {
        setFormVitals({
          formStatus: newVitals.formStatus,
          isProcessing: newVitals.formStatus === 'processing',
          formSubmissionSource: newVitals.submissionSource as RelevantSubmissionSource | undefined
        })
      }
    }

  return { formStatus, isProcessing, formSubmissionSource, subscribeToFormStatus, formMethodsRef }
}

export default useFormVitalsSubscription
