import { get } from 'lodash'
import { isAllEmpty, isEmptyValue } from './testers'
import { checkConditions } from './conditions'

import type {
  AttrVal,
  SchemaEntry,
  EntityErrors,
  ValidateObjectFn,
  AttrErrors,
  ContextAndResolvers,
  EntityObject,
  EntitySchema,
  ValidationOptions
} from '~/form-brain2'

interface ValidateFieldProps {
  value: AttrVal
  contextAndResolvers: ContextAndResolvers
  schemaEntry?: SchemaEntry
  validationOptions?: ValidationOptions
  callerScopeValues?: EntityObject
}

interface ValidateObjectProps {
  values: EntityObject
  contextAndResolvers: ContextAndResolvers
  schema?: EntitySchema
  validationOptions?: ValidationOptions
  callerScopeValues?: EntityObject
}

const
  validateField = ({ value, schemaEntry, contextAndResolvers, validationOptions, callerScopeValues }: ValidateFieldProps): AttrErrors => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    validationOptions = validationOptions ?? ({} as ValidationOptions)

    if (!schemaEntry || isEmptyValue(schemaEntry) || schemaEntry.isTransient) { return /* no op */ }
    if (typeof schemaEntry !== 'object') { return /* cannot validate without proper format */ }

    const { requiredWhen, recommendedWhen /*, type, format */ } = schemaEntry

    if (isEmptyValue(value) || isAllEmpty(value)) {
      const isRequired = requiredWhen && checkConditions({
        contextAndResolvers,
        callerScopeValues,
        conditions: requiredWhen,
        fallback: false,
        callerName: schemaEntry.fieldName
      })

      if (isRequired) { return ['cannot be blank'] }

      const isSuggested = validationOptions.strict && recommendedWhen && checkConditions({
        contextAndResolvers,
        callerScopeValues,
        conditions: recommendedWhen,
        fallback: false,
        callerName: schemaEntry.fieldName
      })

      if (isSuggested) { return ['should not be blank (suggested)'] }
      // } else if (type === 'enum' || type === 'array:enum') {
      //   Removing because we want old values to be allowed and don't currently have a system for that

      //   const
      //     optionKeys = Array.isArray(options) ? options : [options],
      //     optionValues = flatMap(optionKeys, k => resolveOptions({formContext, options: k, valuesOnly: true}))

      //   if (Array.isArray(value)) {
      //     if (value.some(v => !optionValues.includes(v))) { return ['must all be permitted values'] }
      //   } else if (!optionValues.includes(value)) { return ['is not a permitted value'] }

      // } else if (type === 'numeric') {
      //   const number = Number(parseFloat(value))

      //   if (isNaN(value)) {
      //     return ['must be a number']
      //   } else if (format === 'integer' && number !== parseInt(value)) {
      //     return ['must be a whole number']
      //   } else if (format === '%' && (number > 100 || number < 0)) {
      //     return ['must be a percentage (between 0 and 100)']
      //   } else if (format === 'year' && (number !== parseInt(value) || number < 1000 || number > 9999)) {
      //     return ['must be a valid year']
      //   } else if ('gt' in format && typeof format.gt === 'number' && number <= format.gt) {
      //     return [`Must be greater than ${(format.gt as number).toString()}`]
      //   } else if ('gto' in format && typeof format.gto === 'number' && number < format.gto) {
      //     return [`Must be greater than or equal to ${(format.gto as number).toString()}`]
      //   } else if ('lt' in format && typeof format.lt === 'number' && number >= format.lt) {
      //     return [`Must be less than ${(format.lt as number).toString()}`]
      //   } else if ('lto' in format && typeof format.lto === 'number' && number > format.lto) {
      //     return [`Must be less than or equal to ${(format.lto as number).toString()}`]
      //   }
      // } else if (format === 'date') {
      //   if (!parseDate(value).isValid) {
      //     return [`must be a valid date in one of the following formats: ${DATE_FORMATS.join(', ')}`]
      //   }
    }
  },
  getSchemaForObject = (baseSchema: EntitySchema | undefined, values: EntityObject): EntitySchema | undefined => {
    if (!baseSchema) { return baseSchema }

    const
      dynamicFormatByFieldValue = baseSchema.dynamicFormatByFieldValue as unknown as undefined | Record<string | number, EntitySchema>,
      dynamicFormatFieldName = baseSchema.dynamicFormatFieldName as unknown as undefined | string

    if (!dynamicFormatFieldName || !dynamicFormatByFieldValue) { return baseSchema }

    const
      relevantValue = values?.[dynamicFormatFieldName],
      relevantSchema = typeof relevantValue === 'string' || typeof relevantValue === 'number'
        ? dynamicFormatByFieldValue[relevantValue]
        : undefined

    return relevantSchema ?? {}
  },
  validateObject = (props: ValidateObjectProps): EntityErrors => {
    let ret: EntityErrors = {}
    const
      { contextAndResolvers, validationOptions } = props,
      values = props.values || {},
      schema = getSchemaForObject(props.schema, values),
      permissive = validationOptions?.permissive,
      deletionKey = validationOptions?.deletionKey

    if (!schema || isEmptyValue(schema)) {
      ret = {}
    } else if (deletionKey && values[deletionKey]) {
      ret = {}
    } else {
      ret = Object.keys(permissive ? values : schema).reduce<EntityErrors>(
        (memo, name) => {
          const
            schemaEntry = get(schema, name),
            value = get(values, name)

          if (schemaEntry.fieldType === 'COLLECTION') {
            memo[`${name}_base`] = validateField({ schemaEntry, contextAndResolvers, value: value as AttrVal, validationOptions, callerScopeValues: values })

            if (value && Array.isArray(value)) {
              memo[name] = (value as EntityObject[]).map(o => validateObject({ ...props, values: o, schema: schemaEntry.format }))
            }
          } else if (schemaEntry.fieldType === 'SCOPE') {
            if (value && typeof value === 'object') {
              memo[name] = validateObject({ ...props, values: (value as EntityObject), schema: schemaEntry.format })
            }

            memo[name] = {
              ...(memo[name] ?? {}),
              _base: validateField({ schemaEntry, contextAndResolvers, value: value as AttrVal, validationOptions, callerScopeValues: values })
            }
          } else {
            const errors = validateField({ schemaEntry, contextAndResolvers, value: value as AttrVal, validationOptions, callerScopeValues: values })

            memo[name] = errors?.length ? errors : undefined
          }

          return memo
        }, {}
      )
    }

    return ret
  },
  formDefaultValidateObject: ValidateObjectFn = (params) => {
    // return validateObject({...params, validationOptions: { permissive: params.validationOptions.triggeredBy === 'SUBMIT' }})
    // return validateObject({...params, validationOptions: { permissive: true }})
    return validateObject(params)
  }

export { validateField, validateObject, formDefaultValidateObject }
