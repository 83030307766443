import type { AnyPlainOrInputElement, QuestionnaireFormat } from '../questionnaire/types'
import { MASCOT_VARIANTS_LIST, SPECIAL_LAYOUT_TYPE_LIST, TILES_LIST } from './optionLists'

export const
  getNewStepFormBody = (omitBaseOnWorksheet?: boolean): AnyPlainOrInputElement[] => {
    const
      basedOnKey = omitBaseOnWorksheet ? 'basedOnId' : 'basedOnType',
      baseOnWorksheetFields: AnyPlainOrInputElement[] = omitBaseOnWorksheet
        ? []
        : [{
            id: 'stepBasedOnType',
            elementVariety: 'SELECT_INPUT',
            label: 'Start from:',
            fieldName: 'basedOnType',
            placeholder: 'Choose one',
            invalidates: ['basedOnId'],
            hint: 'The format and contents of the step will be based on the template or step you choose here. You can change this later if needed, but only until you publish the step. After the step is published to learners, this cannot be changed.',
            list: [{
              optionValue: 'Template',
              optionLabel: 'A template'
            }, {
              optionValue: 'Worksheet',
              optionLabel: 'An existing step'
            }],
            blankOption: 'Start from scratch'
          },
          {
            id: 'stepBasedOnWorksheet',
            elementVariety: 'SELECT_INPUT',
            fieldName: 'basedOnId',
            placeholder: 'Choose a step',
            noSort: true,
            visibleWhen: [
              { conditionType: 'equals', testValue: 'Worksheet', valueName: basedOnKey },
              { conditionType: 'present', valueName: basedOnKey }
            ],
            requiredWhen: [{ conditionType: 'equals', testValue: 'Worksheet', valueName: basedOnKey }]
          }]

    return [
      ...baseOnWorksheetFields,
      {
        id: 'stepBasedOnTemplate',
        elementVariety: 'SELECT_INPUT',
        fieldName: 'basedOnId',
        placeholder: 'Choose a template',
        noSort: true,
        visibleWhen: [
          { conditionType: 'equals', testValue: 'Template', valueName: basedOnKey },
          { conditionType: 'present', valueName: basedOnKey }
        ],
        requiredWhen: [{ conditionType: 'equals', testValue: 'Template', valueName: basedOnKey }]
      },
      {
        id: 'stepName',
        elementVariety: 'TEXT_INPUT',
        fieldName: 'name',
        label: 'Step name:',
        placeholder: 'Enter a name',
        visibleWhen: [{ conditionType: 'absent', valueName: basedOnKey }]
      },
      {
        id: 'stepFormat',
        elementVariety: 'SELECT_INPUT',
        fieldName: 'format',
        noSort: true,
        label: 'Step format:',
        invalidates: ['specialLayoutType'],
        visibleWhen: [{ conditionType: 'absent', valueName: basedOnKey }],
        requiredWhen: [{ conditionType: 'absent', valueName: basedOnKey }]
      },
      {
        id: 'formatInstructions',
        elementVariety: 'INSTRUCTIONS',
        paragraphs: ['The step format cannot be changed later. Choose any option to learn more about it.'],
        visibleWhen: [
          { conditionType: 'absent', valueName: basedOnKey },
          { conditionType: 'absent', valueName: 'format' }
        ]
      },
      {
        id: 'formatDetails',
        elementVariety: 'SPECIAL',
        specialElementId: 'stepFormatInfo',
        visibleWhen: [{ conditionType: 'present', valueName: 'format' }]
      },
      {
        id: 'listSpecialLayout',
        elementVariety: 'SELECT_INPUT',
        fieldName: 'specialLayoutType',
        hint: 'The list type cannot be changed later. Most steps do not require a special type.',
        label: 'Special list type:',
        noSort: true,
        blankOption: 'None',
        list: SPECIAL_LAYOUT_TYPE_LIST,
        visibleWhen: [
          { conditionType: 'absent', valueName: basedOnKey },
          { conditionType: 'present', valueName: 'format' },
          { conditionType: 'equals', valueName: 'format', testValue: 'LIST' as QuestionnaireFormat }
        ]
      }
    ]
  },
  newStepFormBody = getNewStepFormBody(),
  getExistingStepFormBody = (omitBaseOnWorksheet?: boolean): AnyPlainOrInputElement[] => {
    const baseOnWorksheetFields: AnyPlainOrInputElement[] = omitBaseOnWorksheet
      ? []
      : [{
          id: 'stepBasedOnType',
          elementVariety: 'SELECT_INPUT',
          label: 'Start from:',
          fieldName: 'basedOnType',
          placeholder: 'Choose one',
          invalidates: ['basedOnId'],
          list: [{
            optionValue: 'Template',
            optionLabel: 'A template'
          }, {
            optionValue: 'Worksheet',
            optionLabel: 'An existing step'
          }],
          blankOption: 'Start from scratch'
        },
        {
          id: 'stepBasedOnWorksheet',
          elementVariety: 'SELECT_INPUT',
          fieldName: 'basedOnId',
          placeholder: 'Choose a step',
          missingValueLabel: '(Selected step unavailable)',
          noSort: true,
          blankOption: 'None',
          visibleWhen: [
            { conditionType: 'equals', testValue: 'Worksheet', valueName: 'basedOnType' },
            { conditionType: 'present', valueName: 'basedOnType' }
          ],
          requiredWhen: [{ conditionType: 'equals', testValue: 'Worksheet', valueName: 'basedOnType' }]
        }]

    return [
      {
        id: 'stepName',
        elementVariety: 'TEXT_INPUT',
        fieldName: 'name',
        label: 'Step name:',
        placeholder: 'Enter a name'
      },
      ...baseOnWorksheetFields,
      {
        id: 'stepBasedOnTemplate',
        elementVariety: 'SELECT_INPUT',
        fieldName: 'basedOnId',
        placeholder: 'Choose a template',
        missingValueLabel: '(Selected template unavailable)',
        noSort: true,
        visibleWhen: omitBaseOnWorksheet
          ? undefined
          : [
              { conditionType: 'equals', testValue: 'Template', valueName: 'basedOnType' },
              { conditionType: 'present', valueName: 'basedOnType' }
            ],
        requiredWhen: [{ conditionType: 'equals', testValue: 'Template', valueName: omitBaseOnWorksheet ? 'basedOnId' : 'basedOnType' }]
      },
      {
        id: 'stepFormat',
        elementVariety: 'SELECT_INPUT',
        fieldName: 'format',
        label: 'Step format:',
        readOnlyWhen: [{ conditionType: 'always' }]
      },
      {
        id: 'listSpecialLayout',
        elementVariety: 'SELECT_INPUT',
        fieldName: 'specialLayoutType',
        label: 'Special list type:',
        blankOption: 'None',
        list: SPECIAL_LAYOUT_TYPE_LIST,
        visibleWhen: [{ conditionType: 'present', valueName: 'specialLayoutType' }],
        readOnlyWhen: [{ conditionType: 'always' }]
      },
      {
        id: 'stepSubmitAsGroup',
        elementVariety: 'CHECKBOX_INPUT',
        fieldName: 'submitAsGroup',
        hint: 'Steps that impact the design of the experiment will follow the overall individual vs group experiment setting, while steps that do not influence that design are filled out individually by default. You can override that here if you want this step to be completed only once by the whole group together.',
        label: 'Learners should fill out this step together as a group',
        visibleWhen: [{ conditionType: 'falsy', valueName: 'fixedRank' }]
      }
    ]
  },
  existingStepFormBody = getExistingStepFormBody(),
  advancedPageFormFields: AnyPlainOrInputElement[] = [
    {
      id: 'stepHomePageText',
      elementVariety: 'LONG_ANSWER',
      fieldName: 'homePageText',
      label: 'Home page text:',
      hint: 'This text will appear on the learner\'s home page when this step is active. Use it for additional instructions or to help learners know what to expect when they start the step.'
    },
    {
      id: 'stepParentWorksheetId',
      elementVariety: 'SELECT_INPUT',
      fieldName: 'parentWorksheetId',
      hint: 'Steps can be grouped into hubs. You can nest this step under any existing hub step. Leave this blank on top-level steps which should appear on the scientific method cycle.',
      label: 'Nest this step under',
      blankOption: '(Not Nested)',
      noSort: true
    },
    {
      id: 'stepParentTemplateId',
      elementVariety: 'SELECT_INPUT',
      fieldName: 'parentTemplateId',
      hint: 'Steps can be grouped into hubs. You can nest this step under any existing hub step. Leave this blank on top-level steps which should appear on the scientific method cycle.',
      label: 'Nest this step template under',
      blankOption: '(Not Nested)',
      noSort: true
    },
    {
      id: 'stepTiles',
      elementVariety: 'MULTI_SELECT_INPUT',
      fieldName: 'tiles',
      hint: 'These appear at the top of the step page as an easy reference for experiment details',
      label: 'Quick-Access Tiles',
      list: TILES_LIST
    },
    {
      id: 'stepIncludeReviewStep',
      elementVariety: 'CHECKBOX_INPUT',
      fieldName: 'includeReviewStep',
      hint: 'A review step allows the learner to see all their answers on one page before submitting',
      label: 'Include a review page at the end of the step'
    },
    {
      id: 'stepReviewHintText',
      elementVariety: 'LONG_ANSWER',
      fieldName: 'reviewStepHintText',
      label: 'Review page hint:',
      hint: 'This text will be shown in the mascot\'s speech bubble on the review page for this step'
    },
    {
      id: 'stepReviewMascot',
      elementVariety: 'SELECT_INPUT',
      fieldName: 'reviewStepMascotVariant',
      hint: 'Which version of the mascot should be shown on the review page for this step?',
      label: 'Review page mascot:',
      noSort: true,
      blankOption: 'None',
      list: MASCOT_VARIANTS_LIST
    },
    {
      id: 'reviewMascotPreview',
      elementVariety: 'SPECIAL',
      specialElementId: 'mascotPreview',
      textKey: 'reviewStepHintText',
      variantKey: 'reviewStepMascotVariant',
      visibleWhen: [{ conditionType: 'truthy', valueName: 'includeReviewStep' }]
    },
    {
      id: 'stepSkipSubmission',
      elementVariety: 'CHECKBOX_INPUT',
      fieldName: 'skipSubmission',
      hint: 'Select this option if you want to make a step optional or want students to be able to continue editing their responses indefinitely',
      label: 'This step does not need to be submitted'
    },
    {
      id: 'stepResponsePageHintText',
      elementVariety: 'LONG_ANSWER',
      fieldName: 'responsePageHintText',
      label: 'Final hint:',
      hint: 'This text will be shown in the mascot\'s speech bubble once a response for this step has been submitted'
    },
    {
      id: 'stepResponsePageMascot',
      elementVariety: 'SELECT_INPUT',
      fieldName: 'responsePageMascotVariant',
      hint: 'Which version of the mascot should be shown once a response step has been submitted?',
      label: 'Final mascot:',
      noSort: true,
      blankOption: 'None',
      list: MASCOT_VARIANTS_LIST
    },
    {
      id: 'responseMascotPreview',
      elementVariety: 'SPECIAL',
      specialElementId: 'mascotPreview',
      textKey: 'responsePageHintText',
      variantKey: 'responsePageMascotVariant',
      visibleWhen: [{ conditionType: 'falsy', valueName: 'skipSubmission' }]
    }
  ]
