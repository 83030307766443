import * as React from 'react'
import { cloneDeep, get, pullAt, set } from 'lodash'
import { FormContext } from './FormContext'
import type { EntityErrors, UpdateValuesFn, EntityObject, EntitySchema, FormCollectionManagerProps, FormBody, FormRendererComponentType, CollectionOptions, ToggleFn, CollectionRendererProps, UpdateErrorsFn } from './types'
import type { FormContextType } from './internalTypes'

const
  defaultErrors = {},
  DEFAULT_ENTRY: EntityObject = {},
  FormCollectionManager: React.FC<FormCollectionManagerProps> = (props) => {
    const
      // Form context and state
      formContext = React.useContext(FormContext),
      { updateValues, updateErrors, schema, errors, values } = formContext,

      // Props
      { name, collectionOptions: _collectionOptions, formBody, CollectionRenderer, CollectionEntryRenderer } = props,
      defaultEntry = _collectionOptions?.defaultEntry ?? DEFAULT_ENTRY,
      defaultEntries = _collectionOptions?.defaultEntries ?? (_collectionOptions?.noInitialEntry ? [] : [defaultEntry]),

      // Collection state
      schemaEntry = React.useMemo(() => (
        get(schema, name) ?? { type: 'collection', format: {} }
      ), [schema, name]),
      childSchema = schemaEntry.format ?? {},
      childErrors = (get(errors, name) ?? {}) as EntityErrors,
      currentEntries: EntityObject[] = (get(values, name) ?? defaultEntries) as EntityObject[],
      currentErrors: EntityErrors[] = (get(errors, name) ?? {}) as EntityErrors[],
      initialValues = (get(formContext.initialValues, name) ?? defaultEntries) as EntityObject[],

      // Collection context
      updateEntry: (entryIndex: number) => UpdateValuesFn = React.useCallback(
        (entryIndex) => ({ newValues, silentUpdate }) => {
          const
            updatedCollection = currentEntries.map((o, i) => (
              i === entryIndex ? { ...o, ...newValues } : o
            )),
            valuesWithUpdatedCollection = set(cloneDeep(values), name, updatedCollection)

          if (updateValues) { updateValues({ newValues: valuesWithUpdatedCollection }) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentEntries, updateValues, name]
      ),
      updateCollectionErrors: (entryIndex: number) => UpdateErrorsFn = React.useCallback(
        (entryIndex) => ({ newErrors }) => {
          const
            updatedCollectionErrors = currentErrors.map((o, i) => (
              i === entryIndex ? { ...o, ...newErrors } : o
            ))
            // valuesWithUpdatedCollection = set(cloneDeep(values), name, updatedCollectionErrors)

          if (updateErrors) { updateErrors({ newErrors: { [name]: updatedCollectionErrors } }) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentEntries, updateValues, name]
      ),
      removeEntry: (entryIndex: number) => ToggleFn = React.useCallback(
        (entryIndex) => () => {
          const updatedCollection = [...currentEntries]

          pullAt(updatedCollection, [entryIndex])

          if (updateValues) { updateValues({ newValues: { [name]: updatedCollection } }) }
        }, [currentEntries, updateValues, name]
      ),
      addEntry = (newDefault?: EntityObject, insertBeforeIndex?: number): void => {
        const
          newEntry = newDefault ?? defaultEntry,
          insertAtEnd = insertBeforeIndex === undefined || insertBeforeIndex === currentEntries.length,
          updatedCollection = currentEntries.length
            ? currentEntries.reduce<EntityObject[]>((memo, o, i) => {
              if (i === (currentEntries.length - 1) && insertAtEnd) {
                return [...memo, o, newEntry]
              } else if (i === insertBeforeIndex) {
                return [...memo, newEntry, o]
              } else {
                return [...memo, o]
              }
            }, [])
            : [newEntry]

        if (updateValues) { updateValues({ newValues: { [name]: updatedCollection } }) }
      },
      collectionScope = formContext.scope ? `${formContext.scope}.${name}` : name,
      collectionOptions = _collectionOptions
        ? {
            ..._collectionOptions,
            collectionLength: currentEntries.length ?? 0
          }
        : undefined,
      keys = currentEntries.map((o, i) => (
        o.id && typeof o.id === 'string'
          ? o.id
          : `${collectionScope}[${i}]-of-${currentEntries.length ?? 'unknown'}`
      )),
      keysRef = React.useRef(keys)

    React.useEffect(() => {
      keysRef.current = keys
    }, [keys])

    return (
      <CollectionRenderer
        name={name}
        addEntry={addEntry}
        collectionScope={collectionScope}
        collectionOptions={collectionOptions}
        entries={currentEntries}>
        {currentEntries.map((o, i) => {
          const
            scope = `${collectionScope}[${i}]`,
            key = keys[i],
            entrySchema = schemaEntry.dynamicFormatByFieldValue && schemaEntry.dynamicFormatFieldName
              ? schemaEntry.dynamicFormatByFieldValue[o[schemaEntry.dynamicFormatFieldName] as string] ?? childSchema
              : childSchema

          return <CollectionEntryManager
            key={key}
            entry={o}
            entryIndex={i}
            scope={scope}
            formId={`${collectionScope}-${i}`}
            formBody={formBody}
            schema={entrySchema}
            errors={childErrors[i] ?? defaultErrors}
            updateEntry={updateEntry(i)}
            updateEntryErrors={updateCollectionErrors(i)}
            removeEntry={removeEntry(i)}
            addEntry={addEntry}
            isNew={keysRef.current && !keysRef.current.includes(key)}
            initialEntry={initialValues[i] ?? defaultEntry}
            CollectionEntryRenderer={CollectionEntryRenderer}
            collectionOptions={collectionOptions} />
        })}
      </CollectionRenderer>
    )
  }

interface CollectionEntryManagerProps {
  entry: EntityObject
  entryIndex: number
  scope: string
  formId: string
  formBody: FormBody
  schema: EntitySchema | undefined
  errors: EntityErrors
  addEntry: CollectionRendererProps['addEntry']
  isNew: boolean
  updateEntry: UpdateValuesFn
  updateEntryErrors: UpdateErrorsFn
  removeEntry: () => void
  initialEntry: EntityObject
  CollectionEntryRenderer: FormRendererComponentType
  collectionOptions?: CollectionOptions
}

export const CollectionEntryManager: React.FC<CollectionEntryManagerProps> = (props) => {
  const
    formContext = React.useContext(FormContext),
    { formId, entry, entryIndex, CollectionEntryRenderer, formBody, scope, updateEntry, updateEntryErrors, removeEntry, addEntry, isNew, schema, errors, collectionOptions, initialEntry } = props,
    [isEditing, setIsEditing] = React.useState(!!collectionOptions?.editInline || isNew),
    scopedFormContext = React.useMemo(() => ({ // TODO DEV override form state with a nested CLEAN value?!?
      ...formContext,
      values: entry,
      initialValues: initialEntry,
      scope,
      schema,
      errors,
      updateValues: updateEntry,
      updateErrors: updateEntryErrors,
      formOptions: formContext.formOptions,
      scopeOptions: {
        ...collectionOptions,
        entryIndex,
        isEntryNew: isNew,
        isEditing,
        removeEntry,
        addEntry,
        toggleEditing: () => { setIsEditing(_isEditing => !_isEditing) }
      }
    }), [schema, entry, entryIndex, initialEntry, collectionOptions, formContext, scope, isNew, errors, isEditing, setIsEditing, updateEntry, updateEntryErrors, removeEntry, addEntry])

  return <FormContext.Provider value={scopedFormContext as FormContextType}>
    <CollectionEntryRenderer formId={formId} formBody={formBody} isNested />
  </FormContext.Provider>
}

export default FormCollectionManager
