import * as React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Navigate, useSearchParams } from 'react-router-dom'
import { DOCUMENT_TITLES, HEADER_TEXTS, ABOUT_TEXT } from '~/utils/strings'
import { useSession } from '~/hooks'
import { BrandedPage, FlexibleMain } from '~/components/layouts'
import { CheckmarkIcon, HeadingOne, Link } from '~/components/atoms'
import { Blurb, InfoPanel } from '~/components/molecules'
import TeacherRequestAccountForm from '~/components/organisms/TeacherRequestAccountForm'
import RESEARCH_THEME from '../ResearchTheme'

const
  OrientationBlurb = styled(Blurb)`
    [data-cy="hint-text"] {
      font-size: 0.95rem;
    }
    
    p { margin-bottom: 0.25em; }

    ul {
      padding-left: 1.5em;

      li { margin-bottom: 0.5em; }
    }
  `,
  heading = <HeadingOne color="alt">Request an Account</HeadingOne>,
  blurbContent = <>
    <p>
      you consent to:
    </p>
    <ul>
      <li>have your email address collected and stored for the sole purpose of authenticating and communicating with you on this platform</li>
      <li>be bound by AgConnect&apos;s <Link to="/educate/terms-of-use" reloadDocument>Terms of Use</Link> and <Link to="/educate/privacy-policy" reloadDocument>Privacy Policy</Link>, updates to which are effective when posted here</li>
      <li>take responsibility for the conduct within AgConnct of the learners you supervise through this platform</li>
    </ul>
    <p>
      You and your school also agree that you and they are responsible for any privacy rules that apply to your and their students (e.g., FERPA)
    </p>
  </>,
  about = <InfoPanel heading="About AgConnect">
    {ABOUT_TEXT.teachingHome.map(s => <p key={s}>{s}</p>)}
  </InfoPanel>,
  GlobalHomePage: React.FC = () => {
    const
      { learnerId, isSessionValid, isTeacher } = useSession(),
      [searchParams] = useSearchParams(),
      pageError = searchParams.get('errors'),
      primaryContent = <>
        <OrientationBlurb
          withBackground
          icon={<CheckmarkIcon />}
          heading=" By creating an account with AgConnect..."
          content={blurbContent} />
        <TeacherRequestAccountForm pageError={pageError ?? undefined} />
      </>

    return <ThemeProvider theme={RESEARCH_THEME}>
      <BrandedPage
        documentTitle={DOCUMENT_TITLES.public}
        headerText={HEADER_TEXTS.public}
        whichHome="teaching">
        <FlexibleMain
          noHR
          heading={heading}
          primaryContent={primaryContent}
          secondaryContent={about} />
      </BrandedPage>
      {isSessionValid && learnerId
        ? <Navigate replace to="/project-home" />
        : null}
      {isSessionValid && isTeacher
        ? <Navigate replace to="/projects" />
        : null}
    </ThemeProvider>
  }

export default GlobalHomePage
