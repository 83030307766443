import { InputLikeVarieties, PlainElementVarieties } from './plainElementTypes'
import { InputElementVarieties, InputElementVarietiesWithPlaceholder, InputElementVarietiesWithUnit } from './inputElementTypes'
import { SectionElementVarieties, ListSectionElementVarieties, PagedSectionElementVarieties } from './sectionElementTypes'
import type { AnyListSectionElement, AnyBodyElement, PagedSectionElement } from './sectionElementTypes'
import type { FieldType } from './fieldTypes'
import type { AnyPlainOrInputElement } from './plainElementTypes'
import type { AnyInputElement } from './inputElementTypes'

export type {
  AnyPlainOrInputElement,
  AnyInputElement,
  AnyBodyElement,
  AnyListSectionElement
}

export {
  PlainElementVarieties,
  InputElementVarieties,
  SectionElementVarieties,
  ListSectionElementVarieties,
  PagedSectionElementVarieties,
  InputElementVarietiesWithPlaceholder,
  InputElementVarietiesWithUnit
}

export const ElementVarieties = [...SectionElementVarieties, ...PlainElementVarieties, ...InputElementVarieties, 'PAGE'] as const
export type ElementVariety = typeof ElementVarieties[number]

export const comparableInputVarieties = [...InputElementVarieties] as ElementVariety[]
export const comparableInputLikeVarieties = [...InputLikeVarieties] as ElementVariety[]

export type {
  Questionnaire,
  AnyQuestionnaire,
  PagedQuestionnaire,
  ListQuestionnaire,
  DownloadsQuestionnaire,
  HubQuestionnaire,
  QuestionnaireFormat,
  PagedQuestionnaireAttrs,
  ListQuestionnaireAttrs,
  HubQuestionnaireAttrs,
  DownloadsQuestionnnaireAttrs,
  SpecialLayoutType,
  ListQuestionnaireDefault,
  WalkthroughStep,
  WalkthroughCheckpoint,
  TileId,
  SpecialContentId
} from './questionnaireTypes'
export { SpecialLayoutTypes } from './questionnaireTypes'

export type {
  SectionElementVariety,
  AnySectionElement,

  ListSectionElement,
  ListSectionElementVariety,
  RepeatingSectionElement,
  SpecialListSectionElement,
  SpecialSectionId,
  OpeningSectionElement,
  SpecialOpeningSectionElement,
  ClosingSectionElement,
  SpecialClosingSectionElement,

  StatsTestType,
  SummaryStatName,

  PagedSectionElement,
  PagedSectionElementVariety,
  PageElement
} from './sectionElementTypes'
export {
  SpecialSectionIds,
  SpecialSectionIdsBySpecialLayoutType
} from './sectionElementTypes'

export type {
  PlainElementVariety,
  AnyPlainElement,
  TextElement,
  HeadingElement,
  SubheadingElement,
  InstructionsElement,
  BlurbElement,
  IconName,
  ValueElement,
  TableElement,
  CollapsibleElement,
  MadLibElement,
  MadLibSegment,
  CollectionElement,
  ScopeElement,
  FieldsetElement,
  SpecialElement,
  SpecialElementId
} from './plainElementTypes'
export { SpecialElementIds, InputLikeVarieties } from './plainElementTypes'

export type {
  InputElementVariety,
  InputElement,
  CustomInput,
  TextInput,
  DateInput,
  SelectInput,
  MultiSelectInput,
  MultiTextInput,
  CheckboxInput,
  ColorPickerInput
} from './inputElementTypes'

export type {
  VisibilityCondition,
  ValidationCondition,
  ConditionType
} from './conditionTypes'
export {
  IndependentConditions,
  TextToTextConditions,
  TextToIntConditions,
  TextToArrayConditions,
  TextConditions,
  NumberConditions,
  DateConditions,
  ArrayToSingletonConditions,
  ArrayToIntConditions,
  ArrayConditions,
  ComparativeConditions,
  ConditionTypes
} from './conditionTypes'

export type {
  QCalculation
} from './calculationTypes'

export type {
  QField,
  FieldType,
  ListOption
} from './fieldTypes'

export const
  elementVarietiesByFieldType: Record<FieldType, ElementVariety> = {
    NUMBER: 'NUMBER_INPUT',
    STRING: 'TEXT_INPUT',
    'STRING:ARRAY': 'MULTI_SELECT_INPUT',
    DATE: 'DATE_INPUT',
    ENUM: 'SELECT_INPUT',
    BOOLEAN: 'CHECKBOX_INPUT',
    SCOPE: 'SCOPE',
    COLLECTION: 'COLLECTION'
  },
  isInputElement = (element: AnyPlainOrInputElement): element is AnyInputElement => {
    return comparableInputVarieties.includes(element.elementVariety)
  },
  isListSection = (bodyElement: AnyBodyElement): bodyElement is AnyListSectionElement => {
    const listSectionVarieties = [...ListSectionElementVarieties] as ElementVariety[]

    return listSectionVarieties.includes(bodyElement.elementVariety)
  },
  isPagedSection = (bodyElement: AnyBodyElement): bodyElement is PagedSectionElement => {
    const pagedSectionVarieties = [...PagedSectionElementVarieties] as ElementVariety[]

    return pagedSectionVarieties.includes(bodyElement.elementVariety)
  }
