import type { AnyObject } from '~/types/utilities'

export const
  isNumeric = (value: unknown): value is number | string => {
    if (typeof value === 'number') {
      return !isNaN(value) && isFinite(value)
    } else if (typeof value === 'string') {
      // return !isNaN(+value)
      return parseFloat(value).toString() === value
    } else {
      return false
    }
  },
  isEmptyValue = (value: unknown): boolean => {
    if (isNumeric(value)) return false

    if (typeof value === 'boolean') return false
    if (typeof value === 'function') return false

    return (
      value == null || value === undefined ||
      (typeof value === 'object' && ('length' in value) && value.length === 0) ||
      (typeof value === 'object' && Object.keys(value).length === 0 && value.constructor === Object) ||
      (typeof value === 'string' && value.trim().length === 0)
    )
  },
  isAllEmpty = (obj?: unknown): boolean => {
    const empty = isEmptyValue(obj)

    if (Array.isArray(obj)) {
      return empty || obj.every(o => isAllEmpty(o))
    } else if (obj && typeof obj === 'object') {
      return empty || Object.keys(obj).every(k => isAllEmpty((obj as AnyObject)[k]))
    } else {
      return empty
    }
  }
