import { existingStepSchema } from './baseSchemas'
import { MASCOT_VARIANTS_LIST } from './optionLists'
import { blurbElementSchema } from './elementSchemas'
import { advancedPageFormFields, existingStepFormBody } from './baseFormBodies'
import { blurbFormBody } from './elementFormBodies'
import type { EntitySchema } from '~/form-brain2'
import type { AnyPlainOrInputElement, QuestionnaireFormat } from '~/features/questionnaire/types'

export const
  downloadsStepSchema: EntitySchema = {
    ...existingStepSchema,
    pageHint: {
      fieldName: 'pageHint',
      fieldType: 'STRING',
      visibleWhen: [{ conditionType: 'equals', valueName: 'format', testValue: 'DOWNLOADS' as QuestionnaireFormat }]
    },
    mascotVariant: {
      fieldName: 'mascotVariant',
      fieldType: 'ENUM',
      visibleWhen: [
        { conditionType: 'equals', valueName: 'format', testValue: 'DOWNLOADS' as QuestionnaireFormat },
        { conditionType: 'present', valueName: 'pageHint' },
        { conditionType: 'wordLengthAtLeast', valueName: 'pageHint', testValue: 1 }
      ]
    },
    leader: {
      fieldName: 'leader',
      fieldType: 'SCOPE',
      format: blurbElementSchema,
      visibleWhen: [{ conditionType: 'equals', valueName: 'format', testValue: 'DOWNLOADS' as QuestionnaireFormat }]
    }
  },
  downloadsStepFormBody: AnyPlainOrInputElement[] = [
    ...existingStepFormBody,
    {
      id: 'downloadsOptions',
      elementVariety: 'COLLAPSIBLE',
      expandButtonLabel: 'Show Downloads Step Options',
      collapseButtonLabel: 'Hide Downloads Step Options',
      body: [
        {
          id: 'downloadsPageHintText',
          elementVariety: 'LONG_ANSWER',
          fieldName: 'pageHint',
          label: 'Downloads page hint:',
          hint: 'This text will be shown in the mascot\'s speech bubble on the downloads page'
        },
        {
          id: 'downloadsPageMascot',
          elementVariety: 'SELECT_INPUT',
          fieldName: 'mascotVariant',
          hint: 'Which version of the mascot should be shown on the downloads page?',
          label: 'Downloads page mascot:',
          noSort: true,
          blankOption: 'None',
          list: MASCOT_VARIANTS_LIST
        },
        {
          id: 'downloadsMascotPreview',
          elementVariety: 'SPECIAL',
          specialElementId: 'mascotPreview',
          textKey: 'pageHint',
          variantKey: 'mascotVariant'
        },
        {
          id: 'downloadsPageLeader',
          elementVariety: 'SCOPE',
          fieldName: 'leader',
          label: 'Optional Intro Section',
          formBody: blurbFormBody
        }
      ]
    },
    {
      id: 'downloadsAdvanced',
      elementVariety: 'COLLAPSIBLE',
      expandButtonLabel: 'Show Advanced Options',
      collapseButtonLabel: 'Hide Advanced Options',
      body: advancedPageFormFields
    }
  ]
