import { isEmptyValue } from '~/utils/testers'
import { type SavePointDataSection } from './helpers'

export function pngFromSavePointData (section: SavePointDataSection[], title: string | undefined, stringOnly: true): Promise<null | string>
export function pngFromSavePointData (section: SavePointDataSection[], title: string | undefined): Promise<null | Blob>

export function pngFromSavePointData (section: SavePointDataSection[], title: string | undefined, stringOnly?: boolean): Promise<null | Blob | string> {
  const
    initialRow = section[0]?.data?.[0],
    base64png = Array.isArray(initialRow) ? initialRow[0] : initialRow?.base64png

  if (!base64png || typeof base64png !== 'string' || isEmptyValue(base64png)) {
    return Promise.resolve(null)
  }

  return stringOnly
    ? Promise.resolve(base64png)
    : fetch(base64png).then(response => response.blob())
}

export default pngFromSavePointData
