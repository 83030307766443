import {
  BasicCheckmark,
  BasicSquare,
  BasicDiamond,
  BasicCircle,
  BasicTriangle,
  BasicPentagon,
  BasicAsterisk,
  BasicTribble,
  AngleBrace,
  Notepad,
  RetortFlask
} from '~/components/atoms/Shapes'
import type { IconName } from '~/features/questionnaire/types'

export const availableIcons: Record<IconName, React.ComponentType> = {
  check: BasicCheckmark,
  square: BasicSquare,
  diamond: BasicDiamond,
  circle: BasicCircle,
  triangle: BasicTriangle,
  pentagon: BasicPentagon,
  asterisk: BasicAsterisk,
  bobble: BasicTribble,
  angle: AngleBrace,
  notepad: Notepad,
  flask: RetortFlask
}
