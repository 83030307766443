import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { useParams } from 'react-router-dom'
import { Form } from '~/form-brain2'
import { selectBasisForExperiment, selectExperimentById, selectResponsesByExperimentId, selectWorksheetsByExperimentId } from '~/features/entity/selectors'
import { addEmphasisToString, experimentFormResolvers, WorksheetHelpers } from '~/features'
import { useAppSelector, useFormVitalsSubscription, useProjectFormBody, useUpdateEntity, useResetEntity } from '~/hooks'
import { ButtonWithConfirm, FormRenderer, ModalOverlay, SmallMessageContainer } from '~/components/molecules'
import { Button, FormLabel, SizedLoadingIcon, SettingsTitleRow, SettingsSectionDivider, StatusText } from '~/components/atoms'
import ProjectSteps from './ProjectSteps'
import SubmitButtonComplex from '../../molecules/SubmitButtonComplex'

import type { EntityObject, FormOnSubmitFn } from '~/form-brain2'
import type { Experiment, TemplateSet } from '~/features'
import { getBaseErrorString } from '~/utils/formatters'
import { isAllEmpty } from '~/utils/testers'

interface Props {
  segment: 'settings' | 'labels'
}

const
  formName = 'ProjectSettings',
  StyledButton = styled(Button)`
    align-self: flex-start;
  `,
  Actions = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${p => p.theme.emBaseSpacing}em;
    margin-bottom: ${p => p.theme.emBaseSpacing}em;
  `,
  // accessTipParagraphs = [
  //   'You can control access at three levels:',
  //   '- Project (buttons here): Set access for **all learners** to the **whole project**',
  //   '- Step (table below): Set access for **all learners** to **individual steps**',
  //   '- Learner (dropdown above): Set access for **individual learners** to **individual steps**'
  // ],
  ProjectSettings: React.FC<Props> = ({ segment }) => {
    const
      THEME = useTheme(),
      { id } = useParams(),
      { updateEntity, sourceOfUpdate, isUpdating } = useUpdateEntity(),
      { resetEntity, isSuccess: isRefreshSuccess, isResetting, errors: resetErrors } = useResetEntity({
        id,
        type: 'experiment',
        silentUpdate: true,
        clearStateOnSuccessAfter: 2000
      }),
      { formStatus, subscribeToFormStatus } = useFormVitalsSubscription(),
      experiment = useAppSelector(selectExperimentById(id)) as Experiment,
      worksheets = useAppSelector(selectWorksheetsByExperimentId(id)),
      firstWorksheet = WorksheetHelpers.getSortedTopLevelWorksheets(worksheets)[0],
      { schema, formBody } = useProjectFormBody(segment === 'settings' ? 'settings' : 'labels', id),
      basisForExperiment = useAppSelector(selectBasisForExperiment(experiment)),
      basedOnTitle = !basisForExperiment
        ? undefined
        : basisForExperiment.type === 'experiment'
          ? (basisForExperiment as Experiment).name
          : (basisForExperiment as TemplateSet).title,
      responses = useAppSelector(selectResponsesByExperimentId(id)),
      allowReset = basedOnTitle && isAllEmpty(responses),
      onSubmit: FormOnSubmitFn = ({ values, submissionSource }) => updateEntity({
        newValues: values,
        entity: experiment,
        sourceOfUpdate: submissionSource
      }),
      startProject: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        void onSubmit({
          values: { startedAt: new Date().toISOString() },
          submissionSource: 'start'
        })
      },
      unstartProject: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        void onSubmit({
          values: { startedAt: null, closedAt: null } as unknown as EntityObject, // Has to be null, undefined is not send with json
          submissionSource: 'unstart'
        })
      },
      closeProject: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        void onSubmit({
          values: { closedAt: new Date().toISOString() },
          submissionSource: 'close'
        })
      },
      reopenProject: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        void onSubmit({
          values: { closedAt: null } as unknown as EntityObject, // Has to be null, undefined is not send with json
          submissionSource: 'reopen'
        })
      },
      resetProject: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        resetEntity()
      },
      formId = `${formName}${(experiment.updatedAt ?? '').replace(/\D/g, '')}`

    React.useEffect(() => {
      if (isRefreshSuccess) {
        const timeout = setTimeout(() => { location.reload() }, 1800)

        return () => { clearTimeout(timeout) }
      }
    }, [isRefreshSuccess])

    return <>
      {isRefreshSuccess
        ? <ModalOverlay wholePage>
          <SmallMessageContainer
            body={<SizedLoadingIcon />}
            subheading="Reset successful! Reloading group..."
            footerContent={null}
          />
        </ModalOverlay>
        : null}
      <SettingsTitleRow>
        <FormLabel
          label={segment === 'settings' ? 'Main Settings' : 'Customizable Labels'}
          showLabel
          asHeading
        />
        {allowReset && !isRefreshSuccess
          ? <ButtonWithConfirm
            onConfirm={resetProject}
            buttonProps={{
              size: 'medium',
              label: 'Reset',
              color: 'caution',
              isLoading: isResetting,
              isDisabled: isUpdating
            }}
            cancelLabel="Cancel"
            confirmLabel="Confirm Reset"
            subheading="Reset experiment steps and settings"
            confirmationBody={`This will delete any changes you have made to the steps in this project and replace them with the steps from ${basedOnTitle}`}
            errorMessage={resetErrors ? getBaseErrorString(resetErrors, 'An unexpected error occurred') : undefined}
          />
          : null}
      </SettingsTitleRow>
      <Form
        formId={formId}
        entitySchema={schema}
        formBody={formBody}
        initialValues={experiment as unknown as EntityObject}
        onSubmitCallback={onSubmit}
        FormRenderer={FormRenderer}
        subscribeToFormStatus={subscribeToFormStatus}
        formOptions={{ RootFormRenderer: FormRenderer }}
        {...experimentFormResolvers}
      />
      <SubmitButtonComplex
        {...{ formId, formStatus }}
        label="Save Changes"
        isLoading={isUpdating && sourceOfUpdate === 'event'}
        isDisabled={!!sourceOfUpdate}
      />
      {segment === 'settings'
        ? <>
           <SettingsSectionDivider color={THEME.colors.understateDark} />
           <FormLabel
            label="Project Access"
            showLabel
            asHeading
          />
          <StatusText color="default">
            {addEmphasisToString('Here you can control access for **all learners** to the **whole project**')}
          </StatusText>
          <StatusText color="accent">
            {experiment.closedAt
              ? 'Project has ended: learners can no longer access the project.'
              : experiment.startedAt && !firstWorksheet.releasedAt
                ? 'Project is open but the first step is unpublished: learners can access the home page but cannot work on the project.'
                : experiment.startedAt
                  ? 'Project is open: learners can access and work on the project.'
                  : 'Project is closed: learners can access the welcome page but cannot work on the project.'}
          </StatusText>
          <Actions>
            {experiment.closedAt
              ? <StyledButton size="medium" color="back" label="Re-Open Project" onClick={reopenProject} isLoading={sourceOfUpdate === 'reopen'} isDisabled={isUpdating && sourceOfUpdate !== 'reopen'} />
              : experiment.startedAt
                ? <>
                  <StyledButton size="medium" color="back" label="Pause Project" onClick={unstartProject} isLoading={sourceOfUpdate === 'unstart'} isDisabled={isUpdating && sourceOfUpdate !== 'unstart'} />
                  <StyledButton size="medium" color="back" label="End Project" onClick={closeProject} isLoading={sourceOfUpdate === 'close'} isDisabled={isUpdating && sourceOfUpdate !== 'close'} />
                </>
                : <StyledButton size="medium" color="back" label="Open Project" onClick={startProject} isLoading={sourceOfUpdate === 'start'} isDisabled={isUpdating && sourceOfUpdate !== 'start'} />}
          </Actions>
        </>
        : null}
      {segment === 'settings'
        ? <>
           <SettingsSectionDivider color={THEME.colors.understateDark} />
           <FormLabel
            label="Project Steps"
            showLabel
            asHeading
          />
           <StatusText color="default">
             {addEmphasisToString('The Publish and Retract buttons control access for **all learners** to **individual steps**.')}
             <br />
             {addEmphasisToString('Note that once any learner has started a step, that step can no longer be edited or retracted.')}
           </StatusText>
          <ProjectSteps isDisabled={isUpdating} />
        </>
        : null}
    </>
  }

export default ProjectSettings
