import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { BasicCheckmark } from '~/components/atoms/Shapes'

interface FauxCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  $hasError?: boolean
  className?: string
}

const
  FakeInputWrapper = styled.div<{ $readonly?: boolean, $disabled?: boolean, $hasError?: boolean }>`
    flex: 0 0 auto;
    position: relative;
    display: flex;
    width: 1.3em;
    height: 1.3em;

    .checkbox-background {
      border-color: ${p => p.$hasError ? p.theme.colors.cautionStandaloneText : p.$readonly ? 'transparent' : p.$disabled ? p.theme.colors.understateMed : p.theme.colors.borderAccentAlt};
      background: ${p => p.$disabled ? 'transparent' : p.$readonly ? p.theme.colors.understateLight : p.theme.colors.bodyBg};
    }

    .checkbox-check-icon {
      position: relative;
      width: 1em;
      margin: auto;
      z-index: ${p => p.theme.zLocalTop};
    }

    input:focus + .checkbox-background {
      ${p => !!p.$disabled || !!p.$readonly ? '' : `border-color: ${p.theme.colors.back}; outline: 1px solid ${p.theme.colors.back};`}
    }
  `,
  Input = styled.input`
    position: absolute;
    left: 0em;
    width: 1.3em;
    height: 1.3em;
    opacity: 0;
    z-index: ${p => p.theme.zLocalTop + 1};

    &:hover {
      cursor: pointer;
    }

    &[disabled]:hover, &[readonly]:hover {
      cursor: auto;
    }
  `,
  FakeInputBackground = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    width: 1.3em;
    height: 1.3em;
    border-radius: 0.35em;
    border-width: 1px;
    border-style: solid;
  `,
  FauxCheckbox: React.FC<FauxCheckboxProps> = ({ name, onChange, disabled, readOnly, $hasError, className, defaultChecked, ...otherProps }) => {
    const THEME = useTheme()

    return (
      <FakeInputWrapper
        $readonly={readOnly}
        $disabled={disabled}
        $hasError={$hasError}
        data-cy="faux-checkbox"
        className={className}>
        <Input
          type="checkbox"
          name={name}
          defaultChecked={defaultChecked}
          disabled={disabled}
          readOnly={readOnly}
          onChange={!!disabled || readOnly ? undefined : onChange}
          {...otherProps}
         />
        <FakeInputBackground className="checkbox-background" />
        {defaultChecked
          ? <BasicCheckmark
            className="checkbox-check-icon"
            color={readOnly ? THEME.colors.understateDark : disabled ? THEME.colors.understateLight : THEME.colors.borderAccentAlt}
            outlineColor={!!readOnly || disabled ? THEME.colors.understateDark : THEME.colors.back} />
          : null}
      </FakeInputWrapper>
    )
  }

export default FauxCheckbox
