import type { ObjectId, RootEntity, TimeString } from '~/types/utilities'
import type { QCalculation, QField } from '../questionnaire/types'

export interface Experiment extends RootEntity {
  // System
  ownerId: ObjectId
  randomnessSeed: string
  testmode?: boolean
  startedAt?: TimeString
  closedAt?: TimeString

  // Configuration
  name: string
  experimentStyle: 'individual' | 'group'
  progressBasis: 'manual' | 'approval' | 'submission'// | 'weekday' | 'interval'
  basedOnId?: ObjectId
  basedOnType?: 'TemplateSet' | 'Experiment'
  dynamicLabels: Partial<DynamicLabels>

  // Worksheet & Response
  sharedFields?: QField
  sharedCalculations?: QCalculation[]
}

// export interface IntervalBasis extends Experiment {
//   progressBasis: 'interval'
//   intervalOfDays: number
// }

// export interface WeekdayBasis extends Experiment {
//   progressBasis: 'weekday'
//   dayOfWeek: 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun'
// }

export interface DynamicLabels {
  learnerProjectHome: string
  mascotName: string
  brandIconDescriptor: string
  shortTextPlaceholder: string
  numberPlaceholder: string
  longTextPlaceholder: string
  selectPlaceholder: string
  backToHome: string
  backToHub: string
  submitStep: string
  waitToSubmitStep: string
  saveAndReturn: string
  requestToMakeChanges: string
  listItemCancel: string
  listItemSave: string
  listItemSaveAndReview: string
  listItemReviewAll: string
  firstPageBack: string
  pageBack: string
  pageNext: string
  sectionNext: string
  pageReview: string
  returnToListFromReview: string
  returnToPageFromReview: string
  preSubmitWarning: string
  preDeleteWarning: string
  pagedIntroBlurb: string
  sectionIntroBlurb: string
  cancelSubmitButton: string
  confirmSubmitButton: string
  cancelDeleteButton: string
  confirmDeleteButton: string
  nothingToTurnIn: string
  eventNotYetSubmitted: string
  eventSubmitted: string
  eventStudentRequestsReopen: string
  outcomeRequestPending: string
  outcomeRequestApproved: string
  outcomeRequestDenied: string
  outcomeSubmissionApproved: string
  outcomeSubmissionDenied: string
  _addExampleData: string // system only
  _saveExampleData: string // system only
}

export const
  DYNAMIC_LABELS: DynamicLabels = {
    learnerProjectHome: 'Experiment Center',
    mascotName: 'Carson',
    brandIconDescriptor: 'tower icon',
    shortTextPlaceholder: 'Type here.',
    numberPlaceholder: 'Enter a number.',
    longTextPlaceholder: 'Click here to start typing your response.',
    selectPlaceholder: 'Choose one',
    backToHome: 'Home',
    backToHub: 'Step Home',
    submitStep: 'Hand in',
    waitToSubmitStep: 'Save for later',
    saveAndReturn: 'Save and go Home',
    requestToMakeChanges: 'Request to make changes',
    listItemCancel: 'Cancel',
    listItemSave: 'Save',
    listItemSaveAndReview: 'Save & review',
    listItemReviewAll: 'Review',
    firstPageBack: 'Back',
    pageBack: 'Back',
    pageNext: 'Next',
    sectionNext: 'Next section',
    pageReview: 'Review',
    returnToPageFromReview: 'Return to previous page',
    returnToListFromReview: 'Return to add or remove from list',
    preSubmitWarning: `When you turn this step in, you will no longer be able to edit it - just like turning in answers on paper! 

If you need to change something after you turn it in, you can request that your teacher unlock it.`,
    preDeleteWarning: 'If you delete this, it is gone forever - there\'s no one who can get it back!',
    pagedIntroBlurb: `Your work is saved every time you use the left or right arrow button above.
You can go back and forth between questions any time.`,
    sectionIntroBlurb: 'You can return to any section you have already been to by using the tabs above the question.',
    cancelSubmitButton: 'Cancel',
    confirmSubmitButton: 'Hand in',
    cancelDeleteButton: 'Cancel',
    confirmDeleteButton: 'Delete',
    nothingToTurnIn: 'Nothing to turn in here; just use the home button to return to the experiment home page when you are done.',

    eventNotYetSubmitted: 'In progress.',
    eventSubmitted: 'Handed in.',
    eventStudentRequestsReopen: 'Requested to make changes.',
    outcomeRequestPending: 'Awaiting response.',
    outcomeRequestApproved: 'Request granted.',
    outcomeRequestDenied: 'Request not granted.',
    outcomeSubmissionDenied: 'Requested revision.',
    outcomeSubmissionApproved: 'Approved.',
    _addExampleData: 'Update Example',
    _saveExampleData: 'Update Example' // System only
  },
  DYNAMIC_LABEL_DETAILS = {
    learnerProjectHome: {
      label: 'Learner home page title',
      hint: ''
    },
    mascotName: {
      label: 'Mascot name',
      hint: 'The name shown for the scientist pig who provides hints throughout AgConnect'
    },
    // brandIconDescriptor: {
    //   label: '',
    //   hint: ''
    // },
    shortTextPlaceholder: {
      label: 'Placeholder for text input',
      hint: 'When nothing has been entered for an answer which is just a few words or a sentence, this text will show by default'
    },
    numberPlaceholder: {
      label: 'Placeholder for number input',
      hint: 'When nothing has been entered for an answer which is a number, this text will show by default'
    },
    longTextPlaceholder: {
      label: 'Placeholder for long text input',
      hint: 'When nothing has been entered for a long answer question (more than one line or sentence), this text will show by default',
      longAnswer: true
    },
    selectPlaceholder: {
      label: 'Placeholder for dropdowns',
      hint: 'When nothing has been chosen for a dropdown yet, this text will show by default'
    },
    backToHome: {
      label: 'Button going back to home page',
      hint: 'The label for the button which takes a learner back to the learner home page'
    },
    // backToHub: {
    //   label: 'Button going back to a hub step',
    //   hint: 'The label for the button which takes'
    // },
    submitStep: {
      label: 'Step submit button label',
      hint: 'The label for the button which submits a learner\'s response for a step'
    },
    waitToSubmitStep: {
      label: 'Submit later button label',
      hint: 'The label for the button on the review page which saves the response without submitting and takes a learner back to the home page'
    },
    requestToMakeChanges: {
      label: 'Request to reopen button label',
      hint: 'The label for the button on the submitted response page which sends a request to you to reopen the response so the learner can make changes'
    },
    listItemCancel: {
      label: 'List entry cancel button',
      hint: 'The label for the button on a list entry which closes or discards the entry which is currently being worked on'
    },
    listItemSave: {
      label: 'List entry save button',
      hint: 'The label for the button on a list entry which saves the entry which is currently being worked on'
    },
    listItemSaveAndReview: {
      label: 'Final list entry save button',
      hint: 'The label for the button on a list entry which saves the entry and takes the user to the review page (shown only on the final item)'
    },
    listItemReviewAll: {
      label: 'List review all button',
      hint: 'The label for the button at the end of a list step which take the user to the review page (shown only if the conditions for completing the list are met)'
    },
    returnToListFromReview: {
      label: 'Return to list button',
      hint: 'When in the review section for a list step, this button allows the user to go back to the whole list view and add or remove list entries'
    },
    // firstPageBack: {
    //   label: '',
    //   hint: ''
    // },
    pageBack: {
      label: 'Page back button',
      hint: 'The label for the button on a paged step form which takes the user to the previous page or section of the same form'
    },
    pageNext: {
      label: 'Page next button',
      hint: 'The label for the button on a paged step form which takes the user to the next page or section of the same form'
    },
    sectionNext: {
      label: 'Section next button',
      hint: 'When the next page is part of a new section, this label replaces the "Page next button" label'
    },
    pageReview: {
      label: 'Page review all button',
      hint: 'The label for the button at the end of the last section of a paged step form which take the user to the review page'
    },
    returnToPageFromReview: {
      label: 'Return to pages button',
      hint: 'When in the review section for a paged step, this button allows the user to go back to the page-by-page version of the form'
    },
    preSubmitWarning: {
      label: 'Submit confirmation warning',
      hint: 'This is the text that is shown on the confirmation popup when a step is about to be submitted and the user still has the choice to cancel',
      longAnswer: true
    },
    cancelSubmitButton: {
      label: 'Cancel submit button',
      hint: 'The label for the button which exits the step submission process without submitting'
    },
    confirmSubmitButton: {
      label: 'Confirm submit button',
      hint: 'The label for the button which triggers the actual submission for a step response'
    },
    preDeleteWarning: {
      label: 'Delete confirmation warning',
      hint: 'This is the text that is shown on the confirmation popup when something is about to be deleted and the user still has the choice to cancel',
      longAnswer: true
    },
    cancelDeleteButton: {
      label: 'Cancel deletion button',
      hint: 'The label for the button which exits the deletion process without deleting'
    },
    confirmDeleteButton: {
      label: 'Confirm deletion button',
      hint: 'The label for the button which triggers the actual deletion of something'
    },
    pagedIntroBlurb: {
      label: 'Paged form intro',
      hint: 'This text appears on the first page of every page form to remind the user how to use the paged form works',
      longAnswer: true
    },
    sectionIntroBlurb: {
      label: 'Section form intro',
      hint: 'This text appears at the bottom of the first page of every new section in a paged form to remind the user how sections work',
      longAnswer: true
    },
    nothingToTurnIn: {
      label: 'Nothing to turn in reminder',
      hint: 'This text appears at the bottom of the main or last page of a step which does not need to be submitted',
      longAnswer: true
    },
    eventNotYetSubmitted: {
      label: 'Response draft status',
      hint: 'The label used for responses which are in-progress or otherwise have not yet been submitted for review'
    },
    eventSubmitted: {
      label: 'Response submitted status',
      hint: 'The label used for responses which have been submitted for review'
    },
    eventStudentRequestsReopen: {
      label: 'Requested to reopen status',
      hint: 'The label used when the student has requested to make changes to a submitted response'
    },
    outcomeRequestPending: {
      label: 'Awaiting response status',
      hint: 'The label used any time a learner\'s response is awaiting action from you'
    },
    outcomeRequestApproved: {
      label: 'Request to reopen granted status',
      hint: 'The label used when a request to reopen has been approved and the learner can make changes to the response'
    },
    outcomeRequestDenied: {
      label: 'Request to reopen not granted status',
      hint: 'The label used when a request to reopen has been denied and the learner cannot make changes to the response'
    },
    outcomeSubmissionApproved: {
      label: 'Response approved status',
      hint: 'The label used when you have approved a submitted response and it can no longer be changed in any way'
    },
    outcomeSubmissionDenied: {
      label: 'Response not approved status',
      hint: 'The label used when you have reopened a submitted response for the student to revise and resubmit'
    }
  }
