import { existingStepSchema } from './baseSchemas'
import { MASCOT_VARIANTS_LIST } from './optionLists'
import { advancedPageFormFields, existingStepFormBody } from './baseFormBodies'
import type { EntitySchema } from '~/form-brain2'
import type { AnyPlainOrInputElement, QuestionnaireFormat } from '~/features/questionnaire/types'

export const
  hubStepSchema: EntitySchema = {
    ...existingStepSchema,
    pageHint: {
      fieldName: 'pageHint',
      fieldType: 'STRING',
      visibleWhen: [{ conditionType: 'equals', valueName: 'format', testValue: 'HUB' as QuestionnaireFormat }]
    },
    mascotVariant: {
      fieldName: 'mascotVariant',
      fieldType: 'ENUM',
      visibleWhen: [
        { conditionType: 'equals', valueName: 'format', testValue: 'HUB' as QuestionnaireFormat },
        { conditionType: 'present', valueName: 'pageHint' },
        { conditionType: 'wordLengthAtLeast', valueName: 'pageHint', testValue: 1 }
      ]
    },
    hubPageText: {
      fieldName: 'hubPageText',
      fieldType: 'STRING',
      visibleWhen: [{ conditionType: 'equals', valueName: 'format', testValue: 'HUB' as QuestionnaireFormat }]
    }
  },
  hubStepFormBody: AnyPlainOrInputElement[] = [
    ...existingStepFormBody,
    {
      id: 'hubOptions',
      elementVariety: 'COLLAPSIBLE',
      expandButtonLabel: 'Show Hub Options',
      collapseButtonLabel: 'Hide Hub Options',
      body: [
        {
          id: 'stepHubPageText',
          elementVariety: 'LONG_ANSWER',
          fieldName: 'hubPageText',
          label: 'Hub page text:',
          hint: 'This text will appear on the hub page, along with the home page text for each nested step, when this step is active. Use it for additional instructions or to help learners know what to expect when they start the step.'
        },
        {
          id: 'hubPageHintText',
          elementVariety: 'LONG_ANSWER',
          fieldName: 'pageHint',
          label: 'Hub hint:',
          hint: 'This text will be shown in the mascot\'s speech bubble on the hub page'
        },
        {
          id: 'hubPageMascot',
          elementVariety: 'SELECT_INPUT',
          fieldName: 'mascotVariant',
          hint: 'Which version of the mascot should be shown on the hub page?',
          label: 'Hub mascot:',
          noSort: true,
          blankOption: 'None',
          list: MASCOT_VARIANTS_LIST
        },
        {
          id: 'hubMascotPreview',
          elementVariety: 'SPECIAL',
          specialElementId: 'mascotPreview',
          textKey: 'pageHint',
          variantKey: 'mascotVariant'
        }
      ]
    },
    {
      id: 'hubAdvancedCollapsible',
      elementVariety: 'COLLAPSIBLE',
      expandButtonLabel: 'Show Advanced Options',
      collapseButtonLabel: 'Hide Advanced Options',
      body: advancedPageFormFields.filter(o => !('fieldName' in o) || o.fieldName !== 'parentWorksheetId')
    }
  ]
