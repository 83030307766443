import * as React from 'react'
import styled from 'styled-components'
import { Icon, BasicCircle, BasicTriangle, BasicDoubleArrow } from '~/components/atoms'

export interface SimplePaginationProps {
  pageCount: number
  onPageSelect: (pageNumber: number) => void
  selectedPage?: number
  disabled?: boolean
  showSkip?: boolean
  className?: string
}

const
  SimplePaginationOuter = styled.div<{ pageCount: number, showSkip?: boolean }>`
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    width: ${p => p.pageCount + 5.2 + (p.showSkip ? 5.2 : 0)}em;
    align-items: center;
  `,
  ButtonIcon = styled(Icon)`
    flex: 0 0 1.6em;
  `,
  PageIcon = styled(Icon)<{ $selected: boolean }>`
    flex: 0 0 ${p => p.$selected ? 0.6 : 0.4}em;

    .icon-shadow-target {
      filter: none !important;
    }
  `,
  SimplePagination: React.FC<SimplePaginationProps> = ({ pageCount, onPageSelect, selectedPage, disabled, showSkip, className }) => {
    const
      isFirstPage = selectedPage === 0,
      isLastPage = selectedPage === pageCount - 1,
      disableBack = !!disabled || !selectedPage || isFirstPage,
      disableForward = !!disabled || isLastPage,
      selectPageOnClick = (pageNumber: number): React.MouseEventHandler => () => { onPageSelect(pageNumber) }

    return <SimplePaginationOuter className={className} pageCount={pageCount} showSkip={showSkip} data-cy="simple-pagination">
      {showSkip
        ? <ButtonIcon
          content={BasicDoubleArrow}
          degreesRotation={-180}
          color={disableBack ? 'disabled' : 'back'}
          onClick={disableBack ? undefined : selectPageOnClick(0)}
          accessibleDescription={disableBack ? undefined : 'Go to first page'}
          className="simple-pagination-skip-back" />
        : undefined}

      <ButtonIcon
        content={BasicTriangle}
        degreesRotation={-90}
        onClick={disableBack ? undefined : selectPageOnClick(selectedPage - 1)}
        accessibleDescription={disableBack ? undefined : 'Go to previous page'}
        color={disableBack ? 'disabled' : 'back'}
        className="simple-pagination-back" />

      {[...Array(pageCount)].map((_, i) =>
        <PageIcon
          key={i}
          frameStyle="plain"
          $selected={selectedPage === i}
          content={BasicCircle}
          color={selectedPage !== i
            ? 'disabled'
            : disabled
              ? 'understate'
              : 'back'}
          {...(disabled ? {} : { onClick: selectPageOnClick(i), accessibleDescription: `Go to page ${i}` })}
          className="simple-pagination-page-icon"
        />
      )}

      <ButtonIcon
        content={BasicTriangle}
        degreesRotation={90}
        onClick={disableForward ? undefined : selectPageOnClick((selectedPage ?? -1) + 1)}
        accessibleDescription={disableForward ? undefined : 'Go to next page'}
        color={disableForward ? 'disabled' : 'back'}
        className="simple-pagination-forward" />

      {showSkip
        ? <ButtonIcon
          content={BasicDoubleArrow}
          onClick={disableForward ? undefined : selectPageOnClick(pageCount - 1)}
          accessibleDescription={disableForward ? undefined : 'Go to last page'}
          color={disableForward ? 'disabled' : 'back'}
          className="simple-pagination-skip-forward" />
        : undefined}
    </SimplePaginationOuter>
  }

export default SimplePagination
