import { keyBy } from 'lodash'
import { SystemFieldNames, SystemFieldGroups, getControlGroupTitle, getControlGroupDescription, INDEPENDENT_VARIABLES } from './systemFieldConstants'
import { fieldDetails as fertilizerTimingReference } from './fertilizerTimingReference'
import { fieldDetails as fertilizerTimingValue } from './fertilizerTimingValue'
import { fieldDetails as fertilizerTimingUnit } from './fertilizerTimingUnit'

import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

export interface FixedTimingTreatmentFormat {
  fertilizerTimingReference: 'at planting' | 'at germination'
  fertilizerTimingValue?: undefined
  fertilizerTimingUnit?: undefined
}

export interface ReferencedTreatmentFormat {
  fertilizerTimingReference: string
  fertilizerTimingValue: number
  fertilizerTimingUnit: string
}

export type FertilizerTimingTreatmentFormat = FixedTimingTreatmentFormat | ReferencedTreatmentFormat

const
  independentVariable = INDEPENDENT_VARIABLES.FERTILIZER_TIMING,
  schemaEntry: QField = {
    fieldName: SystemFieldNames.fertilizerTimingControlGroup,
    fieldType: 'SCOPE',
    visibleWhen: [{
      valueName: SystemFieldNames.independentVariable,
      conditionType: 'matches',
      testValue: independentVariable
    }],
    format: keyBy([
      fertilizerTimingReference.schemaEntry,
      fertilizerTimingValue.schemaEntry,
      fertilizerTimingUnit.schemaEntry
    ], 'fieldName')
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.fertilizerTimingControlGroup,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    shouldPrecede: [SystemFieldNames.fertilizerTimingTreatmentGroups],
    isRequired: false,
    title: getControlGroupTitle(independentVariable),
    description: getControlGroupDescription(independentVariable),
    controlGroupFor: independentVariable,
    schemaEntry
  }

export {
  fieldDetails
}
