import React from 'react'
import axios from 'axios'
import { get } from 'lodash'
import { useDispatch } from 'react-redux'
import { baseUrl } from '~/services/api'
import { processAxiosResultForForm } from '~/utils/errorMessages'
import { clientIdReceived, raceOperationCompleted, raceOperationIgnored, raceOperationStarted } from '~/features/session/sessionSlice'
import useAppSelector from './useAppSelector'
import { selectIsWinningOperation, selectWinningOperationId } from '~/features/session/selectors'
import { getState } from '~/services/store'
import { getSemiRandomString } from '~/utils/strings'

interface UseClientId {
  clientId?: string
  isFetchingClientId?: boolean
}

const
  operationKey = 'POST:clients',
  useClientId = (): UseClientId => {
    const
      dispatch = useDispatch(),
      [isFetching, setIsFetching] = React.useState<boolean>(false),
      clientId = useAppSelector(state => state.session.clientId),
      fetchClientId = React.useCallback((): void => {
        if (selectWinningOperationId(operationKey)(getState())) { return }

        const
          operationId = getSemiRandomString(),
          requestInfo = { key: operationKey, operationId }

        setIsFetching(true)
        dispatch(raceOperationStarted(requestInfo))

        processAxiosResultForForm(axios({
          method: 'post',
          url: `${baseUrl}clients`,
          data: { clientType: process.env.REACT_APP_CLIENT_TYPE }
        }))
          .then(({ data }) => {
            const
              newClientId = get(data, 'data.[0].id') as string,
              isWinningOperation = selectIsWinningOperation(requestInfo)(getState())

            if (isWinningOperation && !getState().session.clientId) {
              dispatch(clientIdReceived(newClientId))
              dispatch(raceOperationCompleted(requestInfo))
            } else {
              dispatch(raceOperationIgnored(requestInfo))
            }

            setIsFetching(true)
          })
          .catch(({ errors }) => {
            setIsFetching(false)
          })
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [setIsFetching, dispatch, clientIdReceived])

    React.useEffect(() => {
      if (!isFetching && !clientId) {
        fetchClientId()
      }
    }, [isFetching, fetchClientId, clientId])

    return {
      isFetchingClientId: isFetching,
      clientId
    }
  }

export default useClientId
