import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  EXPERIMENT_LOCATIONS = {
    CONTAINER: 'containers',
    GROUND: 'the ground'
  } as const,
  title = 'Growing location (ground v. container)',
  description = 'The choice of location changes options, language, and graphics throught the experiment so they are more relevant to the learner.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.location,
    fieldType: 'ENUM',
    invalidates: [
      SystemFieldNames.plantingFormat,
      SystemFieldNames.plantSpacingUnit,
      SystemFieldNames.plantSpacingArea,
      SystemFieldNames.containerType
    ],
    options: [
      { optionValue: EXPERIMENT_LOCATIONS.CONTAINER },
      { optionValue: EXPERIMENT_LOCATIONS.GROUND }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.location,
    variableGroup: SystemFieldGroups.WHAT_WHERE,
    isRequired: true, // TODO make optional if possible
    optionValuesReadOnly: true,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails,
  EXPERIMENT_LOCATIONS
}
