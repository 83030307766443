import * as React from 'react'
import styled from 'styled-components'
import { isAllEmpty } from '~/utils/testers'
import { formatValueForInput, inputIdFromBodyElement } from '~/utils/formatters'
import { BaseInput } from '~/components/atoms'
import FormFieldWrapper from '~/components/molecules/FormElements/FormFieldWrapper'

import type { DateInput, TextInput as TextInputElement } from '~/features'
import type { InputProps } from '~/types/forms'
import type { AttrVal } from '~/form-brain2'

const
  permittedTypes = ['button', 'checkbox', 'color', 'date', 'datetime-local', 'email', 'file', 'hidden', 'image', 'month', 'number', 'password', 'radio', 'range', 'reset', 'search', 'submit', 'tel', 'text', 'time', 'url', 'week'],
  Input = styled(BaseInput)<{ $hasError?: boolean | null, $narrow?: boolean, $wide?: boolean }>`
    max-width: 100%;
    // width: 100%;
    width: ${p => p.$narrow ? '5em' : p.$wide ? '18em' : 'auto'};
  `,
  TextInput: React.FC<InputProps & { className?: string }> = (inputProps) => {
    const
      { name, currentValue, updateAttr, errors, showError, isReadOnly, isDisabled, placeholder, pathToAttr } = inputProps,
      bodyElement = inputProps.bodyElement as TextInputElement | DateInput,
      { elementVariety } = bodyElement,
      inputId = inputIdFromBodyElement(bodyElement, pathToAttr),
      onChange: React.ChangeEventHandler<HTMLInputElement> = e => { updateAttr(e.currentTarget.value) },
      inputValue = formatValueForInput(
        currentValue as unknown as AttrVal,
        elementVariety
      ),
      hasError = showError && !isAllEmpty(errors),
      { inputType: givenInputType } = bodyElement as TextInputElement,
      inputType: React.HTMLInputTypeAttribute = name === 'password'
        ? 'password'
        : name === 'username'
          ? 'username'
          : typeof givenInputType === 'string' && permittedTypes.includes(givenInputType)
            ? givenInputType
            : elementVariety === 'DATE_INPUT'
              ? 'date'
              : elementVariety === 'NUMBER_INPUT'
                ? 'number'
                : 'text'

    return <FormFieldWrapper className={inputProps.className} inputProps={inputProps} inputId={inputId}>
      <Input
        $narrow={inputType === 'number' && !!inputValue?.length}
        $wide={inputType === 'username' || inputType === 'email'}
        type={inputType}
        name={name}
        id={inputId}
        $hasError={hasError}
        value={inputValue}
        onChange={onChange}
        readOnly={isReadOnly}
        disabled={isDisabled}
        placeholder={isReadOnly ? '(Blank)' : placeholder}
        data-cy="text-input" />
    </FormFieldWrapper>
  }

export default TextInput
