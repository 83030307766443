import type { EntitySchema } from '~/form-brain2'
import type { AnyBodyElement } from '~/features/questionnaire/types'

export const
  templateSetSchema: EntitySchema = {
    title: {
      fieldName: 'title',
      fieldType: 'STRING',
      requiredWhen: [{ conditionType: 'always' }]
    },
    description: {
      fieldName: 'description',
      fieldType: 'STRING'
    },
    basedOnId: {
      fieldName: 'basedOnId',
      fieldType: 'STRING'
    }
  },
  templateSetFormBody: AnyBodyElement[] = [
    {
      id: 'templateSetName',
      elementVariety: 'SHORT_ANSWER',
      label: 'Title:',
      placeholder: 'Enter a title',
      hint: 'This is the title teachers will see in the group "Start from" dropdown; it should tell them what to expect from the template, which might include indicating the content, complexity, or target age group.',
      fieldName: 'title'
    },
    {
      id: 'templateSetBasedOn',
      elementVariety: 'SELECT_INPUT',
      label: 'Start from:',
      fieldName: 'basedOnId',
      missingValueLabel: '(Selected template unavailable)'
    },
    {
      id: 'templateSetDescription',
      elementVariety: 'LONG_ANSWER',
      label: 'Description:',
      hint: 'Optionally, use this area to give teachers more information that will help them choose between templates.',
      fieldName: 'description'
    }
  ]
