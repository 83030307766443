const
  pxBorderWidth = 5,
  pxTVBorderWidth = pxBorderWidth - 1.5, // triangle vertical border width
  pxTHBorderWidth = pxBorderWidth + 3, // triangle right point border width
  pxVerticalOffset = pxTVBorderWidth,
  // rectangle dimensions //
  pxRectangleWidth = 221.5,
  pxRectangleMinHeight = 67,
  // triangle position //
  pxTriangleLeft = pxRectangleWidth - pxBorderWidth,
  pxTriangleTop = 28.655 - pxBorderWidth,
  // inner triangle dimensions //
  pxTriangleHeight = 17.69,
  pxTriangleWidth = 18.8,
  pxTriangleCenter = pxTriangleHeight / 2,
  // triangle points //
  innerTriangle = {
    topLeft: [0, 0 + pxVerticalOffset],
    bottomLeft: [0, pxTriangleHeight + pxVerticalOffset],
    right: [pxTriangleWidth, pxTriangleCenter + pxVerticalOffset]
  },
  outerTriangle = {
    topLeft: [0, 0],
    bottomLeft: [0, pxTriangleHeight + (2 * pxTVBorderWidth)],
    right: [pxTriangleWidth + pxTHBorderWidth, pxTriangleCenter + pxVerticalOffset]
  },
  // outer triangle dimensions //
  pxTriangleOuterWidth = outerTriangle.right[0],
  pxTriangleOuterHeight = outerTriangle.bottomLeft[1],
  pxOverallWidth = pxRectangleWidth + pxTriangleOuterWidth - pxBorderWidth

export {
  outerTriangle,
  innerTriangle,
  pxBorderWidth,
  pxOverallWidth,
  pxRectangleWidth,
  pxRectangleMinHeight,
  pxTriangleLeft,
  pxTriangleTop,
  pxTriangleOuterHeight,
  pxTriangleOuterWidth
}
