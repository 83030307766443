import { difference, groupBy, indexOf, sortBy } from 'lodash'
import { WorksheetHelpers } from '~/features'
import { scheduleSortFn } from '~/features/experiment/specialSections'
import { isEmptyValue, isNumeric } from '~/utils/testers'

import type { ListEntry, ListSectionElement, ListWorksheet, RepeatingSectionElement } from '~/features'
import type { ListEntrySummary, ListStepSelection } from '~/components/organisms/ListStep/types'
import type { ScheduleActivity } from '~/features/experiment/specialSections/schedule'

interface GetEntrySummariesParams {
  currentSelection: ListStepSelection
  entries: ListEntry[]
  worksheet: ListWorksheet
}

interface SelectionBreakdown {
  selectedIndex: number | undefined
  isAddingOpener: boolean | undefined
  isAddingCloser: boolean | undefined
  isAddingRepeater: boolean | undefined
}

export const
  getSelectionBreakdown = (currentSelection: ListStepSelection): SelectionBreakdown => {
    let selectedIndex, isAddingOpener, isAddingRepeater, isAddingCloser

    if (!currentSelection) {
      // no op
    } else if ('entryIndex' in currentSelection) {
      selectedIndex = currentSelection.entryIndex
    } else if (currentSelection.segment === 'LIST_OPENER_SECTION') {
      isAddingOpener = true
    } else if (currentSelection.segment === 'LIST_CLOSER_SECTION') {
      isAddingCloser = true
    } else {
      isAddingRepeater = true
    }

    return { selectedIndex, isAddingOpener, isAddingCloser, isAddingRepeater }
  },
  getElegibleRepeatingSections = (worksheet: ListWorksheet, entries: ListEntry[]): RepeatingSectionElement[] => {
    return worksheet.repeatingSections.filter(o =>
      isEmptyValue(o.maximumLength) ||
      entries.filter(e => e.sectionId === o.id).length < (o.maximumLength as number)
    )
  },
  getIncompleteRepeatingSections = (worksheet: ListWorksheet, entries: ListEntry[]): ListSectionElement[] => {
    return worksheet.repeatingSections.filter(o =>
      !isEmptyValue(o.minimumLength) &&
      entries.filter(e => e.sectionId === o.id).length < (o.minimumLength as number)
    )
  },
  getEntrySummaries = ({ currentSelection, entries, worksheet }: GetEntrySummariesParams): ListEntrySummary[] => {
    let
      openerSummary,
      closerSummary,
      newRepeaterSummary,
      repeaterSummaries = []
    const
      { selectedIndex, isAddingOpener, isAddingCloser, isAddingRepeater } = getSelectionBreakdown(currentSelection),
      sectionsById = WorksheetHelpers.getSectionsById(worksheet),
      openingEntry = entries.find(o => sectionsById[o.sectionId].elementVariety === 'LIST_OPENER_SECTION'),
      closingEntry = entries.find(o => sectionsById[o.sectionId].elementVariety === 'LIST_CLOSER_SECTION'),
      repeatingEntries = difference(entries, [openingEntry, closingEntry]) as ListEntry[],
      elegibleRepeatingSections = getElegibleRepeatingSections(worksheet, entries),
      incompleteRepeatingSections = getIncompleteRepeatingSections(worksheet, entries),
      closingSectionAvailable = incompleteRepeatingSections.length === 0 && worksheet.closingSection,
      entriesBySectionId = groupBy(entries, 'sectionId')

    if (!!isAddingOpener || openingEntry) {
      const actualIndex = openingEntry ? indexOf(entries, openingEntry) : undefined

      openerSummary = {
        isSelected: !!isAddingOpener || selectedIndex === actualIndex,
        section: worksheet.openingSection,
        entry: openingEntry,
        nthOfKind: 0,
        actualIndex
      }
    }

    const
      repeaters = (repeatingEntries ?? []).map((entry, i) => {
        const
          actualIndex = indexOf(entries, entry),
          section = sectionsById[entry.sectionId],
          sortByField = (section as RepeatingSectionElement).sortByField,
          sortOrder = (section as RepeatingSectionElement).sortOrder,
          rawSortValue = worksheet.specialLayoutType === 'SCHEDULE'
            ? scheduleSortFn(entry as unknown as ScheduleActivity)
            : sortByField
              ? entry[sortByField]
              : undefined

        return {
          entry,
          sortBy: sortOrder === 'numerically' && isNumeric(rawSortValue)
            ? parseFloat(rawSortValue as string)
            : rawSortValue,
          isSelected: !isEmptyValue(selectedIndex) && selectedIndex === actualIndex,
          section,
          actualIndex
        }
      }),
      sortedRepeatersA = repeaters.every(o => isEmptyValue(o.sortBy))
        ? repeaters
        : worksheet.sortDirection === 'descending'
          ? sortBy(repeaters, 'sortBy').reverse()
          : sortBy(repeaters, 'sortBy'),
      sortedRepeaters = worksheet.reverseStartAndEnd
        ? sortedRepeatersA.reverse()
        : sortedRepeatersA

    repeaterSummaries = sortedRepeaters.map(e => {
      const entriesSameSection = sortedRepeatersA.filter(o => o.section.id === e.section.id)

      return {
        ...e,
        nthOfKind: Math.max(0, indexOf(entriesSameSection, e))
      }
    })

    if (isAddingRepeater) {
      const section = elegibleRepeatingSections.length === 1 ? elegibleRepeatingSections[0] : undefined

      newRepeaterSummary = {
        isSelected: true,
        nthOfKind: section ? (entriesBySectionId[section.id]?.length ?? 0) : undefined,
        section,
        availableSections: elegibleRepeatingSections
      }
    }

    if (!!closingSectionAvailable || !!isAddingCloser || closingEntry) {
      const actualIndex = closingEntry ? indexOf(entries, closingEntry) : undefined

      closerSummary = {
        isSelected: !!isAddingCloser || (!isEmptyValue(actualIndex) && selectedIndex === actualIndex),
        section: worksheet.closingSection,
        entry: closingEntry,
        nthOfKind: 0,
        actualIndex
      }
    }

    const entrySummaries = worksheet.reverseStartAndEnd
      ? [
          closerSummary,
          newRepeaterSummary,
          ...repeaterSummaries,
          openerSummary
        ]
      : [
          openerSummary,
          ...repeaterSummaries,
          newRepeaterSummary,
          closerSummary
        ]

    return entrySummaries.filter(o => o) as ListEntrySummary[]
  }
