import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Timing interval between measurements',
  description = 'This allows learners to specify how often they will take measurements. This is used to build a schedule for the experiment and to calculate information like the total number of measurements that will be taken during the experiment.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.measurementRepeatValue,
    fieldType: 'NUMBER'
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.measurementRepeatValue,
    variableGroup: SystemFieldGroups.SCHEDULE,
    shouldFollow: [SystemFieldNames.measurementTimingScheme],
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
