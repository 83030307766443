import { toLower } from 'lodash'
import { ICON_CONTENT_LIST } from './optionLists'
import { InputElementVarietiesWithPlaceholder, InputElementVarietiesWithUnit } from '~/features/questionnaire/types'
import { ELEMENT_TYPES } from '~/utils/strings'
import { LONG_ANSWER_ROWS, SHORT_ANSWER_ROWS } from '~/components/molecules/FormElements/Textarea'
import { BASE_INPUT_FIELDS_IN_MAD_LIBS } from './elementSchemas'

import type { AnyPlainOrInputElement, CollectionElement, MultiTextInput, TextInput } from '~/features/questionnaire/types'

const
  idElement: TextInput = {
    id: 'elementId',
    fieldName: 'id',
    label: 'ID',
    elementVariety: 'TEXT_INPUT'
  },
  invalidatesElement: MultiTextInput = {
    id: 'element-invalidates',
    fieldName: 'invalidates',
    elementVariety: 'MULTI_TEXT_INPUT',
    label: 'Clear answers on change',
    showHintAlways: true,
    hint: 'Questions/answers selected here will be erased when the answer to this question is changed.'
  },
  visibilityConditionElement: CollectionElement = {
    id: 'visibleWhenCollection',
    fieldName: 'visibleWhen',
    elementVariety: 'COLLECTION',
    label: 'Visibility',
    labelAsHeading: true,
    emptyListelement: {
      id: 'visibleWhen',
      elementVariety: 'INSTRUCTIONS',
      paragraphs: ['Always visible']
    },
    firstEntryLeadIn: 'Show when',
    noInitialEntry: true,
    understatedAdder: true,
    editInline: true,
    offsetEntries: true,
    entryNamePlural: 'conditions',
    entryNameSingular: 'condition',
    useAlternateEntryRenderer: 'conditionList',
    formBody: []
  },
  // validWhenElement: CollectionElement = {
  //   id: 'validWhenCollection',
  //   fieldName: 'validWhen',
  //   elementVariety: 'COLLECTION',
  //   label: 'Validity',
  //   labelAsHeading: true,
  //   emptyListelement: {
  //     id: 'validWhenBlank',
  //     elementVariety: 'INSTRUCTIONS',
  //     paragraphs: ['Always valid. Any answer will be accepted.']
  //   },
  //   firstEntryLeadIn: 'Valid when',
  //   noInitialEntry: true,
  //   understatedAdder: true,
  //   editInline: true,
  //   entryNamePlural: 'criteria',
  //   entryNameSingular: 'criteria',
  //   useAlternateEntryRenderer: 'conditionList',
  //   formBody: []
  // },
  requiredWhenElement: CollectionElement = {
    id: 'requiredWhenCollection',
    fieldName: 'requiredWhen',
    elementVariety: 'COLLECTION',
    label: 'Requirement',
    labelAsHeading: true,
    emptyListelement: {
      id: 'requiredWhen',
      elementVariety: 'INSTRUCTIONS',
      paragraphs: ['Not required. May be left blank.']
    },
    firstEntryLeadIn: 'Required when',
    noInitialEntry: true,
    understatedAdder: true,
    editInline: true,
    offsetEntries: true,
    entryNamePlural: 'conditions',
    entryNameSingular: 'condition',
    useAlternateEntryRenderer: 'conditionList',
    formBody: []
  },
  readOnlyWhenElement: CollectionElement = {
    id: 'readOnlyWhenCollection',
    fieldName: 'readOnlyWhen',
    elementVariety: 'COLLECTION',
    label: 'Read Only',
    labelAsHeading: true,
    emptyListelement: {
      id: 'readOnlyWhen',
      elementVariety: 'INSTRUCTIONS',
      paragraphs: ['Not read-only. Can always be edited unless the step has been submitted.']
    },
    firstEntryLeadIn: 'Read-only when',
    noInitialEntry: true,
    understatedAdder: true,
    editInline: true,
    offsetEntries: true,
    entryNamePlural: 'conditions',
    entryNameSingular: 'condition',
    useAlternateEntryRenderer: 'conditionList',
    formBody: []
  }

export const
  blurbFormBody: AnyPlainOrInputElement[] = [
    {
      id: 'blurbtitle',
      fieldName: 'title',
      elementVariety: 'SHORT_ANSWER',
      label: 'Title'
    },
    {
      id: 'blurbparagraphs',
      fieldName: 'paragraphs',
      elementVariety: 'PARAGRAPHS',
      label: 'Content'
    },
    {
      id: 'blurbiconContent',
      fieldName: 'iconContent',
      elementVariety: 'SELECT_INPUT',
      label: 'Icon',
      customOption: 'Custom Text',
      list: ICON_CONTENT_LIST
    },
    {
      id: 'blurbiconColor',
      fieldName: 'iconColor',
      elementVariety: 'COLOR_PICKER',
      label: 'Icon Color',
      contentKey: 'iconContent'
    },
    {
      /** TODO test */
      id: 'blurbwithBackground',
      fieldName: 'withBackground',
      elementVariety: 'CHECKBOX_INPUT',
      label: 'Add contrasting background behind text'
    },
    // {
    //   id: 'blurbvisibleWhen',
    //   fieldName: 'visibleWhen',
    //   elementVariety: 'COLLECTION',
    //   formBody: [],
    //   entryNameSingular: ''
    // }
    idElement
  ],
  blurbFormBodyWithLocation: AnyPlainOrInputElement[] = [
    ...blurbFormBody.slice(0, -1),
    {
      id: 'blurblocation',
      fieldName: 'location',
      elementVariety: 'SELECT_INPUT',
      label: 'Blurb position',
      blankOption: 'Automatic',
      hint: 'If there is space around the blurb, where should the blurb be located in the space?',
      list: [{
        optionValue: 'bottom',
        optionLabel: 'Bottom of space'
      }, {
        optionValue: 'top',
        optionLabel: 'Top of space'
      }, {
        optionValue: 'center',
        optionLabel: 'Center of space'
      }]
    },
    visibilityConditionElement,
    ...blurbFormBody.slice(-1)
  ],
  headingFormBody: AnyPlainOrInputElement[] = [
    {
      id: 'heading-text',
      fieldName: 'text',
      placeholder: `Type ${toLower(ELEMENT_TYPES.HEADING)} here`,
      elementVariety: 'TEXT_INPUT'
    },
    visibilityConditionElement,
    idElement
  ],
  instructionsFormBody: AnyPlainOrInputElement[] = [
    {
      id: 'instructions-paragraphs',
      fieldName: 'paragraphs',
      placeholder: `Type ${toLower(ELEMENT_TYPES.INSTRUCTIONS)} here`,
      elementVariety: 'PARAGRAPHS'
    },
    visibilityConditionElement,
    idElement
  ],
  subheadingFormBody: AnyPlainOrInputElement[] = [
    {
      id: 'subheading-text',
      fieldName: 'text',
      placeholder: `Type ${toLower(ELEMENT_TYPES.SUBHEADING)} here`,
      elementVariety: 'TEXT_INPUT'
    },
    visibilityConditionElement,
    idElement
  ],
  textFormBody: AnyPlainOrInputElement[] = [
    {
      id: 'text-text',
      fieldName: 'text',
      placeholder: 'Type text here',
      elementVariety: 'TEXT_INPUT'
    },
    {
      id: 'text-is-bold',
      fieldName: 'isBold',
      label: 'Bold',
      elementVariety: 'CHECKBOX_INPUT'
    },
    visibilityConditionElement,
    idElement
  ],
  fieldsetFormBody: AnyPlainOrInputElement[] = [
    {
      id: 'fieldset-element-body',
      fieldName: 'body',
      elementVariety: 'COLLECTION',
      label: 'Content',
      labelAsHeading: true,
      noInitialEntry: true,
      entryNamePlural: 'content',
      entryNameSingular: 'content',
      offsetEntries: true,
      useAlternateEntryRenderer: 'bodyElementList',
      formBody: []
    },
    {
      id: 'fieldset-enclose-content',
      fieldName: 'encloseContent',
      elementVariety: 'CHECKBOX_INPUT',
      label: 'Draw a box around the content to visually separate it from surrounding sections'
    },
    {
      id: 'fieldset-offset-content',
      fieldName: 'offsetContent',
      elementVariety: 'CHECKBOX_INPUT',
      label: 'Shift the content to the right to visually separate it from surrounding elements'
    },
    visibilityConditionElement,
    idElement
  ],
  _fieldNameInput: TextInput = {
    id: 'element-field-name',
    fieldName: 'fieldName',
    elementVariety: 'TEXT_INPUT',
    label: 'Question/Answer ID',
    requiredWhen: [{ conditionType: 'always' }],
    hint: 'The name you will use if you need to refer to the response to this question elsewhere in the project; letters and numbers only. This allows you to change the wording of the question here without having to change it everywhere it is used.'
  },
  _baseInputBody: AnyPlainOrInputElement[] = [
    {
      id: 'element-label',
      fieldName: 'label',
      elementVariety: 'SHORT_ANSWER',
      label: 'Question (or label)'
    },
    _fieldNameInput,
    {
      id: 'element-hint',
      fieldName: 'hint',
      elementVariety: 'SHORT_ANSWER',
      label: 'Hint',
      hint: 'The help text that goes with the question - just like this help text!'
    },
    // TODO validWhen, requiredWhen, recommendedWhen, readOnlyWhen, invalidates, hideError(?)
    {
      id: 'element-show-hint-always',
      fieldName: 'showHintAlways',
      elementVariety: 'CHECKBOX_INPUT',
      label: 'Always show the hint for this content (hide question icon)',
      visibleWhen: [{ conditionType: 'present', valueName: 'hint' }]
    },
    {
      id: 'element-placeholder',
      fieldName: 'placeholder',
      elementVariety: 'SHORT_ANSWER',
      label: 'Placeholder',
      hint: 'Override the project\'s default placeholder (appears in the text area when the answer is empty) for this type of question',
      placeholder: 'See project settings for defaults',
      visibleWhen: [{ conditionType: 'anyOf', valueName: 'elementVariety', testValue: InputElementVarietiesWithPlaceholder }]
    },
    {
      id: 'element-unit',
      fieldName: 'unit',
      elementVariety: 'TEXT_INPUT',
      label: 'Unit',
      hint: 'A unit, like ounces or °F, that appears after the text area',
      visibleWhen: [{ conditionType: 'anyOf', valueName: 'elementVariety', testValue: InputElementVarietiesWithUnit }]
    }
    // { // Currently system only
    //   id: 'element-hide-label',
    //   fieldName: 'hideLabel',
    //   elementVariety: 'CHECKBOX_INPUT',
    //   label: 'Hide label in form (it may still show in summary or in messages when information is missing)'
    // },
    // { // Currently system only
    //   id: 'element-hide-unit',
    //   fieldName: 'hideUnit',
    //   elementVariety: 'CHECKBOX_INPUT',
    //   label: 'Hide unit'
    // }
  ],
  textInputFormBody: AnyPlainOrInputElement[] = [
    ..._baseInputBody,
    visibilityConditionElement,
    requiredWhenElement,
    readOnlyWhenElement,
    invalidatesElement,
    idElement
  ],
  customTextInputFormBody: AnyPlainOrInputElement[] = [
    ..._baseInputBody,
    {
      id: 'collection-advanced',
      elementVariety: 'COLLAPSIBLE',
      collapseButtonLabel: 'Hide Advanced Settings',
      expandButtonLabel: 'Show Advanced Settings',
      body: [
        {
          id: 'element-num-rows',
          fieldName: 'numRows',
          elementVariety: 'NUMBER_INPUT',
          label: 'Box height (number of lines)',
          unit: 'lines',
          showHintAlways: true,
          hint: `You can make the default text area taller or shorter by changing this number; default for short answer is ${SHORT_ANSWER_ROWS}, for long answer ${LONG_ANSWER_ROWS}`
        },
        {
          id: 'element-num-columns',
          fieldName: 'numCols',
          elementVariety: 'NUMBER_INPUT',
          label: 'Box width (number of characters)',
          showHintAlways: true,
          unit: 'characters',
          hint: 'You can make the text area wider or narrower by changing this number',
          placeholder: 'Default: full width'
        }
      ]
    },
    visibilityConditionElement,
    requiredWhenElement,
    readOnlyWhenElement,
    invalidatesElement,
    idElement
  ],
  _baseMultipleChoiceFormBody: AnyPlainOrInputElement[] = [
    ..._baseInputBody.filter(o => !('fieldName' in o) || o.fieldName !== 'placeholder'),
    {
      id: 'select-input-element-list',
      fieldName: 'list',
      elementVariety: 'COLLECTION',
      label: 'Choices',
      formBody: [
        {
          id: 'select-input-list-value',
          elementVariety: 'TEXT_INPUT',
          fieldName: 'optionValue',
          placeholder: 'Value here',
          label: 'Value'
        },
        {
          id: 'select-input-list-value',
          elementVariety: 'SHORT_ANSWER',
          fieldName: 'optionLabel',
          placeholder: 'Label here (optional)',
          label: 'Label (if different than value)',
          visibleWhen: [{ conditionType: 'present', valueName: 'optionValue' }]
        },
        {
          ...visibilityConditionElement,
          hideLabel: true
        }
      ],
      entryNameSingular: 'choice',
      entryNamePlural: 'choices',
      offsetEntries: true
      // TODO
    },
    {
      id: 'select-element-no-sort',
      fieldName: 'noSort',
      elementVariety: 'CHECKBOX_INPUT',
      label: 'Preserve list order (by default, choice lists are sorted alphabetically)'
    },
    {
      id: 'select-element-hide-when-empty',
      fieldName: 'hideWhenListEmpty',
      elementVariety: 'CHECKBOX_INPUT',
      label: 'Hide this question when there are no choices available'
    }
  ],
  _multipleChoiceCustomOptions: AnyPlainOrInputElement[] = [
    {
      id: 'select-element-custom-option',
      fieldName: 'customOption',
      elementVariety: 'TEXT_INPUT',
      label: 'Label for custom/other choice',
      placeholder: 'Default: no custom/other option',
      hint: 'Add a label here to allow learners to type an answer which is not included in the choices, for example: "Add your own" or "Something else"'
    },
    {
      id: 'select-element-custom-placeholder',
      fieldName: 'customPlaceholder',
      elementVariety: 'TEXT_INPUT',
      label: 'Placeholder',
      placeholder: 'Default: set by project',
      hint: 'Add text here to use a different placeholder than the project\'s default for this type of question (see project settings, labels to change defaults)'
    }
  ],
  selectInputFormBody: AnyPlainOrInputElement[] = [
    ..._baseMultipleChoiceFormBody,
    {
      id: 'select-element-blank-ption',
      fieldName: 'blankOption',
      elementVariety: 'TEXT_INPUT',
      label: 'Label for blank/none choice',
      placeholder: 'Default: no blank/none option',
      hint: 'Add a label here to include a blank/none option, for example: "None of the above", "Did not use", or "N/A"'
    },
    ..._multipleChoiceCustomOptions,
    visibilityConditionElement,
    requiredWhenElement,
    readOnlyWhenElement,
    invalidatesElement,
    idElement
  ],
  multiSelectFormBody: AnyPlainOrInputElement[] = [
    ..._baseMultipleChoiceFormBody,
    {
      id: 'multi-select-select-none',
      fieldName: 'selectNoneOption',
      elementVariety: 'TEXT_INPUT',
      label: 'Label for "select none" choice',
      hint: 'Add text here to include a choice equivalent to selecting none',
      placeholder: 'Default: no select none'
    },
    {
      id: 'multi-select-select-all',
      fieldName: 'selectAllOption',
      elementVariety: 'TEXT_INPUT',
      label: 'Label for "select all" choice',
      hint: 'Add text here to include a choice equivalent to selecting all',
      placeholder: 'Default: no select all'
    },
    ..._multipleChoiceCustomOptions,
    visibilityConditionElement,
    requiredWhenElement,
    readOnlyWhenElement,
    invalidatesElement,
    idElement
  ],
  madLibElementFormBody: AnyPlainOrInputElement[] = [
    ..._baseInputBody.filter(o => 'fieldName' in o && o.fieldName && BASE_INPUT_FIELDS_IN_MAD_LIBS.includes(o.fieldName)),
    {
      id: 'mad-lib-element-form-body',
      fieldName: 'segments',
      elementVariety: 'SPECIAL',
      specialElementId: 'madLibSegments'
    },
    visibilityConditionElement,
    requiredWhenElement,
    readOnlyWhenElement,
    idElement
  ],
  collectionElementFormBody: AnyPlainOrInputElement[] = [
    /*
    TODO?
    'hideLabel',
    'requiredWhen'
    */
    {
      id: 'element-label',
      fieldName: 'label',
      elementVariety: 'SHORT_ANSWER',
      label: 'List title (or question)'
    },
    _fieldNameInput,
    {
      id: 'collection-entry-name-singular',
      fieldName: 'entryNameSingular',
      elementVariety: 'TEXT_INPUT',
      label: 'Entry label',
      placeholder: 'What is this a list of?',
      hint: 'This is the word used in the add button "Add a (Your Label Here)"'
    },
    {
      id: 'collection-entry-name-plural',
      elementVariety: 'TEXT_INPUT',
      fieldName: 'entryNamePlural',
      label: 'Plural entry label',
      placeholder: 'What is this a list of?',
      hint: 'This is the plural of the entry label (above). It is used in a variety of places for example "Add 3 more (Your Plural Heres) before continuing".'
    },
    {
      id: 'collection-element-form-body',
      fieldName: 'formBody',
      elementVariety: 'COLLECTION',
      label: 'Content for List Items',
      labelAsHeading: true,
      noInitialEntry: true,
      entryNamePlural: 'questions',
      entryNameSingular: 'question',
      offsetEntries: true,
      useAlternateEntryRenderer: 'bodyElementList',
      formBody: []
    },
    visibilityConditionElement,
    {
      id: 'collection-advanced',
      elementVariety: 'COLLAPSIBLE',
      collapseButtonLabel: 'Hide Advanced Settings',
      expandButtonLabel: 'Show Advanced Settings',
      body: [
        {
          id: 'collection-carry-over-fields',
          fieldName: 'carryOverFields',
          elementVariety: 'MULTI_SELECT_INPUT',
          noSort: true,
          label: 'When a new item is added to the list, which answers (if any) should be copied from the previous item?',
          hint: 'This can be used to make it faster to enter long lists, such as data, by carrying over information (like a date or location) that is likely to be the similar from the most recent entry'
        },
        // TODO validate array length and add error message with entryNamePlural
        // {
        //   id: 'collection-entry-minimum-length',
        //   fieldName: 'minimumLength',
        //   elementVariety: 'NUMBER_INPUT',
        //   label: 'Minimum length',
        //   placeholder: 'Enter a number (optional)',
        //   hint: 'Enter a number here to require learners to add a certain number of entries to this list'
        // },
        // {
        //   id: 'collection-entry-maximum-length',
        //   fieldName: 'maximumLength',
        //   elementVariety: 'NUMBER_INPUT',
        //   label: 'Maximum length',
        //   placeholder: 'Enter a number (optional)',
        //   hint: 'Enter a number here to limit the number entries a learner can add to this list'
        // },
        {
          id: 'collection-settings-checkbox-group',
          elementVariety: 'HEADING',
          text: 'List Format Settings'
        },
        {
          id: 'collection-edit-inline',
          fieldName: 'editInline',
          elementVariety: 'CHECKBOX_INPUT',
          label: 'Use compact layout for list (best for data entry and short questions)'
        },
        {
          id: 'collection-enclose-entries',
          fieldName: 'encloseEntries',
          elementVariety: 'CHECKBOX_INPUT',
          label: 'Draw a box around each list item to visually separate them (best for long entry forms)'
        },
        {
          id: 'collection-offset-entries',
          fieldName: 'offsetEntries',
          elementVariety: 'CHECKBOX_INPUT',
          label: 'Shift the entire list to the right to visually separate it from surrounding elements'
        },
        {
          id: 'collection-no-initial',
          fieldName: 'noInitialEntry',
          elementVariety: 'CHECKBOX_INPUT',
          label: 'Hide the entry form when the list is blank (by default one blank entry is shown)'
        },
        {
          id: 'collection-element-leader',
          fieldName: 'leader',
          elementVariety: 'SCOPE',
          label: 'Lead-In Element',
          showLabelWithAdder: true,
          noInitialEntry: true,
          useAlternateEntryRenderer: 'bodyElementList',
          formBody: []
        }
        // TODO check that readOnlyWhen is applied correctly to collections, then add here
      ]
    },
    idElement
  ],
  scopeElementFormBody: AnyPlainOrInputElement[] = [
    {
      id: 'element-label',
      fieldName: 'label',
      elementVariety: 'SHORT_ANSWER',
      label: 'Group title or question'
    },
    _fieldNameInput,
    {
      id: 'scope-element-form-body',
      fieldName: 'formBody',
      elementVariety: 'COLLECTION',
      label: 'Content for Group',
      labelAsHeading: true,
      noInitialEntry: true,
      entryNamePlural: 'questions',
      entryNameSingular: 'question',
      offsetEntries: true,
      useAlternateEntryRenderer: 'bodyElementList',
      formBody: []
    },
    visibilityConditionElement,
    readOnlyWhenElement,
    {
      id: 'scope-advanced',
      elementVariety: 'COLLAPSIBLE',
      collapseButtonLabel: 'Hide Advanced Settings',
      expandButtonLabel: 'Show Advanced Settings',
      body: [
        {
          id: 'scope-settings-checkbox-group',
          elementVariety: 'HEADING',
          text: 'Group Format Settings'
        },
        {
          id: 'scope-element-hide-label',
          fieldName: 'hideLabel',
          elementVariety: 'CHECKBOX_INPUT',
          label: 'Hide title in form (it may still show in summary or in messages when information is missing)'
        },
        {
          id: 'scope-as-row',
          fieldName: 'asRow',
          elementVariety: 'CHECKBOX_INPUT',
          label: 'Show content grouped into one question, similar to a fill-in-the-blank'
        },
        {
          id: 'scope-offset-content',
          fieldName: 'offsetContent',
          elementVariety: 'CHECKBOX_INPUT',
          label: 'Shift contents to the right to visually separate it from surrounding elements'
        },
        {
          id: 'collection-no-initial',
          fieldName: 'noInitialEntry',
          elementVariety: 'CHECKBOX_INPUT',
          label: 'Start by showing "add" button instead of blank form (best for optional sections)'
        }
      ]
    },
    idElement
  ]
