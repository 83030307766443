import { times } from 'lodash'
import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { BotanicalDense, BotanicalLinear, BotanicalSparse, BotanicalForked, DashedLine } from '~/components/atoms'

export type OverlayColorKey = 'containerAccentBg' | 'bodyBg' | 'containerBg' | 'containerAsideBg' | 'containerDarkBg'

interface BotanicalOverlayProps {
  colorKey?: OverlayColorKey
  className?: string
}

interface BotanicalBackgroundProps {
  orientation?: 'row' | 'column'
  colorKey?: OverlayColorKey
  opacity?: number
  className?: string
}

const
  overlayAspectRatio = 1.218,
  BotanicalOverlayOuter = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: ${overlayAspectRatio};

    background: transparent;
  `,
  StyledBotanicalDenseTop = styled(BotanicalDense)`
    position: absolute;
    aspect-ratio: 0.612;
    width: 14.50%;
    left: 8%;
    top: 3.46%;

    transform: rotate(43.66deg);
  `,
  StyledBotanicalDenseBottom = styled(BotanicalDense)`
    position: absolute;
    aspect-ratio: 0.612;
    width: 14.50%;
    left: 21.98%;
    bottom: 0%;

    transform: rotate(-32.07deg);
  `,
  StyledBotanicalSparse = styled(BotanicalSparse)`
    position: absolute;
    aspect-ratio: 0.562;
    width: 17.63%;
    right: 13.31%;
    bottom: 5.25%;

    transform: rotate(-22.97deg);
  `,
  StyledBotanicalLinearBottom = styled(BotanicalLinear)`
    position: absolute;
    aspect-ratio: 0.247;
    width: 6.16%;
    left: 4.08%;
    bottom: 15.31%;

    transform: scaleY(-1) rotate(0deg);
  `,
  StyledBotanicalLinearTop = styled(BotanicalLinear)`
    position: absolute;
    aspect-ratio: 0.247;
    width: 6.16%;
    right: 2.20%;
    top: 4.86%;

    transform: scaleY(1) rotate(166.66deg);
  `,
  StyledBotanicalForked = styled(BotanicalForked)`
    position: absolute;
    aspect-ratio: 0.555;
    width: 12.94%;
    right: 22.86%;
    top: 4.86%;
    bottom: 59.64%;

    transform: rotate(25.06deg);
  `,
  PositionedDashedLine = styled(DashedLine)<{ $index: number }>`
    position: absolute;
    top: ${p => p.$index * 7.23}%;
  `,
  BotanicalOverlay: React.FC<BotanicalOverlayProps> = ({ colorKey, className }) => {
    const
      theme = useTheme(),
      overlayColor = theme.colors[colorKey ?? 'containerAccentBg'],
      lines: JSX.Element[] = []

    times(14, (index) => {
      lines[index] = <PositionedDashedLine key={index} $index={index} color={overlayColor} spacing={3} />
    })

    return <BotanicalOverlayOuter className={className} data-cy="botanical-overlay">
      {lines}
      <StyledBotanicalDenseTop color={overlayColor} />
      <StyledBotanicalSparse color={overlayColor} />
      <StyledBotanicalLinearBottom color={overlayColor} />
      <StyledBotanicalDenseBottom color={overlayColor} />
      <StyledBotanicalLinearTop color={overlayColor} />
      <StyledBotanicalForked color={overlayColor} />
    </BotanicalOverlayOuter>
  },
  BotanicalRepeatWrapper = styled.div<{ $orientation: 'row' | 'column', $opacity: number }>`
    display: flex;
    flex-direction: ${p => p.$orientation};
    overflow: hidden;
    opacity: ${p => p.$opacity};

    > * {
      flex: 1 0 ${p => p.theme.pxMaxContentWidth / 2}px;
    }
  `,
  BotanicalBackground: React.FC<BotanicalBackgroundProps> = ({ orientation = 'row', colorKey = 'containerAccentBg', opacity = 1, className }) => {
    return <BotanicalRepeatWrapper className={className} $orientation={orientation} $opacity={opacity}>
      <BotanicalOverlay colorKey={colorKey} />
      <BotanicalOverlay colorKey={colorKey} />
    </BotanicalRepeatWrapper>
  }

export default BotanicalOverlay
export { BotanicalBackground, overlayAspectRatio }
