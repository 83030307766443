import React from 'react'
import FormBodyForm from '../Body/FormBodyForm'

import type { AnyPlainOrInputElement, Experiment, ListWorksheet, PagedSectionElement, PagedWorksheet } from '~/features'

interface Props {
  experiment: Experiment
  worksheet: PagedWorksheet
  allWorksheets: Array<ListWorksheet | PagedWorksheet>
  section: PagedSectionElement
  updateWorksheet: (updatedWorksheet: PagedWorksheet) => void
  selectedPage: number
  setSelectedPage: (page?: number) => void
}

const
  PageBodyForm: React.FC<Props> = ({ experiment, worksheet, allWorksheets, section, updateWorksheet, selectedPage, setSelectedPage }) => {
    const
      currentPage = section.pages[selectedPage],
      updateBody = (newBody: AnyPlainOrInputElement[]): void => {
        const
          updatedSection: PagedSectionElement = {
            ...section,
            pages: section.pages.map((p, i) => (
              i === selectedPage
                ? { ...p, body: newBody ?? [] }
                : p
            ))
          }

        updateWorksheet({
          ...worksheet,
          sections: worksheet.sections.map(s => (
            s.id === section.id
              ? updatedSection
              : s
          ))
        })
      }

    return <FormBodyForm key={currentPage.id} currentWorksheetId={worksheet.id} bodyElements={currentPage.body} {...{ updateBody, allWorksheets }} />
  }

export default PageBodyForm
