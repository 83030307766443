import * as React from 'react'
import styled from 'styled-components'
import { cloneDeep, set } from 'lodash'
import { FormCollection, FormContext } from '~/form-brain2'
import { isAllEmpty } from '~/utils/testers'
import { Button, FormLabel } from '~/components/atoms'
import InfoPanel from '~/components/molecules/InfoPanel'
import CollectionRenderer from '~/components/molecules/FormRenderers/CollectionRenderer'
import ListMaker from '~/components/molecules/FormElements/ListMaker'
import ConditionListItem from './ConditionListItem'

import type { CollectionOptions, EntityObject } from '~/form-brain2'
import type { InputProps } from '~/types/forms'
import type { CollectionElement, ListOption, MultiTextInput, VisibilityCondition } from '~/features'
import type { SystemFieldDetails } from '~/features/experiment/systemFields'

interface CollectionVariantProps {
  bodyElement: CollectionElement
  systemField: SystemFieldDetails
  systemFieldCollectionValues: ListOption[] | VisibilityCondition[]
  inputProps?: undefined
}

interface ListVariantProps {
  bodyElement: MultiTextInput
  systemField: SystemFieldDetails
  systemFieldCollectionValues: string[]
  inputProps: InputProps
}

type Props = CollectionVariantProps | ListVariantProps

const
  StyledInfoPanel = styled(InfoPanel)`
    background-color: ${p => p.theme.colors.bodyBg};
    padding: ${p => p.theme.emSmallSpacing * 1.5}em;
    padding-bottom: ${p => p.theme.emBaseSpacing}em;
  `,
  Outer = styled.div<{ $offset?: boolean }>`
    ${p => p.$offset
      ? `
        padding-left: ${p.theme.emBaseSpacing}em;
        padding-bottom: ${p.theme.emBaseSpacing}em;
        padding-top: ${p.theme.emSmallSpacing}em;
        border-left: 2px dashed ${p.theme.colors.borderAlt};
        
        margin-left: ${p.theme.emSmallSpacing * 0.66}em;

        + [data-cy="collection-renderer"] {
          margin-top: 0px;
          padding-top: 1px;
        }
      `
      : ''}
  `,
  SystemFieldDefaultCollection: React.FC<Props> = ({ bodyElement, systemField, inputProps, systemFieldCollectionValues }) => {
    const
      { formOptions, values, updateValues } = React.useContext(FormContext),
      name = bodyElement.fieldName,
      ownValues = values[name],
      hasOverrides = !isAllEmpty(ownValues),
      // [isUnlocked, setIsUnlocked] = React.useState(!isAllEmpty(ownValues)),
      annotatedLabel = bodyElement.label
        ? hasOverrides
          ? bodyElement.label
          : `${bodyElement.label} (Default)`
        : undefined,
      unlockAndFill: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        if (updateValues) {
          updateValues({ newValues: set(cloneDeep(values), name, systemFieldCollectionValues) })
        }
      },
      resetValues: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        if (updateValues) {
          updateValues({ newValues: set(cloneDeep(values), name, null) })
        }
      },
      heading = annotatedLabel ? <FormLabel asHeading showLabel label={annotatedLabel} /> : null,
      message = <Outer $offset={'offsetEntries' in bodyElement && bodyElement.offsetEntries}>
        {hasOverrides
          ? <StyledInfoPanel>The default settings for this built-in question have been overriden by the values below. <Button onClick={resetValues} label="Reset to Default" color="back" size="small" /></StyledInfoPanel>
          : <StyledInfoPanel>This built-in question has the following default settings. You can customize these as needed. <Button onClick={unlockAndFill} label="Unlock & Customize" color="back" size="small" /></StyledInfoPanel>}
      </Outer>

    if (bodyElement.elementVariety === 'COLLECTION') {
      const
        customCollctionOptions: CollectionOptions = {
          ...bodyElement,
          isReadOnly: !hasOverrides,
          defaultEntries: systemFieldCollectionValues as unknown as EntityObject[],
          hideLabel: !!annotatedLabel
        },
        baseFormBody = bodyElement.formBody ?? [],
        formBody = hasOverrides && name === 'list'
          ? baseFormBody.map(o => ('fieldName' in o && o.fieldName === 'optionValue' ? { ...o, readOnlyWhen: [{ conditionType: 'always' }] } : o))
          : baseFormBody

      return <>
        {heading}
        {message}
        <FormCollection
          name={bodyElement.fieldName}
          collectionOptions={customCollctionOptions}
          formBody={formBody}
          CollectionRenderer={CollectionRenderer}
          CollectionEntryRenderer={
            bodyElement.useAlternateEntryRenderer === 'conditionList'
              ? ConditionListItem
              : formOptions.RootFormRenderer
          }
        />
      </>
    } else { /** only field here right now is 'invalidates' */
      return <>
        {heading}
        {message}
        <ListMaker
          {...(inputProps as InputProps)}
          currentValue={(hasOverrides ? (inputProps as InputProps).currentValue : systemFieldCollectionValues) as string[] | undefined}
          isReadOnly={!hasOverrides}
          showLabel={false}
        />
      </>
    }
  }

export default SystemFieldDefaultCollection
