import * as React from 'react'
import styled from 'styled-components'
import { sortBy } from 'lodash'
import { inputIdFromBodyElement } from '~/utils/formatters'
import { calculateTTestValues, calculateANOVAValues } from '~/features'
import { getTreatmentObjects } from '~/features/experiment/fieldSelectors/treatmentSelectors'
import { Button, ErrorText, FormLabel, Icon } from '~/components/atoms'
import { Select, ExpandedRow, FormRenderer } from '~/components/molecules'
import StatsTestSummary from './StatsTestSummary'

import type { EntityObject, FormOnSubmitFn, FullOptionList } from '~/form-brain2'
import type { Experiment, SpecialListSectionElement, ListEntry, ListResponse, SelectInput, StatsTestType } from '~/features'
import type { ListEntrySummary } from '~/components/organisms/ListStep/types'
import { MISC_DEFAULTS } from '~/utils/strings'

interface Props {
  experiment: Experiment
  section: SpecialListSectionElement
  response: ListResponse
  otherEntities: EntityObject[]
  entrySummary: ListEntrySummary
  onClose?: React.MouseEventHandler
  onSubmitCallback: FormOnSubmitFn
  isUpdating: boolean
}

interface TestTypeOption {
  value: StatsTestType
  label: string | number
  hidden: boolean
}

const
  StyledButton = styled(Button)`
    align-self: center;
  `,
  TreatmentPickers = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${p => p.theme.emSmallSpacing}em;

    [data-cy="form-field-wrapper"] {
      width: unset;
    }
  `,
  StyledErrorText = styled(ErrorText)`
    align-self: center;
    max-width: ${p => p.theme.emMaxReadingWidth}em;
    align-items: flex-start;

    > div {
      text-align: center;
    }
  `,
  blankTestValues = {
    testType: undefined,
    groupA: undefined,
    groupB: undefined,
    fValue: undefined,
    pValue: undefined,
    dof: undefined,
    dfw: undefined,
    dfb: undefined,
    alpha: undefined
  },
  blankFormOptions = { RootFormRenderer: FormRenderer },
  testTypeBodyElement: SelectInput = {
    id: 'entry-testType',
    fieldName: 'testType',
    elementVariety: 'SELECT_INPUT'
  },
  groupABodyElement: SelectInput = {
    id: 'entry-groupA',
    fieldName: 'groupA',
    elementVariety: 'SELECT_INPUT'
  },
  groupBBodyElement: SelectInput = {
    id: 'entry-groupB',
    fieldName: 'groupB',
    elementVariety: 'SELECT_INPUT'
  },
  getLabelForTest = (entry?: ListEntry): string => {
    if (entry?.testType === 't-test') {
      return `Student's T-Test: ${entry.groupA as string} and ${entry.groupB as string}`
    }

    if (entry?.testType === 'ANOVA') {
      return 'Analysis of Variance (ANOVA)'
    }

    return 'Statistical Test'
  },
  StatsTestCard: React.FC<Props> = ({ experiment, section, response, entrySummary, otherEntities, onClose, onSubmitCallback, isUpdating }) => {
    const
      { nthOfKind, entry } = entrySummary,
      iconNumber = (nthOfKind ?? 0) + 1,
      isPersisted = !!entry?.testType,
      [state, setState] = React.useState<{ entry: ListEntry, error?: string }>({ entry: entry ?? { sectionId: section.id } }),
      entryInProgress = state.entry,
      setEntryInProgress = (entryToEntry: ((entry: ListEntry) => ListEntry)): void => { setState(({ entry }) => ({ entry: entryToEntry(entry) })) },
      selectedTestType = entryInProgress.testType as StatsTestType | undefined,
      tTestOption: TestTypeOption = {
        value: 't-test',
        label: section.tTestLabel ?? MISC_DEFAULTS.tTestLabel,
        hidden: false
      },
      anovaOption: TestTypeOption = {
        value: 'ANOVA',
        label: section.anovaLabel ?? MISC_DEFAULTS.anovaLabel,
        hidden: false
      },
      testTypeOptions: TestTypeOption[] = [tTestOption, anovaOption].map(o => ({
        ...o,
        hidden: !!section.includedTestTypes && !section.includedTestTypes.includes(o.value)
      })),
      treatments = sortBy(getTreatmentObjects(otherEntities), o => o.treatmentIndex).map(o => o.treatment),
      getTreatmentOptionsExcept = (disabledTreatment?: string): FullOptionList => {
        return treatments.map((treatmentName) => ({
          value: treatmentName,
          label: treatmentName,
          disabled: treatmentName === disabledTreatment
        }))
      },
      readyToRun = selectedTestType && (selectedTestType !== 't-test' || (entryInProgress.groupA && entryInProgress.groupB)),
      runTest: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        try {
          if (selectedTestType === 't-test') {
            const { pValue, tValue, meanA, meanB, dof } = calculateTTestValues({
              experiment,
              groupA: entryInProgress.groupA as string,
              groupB: entryInProgress.groupB as string,
              otherEntities
            })

            setEntryInProgress(_values => ({ ..._values, pValue, tValue, meanA, meanB, dof, alpha: section.tTestAlpha ?? MISC_DEFAULTS.tTestAlpha }))
          } else {
            const { fValue, pValue, dfw, dfb } = calculateANOVAValues({ experiment, otherEntities })

            setEntryInProgress(_values => ({ ..._values, fValue, pValue, dfw, dfb, alpha: section.anovaAlpha ?? MISC_DEFAULTS.anovaAlpha }))
          }
        } catch (e) {
          const message = typeof e === 'string'
            ? e
            : e && typeof e === 'object' && 'message' in e
              ? (e?.message as string | undefined)
              : undefined

          setState(_state => ({ ..._state, error: message ?? 'There was an unknown error while running the test.' }))
        }
      },
      onSubmit: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        void onSubmitCallback({ values: entryInProgress }).then(() => { if (onClose) { onClose(e) } })
      }

    return (
      <ExpandedRow
        isInline
        heading={getLabelForTest(entry)}
        headerIcon={<Icon content={iconNumber.toString()} degreesRotation={-8.6} color="back" />}
        leftButton={onClose ? <Button onClick={onClose} color="caution" size="small" label="Close" /> : undefined}
        rightButton={entryInProgress.pValue && !isPersisted ? <Button onClick={onSubmit} color="forward" withIcon="checkmark" size="small" label="Save" isLoading={isUpdating} /> : undefined}
        neutralBackground>
        {!isPersisted
          ? <Select
            updateAttr={(newValue) => { setEntryInProgress(_entryInProgress => ({ ...entryInProgress, ...blankTestValues, testType: newValue })) }}
            name="testType"
            pathToAttr="entries.testType"
            bodyElement={testTypeBodyElement}
            formOptions={blankFormOptions}
            placeholder="Choose a test"
            list={testTypeOptions}
            currentValue={selectedTestType}
          />
          : null}
        {selectedTestType === 't-test' && !isPersisted
          ? <FormLabel htmlFor={inputIdFromBodyElement(groupABodyElement, 'entries.groupA')} label="Which treatments do you want to compare?" showLabel />
          : null}
        {selectedTestType === 't-test' && !isPersisted
          ? <TreatmentPickers>
            <Select
              updateAttr={(newValue) => { setEntryInProgress(_entryInProgress => ({ ...entryInProgress, groupA: newValue })) }}
              name="groupA"
              pathToAttr="entries.groupA"
              bodyElement={groupABodyElement}
              formOptions={blankFormOptions}
              placeholder="Choose a treatment"
              list={getTreatmentOptionsExcept(entryInProgress.groupB as string | undefined)}
              currentValue={entryInProgress.groupA as string | undefined}
            />
            <Select
              updateAttr={(newValue) => { setEntryInProgress(_entryInProgress => ({ ...entryInProgress, groupB: newValue })) }}
              name="groupB"
              pathToAttr="entries.groupB"
              bodyElement={groupBBodyElement}
              formOptions={blankFormOptions}
              placeholder="Choose another treatment"
              list={getTreatmentOptionsExcept(entryInProgress.groupA as string | undefined)}
              currentValue={entryInProgress.groupB as string | undefined}
            />
          </TreatmentPickers>
          : null}
        {selectedTestType
          ? <StatsTestSummary entry={entryInProgress} otherEntities={otherEntities} />
          : null}
        {readyToRun && !isPersisted
          ? <StyledButton size="small" color="back" label="Run Test" onClick={runTest} isDisabled={!!state.error} />
          : null}
        {state.error
          ? <StyledErrorText>{state.error}</StyledErrorText>
          : null}
      </ExpandedRow>
    )
  }

export default StatsTestCard
