import React, { type MouseEventHandler } from 'react'
import styled from 'styled-components'
import { BasicChain, Icon } from '~/components/atoms'

import type { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types'

interface Props {
  linkTitle: string
  linkUrl?: string
  color: React.ComponentProps<typeof Icon>['color']
  className?: string
}

interface State {
  show: boolean
  success: boolean
  timeoutId?: TimeoutId
}

const
  initialState = { show: false, success: false },
  HiddenArea = styled.input`
    opacity: 0;
    width: 1px;
    height: 1px;
    border: 0;
    padding: 0;
  `,
  Message = styled.div<{ $shouldShow?: boolean }>`
    position: fixed;
    transition: right 1s;
    font-style: normal;
    right: ${p => p.$shouldShow ? 25 : -1000}px;
    bottom: 25px;
    padding: ${p => p.theme.emSmallSpacing}em;
    color: ${p => p.theme.colors.bodyText};
    border-radius: ${p => p.theme.pxBorderRadius}px;
    background-color: ${p => p.theme.colors.translucentLight};
    box-shadow: 3px 3px 8px #888888;
  `,
  ToClipboardButton: React.FC<Props> = ({ linkTitle, linkUrl, color, className }) => {
    const
      [messageState, setMessageState] = React.useState<State>(initialState),
      textElementRef = React.useRef<HTMLInputElement | null>(null),
      cleanupTimeout = (): void => { if (messageState.timeoutId) { clearTimeout(messageState.timeoutId) } },
      onClickCopyToClipboard: MouseEventHandler = e => {
        if (e) { e.stopPropagation(); e.preventDefault() }
        let success = false

        if (textElementRef.current) {
          textElementRef.current.select()
          success = document.execCommand('copy')
        }

        cleanupTimeout()
        setMessageState({
          success,
          show: true,
          timeoutId: setTimeout(() => { setMessageState({ success, show: false, timeoutId: undefined }) }, 5000)
        })
      }

    React.useEffect(() => cleanupTimeout)

    return <>
      <Icon
        content={BasicChain}
        frameStyle="plain"
        color={color}
        onClick={linkUrl ? onClickCopyToClipboard : undefined}
        accessibleDescription={linkTitle}
        {...(linkUrl ? { linkProps: { to: linkUrl } } : {})}
        className={className}
      />
      <HiddenArea
        readOnly
        ref={textElementRef}
        value={linkUrl} />
      <Message $shouldShow={messageState.show}>
        {messageState.success
          ? 'Copied link to clipboard!'
          : 'Could not copy link to clipboard; try using right click or long press'}
      </Message>
    </>
  }

export default ToClipboardButton
