import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  MEASUREMENT_RELATIONSHIPS = {
    TOTAL: 'total',
    RATE: 'rate',
    AVERAGE: 'repeat'
  },
  title = 'What type of measurement set will be collected (time series, aggregate values, etc)',
  description = 'When multiple measurements are taken, how are they related to one another. This allows summary stats and analyses to be calculated appropriately. If this question is omitted or no answer is chosen, all measurements for the same subject will be averaged.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.measurementRelationship,
    fieldType: 'ENUM',
    options: [
      {
        optionValue: MEASUREMENT_RELATIONSHIPS.TOTAL,
        optionLabel: 'Measurements are part of a total that builds up over time - they should be added up'
      },
      {
        optionValue: MEASUREMENT_RELATIONSHIPS.RATE,
        optionLabel: 'Measurements show a change in something over time - they should be used to calculate a rate'
      },
      {
        optionValue: MEASUREMENT_RELATIONSHIPS.AVERAGE,
        optionLabel: 'None of the above, each measurement will measure the same thing but it is not expected to change over time - they should be averaged'
      }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.measurementRelationship,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    shouldFollow: [SystemFieldNames.measurementTimingScheme],
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
