import { isAllEmpty } from '../testers'
import { FORM_STATUSES } from '../FormContext'

import type { FormBeforeSubmitFn } from '../types'
import type { FormState } from '../internalTypes'

interface Params {
  state: FormState
  event?: React.FormEvent
  beforeSubmit?: FormBeforeSubmitFn
  submissionSource?: string
}

export const
  getStateOnSubmitInitiated = ({ state, beforeSubmit, event, submissionSource }: Params): FormState => {
    submissionSource = submissionSource ?? (event ? 'event' : undefined)

    const
      valuesToSubmit = beforeSubmit
        ? beforeSubmit({ values: state.values, contextAndResolvers: state })
        : state.values,
      errors = state.validateObject({
        values: valuesToSubmit,
        schema: state.schema,
        contextAndResolvers: { ...state, values: valuesToSubmit },
        validationOptions: { triggeredBy: 'SUBMIT' }
      })

    if (isAllEmpty(errors)) {
      return {
        ...state,
        submissionSource,
        formStatus: FORM_STATUSES.PROCESSING,
        values: valuesToSubmit,
        errors: {},
        showingAllMissing: false
      }
    } else {
      return {
        ...state,
        errors,
        submissionSource,
        values: valuesToSubmit,
        showingAllErrors: true
      }
    }
  }
