import { get } from 'lodash'
import axios from 'axios'

import type { EntityErrors, ResultObject } from '~/form-brain2'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import type { SerializedError } from '@reduxjs/toolkit'
import type { AxiosError, AxiosPromise, AxiosResponse } from 'axios'

const
  maintenanceMessage = 'AgConnect is currently undergoing maintenance. Please try again later.',
  unexpectedErrorMessage = 'There was a problem completing this request. Please try again later and contact support if the problem persists.',
  wrapErrors = (errorMessages: string[]): ResultObject => ({ errors: { base: errorMessages } }),
  concatErrors = (errorMessages: string[], systemMessage?: string): string[] => {
    return errorMessages.concat(systemMessage ? [`Error message: ${systemMessage}`] : [])
  },
  processQueryError = (error: FetchBaseQueryError | AxiosError): ResultObject => {
    const
      systemErrorMessage = 'error' in error ? error.error : undefined,
      serverErrorsObject = 'error' in error ? undefined : 'data' in error ? (JSON.stringify(error?.data) as ResultObject) : undefined

    if (error?.status && ([422, 404, 401] as Array<string | number>).includes(error?.status) && serverErrorsObject) {
      return serverErrorsObject
    } else if (error?.status === 503) {
      return wrapErrors([maintenanceMessage])
    } else {
      // TODO DEV send error report
      return wrapErrors(concatErrors([unexpectedErrorMessage], systemErrorMessage))
    }
  },
  processSystemError = (error: SerializedError): ResultObject => {
    // TODO DEV send error report, this is a client-side error with a stacktrace
    return wrapErrors(concatErrors([unexpectedErrorMessage], error.message))
  },
  processAxiosResultForForm = (axiosPromise: AxiosPromise, submissionSource?: string): Promise<ResultObject> => {
    const result = axiosPromise.then(
      (response: AxiosResponse) => {
        const { data /*, status, statusText, headers, config, request */ } = response
        /* keep log */ console.log('not a problem', data)

        return Promise.resolve({ data, submissionSource })
      },
      (error: Error | AxiosError) => {
        console.log({ error })
        // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
        const expectedErrors = get(error, 'response.data.errors') as EntityErrors | undefined

        if (expectedErrors) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ errors: expectedErrors, data: { unauthorized: (error as AxiosError)?.status === 401 } })
        }

        if (!axios.isAxiosError(error)) {
          return Promise.reject(processSystemError(error))
        }

        return Promise.reject(processQueryError(error))
      }
    )

    return result
  }

export { processAxiosResultForForm, unexpectedErrorMessage, wrapErrors }
