import React from 'react'
import { Row, FixedCell, GrowingCell, DividerLine } from '~/components/molecules/InteractiveRowList'
import styled, { useTheme } from 'styled-components'
import { FauxCheckbox } from '~/components/atoms'
import type { DownloadableContentItem, DownloadableContentFormat, SelectedOptions } from './helpers/getDownloadOptions'
import { omit } from 'lodash'

interface Props {
  setSelectedOptions: React.Dispatch<React.SetStateAction<SelectedOptions>>
  includedTypes: DownloadableContentFormat[]
  selectedOptions: SelectedOptions
  downloadableContentItem: DownloadableContentItem
}

const
  WrappingRow = styled(Row)`
    @media(max-width: ${p => p.theme.pxFullScreenCutoffWidth}px) {
      flex-wrap: wrap;
    }
  `,
  TitleCell = styled(GrowingCell)`
    color: ${p => p.theme.colors.headingAccentText};

    @media(max-width: ${p => p.theme.pxFullScreenCutoffWidth}px) {
      width: 100%;
    }
  `,
  CheckboxCell = styled(FixedCell)`
    color: ${p => p.theme.colors.bodyText};
    // width: 3em;
  `,
  DisappearingCell = styled(CheckboxCell)`
    flex: 0 1 auto;
    min-width: 0px;

    @media(max-width: ${p => p.theme.pxFullScreenCutoffWidth}px) {
      display: none;
    }
  `,
  DisappearingLine = styled(DividerLine)`
    @media(max-width: ${p => p.theme.pxFullScreenCutoffWidth}px) {
      display: none;
    }
  `,
  InvisibleString = styled.div`
    opacity: 0;
  `,
  CheckboxPlaceholder = styled.div`
    width: 1.3em;
    height: 1.3em;
  `,
  formatNames: Record<DownloadableContentFormat, string> = {
    text: 'Word',
    csv: 'Excel',
    image: 'Picture'
  },
  DownloadsRow: React.FC<Props> = ({ setSelectedOptions, includedTypes, selectedOptions, downloadableContentItem }) => {
    const
      THEME = useTheme(),
      id = downloadableContentItem.id,
      thisSelectedOptions = selectedOptions[id] ?? [],
      getOnClick = (format: DownloadableContentFormat): React.ChangeEventHandler => (e) => {
        setSelectedOptions((state: SelectedOptions) => {
          const
            previousFormats = state[id] ?? [],
            newState = {
              ...state,
              [id]: previousFormats.includes(format)
                ? previousFormats.filter(o => o !== format)
                : [...previousFormats, format]
            }

          return newState[id] && !newState[id].length
            ? omit(newState, id)
            : newState
        })
      }

    return <WrappingRow>
      <TitleCell>{downloadableContentItem.label}</TitleCell>
      <DisappearingLine orientation="vertical" color={THEME.colors.borderAccentAlt} />
        {includedTypes.map(str => {
          if (downloadableContentItem.formats.includes(str)) {
            return <CheckboxCell key={str}>
              <FauxCheckbox
                name={`${downloadableContentItem.id}-${str}`}
                onChange={getOnClick(str)}
                defaultChecked={thisSelectedOptions.includes(str)} />
              <div>{formatNames[str]}</div>
            </CheckboxCell>
          } else {
            return <DisappearingCell key={str}>
              <CheckboxPlaceholder />
              <InvisibleString>{formatNames[str]}</InvisibleString>
            </DisappearingCell>
          }
        })}
    </WrappingRow>
  }

export default DownloadsRow
