import styled from 'styled-components'
import CardHeader from '~/components/molecules/CardHeader'

const
  CollapsedRow = styled(CardHeader)<{ $selected?: boolean, $closing?: boolean }>`
    ${p => p.$selected
      ? `
      background: ${p.theme.colors.containerAccentBg};
      margin-right: -20px;
      `
      : ''}

    ${p => p.$closing ? 'margin-top: auto;' : ''}

    margin-bottom: 1em;
    box-shadow: 1px 1.5px 4px 0px ${p => p.theme.colors.shadow};

    > * > :first-child {
      ${p => p.$selected ? 'margin: auto; display: flex; align-items: center;' : ''}
    }

    .card-header__heading {
      white-space: normal;
    }
  `

export default CollapsedRow
