import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'
import { FERTILIZER_TIMING_REFERENCES } from './fertilizerTimingReference'

const
  title = 'Fertilizer timing unit',
  description = 'The unit (days, weeks, etc.) for the fertilizer timing value',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.fertilizerTimingUnit,
    fieldType: 'STRING',
    options: [
      { optionValue: 'day(s)' },
      { optionValue: 'week(s)' }
    ],
    visibleWhen: [
      {
        valueName: SystemFieldNames.fertilizerTimingReference,
        conditionType: 'present',
        conditionScope: 'ownScope'
      },
      {
        valueName: SystemFieldNames.fertilizerTimingReference,
        conditionType: 'noneOf',
        testValue: [
          FERTILIZER_TIMING_REFERENCES.AT_PLANTING,
          FERTILIZER_TIMING_REFERENCES.AT_GERMINATION
        ],
        conditionScope: 'ownScope'
      }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.fertilizerTimingUnit,
    variableGroup: SystemFieldGroups.CONTROL_VARIABLES,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
