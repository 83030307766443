import React from 'react'
import styled from 'styled-components'
import { HintText } from '~/components/atoms'
import { getCalculatedValue } from '~/features/experiment/calculatedValues'
import { floatString } from '~/utils/formatters'
import { isNumeric } from '~/utils/testers'

import type { EntityObject } from '~/form-brain2'
import type { ListEntry } from '~/features'

interface Props {
  entry: ListEntry
  otherEntities: EntityObject[]
}

const
  TestContent = styled.div<{ $first?: boolean, $last?: boolean }>`
    ${p => p.theme.bodyFont};
    font-weight: normal;
    color: ${p => p.theme.colors.bodyText};
    font-size: 1rem;
    line-height: 1.2;
    margin-bottom: ${p => p.$last ? p.theme.emBaseSpacing * 1.5 : p.theme.emSmallSpacing}em;
    margin-top: ${p => p.$first ? p.theme.emBaseSpacing : 0}em;
  `,
  GroupName = styled(TestContent)<{ $emphasize?: boolean, $blank?: boolean }>`
    display: inline;
    color: ${p => p.$blank ? p.theme.colors.bodyUnderstateText : p.$emphasize ? p.theme.colors.bodyAccentText : p.theme.colors.bodyText};
    font-weight: ${p => p.$blank ? 'normal' : 'bold'};
  `,
  Bubble = styled.div`
    background: ${p => p.theme.colors.bodyBg};
    padding: ${p => p.theme.emSmallSpacing}em;
    border-radius: ${p => p.theme.pxBorderRadius * 2}px;
    margin-bottom: ${p => p.theme.emBaseSpacing * 1.5}em;
    margin-top: ${p => p.theme.emSmallSpacing}em;
  `,
  StyledHintText = styled(HintText)`
    text-align: left;
  `,
  Em = styled.span`
    color: ${p => p.theme.colors.bodyAccentText};
    font-weight: bold;
  `,
  groupAPlaceholder = 'First Group',
  groupBPlaceholder = 'Second Group',
  tTestIntro = 'A t-test looks at whether or not the difference between two groups is significant.',
  anovaTestIntro = 'An analysis of variance (ANOVA for short) looks at whether or not there are any significant differences between treatments. On its own, it cannot say anything about which treatments are different or in what way.',
  /** If you change this, change it in getValuesForItem too */
  StatsTestSummary: React.FC<Props> = ({ entry, otherEntities }) => {
    const
      { testType, groupA, groupB } = entry,
      // dependentVariableText = 'total harvested weight' // TODO DEV
      dependentVariableText = getCalculatedValue('dependentVariableResultDescription', otherEntities)

    if (testType === 't-test') {
      const
        emphasisGroup = !groupA && !groupB
          ? 'test'
          : groupA && groupB
            ? 'none'
            : groupA ? 'A' : 'B',
        groupALabel = (groupA as string | undefined) ?? groupAPlaceholder,
        groupBLabel = (groupB as string | undefined) ?? groupBPlaceholder,
        groupAText = <GroupName $emphasize={emphasisGroup === 'A'} $blank={!groupA}>{groupALabel}</GroupName>,
        groupBText = <GroupName $emphasize={emphasisGroup === 'B'} $blank={!groupB}>{groupBLabel}</GroupName>,
        { dof, meanA, meanB, tValue, pValue, alpha } = entry,
        hasTestResult = isNumeric(pValue),
        isSignificant = hasTestResult && isNumeric(tValue) && isNumeric(alpha)
          ? (pValue as number) < (alpha as number)
          : undefined

      return <>
        <TestContent $first>{tTestIntro}</TestContent>
        <TestContent>The base assumption (null hypothesis) is that the difference between {dependentVariableText} for {groupAText} and for {groupBText} is <GroupName $emphasize={emphasisGroup === 'test'}>0</GroupName></TestContent>
        <TestContent $last={!hasTestResult}>You are testing for the possibility (alternative hypothesis) that the difference between {dependentVariableText} for {groupAText} and for {groupBText} is <GroupName $emphasize={emphasisGroup === 'test'}>greater or less than 0</GroupName></TestContent>
        {hasTestResult
          ? <Bubble>
            <StyledHintText color="default">
              <>
                The difference in {dependentVariableText} between treatments <Em>{groupALabel}</Em> (Mean = {floatString(meanA as number, { places: 2 })}) and <Em>{groupBLabel}</Em> (Mean = {floatString(meanB as number, { places: 2 })})) was <Em>{isSignificant ? 'significant' : 'not significant'}</Em> (t({dof}) = {floatString(tValue as number)}; p = {floatString(pValue as number)}, α = {floatString(alpha as number)}).
              </>
            </StyledHintText>
          </Bubble>
          : null}
      </>
    }

    const
      { dfw, dfb, fValue, pValue, alpha } = entry,
      hasTestResult = isNumeric(pValue),
      isSignificant = hasTestResult && isNumeric(fValue) && isNumeric(alpha)
        ? (pValue as number) < (alpha as number)
        : undefined

    return <>
      <TestContent $first>{anovaTestIntro}</TestContent>
      <TestContent>The base assumption (null hypothesis) is that the mean {dependentVariableText} for the groups are <GroupName>all equal</GroupName></TestContent>
      <TestContent $last>You are testing for the possibility (alternative hypothesis) that the mean of <GroupName>at least one of the groups is different</GroupName> from the others in a statistically significant way</TestContent>
      {hasTestResult
        ? <Bubble>
          <StyledHintText color="default">
            <>
              There {isSignificant ? 'is' : 'are'} <Em>{isSignificant ? 'at least one statistically significant difference' : 'no statistically significant differences'}</Em> in {dependentVariableText} between treatments. (f({dfb},{dfw}) = {floatString(fValue as number)}; p = {floatString(pValue as number)}, α = {floatString(alpha as number)}).
            </>
          </StyledHintText>
        </Bubble>
        : null}
    </>
  }

export default StatsTestSummary
export { groupAPlaceholder, groupBPlaceholder, tTestIntro, anovaTestIntro }
