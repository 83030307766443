import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import { DependentVariableCategories, fieldDetails as dependentVariableCategory } from './dependentVariableCategory'

import type { QField, VisibilityCondition } from '~/features/questionnaire/types'
import type { DependentVariableCategory } from './dependentVariableCategory'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  dvcEquals = (dvcToMatch: DependentVariableCategory): VisibilityCondition[] => [{
    valueName: dependentVariableCategory.fieldName,
    conditionType: 'equals',
    testValue: dvcToMatch
  }],
  title = 'Dependent variable',
  description = 'This is the variable that will be observed and measured in the experiment',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.dependentVariable,
    fieldType: 'STRING',
    options: [
      {
        optionValue: 'vegetable yield',
        optionLabel: 'yield',
        visibleWhen: dvcEquals(DependentVariableCategories.VEGETABLE)
      },
      {
        optionValue: 'vegetable growth',
        optionLabel: 'growth',
        visibleWhen: dvcEquals(DependentVariableCategories.VEGETABLE)
      },
      {
        optionValue: 'row crop yield',
        optionLabel: 'yield',
        visibleWhen: dvcEquals(DependentVariableCategories.ROW_CROP)
      },
      {
        optionValue: 'row crop growth',
        optionLabel: 'growth',
        visibleWhen: dvcEquals(DependentVariableCategories.ROW_CROP)
      },
      {
        optionValue: 'cover crop yield',
        optionLabel: 'yield',
        visibleWhen: dvcEquals(DependentVariableCategories.COVER_CROP)
      },
      {
        optionValue: 'cover crop growth',
        optionLabel: 'growth',
        visibleWhen: dvcEquals(DependentVariableCategories.COVER_CROP)
      },
      {
        optionValue: 'soil conditions',
        optionLabel: 'conditions',
        visibleWhen: dvcEquals(DependentVariableCategories.SOIL)
      },
      {
        optionValue: 'soil health',
        optionLabel: 'health',
        visibleWhen: dvcEquals(DependentVariableCategories.SOIL)
      }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.dependentVariable,
    variableGroup: SystemFieldGroups.QUESTION,
    isRequired: true,
    shouldFollow: [SystemFieldNames.dependentVariableCategory],
    includeOtherByDefault: true,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
