import * as React from 'react'
import styled from 'styled-components'
import { difference, findIndex, omit } from 'lodash'
import { isEmptyValue } from '~/utils/testers'
import { inputIdFromBodyElement } from '~/utils/formatters'
import FauxCheckbox from '~/components/atoms/FauxCheckbox'
import { baseInputStyles, BaseInput, WithUnderline } from '~/components/atoms'
import FormFieldWrapper from '~/components/molecules/FormElements/FormFieldWrapper'
import Select from './Select'

import type { AttrVal, AttrValArray, FullOptionList, ListItem } from '~/form-brain2'
import type { InputProps } from '~/types/forms'
import type { MultiSelectInput } from '~/features'

interface MultiSelectOption extends ListItem {
  checked?: boolean
  onPress?: () => void
}

interface GetFullOptionsOptions {
  options: FullOptionList
  currentValues: AttrValArray
  updateAttr: (newVal: AttrValArray) => void
  noSort?: boolean | null
  selectAllOption?: boolean | string | null
  selectNoneOption?: boolean | string | null
  allEnabledValues: AttrValArray
}

const
  Wrapper = styled.div`
    ${baseInputStyles as any /* Silence type warning */}
    flex: 1 0 100%;
    flex-wrap: wrap;
    padding-top: 0; 
    padding-bottom: 0;
    background: unset;
  `,
  Checkbox = styled(FauxCheckbox)`
  `,
  UnderlineWrapper = styled(WithUnderline)`
    margin-left: ${p => p.theme.emSmallSpacing / 2}em;
  `,
  TextInput = styled(BaseInput)`
    background: ${p => p.theme.colors.bodyBg};
    max-width: 300px;
    min-width: 250px;
  `,
  Opt = styled.span`
    padding: 0 ${p => p.theme.emSmallSpacing}em;
    display: flex;

    label {
      display: flex;
      align-items: center;
      gap: ${p => p.theme.emSmallSpacing}em;
    }
  `,
  CustomOpt = styled(Opt)`
    flex-basis: 100%;
  `,
  LabelText = styled.span<{ $disabled?: boolean }>`
    ${p => p.$disabled ? `color: ${p.theme.colors.understateMed};` : null}
  `,
  getFullOptions: ((options: GetFullOptionsOptions) => MultiSelectOption[]) = ({
    options,
    selectAllOption,
    selectNoneOption,
    allEnabledValues,
    currentValues,
    updateAttr
  }) => {
    const displayOptions = [...options]

    if (selectAllOption) {
      const
        allSelected = isEmptyValue(difference(allEnabledValues, currentValues)),
        allEnabledValuesOption = {
          label: typeof selectAllOption === 'string' ? selectAllOption : 'All',
          checked: allSelected,
          onPress: () => { updateAttr(allSelected ? [] : allEnabledValues) },
          value: 'thisbetterbeunique',
          disabled: false
        }

      displayOptions.unshift(allEnabledValuesOption)
    }

    if (selectNoneOption) {
      const
        noneSelected = isEmptyValue(currentValues),
        noneOption = {
          label: typeof selectNoneOption === 'string' ? selectNoneOption : 'None',
          checked: noneSelected,
          onPress: () => { updateAttr([]) },
          value: 'thisbetterbeuniquetoo',
          disabled: false
        }

      displayOptions.unshift(noneOption)
    }

    return displayOptions
  },
  getSafeVal = (val: AttrVal): string | undefined => (
    typeof val === 'number' || typeof val === 'boolean'
      ? val.toString()
      : val
  ),
  MultiSelect: React.FC<InputProps & { className?: string }> = (inputProps) => {
    const
      options: FullOptionList = inputProps.list ?? [],
      { name, updateAttr, currentValue, className, isReadOnly, isDisabled, pathToAttr } = inputProps,
      bodyElement = inputProps.bodyElement as MultiSelectInput,
      { selectAllOption, selectNoneOption, customOption } = bodyElement,
      inputId = inputIdFromBodyElement(bodyElement, pathToAttr),
      currentValues: AttrValArray | AttrVal = !currentValue
        ? []
        : Array.isArray(currentValue)
          ? currentValue
          : [currentValue],
      allValues = options.map(o => o.value) as AttrValArray,
      allEnabledValues = options.filter(o => !o.disabled).map(o => o.value),
      customValueIndex = findIndex(currentValues, o => !allValues.includes(o)),
      displayOptions = getFullOptions({ options, selectAllOption, selectNoneOption, allEnabledValues, currentValues, updateAttr }),
      handleChange = (value: AttrVal) => () => {
        updateAttr(
          currentValues.includes(value)
            ? currentValues.filter(o => o !== value)
            : currentValues.concat([value])
        )
      },
      toggleCustomValue = (): void => {
        updateAttr(
          customValueIndex >= 0
            ? currentValues.filter(o => allValues.includes(o))
            : currentValues.concat([''])
        )
      },
      updateCustomValue = (value: AttrVal): void => {
        updateAttr([
          ...currentValues.filter(o => allValues.includes(o)),
          value
        ])
      }

    if (!currentValues.length && (!displayOptions.length || !displayOptions.some(o => !isEmptyValue(o)))) {
      // No value, no non-blank options, display empty select input
      return <Select {...inputProps} list={[]} isReadOnly bodyElement={{ ...omit(bodyElement, 'list'), elementVariety: 'SELECT_INPUT' }} />
    }

    return <FormFieldWrapper className={className} inputId={inputId} inputProps={inputProps} noWrapper>
      <Wrapper
        id={inputId}
        role="listbox"
        aria-multiselectable
        aria-readonly={isReadOnly}
        data-cy="multi-select">
        {displayOptions.map(({ value, disabled, checked, onPress, label }, index) => (
          <Opt key={value} role="option" aria-selected={checked}>
            <label>
              <Checkbox
                name={`_${name}.${index}`}
                type="checkbox"
                disabled={disabled ?? isDisabled}
                readOnly={isReadOnly}
                defaultChecked={checked ?? currentValues.includes(value)}
                onChange={onPress ?? handleChange(value)} />
              <LabelText $disabled={disabled}>{label}</LabelText>
            </label>
          </Opt>
        ))}
        {customOption
          ? <CustomOpt role="option" aria-selected={customValueIndex >= 0}>
            <label htmlFor={`${name}.custom`}>
              <Checkbox
                name={`_${name}.custom`}
                type="checkbox"
                readOnly={isReadOnly}
                disabled={isDisabled}
                defaultChecked={customValueIndex >= 0}
                onChange={toggleCustomValue}/>
              <LabelText $disabled={isDisabled}>
                {customOption}
              </LabelText>
              <UnderlineWrapper>
                <TextInput
                  id={`_${name}.custom`}
                  type="text"
                  name={name}
                  readOnly={isReadOnly}
                  disabled={isDisabled}
                  placeholder={isReadOnly ? '(Blank)' : bodyElement.customPlaceholder ?? 'Type here'}
                  value={customValueIndex >= 0 ? getSafeVal(currentValues[customValueIndex]) : ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => { updateCustomValue(e.currentTarget.value) }}
                />
              </UnderlineWrapper>
            </label>
          </CustomOpt>
          : null}
      </Wrapper>
    </FormFieldWrapper>
  }

export default MultiSelect
