import React from 'react'
import { get } from 'lodash'
import { useDispatch } from 'react-redux'
import { baseUrl } from '~/services/api'
import { entitiesReceived } from '~/features/entity/entitySlice'
import useSession from './useSession'

import type { EntityErrors } from '~/form-brain2'
import type { APIEntity, ObjectId } from '~/types/utilities'
import type { AnyEntity } from '~/features/entity/entitySlice'
import { captureMessage } from '@sentry/react'

interface UseResetEntityParams {
  id: ObjectId | undefined
  type: keyof typeof apiPathLookup
  silentUpdate?: boolean
  clearStateOnSuccessAfter?: number
}

interface UseResetEntity {
  resetEntity: () => void
  resetStateForResetEntity: () => void
  isResetting?: boolean
  isSuccess?: boolean
  errors?: EntityErrors
}

interface UseResetEntityState {
  isResetting?: boolean
  isSuccess?: boolean
  errors?: EntityErrors
}

const
  apiPathLookup: Record<'templateSet' | 'template' | 'experiment' | 'worksheet', string> = {
    templateSet: 'template_sets',
    template: 'templates',
    experiment: 'experiments',
    worksheet: 'worksheets'
  },
  useResetEntity = ({ id, type, silentUpdate, clearStateOnSuccessAfter }: UseResetEntityParams): UseResetEntity => {
    const
      dispatch = useDispatch(),
      { activeToken, makeRequestWithSession } = useSession(),
      [state, setState] = React.useState<UseResetEntityState>({}),
      { isResetting, isSuccess, errors } = state,
      resetStateForResetEntity = (): void => {
        setState({})
      },
      resetEntity: UseResetEntity['resetEntity'] = () => {
        if (!id || !type) {
          setState({ errors: { _base: ['Something went wrong, please try again'] } })
          captureMessage(`useResetEntity resetEntity called with missing parameters (id: ${id}, type: ${type})`)
        } else {
          setState({ isResetting: true })

          makeRequestWithSession({
            method: 'put',
            url: `${baseUrl}${apiPathLookup[type]}/${id}/reset`,
            headers: {
              Authorization: `Bearer ${activeToken as string}`
            },
            data: {}
          })
            .then((params) => {
              const entities = get(params, 'data.data') as Array<APIEntity<AnyEntity>>

              if (!silentUpdate) { dispatch(entitiesReceived(entities)) }
              setState({ isSuccess: true })
            })
            .catch((params) => {
              setState({ errors: params.errors })
            })
        }
      }

    React.useEffect(() => {
      if (isSuccess && clearStateOnSuccessAfter) {
        const timeout = setTimeout(() => { setState({}) }, clearStateOnSuccessAfter)

        return () => { clearTimeout(timeout) }
      }
    }, [isSuccess, clearStateOnSuccessAfter])

    return {
      resetEntity,
      resetStateForResetEntity,
      isResetting,
      isSuccess,
      errors
    }
  }

export default useResetEntity
