import * as React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Navigate } from 'react-router-dom'
import { DOCUMENT_TITLES, HEADER_TEXTS } from '~/utils/strings'
import RESEARCH_THEME from '~/components/ResearchTheme'
import { useSession } from '~/hooks'
import { BrandedPage, FlexibleMain } from '~/components/layouts'
import { HeadingOne, Link, Icon } from '~/components/atoms'
import { Blurb, InfoPanel } from '~/components/molecules'

const
  OrientationBlurb = styled(Blurb)`
    [data-cy="hint-text"] {
      font-size: 0.95rem;
    }

    ul {
      padding-left: 1.5em;
    }
  `,
  heading = <HeadingOne color="alt">Welcome to AgConnect</HeadingOne>,
  blurbContent = <>
    <p>
      If you are a...
    </p>
    <ul>
      <li>student or learner, contact your teacher for a login link</li>
      <li>teacher, or want to learn more about AgConnect&apos;s teaching tool, visit the <Link to="/educate">education home page</Link></li>
      <li>survey participant or researcher, or want to learn more about AgConnect&apos;s research tools, visit the <Link reloadDocument to="/participate">participant home page</Link></li>
    </ul>
  </>,
  primaryContent = (
    <OrientationBlurb
      withBackground
      icon={<Icon color="forward" content="?" frameStyle="circle" />}
      heading="Where is my program?"
      content={blurbContent} />
  ),
  about = <InfoPanel heading="About AgConnect">
    <p>AgConnect is an online platform administered by the University of Vermont. Its purpose is to facilitate programs which connect our communities to advance our collective knowledge of the issues which impact our living landscape.</p>
    <p>AgConnect&apos;s teaching tools were developed with generous funding from the United States Department of Agriculture, National Institute of Food and Agriculture, Award No. 2020-67038-31043.</p>
    <p>AgConnect&apos;s research tools were developed through the USDA Organic Research and Extension Initiative (OREI) grant Advancing Grass-Fed Dairy: A Whole Systems Approach to Enhancing Productivity, Quality, and Farm Viability in the US (#2018-02802).</p>
  </InfoPanel>,
  GlobalHomePage: React.FC = () => {
    const { learnerId, isSessionValid, isTeacher } = useSession()

    return <ThemeProvider theme={RESEARCH_THEME}>
      <BrandedPage
        documentTitle={DOCUMENT_TITLES.public}
        headerText={HEADER_TEXTS.public}
        whichHome="all">
        <FlexibleMain
          noHR
          heading={heading}
          primaryContent={primaryContent}
          secondaryContent={about} />
      </BrandedPage>
      {isSessionValid && learnerId
        ? <Navigate replace to="/project-home" />
        : null}
      {isSessionValid && isTeacher
        ? <Navigate replace to="/projects" />
        : null}
    </ThemeProvider>
  }

export default GlobalHomePage
