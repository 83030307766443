import * as React from 'react'
import type { BasicIconProps } from '~/types/utilities'

const
  BasicTribble: React.FC<BasicIconProps> = ({ className, color = 'black', outlineColor }) => {
    return (
      <svg
        className={className}
        viewBox="-0.5 -0.5 23 23"
        xmlns="http://www.w3.org/2000/svg"
        data-cy="basic-tribble">
        <path
          fill={color}
          stroke={outlineColor}
          strokeWidth="1.3px"
          d="M10.0168 1.30409C10.2189 0.213779 11.7811 0.213781 11.9832 1.30409L12.4348 3.74017C12.5794 4.5201 13.531 4.82929 14.1064 4.28329L15.9037 2.5779C16.708 1.81463 17.9719 2.7329 17.4946 3.73378L16.428 5.97005C16.0866 6.68601 16.6747 7.49548 17.4611 7.39198L19.9175 7.06867C21.0169 6.92397 21.4997 8.40978 20.5252 8.93892L18.3479 10.1212C17.6508 10.4997 17.6508 11.5003 18.3479 11.8788L20.5252 13.0611C21.4997 13.5902 21.0169 15.076 19.9175 14.9313L17.4611 14.608C16.6747 14.5045 16.0866 15.314 16.428 16.03L17.4946 18.2662C17.9719 19.2671 16.708 20.1854 15.9037 19.4221L14.1064 17.7167C13.531 17.1707 12.5794 17.4799 12.4348 18.2598L11.9832 20.6959C11.7811 21.7862 10.2189 21.7862 10.0168 20.6959L9.56516 18.2598C9.42058 17.4799 8.46898 17.1707 7.89358 17.7167L6.09635 19.4221C5.29197 20.1854 4.02807 19.2671 4.50542 18.2662L5.57197 16.03C5.91343 15.314 5.32531 14.5045 4.53888 14.608L2.08248 14.9313C0.983076 15.076 0.500312 13.5902 1.4748 13.0611L3.6521 11.8788C4.34918 11.5003 4.34918 10.4997 3.6521 10.1212L1.4748 8.93892C0.50031 8.40977 0.983078 6.92397 2.08248 7.06867L4.53888 7.39198C5.32531 7.49548 5.91343 6.68601 5.57197 5.97005L4.50542 3.73378C4.02807 2.7329 5.29197 1.81463 6.09635 2.5779L7.89359 4.28329C8.46898 4.82929 9.42058 4.5201 9.56516 3.74017L10.0168 1.30409Z"
        />
      </svg>
    )
  }

export default BasicTribble
