import { difference } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import { Icon } from '~/components/atoms/Icons'
import Blurb from '~/components/molecules/Blurb'
import { availableIcons } from '~/components/molecules/FormElements/availableIcons'
import type { BlurbElement, IconName } from '~/features/questionnaire/types'

interface ExtraBlurbProps {
  className?: string
  noWrapper?: boolean
  withBottomDivider?: boolean
  withTopDivider?: boolean
  naturalWidth?: boolean
  paragraphs: Array<string | Array<string | JSX.Element>>
}

const
  FormBlurb = styled(Blurb)`
    min-height: unset;
    
    p {
      margin-bottom: 1em;
    }
  `,
  BlurbWrapper = styled.div<{
    $location?: 'bottom' | 'top' | 'center'
    $naturalWidth?: boolean
    $withBottomDivider?: boolean
    $withTopDivider?: boolean
  }>`
    margin-bottom: ${p => p.$location === 'top' ? 'auto' : '0.45em'};
    margin-top: ${p => p.$location === 'bottom' ? 'auto' : `${p.theme.emSmallSpacing / 2}em`};
    padding-top: ${p => p.$location === 'bottom' ? p.theme.emBaseSpacing * 3 : 0}em;
    padding-bottom: ${p => p.$location === 'top' ? p.theme.emBaseSpacing * 1.5 : 0}em;

    ${p => !p.$naturalWidth ? `max-width: ${p.theme.emMaxReadingWidth}em;` : !p.$location ? `margin-bottom: ${p.theme.emBaseSpacing}em;` : ''}

    ${p => p.$withBottomDivider
      ? `
        border-bottom: 3px dashed ${p.theme.colors.borderAccentAlt};
        margin: 0;
        `
      : p.$withTopDivider
        ? `
          border-top: 3px dashed ${p.theme.colors.borderAccentAlt};
          margin: 0;`
        : ''}
  `,
  BlurbBuilder: React.FC<Omit<BlurbElement, 'paragraphs' | 'id' | 'elementVariety'> & ExtraBlurbProps> = (props) => {
    const
      { title, iconColor, iconContent, paragraphs, location, noWrapper, withBottomDivider, withTopDivider, naturalWidth, className } = props,
      listParagraphs = paragraphs.filter(o => (typeof o === 'string' && o.slice(0, 2) === '- ') || (Array.isArray(o) && typeof o[0] === 'string' && o[0].slice(0, 2) === '- ')),
      nonListParagraphs = difference(paragraphs, listParagraphs),
      content = listParagraphs.length
        ? <>
          {nonListParagraphs.map((p, i) => {
            return i === (nonListParagraphs.length - 1)
              ? <div key={i}>{p}</div>
              : <p key={i}>{p}</p>
          })}
          {listParagraphs.length
            ? <ul>{listParagraphs.map((el, i) => {
              return <li key={i}>{
                Array.isArray(el)
                  ? el.map((s, j) => j === 0 ? (s as string).slice(2) : s)
                  : el
              }</li>
            })}</ul>
            : undefined}
        </>
        : paragraphs.map((p, i) => {
          return <p key={i}>{p}</p>
        }),
      blurbProper = <FormBlurb
        icon={iconContent
          ? <Icon content={iconContent in availableIcons ? availableIcons[iconContent as IconName] : iconContent} color={iconColor} />
          : undefined}
        content={content}
        heading={title}
        withBackground={!!props.withBackground}
        className={className}
      />

    return noWrapper
      ? blurbProper
      : <BlurbWrapper $location={location} $naturalWidth={naturalWidth} $withBottomDivider={withBottomDivider} $withTopDivider={withTopDivider}>
         {blurbProper}
      </BlurbWrapper>
  }

export default BlurbBuilder
