import { useCallback } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { isAllEmpty } from '~/utils/testers'
import useRefreshSession from './useRefreshSession'
import useAppSelector from './useAppSelector'
import { processAxiosResultForForm } from '~/utils/errorMessages'
import { getSessionType, received401Error } from '~/features/session/sessionSlice'

import type { AxiosRequestConfig } from 'axios'
import type { User } from '~/features'
import type { SessionTypes } from '~/services/api'
import type { ResultObject } from '~/form-brain2'

interface UseSession {
  isProcessing?: boolean
  received401ForSessionType?: typeof SessionTypes[number]
  makeRequestWithSession: (axiosOptions: AxiosRequestConfig) => Promise<ResultObject>
  hasSession?: boolean
  isSessionValid?: boolean
  activeToken?: string
  isAdmin?: boolean
  isResearcher?: boolean
  isTeacher?: boolean
  isLearner?: boolean
  isParticipant?: boolean
  userId?: string
  learnerId?: string
  user?: User
  accountTypeString: string
}

const
  getAccountTypeString = (isAdmin: boolean, isResearcher: boolean, isTeacher: boolean, isParticipant: boolean, userId: string | undefined): string => {
    if (!userId) { return 'Not logged in' }

    const roles = [
      isAdmin ? 'admin' : null,
      isParticipant ? 'participant' : null,
      isResearcher ? 'researcher' : null,
      isTeacher ? 'teacher' : null
    ].filter(o => o)

    if (isAllEmpty(roles)) {
      return 'participant'
    } else {
      return roles.join(', ')
    }
  },
  useSession = (): UseSession => {
    const
      { user, /* sessionErrors, */ isProcessing, isSessionValid, activeToken, sessionData } = useRefreshSession(),
      dispatch = useDispatch(),
      sessionType = getSessionType(sessionData),
      received401ForSessionType = useAppSelector(state => state.session.received401ForSessionType),
      userId = sessionData?.user_id,
      learnerId = sessionData?.learner_id,
      isAdmin = sessionData?.is_admin,
      isResearcher = sessionData?.is_researcher,
      isTeacher = sessionData?.is_teacher,
      isParticipant = sessionData?.is_participant,
      isLearner = !!learnerId,
      hasSession = !isAllEmpty(sessionData),
      accountTypeString = getAccountTypeString(!!isAdmin, !!isResearcher, !!isTeacher, !!isParticipant, userId),
      makeRequestWithSession: UseSession['makeRequestWithSession'] = useCallback((axiosOptions) => {
        return processAxiosResultForForm(axios(axiosOptions)).then(
          (res) => res,
          (params) => {
            if (params?.data?.unauthorized) {
              dispatch(received401Error({ sessionType }))
              return Promise.reject(params)
            } else {
              return Promise.reject(params)
            }
          }
        )
      }, [sessionType, dispatch])

    return {
      isProcessing,
      hasSession,
      isSessionValid,
      activeToken,
      isAdmin,
      isResearcher,
      isTeacher,
      isLearner,
      isParticipant,
      userId,
      user,
      learnerId,
      accountTypeString,
      received401ForSessionType,
      makeRequestWithSession
    }
  }

export default useSession
export { getAccountTypeString }
