export type ButtonColor = 'forward' | 'back' | 'caution'
export type ButtonSize = 'medium' | 'large' | 'small'
export type ButtonIcon = 'arrowL' | 'arrowR' | 'checkmark'

type ButtonTextColor = 'forwardText' | 'backText' | 'cautionText'
type ButtonStandaloneTextColor = 'forwardStandaloneText' | 'backStandaloneText' | 'cautionStandaloneText'

export const
  textColorKeys: Record<ButtonColor, ButtonTextColor> = {
    forward: 'forwardText',
    back: 'backText',
    caution: 'cautionText'
  },
  standaloneTextColorKeys: Record<ButtonColor, ButtonStandaloneTextColor> = {
    forward: 'forwardStandaloneText',
    back: 'backStandaloneText',
    caution: 'cautionStandaloneText'
  },
  minWidthBySize = {
    small: null,
    medium: 6.39,
    large: 12.016
  },
  lineHeightBySize = {
    small: 2.04,
    medium: 2,
    large: 2.85
  },
  fontSizeByButtonSize = {
    small: 0.848,
    medium: 1,
    large: 1.33
  }
