import React from 'react'
import { get } from 'lodash'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { isAllEmpty } from '~/utils/testers'
import { baseUrl } from '~/services/api'
import { entitiesReceived, type AnyEntity } from '~/features/entity/entitySlice'
import { selectHasInitializedData, selectIsWinningOperation, selectWinningOperationId } from '~/features/session/selectors'
import {
  dataInitialized,
  raceOperationCompleted,
  raceOperationIgnored,
  raceOperationStarted
} from '~/features/session/sessionSlice'
import useAppSelector from './useAppSelector'
import useSession from './useSession'

import type { AttrErrors, EntityErrors } from '~/form-brain2'
import type { APIEntity } from '~/../cypress/support/types'
import { getState } from '~/services/store'
import { selectIsStale } from '~/features/entity/selectors'
import { getSemiRandomString } from '~/utils/strings'

export type DataStatus = 'uninitialized' | 'initializing' | 'refreshing' | 'available' | 'error'

interface UseEntityData {
  dataStatus: DataStatus
  hasInitializedData?: boolean
  hasAttemptedRefresh?: boolean
  isLoading?: boolean
  errors?: AttrErrors
}

interface UseEntityDataState {
  isProcessing?: boolean
  hasAttemptedRefresh?: boolean
  rawErrors?: EntityErrors
}

const
  operationKey = 'GET:entities',
  inferDataStatus = ({ hasErrors, isProcessing, hasInitializedData }: { hasErrors?: boolean, isProcessing?: boolean, hasInitializedData?: boolean }): DataStatus => {
    return hasErrors
      ? 'error'
      : isProcessing && hasInitializedData
        ? 'refreshing'
        : isProcessing && !hasInitializedData
          ? 'initializing'
          : hasInitializedData
            ? 'available'
            : 'uninitialized'
  },
  useEntityData = (skipLoadOnPathChange?: 'lazy'): UseEntityData => {
    const
      dispatch = useDispatch(),
      { pathname } = useLocation(),
      prevPathname = React.useRef<string | undefined>(),
      { activeToken, makeRequestWithSession } = useSession(),
      hasInitializedData = useAppSelector(selectHasInitializedData),

      [state, setState] = React.useState<UseEntityDataState>({}),
      { isProcessing, rawErrors, hasAttemptedRefresh } = state,

      errors = rawErrors?.base as AttrErrors,
      hasErrors = !isAllEmpty(rawErrors),
      dataStatus = inferDataStatus({ hasErrors, isProcessing, hasInitializedData }),

      fetchEntityData = React.useCallback((): void => {
        const
          operationId = getSemiRandomString(),
          requestInfo = { key: operationKey, operationId }

        setState({ isProcessing: true })
        dispatch(raceOperationStarted(requestInfo))

        makeRequestWithSession({
          method: 'get',
          url: `${baseUrl}entities`,
          headers: {
            Authorization: `Bearer ${activeToken as string}`
          }
        })
          .then(({ data }) => {
            const
              entities = get(data, 'data') as Array<APIEntity<AnyEntity>>,
              isWinningOperation = selectIsWinningOperation(requestInfo)(getState())

            if (isWinningOperation) {
              dispatch(entitiesReceived(entities))
              dispatch(raceOperationCompleted(requestInfo))
              if (!hasInitializedData) { dispatch(dataInitialized()) }
            } else {
              dispatch(raceOperationIgnored(requestInfo))
            }

            setState({ isProcessing: false, hasAttemptedRefresh: true })
          })
          .catch(({ errors }) => {
            const isWinningOperation = selectIsWinningOperation(requestInfo)(getState())
            dispatch(raceOperationCompleted(requestInfo))
            setState(state => ({
              ...state,
              isProcessing: false,
              rawErrors: isWinningOperation ? errors : undefined
            }))
          })
      }, [dispatch, setState, activeToken, hasInitializedData, makeRequestWithSession])

    React.useEffect(() => {
      if (
        !isProcessing && !hasErrors &&
        !selectWinningOperationId(operationKey)(getState()) &&
        (
          !hasInitializedData || selectIsStale(getState()) || // unnecessary to check both but clearer intent
          (pathname !== prevPathname.current && !skipLoadOnPathChange) // refresh data on navigate unless page specified otherwise
        )
      ) {
        fetchEntityData()
      }

      prevPathname.current = pathname
    }, [fetchEntityData, isProcessing, hasErrors, hasInitializedData, pathname, prevPathname, skipLoadOnPathChange])

    React.useEffect(() => {
      if (!activeToken) {
        setState(state => ({ ...state, rawErrors: { base: ['You are not logged in'] } }))
      }
    }, [activeToken])

    return {
      hasInitializedData,
      hasAttemptedRefresh,
      isLoading: isProcessing,
      errors,
      dataStatus
    }
  }

export { inferDataStatus }
export default useEntityData
