import * as React from 'react'
import type { BasicIconProps } from '~/types/utilities'

const
  BasicPentagon: React.FC<BasicIconProps> = ({ className, color = 'black', outlineColor }) => {
    return (
      <svg
        className={className}
        viewBox="-0.5 -1 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-cy="basic-pentagon">
        <path
          fill={color}
          stroke={outlineColor}
          strokeWidth="1.8px"
          strokeLinejoin="round"
          d="M14.5 0.572266L21.3183 5.68767L28.2903 10.5915L25.5323 18.6569L23.0229 26.803L14.5 26.6723L5.97711 26.803L3.46774 18.6569L0.709681 10.5915L7.68169 5.68767L14.5 0.572266Z"
        />
      </svg>
    )
  }

export default BasicPentagon
