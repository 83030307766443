import * as React from 'react'
import * as Sentry from '@sentry/react'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import { HEADER_TEXTS, TEACHING_HELP_PHONE_NUMBER, TEACHING_HELP_URL } from '~/utils/strings'
import RESEARCH_THEME from '~/components/ResearchTheme'
import { BrandedPage, Main } from '~/components/layouts'
import { HeadingOne as _HeadingOne, HintText, Link } from '~/components/atoms'

interface ErrorContentProps {
  errorTitle: string
  message: string
  children?: React.ReactNode
  bareMessage?: boolean
}

const
  HeadingOne = styled(_HeadingOne)`
    margin-bottom: ${p => p.theme.emSmallSpacing * 0.5}em;
  `,
  NarrowMain = styled(Main)`
    max-width: ${p => p.theme.emMaxReadingWidth * 1.25}em;
    margin: auto;
  `,
  LeadIn = styled.p`
    font-size: 1em;
    margin: ${p => p.theme.emBaseSpacing * 1.5}em 0 ${p => p.theme.emSmallSpacing}em 0;
  `,
  Ul = styled.ul`
    font-size: 1em;
    margin: 0 0 ${p => p.theme.emSmallSpacing}em 0;
  `,
  Li = styled.li`
  `,
  ContactUsMessage: React.FC<{ question?: string }> = ({ question }) => {
    const link = <Link to={`${TEACHING_HELP_URL}`} reloadDocument>contact support</Link>

    return <span>
      {question ?? 'Need help?'} Call {TEACHING_HELP_PHONE_NUMBER} or {link}
    </span>
  },
  ErrorPage: React.FC<ErrorContentProps> = ({ errorTitle, message, children, bareMessage }) => {
    return (
      <ThemeProvider theme={RESEARCH_THEME}>
        <BrandedPage
          documentTitle={`${errorTitle} | AgConnect`}
          whichHome="all"
          headerText={HEADER_TEXTS.public}>
          <NarrowMain>
            <HeadingOne color="alt">Oops!</HeadingOne>

            {bareMessage
              ? null
              : <HintText color="accent">An unexpected error has occurred:</HintText>}
            <HintText color="accent"><i>{message}</i></HintText>

            {children ?? <Ul><Li><ContactUsMessage /></Li></Ul>}
          </NarrowMain>
        </BrandedPage>
      </ThemeProvider>
    )
  },
  RouterErrorPage: React.FC = () => {
    const error = useRouteError()

    if (isRouteErrorResponse(error) && error.status === 404) {
      return <ErrorPage
        errorTitle="Page Not Found"
        message="Page Not Found">
        <HintText color="accent">{'Unfortunately, you have hit a dead end and will have to keep on exploring.'}</HintText>
        <LeadIn>{'We can help you find what you are looking for:'}</LeadIn>
        <Ul>
          <Li>Check the web address for typos</Li>
          <Li>Return to the <Link to="/">AgConnect home page</Link></Li>
          <Li><ContactUsMessage /></Li>
        </Ul>
      </ErrorPage>
    }

    Sentry.captureException(error)

    return (
      <ErrorPage
        errorTitle="Unexpected Error"
        message={(error as Response).statusText || (error as Error).message}>
        <LeadIn>Our team has been notified and will look into what happened</LeadIn>
        <Ul>
          <Li>Return to the <Link to="/">AgConnect home page</Link></Li>
          <Li><ContactUsMessage /></Li>
        </Ul>
      </ErrorPage>
    )
  }

export default RouterErrorPage
export { ErrorPage, ContactUsMessage, LeadIn, Ul, Li }
