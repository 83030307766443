import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { isEmpty, sortBy, values } from 'lodash'
import { useParams } from 'react-router-dom'
import { isEmptyValue } from '~/utils/testers'
import { useAppSelector, useUpdateEntity, useDeleteEntity } from '~/hooks'
import { selectExperimentById, selectLearners } from '~/features/entity/selectors'
import {
  Button,
  Icon,
  CheckmarkIcon,
  BasicCross,
  BasicTriangle,
  ListItem,
  ListItemCell,
  listItemIconStyles,
  ListItemLoadingIcon,
  ListItemCellDivider
} from '~/components/atoms'
import {
  EmptyContentPlaceholder,
  TextInput,
  ModalOverlay,
  DialogueContainer,
  ToClipboardButton,
  HeadingWithAdder,
  FormRenderer
} from '~/components/molecules'
import NewLearnerForm from '~/components/organisms/NewLearnerForm'

import type { TextInput as TextInputElement } from '~/features'
import type { ObjectId } from '~/types/utilities'

type SelectedLearnerState = [string | undefined, string | undefined, boolean]

const
  nameBodyElement: TextInputElement = {
    id: 'only',
    elementVariety: 'TEXT_INPUT',
    fieldName: 'displayId'
  },
  LearnerNameCell = styled(ListItemCell)`
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  `,
  StyledLinkIcon = styled(ToClipboardButton)`
    ${listItemIconStyles}
  `,
  StyledIcon = styled(Icon)`
    ${listItemIconStyles}
  `,
  DeleteIcon = styled(Icon)`
    width: 27px;
    margin-left: auto;
  `,
  StyledCheckmarkIcon = styled(CheckmarkIcon)`
    width: 40px;
    margin-left: auto;
  `,
  selectedInputClassName = '_project-group-name-edit-input',
  learnerNameInputProps = {
    dataType: 'string',
    name: 'displayId',
    pathToAttr: 'displayId',
    toggleHint: () => {},
    extraFieldConfig: {}
  },
  ProjectGroup: React.FC = () => {
    const
      { id } = useParams(),
      THEME = useTheme(),
      [isAdding, setIsAdding] = React.useState(false),
      [isDeleting, setIsDeleting] = React.useState(false),
      [
        [selectedLearnerId, selectedLearnerName, isError],
        setSelectedLearner
      ] = React.useState<SelectedLearnerState>([undefined, undefined, false]),
      { updateEntity, isUpdating } = useUpdateEntity(),
      { deleteEntity, isDeleting: isProcessingDelete } = useDeleteEntity(),
      experiment = useAppSelector(selectExperimentById(id)),
      learnersById = useAppSelector(selectLearners),
      learners = values(learnersById),
      relevantLearners = sortBy(
        learners.filter(learner => learner.experimentId === id),
        'createdAt'
      ),
      learner = selectedLearnerId ? learnersById[selectedLearnerId] : undefined,
      saveSelectedLearner = React.useCallback((e?: MouseEvent) => {
        if (e) { e.stopPropagation(); e.preventDefault() }
        if (!learner || !selectedLearnerName) { return }

        updateEntity({ entity: learner, newValues: { displayId: selectedLearnerName } })
          .then(() => { setSelectedLearner([undefined, undefined, false]) })
          .catch(() => { setSelectedLearner(val => [val[0], selectedLearnerName, true]) })
      }, [learner, selectedLearnerName, updateEntity]),
      onConfirmDelete: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }
        if (!learner) { return }

        deleteEntity(learner)
          .then(() => { setSelectedLearner([undefined, undefined, false]); setIsDeleting(false) })
          .catch(() => { setSelectedLearner(val => [val[0], selectedLearnerName, true]) })
      }

    React.useEffect(() => {
      if (selectedLearnerId) {
        const handleKeyPress = (e: KeyboardEvent): void => {
          if (document.activeElement?.closest(`.${selectedInputClassName}`)) {
            if (e.key === 'Escape') {
              setSelectedLearner([undefined, undefined, false])
            } else if (e.key === 'Enter') {
              saveSelectedLearner()
            }
          }
        }

        document.addEventListener('keydown', handleKeyPress)

        return () => {
          document.removeEventListener('keydown', handleKeyPress)
        }
      }
    }, [selectedLearnerId, setSelectedLearner, saveSelectedLearner])

    return <div>
      {isDeleting
        ? <ModalOverlay wholePage>
            <div style={{ width: `${THEME.emMediumContentWidth}em`, maxWidth: '100%' }}>
              <DialogueContainer
                subheading={`Remove ${selectedLearnerName as string} from group`}
                heading="Are you sure?"
                body={`Confirming will delete ${selectedLearnerName as string}'s account and all of their work. This action is irreversible.`}
                footerContent={
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                      label="Cancel"
                      isDisabled={isProcessingDelete}
                      onClick={() => { setIsDeleting(false) }}
                      color="back"
                      size="medium"
                    />
                    <Button
                      label="Delete"
                      isLoading={isProcessingDelete}
                      onClick={onConfirmDelete}
                      color="caution"
                      size="medium"
                    />
                  </div>
                }
              />
            </div>
          </ModalOverlay>
        : null}
      {isAdding
        ? <NewLearnerForm
            onCancel={() => { setIsAdding(false) }}
            experimentId={id as ObjectId}
          />
        : <HeadingWithAdder
            heading="Project Group"
            pointerLabel={
              isEmpty(relevantLearners)
                ? 'Add someone to the project'
                : 'Add another person'
            }
            onClick={() => {
              setIsAdding(true)
              setSelectedLearner([undefined, undefined, false])
            }}
          />}
      {isEmptyValue(relevantLearners)
        ? <div style={{ position: 'relative' }}>
            <EmptyContentPlaceholder colorKey="bodyBg" opacity={0.75}>
              <span>{'There\'s no one here!'}</span>
              <br />
              <span>You can use the plus button to add someone to this project.</span>
            </EmptyContentPlaceholder>
          </div>
        : relevantLearners.map(learner => {
          const
            isSelected = selectedLearnerId && learner.id === selectedLearnerId,
            onSelect: React.MouseEventHandler = () => {
              setSelectedLearner([learner.id, learner.displayId, false])
            },
            hasName = !isEmptyValue(selectedLearnerName)

          return <ListItem key={learner.id}>
            <LearnerNameCell
              tabIndex={isSelected ? undefined : 0}
              onClick={isSelected ? undefined : onSelect}>
              {isSelected
                ? <TextInput
                    {...learnerNameInputProps}
                    bodyElement={nameBodyElement}
                    formOptions={{ RootFormRenderer: FormRenderer }}
                    className={selectedInputClassName}
                    currentValue={selectedLearnerName}
                    isReadOnly={!!isUpdating || isProcessingDelete}
                    updateAttr={newValue => {
                      setSelectedLearner([learner.id, newValue as string, false])
                    }}
                    {...(isError
                      ? { showError: true, error: ['unable to make this change, something went wrong'] }
                      : hasName
                        ? {}
                        : { showError: true, error: ['cannot be blank'] }
                    )}
                  />
                : learner.displayId}
              {!isSelected
                ? null
                : !!isUpdating || isProcessingDelete
                    ? <><ListItemLoadingIcon />Saving</>
                    : <>
                      <DeleteIcon
                        accessibleDescription="Delete learner"
                        content={BasicCross}
                        degreesRotation={45}
                        color="caution"
                        frameStyle="circle"
                        onClick={() => { setIsDeleting(true) }}
                      />
                      <StyledCheckmarkIcon
                        accessibleDescription="Save & close"
                        {...(hasName ? { onClick: saveSelectedLearner } : {})}
                      />
                    </>}
            </LearnerNameCell>
            <ListItemCellDivider />
            <ListItemCell>
              Access Link
              <StyledLinkIcon
                color={experiment?.closedAt ? 'disabled' : 'back'}
                linkTitle="Click to copy the access link to your clipboard"
                linkUrl={!!experiment?.closedAt || !learner.token ? undefined : `${window.location.origin}/project-access/${learner.token}`}
              />
            </ListItemCell>
            <ListItemCellDivider />
            <ListItemCell>
              Responses
              <StyledIcon
                content={BasicTriangle}
                degreesRotation={90}
                color={experiment?.startedAt ? 'back' : 'disabled'}
                frameStyle="circle"
                {...(experiment?.startedAt
                  ? { linkProps: { to: `/projects/${id as ObjectId}/responses?learnerId=${learner.id}` } }
                  : {})}
              />
            </ListItemCell>
          </ListItem>
        })}
    </div>
  }

export default ProjectGroup
