import * as React from 'react'
import styled from 'styled-components'
import { CONDITIONAL_QUESTION_ICON, MISSING_INTERPOLATION_PLACEHOLDER } from '~/utils/strings'

interface EditorKeyProps {
  className?: string
}

const
  Outer = styled.div`
    margin-top: auto;
    padding: 0px ${p => p.theme.emBaseSpacing}em;
    font-size: 0.9em;
    color: ${p => p.theme.colors.understateDark};
  `,
  KeyItem = styled.div`
    margin-top: ${p => p.theme.emSmallSpacing}em;
  `,
  Sup = styled.span`
    vertical-align: super;
    color: ${p => p.theme.colors.bodyText};
  `,
  EditorKey: React.FC<EditorKeyProps> = ({ className }) => {
    return <Outer className={className}>
      <KeyItem><Sup>{CONDITIONAL_QUESTION_ICON}</Sup> The indicated question is only shown to the learner when certain conditions are met (see content tab for details).</KeyItem>
      <KeyItem>A phrase surrounded by -- will be replaced by a learner&apos;s answer from a previous question. Where {MISSING_INTERPOLATION_PLACEHOLDER} appears in the preview, there is currently no value to show. To see what the learner will see, find that question in the editor (it may be in a different step) and answer it. Your example answer will appear here.</KeyItem>
    </Outer>
  }

export default EditorKey
