import * as React from 'react'
import { isAllEmpty } from './testers'
import { defaultResolvers, FormContext, FORM_STATUSES } from './FormContext'
import {
  getStateOnSubmitInitiated,
  getStateOnUpdatedValues,
  handleFormStatusChange
} from './helpers'

import type { EntityObject, FormOptions, FormProps, FormSubmitFunction, ToggleFn, UpdateErrorsFn, UpdateValuesFn } from './types'
import type { FormState, FormContextType } from './internalTypes'
import { isEqual } from 'lodash'

const
  fakeAndTypeCoercedFormOptions = {} as unknown as FormOptions,
  getInitialState = (props: FormProps): FormState => {
    return {
      values: props.initialValues ?? {},
      initialValues: props.initialValues ?? {},
      scope: props.baseScope ?? '',
      schema: props.entitySchema ?? {},
      formOptions: props.formOptions ?? fakeAndTypeCoercedFormOptions,
      activeFormId: props.formId,

      formStatus: FORM_STATUSES.CLEAN,
      showingAllMissing: false,
      submissionSource: undefined,
      showingAllErrors: !isAllEmpty(props.initialErrors),
      errors: props.initialErrors ?? {},
      result: {},

      resolveKey: props.resolveKey,
      resolveHint: props.resolveHint ?? defaultResolvers.resolveHint,
      resolveList: props.resolveList ?? defaultResolvers.resolveList,
      resolveLabel: props.resolveLabel ?? defaultResolvers.resolveLabel,
      resolvePlaceholder: props.resolvePlaceholder ?? defaultResolvers.resolvePlaceholder,
      resolveUnit: props.resolveUnit ?? defaultResolvers.resolveUnit,
      resolveCriteria: props.resolveCriteria ?? defaultResolvers.resolveCriteria,
      validateObject: props.validateObject ?? defaultResolvers.validateObject
    }
  },
  Form: React.FC<FormProps> = (props) => {
    const
      [_state, setState] = React.useState<FormState>(getInitialState(props)),
      parentalValues = React.useRef<EntityObject | undefined>(props.initialValues),
      newInitialValuesReceived = !isEqual(props.initialValues, parentalValues.current),
      previousFormStatus = React.useRef(_state.formStatus),
      { values, formStatus, result } = _state,
      { onSuccessCallback, onSubmitCallback, onErrorCallback, onAttrChangeCallback, subscribeToFormStatus, FormRenderer } = props,
      toggleShowAllMissing: ToggleFn = React.useCallback(() => {
        setState(state => ({ ...state, showMissing: !state.showingAllMissing }))
      }, []),
      toggleShowAllErrors: ToggleFn = React.useCallback(() => {
        setState(state => ({ ...state, showingAllErrors: !state.showingAllErrors }))
      }, []),
      updateValues: UpdateValuesFn = React.useCallback(({ newValues, silentUpdate }) => {
        setState(state => getStateOnUpdatedValues({ state, newValues, silentUpdate, onChange: props.onChangeCallback }))
      }, [props.onChangeCallback]),
      updateErrors: UpdateErrorsFn = React.useCallback(({ newErrors }) => {
        setState(state => ({ ...state, errors: { ...state.errors, ...newErrors } }))
      }, []),
      triggerFormSubmission: FormSubmitFunction = React.useCallback((event, submissionSource) => {
        if (event) { event.stopPropagation(); event.preventDefault() }

        setState(state => getStateOnSubmitInitiated({ state, event, submissionSource, beforeSubmit: props.beforeSubmitCallback }))
      }, [props.beforeSubmitCallback]),
      formContext: FormContextType = React.useMemo(() => ({
        ..._state,
        updateValues,
        updateErrors,
        toggleShowAllMissing,
        toggleShowAllErrors,
        triggerFormSubmission,
        onAttrChangeCallback
      }), [_state, updateValues, updateErrors, toggleShowAllMissing, toggleShowAllErrors, triggerFormSubmission, onAttrChangeCallback])

    /** Informs the parent of any changes to their subscribed values */
    React.useEffect(() => {
      if (subscribeToFormStatus) { subscribeToFormStatus({ formStatus: _state.formStatus, submissionSource: _state.submissionSource, values: _state.values }) }
    }, [subscribeToFormStatus, _state.formStatus, _state.submissionSource, _state.values])

    /** Allows programmatic submission from the parent */
    React.useImperativeHandle(props.methodRef, () => ({
      triggerFormSubmission,
      toggleShowAllMissing,
      toggleShowAllErrors
    }), [triggerFormSubmission, toggleShowAllErrors, toggleShowAllMissing])

    /** Updates the form's entire state appropriately for
     *  the status transitions that is ocurring */
    React.useEffect(() => {
      if (formStatus !== previousFormStatus.current) {
        handleFormStatusChange({ onSubmitCallback, onSuccessCallback, onErrorCallback, values, formStatus, result, setState, submissionSource: _state.submissionSource })
      }
      previousFormStatus.current = formStatus
    }, [formStatus, previousFormStatus, _state.submissionSource, values, onErrorCallback, onSubmitCallback, onSuccessCallback, result])

    /** Keeps formOptions in sync with passed in values */
    React.useEffect(() => {
      const
        newOptions = props.formOptions && !isEqual(props.formOptions, _state.formOptions),
        newSchema = props.entitySchema && !isEqual(props.entitySchema, _state.schema)

      if (!!newOptions || newSchema) {
        setState(state => ({
          ...state,
          formOptions: newOptions ? (props.formOptions ?? fakeAndTypeCoercedFormOptions) : state.formOptions,
          schema: newSchema ? (props.entitySchema ?? {}) : state.schema
        }))
      }
    }, [props.formOptions, props.entitySchema, _state.formOptions, _state.schema])

    /** Ensures that form shows updated values from parent as soon
     * as possible without interrupting submission state flow */
    React.useEffect(() => {
      if (newInitialValuesReceived && formStatus !== 'processing') {
        /* keep log */ console.log('resetting form state')
        setState(state => ({
          ...state,
          formStatus: state.formStatus === 'success' ? FORM_STATUSES.CLEAN_AFTER_SUCCESS : FORM_STATUSES.CLEAN,
          values: props.initialValues ?? state.values
        }))
        parentalValues.current = props.initialValues
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newInitialValuesReceived, formStatus])

    /** Ensures that the entire form is reset as soon as possible after
     *  after its ID changes, again without interrupting subsmission state flow.
     *  This provides a mechanism for the parent to reset the form. */
    React.useEffect(() => {
      if (props.formId !== _state.activeFormId && formStatus !== 'processing') {
        setState({
          ...getInitialState(props),
          formStatus: formStatus === 'success' ? FORM_STATUSES.CLEAN_AFTER_SUCCESS : FORM_STATUSES.CLEAN
        })
      }
    }, [props.formId, formStatus, setState, props, _state.activeFormId])

    return <FormContext.Provider value={formContext}>
      <FormRenderer formId={_state.activeFormId} formBody={props.formBody} className={props.className} />
    </FormContext.Provider>
  }

export default Form
