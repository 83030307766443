import type { TemplateSet } from './types'

export const
  getTemplateStatusString = (templateSet: TemplateSet): string => {
    if (templateSet.archivedAt) {
      return 'Archived'
    }

    if (templateSet.publishedAt) {
      return 'Published'
    }

    return 'Draft'
  }
