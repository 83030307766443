import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

export type MeasurementTimingScheme = typeof MEASUREMENT_TIMING_SCHEMES[keyof typeof MEASUREMENT_TIMING_SCHEMES]

const
  MEASUREMENT_TIMING_SCHEMES = {
    ONCE: 'once',
    REPEATING: 'repeating'
  } as const,
  title = 'Whether one measurement will be taken (per subject) or multiple',
  description = 'This is used to tailor language, build a schedule for the experiment, and calculate information like the total number of measurements that will be taken during the experiment.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.measurementTimingScheme,
    fieldType: 'ENUM',
    options: [
      { optionValue: MEASUREMENT_TIMING_SCHEMES.ONCE },
      { optionValue: MEASUREMENT_TIMING_SCHEMES.REPEATING }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.measurementTimingScheme,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails,
  MEASUREMENT_TIMING_SCHEMES
}
