import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import { fieldDetails as seedsPerPlant } from './seedsPerPlant'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Expected thinning time (days after germination)',
  description = 'This is used to build a schedule for the experiment.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.thinningDay,
    fieldType: 'NUMBER',
    visibleWhen: [
      { valueName: seedsPerPlant.fieldName, conditionType: 'present' },
      { valueName: seedsPerPlant.fieldName, conditionType: 'gt', testValue: 1 }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.thinningDay,
    variableGroup: SystemFieldGroups.SCHEDULE,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
