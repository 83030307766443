import * as React from 'react'
import styled from 'styled-components'
import {
  Icon,
  BasicCross,
  HeadingTwo
} from '~/components/atoms'
import ActionPointer from './ActionPointer'

interface Props {
  onClick?: React.MouseEventHandler
  heading: string
  pointerLabel: string
}

const
  HeadingRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: auto;
    align-items: center;
    margin-bottom: 1em;
  `,
  StyledPointer = styled(ActionPointer)`
    width: unset;
    margin-left: auto;
    transform: scale(1.2);
    transform-origin: right;
  `,
  ProjectGroup: React.FC<Props> = ({ onClick, heading, pointerLabel }) => {
    return <HeadingRow>
      <HeadingTwo>{heading}</HeadingTwo>
      <StyledPointer
        label={pointerLabel}
        orientation="right"
        icon={
          <Icon
            content={BasicCross}
            color="back"
            frameStyle="circle"
            onClick={onClick}
          />
        }
      />
    </HeadingRow>
  }

export default ProjectGroup
