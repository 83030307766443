import { existingStepSchema } from './baseSchemas'
import { MASCOT_VARIANTS_LIST } from './optionLists'
import { blurbOrCheckpointSchema } from './elementSchemas'
import type { EntitySchema } from '~/form-brain2'
import type { AnyPlainOrInputElement, QuestionnaireFormat, SpecialLayoutType } from '~/features/questionnaire/types'
import { advancedPageFormFields, existingStepFormBody } from './baseFormBodies'
import { blurbFormBody } from './elementFormBodies'

export const
  listStepSchema: EntitySchema = {
    ...existingStepSchema,
    pageHint: {
      fieldName: 'pageHint',
      fieldType: 'STRING',
      visibleWhen: [{ conditionType: 'equals', valueName: 'format', testValue: 'LIST' as QuestionnaireFormat }]
    },
    mascotVariant: {
      fieldName: 'mascotVariant',
      fieldType: 'ENUM',
      visibleWhen: [
        { conditionType: 'equals', valueName: 'format', testValue: 'LIST' as QuestionnaireFormat },
        { conditionType: 'present', valueName: 'pageHint' },
        { conditionType: 'wordLengthAtLeast', valueName: 'pageHint', testValue: 1 }
      ]
    },
    sortDirection: {
      fieldName: 'sortDirection',
      fieldType: 'ENUM'
    },
    reverseStartAndEnd: {
      fieldName: 'reverseStartAndEnd',
      fieldType: 'BOOLEAN'
    },
    /** No longer used */
    // numberAllEntries: {
    //   fieldName: 'numberAllEntries',
    //   fieldType: 'BOOLEAN',
    //   visibleWhen: [{ conditionType: 'equals', valueName: 'format', testValue: 'LIST' as QuestionnaireFormat }]
    // },
    walkthrough: {
      fieldName: 'walkthrough',
      fieldType: 'COLLECTION',
      visibleWhen: [{ conditionType: 'equals', valueName: 'format', testValue: 'LIST' as QuestionnaireFormat }],
      format: {
        sectionId: {
          fieldName: 'sectionId',
          fieldType: 'STRING',
          requiredWhen: [{ conditionType: 'always' }]
        },
        intro: {
          fieldName: 'intro',
          fieldType: 'SCOPE',
          requiredWhen: [{ conditionType: 'absent', valueName: 'outro', conditionScope: 'ownScope' }],
          format: blurbOrCheckpointSchema
        },
        outro: {
          fieldName: 'outro',
          fieldType: 'SCOPE',
          requiredWhen: [{ conditionType: 'absent', valueName: 'intro', conditionScope: 'ownScope' }],
          format: {
            ...blurbOrCheckpointSchema,
            showWithPrevious: {
              fieldName: 'showWithPrevious',
              fieldType: 'BOOLEAN'
            }
          }
        }
      }
    },
    defaults: {
      fieldName: 'defaults',
      fieldType: 'COLLECTION',
      visibleWhen: [
        { conditionType: 'equals', valueName: 'format', testValue: 'LIST' as QuestionnaireFormat },
        { conditionType: 'present', valueName: 'specialLayoutType' },
        { conditionType: 'anyOf', valueName: 'specialLayoutType', testValue: ['MATERIALS', 'SCHEDULE', 'SET_UP'] as SpecialLayoutType[] },
        { conditionType: 'lengthAtLeast', valueName: 'repeatingSections', testValue: 1 }
      ]
    }
    // openingSection?: OpeningSectionElement
    // repeatingSections: RepeatingSectionElement[]
    // closingSection?: ClosingSectionElement
    // offerPrintableResults?: boolean // Not yet supported
  },
  // TODO Special list format schemas including:
  // defaults?: ListQuestionnaireDefault[],
  walkthroughIntroOutroFormBody: AnyPlainOrInputElement[] = [
    {
      id: 'walkthroughIntroFormat',
      fieldName: 'elementVariety',
      elementVariety: 'SELECT_INPUT',
      label: 'Include checkpoint?',
      hint: 'With a checkpoint, the learner clicks a button to move on',
      showHintAlways: true,
      visibleWhen: [{ conditionType: 'always' }],
      list: [{
        optionValue: 'BLURB',
        optionLabel: 'No checkpoint'
      }, {
        optionValue: 'PAGE',
        optionLabel: 'Yes'
      }]
    },
    {
      id: 'checkpointLabel',
      fieldName: 'checkpointButtonLabel',
      hint: 'The text for the checkpoint button. Leave this blank to use the default "Next" button label for the group.',
      label: 'Checkpoint button label',
      elementVariety: 'TEXT_INPUT',
      visibleWhen: [
        { conditionType: 'equals', valueName: 'elementVariety', testValue: 'PAGE' },
        { conditionType: 'present', valueName: 'elementVariety' }
      ]
    },
    {
      id: 'checkpointBody',
      elementVariety: 'COLLECTION',
      fieldName: 'body',
      maximumLength: 1,
      minimumLength: 1,
      entryNameSingular: 'checkpoint blurb',
      entryNamePlural: 'checkpoint blurbs',
      hideLabel: true,
      visibleWhen: [
        { conditionType: 'equals', valueName: 'elementVariety', testValue: 'PAGE' },
        { conditionType: 'present', valueName: 'elementVariety' }
      ],
      formBody: blurbFormBody
    },
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    ...blurbFormBody.map(o => ({
      ...o,
      visibleWhen: [
        { conditionType: 'equals', valueName: 'elementVariety', testValue: 'BLURB' },
        { conditionType: 'present', valueName: 'elementVariety' }
      ]
    } as AnyPlainOrInputElement)),
    {
      id: 'walkthroughStageShowWithPrevious',
      elementVariety: 'CHECKBOX_INPUT',
      fieldName: 'showWithPrevious',
      hint: 'By default, a checkpoint outro is not shown until the form is submitted. Check this box to start showing the outro earlier, before the form is submitted.',
      label: 'Show outro while form is being completed',
      visibleWhen: [
        { conditionType: 'equals', valueName: 'elementVariety', testValue: 'PAGE' },
        { conditionType: 'present', valueName: 'elementVariety' }
      ]
    }
  ],
  listStepFormBody: AnyPlainOrInputElement[] = [
    ...existingStepFormBody,
    {
      id: 'listStepOptions',
      elementVariety: 'COLLAPSIBLE',
      expandButtonLabel: 'Show List Options',
      collapseButtonLabel: 'Hide List Options',
      body: [
        {
          id: 'listHintText',
          elementVariety: 'LONG_ANSWER',
          fieldName: 'pageHint',
          label: 'Base hint:',
          hint: 'This text will be shown in the mascot\'s speech bubble on the list page by default, such as when none of the list entries are being edited or when the active section doesn\'t have its own hint.'
        },
        {
          id: 'listMascot',
          elementVariety: 'SELECT_INPUT',
          fieldName: 'mascotVariant',
          hint: 'Which version of the mascot should be shown on the list page by default?',
          label: 'Base mascot:',
          noSort: true,
          blankOption: 'None',
          list: MASCOT_VARIANTS_LIST
        },
        {
          id: 'listMascotPreview',
          elementVariety: 'SPECIAL',
          specialElementId: 'mascotPreview',
          textKey: 'pageHint',
          variantKey: 'mascotVariant'
        },
        {
          id: 'listSortDirection',
          elementVariety: 'SELECT_INPUT',
          fieldName: 'sortDirection',
          hint: 'Overrides the default order of list entries that are sorted by date, title, etc.',
          label: 'Sort direction:',
          noSort: true,
          blankOption: 'Automatic',
          visibleWhen: [{ conditionType: 'noneOf', valueName: 'specialLayoutType', testValue: ['ANALYSIS', 'MATERIALS', 'SET_UP', 'SCHEDULE'] as SpecialLayoutType[] }],
          list: [{
            optionValue: 'ascending',
            optionLabel: 'Ascending'
          }, {
            optionValue: 'descending',
            optionLabel: 'Descending'
          }]
        },
        {
          id: 'listReverseStartAndEnd',
          elementVariety: 'CHECKBOX_INPUT',
          fieldName: 'reverseStartAndEnd',
          hint: 'This moves the "add" button and new entry form to the top and inserts new entries at the beginning of the list instead of the end.',
          label: 'Reverse list layout'
        },
        {
          id: 'listDefaultsList',
          elementVariety: 'COLLECTION',
          fieldName: 'defaults',
          label: 'Default List',
          noInitialEntry: true,
          leader: {
            id: 'defaultsListLeader',
            elementVariety: 'INSTRUCTIONS',
            paragraphs: [
              'Some steps give learners the option to build a starter list automatically based on their experimental design. The items you add here appear in the resulting list.'
            ]
          },
          entryNamePlural: 'entries',
          entryNameSingular: 'entry',
          encloseEntries: true,
          formBody: [
            {
              id: 'listDefaultSectionId',
              elementVariety: 'SELECT_INPUT',
              fieldName: 'sectionId',
              label: 'Type of entry',
              invalidates: ['automaticEntryKey', 'entryValues'],
              noSort: true
            },
            {
              id: 'listDefaultAutoEntryKey',
              elementVariety: 'SELECT_INPUT',
              fieldName: 'automaticEntryKey',
              label: 'Use special entry',
              invalidates: ['entryValues'],
              blankOption: '(No)',
              noSort: true,
              hideWhenListEmpty: true,
              visibleWhen: [{ conditionType: 'present', valueName: 'sectionId', conditionScope: 'ownScope' }]
            },
            {
              id: 'listDefaultEntryValues',
              elementVariety: 'SCOPE',
              fieldName: 'entryValues',
              label: 'Custom Content',
              hideDeleteButton: true,
              visibleWhen: [{ conditionType: 'present', valueName: 'sectionId', conditionScope: 'ownScope' }],
              // noInitialEntry: true,
              formBody: []
            },
            {
              id: 'listDefaultVisibleWhen',
              fieldName: 'visibleWhen',
              elementVariety: 'COLLECTION',
              label: 'Visibility',
              labelAsHeading: true,
              emptyListelement: {
                id: 'visibleWhen',
                elementVariety: 'INSTRUCTIONS',
                paragraphs: ['Always visible']
              },
              noInitialEntry: true,
              understatedAdder: true,
              // offsetEntries: true,
              // encloseEntries: true,
              editInline: true,
              entryNamePlural: 'conditions',
              entryNameSingular: 'condition',
              useAlternateEntryRenderer: 'conditionList',
              formBody: []
            }
          ]
        },
        {
          id: 'listWalkthroughSettings',
          elementVariety: 'COLLECTION',
          fieldName: 'walkthrough',
          label: 'Walkthrough',
          noInitialEntry: true,
          leader: {
            id: 'walkthroughSettingsLeader',
            elementVariety: 'INSTRUCTIONS',
            paragraphs: [
              'Add a walkthrough when you want to the learner to fill out certain sections in a certain order or want to provide extra context or instructions the first time a section or sections are filled out.'
            ]
          },
          /** List and visibleWhen are set dynamically in useStepFormBody */
          entryNameSingular: 'walkthrough stage',
          entryNamePlural: 'walkthrough stages',
          encloseEntries: true,
          formBody: [
            {
              id: 'walkthroughStepSectionId',
              elementVariety: 'SELECT_INPUT',
              fieldName: 'sectionId',
              hint: 'Each walkthrough stage will prompt the learner to fill out one list entry for the section selected here',
              label: 'Section for this stage:',
              noSort: true
            },
            {
              id: 'walkthroughIntroSettings',
              elementVariety: 'SCOPE',
              fieldName: 'intro',
              label: 'Intro (shown before form)',
              noInitialEntry: true,
              offsetContent: true,
              formBody: walkthroughIntroOutroFormBody.filter(o => o.id !== 'walkthroughStageShowWithPrevious')
            },
            {
              id: 'walkthroughOutroSettings',
              elementVariety: 'SCOPE',
              fieldName: 'outro',
              label: 'Outro (shown after form)',
              noInitialEntry: true,
              offsetContent: true,
              formBody: walkthroughIntroOutroFormBody
            }
          ]
        }
      ]
    },
    {
      id: 'listFormatAdvancedCollapsible',
      elementVariety: 'COLLAPSIBLE',
      expandButtonLabel: 'Show Advanced Options',
      collapseButtonLabel: 'Hide Advanced Options',
      body: advancedPageFormFields
    }
  ]
