import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { useFormVitalsSubscription } from '~/hooks'
import { ArrowStraight, FormLabel, HintText } from '~/components/atoms'
import StepSettingsForm from '../StepManagement/StepSettingsForm'

import type { ListWorksheet, PagedWorksheet, Experiment, Worksheet, ListTemplate, PagedTemplate, TemplateSet, Template } from '~/features'
import FormatWizard from './FormatWizard'
import TemplateSettingsForm from '../TemplateManagement/TemplateSettingsForm'

interface PropWithWorksheet {
  questionnaire: ListWorksheet | PagedWorksheet
  allQuestionnaires: Worksheet[]
  questionnaireGroup: Experiment
}

interface PropsWithTemplate {
  questionnaire: ListTemplate | PagedTemplate
  allQuestionnaires: Template[]
  questionnaireGroup: TemplateSet
}

type Props = PropWithWorksheet | PropsWithTemplate

const
  InactiveRow = styled.div<{ $disabled?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${p => p.theme.emBaseSpacing}em;

    background: ${p => p.theme.colors.containerAccentBg};
    padding: 1em;

    opacity: ${p => p.$disabled ? '0.5' : '1'};
    cursor: ${p => p.$disabled ? 'not-allowed' : 'pointer'};

    &:not(&:first-child) {
      margin-top: ${p => p.theme.emBaseSpacing}em;
    }

    &:first-child {
      margin-bottom: ${p => p.theme.emSmallSpacing}em;
    }
  `,
  ForwardArrow = styled(ArrowStraight)`
    height: 1.2rem;
  `,
  RowDescriptor = styled(HintText)`
    margin-left: auto;
  `,
  QuestionnaireSettings: React.FC<Props> = ({ questionnaire, questionnaireGroup, allQuestionnaires }) => {
    const
      THEME = useTheme(),
      { formStatus, subscribeToFormStatus } = useFormVitalsSubscription(),
      hasFormat = ['PAGED', 'LIST'].includes(questionnaire.format),
      isWorksheet = questionnaire.type === 'worksheet',
      [activeSection, setActiveSection] = React.useState<'settings' | 'format'>(hasFormat && isWorksheet ? 'format' : 'settings'),
      formIsClean = !formStatus || formStatus === 'clean' || formStatus === 'cleanAfterSuccess'

    return <>
      {hasFormat
        ? activeSection === 'format'
          ? isWorksheet
            ? <FormatWizard
                questionnaire={questionnaire as ListWorksheet | PagedWorksheet}
                allQuestionnaires={allQuestionnaires as Array<ListWorksheet | PagedWorksheet>}
                questionnaireGroup={questionnaireGroup as Experiment}
                {...{ formStatus, subscribeToFormStatus }}
              />
            : <FormatWizard
              questionnaire={questionnaire as ListTemplate | PagedTemplate}
              allQuestionnaires={allQuestionnaires as Array<ListTemplate | PagedTemplate>}
              questionnaireGroup={questionnaireGroup as TemplateSet}
              {...{ formStatus, subscribeToFormStatus }}
            />
          : <InactiveRow onClick={formIsClean ? () => { setActiveSection('format') } : undefined} $disabled={!formIsClean}>
            <FormLabel
              label="Step Format"
              showLabel
              asHeading
            />
            {formIsClean
              ? null
              : <HintText color="default">Save changes to settings first</HintText>}
            <RowDescriptor color="default">Click here to edit the content of the step</RowDescriptor>
            {formIsClean
              ? <ForwardArrow direction="right" color={THEME.colors.back} />
              : null}
          </InactiveRow>
        : null}
      {activeSection === 'settings'
        ? isWorksheet
          ? <StepSettingsForm
              worksheet={questionnaire as ListWorksheet | PagedWorksheet}
              experiment={questionnaireGroup as Experiment}
              allWorksheets={allQuestionnaires as Array<ListWorksheet | PagedWorksheet>}
              { ...{ formStatus, subscribeToFormStatus }}
            />
          : <TemplateSettingsForm
            template={questionnaire as ListTemplate | PagedTemplate}
            templateSet={questionnaireGroup as TemplateSet}
            allTemplates={allQuestionnaires as Array<ListTemplate | PagedTemplate>}
            { ...{ formStatus, subscribeToFormStatus }}
          />
        : <InactiveRow onClick={formIsClean ? () => { setActiveSection('settings') } : undefined} $disabled={!formIsClean}>
          <FormLabel
            label={isWorksheet ? 'Step Settings' : 'Step Template Settings'}
            showLabel
            asHeading
          />
          {formIsClean
            ? null
            : <HintText color="default">Save changes to format first</HintText>}
          <RowDescriptor color="default">Click here for general step settings</RowDescriptor>
          {formIsClean
            ? <ForwardArrow direction="right" color={THEME.colors.back} />
            : null}
        </InactiveRow>}
    </>
  }

export default QuestionnaireSettings
