import * as React from 'react'
import styled from 'styled-components'
import { difference, flatten, mapValues, sortBy, truncate, uniq, values } from 'lodash'
import { isAllEmpty } from '~/utils/testers'
import { getDataPoints, getSummaryStats, getValueAcrossEntities, getGroupedValues, SystemFieldNames } from '~/features'
import { getNewEntryInitialValues } from '../ListStep/helpers'
import { BasicCross, Button, HintText, Icon, Table, TBody, TD, TH, THead, TR } from '~/components/atoms'
import { floatString, symbolSafeStartCase } from '~/utils/formatters'
import { FormRenderer, Textarea } from '~/components/molecules'
import { useUpdateEntity } from '~/hooks'

import type { Experiment, SpecialListSectionElement, ListEntry, ListResponse, SubjectValue, InputElement, RepeatingSectionElement } from '~/features'
import type { EntityObject } from '~/form-brain2'
import type { SummaryStatName } from '~/features/questionnaire/types/sectionElementTypes'

interface Props {
  experiment: Experiment
  section: SpecialListSectionElement
  response?: ListResponse
  otherEntities: EntityObject[]
  entry: ListEntry
  isReadOnly?: boolean
  shrinkFinalColumn?: boolean
}

interface RowProps {
  /* Not used in readonly version */
  response?: ListResponse
  groupName: string
  groupStats: ReturnType<typeof getSummaryStats>
  subjectValues?: SubjectValue[]
  includedStats: SummaryStatName[]
  entry: ListEntry | undefined
  rowIndex: number
  setSelectedRow: React.Dispatch<React.SetStateAction<number | undefined>>
  measurementUnit: string
  isReadOnly?: boolean
  shrinkFinalColumn?: boolean
  section: SpecialListSectionElement
  otherEntities: EntityObject[]
}

interface EditableRowProps extends RowProps {
  response: ListResponse
}

const
  TableWrapper = styled.div`
    background: ${p => p.theme.colors.bodyBg};
    border-radius: 1em;
  `,
  StyledTable = styled(Table)`
    width: 100%;
  `,
  ClickableRow = styled(TR)`
    cursor: pointer;
  `,
  FinalColumnCell = styled(TD)<{ $shrink?: boolean }>`
    width: ${p => p.$shrink ? 10 : 40}%;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 2px;
    text-align: right;
  `,
  ExpandIcon = styled(Icon)`
    flex: 0 0 1.5em;
    width: 1.5em;
    margin-left: auto;
  `,
  RowButton = styled(Button)`
    margin-right: 4px;
  `,
  SubjectRow = styled.div`
    font-size: 1.1em;
    width: 100%;
    text-align: left;
  `,
  SubjectName = styled.span<{ $understate?: boolean }>`
    color: ${p => p.theme.colors.bodyUnderstateText};
    ${p => p.$understate ? 'opacity: 0.5;' : ''}
  `,
  SubjectDetail = styled.span<{ $understate?: boolean }>`
    ${p => p.$understate ? `opacity: 0.5; color: ${p.theme.colors.bodyUnderstateText};` : ''}
  `,
  OutlierButton = styled(Button)`
    font-size: 0.7em;
    margin-left: ${p => p.theme.emSmallSpacing}em;
  `,
  NotesInput = styled(Textarea)`
    margin-top: ${p => p.theme.emSmallSpacing}em;
  `,
  NotesContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      flex: 0 1 auto;
      min-width: 0px;

      @media(max-width: 600px) {
        width: 0;
        height: 0;
        overflow: hidden;
      }
    }
  `,
  Footnote = styled(HintText)`
    text-align: left;
    padding: ${p => p.theme.emSmallSpacing}em;
  `,
  addOrReplaceEntry = ({ entries, existingEntry, newValues }: {
    entries: ListEntry[]
    existingEntry: ListEntry | undefined
    newValues: ListEntry
    section: RepeatingSectionElement
    otherEntities: EntityObject[]
  }): ListEntry[] => {
    if (!entries.find(o => o === existingEntry)) {
      return [
        ...entries,
        newValues
      ]
    }

    return entries.map(e => {
      return e === existingEntry
        ? newValues
        : e
    })
  },
  OpenRow: React.FC<EditableRowProps> = ({ groupName, groupStats, subjectValues, includedStats, entry, response, setSelectedRow, measurementUnit, isReadOnly, shrinkFinalColumn, section, otherEntities }) => {
    const
      subjectNotes = (entry?.subjectNotes ?? {}) as Record<string, string>,
      outlierSubjectIds = (entry?.outlierSubjectIds ?? []) as string[],
      [notes, setNotes] = React.useState<string>(subjectNotes[groupName] ?? ''),
      [updatingSubjectId, setUpdatingSubjectId] = React.useState<string | undefined>(),
      { updateEntity, isUpdating } = useUpdateEntity(),
      updateWithNewEntries = (newEntries: ListEntry[]): void => {
        void updateEntity({
          entity: response,
          newValues: {
            responses: {
              entries: newEntries
            }
          }
        })
      },
      onSaveNotes: React.MouseEventHandler = (e) => {
        if (e) { e.stopPropagation(); e.preventDefault() }

        const newEntries = addOrReplaceEntry({
          entries: response.responses.entries,
          existingEntry: entry,
          newValues: {
            ...(entry ?? getNewEntryInitialValues(section, response.responses.entries, otherEntities)),
            subjectNotes: { ...subjectNotes, [groupName]: notes }
          },
          section,
          otherEntities
        })

        updateWithNewEntries(newEntries)
      },
      onMarkOutlier = (subjectId: string): React.MouseEventHandler => (e) => {
        if (e) { e.stopPropagation(); e.preventDefault() }

        const newEntries = addOrReplaceEntry({
          entries: response.responses.entries,
          existingEntry: entry,
          newValues: {
            ...(entry ?? getNewEntryInitialValues(section, response.responses.entries, otherEntities)),
            outlierSubjectIds: outlierSubjectIds.includes(subjectId) ? difference(outlierSubjectIds, [subjectId]) : [...outlierSubjectIds, subjectId]
          },
          section,
          otherEntities
        })

        updateWithNewEntries(newEntries)
        setUpdatingSubjectId(subjectId)
      },
      onCancel: React.MouseEventHandler = (e) => { e.preventDefault(); e.stopPropagation(); setSelectedRow(undefined) }

    return <>
      <TR $withBackground $noBottomBorder>
        <TH>{groupName}</TH>
        {!isAllEmpty(groupStats)
          ? includedStats.map(statName => <TD key={statName}>{groupStats[statName]} {measurementUnit}</TD>)
          : <TD colSpan={includedStats.length}>
            No Data
          </TD>}
        {isReadOnly
          ? <FinalColumnCell $shrink={shrinkFinalColumn}>
              <RowButton size="small" label="Close" color="back" onClick={onCancel} />
            </FinalColumnCell>
          : <FinalColumnCell $shrink={shrinkFinalColumn}>
            <RowButton size="small" label="Cancel" color="caution" isDisabled={!!isUpdating || !!updatingSubjectId} onClick={onCancel} />
            <RowButton size="small" label="Save" color="forward" onClick={onSaveNotes} isLoading={isUpdating && !updatingSubjectId} isDisabled={!!updatingSubjectId} />
          </FinalColumnCell>}
      </TR>
      <TR $withBackground>
        <TD colSpan={includedStats.length + 2}>
          {(subjectValues ?? []).map(({ name, value, id }) => {
            const isOutlier = outlierSubjectIds.includes(id)

            return <SubjectRow key={id} data-cy="sst-subject-row">
              <SubjectName $understate={isOutlier}>{name}:</SubjectName>
              {' '}<SubjectDetail $understate={isOutlier}>{floatString(value, { places: 1 })} {measurementUnit}</SubjectDetail>
              {' '}
              {isReadOnly
                ? isOutlier ? <SubjectDetail $understate>(Marked as Outlier)</SubjectDetail> : null
                : section.permitOutlierRemoval
                  ? <OutlierButton
                    size="small"
                    color="back"
                    label={isOutlier ? 'Return to Set' : 'Mark as Outlier'}
                    onClick={onMarkOutlier(id)}
                    isDisabled={isUpdating && updatingSubjectId !== id}
                    isLoading={updatingSubjectId === id}
                  />
                  : null}
            </SubjectRow>
          })}
          <NotesInput
            updateAttr={(newValue) => { setNotes(newValue as string) }}
            name="notes"
            pathToAttr="entries.notes"
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            bodyElement={{
              id: 'subject-notes',
              fieldName: 'notes',
              elementVariety: 'LONG_ANSWER'
            } as InputElement}
            formOptions={{ RootFormRenderer: FormRenderer }}
            isReadOnly={isReadOnly}
            placeholder="Click here to add notes about this group"
            currentValue={notes}
          />
        </TD>
      </TR>
    </>
  },
  BlankRow: React.FC<Pick<RowProps, 'includedStats'>> = ({ includedStats }) => {
    return <TR>
      <TD colSpan={1 + includedStats.length + 1}>
        No Data
      </TD>
    </TR>
  },
  ClosedRow: React.FC<RowProps & { unopenable?: boolean }> = ({ groupName, groupStats, includedStats, subjectValues, entry, rowIndex, setSelectedRow, measurementUnit, unopenable, shrinkFinalColumn }) => {
    const
      RowComponent = unopenable ? TR : ClickableRow,
      subjectNotes = (entry?.subjectNotes ?? {}) as Record<string, string>

    return <RowComponent onClick={unopenable ? undefined : () => { setSelectedRow(rowIndex) }} title="Click to expand">
      <TH>{groupName}</TH>
      {!isAllEmpty(groupStats)
        ? includedStats.map(statName => <TD key={statName}>{groupStats[statName]} {measurementUnit}</TD>)
        : <TD colSpan={includedStats.length}>
          No Data
        </TD>}
      <FinalColumnCell $shrink={shrinkFinalColumn}>
        <NotesContent>
          <span>{truncate(subjectNotes[groupName], { length: shrinkFinalColumn ? 5 : 55 })}</span>
          {unopenable ? null : <ExpandIcon content={BasicCross} color="back" />}
        </NotesContent>
      </FinalColumnCell>
    </RowComponent>
  },
  SummaryStatRow: React.FC<RowProps & { unopenable?: boolean, selectedRow?: number }> = ({ unopenable, selectedRow, ...props }) => {
    return props.rowIndex === selectedRow && !unopenable
      ? <OpenRow {...props as EditableRowProps} />
      : <ClosedRow {...props} unopenable={unopenable} />
  },
  SummaryStatsTable: React.FC<Props> = ({ experiment, section, response, entry, otherEntities, isReadOnly, shrinkFinalColumn }) => {
    const
      [selectedRow, setSelectedRow] = React.useState<number | undefined>(),
      { dataPoints, omittedMeasurements, subjectsById } = getDataPoints(otherEntities, experiment),
      treatmentKeys = uniq(sortBy(values(subjectsById), 'treatmentIndex').map(o => o.treatment)),
      includedStats = section.includedSummaryStats ?? [],
      outlierSubjectIds = (entry?.outlierSubjectIds ?? []) as string[],
      measurementUnit = (getValueAcrossEntities(SystemFieldNames.measurementUnit, otherEntities) ?? '') as string,
      { valueSetsByTreatment } = getGroupedValues(dataPoints, experiment, otherEntities, subjectsById, outlierSubjectIds),
      summaryStats = mapValues(valueSetsByTreatment, (vs) => getSummaryStats(vs, includedStats)),
      allValueSummaryStats = getSummaryStats(flatten(values(valueSetsByTreatment)), includedStats)

    return (
      <>
        <TableWrapper>
          <StyledTable>
            <THead>
              <TR>
                <TH>Treatment</TH>
                {includedStats.map(statName => <TH key={statName}>{statName.match(/^[^a-z]+$/) ? statName : symbolSafeStartCase(statName)}</TH>)}
                <TH>Notes</TH>
              </TR>
            </THead>
            {dataPoints.length
              ? <TBody>
                <SummaryStatRow
                  unopenable
                  rowIndex={0}
                  groupName="All Treatments"
                  groupStats={allValueSummaryStats}
                  isReadOnly={isReadOnly}
                  shrinkFinalColumn={shrinkFinalColumn}
                  {...{ entry, setSelectedRow, selectedRow, response, includedStats, measurementUnit, section, otherEntities }}
                />
                {treatmentKeys.map((treatmentName, i) => (
                  <SummaryStatRow
                    key={treatmentName}
                    rowIndex={i + 1}
                    groupName={treatmentName}
                    groupStats={summaryStats[treatmentName]}
                    isReadOnly={isReadOnly}
                    shrinkFinalColumn={shrinkFinalColumn}
                    subjectValues={valueSetsByTreatment[treatmentName]}
                    {...{ entry, setSelectedRow, selectedRow, response, includedStats, measurementUnit, section, otherEntities }}
                  />
                ))}
              </TBody>
              : <TBody><BlankRow { ...{ includedStats } } /></TBody>}
          </StyledTable>
        </TableWrapper>
        {omittedMeasurements.length
          ? <Footnote color="default">{omittedMeasurements.length} measurements were unable to be processed and were omitted.</Footnote>
          : null}
        {outlierSubjectIds.length
          ? <Footnote color="default">{outlierSubjectIds.length} value{outlierSubjectIds.length > 1 ? 's' : ''} marked as {outlierSubjectIds.length > 1 ? 'outliers have' : 'an outlier has'} been omitted from this analysis.</Footnote>
          : null}
      </>
    )
  }

export default SummaryStatsTable
