import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { HeadingGroup, Main } from '~/components/layouts/LayoutWidgets'
import { DashedLine } from '~/components/atoms/Shapes'
import { isEmptyValue } from '~/form-brain2/testers'

interface CoreProps {
  absoluteContent?: React.ReactNode
  heading?: React.ReactNode
  subheading?: React.ReactNode
  infoTiles?: React.ReactNode
  topActions?: React.ReactNode
  noHR?: boolean
  className?: string
}

interface DualContentProps {
  primaryContent: React.ReactNode
  secondaryContent?: React.ReactNode
  children?: React.ReactNode
}

interface SoleContentProps {
  children: React.ReactNode
  primaryContent?: React.ReactNode
  secondaryContent?: React.ReactNode
}

type FlexibleMainProps = (CoreProps & DualContentProps) | (CoreProps & SoleContentProps)

type DoubleContentProps = Pick<FlexibleMainProps, 'primaryContent' | 'secondaryContent'> & { spaceEvenly?: boolean }

const
  TopActions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5em;

    margin-left: 4px;
    margin-top: 4px;
    margin-right: ${p => p.theme.emBaseSpacing * 5.2}em; // 71.5px
    margin-bottom: ${p => p.theme.emBaseSpacing}em; // 14px
  `,
  InfoTiles = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin-left: 4px;
    margin-bottom: ${p => p.theme.emBaseSpacing}em; // 13.5px
    > * {
      z-index: ${p => p.theme.zLocalTop + 1};
    }
  `,
  HR = styled(DashedLine)<{ $hasPrimaryContent?: boolean }>`
    margin-bottom: ${p => p.$hasPrimaryContent ? (p.theme.emBaseSpacing * 2.4) : p.theme.emBaseSpacing}em; // 32px 13.5px
  `,
  ChildrenWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 18px;
  `,
  ContentOuter = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    margin-bottom: 18px;
    column-gap: 2%;
    row-gap: 8.75px; 
    max-width: 100%;
  `,
  PrimaryContent = styled.div<{ $spaceEvenly?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    ${p => p.$spaceEvenly
      ? `
          flex: 0 1 ${p.theme.pxMaxContentWidth / 2}px;
          
          @media(min-width: ${p.theme.pxMaxContentWidth}px) {
            flex: 1 1 ${p.theme.emMinReadingWidth * 0.8}em;
          }
        `
     : `
          flex: 1 1 ${p.theme.emMediumContentWidth * 0.8}em;
        `
    }
    max-width: 100%;
  `,
  SecondaryContent = styled.div<{ $spaceEvenly?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    ${p => p.$spaceEvenly
      ? `
          flex: 1 1 ${p.theme.pxMaxContentWidth / 2}px;

          @media(min-width: ${p.theme.pxMaxContentWidth}px) {
            flex: 1 1 ${p.theme.emMinReadingWidth * 0.8}em;
          }
        `
     : `
          flex: 1 1 ${p.theme.emMinReadingWidth * 0.8}em;
        `
    }
    max-width: 100%;
  `,
  StyledHeadingGroup = styled(HeadingGroup)<{ $hasTiles?: boolean }>`
    ${p => p.$hasTiles ? 'margin-bottom: 0;' : ''}
  `,
  DoubleContent: React.FC<DoubleContentProps> = ({ primaryContent, secondaryContent, spaceEvenly }) => {
    return !primaryContent || !secondaryContent
      ? <ChildrenWrapper>
        {primaryContent ?? secondaryContent}
      </ChildrenWrapper>
      : <ContentOuter>
        {primaryContent ? <PrimaryContent $spaceEvenly={spaceEvenly}>{primaryContent}</PrimaryContent> : null}
        {secondaryContent ? <SecondaryContent $spaceEvenly={spaceEvenly}>{secondaryContent}</SecondaryContent> : null}
      </ContentOuter>
  },
  FlexibleMain: React.FC<FlexibleMainProps> = (props) => {
    const
      THEME = useTheme(),
      {
        children, primaryContent, secondaryContent,
        topActions, absoluteContent, subheading, heading,
        infoTiles, className, noHR
      } = props

    return <Main className={className}>
      {absoluteContent}
      {topActions
        ? <TopActions>
          {topActions}
        </TopActions>
        : null}
      {heading ? <StyledHeadingGroup {...{ heading, subheading }} fullWidth={!absoluteContent} $hasTiles={!isEmptyValue(infoTiles)} /> : null}
      {infoTiles
        ? <InfoTiles>
          {infoTiles}
        </InfoTiles>
        : null}
      {!noHR && (topActions ?? heading ?? infoTiles)
        ? <HR $hasPrimaryContent={!!primaryContent} color={THEME.colors.headingAccentText} />
        : null}
      {primaryContent ?? secondaryContent
        ? <DoubleContent {...{ primaryContent, secondaryContent }} />
        : null}
      {children
        ? <ChildrenWrapper>
          {children}
        </ChildrenWrapper>
        : null}
    </Main>
  }

export { DoubleContent }
export default FlexibleMain
