import React from 'react'
import styled from 'styled-components'
import { HintText } from '~/components/atoms'
import { getDynamicString } from '~/features/experiment/helpers'
import PagedFormatGraphic from '~/assets/pagedFormatGraphic.png'
import ListFormatGraphic from '~/assets/listFormatGraphic.png'
import HubFormatGraphic from '~/assets/hubFormatGraphic.png'
import DownloadsFormatGraphic from '~/assets/downloadFormatGraphic.png'
import type { Experiment, QuestionnaireFormat } from '~/features'

interface Props {
  format?: QuestionnaireFormat
  experiment?: Experiment
}

const
  FormatDetails = styled.div`
    display: flex;
    flex-direction: row;
    // align-items: center;
    gap: ${p => p.theme.emBaseSpacing}em;
    margin-bottom: ${p => p.theme.emBaseSpacing}em;

    > * {
      flex: 1 1 50%;
      min-width: 0;
    }
  `,
  StepFormatInfo: React.FC<Props> = ({ format, experiment }) => {
    if (format === 'DOWNLOADS') {
      return <FormatDetails>
        <img src={DownloadsFormatGraphic} alt="A gray rectangle, in the center is a blue circle containing a downward-pointing white arrow, representing file download" />
        <HintText color="accent">
          A downloads step allows learners to download their work from previous steps in various formats. You can configure this step further on the next page.
        </HintText>
      </FormatDetails>
    }

    if (format === 'HUB') {
      return <FormatDetails>
        <img src={HubFormatGraphic} alt="A gray rectangle containing three green street signs numbered 1 through 3" />
        <HintText color="accent">
          {'A hub step collects a group of smaller steps under a single umbrella, for example grouping steps for materials and setup lists into one step "Materials & Methods". After you create a hub step, you can add new or existing steps to that hub.'}
        </HintText>
      </FormatDetails>
    }

    if (format === 'PAGED') {
      return <FormatDetails>
        <img src={PagedFormatGraphic} alt="A gray rectangle representing a question page, with a row of tabs above and left and right arrows in buttons below." />
        <HintText color="accent">
          A paged step walks learners through a series of questions broken down into small groups, called pages, and bigger groups, called sections. Learners move between sections using tabs and between pages using &quot;{getDynamicString(experiment, 'pageBack')}&quot; and &quot;{getDynamicString(experiment, 'pageNext')}&quot; buttons.
        </HintText>
      </FormatDetails>
    }

    if (format === 'LIST') {
      return <FormatDetails>
        <img src={ListFormatGraphic} alt="Three rectangles: two short, wide gray rectangles representing finished list items, and one large gray rectangle representing an open form, with red and green buttons at the bottom and a plus icon for adding to the list." />
        <HintText color="accent">
          A list step allows learners to respond to the same questions more than once, for example to create a list by filling out the same form for each of their research sources. You can add opening and closing sections with questions that are asked just once at the beginning and end, respectively.
        </HintText>
      </FormatDetails>
    }

    return null
  }

export default StepFormatInfo
