import { get, last, memoize, sortBy, values } from 'lodash'
import type { RootState as State } from '~/services/store'
import type { ObjectId } from '~/types/utilities'
import type { Experiment } from '../experiment/types'
import type { Learner } from '../learner/types'
import type { User } from '../session/types'
import type { Template, TemplateSet } from '../template/types'
import type { AnyWorksheet, Worksheet } from '../worksheet/types'
import type { WorksheetResponse } from '../worksheetResponse/types'

const
  blankArray: Worksheet[] | WorksheetResponse[] | Template[] = [],
  allResponsesForWorksheetIds = (responsesById: Record<string, WorksheetResponse>, worksheetIds: string[], _: number): WorksheetResponse[] => (
    values(responsesById).filter(response => worksheetIds.includes(response.worksheetId))
  ),
  memoizedAllResponsesForWorksheetIds = memoize(
    allResponsesForWorksheetIds,
    (responsesById, worksheetIds, sliceUpdatedAt) => `${worksheetIds.sort().join('-')}${last(sortBy(responsesById, 'updatedAt'))?.updatedAt ?? 'none'}-${sliceUpdatedAt}`
  ),
  allResponsesForLearner = (responsesById: Record<string, WorksheetResponse>, worksheetIds: string[], learnerId: string, _: number): WorksheetResponse[] => (
    values(responsesById).filter(response => worksheetIds.includes(response.worksheetId) && (response.learnerId === learnerId || !response.learnerId))
  ),
  memoizedAllResponsesForLearner = memoize(
    allResponsesForLearner,
    (responsesById, worksheetIds, learnerId, sliceUpdatedAt) => `${learnerId}${last(sortBy(responsesById, 'updatedAt'))?.updatedAt ?? 'none'}-${sliceUpdatedAt}-${worksheetIds.join('-')}`
  ),
  allWorksheetsForExperiment = (worksheetsById: Record<string, AnyWorksheet>, experimentId: string, _: number): AnyWorksheet[] => (
    values(worksheetsById).filter(o => o.experimentId === experimentId)
  ),
  memoizedAllWorksheetsForExperiment = memoize(
    allWorksheetsForExperiment,
    (worksheetsById, experimentId, sliceUpdatedAt) => `${experimentId}${last(sortBy(worksheetsById, 'updatedAt'))?.updatedAt ?? 'none'}-${sliceUpdatedAt}`
  ),
  allResponsesForExperiment = (responsesById: Record<string, WorksheetResponse>, experimentId: ObjectId, _: number): WorksheetResponse[] => (
    values(responsesById).filter(o => o.experimentId === experimentId)
  ),
  memoizedAllResponsesForExperiment = memoize(
    allResponsesForExperiment,
    (responsesById, experimentId, sliceUpdatedAt) => `${experimentId}${last(sortBy(responsesById, 'updatedAt'))?.updatedAt ?? 'none'}-${sliceUpdatedAt}`
  ),
  allTemplatesForSet = (templatesById: Record<string, Template>, templateSetId: string, _: number): Template[] => (
    values(templatesById).filter(o => o.templateSetId === templateSetId)
  ),
  memoizedAllTemplatesForSet = memoize(
    allTemplatesForSet,
    (templatesById, templateSetId, sliceUpdatedAt) => `${templateSetId}${last(sortBy(templatesById, 'updatedAt'))?.updatedAt ?? 'none'}-${sliceUpdatedAt}`
  )

export const
  selectExperiments = (state: State): Record<ObjectId, Experiment> => get(state, 'entity.experiment'),
  selectLearners = (state: State): Record<ObjectId, Learner> => get(state, 'entity.learner'),
  selectWorksheetResponses = (state: State): Record<ObjectId, WorksheetResponse> => get(state, 'entity.worksheetResponse'),
  selectTemplateSets = (state: State): Record<ObjectId, TemplateSet> => get(state, 'entity.templateSet'),
  selectTemplates = (state: State): Record<ObjectId, Template> => get(state, 'entity.template'),
  selectWorksheets = (state: State): Record<ObjectId, AnyWorksheet> => get(state, 'entity.worksheet'),

  selectUserById = (id?: string) => (state: State): User | undefined => id ? get(state, `entity.user.${id}`) : undefined,
  selectExperimentById = (id?: string) => (state: State): Experiment | undefined => id ? get(state, `entity.experiment.${id}`) : undefined,
  selectLearnerById = (id?: string) => (state: State): Learner | undefined => id ? get(state, `entity.learner.${id}`) : undefined,
  selectWorksheetResponseById = (id?: string) => (state: State): WorksheetResponse | undefined => id ? get(state, `entity.worksheetResponse.${id}`) : undefined,
  selectTemplateSetById = (id?: string) => (state: State): TemplateSet | undefined => id ? get(state, `entity.templateSet.${id}`) : undefined,
  selectTemplateById = (id?: string) => (state: State): Template | undefined => id ? get(state, `entity.template.${id}`) : undefined,
  selectWorksheetById = (id?: string) => (state: State): AnyWorksheet | undefined => id ? get(state, `entity.worksheet.${id}`) : undefined,

  selectWorksheetsByExperimentId = (experimentId?: string) => (state: State): AnyWorksheet[] => {
    if (!experimentId) { return blankArray as AnyWorksheet[] }

    const worksheetsById = selectWorksheets(state)

    return memoizedAllWorksheetsForExperiment(worksheetsById, experimentId, state.entity.updatedAt ?? 0)
  },

  selectTemplatesBySetId = (templateSetId?: string) => (state: State): Template[] => {
    if (!templateSetId) { return blankArray as Template[] }

    const templatesById = selectTemplates(state)

    return memoizedAllTemplatesForSet(templatesById, templateSetId, state.entity.updatedAt ?? 0)
  },

  selectResponsesByExperimentId = (experimentId?: string) => (state: State): WorksheetResponse[] => {
    if (!experimentId) { return blankArray as WorksheetResponse[] }

    const responsesById = selectWorksheetResponses(state)

    return memoizedAllResponsesForExperiment(responsesById, experimentId, state.entity.updatedAt ?? 0)
  },

  selectResponsesByLearnerId = (learnerId?: string, worksheetIds?: string[]) => (state: State): WorksheetResponse[] => {
    if (!worksheetIds) { return blankArray as WorksheetResponse[] }

    const responsesById = selectWorksheetResponses(state)

    if (!learnerId) {
      return memoizedAllResponsesForWorksheetIds(responsesById, worksheetIds, state.entity.updatedAt ?? 0)
    }

    return memoizedAllResponsesForLearner(responsesById, worksheetIds, learnerId, state.entity.updatedAt ?? 0)
  },

  selectIsStale = (state: State): boolean => {
    const
      lastUpdate = get(state, 'entity.updatedAt'),
      now = new Date().valueOf()

    if (!lastUpdate) { return true }

    return (now - lastUpdate) > 30000 // 30 seconds
  },

  selectBasisForExperiment = (experiment: Experiment) => (state: State): (Experiment | TemplateSet | undefined) => {
    if (experiment.basedOnType === 'Experiment') {
      return selectExperimentById(experiment.basedOnId)(state)
    } else if (experiment.basedOnType === 'TemplateSet' && experiment.basedOnId) {
      return selectTemplateSets(state)[experiment.basedOnId]
    } else {
      return undefined
    }
  },

  selectBasisForTemplateSet = (experiment: TemplateSet) => (state: State): (TemplateSet | undefined) => {
    if (experiment.basedOnId) {
      return selectTemplateSetById(experiment.basedOnId)(state)
    } else {
      return undefined
    }
  }
