import * as React from 'react'
import { toPairs } from 'lodash'
import styled from 'styled-components'
import { FormContext } from '~/form-brain2'
import { SYSTEM_FIELDS } from '~/features'
import { Button } from '~/components/atoms'
import ButtonWithConfirm from '~/components/molecules/ButtonWithConfirm'
import { CardBody, ExpandedRowOuter } from '~/components/molecules/ExpandedRow'
import { builtInFieldNameElement, elementFormBodies, elementVarietyElement } from '~/features/worksheet/elementFormDetails'

import type { CollectionOptions, FormRendererProps } from '~/form-brain2'
import type { SystemFieldName, AnyBodyElement, AnyPlainOrInputElement, ListOption, VisibilityCondition, InstructionsElement, InputElement, ElementVariety, FieldType, SelectInput, HeadingElement } from '~/features'
import type { AnyElegibleElement, ElegibleVariety } from '~/features/worksheet/elementFormDetails'
import type { SystemFieldDetails } from '~/features/experiment/systemFields'
import { isAllEmpty } from '~/form-brain2/testers'
import { ELEMENT_TYPES_IN_MAD_LIB_SEGMENTS, FIELDS_IN_MAD_LIB_SEGMENTS, FIELD_TYPES_IN_MAD_LIB_SEGMENTS } from '~/features/worksheet/elementSchemas'

type ElementVarietyCategory = 'system' | 'input' | 'plain'

const
  StyledCardBody = styled(CardBody)<{ $formId: string }>`
    align-self: stretch;

    #${p => p.$formId} {
      flex: 1 0 auto;
      margin-bottom: 0;
    }
  `,
  Outer = styled(ExpandedRowOuter)`
    margin-bottom: 4px;
    align-items: center;
  `,
  StyledButton = styled(Button)`
    margin: ${p => p.theme.emBaseSpacing}em 0;
  `,
  ButtonRow = styled.div<{ $withSpace?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    ${p => p.$withSpace ? `margin-top: ${p.theme.emBaseSpacing}em;` : ''}
  `,
  varietyCategoryLabels: Record<ElementVarietyCategory, string> = {
    system: 'Built-In Question/Variable',
    input: 'Custom Question',
    plain: 'Other Content'
  },
  inputCategoryVarieties: Array<ElegibleVariety[number]> = [
    'TEXT_INPUT',
    'SHORT_ANSWER',
    'LONG_ANSWER',
    'NUMBER_INPUT',
    'DATE_INPUT',
    'SELECT_INPUT',
    'MULTI_SELECT_INPUT',
    'CHECKBOX_INPUT',
    'FILL_IN_THE_BLANK',
    'SCOPE',
    'COLLECTION'
  ],
  plainCategoryVarieties: Array<ElegibleVariety[number]> = [
    'INSTRUCTIONS',
    'HEADING',
    'SUBHEADING',
    'TEXT',
    'BLURB',
    'FIELDSET'
  ],
  getInitialConditionCategory = (bodyElement: AnyBodyElement): ElementVarietyCategory | undefined => {
    if ('fieldName' in bodyElement && bodyElement.fieldName && SYSTEM_FIELDS[bodyElement.fieldName as SystemFieldName]) {
      return 'system'
    }

    if (bodyElement.elementVariety && inputCategoryVarieties.includes(bodyElement.elementVariety)) {
      return 'input'
    }

    if (bodyElement.elementVariety && plainCategoryVarieties.includes(bodyElement.elementVariety)) {
      return 'plain'
    }

    return undefined
  },
  getConfiguredFormBody = ({ elementVarietyCategory, bodyElement, systemField, isFieldNameUnlocked, isMadLib }: {
    elementVarietyCategory: ElementVarietyCategory
    bodyElement: AnyElegibleElement
    systemField?: SystemFieldDetails
    isFieldNameUnlocked?: boolean
    isMadLib?: boolean
  }): AnyPlainOrInputElement[] => {
    const
      rawElementBody = bodyElement.elementVariety
        ? elementFormBodies[bodyElement.elementVariety as ElegibleVariety]
        : undefined,
      filteredElementBody = rawElementBody && isMadLib
        ? rawElementBody.filter(
          o => !(o as InputElement).fieldName || FIELDS_IN_MAD_LIB_SEGMENTS.includes((o as InputElement).fieldName)
        )
        : rawElementBody,
      availableInputVarieties = isMadLib
        ? inputCategoryVarieties.filter(o => ELEMENT_TYPES_IN_MAD_LIB_SEGMENTS.includes(o as ElementVariety))
        : inputCategoryVarieties,
      availablePlainVarieties = isMadLib
        ? plainCategoryVarieties.filter(o => ELEMENT_TYPES_IN_MAD_LIB_SEGMENTS.includes(o as ElementVariety))
        : plainCategoryVarieties,
      formBody = [
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {
          id: 'elementVarietyHeading',
          text: varietyCategoryLabels[elementVarietyCategory],
          elementVariety: 'HEADING'
        } as HeadingElement,
        ...(elementVarietyCategory === 'input' || elementVarietyCategory === 'plain'
          ? [{
              ...elementVarietyElement,
              label: 'Content type',
              list: (elementVarietyElement.list as ListOption[]).filter(o => (
                (elementVarietyCategory === 'input'
                  ? availableInputVarieties
                  : availablePlainVarieties
                ).includes(o.optionValue)
              ))
            }]
          : []
        ),
        ...(elementVarietyCategory === 'system'
          ? [{
              ...builtInFieldNameElement,
              label: 'Built-In Question/Variable',
              list: isMadLib
                ? (builtInFieldNameElement.list as Array<(ListOption & { _fieldType: FieldType })>).filter(({ _fieldType }) => FIELD_TYPES_IN_MAD_LIB_SEGMENTS.includes(_fieldType))
                : builtInFieldNameElement.list
            }]
          : []
        ),
        ...(elementVarietyCategory === 'system' && systemField
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          ? [{
              id: `biv-instructions-${bodyElement.id}`,
              elementVariety: 'INSTRUCTIONS',
              paragraphs: [`About ${systemField.title}: ${systemField.description}`]
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            } as InstructionsElement, {
              ...elementVarietyElement,
              label: 'Content type',
              readOnlyWhen: [{ conditionType: 'always' }],
              list: (elementVarietyElement.list as ListOption[]).filter(o => o.optionValue === bodyElement.elementVariety)
            } as SelectInput]
          : []
        ),
        ...(filteredElementBody
          ? filteredElementBody.flatMap(el => (
            'fieldName' in el && el.fieldName === 'fieldName'
              ? elementVarietyCategory === 'system' && systemField
                ? []
                : !isFieldNameUnlocked
                    ? [{
                        ...el,
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        readOnlyWhen: [{ conditionType: 'always' } as VisibilityCondition],
                        hint: 'This ID is locked. If you are certain that the answer to this question is not used to control visibility or configuration for other content, you can unlock it with the button below to make changes.'
                      }]
                    : [el]
              : [el]
          ))
          : [])
      ]

    return formBody
  },
  BodyElementListItemOpen: React.FC<FormRendererProps & { toggleEditing?: () => void, elementId: string }> = ({ formId, className, isNested, toggleEditing = () => {}, elementId }) => {
    const
      formContext = React.useContext(FormContext),
      { scopeOptions, formStatus, formOptions, updateErrors, errors } = formContext,
      bodyElement = formContext.values as unknown as AnyElegibleElement,
      FormRenderer = formOptions.RootFormRenderer,
      { removeEntry /* isEntryNew */ } = scopeOptions as CollectionOptions,
      // wasNew = React.useRef<boolean>(!!isEntryNew),
      [elementVarietyCategory, setElementVarietyCategory] = React.useState<ElementVarietyCategory | undefined>(
        getInitialConditionCategory(bodyElement)
      ),
      /** TODO figure out how to opt out of the fieldName auto-update-to-match-label loop
       * and/or just find a better way to handle making it hard to mess up the field name
       * see list of future features for details
       */
      [isFieldNameUnlocked, setIsFieldNameUnlocked] = React.useState<boolean>(true), // (wasNew.current || !('fieldName' in bodyElement && bodyElement.fieldName)),
      closeButton = <Button
        onClick={toggleEditing}
        isDisabled={formStatus === 'processing'}
        color="back"
        size="small"
        label="Close"
      />,
      isMadLib = scopeOptions && 'isMadLibSegment' in scopeOptions && scopeOptions?.isMadLibSegment,
      removeButton = removeEntry
        ? <ButtonWithConfirm
          onConfirm={removeEntry}
          confirmationBody='This content will not be removed permanently until you save all changes to the step format. If you want this content back, you can go back to group settings without saving - but you will lose any other changes you have made since you last saved.'
          buttonProps={{ size: 'small', label: 'Remove Content', color: 'caution' }}
          cancelLabel='Keep Content'
          confirmLabel='Remove Content'
          subheading='Remove content from draft'
        />
        : null,
      systemField = 'fieldName' in bodyElement && bodyElement.fieldName
        ? SYSTEM_FIELDS[bodyElement.fieldName as SystemFieldName]
        : undefined,
      configuredFormBody = elementVarietyCategory
        ? getConfiguredFormBody({ elementVarietyCategory, bodyElement, systemField, isFieldNameUnlocked, isMadLib })
        : []

    React.useEffect(() => {
      if (
        updateErrors &&
        elementVarietyCategory !== 'system' &&
        systemField
      ) {
        if (!errors.fieldName) {
          updateErrors({ newErrors: { fieldName: ['This ID is for system use only. Enter a different ID.'] } })
        }
      }
    }, [elementVarietyCategory, systemField, updateErrors, errors.fieldName])

    return <Outer $naturalHeight data-cy="body-element-list-item-open" id={elementId}>
      <StyledCardBody $isInline $formId={formId}>
        {elementVarietyCategory
          ? <FormRenderer {...{ formId, formBody: configuredFormBody, className, isNested }} />
          : <ButtonRow>
            <span>Add:</span>
            {toPairs(varietyCategoryLabels).map(([value, label]) => (
              <StyledButton
                key={value}
                color="back"
                size="small"
                label={label}
                onClick={() => { setElementVarietyCategory(value as ElementVarietyCategory) }}
              />
            ))}
          </ButtonRow> }
        <ButtonRow $withSpace>
          {isFieldNameUnlocked || systemField
            ? null
            : <Button
              onClick={() => { setIsFieldNameUnlocked(true) }}
              color="caution"
              size="small"
              label="Unlock ID"
            />}
          {removeButton}
          {elementVarietyCategory && isAllEmpty(errors) && scopeOptions?.elementVariety !== 'SCOPE' ? closeButton : null}
        </ButtonRow>
      </StyledCardBody>
    </Outer>
  }

export default BodyElementListItemOpen
export { getConfiguredFormBody }
