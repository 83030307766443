import * as React from 'react'
import { Button, DashedLine } from '~/components/atoms'
import type { ContextAndResolvers } from '~/form-brain2'
import type { CollapsibleElement } from '~/features/questionnaire/types'
import styled, { useTheme } from 'styled-components'

interface Props {
  bodyElement: CollapsibleElement
  contextAndResolvers: ContextAndResolvers
  children: React.ReactNode
}

const
  StyledButton = styled(Button)<{ $isCollapsed?: boolean }>`
    align-self: flex-start;
    margin: ${p => p.$isCollapsed ? p.theme.emBaseSpacing : p.theme.emSmallSpacing}em 0;
  `,
  HR = styled(DashedLine)`
    margin: ${p => p.theme.emBaseSpacing}em 0;
  `,
  CollapsibleSection: React.FC<Props> = ({ bodyElement, contextAndResolvers, children }) => {
    const
      THEME = useTheme(),
      { expandButtonLabel, collapseButtonLabel, defaultExpanded } = bodyElement,
      [showSection, setShowSection] = React.useState<boolean>(!!defaultExpanded),
      toggleSection: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }
        setShowSection(_showSection => !_showSection)
      }

    return <>
      {showSection ? <HR orientation="horizontal" color={THEME.colors.understateMed} /> : null}
      <StyledButton
        $isCollapsed={!showSection}
        label={showSection ? collapseButtonLabel : expandButtonLabel}
        onClick={toggleSection}
        size="small"
        color="back"
      />
      {showSection ? children : null}
      {showSection ? <HR orientation="horizontal" color={THEME.colors.understateMed} /> : null}
    </>
  }

export default CollapsibleSection
