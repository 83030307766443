import * as React from 'react'
import styled from 'styled-components'
import { CONDITIONAL_QUESTION_ICON, ELEMENT_TYPES } from '~/utils/strings'
import { isAllEmpty } from '~/utils/testers'
import { truncateFromCenter } from '~/utils/formatters'
import { SYSTEM_FIELDS } from '~/features'
import CardHeader from '~/components/molecules/CardHeader'

import type { BodyElement } from '~/form-brain2'
import type { SystemFieldName, HeadingElement, InputElement, InstructionsElement, MadLibElement, TextElement } from '~/features'

const
  StyledCardHeader = styled(CardHeader)`
    margin-bottom: 4px;
  `,
  Superscript = styled.span`
    margin-left: 2px;
    position: relative;
    font-size: 80%;
    top: -0.6em;
  `,
  BodyElementListItemClosed: React.FC<{ index: number, bodyElement: BodyElement, onClick?: React.MouseEventHandler, elementId: string }> = ({ index, bodyElement, onClick, elementId }) => {
    const
      elementVariety = bodyElement.elementVariety as keyof typeof ELEMENT_TYPES,
      varietyLabel = ELEMENT_TYPES[elementVariety],
      relatedSystemField = 'fieldName' in bodyElement && bodyElement.fieldName
        ? SYSTEM_FIELDS[bodyElement.fieldName as SystemFieldName]
        : undefined,
      shownConditionally = !isAllEmpty(bodyElement.visibleWhen) || !isAllEmpty(relatedSystemField?.schemaEntry?.visibleWhen),
      elementDetail = 'label' in bodyElement && (bodyElement as InputElement).label
        ? (bodyElement as InputElement).label
        : 'fieldName' in bodyElement && (bodyElement as InputElement).fieldName
          ? (bodyElement as InputElement).fieldName
          : undefined,
      otherText = 'text' in bodyElement && (bodyElement as HeadingElement).text
        ? (bodyElement as HeadingElement).text
        : 'paragraphs' in bodyElement && (bodyElement as InstructionsElement).paragraphs
          ? (bodyElement as InstructionsElement).paragraphs[0]
          : bodyElement.elementVariety === 'FILL_IN_THE_BLANK' && (bodyElement as MadLibElement).segments.some(o => o.elementVariety === 'TEXT')
            ? (bodyElement as MadLibElement).segments.filter(o => o.elementVariety === 'TEXT').map(o => (o as TextElement).text).join(' [...] ')
            : undefined

    return (
      <StyledCardHeader
        id={elementId}
        onClick={onClick}
        heading={<span>{truncateFromCenter(elementDetail ?? varietyLabel ?? 'Unkown', 52)}{shownConditionally ? <Superscript>{CONDITIONAL_QUESTION_ICON}</Superscript> : null}</span>}
        content={elementDetail ? truncateFromCenter(varietyLabel ?? 'Unkown', 46) : otherText ? truncateFromCenter(otherText, 46) : undefined}
      />
    )
  }

export default BodyElementListItemClosed
