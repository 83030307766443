import { getSemiRandomString } from '~/utils/strings'
import type { ListWorksheet } from '../types'
import type { SpecialListSectionElement } from '~/features/questionnaire/types'

export const
  getDefaultMeasurementLogSection = (sectionId?: string): SpecialListSectionElement => {
    return {
      id: sectionId ?? `measurement-list-${getSemiRandomString()}`,
      elementVariety: 'LIST_ENTRY_SECTION',
      pageHint: 'Taking good notes on observations and accurate data is important in the scientific process.',
      mascotVariant: 'recording',
      entryNamePlural: 'measurements',
      entryNameSingular: 'measurement',
      sortByField: 'date',
      specialSectionId: 'MEASUREMENT_RECORD',
      entryIconContent: 'M',
      whenLabel: 'When?',
      subjectLabel: 'What?',
      measurementLabel: 'How much?',
      detailsLabel: 'You can add more information here, if needed',
      body: []
    }
  },
  getDefaultObservationLogSection = (sectionId?: string): SpecialListSectionElement => {
    return {
      id: sectionId ?? `observation-list-${getSemiRandomString()}`,
      elementVariety: 'LIST_ENTRY_SECTION',
      pageHint: 'Taking good notes on observations and accurate data is important in the scientific process.',
      mascotVariant: 'recording',
      entryNamePlural: 'observations',
      entryNameSingular: 'observation',
      sortByField: 'date',
      specialSectionId: 'OBSERVATION_RECORD',
      entryIconContent: 'O',
      whenLabel: 'When?',
      detailsLabel: 'What did you observe?',
      body: []
    }
  },
  getDefaultActivityLogSection = (sectionId?: string): SpecialListSectionElement => {
    return {
      id: sectionId ?? `activity-list-${getSemiRandomString()}`,
      elementVariety: 'LIST_ENTRY_SECTION',
      pageHint: 'Taking good notes on observations and accurate data is important in the scientific process.',
      mascotVariant: 'recording',
      entryNamePlural: 'activities',
      entryNameSingular: 'activity',
      sortByField: 'date',
      specialSectionId: 'ACTIVITY_RECORD',
      entryIconContent: 'A',
      whenLabel: 'When?',
      whatLabel: 'What did you do?',
      detailsLabel: 'Notes:',
      body: []
    }
  },
  defaultsForDataCollectionLayout = (
    measurementSectionId?: string,
    observationSectionId?: string,
    activitySectionId?: string
  ): Pick<ListWorksheet, 'repeatingSections' | 'openingSection'> => {
    const
      measurementSection = getDefaultMeasurementLogSection(measurementSectionId),
      observationSection = getDefaultObservationLogSection(observationSectionId),
      activitySection = getDefaultActivityLogSection(activitySectionId)

    return {
      openingSection: {
        id: 'data-opener',
        elementVariety: 'LIST_OPENER_SECTION',
        title: 'Day One!',
        mascotVariant: 'pointing',
        body: [
          {
            id: 'data-open-1',
            fieldName: 'startDate',
            elementVariety: 'DATE_INPUT',
            label: 'What day did you start your experiment?'
          },
          {
            id: 'data-open-2',
            fieldName: 'laSetupNotes',
            elementVariety: 'LONG_ANSWER',
            label: 'In your own words, describe how you set up your experiment:'
          }
        ]
      },
      repeatingSections: [
        measurementSection,
        observationSection,
        activitySection
      ]
    }
  }
