import React from 'react'
import { InfoTile } from '~/components/molecules'
import { type WorksheetResponse, interpolateString, WorksheetResponseHelpers, type Experiment } from '~/features'
import { type TileId } from '~/features/questionnaire/types/questionnaireTypes'
import { Icon, Notepad, RetortFlask } from '~/components/atoms'
import { getSubjectObjects, getTreatmentObjects } from '~/features/experiment/fieldSelectors/treatmentSelectors'
import { sortBy } from 'lodash'

interface Props {
  tileId: TileId
  allResponses: WorksheetResponse[]
  experiment: Experiment
  setOpenTile: (tileId?: TileId) => void
  index: number
  tileCount: number
  openTile?: TileId
}

const
  labelLookup: Record<TileId, string> = {
    QUESTION: 'My Question',
    TREATMENTS: 'Treatments',
    SUBJECTS: 'Subjects'
  },
  descriptionLookup: Record<TileId, string> = {
    QUESTION: 'Click to see your experiment purpose',
    TREATMENTS: 'Click to see your experiment treatments',
    SUBJECTS: 'Click to see your experiment subjects and their treatments'
  },
  iconContentLookup: Record<TileId, React.ComponentProps<typeof Icon>['content']> = {
    QUESTION: '?',
    TREATMENTS: RetortFlask,
    SUBJECTS: Notepad
  },
  QuickAccessContent: React.FC<Pick<Props, 'tileId' | 'allResponses' | 'experiment'>> = ({ tileId, allResponses, experiment }) => {
    let content: string[] = []
    const responseEntities = WorksheetResponseHelpers.getEntitiesAcrossResponses(allResponses)

    if (tileId === 'QUESTION') {
      content = [interpolateString('How do changes in --independentVariable-- effect --dependentVariable--?', responseEntities) ?? 'Question not found']
    }

    if (tileId === 'TREATMENTS') {
      const treatments = getTreatmentObjects(responseEntities, experiment)

      content = sortBy(treatments, 'treatmentIndex').map((o, i) => o.treatmentName ? `${o.treatmentName}: ${o.treatment}` : o.treatment)
    }

    if (tileId === 'SUBJECTS') {
      const subjects = getSubjectObjects(responseEntities, experiment)

      content = subjects.map((o, i) => o.label)
    }

    if (content.length < 1) {
      throw new Error(`Tile "${tileId}" does not exist`)
    }

    return <>
      {content.flatMap((s, i) => [
        <span key={s}>{s}</span>,
        i === content.length - 1
          ? null
          : <br key={i} />
      ])}
    </>
  },
  QuickAccessTile: React.FC<Props> = ({ tileId, allResponses, experiment, openTile, setOpenTile, index, tileCount }) => {
    return <InfoTile
      label={labelLookup[tileId]}
      accessibleDescription={descriptionLookup[tileId]}
      content={<QuickAccessContent{ ...{ tileId, allResponses, experiment } } />}
      icon={<Icon content={iconContentLookup[tileId]} degreesRotation={13} frameStyle={tileId === 'QUESTION' ? 'circle' : 'outline'} />}
      isOpen={openTile === tileId}
      onClick={() => { openTile === tileId ? setOpenTile(undefined) : setOpenTile(tileId) }}
      tabAlignment={index === 0 ? 'left' : index === tileCount - 1 ? 'right' : 'center'}
    />
  }

export default QuickAccessTile
