import * as React from 'react'
import styled from 'styled-components'
import { isAllEmpty } from '~/utils/testers'
import { CONDITIONAL_QUESTION_ICON } from '~/utils/strings'
import { QuestionnaireHelpers, SYSTEM_FIELDS, formBodyForSpecialSection } from '~/features'
import { BasicPencil, HintText, Icon, SubheadingTwo } from '~/components/atoms'
import { ActionPointer } from '~/components/molecules'

import type { AnyPlainOrInputElement, ListSectionElement, PageElement, SpecialListSectionElement, SystemFieldName } from '~/features'

interface LineProps {
  title?: string
  pageOrListSection: PageElement | ListSectionElement
  onClick?: React.MouseEventHandler
  isSelected?: boolean
}

const
  NavWrapper = styled.div`
    width: 275px;
  `,
  NavAdder = styled(ActionPointer)`
    opacity: 0.4;
    margin: ${p => p.theme.emSmallSpacing / 2}em ${p => p.theme.emSmallSpacing}em;

    &:hover {
      opacity: 1;
    }
  `,
  NavRow = styled.div<{ $vertical?: boolean, $isSelected?: boolean, $unclickable?: boolean }>`
    display: flex;
    flex-direction: ${p => p.$vertical ? 'column' : 'row'};
    align-items: ${p => p.$vertical ? 'flex-start' : 'center'};
    gap: ${p => p.$vertical ? '4px' : '8px'};
    padding: ${p => p.theme.emSmallSpacing}em;
    border-radius: ${p => p.theme.pxBorderRadius}px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    ${p => p.$isSelected ? `background: ${p.theme.colors.containerAccentBg};` : ''}
    
    ${p => p.$unclickable
      ? ''
      : `
      cursor: pointer;

      &:hover {
        background: ${p.theme.colors.containerAccentBg};
      }
    `}
  `,
  PlainRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
  `,
  SizedIcon = styled(Icon)`
    width: 1.5em;
  `,
  IconCenteringWrapper = styled.div`
    height: calc(1em + 0.8rem); // line height for SubheadingTwo
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    align-items: center;
  `,
  NavSubheading = styled(SubheadingTwo)`
    white-space: pre-line;
  `,
  BodyItem = styled(HintText)`
    margin-left: 2em;
    margin-bottom: 4px;
    text-align: left;
    * {
      line-height: 1.2;
    }
  `,
  Sup = styled.span`
    margin-left: 2px;
    position: relative;
    font-size: 80%;
    top: -0.6em;
  `,
  getElementDescriptor: React.FC<{ element: AnyPlainOrInputElement }> = ({ element }) => {
    const
      relatedSystemField = 'fieldName' in element && element.fieldName
        ? SYSTEM_FIELDS[element.fieldName as SystemFieldName]
        : undefined,
      shownConditionally = !isAllEmpty(element.visibleWhen) || !isAllEmpty(relatedSystemField?.schemaEntry?.visibleWhen)

    return <span>{QuestionnaireHelpers.getBodyElementDescriptor(element)}{shownConditionally ? <Sup>{CONDITIONAL_QUESTION_ICON}</Sup> : null}</span>
  },
  PageOrListSectionLine: React.FC<LineProps> = ({ title, pageOrListSection, onClick, isSelected }) => {
    const body = 'specialSectionId' in pageOrListSection && pageOrListSection.specialSectionId
      ? formBodyForSpecialSection(pageOrListSection as SpecialListSectionElement)
      : pageOrListSection?.body

    return (
      <NavRow
        $vertical
        $unclickable={!title}
        onClick={onClick}
        $isSelected={isSelected}
        data-cy={
          pageOrListSection.elementVariety === 'PAGE'
            ? 'page-line'
            : 'section-line'
        } >
        {title
          ? <PlainRow>
            <IconCenteringWrapper>
              <SizedIcon content={BasicPencil} frameStyle="plain" />
            </IconCenteringWrapper>
            <NavSubheading>{title}</NavSubheading>
          </PlainRow>
          : null}
        {body.map((element, index) => {
          return <BodyItem key={element.id ?? index} color="default" className="section-line__body-item">
            {getElementDescriptor({ element })}
          </BodyItem>
        })}
      </NavRow>
    )
  },
  PagedSectionLine: React.FC<{ title: string, onClick: React.MouseEventHandler }> = ({ title, onClick }) => {
    return <NavRow onClick={onClick} data-cy="section-line">
      <IconCenteringWrapper>
        <SizedIcon content={BasicPencil} frameStyle="plain" />
      </IconCenteringWrapper>
      <NavSubheading>{title}</NavSubheading>
    </NavRow>
  }

export {
  PageOrListSectionLine,
  PagedSectionLine,
  NavWrapper,
  NavAdder,
  NavRow,
  SizedIcon,
  NavSubheading
}
