import { SystemFieldNames, SystemFieldGroups, INDEPENDENT_VARIABLES } from './systemFieldConstants'
import { fieldDetails as customVariableControlGroup } from './customVariableControlGroup'

import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  independentVariable = INDEPENDENT_VARIABLES.IRRIGATION_RATE,
  schemaEntry: QField = {
    fieldName: SystemFieldNames.customVariableTreatmentGroups,
    fieldType: 'COLLECTION',
    visibleWhen: customVariableControlGroup.schemaEntry.visibleWhen,
    format: customVariableControlGroup.schemaEntry.format
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.customVariableTreatmentGroups,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    isRequired: true,
    title: 'Treatment groups for all independent variables not listed',
    description: 'The list of treatments that will be used in the experiment (available when independent variable is not one of the built-in options)',
    treatmentGroupsFor: independentVariable,
    schemaEntry
  }

export {
  fieldDetails
}
