import { ICON_CONTENT_LIST, MASCOT_VARIANTS_LIST } from './optionLists'
import type { EntitySchema } from '~/form-brain2'
import type { AnyPlainOrInputElement, SpecialSectionId, VisibilityCondition } from '../questionnaire/types'
import { blurbElementSchema } from './elementSchemas'
import { blurbFormBody } from './elementFormBodies'
import type { SpecialListSectionAttributes } from '~/features/questionnaire/types/sectionElementTypes'
import { MISC_DEFAULTS } from '~/utils/strings'

const
  conditionIsOpenerOrCloser: VisibilityCondition = {
    conditionType: 'anyOf',
    valueName: 'elementVariety',
    testValue: ['LIST_OPENER_SECTION', 'LIST_CLOSER_SECTION']
  },
  conditionIsRepeater: VisibilityCondition = {
    conditionType: 'equals',
    valueName: 'elementVariety',
    testValue: 'LIST_ENTRY_SECTION'
  },
  relevantSpecialSectionIdsByFieldName: Record<keyof Omit<SpecialListSectionAttributes, 'specialSectionId'>, SpecialSectionId[]> = {
    anovaAlpha: ['STATS_TEST'],
    anovaLabel: ['STATS_TEST'],
    detailsLabel: ['MATERIAL', 'SET_UP_STEP', 'MEASUREMENT_RECORD', 'ACTIVITY_RECORD', 'OBSERVATION_RECORD', 'SUMMARY_STATS'],
    entryIconContent: ['PLAN_ACTIVITY', 'PLAN_MEASUREMENT', 'MATERIAL', 'SET_UP_STEP', 'MEASUREMENT_RECORD', 'ACTIVITY_RECORD', 'OBSERVATION_RECORD', 'SUMMARY_STATS', /* 'DATA_VISUALIZATION', */ 'STATS_TEST'],
    includedSummaryStats: ['SUMMARY_STATS'],
    includedTestTypes: ['STATS_TEST'],
    measurementLabel: ['MEASUREMENT_RECORD'],
    measurementEntryTitleSegments: ['MEASUREMENT_RECORD'],
    permitOutlierRemoval: ['SUMMARY_STATS'],
    seriesLabel: [/* 'DATA_VISUALIZATION' */],
    subjectLabel: ['MEASUREMENT_RECORD'],
    tTestAlpha: ['STATS_TEST'],
    tTestLabel: ['STATS_TEST'],
    whatLabel: ['MATERIAL', 'SET_UP_STEP', 'PLAN_ACTIVITY', 'PLAN_MEASUREMENT', 'ACTIVITY_RECORD'],
    whenLabel: ['MEASUREMENT_RECORD', 'ACTIVITY_RECORD', 'OBSERVATION_RECORD', 'PLAN_ACTIVITY', 'PLAN_MEASUREMENT'],
    xAxisRangeLabel: [/* 'DATA_VISUALIZATION' */],
    yAxisRangeLabel: [/* 'DATA_VISUALIZATION' */]
  },
  getSpecialSectionVisibilityConditions = (fieldName: keyof Omit<SpecialListSectionAttributes, 'specialSectionId'>): VisibilityCondition[] => [{
    conditionType: 'anyOf',
    conditionScope: 'ownScope',
    valueName: 'specialSectionId',
    testValue: relevantSpecialSectionIdsByFieldName[fieldName]
  }, {
    conditionType: 'present',
    valueName: 'specialSectionId'
  }]

export const
  listSectionSchema: EntitySchema = {
    id: {
      fieldName: 'id',
      fieldType: 'STRING'
    },
    elementVariety: {
      fieldName: 'elementVariety',
      fieldType: 'ENUM',
      readOnlyWhen: [{ conditionType: 'always' }]
    },
    body: {
      fieldName: 'body',
      fieldType: 'COLLECTION'
      /* TODO DEV add format */
    },
    title: {
      fieldName: 'title',
      fieldType: 'STRING',
      requiredWhen: [conditionIsOpenerOrCloser]
    },
    entryNameSingular: {
      fieldName: 'entryNameSingular',
      fieldType: 'STRING',
      requiredWhen: [conditionIsRepeater]
    },
    entryNamePlural: {
      fieldName: 'entryNamePlural',
      fieldType: 'STRING',
      requiredWhen: [conditionIsRepeater]
    },
    pageHint: {
      fieldName: 'pageHint',
      fieldType: 'STRING'
    },
    mascotVariant: {
      fieldName: 'mascotVariant',
      fieldType: 'ENUM'
    },
    sortByField: {
      fieldName: 'sortByField',
      fieldType: 'STRING'
    },
    minimumLength: {
      fieldName: 'minimumLength',
      fieldType: 'NUMBER'
    },
    maximumLength: {
      fieldName: 'maximumLength',
      fieldType: 'NUMBER'
    },
    checkList: {
      fieldName: 'checkList',
      fieldType: 'BOOLEAN'
    },
    deleteButtonLabel: {
      fieldName: 'deleteButtonLabel',
      fieldType: 'STRING'
    },
    entryLabelField: {
      fieldName: 'entryLabelField',
      fieldType: 'STRING'
    },
    carryOverFields: {
      fieldName: 'carryOverFields',
      fieldType: 'STRING:ARRAY'
    }
    // printableOnly?: boolean // not yet supported outside benchmarking
    // digitalOnly?: boolean // not yet supported outside benchmarking
    // nonSummaryOnly?: boolean // not yet supported outside benchmarking
    // style?: React.CSSProperties // not yet supported outside system forms
    // visibleWhen?: ValidationCondition[] // not supported for sections outside system forms?
    // parentId?: string // system use only
    // className?: string // system use only
    // offerPrintableForm?: boolean // not yet supported
  },
  listSectionFormBody: AnyPlainOrInputElement[] = [
    {
      id: 'listSectionTitle',
      elementVariety: 'TEXT_INPUT',
      fieldName: 'title',
      label: 'Section title:',
      placeholder: 'Enter a title',
      hint: 'This is the title shown at the top of the form for this section and on its entry row when working on other sections.',
      visibleWhen: [conditionIsOpenerOrCloser]
    },
    {
      id: 'sectionEntryNameSingular',
      elementVariety: 'TEXT_INPUT',
      fieldName: 'entryNameSingular',
      label: 'Entry label:',
      placeholder: 'Enter a label',
      hint: 'This is the word used to label the section. It is used in the add button "Add a (Your Label Here)" and in the section selection dropdown.',
      visibleWhen: [conditionIsRepeater]
    },
    {
      id: 'sectionEntryNamePlural',
      elementVariety: 'TEXT_INPUT',
      fieldName: 'entryNamePlural',
      label: 'Plural entry label:',
      placeholder: 'Enter a label',
      hint: 'This is the word used to label the group of items for this section. It is used in a variety of places for example "Add 3 more (Your Plural Heres) before continuing".',
      visibleWhen: [conditionIsRepeater]
    },
    {
      id: 'sectionIsCheckList',
      elementVariety: 'CHECKBOX_INPUT',
      fieldName: 'checkList',
      label: 'Display as checklist, entries for this section can be checked off',
      visibleWhen: [conditionIsRepeater]
    },
    {
      id: 'listSectionEntryLabel',
      fieldName: 'entryLabelField',
      elementVariety: 'SELECT_INPUT',
      label: 'Title entries with answer to:',
      hint: 'Use the answer to the selected question as the title for the list entry. Only long answer, short answer, text, and numeric answers can be used.',
      visibleWhen: [
        conditionIsRepeater,
        { conditionType: 'notEquals', valueName: 'specialSectionId', testValue: 'MEASUREMENT_RECORD' }
      ]
    },
    {
      id: 'listSectionSortByField',
      elementVariety: 'SELECT_INPUT',
      fieldName: 'sortByField',
      hint: 'Which answer, if any, should be used to sort the entries for this section? Only long answer, short answer, text, date, yes/no, and numeric answers can be used.',
      label: 'Sort by:',
      noSort: true,
      blankOption: 'None, display in order added',
      visibleWhen: [conditionIsRepeater]
    },
    {
      id: 'listSectionSortOrder',
      elementVariety: 'SELECT_INPUT',
      fieldName: 'sortOrder',
      label: 'How should entries be sorted?',
      hint: 'Entries will be sorted into ascending or descending order based on the step settings.',
      blankOption: 'Alphabetically',
      placeholder: 'Default: Alphabetically',
      list: [{
        optionValue: 'numerically',
        optionLabel: 'Numerically (when possible)'
      }],
      visibleWhen: [
        conditionIsRepeater,
        { conditionType: 'present', valueName: 'sortByField' }
      ]
    },
    {
      id: 'listSectionMinimumLength',
      elementVariety: 'TEXT_INPUT',
      fieldName: 'minimumLength',
      label: 'Minimum number of entries:',
      placeholder: 'Enter a number (optional)',
      hint: 'Enter a number here to require learners to add a certain number of entries of this type before moving on to the closing section or submitting.',
      visibleWhen: [conditionIsRepeater]
    },
    {
      id: 'listSectionMaximumLength',
      elementVariety: 'TEXT_INPUT',
      fieldName: 'maximumLength',
      label: 'Maximum number of entries:',
      placeholder: 'Enter a number (optional)',
      hint: 'Enter a number here to limit the number entries a learner can add of this type. Once they have reached the limit, they can continue to add other entries if available.',
      visibleWhen: [conditionIsRepeater]
    },
    {
      id: 'listSectionPageHint',
      elementVariety: 'LONG_ANSWER',
      fieldName: 'pageHint',
      label: 'Section hint:',
      hint: 'This text will be shown in the mascot\'s speech bubble when this section is being worked on'
    },
    {
      id: 'listSectionMascotVariant',
      elementVariety: 'SELECT_INPUT',
      fieldName: 'mascotVariant',
      hint: 'Which version of the mascot should be shown when this section is being worked on?',
      label: 'Section mascot:',
      noSort: true,
      blankOption: 'None',
      list: MASCOT_VARIANTS_LIST,
      visibleWhen: [
        { conditionType: 'present', valueName: 'pageHint' },
        { conditionType: 'wordLengthAtLeast', valueName: 'pageHint', testValue: 1 }
      ]
    },
    {
      id: 'responseMascotPreview',
      elementVariety: 'SPECIAL',
      specialElementId: 'mascotPreview',
      textKey: 'pageHint',
      variantKey: 'mascotVariant',
      positionBelow: true
    },
    {
      id: 'listSectionDeleteButtonLabel',
      elementVariety: 'TEXT_INPUT',
      fieldName: 'deleteButtonLabel',
      label: 'Delete button label:',
      placeholder: 'Default: Delete this item',
      hint: 'Override the text on the delete button for items in this section, if desired.',
      visibleWhen: [conditionIsRepeater]
    },
    {
      id: 'stepCarryOverFields',
      elementVariety: 'MULTI_SELECT_INPUT',
      fieldName: 'carryOverFields',
      hint: 'Any questions you choose here will be copied over to new entries from the last entry of this section in the sorted list. Pre-filling some answers this way can make it faster to fill out the form multiple times in a row.',
      label: 'Carry over to new entries:',
      noSort: true,
      visibleWhen: [conditionIsRepeater]
    }
  ],
  specialListSectionFormBody: AnyPlainOrInputElement[] = [
    {
      id: 'specialSettingsHeading',
      elementVariety: 'HEADING',
      text: 'Special Settings',
      visibleWhen: [{ conditionType: 'present', valueName: 'specialSectionId' }]
    },
    {
      id: 'specialWhatLabel',
      elementVariety: 'SHORT_ANSWER',
      fieldName: 'whatLabel',
      label: 'Label for basic "what" question:',
      placeholder: 'Default varies by section',
      visibleWhen: getSpecialSectionVisibilityConditions('whatLabel')
    },
    {
      id: 'specialWhenLabel',
      elementVariety: 'SHORT_ANSWER',
      fieldName: 'whenLabel',
      label: 'Label for timing question:',
      placeholder: `Default: "${MISC_DEFAULTS.whenLabel}"`,
      visibleWhen: getSpecialSectionVisibilityConditions('whenLabel')
    },
    {
      id: 'specialSubjectLabel',
      elementVariety: 'SHORT_ANSWER',
      fieldName: 'subjectLabel',
      label: 'Label for subject input:',
      placeholder: `Default: "${MISC_DEFAULTS.subjectLabel}"`,
      visibleWhen: getSpecialSectionVisibilityConditions('subjectLabel')
    },
    {
      id: 'specialMeasurementLabel',
      elementVariety: 'SHORT_ANSWER',
      fieldName: 'measurementLabel',
      label: 'Label for measurement input:',
      placeholder: `Default: "${MISC_DEFAULTS.measurementLabel}"`,
      visibleWhen: getSpecialSectionVisibilityConditions('measurementLabel')
    },
    {
      id: 'specialMeasurementTitleSegments',
      elementVariety: 'MULTI_SELECT_INPUT',
      fieldName: 'measurementEntryTitleSegments',
      label: 'Contents of entry label:',
      hint: 'This is the text which will be shown in the minimized list of data already entered. If no selection is made, the default label will be used. The default label includes all of the information.',
      visibleWhen: getSpecialSectionVisibilityConditions('measurementEntryTitleSegments'),
      noSort: true,
      list: [
        'date', 'subject', 'treatment', 'measurement'
      ]
    },
    {
      id: 'specialPermitOutlierRemoval',
      elementVariety: 'CHECKBOX_INPUT',
      fieldName: 'permitOutlierRemoval',
      label: 'Permit learners to mark outliers and exclude them from analysis',
      visibleWhen: getSpecialSectionVisibilityConditions('permitOutlierRemoval')
    },
    {
      id: 'specialIncludedSummaryStats',
      fieldName: 'includedSummaryStats',
      elementVariety: 'MULTI_SELECT_INPUT',
      label: 'Summary stats to include:',
      list: [{
        optionValue: 'range',
        optionLabel: 'Range'
      }, {
        optionValue: 'mean',
        optionLabel: 'Mean'
      }, {
        optionValue: 'SD',
        optionLabel: 'Standard deviation'
      }],
      visibleWhen: getSpecialSectionVisibilityConditions('includedSummaryStats')
    },
    {
      id: 'specialDetailsLabel',
      elementVariety: 'SHORT_ANSWER',
      fieldName: 'detailsLabel',
      label: 'Label for long answer details text box:',
      placeholder: `Default: "${MISC_DEFAULTS.detailsLabel}"`,
      visibleWhen: getSpecialSectionVisibilityConditions('detailsLabel')
    },
    {
      id: 'specialEntryIconContent',
      fieldName: 'includedTestTypes',
      elementVariety: 'MULTI_SELECT_INPUT',
      label: 'Test types to include:',
      list: [{
        optionValue: 't-test',
        optionLabel: MISC_DEFAULTS.tTestLabel
      }, {
        optionValue: 'ANOVA',
        optionLabel: MISC_DEFAULTS.anovaLabel
      }],
      visibleWhen: getSpecialSectionVisibilityConditions('includedTestTypes')
    },
    {
      id: 'specialDetailsLabel',
      elementVariety: 'SHORT_ANSWER',
      fieldName: 'tTestLabel',
      label: 'Label for t-test in picker:',
      placeholder: `Default: "${MISC_DEFAULTS.tTestLabel}"`,
      visibleWhen: getSpecialSectionVisibilityConditions('tTestLabel')
    },
    {
      id: 'specialDetailsLabel',
      elementVariety: 'NUMBER_INPUT',
      fieldName: 'tTestAlpha',
      label: 'Alpha (\u03b1) for t-test significance:',
      placeholder: `Default: "${MISC_DEFAULTS.tTestAlpha}"`,
      visibleWhen: getSpecialSectionVisibilityConditions('tTestAlpha')
    },
    {
      id: 'specialDetailsLabel',
      elementVariety: 'SHORT_ANSWER',
      fieldName: 'anovaLabel',
      label: 'Label for ANOVA test in picker:',
      placeholder: `Default: "${MISC_DEFAULTS.anovaLabel}"`,
      visibleWhen: getSpecialSectionVisibilityConditions('anovaLabel')
    },
    {
      id: 'specialDetailsLabel',
      elementVariety: 'NUMBER_INPUT',
      fieldName: 'anovaAlpha',
      label: 'Alpha (\u03b1) for ANOVA significance:',
      placeholder: `Default: "${MISC_DEFAULTS.anovaAlpha}"`,
      visibleWhen: getSpecialSectionVisibilityConditions('anovaAlpha')
    },
    {
      id: 'specialEntryIconContent',
      fieldName: 'entryIconContent',
      elementVariety: 'SELECT_INPUT',
      label: 'Icon for content for entries of this type:',
      customOption: 'Custom Text',
      list: ICON_CONTENT_LIST,
      visibleWhen: getSpecialSectionVisibilityConditions('entryIconContent')
    },
    // seriesLabel?: string
    // xAxisRangeLabel?: string
    // yAxisRangeLabel?: string
    {
      id: 'generalSettingsHeading',
      elementVariety: 'HEADING',
      text: 'General Settings',
      visibleWhen: [{ conditionType: 'present', valueName: 'specialSectionId' }]
    }
  ],
  pagedSectionElementSchema: EntitySchema = {
    id: {
      fieldName: 'id',
      fieldType: 'STRING'
    },
    elementVariety: {
      fieldName: 'elementVariety',
      fieldType: 'ENUM',
      readOnlyWhen: [{ conditionType: 'always' }]
    },
    pages: {
      fieldName: 'pages',
      fieldType: 'COLLECTION'
      /* TODO DEV add format */
    },
    title: {
      fieldName: 'title',
      fieldType: 'STRING'
    },
    subtitle: {
      fieldName: 'subtitle',
      fieldType: 'STRING'
    },
    iconColor: {
      fieldName: 'iconColor',
      fieldType: 'ENUM'
    },
    iconContent: {
      fieldName: 'iconContent',
      fieldType: 'STRING'
    }
  },
  pagedSectionFormBody: AnyPlainOrInputElement[] = [
    {
      id: 'pagedSectionTitle',
      elementVariety: 'TEXT_INPUT',
      fieldName: 'title',
      label: 'Section title:',
      hint: 'This is the title shown on the tab for this section if there is more than one section. Leave blank and sections will be numbered instead.'
    },
    {
      id: 'pagedSectionSubtitle',
      elementVariety: 'TEXT_INPUT',
      fieldName: 'subtitle',
      label: 'Subtitle:',
      placeholder: 'Default: "Question:"',
      hint: 'This is the small text shown above the question on each page.'
    },
    {
      id: 'sectionIconContent',
      fieldName: 'iconContent',
      elementVariety: 'SELECT_INPUT',
      label: 'Icon:',
      customOption: 'Custom Text',
      list: ICON_CONTENT_LIST
    },
    {
      id: 'sectionIconColor',
      fieldName: 'iconColor',
      elementVariety: 'COLOR_PICKER',
      label: 'Icon Color:',
      contentKey: 'iconContent',
      hint: 'The color the icon appears when the learner is working on this section',
      visibleWhen: [{ conditionType: 'present', valueName: 'iconContent' }]
    }
  ],
  pageElementSchema: EntitySchema = {
    id: {
      fieldName: 'id',
      fieldType: 'STRING'
    },
    elementVariety: {
      fieldName: 'elementVariety',
      fieldType: 'ENUM',
      readOnlyWhen: [{ conditionType: 'always' }]
    },
    body: {
      fieldName: 'body',
      fieldType: 'COLLECTION'
      /* TODO DEV add format */
    },
    pageHint: {
      fieldName: 'pageHint',
      fieldType: 'STRING'
    },
    mascotVariant: {
      fieldName: 'mascotVariant',
      fieldType: 'ENUM'
    },
    specialContentArea: {
      fieldName: 'specialContentArea',
      fieldType: 'SCOPE',
      format: {
        specialContentId: {
          fieldName: 'specialContentId',
          fieldType: 'ENUM'
        }
      }
    },
    heading: {
      fieldName: 'heading',
      fieldType: 'SCOPE',
      format: blurbElementSchema
    },
    useCheckpoint: {
      fieldName: 'useCheckpoint',
      fieldType: 'BOOLEAN'
    },
    checkpointButtonLabel: {
      fieldName: 'checkpointButtonLabel',
      fieldType: 'STRING',
      requiredWhen: [{ conditionType: 'truthy', valueName: 'useCheckpoint' }]
    }
  },
  pageSettingsFormBody: AnyPlainOrInputElement[] = [
    {
      id: 'headingTypeSelect',
      elementVariety: 'SELECT_INPUT',
      fieldName: '_headingType',
      invalidates: ['heading', 'useCheckpoint', 'checkpointButtonLabel'],
      label: 'Heading:',
      hint: 'Try out different options by making changes, heading over to the preview tab to check them out, and them coming back here',
      list: [
        { optionValue: 'text', optionLabel: 'Custom text' },
        { optionValue: 'blurb', optionLabel: 'Custom blurb' }
      ],
      blankOption: 'Use first question as heading'
    },
    {
      id: 'customHeading',
      elementVariety: 'SHORT_ANSWER',
      fieldName: 'heading',
      placeholder: 'Enter a custom heading',
      visibleWhen: [
        { conditionType: 'equals', valueName: '_headingType', testValue: 'text' },
        { conditionType: 'present', valueName: '_headingType' }
      ]
    },
    {
      id: 'toggleUseCheckpoint',
      fieldName: 'useCheckpoint',
      elementVariety: 'CHECKBOX_INPUT',
      label: 'Include checkpoint (learner clicks a button to move on)',
      visibleWhen: [
        { conditionType: 'equals', valueName: '_headingType', testValue: 'blurb' },
        { conditionType: 'present', valueName: '_headingType' }
      ]
    },
    {
      id: 'checkpointLabel',
      fieldName: 'checkpointButtonLabel',
      hint: 'The text for the checkpoint button. Leave this blank to use the default "Next" button label for the group.',
      label: 'Checkpoint button label',
      elementVariety: 'TEXT_INPUT',
      visibleWhen: [
        { conditionType: 'truthy', valueName: 'useCheckpoint' }
      ]
    },
    {
      id: 'pageBlurbHeading',
      elementVariety: 'SCOPE',
      fieldName: 'heading',
      label: 'Heading Blurb',
      formBody: blurbFormBody,
      defaultEntry: {
        elementVariety: 'BLURB',
        withBackground: true
      },
      visibleWhen: [
        { conditionType: 'equals', valueName: '_headingType', testValue: 'blurb' },
        { conditionType: 'present', valueName: '_headingType' }
      ]
    },
    {
      id: 'stepPagePageHint',
      elementVariety: 'LONG_ANSWER',
      fieldName: 'pageHint',
      label: 'Page hint:',
      hint: 'This text will be shown in the mascot\'s speech bubble on this page'
    },
    {
      id: 'stepPageMascotVariant',
      elementVariety: 'SELECT_INPUT',
      fieldName: 'mascotVariant',
      hint: 'Which version of the mascot should be shown on this page?',
      label: 'Section mascot:',
      noSort: true,
      blankOption: 'None',
      list: MASCOT_VARIANTS_LIST,
      visibleWhen: [
        { conditionType: 'present', valueName: 'pageHint' },
        { conditionType: 'wordLengthAtLeast', valueName: 'pageHint', testValue: 1 }
      ]
    },
    {
      id: 'stepPageMascotPreview',
      elementVariety: 'SPECIAL',
      specialElementId: 'mascotPreview',
      textKey: 'pageHint',
      variantKey: 'mascotVariant',
      positionBelow: true
    },
    {
      id: 'pageSpecialContentArea',
      elementVariety: 'SCOPE',
      fieldName: 'specialContentArea',
      formBody: [{
        id: 'pageSpecialContentId',
        elementVariety: 'SELECT_INPUT',
        fieldName: 'specialContentId',
        blankOption: 'None',
        label: 'Special Content:',
        list: [
          { optionValue: 'RESULTS_EXPLORER', optionLabel: 'Results Explorer' },
          { optionValue: 'TREATMENT_LAYOUT', optionLabel: 'Diagram of Treaments (Coming Soon)' }
        ]
      }]
    }
  ]
