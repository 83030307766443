import * as React from 'react'

interface Props {
  color?: string
  className?: string
}

const
  AngleBrace: React.FC<Props> = ({ className, color = 'black' }) => {
    return (
      <svg className={className} viewBox="0 0 46 78" fill="none" xmlns="http://www.w3.org/2000/svg" data-cy="angle-brace">
        <path d="M46 39L7 78L0 71L32 39L0 7L7 0L46 39Z" fill={color} />
      </svg>
    )
  }

export default AngleBrace
