import * as React from 'react'
import styled from 'styled-components'
import { isEqual, keys, pick, upperFirst } from 'lodash'
import { Form } from '~/form-brain2'
import { useWindowWidth } from '~/hooks'
import { SYSTEM_FIELDS, elementVarietiesByFieldType, experimentFormResolvers } from '~/features'
import { baseElementSchema, elementSchemas } from '~/features/worksheet/elementFormDetails'
import { Icon } from '~/components/atoms'
import { Blurb, FormRenderer } from '~/components/molecules'

import type { EntityCollection, EntityObject, EntitySchema, FormOnAttrChangeFn, FormOnChangeFn, FormOnSubmitFn, SubscribeToFormStatusFn } from '~/form-brain2'
import type { ObjectId } from '~/types/utilities'
import type { SystemFieldName, AnyPlainOrInputElement, ElementVariety, ListOption, ListWorksheet, PagedWorksheet } from '~/features'
import type { ElegibleVariety } from '~/features/worksheet/elementFormDetails'

interface Props {
  bodyElements: AnyPlainOrInputElement[]
  allWorksheets: Array<ListWorksheet | PagedWorksheet>
  currentWorksheetId: ObjectId
  updateBody: (newValues: AnyPlainOrInputElement[]) => void
  formTypeName?: string
}

const
  StyledForm = styled(Form)`
    margin-bottom: 0;

    .form-body-form-collection > div {
      margin-bottom: 0;
    }
  `,
  StyledBlurb = styled(Blurb)`
    margin-bottom: ${p => p.theme.emSmallSpacing}em;
    padding: ${p => p.theme.emSmallSpacing}em;
    background: ${p => p.theme.colors.containerBg};
    border-radius: ${p => p.theme.pxBorderRadius * 1.4}px;
    min-height: unset;
  `,
  resetElementValues = (element: EntityObject, newVariety: ElegibleVariety): EntityObject => {
    const
      newSchema = elementSchemas[newVariety],
      cleanedValues = pick(element, keys(newSchema).concat(keys(baseElementSchema)).concat(['_isEditing'])),
      requiredValues = newVariety === 'SELECT_INPUT'
        ? { list: [{}] }
        : newVariety === 'FILL_IN_THE_BLANK'
          ? { segments: [] }
          : {}

    return {
      ...requiredValues,
      ...cleanedValues
    }
  },
  forceFormat = (fieldName: string): string => {
    return fieldName.replace(/[^a-zA-Z0-9]/g, '')
  },
  generateFieldName = (element: EntityObject): string | undefined => {
    const label: string | undefined = element.label as string | undefined

    if (!label) { return undefined }

    const
      words: string[] = label.split(/[ -]/),
      fragments: string[] = words.map(s => forceFormat(s).slice(0, 3)).map(s => upperFirst(s))

    return fragments.join('').slice(0, 24)
  },
  /** TODO - audit this method and its flow; it may not be necessary since the removal of _elementVarietyCategory transient variable */
  individualElementOnChange = (newEntryValues: EntityObject, prevEntries: EntityObject[]): EntityObject => {
    let updatedEntry
    const
      prevValues = prevEntries.find(o => o.id === newEntryValues.id) ?? {},
      nextValues = newEntryValues,
      prevVariety = prevValues.elementVariety as ElegibleVariety | undefined,
      nextVariety = nextValues.elementVariety as ElegibleVariety | undefined

    if (nextVariety && prevVariety !== nextVariety) {
      updatedEntry = resetElementValues(nextValues, nextVariety)
    } else if (nextVariety && nextValues.label && prevValues.label !== nextValues.label) {
      const
        generatedFieldName = generateFieldName(nextValues),
        prevGeneratedFieldName = generateFieldName(prevValues),
        shouldHaveFieldName = !!elementSchemas[nextVariety]?.fieldName,
        hasUniqueFieldName = nextValues.fieldName && !prevGeneratedFieldName?.match(new RegExp(forceFormat(nextValues.fieldName as string), 'gi'))

      updatedEntry = {
        ...nextValues,
        fieldName: hasUniqueFieldName
          ? forceFormat(nextValues.fieldName as string)
          : shouldHaveFieldName
            ? generatedFieldName
            : undefined
      }
    } else if (nextValues.fieldName && nextValues.fieldName !== prevValues.fieldName) {
      updatedEntry = {
        ...nextValues,
        fieldName: forceFormat(nextValues.fieldName as string)
      }
    } else if (nextVariety === 'FILL_IN_THE_BLANK' && Array.isArray(nextValues.segments)) {
      updatedEntry = {
        ...nextValues,
        segments: nextValues.segments.map(s => individualElementOnChange(s as EntityObject, (Array.isArray(prevValues.segments) ? prevValues.segments : []) as EntityObject[]))
      }
    } else if ((nextVariety === 'COLLECTION' || nextVariety === 'SCOPE') && Array.isArray(nextValues.formBody)) {
      updatedEntry = {
        ...nextValues,
        formBody: nextValues.formBody.map(s => individualElementOnChange(s as EntityObject, (Array.isArray(prevValues.formBody) ? prevValues.formBody : []) as EntityObject[]))
      }
    } else {
      updatedEntry = nextValues
    }

    return updatedEntry
  },
  FormBodyForm: React.FC<Props> = ({ bodyElements, updateBody, formTypeName, allWorksheets, currentWorksheetId }) => {
    const
      windowWidth = useWindowWidth(),
      formBody: AnyPlainOrInputElement[] = [{
        id: 'form-element-collection',
        elementVariety: 'COLLECTION',
        fieldName: 'body',
        entryNamePlural: 'content',
        entryNameSingular: 'content',
        className: 'form-body-form-collection',
        hideLabel: true,
        useAlternateEntryRenderer: 'bodyElementList',
        formBody: []
      }],
      schema: EntitySchema = {
        body: {
          fieldName: 'body',
          fieldType: 'COLLECTION',
          dynamicFormatFieldName: 'elementVariety',
          dynamicFormatByFieldValue: elementSchemas
        }
      },
      onChange: FormOnChangeFn = ({ nextValues, prevValues }) => {
        const
          prevBody = (prevValues.body ?? []) as EntityObject[],
          newBody = (nextValues.body ?? []) as EntityObject[],
          updatedValues = newBody.map(o => individualElementOnChange(o, prevBody))

        return {
          body: updatedValues
        }
      },
      onChangeAttr: FormOnAttrChangeFn = ({ bodyElement, nextValue, nextValues, contextAndResolvers }) => {
        if (bodyElement.id === 'BIVPicker' && nextValues.fieldName) {
          if (!nextValue) return nextValues

          const
            baseField = SYSTEM_FIELDS[nextValues.fieldName as SystemFieldName]?.schemaEntry,
            elementVariety = baseField ? (baseField.options ? 'SELECT_INPUT' : elementVarietiesByFieldType[baseField.fieldType]) : (nextValues.elementVariety as ElementVariety),
            carryoverList = bodyElement.id === 'BIVPicker' ? undefined : nextValues.list,
            newList = ((carryoverList as ListOption[] | undefined) ?? baseField?.options ?? []).map(o => ({ ...o, _systemValue: !!baseField })),
            list = ['SELECT_INPUT', 'MULTI_SELECT_INPUT'].includes(elementVariety as ElementVariety)
              ? newList
              : undefined

          return {
            ...nextValues,
            ...(elementVariety ? { elementVariety } : {}),
            ...(list ? { list: (list as unknown as EntityCollection) } : {})
          }
        }

        return experimentFormResolvers.onAttrChangeCallback({ bodyElement, nextValue, nextValues, contextAndResolvers })
      },
      updateUpstream: SubscribeToFormStatusFn = ({ values: nextValues }) => {
        const nextBody = (nextValues?.body ?? [{}])

        if (!isEqual(bodyElements, nextBody)) {
          updateBody(nextBody as unknown as AnyPlainOrInputElement[])
        }
      },
      onSubmit: FormOnSubmitFn = () => Promise.resolve({})

    return <>
      {windowWidth && windowWidth < 800
        ? <StyledBlurb content="For the best editing experience, please use a larger screen." icon={<Icon content="!" color="attention" />} />
        : null}
      <StyledForm
        {...experimentFormResolvers}
        formId="questionnaire-body"
        initialValues={{
          body: bodyElements
        } as unknown as EntityObject}
        FormRenderer={FormRenderer}
        formBody={formBody}
        entitySchema={schema}
        onChangeCallback={onChange}
        onAttrChangeCallback={onChangeAttr}
        onSubmitCallback={onSubmit}
        subscribeToFormStatus={updateUpstream}
        formOptions={{
          automaticIdFields: true,
          formTypeName,
          RootFormRenderer: FormRenderer,
          worksheetsThisExperiment: allWorksheets,
          currentWorksheetId
        }}
      />
    </>
  }

export default FormBodyForm
