export type TabIconColor = 'understate' | 'disabled' | undefined
export type TabRole = 'active' | 'inactive' | 'disabled'

type TabTextColor = 'headingAccentText' | 'headingText' | 'understateLight'
type TabBackgroundColor = 'containerAccentBg' | 'containerBg'

export const
  pxIconWidth = 31,
  pxTabHorizontalPadding = 6.5,
  pxMinTabWidth = pxIconWidth + (pxTabHorizontalPadding * 2),
  remTabHeight = 2.74,
  textColorKeys: Record<TabRole, TabTextColor> = {
    active: 'headingAccentText',
    inactive: 'headingText',
    disabled: 'understateLight'
  },
  backgroundColorKeys: Record<TabRole, TabBackgroundColor> = {
    active: 'containerAccentBg',
    inactive: 'containerBg',
    disabled: 'containerBg'
  },
  iconColorLookup: Record<TabRole, TabIconColor> = {
    active: undefined,
    inactive: 'understate',
    disabled: 'disabled'
  }
