import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Fertilizer rate (value)',
  description = 'The amount of fertilizer that will be used. When this is on its own, it will be applied to all subjects. When part of a treatment, it will be applied to subjects receiving that treatment.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.fertilizerRateValue,
    fieldType: 'NUMBER'
    // visibleWhen: ivEqualityCondition(variableName, exclude)
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.fertilizerRateValue,
    variableGroup: SystemFieldGroups.CONTROL_VARIABLES,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
