import * as React from 'react'
import styled from 'styled-components'
import { useLearnerStepContext, useUpdateEntity } from '~/hooks'
import DeleteConfirmationComplex from '~/components/organisms/DeleteConfirmationComplex'
import { isAllEmpty } from '~/form-brain2/testers'
import { getBaseErrorString } from '~/utils/formatters'

interface Props {
  resetButtonLabel: string
  isDisabled?: boolean
  onSuccess?: () => void
}

const
  Actions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: ${p => p.theme.emBaseSpacing * 1.5}em;
  `,
  ListResetComplex: React.FC<Props> = ({ resetButtonLabel, isDisabled, onSuccess }) => {
    const
      { experiment, response } = useLearnerStepContext(),
      { updateEntity, isUpdating: isProcessingUpdate, errors } = useUpdateEntity(),
      isError = !isAllEmpty(errors),
      parsedError = getBaseErrorString(
        errors,
        'Unable to reset this list. Please try again later and contact support if the problem continues.'
      ),
      onConfirmDelete = (): void => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        updateEntity({
          newValues: { ...response, responses: { entries: [] } },
          entity: response
        }).then(
          () => { if (onSuccess) { onSuccess() } },
          (error) => {
            /* keep log */ console.log(error)
          }
        )
      }

    return <Actions>
      <DeleteConfirmationComplex
        isProcessing={!!isProcessingUpdate}
        isDisabled={!!isDisabled}
        onConfirmDelete={onConfirmDelete}
        triggerLabel={resetButtonLabel}
        subheading="Erase this list and start over"
        experiment={experiment}
        errorMessage={isError ? parsedError : undefined}
        overrideButtonProps={{ size: 'small' }}
      />
    </Actions>
  }

export default ListResetComplex
