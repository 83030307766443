import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'
import { EXPERIMENT_LOCATIONS } from './location'

const
  title = 'Randomization options',
  RANDOMIZATION_METHODS = {
    ASSIGN_RANDOMLY: 'random treatment assignment',
    ASSIGN_RANDOM_BLOCK: 'random block treatment assignment',
    POSITION_RANDOMLY: 'random position'
  } as const,
  description = 'This choice determines how subjects will be numbered. If random assignment is chosen, the assignment of subject numbers will be entirely random. If random block is chosen, subjects will be numbered in blocks, each including one replicate of each treatment (Subjects 1-5 include each treatment, replicate 1; Subjects 6-10 include each treatment, replicate 2; and so on) and numbers within each block will be assigned randomly. If random placement is chosen or no randomization method is chosen, subjects will be numbered in order of treatment with all replicates sequentially numbered (Subject 1 is treatment 1, replicate 1; Subject 2 is treatment 1, replicate 2; and so on).',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.randomizationMethod,
    fieldType: 'STRING',
    options: [
      {
        optionValue: RANDOMIZATION_METHODS.ASSIGN_RANDOMLY,
        optionLabel: 'Assign treatments to subjects randomly'
      },
      {
        optionValue: RANDOMIZATION_METHODS.ASSIGN_RANDOM_BLOCK,
        optionLabel: 'Assign treatments to subjects within replicate blocks randomly'
      },
      {
        optionValue: RANDOMIZATION_METHODS.POSITION_RANDOMLY,
        optionLabel: 'Place subjects from different groups in a random order',
        visibleWhen: [{
          conditionType: 'equals',
          valueName: 'location',
          testValue: EXPERIMENT_LOCATIONS.CONTAINER
        }]
      }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.randomizationMethod,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    isRequired: false,
    optionValuesReadOnly: true,
    title,
    description,
    schemaEntry
  }

export type RandomizationMethod = typeof RANDOMIZATION_METHODS[keyof typeof RANDOMIZATION_METHODS]

export {
  fieldDetails,
  RANDOMIZATION_METHODS
}
