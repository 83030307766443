import type { BodyElement } from '~/form-brain2'
import type { ColorClass, FieldName } from '~/types/utilities'
import type { MascotVariant } from '~/components/molecules/PageHint'
import type { AnyPlainOrInputElement, BlurbElement, IconName } from './plainElementTypes'
import type { SpecialContentId, SpecialLayoutType } from './questionnaireTypes'

export type SectionElementVariety = typeof SectionElementVarieties[number]

interface TerminalPageElement extends BodyElement {
  body: AnyPlainOrInputElement[]
  pageHint?: string
  mascotVariant?: MascotVariant
}

// Paged Sections

export const PagedSectionElementVarieties = ['SECTION', 'OPENER_SECTION', 'CLOSER_SECTION'] as const
export type PagedSectionElementVariety = typeof PagedSectionElementVarieties[number]

export type AnyListSectionElement = (
  // ListSectionElement |
  RepeatingSectionElement |
  SpecialListSectionElement |
  OpeningSectionElement |
  SpecialOpeningSectionElement |
  ClosingSectionElement |
  SpecialClosingSectionElement
)
export type AnySectionElement = (
  PagedSectionElement |
  PageElement |
  CheckpointElement |
  AnyListSectionElement
)
export type AnyBodyElement = AnyPlainOrInputElement | AnySectionElement

export interface PagedSectionElement extends BodyElement {
  elementVariety: PagedSectionElementVariety
  pages: PageElement[]
  title?: string // This will show on every page if more than one section exist?
  subtitle?: string
  iconColor?: ColorClass
  iconContent?: IconName | string
}

export interface PageElement extends TerminalPageElement {
  elementVariety: 'PAGE'
  specialContentArea?: {
    specialContentId: SpecialContentId
  } | null
  heading?: string | BlurbElement | null
  _headingType?: string /** System only, not persisted */
  // summarizeWithOptionToEdit?: boolean /** Currently used only by Benchmarking */
  useCheckpoint?: boolean
  checkpointButtonLabel?: string
}

export interface CheckpointElement extends PageElement {
  useCheckpoint: true
  checkpointButtonLabel: string
  body: BlurbElement[]
}

// LIST SECTIONS

export const ListSectionElementVarieties = ['LIST_OPENER_SECTION', 'LIST_ENTRY_SECTION', 'LIST_CLOSER_SECTION'] as const
export type ListSectionElementVariety = typeof ListSectionElementVarieties[number]

/** TODO typing needs to be updated to accomodate special opener and closer sections */
/** Also ideally, invert typing so that ListSectionElement is a union of the three/four variations */
export interface ListSectionElement extends TerminalPageElement {
  elementVariety: ListSectionElementVariety
}

export interface RepeatingSectionElement extends ListSectionElement {
  elementVariety: 'LIST_ENTRY_SECTION'
  entryNameSingular: string
  entryNamePlural: string
  entryLabelField?: FieldName
  sortByField?: FieldName
  sortOrder?: 'numerically' | 'alphabetically'
  minimumLength?: number
  maximumLength?: number
  offerPrintableForm?: boolean
  checkList?: boolean
  deleteButtonLabel?: string
  carryOverFields?: FieldName[]
}

export const SpecialSectionIds = ['PLAN_ACTIVITY', 'PLAN_MEASUREMENT', 'MATERIAL', 'SET_UP_STEP', 'MEASUREMENT_RECORD', 'ACTIVITY_RECORD', 'OBSERVATION_RECORD', 'SUMMARY_STATS', /* 'DATA_VISUALIZATION', */ 'STATS_TEST'] as const
export type SpecialSectionId = typeof SpecialSectionIds[number]

/** TODO typing needs to be updated to accomodate special opener and closer sections */
// export interface SpecialListSectionElement {
export interface SpecialListSectionElement extends RepeatingSectionElement, SpecialListSectionAttributes { }

export interface SpecialListSectionAttributes {
  specialSectionId: SpecialSectionId
  whatLabel?: string
  whenLabel?: string
  detailsLabel?: string
  subjectLabel?: string
  measurementLabel?: string
  measurementEntryTitleSegments?: Array<'date' | 'subject' | 'treatment' | 'measurement'>
  seriesLabel?: string
  xAxisRangeLabel?: string
  yAxisRangeLabel?: string
  tTestLabel?: string
  tTestAlpha?: number
  anovaLabel?: string
  anovaAlpha?: number
  entryIconContent?: string | IconName
  includedSummaryStats?: SummaryStatName[]
  permitOutlierRemoval?: boolean
  includedTestTypes?: StatsTestType[]
  /** Not used this version */
  // seriesAggregationTypes?: Array<'total' | 'aggregate total' | 'average'>
  // seriesGroupTypes?: Array<'treatment' | 'subject' | 'date'>
}

export type SummaryStatName = 'range' | 'mean' | 'SD'

export const StatsTestTypes = ['t-test', 'ANOVA'] as const
export type StatsTestType = typeof StatsTestTypes[number]

export interface PlainOpeningSectionElement extends ListSectionElement {
  elementVariety: 'LIST_OPENER_SECTION'
  title: string
}

export interface SpecialOpeningSectionElement extends PlainOpeningSectionElement, SpecialListSectionAttributes {}

export type OpeningSectionElement = PlainOpeningSectionElement | SpecialOpeningSectionElement

export interface PlainClosingSectionElement extends ListSectionElement {
  elementVariety: 'LIST_CLOSER_SECTION'
  title: string
}

export interface SpecialClosingSectionElement extends PlainClosingSectionElement, SpecialListSectionAttributes {}

export type ClosingSectionElement = PlainClosingSectionElement | SpecialClosingSectionElement

interface SpecialSectionDetails {
  specialSectionId: SpecialSectionId
  adderLabel: string
}
interface SpecialLayoutOptions {
  LIST_ENTRY_SECTION?: SpecialSectionDetails[]
  LIST_OPENER_SECTION?: SpecialSectionDetails
  LIST_CLOSER_SECTION?: SpecialSectionDetails
}

export const SpecialSectionIdsBySpecialLayoutType: Record<SpecialLayoutType, SpecialLayoutOptions> = {
  SET_UP: {
    LIST_ENTRY_SECTION: [{
      specialSectionId: 'SET_UP_STEP',
      adderLabel: 'Set-up Step'
    }]
  },
  MATERIALS: {
    LIST_ENTRY_SECTION: [{
      specialSectionId: 'MATERIAL',
      adderLabel: 'Checklist Item'
    }]
  },
  SCHEDULE: {
    LIST_ENTRY_SECTION: [{
      specialSectionId: 'PLAN_ACTIVITY',
      adderLabel: 'Scheduled Activity'
    }, {
      specialSectionId: 'PLAN_MEASUREMENT',
      adderLabel: 'Scheduled Measurements'
    }]
  },
  DATA: {
    LIST_ENTRY_SECTION: [{
      specialSectionId: 'MEASUREMENT_RECORD',
      adderLabel: 'Measurement Log'
    }, {
      specialSectionId: 'ACTIVITY_RECORD',
      adderLabel: 'Activity Log'
    }, {
      specialSectionId: 'OBSERVATION_RECORD',
      adderLabel: 'Observation Log'
    }]
  },
  ANALYSIS: {
    LIST_OPENER_SECTION: {
      specialSectionId: 'SUMMARY_STATS',
      adderLabel: 'Summary Statistics'
    },
    LIST_ENTRY_SECTION: [{
      specialSectionId: 'STATS_TEST',
      adderLabel: 'Statistical Tests'
    }/*, 'DATA_VISUALIZATION' */]
  }
}

export const SectionElementVarieties = [...ListSectionElementVarieties, ...PagedSectionElementVarieties] as const
