import { cloneDeep, get } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import { baseUrl } from '~/services/api'
import { entitiesReceived } from '~/features/entity/entitySlice'
import useSession from './useSession'

import type { AnyEntity, EntityType } from '~/features/entity/entitySlice'
import type { EntityErrors, EntityObject, ResultObject } from '~/form-brain2'
import type { APIEntity } from '~/../cypress/support/types'

interface UseAddEntity {
  addEntity: ({ type, values }: { type: EntityType, values: Partial<AnyEntity> }) => Promise<ResultObject>
  isAdding?: boolean
  isSuccess?: boolean
  errors?: EntityErrors
}

interface UseAddEntityState {
  isAdding?: boolean
  isSuccess?: boolean
  errors?: EntityErrors
}

const
  apiPathLookup: Record<EntityType, string> = {
    user: 'users',
    templateSet: 'template_sets',
    template: 'templates',
    experiment: 'experiments',
    learner: 'learners',
    worksheet: 'worksheets',
    worksheetResponse: 'worksheet_responses'
  },
  useAddEntity = (): UseAddEntity => {
    const
      dispatch = useDispatch(),
      { activeToken, makeRequestWithSession } = useSession(),
      [state, setState] = React.useState<UseAddEntityState>({}),
      { isAdding, isSuccess, errors } = state,
      addEntity: UseAddEntity['addEntity'] = ({ type, values }) => {
        setState({ isAdding: true })
        const
          body = { [type]: values },
          path = apiPathLookup[type]

        return makeRequestWithSession({
          method: 'post',
          url: `${baseUrl}${path}`,
          headers: {
            Authorization: `Bearer ${activeToken as string}`
          },
          data: body
        })
          .then((params) => {
            const entities = get(params, 'data.data') as Array<APIEntity<AnyEntity>>

            dispatch(entitiesReceived(entities))
            setState({ isSuccess: true })

            const ret = cloneDeep(params)

            ret.payload = entities[0]?.attributes as unknown as EntityObject

            return ret
          })
          .catch((params) => {
            setState({ errors: params.errors })
            return Promise.reject(params)
          })
      }

    return {
      isAdding,
      isSuccess,
      errors,
      addEntity
    }
  }

export { apiPathLookup }
export default useAddEntity
