import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Irrigation method',
  description = 'A label for the method or delivery system that will be used for irrigation. When this is on its own, it will be applied to all subjects. When part of a treatment, it will be applied to subjects receiving that treatment.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.irrigationMethodName,
    fieldType: 'STRING',
    // visibleWhen: ivEqualityCondition(variableName, exclude),
    options: [
      { optionValue: 'hose' },
      { optionValue: 'watering can' },
      { optionValue: 'overhead sprinkler' },
      { optionValue: 'drip irrigation' }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.irrigationMethodName,
    variableGroup: SystemFieldGroups.CONTROL_VARIABLES,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
