import { keyBy, values } from 'lodash'
import { SystemFieldNames, SystemFieldGroups, INDEPENDENT_VARIABLES } from './systemFieldConstants'
import { fieldDetails as customVariableTreatmentLabel } from './customVariableTreatmentLabel'

import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

export interface CustomVariableTreatmentFormat {
  customVariableTreatmentLabel: string
}

const
  schemaEntry: QField = {
    fieldName: SystemFieldNames.customVariableControlGroup,
    fieldType: 'SCOPE',
    visibleWhen: [{
      valueName: SystemFieldNames.independentVariable,
      conditionType: 'noneOf',
      testValue: values(INDEPENDENT_VARIABLES)
    }],
    format: keyBy([
      customVariableTreatmentLabel.schemaEntry
    ], 'fieldName')
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.customVariableControlGroup,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    shouldPrecede: [SystemFieldNames.customVariableTreatmentGroups],
    isRequired: false,
    title: 'Control group for all independent variables not listed',
    description: 'An optional baseline treatment to compare other treatments to (available when independent variable is not one of the built-in options)',
    schemaEntry
  }

export {
  fieldDetails
}
