import { sortBy, values } from 'lodash'
import useAppSelector from './useAppSelector'
import { templateSetFormBody, templateSetSchema } from '~/features/template/formContent'
import { selectTemplateSets } from '~/features/entity/selectors'
import type { EntitySchema, FormBody } from '~/form-brain2/types'

const
  useTemplateSetFormBody = (): {
    formBody: FormBody
    schema: EntitySchema
  } => {
    const
      templateSetsById = useAppSelector(selectTemplateSets),
      sortedTemplateSets = sortBy(values(templateSetsById), ['systemTemplate', 'title']),
      formBody = templateSetFormBody.map(el => {
        if ('fieldName' in el && el.fieldName === 'basedOnId') {
          return {
            ...el,
            blankOption: 'Start from scratch',
            noSort: true,
            list: sortedTemplateSets.map(o => ({
              optionValue: o.id,
              optionLabel: o.systemTemplate ? `${o.title} (system)` : o.title
            }))
          }
        } else {
          return el
        }
      })

    return {
      formBody,
      schema: templateSetSchema
    }
  }

export default useTemplateSetFormBody
