import * as React from 'react'
import styled from 'styled-components'

export type AlertTextColor = 'accent' | 'understate' | 'caution'

interface AlertTextProps {
  className?: string
  children?: React.ReactNode
  color: AlertTextColor
}

interface AlertTextOuterProps {
  $textColor: AlertTextColor
}

const
  textColorLookup: Record<AlertTextColor, ('bodyAccentText' | 'bodyUnderstateText' | 'cautionStandaloneText')> = {
    accent: 'bodyAccentText',
    understate: 'bodyUnderstateText',
    caution: 'cautionStandaloneText'
  },
  AlertTextOuter = styled.div<AlertTextOuterProps>`
    color: ${p => p.theme.colors[textColorLookup[p.$textColor]]};
    ${p => p.theme.accentFont}
    font-size: 1.345rem; // rem math: 57.3px / 2 / 15px
    text-align: center;
  `,
  AlertText: React.FC<AlertTextProps> = ({ className, children, color }) => {
    return <AlertTextOuter data-cy="alert-text" $textColor={color} className={className}>
      {children}
    </AlertTextOuter>
  }

export default AlertText
