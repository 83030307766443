import { jStat } from 'jstat'
import { getValueAcrossEntities } from '../calculatedValues'
import { getGroupedValues } from './getAggregatedValues'
import getDataPoints from './getDataPoints'

import type { EntityObject } from '~/form-brain2'
import type { Experiment } from '../types'
import { sum } from 'lodash'

export interface ANOVAValues {
  pValue: number
  fValue: number
  dfb: number
  dfw: number
}

interface Params {
  experiment: Experiment
  otherEntities: EntityObject[]
}

const
  calculateANOVAValues = ({ experiment, otherEntities }: Params): ANOVAValues => {
    const
      { dataPoints, subjectsById } = getDataPoints(otherEntities, experiment),
      outlierSubjectIds = (getValueAcrossEntities('outlierSubjectIds', otherEntities, true) as string[] | undefined) ?? [],
      { valueSetsByTreatment } = getGroupedValues(dataPoints, experiment, otherEntities, subjectsById, outlierSubjectIds),
      allValueSets = Object.values(valueSetsByTreatment).map(set => set.map(o => o.value)),
      fValue = jStat.anovafscore(allValueSets),
      dfb = allValueSets.length - 1,
      dfw = sum(allValueSets.map(o => o.length)) - allValueSets.length

    if (allValueSets.length < 2) {
      throw new Error('Cannot run test because there is nothing to compare. This test requires at least two groups with at least one measurement each.')
    }

    if (dfw < 1) {
      throw new Error(`Cannot run test because there are too few measurements (${sum(allValueSets.map(o => o.length))} aggregated measurements, degrees of freedom within: ${dfw})`)
    }

    if (isNaN(fValue)) {
      throw new Error(`Cannot run test, resulting f value is not a number (${allValueSets.length} groups with data, ${sum(allValueSets.map(o => o.length))} aggregated measurements, degrees of freedom within: ${dfw}, degrees of freedom between: ${dfb})`)
    }

    return {
      fValue,
      dfb,
      dfw,
      pValue: jStat.ftest(fValue, dfb, dfw)
    }
  }

export default calculateANOVAValues
