import { times } from 'lodash'

export const
  pointOnCircleFromAngle = ({ degreeAngle, cx, cy, r }: { degreeAngle: number, cx: number, cy: number, r: number }): [number, number] => {
    const
      radianAngle = (degreeAngle - 90) * Math.PI / 180.0,
      x = cx + (r * Math.cos(radianAngle)),
      y = cy + (r * Math.sin(radianAngle))

    return [x, y]
  },
  toPlaneSkewingFactor = 0.1,
  skewToPlane = (angle: number): number => {
    return (angle > 30 && angle < 85)
      ? ((85 - angle) * toPlaneSkewingFactor) + angle
      : (angle > 210 && angle < 265)
          ? ((265 - angle) * toPlaneSkewingFactor) + angle
          : (angle > 95 && angle < 150)
              ? angle - ((angle - 95) * toPlaneSkewingFactor)
              : (angle > 275 && angle < 330)
                  ? angle - ((angle - 275) * toPlaneSkewingFactor)
                  : angle
  },
  degreeStopsForStepCount = (stepCount: number): number[] => {
    const
      oddNumberOfSteps = !!(stepCount % 2),
      stepDegreeDelta = 1 / stepCount * 360,
      ret: number[] = []

    if (!oddNumberOfSteps) {
      times(stepCount, (index) => {
        ret[index] = stepDegreeDelta * index
      })

      return ret.map(skewToPlane)
    }

    const
      // Add space at the bottom to prevent icons from overlapping.
      // In order to make room, subtract a slice of that space
      // from all the other intervals.
      extraSpaceAtBase = stepDegreeDelta / 2,
      spaceToDistribute = -extraSpaceAtBase,
      adjustmentForEach = spaceToDistribute / (stepCount - 1),
      outerDelta = stepDegreeDelta + adjustmentForEach,
      middleDelta = stepDegreeDelta + extraSpaceAtBase,
      cutoffIndex = (stepCount - 1) / 2

    times(stepCount, (index) => {
      if (index <= cutoffIndex) {
        ret[index] = index * outerDelta
      } else {
        ret[index] = (index - 1) * outerDelta + middleDelta
      }
    })

    return ret.map(skewToPlane)
  }
