import { capitalize, floor } from 'lodash'
import { DateTime } from 'luxon'
import { type InputElementVariety, type CollectionElement, type InputElement, type ScopeElement } from '~/features'
import { type AttrVal, type EntityErrors } from '~/form-brain2'
import { isEmptyValue, isNumeric } from '~/utils/testers'

interface FloatStringOptions {
  places?: number | null
  force?: boolean | null
  placeholder?: string | null
  NaNPlaceholder?: string | null
  humanReadable?: string | null
}

export const
  INPUT_DATE_FORMAT = 'yyyy-MM-dd',
  CORE_DATE_FORMATS = ['MM/dd/yyyy', INPUT_DATE_FORMAT, 'yyyy/MM/dd', 'dd/MM/yyyy', 'LLLL dd, yyyy', 'LLL dd, yyyy'],
  DATE_FORMATS = [
    ...CORE_DATE_FORMATS,
    ...CORE_DATE_FORMATS.map(s => s.replace(/dd/, 'd')),
    ...CORE_DATE_FORMATS.map(s => s.replace(/MM/, 'M')),
    ...CORE_DATE_FORMATS.map(s => s.replace(/dd/, 'd').replace(/MM/, 'M'))
  ].flatMap(s => s.includes('/') ? [s, s.replace('/', '-')] : s),
  parseDate = (_dateString?: string | null): DateTime => {
    const dateString = _dateString ?? ''
    let result = DATE_FORMATS.map(f => DateTime.fromFormat(dateString, f)).find(d => d.isValid)

    if (!result) { result = DateTime.fromISO(dateString) }
    if (!result.isValid) { result = DateTime.fromHTTP(dateString) }
    if (!result.isValid) { result = DateTime.fromRFC2822(dateString) }

    return result
  },
  parseNumIfPossible = (rawValue?: string | number | null): number | undefined => {
    if (isNumeric(rawValue)) {
      return parseFloat(rawValue as string)
    }
  },
  formatDate = (d: string | DateTime, format = 'LLLL dd, yyyy'): string => {
    const dt = typeof d === 'string'
      ? parseDate(d)
      : d

    return dt.isValid
      ? dt.toFormat(format)
      : typeof d === 'string'
        ? d
        : 'invalid'
  },
  formatDateForInput = (d: string | DateTime): string => {
    return formatDate(d, INPUT_DATE_FORMAT)
  },
  floatString = (rawValue?: string | number | null, options: FloatStringOptions = {}): string | null => {
    const
      places = isEmptyValue(options.places) ? 2 : (options.places as number),
      force = options.force,
      value = typeof rawValue === 'string' && isNumeric(rawValue)
        ? +rawValue
        : rawValue

    if (isEmptyValue(value)) {
      return (
        options.placeholder ?? ''
      )
    } else if (typeof value === 'string') {
      return (
        value
      )
    } else if (isNaN(value as number)) {
      return (
        options.NaNPlaceholder ?? 'N/A'
      )
    } else if (force) {
      return (
        options.humanReadable
          ? (value as number).toLocaleString('en-US', { minimumFractionDigits: places, maximumFractionDigits: places })
          : (value as number).toFixed(places)
      )
    } else {
      return (
        options.humanReadable
          ? (value as number).toLocaleString('en-US', { maximumFractionDigits: places })
          : parseFloat((value as number).toFixed(places)).toString()
      )
    }
  },
  formatValueForInput = (currentValue: AttrVal, elementVariety: InputElementVariety): string => {
    return typeof currentValue === 'number' || typeof currentValue === 'boolean'
      ? currentValue.toString()
      : elementVariety === 'DATE_INPUT' && currentValue
        ? formatDateForInput(currentValue)
        : currentValue ?? ''
  },
  valueStringForError = (val: unknown): string => {
    return typeof val === 'string'
      ? val
      : val == null
        ? `${val === null ? 'null' : 'undefined'}`
        : isNumeric(val) || typeof val === 'boolean'
          ? (val as string | number | boolean).toString()
          : Array.isArray(val)
            ? `Array<${valueStringForError(val[0])}>`
            : val?.constructor?.name ?? 'unknown'
  },
  inputIdFromBodyElement = (el: InputElement | ScopeElement | CollectionElement, pathToAttr?: string): string => {
    return `${el.fieldName}--${el.id}${pathToAttr && !isEmptyValue(pathToAttr) ? `--${pathToAttr.replace(/(\[|\])/g, '')}` : ''}`
  },
  getBaseErrorString = (errors: EntityErrors | undefined, fallback: string): string => {
    const baseError = (errors?._base ?? errors?.base) as string[] | undefined

    return baseError
      ? baseError.join(', ')
      : fallback
  },
  symbolSafeStartCase = (str: string): string => str.replace(/\w+/g, capitalize),
  truncateFromCenter = (str: string, totalLength: number): string => {
    const
      lengthAfterEllipses = totalLength - 3,
      partLength = floor(lengthAfterEllipses / 2)

    return str.length < totalLength
      ? str
      : `${str.slice(0, partLength + 1)}...${str.slice(-partLength)}`
  }
