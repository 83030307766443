import { forEach, fromPairs, last, lowerCase, merge, sortBy, upperFirst } from 'lodash'
import { comparableInputVarieties, InputLikeVarieties } from '~/features/questionnaire/types'
import { interpolateString } from '~/features/experiment/calculatedValues'
import { getDominantResponseStatus } from '~/features/worksheetResponse/helpers'

import type { ButtonIcon } from '~/components/atoms/Button'
import type { Template } from '~/features/template/types'
import type { Experiment, DynamicLabels } from '~/features/experiment/types'
import type { WorksheetResponse, ListEntry } from '~/features/worksheetResponse/types'
import type { WorksheetResponseStatus } from '~/features/worksheetResponse/helpers'
import type { OpeningSectionElement, ClosingSectionElement, RepeatingSectionElement, ListSectionElement, AnyListSectionElement } from '~/features/questionnaire/types/sectionElementTypes'
import type { AnyPlainOrInputElement } from '~/features/questionnaire/types/plainElementTypes'
import type { InputElement } from '~/features/questionnaire/types/inputElementTypes'
import type { ListOption, SpecialListSectionElement, SpecialSectionId } from '~/features/questionnaire/types'
import type {
  ListWorksheet,
  PagedWorksheet,
  Worksheet
} from './types'
import type { EntityObject } from '~/form-brain2'
import type { FieldName } from '~/types/utilities'

export type WorksheetStatusForStudent = 'closed' | 'open' | 'approved' | 'submitted'

interface NavDetailsParams {
  worksheet: PagedWorksheet
  sectionIndex: number
  pageIndex: number
}

export interface PageNavDetails {
  backNav: DirectionDetail
  forwardNav: DirectionDetail
  submitOnForward: boolean
  showSkipSubmissionMessage: boolean
  /** This will be for the current section if on final page */
  nextSectionIndex: number
  /** This will be for the current page if on final page */
  nextPageIndex: number
}

interface DirectionDetail {
  to: string
  labelKey: keyof DynamicLabels
  withIcon?: ButtonIcon
}

export const
  getSectionList = (worksheet: ListWorksheet): AnyListSectionElement[] => {
    return [
      worksheet.openingSection,
      ...worksheet.repeatingSections || [],
      worksheet.closingSection
    ].filter(o => o) as AnyListSectionElement[]
  },
  getSectionsById = (worksheet: ListWorksheet): Record<string, ListSectionElement> => {
    const sections = getSectionList(worksheet)

    return fromPairs(sections.map(o => [o.id, o]))
  },
  getFirstInput = (elements: AnyPlainOrInputElement[]): InputElement | undefined => {
    return elements.find(o => [...comparableInputVarieties, ...InputLikeVarieties].includes(o.elementVariety)) as InputElement | undefined
  },
  getInferredPageLabel = (elements: AnyPlainOrInputElement[], fallback: string, otherEntities: EntityObject[]): [string, string | undefined] => {
    let ret, raw
    const
      firstInput = getFirstInput(elements),
      firstElement = elements[0]

    if (firstInput?.label) { ret = firstInput.label as string | undefined }
    if (!ret && 'label' in firstElement) { ret = firstElement.label }

    if (ret) {
      raw = ret
      ret = interpolateString(ret, otherEntities)
    }

    return [ret ?? fallback, raw]
  },
  getHeadingForSection = ({ section, index, entry }: { section?: RepeatingSectionElement, index: number, entry?: ListEntry }): string => {
    const
      namingKeysBySpecialSectionId: Record<SpecialSectionId, FieldName> = {
        PLAN_ACTIVITY: 'description',
        PLAN_MEASUREMENT: 'description',
        MATERIAL: 'description',
        SET_UP_STEP: 'description',
        OBSERVATION_RECORD: 'details',
        ACTIVITY_RECORD: 'description',
        /* The following get their titles elsewhere */
        MEASUREMENT_RECORD: '',
        SUMMARY_STATS: '',
        STATS_TEST: ''
        // DATA_VISUALIZATION: 'visualization_type'
      },
      namingKey = section?.entryLabelField,
      indexLabel = (index + 1).toString(),
      heading = (
        (namingKey
          ? entry?.[namingKey] as string | undefined
          : null) ??
        ((section as SpecialListSectionElement | undefined)?.specialSectionId
          ? entry?.[namingKeysBySpecialSectionId[(section as SpecialListSectionElement).specialSectionId]] as string | undefined
          : null) ??
        (section
          ? `${section.entryNameSingular} ${indexLabel}`
          : 'Entry')
      )

    return upperFirst(heading)
  },
  getPathForPage = (worksheetId: string, sectionI: number, pageI: number): string => `/project-step/${worksheetId}/section/${sectionI}/page/${pageI}`,
  getNavDetailsForPage = ({ worksheet, sectionIndex, pageIndex }: NavDetailsParams): PageNavDetails => {
    const
      includeReviewStep = worksheet.includeReviewStep,
      skipSubmission = worksheet.skipSubmission,
      section = worksheet.sections[sectionIndex],
      prevSection = worksheet.sections[sectionIndex - 1],
      isFirstSection = sectionIndex === 0,
      isLastSection = sectionIndex === worksheet.sections.length - 1,
      isFirstPage = pageIndex === 0,
      isLastPage = pageIndex === section.pages.length - 1,
      isFinalStep = isLastSection && isLastPage,
      backNav: DirectionDetail = isFinalStep && !includeReviewStep && !skipSubmission
        ? { to: '/project-home', labelKey: 'waitToSubmitStep', withIcon: undefined }
        : isFirstSection && isFirstPage
          ? { to: '/project-home', labelKey: 'backToHome' }
          : isFirstPage
            ? { to: getPathForPage(worksheet.id, sectionIndex - 1, prevSection.pages.length - 1), labelKey: 'pageBack' }
            : { to: getPathForPage(worksheet.id, sectionIndex, pageIndex - 1), labelKey: 'pageBack' },
      nextPageIndex = isFinalStep
        ? pageIndex
        : isLastPage
          ? 0
          : pageIndex + 1,
      nextSectionIndex = isLastPage && !isFinalStep
        ? sectionIndex + 1
        : sectionIndex,
      forwardNav: DirectionDetail = isFinalStep && !includeReviewStep && !skipSubmission
        ? { to: `/project-step/${worksheet.id}/response`, labelKey: 'submitStep' }
        : isFinalStep && !includeReviewStep // and DO skip submission
          ? { to: '/project-home', labelKey: 'saveAndReturn', withIcon: 'checkmark' }
          : isLastPage && isLastSection
            ? { to: `/project-step/${worksheet.id}/review`, withIcon: 'checkmark', labelKey: 'pageReview' }
            : { to: getPathForPage(worksheet.id, nextSectionIndex, nextPageIndex), labelKey: isLastPage ? 'sectionNext' : 'pageNext' }

    return {
      backNav,
      forwardNav,
      submitOnForward: isFinalStep && !includeReviewStep && !skipSubmission,
      showSkipSubmissionMessage: isFinalStep && !!skipSubmission && !includeReviewStep,
      nextPageIndex,
      nextSectionIndex
    }
  },
  getAdderStringFromRepeatingSections = (sections: RepeatingSectionElement[]): string => {
    const
      labels = sections.map(s => lowerCase(s.entryNameSingular)),
      joinedLabel = labels.length <= 1
        ? labels[0]
        : `${labels.slice(0, -1).join(', ')} or ${labels[labels.length - 1]}`

    return `Add a new ${joinedLabel}!`
  },
  getSortedWorksheetsForHubStep = <T extends (Worksheet | Template)>(hubId: string, allWorksheets: T[]): T[] => {
    return sortBy(allWorksheets.filter(
      o => (('parentWorksheetId' in o ? o.parentWorksheetId : 'parentTemplateId' in o ? o.parentTemplateId : undefined)) === hubId
    ), 'rank')
  },
  getSortedTopLevelWorksheets = <T extends (Worksheet | Template)>(allWorksheets: T[]): T[] => {
    return sortBy(allWorksheets.filter(
      o => !('parentWorksheetId' in o && o.parentWorksheetId) && !('parentTemplateId' in o && o.parentTemplateId)
    ), 'rank')
  },
  getDoesWorksheetRequireResponse = (worksheet?: Worksheet): boolean => {
    return !(worksheet?.format === 'HUB' || worksheet?.format === 'DOWNLOADS' || worksheet?.skipSubmission)
  },
  getResponseStatusForWorksheet = (
    worksheet: Worksheet,
    allWorksheets: Worksheet[],
    allResponses: WorksheetResponse[]
  ): WorksheetResponseStatus | undefined => {
    if (worksheet.format === 'HUB') {
      const
        subWorksheets = getSortedWorksheetsForHubStep(worksheet.id, allWorksheets),
        subWorksheetIds = subWorksheets.map(o => o.id),
        responses = allResponses.filter(r => subWorksheetIds.includes(r.worksheetId))

      return getDominantResponseStatus(responses, subWorksheets.some(w => !worksheet.skipSubmission && !responses.some(r => r.worksheetId === w.id)))
    }

    const response = allResponses.find(r => r.worksheetId === worksheet.id)

    return response
      ? getDominantResponseStatus([response])
      : undefined
  },
  _getWorksheetAvailabilities = (
    relevantWorksheets: Worksheet[],
    allWorksheets: Worksheet[],
    responseStatusByWorksheet: Record<string, WorksheetResponseStatus | undefined>,
    progressBasis: Experiment['progressBasis']
  ): Record<string, 'open' | 'closed'> => {
    const worksheetAvailabilityById: Record<string, 'open' | 'closed'> = {}

    forEach(relevantWorksheets, (worksheet, i) => {
      const
        prevWorksheet = relevantWorksheets[i - 1] && relevantWorksheets[i - 1].format === 'HUB'
          ? last(getSortedWorksheetsForHubStep(relevantWorksheets[i - 1].id, allWorksheets))
          : relevantWorksheets[i - 1],
        prevStatus = prevWorksheet ? worksheetAvailabilityById[prevWorksheet.id] : undefined,
        prevResponseStatus = prevWorksheet ? responseStatusByWorksheet[prevWorksheet.id] : undefined

      if (!worksheet.releasedAt) { // This worksheet is closed
        worksheetAvailabilityById[worksheet.id] = 'closed'
      } else if (!prevWorksheet) { // Is the first worksheet
        worksheetAvailabilityById[worksheet.id] = 'open'
      } else if (prevStatus === 'closed') { // The previous worksheet is closed (and is not the first worksheet)
        worksheetAvailabilityById[worksheet.id] = 'closed'
      } else if (!prevResponseStatus) { // No matter what, you can't start the next step until your start the previous one
        worksheetAvailabilityById[worksheet.id] = 'closed'
      } else if (progressBasis === 'manual' || prevWorksheet.skipSubmission) {
        worksheetAvailabilityById[worksheet.id] = 'open'
      } else if (progressBasis === 'submission' && (['approved', 'submitted', 'studentRequestsReopen'] as WorksheetResponseStatus[]).includes(prevResponseStatus)) {
        worksheetAvailabilityById[worksheet.id] = 'open'
      } else {
        worksheetAvailabilityById[worksheet.id] = prevResponseStatus === 'approved' ? 'open' : 'closed'
      }

      if (worksheet.format === 'HUB') {
        const childWorksheets = getSortedWorksheetsForHubStep(worksheet.id, allWorksheets)

        if (worksheetAvailabilityById[worksheet.id] === 'closed') {
          merge(
            worksheetAvailabilityById,
            fromPairs(childWorksheets.map(o => [o.id, 'closed']))
          )
        } else {
          merge(
            worksheetAvailabilityById,
            _getWorksheetAvailabilities(childWorksheets, allWorksheets, responseStatusByWorksheet, progressBasis)
          )
        }
      }
    })

    return worksheetAvailabilityById
  },
  getWorksheetAvailabilities = (
    allWorksheets: Worksheet[],
    allResponses: WorksheetResponse[],
    progressBasis: Experiment['progressBasis']
  ): [Record<string, 'open' | 'closed'>, Record<string, WorksheetResponseStatus | undefined>] => {
    const
      responseStatusByWorksheet = fromPairs(allWorksheets.map(worksheet => {
        return [worksheet.id, getResponseStatusForWorksheet(worksheet, allWorksheets, allResponses)]
      })),
      sortedTopLevelWorksheets = getSortedTopLevelWorksheets(allWorksheets),
      worksheetStatusesById = _getWorksheetAvailabilities(
        sortedTopLevelWorksheets,
        allWorksheets,
        responseStatusByWorksheet,
        progressBasis
      )

    return [worksheetStatusesById, responseStatusByWorksheet]
  },
  getStudentFacingWorksheetStatuses = (
    allWorksheets: Worksheet[],
    allResponses: WorksheetResponse[],
    progressBasis: Experiment['progressBasis']
  ): Record<string, WorksheetStatusForStudent> => {
    const [worksheetStatusesById, responseStatusByWorksheetId] = getWorksheetAvailabilities(allWorksheets, allResponses, progressBasis)

    return fromPairs(allWorksheets.map(worksheet => {
      let status: WorksheetStatusForStudent
      const
        responseStatus = responseStatusByWorksheetId[worksheet.id],
        worksheetAvailability = worksheetStatusesById[worksheet.id]

      if (worksheetAvailability === 'closed') {
        status = 'closed'
      } else if (responseStatus === 'approved') {
        status = 'approved'
      } else if (responseStatus === 'submitted' || responseStatus === 'studentRequestsReopen') {
        status = 'submitted'
      } else {
        status = 'open'
      }

      return [worksheet.id, status]
    }))
  },
  getFollowingWorksheetResponseAndStatus = (
    worksheet: Worksheet,
    allWorksheets: Worksheet[],
    allResponses: WorksheetResponse[],
    progressBasis: Experiment['progressBasis']
  ): [
    WorksheetStatusForStudent | undefined,
    WorksheetResponse | undefined,
  ] => {
    const
      sortedWorksheetSet = worksheet.parentWorksheetId
        ? getSortedWorksheetsForHubStep(worksheet.parentWorksheetId, allWorksheets)
        : getSortedTopLevelWorksheets(allWorksheets),
      thisWorksheetIndex = sortedWorksheetSet.indexOf(worksheet),
      nextWorksheet = thisWorksheetIndex !== sortedWorksheetSet.length - 1
        ? sortedWorksheetSet[thisWorksheetIndex + 1]
        : undefined

    if (!nextWorksheet) {
      const parentWorksheet = allWorksheets.find(o => o.id === worksheet.parentWorksheetId)

      return parentWorksheet
        ? getFollowingWorksheetResponseAndStatus(parentWorksheet, allWorksheets, allResponses, progressBasis)
        : [undefined, undefined]
    }

    const
      stepStatusesById = getStudentFacingWorksheetStatuses(allWorksheets, allResponses, progressBasis),
      relevantResponse = allResponses.find(o => o.worksheetId === nextWorksheet.id)

    return [
      stepStatusesById[nextWorksheet.id],
      relevantResponse
    ]
  },
  replaceSectionInWorksheet = (
    worksheet: ListWorksheet,
    section: ListSectionElement,
    newSection: ListSectionElement | undefined
  ): ListWorksheet => {
    return {
      ...worksheet,
      openingSection: section.elementVariety === 'LIST_OPENER_SECTION' ? ((newSection ?? null) as unknown as OpeningSectionElement | undefined) : worksheet.openingSection,
      closingSection: section.elementVariety === 'LIST_CLOSER_SECTION' ? ((newSection ?? null) as unknown as ClosingSectionElement | undefined) : worksheet.closingSection,
      repeatingSections: section.elementVariety === 'LIST_ENTRY_SECTION'
        ? worksheet.repeatingSections.reduce<RepeatingSectionElement[]>((memo, o) => {
          if (o.id !== section.id) {
            return [...memo, o]
          } else if (newSection) {
            return [...memo, newSection as unknown as RepeatingSectionElement]
          } else {
            return memo
          }
        }, [])
        : worksheet.repeatingSections
    }
  },
  getWalkthroughSectionIdOptions = (sections: AnyListSectionElement[]): ListOption[] => {
    return sections.map(section => ({
      optionValue: section.id,
      optionLabel: section.elementVariety === 'LIST_ENTRY_SECTION'
        ? `List Entry: ${section.entryNameSingular}`
        : section.elementVariety === 'LIST_OPENER_SECTION'
          ? `Opening: ${section.title}`
          : `Closing: ${section.title}`
    }))
  }
  // getReopenableStep = (worksheets, responses) => {
  //   const
  //     stepStatusesByWorksheetId = getStudentFacingWorksheetStatuses(worksheets, responses, experiment.progressBasis),
  //     sortedWorksheets = getSortedTopLevelWorksheets<T: Worksheet | Template>(worksheets),
  //     indexOfCurrentStep = findIndex(sortedWorksheets, worksheet),
  //     responseThatCanBeReopened = findLastIndex(sortedWorksheets, o => stepStatusesByWorksheetId[o.id] === 'submitted')
  // }
