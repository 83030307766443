import * as React from 'react'
import styled, { css, useTheme } from 'styled-components'
import { DashedLine } from '~/components/atoms/Shapes'

interface WithUnderlineProps {
  children: React.ReactNode
  withError?: boolean
}

export const
  remBaseInputFontSize = 1,
  emBaseInputLineHeight = 1, // 1.5,
  emBaseInputVPadding = 0.55,
  emBaseInputHPadding = 0.55,
  baseStyles = css<{ $hasError?: boolean | null }>`
    width: 100%;
    flex: 0 1 0px;
    font-size: ${remBaseInputFontSize}rem;
    line-height: ${emBaseInputLineHeight}em;
    ${p => p.theme.accentFont}

    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // clear: left;

    color: ${p => p.theme.colors.bodyText};
    background: ${p => p.theme.colors.bodyBg};
    border: ${p => `${p.theme.pxBorderWidth}px solid transparent`};
    padding: ${emBaseInputVPadding}em ${emBaseInputHPadding}em;
    margin: 2px 0;  
    // margin-right: ${p => p.theme.emSmallSpacing}em;
    transition: border-color;

    &:focus {
      border-color: ${p => p.$hasError ? p.theme.colors.cautionStandaloneText : p.theme.colors.borderAccentAlt};
      outline: ${p => p.$hasError ? `1px solid ${p.theme.colors.cautionStandaloneText}` : 'none'};
    }

    ${p => p.$hasError ? `border-color: ${p.theme.colors.cautionStandaloneText};` : ''};

    &[readonly], &[disabled] {
      background: transparent;
      cursor: auto;
    }

    ::placeholder {
      color: ${p => p.theme.colors.understateMed};
    }
  `,
  BaseInput = styled.input<{ $hasError?: boolean | null }>`${baseStyles as any}`,
  LineWrapper = styled.div`
    position: relative;
  `,
  InputUnderline = styled(DashedLine)`
    position: absolute;
    font-size: ${remBaseInputFontSize}rem;
    top: ${emBaseInputLineHeight + emBaseInputVPadding + 0.55}em;
    left: 1%;
    width: 98%;
  `,
  WithUnderline: React.FC<WithUnderlineProps> = ({ children, withError }) => {
    const THEME = useTheme()

    return <LineWrapper>
      {children}
      <InputUnderline color={withError ? THEME.colors.cautionStandaloneText : THEME.colors.borderAccentAlt } />
    </LineWrapper>
  }

export default BaseInput
