import type { ArrowCurvedDirection } from '~/components/atoms/Shapes'
export type StepPointerVariant = 'NE-head' | 'NE-tail' | 'SE-tail' | 'NW-tail' | 'SW-tail' | 'SW-head'

export const
  arrowRotationLookup: Record<StepPointerVariant, string> = {
    'NE-head': 'rotate(128deg)',
    'NE-tail': 'rotate(128deg)',
    'SE-tail': 'rotate(52deg)',
    'NW-tail': 'rotate(-120deg)',
    'SW-tail': 'rotate(-52deg)',
    'SW-head': 'rotate(-52deg)'
  },
  hintTextRotationLookup: Record<StepPointerVariant, string> = {
    'NE-head': 'rotate(-10deg)',
    'NE-tail': 'rotate(22.83deg)',
    'SE-tail': 'rotate(-22.83deg)',
    'NW-tail': 'rotate(-22.83deg)',
    'SW-tail': 'rotate(22.83deg)',
    'SW-head': 'rotate(-22.83deg)'
  },
  arrowDirectionLookup: Record<StepPointerVariant, ArrowCurvedDirection> = {
    'NE-head': 'counterClockwise',
    'NE-tail': 'counterClockwise',
    'SE-tail': 'clockwise',
    'NW-tail': 'clockwise',
    'SW-tail': 'counterClockwise',
    'SW-head': 'counterClockwise'
  },
  outerDimensionsLookup: Record<StepPointerVariant, { x: number, y: number }> = {
    'NE-head': { x: 73, y: 97.5 },
    'NE-tail': { x: 99, y: 80.5 },
    'SE-tail': { x: 98.5, y: 81 },
    'NW-tail': { x: 103, y: 77 },
    'SW-tail': { x: 102.5, y: 78 },
    'SW-head': { x: 81.5, y: 102.5 }
  },
  arrowPositionLookup: Record<StepPointerVariant, { x: number, y: number }> = {
    'NE-head': { x: 22.5, y: 0 },
    'NE-tail': { x: 46, y: -10 },
    'SE-tail': { x: 45, y: 20 },
    'NW-tail': { x: 20, y: -10 },
    'SW-tail': { x: 20, y: 20 },
    'SW-head': { x: 25, y: 40 }
  },
  textPositionLookup: Record<StepPointerVariant, { x: number, y: number }> = {
    'NE-head': { x: 0, y: 52.50 },
    'NE-tail': { x: 0, y: 32.50 },
    'SE-tail': { x: 0, y: 10 },
    'NW-tail': { x: 25, y: 35 },
    'SW-tail': { x: 30, y: 20 },
    'SW-head': { x: 0, y: 10 }
  }
