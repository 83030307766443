import * as React from 'react'
import styled from 'styled-components'
import { BodyText, HeadingThree } from '~/components/atoms'

interface InfoPanelProps {
  children: React.ReactNode
  heading?: string
  className?: string
}

const
  InfoPanelOuter = styled(BodyText)`
    padding: ${p => p.theme.emBaseSpacing * 1.15}em;
    border-radius: ${p => p.theme.pxBorderRadius * 1.4}px;

    p:not(:last-child) {
      padding-bottom: 28px;
    }
    
    background: ${p => p.theme.colors.containerAccentBg};
  `,
  Heading = styled(HeadingThree)`
    margin-bottom: ${p => p.theme.emSmallSpacing * 0.75}em;
  `,
  InfoPanel: React.FC<InfoPanelProps> = ({ className, children, heading }) => {
    return (
      <InfoPanelOuter understate={true} className={className} data-cy="info-panel">
        {heading ? <Heading>{heading}</Heading> : null}
        {children}
      </InfoPanelOuter>
    )
  }

export default InfoPanel
