import * as React from 'react'
import IconText from '~/components/atoms/Icons/IconText'
import { circleBorderRatio, pxIconBaseSize, ShadowedSvg } from '~/components/atoms/Icons/iconConstants'
import type { BasicIconProps, ColorClass } from '~/types/utilities'

interface CoreProps {
  content: string | React.ComponentType<BasicIconProps>
  colorClass: ColorClass
  backgroundColor: string
  borderColor: string
  contentColor: string
}

const
  CircularIcon: React.FC<CoreProps> = ({ content, colorClass, backgroundColor, contentColor, borderColor }) => {
    const
      Content = content,
      internalIconWidth = pxIconBaseSize,
      baseFontSize = internalIconWidth * 0.8,
      circleRadius = internalIconWidth / 2,
      borderWidth = internalIconWidth * circleBorderRatio

    return <ShadowedSvg data-cy="icon-frame-circle" viewBox={`0 0 ${internalIconWidth} ${internalIconWidth}`}>
      <circle cx={circleRadius} cy={circleRadius} r={circleRadius} fill={borderColor} />
      <circle cx={circleRadius} cy={circleRadius} r={circleRadius - borderWidth} fill={backgroundColor} />
      {typeof content === 'string'
        ? <IconText
            text={content}
            className='icon-rotation-target'
            textColor={contentColor}
            pxFontSize={
              content.length < 2
                ? baseFontSize
                : baseFontSize * 0.8
            }
          />
        : <g className='icon-rotation-target'>
            <svg
              viewBox={`0 0 ${internalIconWidth} ${internalIconWidth}`}
              width="50%"
              x="25%"
              y="0">
            <Content color={contentColor} colorClass={colorClass} />
          </svg>
        </g>}
    </ShadowedSvg>
  }

export default CircularIcon
