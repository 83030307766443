import { getSemiRandomString } from '~/utils/strings'
import { EXPERIMENT_LOCATIONS, INDEPENDENT_VARIABLES, PLANTING_FORMATS, SystemFieldNames } from '~/features/experiment/systemFields'
import type { SpecialListSectionElement } from '~/features/questionnaire/types/sectionElementTypes'
import type { ListWorksheet } from '../types'

export const
  getDefaultMaterialChecklistItemSection = (sectionId?: string): SpecialListSectionElement => {
    return {
      id: sectionId ?? `materials-list-${getSemiRandomString()}`,
      elementVariety: 'LIST_ENTRY_SECTION',
      specialSectionId: 'MATERIAL',
      entryNamePlural: 'checklist items',
      entryNameSingular: 'checklist item',
      whatLabel: 'What do you need?',
      detailsLabel: 'You can add more information here, if needed',
      deleteButtonLabel: 'Delete this Item',
      pageHint: 'You can check off the items on your list as you do them!',
      mascotVariant: 'knapsack',
      checkList: true,
      body: []
    }
  },
  defaultsForMaterialsLayout = (materialsSectionId?: string): Pick<ListWorksheet, 'repeatingSections' | 'defaults'> => {
    const checklistItemSection = getDefaultMaterialChecklistItemSection(materialsSectionId)

    return {
      repeatingSections: [checklistItemSection],
      defaults: [
        /** SEEDS */
        {
          // id: 'seeds for managing individuals',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: '--calculatedSeedsNeeded-- --subject-- seeds'
          },
          visibleWhen: [{ valueName: SystemFieldNames.plantingFormat, conditionType: 'anyOf', testValue: [PLANTING_FORMATS.INDIVIDUAL_CONTAINER, PLANTING_FORMATS.INDIVIDUAL_GROUND] }]
        },
        {
          // id: 'seeds for groups of plants',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: '--calculatedSeedsNeeded-- --subject-- seeds'
          },
          visibleWhen: [
            { valueName: SystemFieldNames.plantingFormat, conditionType: 'anyOf', testValue: [PLANTING_FORMATS.GROUP_CONTAINER, PLANTING_FORMATS.GROUP_GROUND] },
            { valueName: SystemFieldNames.plantSpacingUnit, conditionType: 'matches', testValue: 'plant' }
          ]
        },
        {
          // id: 'seeds when specified seeds per group',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: '--subject-- seeds, --calculatedSeedsNeeded-- --calculatedSeedUnit--'
          },
          visibleWhen: [
            { valueName: SystemFieldNames.plantingFormat, conditionType: 'anyOf', testValue: [PLANTING_FORMATS.GROUP_CONTAINER, PLANTING_FORMATS.GROUP_GROUND] },
            { valueName: SystemFieldNames.plantSpacingUnit, conditionType: 'matches', testValue: 'seed' }
          ]
        },
        {
          // id: 'seeds for any other group configuration',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: 'Enough --subject-- seed for all --sampleSize-- of your subjects'
          },
          visibleWhen: [
            { valueName: SystemFieldNames.plantingFormat, conditionType: 'anyOf', testValue: [PLANTING_FORMATS.GROUP_CONTAINER, PLANTING_FORMATS.GROUP_GROUND] },
            { valueName: SystemFieldNames.plantSpacingUnit, conditionType: 'notMatches', testValue: 'seed' },
            { valueName: SystemFieldNames.plantSpacingUnit, conditionType: 'notMatches', testValue: 'plant' }
          ]
        },

        /** SPACE or CONTAINERS */
        {
          // id: 'space for individual in ground',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: 'Enough growing space for --sampleSize-- plants'
          },
          visibleWhen: [{ valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.INDIVIDUAL_GROUND }]
        },
        {
          // id: 'space for group in ground',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: 'Enough growing space for each --subjectGroupName--, --sampleSize-- in total'
          },
          visibleWhen: [{ valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.GROUP_GROUND }]
        },
        {
          // id: 'containers for managing individuals',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: '--sampleSize-- --containerType-- (or fewer if some plants will share)'
          },
          visibleWhen: [{ valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.INDIVIDUAL_CONTAINER }]
        },
        {
          // id: 'containers for plain groups',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: '--sampleSize-- --containerType--'
          },
          visibleWhen: [
            { valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.GROUP_CONTAINER },
            { valueName: SystemFieldNames.plantSpacingArea, conditionType: 'matches', testValue: 'container' }
          ]
        },
        {
          // id: 'containers for custom groups',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: '--sampleSize-- --containerType-- (or fewer if --containerType-- will contain more than one --subjectGroupName--)'
          },
          visibleWhen: [
            { valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.GROUP_CONTAINER },
            { valueName: SystemFieldNames.plantSpacingArea, conditionType: 'notMatches', testValue: 'container' }
          ]
        },

        /** LABELS */
        {
          // id: 'labels for containers',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: 'Labeling stakes or stickers'
          },
          visibleWhen: [{ valueName: SystemFieldNames.location, conditionType: 'equals', testValue: EXPERIMENT_LOCATIONS.CONTAINER }]
        },
        {
          // id: 'labels for in ground',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: 'Labeling stakes or flags'
          },
          visibleWhen: [{ valueName: SystemFieldNames.location, conditionType: 'equals', testValue: EXPERIMENT_LOCATIONS.GROUND }]
        },
        {
          // id: 'label marker',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: 'A waterproof pen or marker to write on the labels with'
          }
        },

        /** LAYOUT */
        {
          // id: 'layout for group in ground',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: 'Any tools you need to lay out each --subjectGroupName--, such as string and measuring tape (add them to this list!)'
          },
          visibleWhen: [{ valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.GROUP_GROUND }]
        },
        {
          // id: 'layout for individual in ground',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: 'Any tools you need to lay out your planting area, such as measuring tape (add them to this list!)'
          },
          visibleWhen: [{ valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.INDIVIDUAL_GROUND }]
        },

        /** OTHER */
        {
          // id: 'media',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: 'Enough planting media to fill all your --containerType--'
          },
          visibleWhen: [{ valueName: SystemFieldNames.location, conditionType: 'equals', testValue: EXPERIMENT_LOCATIONS.CONTAINER }]
        },
        {
          // id: 'planting',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: 'Any tools you need for planting, like a mechanical seeder or a trowel (add them to this list!)'
          }
        },
        {
          // id: 'scale for fertilizer generally',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: 'A scale, measuring spoon, or cup to measure fertilizer with'
          },
          visibleWhen: [{ valueName: SystemFieldNames.fertilizerName, conditionType: 'present' }]
        },
        {
          // id: 'scale for fertilizer variable',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: 'A scale, measuring spoon, or cup to measure fertilizer with'
          },
          visibleWhen: [{ valueName: SystemFieldNames.independentVariable, conditionType: 'anyOf', testValue: [INDEPENDENT_VARIABLES.FERTILIZER_TYPE] }]
        },
        {
          // id: 'irrigation',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: 'Any watering equipment you need, like a hose or watering can (add them to this list!)'
          }
        },
        {
          // id: 'measurement',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: 'Any tools or equipment you need to take measurements'
          }
        },
        {
          // id: 'harvest',
          sectionId: checklistItemSection.id,
          entryValues: {
            description: 'Any harvesting supplies you need, like scissors or crates (add them to this list!)'
          }
        }
      ]
    }
  }
