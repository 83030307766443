import * as React from 'react'
import { Form } from '~/form-brain2'
import { useAddEntity, useFormVitalsSubscription } from '~/hooks'
import { experimentFormResolvers } from '~/features'
import { FormRenderer, NewEntityFormOuter } from '~/components/molecules'

import type { EntitySchema, FormOnSubmitFn } from '~/form-brain2'
import type { BlurbElement, Learner, TextInput } from '~/features'
import type { ObjectId } from '~/types/utilities'

interface NewLearnerFormProps {
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void
  experimentId: ObjectId
}

const
  schema: EntitySchema = {
    displayId: {
      fieldName: 'displayId',
      fieldType: 'STRING',
      requiredWhen: [{ conditionType: 'always' }]
    }
  },
  body: Array<TextInput | BlurbElement> = [{
    id: 'only',
    elementVariety: 'TEXT_INPUT',
    fieldName: 'displayId',
    inlineLabel: true,
    hideLabel: true,
    label: 'Learner name',
    placeholder: 'Enter a name'
  }, {
    id: 'coppa-blurb',
    elementVariety: 'BLURB',
    iconColor: 'attention',
    noWrapper: true,
    naturalWidth: true,
    iconContent: '!',
    paragraphs: [
      'In compliance with AgConnect\'s terms and COPPA please do not enter FULL names or other information that would allow a child to be uniquely identified by a third party.'
    ]
  }],
  getDefaultEntity = (experimentId: ObjectId): Partial<Learner> => ({
    experimentId
  }),
  NewLearnerForm: React.FC<NewLearnerFormProps> = ({ onCancel, experimentId }) => {
    const
      { addEntity } = useAddEntity(),
      { formStatus, subscribeToFormStatus } = useFormVitalsSubscription(),
      onSubmit: FormOnSubmitFn = ({ values }) => {
        return addEntity({ values, type: 'learner' })
      },
      formName = 'new-learner-form'

    return (
      <NewEntityFormOuter
        title="New Group Member"
        formStatus={formStatus}
        formName={formName}
        onCancel={onCancel}
        >
        <Form
          formId={formName}
          entitySchema={schema}
          formBody={body}
          initialValues={getDefaultEntity(experimentId)}
          onSubmitCallback={onSubmit}
          FormRenderer={FormRenderer}
          subscribeToFormStatus={subscribeToFormStatus}
          formOptions={{ RootFormRenderer: FormRenderer }}
          {...experimentFormResolvers}
        />
      </NewEntityFormOuter>
    )
  }

export default NewLearnerForm
