import { getMaterialsListItemForm } from './materials'
import { getScheduleActivityForm, getScheduleMeasurementForm, activityAutomaticEntryKeyLabels, measurementAutomaticEntryKeyLabels } from './schedule'
import { getSetUpStepForm, setUpAutomaticEntryKeyLabels } from './setUp'
import { getObservationRecordForm, getActivityRecordForm, getMeasurementRecordForm } from './data'

import type { AnyPlainOrInputElement, ListOption, RepeatingSectionElement, SpecialListSectionElement, VisibilityCondition } from '~/features/questionnaire/types'
import { toPairs } from 'lodash'
// import { allFieldsFromFormBody } from '~/features/questionnaire/helpers'

export { getScheduleDefault, getScheduleTimingString, scheduleSortFn } from './schedule'
export { getSetUpDefault } from './setUp'
// export { getBarChartForm, getLineChartForm } from './analysis'

export const
  formBodyForSpecialSection = (section: SpecialListSectionElement): AnyPlainOrInputElement[] => {
    return section.specialSectionId === 'PLAN_ACTIVITY'
      ? getScheduleActivityForm(section)
      : section.specialSectionId === 'PLAN_MEASUREMENT'
        ? getScheduleMeasurementForm(section)
        : section.specialSectionId === 'MATERIAL'
          ? getMaterialsListItemForm(section)
          : section.specialSectionId === 'SET_UP_STEP'
            ? getSetUpStepForm(section)
            : section.specialSectionId === 'OBSERVATION_RECORD'
              ? getObservationRecordForm(section)
              : section.specialSectionId === 'ACTIVITY_RECORD'
                ? getActivityRecordForm(section)
                : section.specialSectionId === 'MEASUREMENT_RECORD'
                  ? getMeasurementRecordForm(section)
                  : []
  },
  sectionIdVisibilityCondition = (sectionId: string): VisibilityCondition => {
    return {
      conditionType: 'equals',
      valueName: 'sectionId',
      testValue: sectionId,
      conditionScope: 'ownScope'
    }
  },
  specialSectionSpecialDefaultEntryList = (sections: Array<RepeatingSectionElement | SpecialListSectionElement>): ListOption[] => {
    return sections.flatMap(section => {
      if ('specialSectionId' in section && section.specialSectionId === 'PLAN_ACTIVITY') {
        return toPairs(activityAutomaticEntryKeyLabels).map(([optionValue, optionLabel]) => ({
          optionValue,
          optionLabel,
          visibleWhen: [sectionIdVisibilityCondition(section.id)]
        }))
      } else if ('specialSectionId' in section && section.specialSectionId === 'PLAN_MEASUREMENT') {
        return toPairs(measurementAutomaticEntryKeyLabels).map(([optionValue, optionLabel]) => ({
          optionValue,
          optionLabel,
          visibleWhen: [sectionIdVisibilityCondition(section.id)]
        }))
      } else if ('specialSectionId' in section && section.specialSectionId === 'SET_UP_STEP') {
        return toPairs(setUpAutomaticEntryKeyLabels).map(([optionValue, optionLabel]) => ({
          optionValue,
          optionLabel,
          visibleWhen: [sectionIdVisibilityCondition(section.id)]
        }))
      } else {
        return []
      }
    })
  }
  // sectionFieldNamesList = (section: RepeatingSectionElement | SpecialListSectionElement): ListOption[] => {
  //   const body = 'specialSectionId' in section
  //     ? formBodyForSpecialSection(section)
  //     : section.body
  //
  //   return allFieldsFromFormBody(body).map(({ fieldName, description }) => ({
  //     optionValue: fieldName,
  //     optionLabel: description ?? fieldName,
  //     visibleWhen: [sectionIdVisibilityCondition(section.id)]
  //   }))
  // }
