import * as React from 'react'
import { Form } from '~/form-brain2'
import { getBaseErrorString } from '~/utils/formatters'
import { useStepFormBody, useUpdateEntity, useResetEntity } from '~/hooks'
import { experimentFormResolvers } from '~/features'
import {
  downloadsStepFormBody,
  downloadsStepSchema,
  hubStepFormBody,
  hubStepSchema,
  listStepFormBody,
  listStepSchema,
  pagedStepFormBody,
  pagedStepSchema
} from '~/features/worksheet/formHelpers'
import { CheckmarkIcon, FormLabel, SettingsContentWrapper, SettingsTitleRow } from '~/components/atoms'
import { ButtonWithConfirm, ButtonWithMessage, FormRenderer, SubmitButtonComplex } from '~/components/molecules'

import type { EntityObject, EntitySchema, FormOnSubmitFn, FormStatus, SubscribeToFormStatusFn } from '~/form-brain2'
import type { SelectInput, ListOption, QuestionnaireFormat, Experiment, Worksheet, AnyPlainOrInputElement, AnyWorksheet } from '~/features'

interface Props {
  worksheet: AnyWorksheet
  experiment: Experiment
  formStatus: FormStatus | undefined
  allWorksheets: Worksheet[]
  subscribeToFormStatus: SubscribeToFormStatusFn
}

const
  formName = 'step-settings-form',
  schemaByFormat: Record<QuestionnaireFormat, EntitySchema> = {
    LIST: listStepSchema,
    PAGED: pagedStepSchema,
    HUB: hubStepSchema,
    DOWNLOADS: downloadsStepSchema
  },
  baseFormBodyByFormat: Record<QuestionnaireFormat, AnyPlainOrInputElement[]> = {
    LIST: listStepFormBody,
    PAGED: pagedStepFormBody,
    HUB: hubStepFormBody,
    DOWNLOADS: downloadsStepFormBody
  },
  StepSettingsForm: React.FC<Props> = ({ worksheet, allWorksheets, experiment, formStatus, subscribeToFormStatus }) => {
    const
      { updateEntity, isUpdating } = useUpdateEntity(),
      { resetEntity, isResetting, errors: resetErrors, isSuccess: isResetSuccess } = useResetEntity({
        id: worksheet.id,
        type: 'worksheet',
        clearStateOnSuccessAfter: 4000
      }),
      { formBody } = useStepFormBody(baseFormBodyByFormat[worksheet.format], experiment.id, worksheet.id),
      basedOnFieldId = worksheet.basedOnType === 'Template' ? 'stepBasedOnTemplate' : 'stepBasedOnWorksheet',
      basedOnTitle = worksheet.basedOnId
        ? (((formBody.find(o => o.id === basedOnFieldId) as SelectInput | undefined)?.list ?? []) as ListOption[]).find(o => o.optionValue === worksheet.basedOnId)?.optionLabel
        : null,
      onSubmit: FormOnSubmitFn = ({ values, submissionSource }) => updateEntity({
        newValues: values,
        entity: worksheet,
        sourceOfUpdate: submissionSource
      }),
      resetStep: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        resetEntity()
      }

    return <SettingsContentWrapper data-cy="step-settings-form">
      <SettingsTitleRow>
        <FormLabel
          label="Step Settings"
          showLabel
          asHeading
        />
        {!basedOnTitle
          ? null
          : <ButtonWithMessage
            messageText={isResetSuccess ? 'Step has been reset' : undefined}
            icon={isResetSuccess ? <CheckmarkIcon /> : null}
            swapButtonAndMessage
            button={
              <ButtonWithConfirm
                onConfirm={resetStep}
                buttonProps={{
                  size: 'medium',
                  label: 'Reset',
                  color: 'caution',
                  isLoading: isResetting,
                  isDisabled: !!isUpdating || isResetSuccess
                }}
                hasSucceeded={isResetSuccess}
                cancelLabel="Cancel"
                confirmLabel="Confirm Reset"
                subheading="Reset experiment step"
                confirmationBody={`This will delete any changes you have made to the step and replace them with the settings and format from ${basedOnTitle}`}
                errorMessage={resetErrors ? getBaseErrorString(resetErrors, 'An unexpected error occurred') : undefined}
              />
            }
          />}
      </SettingsTitleRow>
      <Form
        formId={formName}
        formBody={formBody}
        entitySchema={schemaByFormat[worksheet.format]}
        initialValues={worksheet as unknown as EntityObject}
        onSubmitCallback={onSubmit}
        FormRenderer={FormRenderer}
        subscribeToFormStatus={subscribeToFormStatus}
        formOptions={{
          automaticIdFields: true,
          worksheetsThisExperiment: allWorksheets,
          RootFormRenderer: FormRenderer
        }}
        {...experimentFormResolvers}
      />
      <SubmitButtonComplex
        formId={formName}
        label="Save Changes"
        formStatus={formStatus}
        isLoading={isUpdating}
      />
    </SettingsContentWrapper>
  }

export default StepSettingsForm
