import * as React from 'react'
import type { BasicIconProps } from '~/types/utilities'

const
  tipPath = 'M5.62057 24.8828L2.82129 35.7981L13.5385 32.8776L13.1546 29.3533L9.45958 29.076L9.17165 25.2907',
  bodyPath = 'M20.5211 9.13281L7.56445 22.3649L11.6594 22.8218L11.9473 26.5581L15.5944 26.8354L16.0583 31.0123L29.0149 17.7802',
  eraserPath = 'M27.7594 2.79688C27.1356 2.79688 26.5277 3.04161 26.0479 3.51477L22.6567 6.97372L31.1346 15.6374L34.5257 12.2111C35.4854 11.2158 35.4854 9.66582 34.5257 8.71951L29.455 3.51477C28.9751 3.04161 28.3673 2.79688 27.7594 2.79688Z',
  boundingPath = 'M25.9998 3.50305C26.4796 3.02989 27.0875 2.78516 27.7113 2.78516C28.3192 2.78516 28.927 3.02989 29.4069 3.50305L34.4776 8.70779C35.4373 9.6541 35.4373 11.2041 34.4776 12.1994L30.1343 16.6382L14.467 32.467L2.97653 35.7131L6 24L21.6564 7.97448L25.9998 3.50305Z',
  BasicPencil: React.FC<BasicIconProps> = ({ className, color = 'black', outlineColor }) => {
    return (
      <svg
        className={className}
        viewBox={outlineColor ? '0.25 0 38 38' : '0 0 38 38'}
        xmlns="http://www.w3.org/2000/svg"
        data-cy="basic-pencil">
        {outlineColor
          ? <path
              d={boundingPath}
              fill={outlineColor}
              stroke={outlineColor}
              strokeWidth="5"
              strokeLinejoin="round"
            />
          : null}
        <path d={eraserPath} fill={color} />
        <path d={bodyPath} fill={color} />
        <path d={tipPath} fill={color} />
      </svg>
    )
  }

export default BasicPencil
