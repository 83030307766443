import { last } from 'lodash'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { DashedLine, HintText } from '~/components/atoms'
import { WorksheetResponseHelpers } from '~/features'
import { getEventLogSummaries } from '~/features/worksheetResponse/helpers'

import type { Experiment, ResponseEventLog as LogEntryType } from '~/features'

interface Props {
  eventLog: LogEntryType[]
  experiment: Experiment
}
const
  LogOuter = styled.div`
    background: ${p => p.theme.colors.containerAccentBg};
    padding: ${p => p.theme.emBaseSpacing}em ${p => p.theme.emBaseSpacing * 1.2}em;
    border-radius: ${p => p.theme.pxBorderRadius * 2}px;
    margin-bottom: ${p => p.theme.emBaseSpacing}em;
  `,
  EntryRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    &:not(:last-child) {
      margin-bottom: ${p => p.theme.emSmallSpacing}em;
    }

    > :first-child {
      flex 1 0 auto;
    }
  `,
  EntryRowCell = styled.div`
    flex: 0 0 auto;
  `,
  EntryText = styled(HintText)`
    font-size: 1.2rem;
    text-align: left;
  `,
  ResultSet = styled.div`
    ${p => p.theme.bodyFont};
    color: ${p => p.theme.colors.bodyText};
  `,
  ResultLabel = styled.div`
    margin-bottom: ${p => p.theme.emSmallSpacing}em;
  `,
  ResultText = styled.div`
    font-weight: normal;
    margin-left: ${p => p.theme.emSmallSpacing / 2}em;
    margin-bottom: ${p => p.theme.emBaseSpacing}em;
  `,
  StyledDashedLine = styled(DashedLine)`
    margin-bottom: ${p => p.theme.emBaseSpacing}em;
  `,
  ResponseEventLog: React.FC<Props> = ({ eventLog, experiment }) => {
    const THEME = useTheme()

    if (!eventLog.length) { return null }

    const
      reversedEventLog = [...eventLog].reverse(),
      lastLog = last(WorksheetResponseHelpers.getLiveLogs(eventLog)),
      gradedEvent = !!lastLog?.comment || lastLog?.grade
        ? lastLog
        : undefined,
      eventLogSummaries = getEventLogSummaries(reversedEventLog, experiment)

    return <LogOuter data-cy="response-event-log">
      {gradedEvent
        ? <>
          {gradedEvent.grade
            ? <ResultSet>
              <ResultLabel>Grade</ResultLabel>
              <ResultText>{gradedEvent.grade}</ResultText>
            </ResultSet>
            : null}
          {gradedEvent.comment
            ? <ResultSet>
              <ResultLabel>Teacher Note</ResultLabel>
              <ResultText>{gradedEvent.comment}</ResultText>
            </ResultSet>
            : null}
          <StyledDashedLine color={THEME.colors.borderAccent} />
        </>
        : null}
      {eventLogSummaries.map((eventLogSummary, index) => {
        const { eventString, outcomeString } = eventLogSummary

        return <EntryRow key={index} data-cy="response-event-log-entry-row">
          <EntryRowCell>
            <EntryText color="default">{eventString}</EntryText>
          </EntryRowCell>
          {outcomeString
            ? <EntryRowCell>
              <EntryText color="default">{outcomeString}</EntryText>
            </EntryRowCell>
            : null}
        </EntryRow>
      })}
    </LogOuter>
  }

export default ResponseEventLog
export { LogOuter, EntryRow, EntryRowCell, EntryText, ResultSet, ResultLabel, ResultText }
