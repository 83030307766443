import { FORM_STATUSES } from '../FormContext'
import type { FormState } from '../internalTypes'

import type {
  FormOnChangeFn,
  EntityObject
} from '../types'

interface Params {
  state: FormState
  newValues: EntityObject
  silentUpdate?: boolean
  onChange?: FormOnChangeFn
}

export const
  getStateOnUpdatedValues = ({ state, newValues, silentUpdate, onChange }: Params): FormState => {
    const nextModel = !onChange
      ? { ...state.values, ...newValues }
      : onChange({
        prevValues: state.values,
        nextValues: { ...state.values, ...newValues },
        contextAndResolvers: state
      })

    return {
      ...state,
      values: nextModel,
      errors: state.validateObject({
        values: nextModel,
        schema: state.schema,
        contextAndResolvers: { ...state, values: nextModel },
        validationOptions: { triggeredBy: 'CHANGE' }
      }),
      formStatus: silentUpdate ? state.formStatus : FORM_STATUSES.DIRTY
    }
  }
