import { SystemFieldNames, SystemFieldGroups, INDEPENDENT_VARIABLES } from './systemFieldConstants'
import { IndependentVariableCategories } from './independentVariableCategory'
import type { QField, VisibilityCondition } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'
import type { IndependentVariableCategory } from './independentVariableCategory'

const
  ivcEquals = (ivcToMatch: IndependentVariableCategory): VisibilityCondition[] => [{
    valueName: SystemFieldNames.independentVariableCategory,
    conditionType: 'equals',
    testValue: ivcToMatch
  }],
  title = 'Independent variable',
  description = 'This is the variable the learner(s) will manipulate in the experiment.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.independentVariable,
    fieldType: 'STRING',
    options: [
      {
        optionValue: INDEPENDENT_VARIABLES.SEED_VARIETY,
        optionLabel: 'variety',
        visibleWhen: ivcEquals(IndependentVariableCategories.SEED)
      },
      {
        optionValue: INDEPENDENT_VARIABLES.PLANTING_DENSITY,
        optionLabel: 'density (rate or spacing)',
        visibleWhen: ivcEquals(IndependentVariableCategories.PLANTING)
      },
      {
        optionValue: INDEPENDENT_VARIABLES.FERTILIZER_TYPE,
        optionLabel: 'type',
        visibleWhen: ivcEquals(IndependentVariableCategories.FERTILIZER)
      },
      {
        optionValue: INDEPENDENT_VARIABLES.FERTILIZER_RATE,
        optionLabel: 'rate',
        visibleWhen: ivcEquals(IndependentVariableCategories.FERTILIZER)
      },
      {
        optionValue: INDEPENDENT_VARIABLES.FERTILIZER_TIMING,
        optionLabel: 'timing',
        visibleWhen: ivcEquals(IndependentVariableCategories.FERTILIZER)
      },
      {
        optionValue: INDEPENDENT_VARIABLES.IRRIGATION_TYPE,
        optionLabel: 'type',
        visibleWhen: ivcEquals(IndependentVariableCategories.IRRIGATION)
      },
      {
        optionValue: INDEPENDENT_VARIABLES.IRRIGATION_RATE,
        optionLabel: 'rate',
        visibleWhen: ivcEquals(IndependentVariableCategories.IRRIGATION)
      },
      {
        optionValue: INDEPENDENT_VARIABLES.IRRIGATION_TIMING,
        optionLabel: 'timing',
        visibleWhen: ivcEquals(IndependentVariableCategories.IRRIGATION)
      },
      {
        optionValue: 'mulch',
        visibleWhen: ivcEquals(IndependentVariableCategories.OTHER)
      },
      {
        optionValue: 'soil or bed prep',
        visibleWhen: ivcEquals(IndependentVariableCategories.OTHER)
      },
      {
        optionValue: 'planting media',
        visibleWhen: ivcEquals(IndependentVariableCategories.OTHER)
      }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.independentVariable,
    variableGroup: SystemFieldGroups.QUESTION,
    isRequired: true,
    shouldFollow: [SystemFieldNames.independentVariableCategory],
    includeOtherByDefault: true,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
