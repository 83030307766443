import * as React from 'react'
import type { BasicIconProps } from '~/types/utilities'

const
  BasicTriangle: React.FC<BasicIconProps> = ({ className, color = 'black', outlineColor }) => {
    return (
      <svg
        className={className}
        viewBox="-3.5 -3.5 57 57"
        xmlns="http://www.w3.org/2000/svg"
        data-cy="basic-triangle">
        <polygon
          fill={color}
          stroke={outlineColor}
          strokeWidth="3px"
          strokeLinejoin="round"
          points="0,43.3 25,0 50,43.3" />
      </svg>
    )
  }

export default BasicTriangle
