import useAppSelector from './useAppSelector'
import useEntityData from './useEntityData'
import useSession from './useSession'
import { WorksheetHelpers } from '~/features'
import { selectExperimentById, selectLearnerById, selectResponsesByLearnerId, selectWorksheetsByExperimentId } from '~/features/entity/selectors'

import type {
  Experiment,
  Learner,
  Worksheet,
  WorksheetResponse,
  WorksheetStatusForStudent
} from '~/features'

interface StepData {
  isLoading?: boolean
  forbidden?: boolean
  stepIsAvailable?: boolean
  learner?: Learner
  experiment?: Experiment
  worksheet?: Worksheet
  worksheets: Worksheet[]
  worksheetStatus?: WorksheetStatusForStudent
  worksheetStatuses?: ReturnType<typeof WorksheetHelpers.getWorksheetAvailabilities>[0]
  response?: WorksheetResponse
  responses: WorksheetResponse[]
}

const
  useStepData = (stepId: string): StepData => {
    const
      { dataStatus } = useEntityData('lazy'),
      { learnerId } = useSession(),
      learner = useAppSelector(selectLearnerById(learnerId)),
      experiment = useAppSelector(selectExperimentById(learner?.experimentId)),
      worksheets = useAppSelector(selectWorksheetsByExperimentId(experiment?.id)),

      worksheet = (worksheets ?? []).find(o => o.id === stepId),
      worksheetIds = (worksheets ?? []).map(o => o.id),

      responses = useAppSelector(selectResponsesByLearnerId(learnerId, worksheetIds)),
      response = (responses ?? []).find(o => o.worksheetId === stepId),

      learnerHasAccessToRequestedStep = worksheetIds.includes(stepId),
      isLoading = dataStatus === 'initializing' || dataStatus === 'uninitialized',

      [worksheetStatuses] = worksheets && responses && experiment
        ? WorksheetHelpers.getWorksheetAvailabilities(worksheets, responses, experiment.progressBasis)
        : [undefined],
      worksheetStatus = worksheetStatuses && worksheet
        ? worksheetStatuses[worksheet.id]
        : undefined,
      stepIsAvailable = worksheetStatus && worksheetStatus !== 'closed'

    return {
      isLoading,
      forbidden: !isLoading && !learnerHasAccessToRequestedStep,
      stepIsAvailable,
      learner,
      experiment,
      worksheet,
      worksheets,
      worksheetStatuses,
      worksheetStatus,
      response,
      responses
    }
  }

export default useStepData
