import type { DefaultTheme } from 'styled-components'
import { BASE_THEME, AGC_COLORS as _ } from './BaseTheme'

const
  RESEARCH_COLORS = {
    accentRedLight: '#FEB2A8',
    // accentRedDark: AGC_COLORS.redMed,
    accentOrangeLight: '#FDD2A7',
    accentOrangeDark: '#F99235',
    accentGreenLight: '#B0E9B5',
    // accentGreenDark: AGC_COLORS.greenMed,
    accentSageLight: '#A2D3B8',
    accentSageDark: '#307D51',
    accentBlueLight: '#A5D5F1',
    accentBlueDark: '#3E89B7',
    accentSlateLight: '#9EB5C6',
    accentSlateDark: '#547696',

    redDarkGray: '#953530' // Consider AGC_COLORS.redDark - this may just be inconsitent usage in the design sheet
  },
  // Very similar to the teaching theme but with fewer colors and no mascot
  RESEARCH_THEME: DefaultTheme = {
    ...BASE_THEME,
    accentFont: 'font-family: "Lato", sans-serif;',

    showMascot: false,

    colors: {
      ...BASE_THEME.colors,

      brandPrimary: _.uvmBrandLightGreen,
      brandSecondary: _.uvmBrandDarkGreen,

      translucentDark: 'rgba(0,0,0,0.8)',

      bodyAccentText: _.greenDark,
      borderAccent: _.greenDark,
      borderAccentAlt: _.greenMed,
      headingAccentText: _.greenDark,
      headingAccentTextAlt: _.blueDark,
      headingUnderstateText: _.greenMed,
      containerAccentBg: _.greenLight,
      containerAccentText: _.grayDark,

      back: _.greenGrayDark,
      backText: _.white,
      backStandaloneText: _.grayMedDark,

      caution: _.redMed,
      cautionText: _.white,
      cautionStandaloneText: RESEARCH_COLORS.redDarkGray,

      attentionStandaloneText: _.greenMed,

      accentOneDark: _.redMed,
      accentOneLight: RESEARCH_COLORS.accentRedLight,
      accentTwoDark: RESEARCH_COLORS.accentOrangeDark,
      accentTwoLight: RESEARCH_COLORS.accentOrangeLight,
      accentThreeDark: _.greenMed,
      accentThreeLight: RESEARCH_COLORS.accentGreenLight,
      accentFourDark: RESEARCH_COLORS.accentSageDark,
      accentFourLight: RESEARCH_COLORS.accentSageLight,
      accentFiveDark: RESEARCH_COLORS.accentBlueDark,
      accentFiveLight: RESEARCH_COLORS.accentBlueLight,
      accentSixDark: RESEARCH_COLORS.accentSlateDark,
      accentSixLight: RESEARCH_COLORS.accentSlateLight
    }
  }

export default RESEARCH_THEME
export { RESEARCH_THEME, RESEARCH_COLORS }
