import * as React from 'react'
import styled from 'styled-components'
import { sortBy } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import { useAddWorksheetResponse, useLearnerStepContext } from '~/hooks'
import { DoubleContent } from '~/components/layouts'
import { WorksheetHelpers, addEmphasisToString, interpolateString } from '~/features'

import type { HubWorksheet, Worksheet } from '~/features'
import { InfoPanel, StepSign } from '~/components/molecules'
import { isAllEmpty } from '~/form-brain2/testers'
import { SubheadingTwo } from '~/components/atoms'
import { getBaseErrorString } from '~/utils/formatters'
import { getEntitiesAcrossResponses } from '~/features/worksheetResponse/helpers'

const
  StepListOuter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    > :last-child {
      margin-bottom: 2em;
    }
  `,
  StyledStepSign = styled(StepSign)`
    font-size: 1.6em;
  `,
  SubheadingThree = styled(SubheadingTwo)`
    color: ${p => p.theme.colors.headingText};
    margin-top: ${p => p.theme.emBaseSpacing}em;
  `,
  HubStep: React.FC = () => {
    const
      navigate = useNavigate(),
      { stepId } = useParams(),
      stepContext = useLearnerStepContext(),
      { addWorksheetResponse, isAdding } = useAddWorksheetResponse(),
      { setMascotState, responses, worksheets, experiment, learner } = stepContext,
      worksheet = stepContext.worksheet as HubWorksheet,
      entities = getEntitiesAcrossResponses(responses)

    React.useEffect(() => {
      if (worksheet?.format && worksheet.format !== 'HUB') {
        navigate(`/project-step/${stepId}`, { replace: true })
      }
    }, [worksheet?.format, navigate, stepId])

    React.useEffect(() => {
      setMascotState({
        mascotVariant: worksheet.mascotVariant ?? 'knapsack',
        hintText: worksheet.pageHint ?? 'Use the green buttons to get to different parts of this step.'
      })
    }, [worksheet, setMascotState])

    const
      sortedDependentWorksheets = sortBy(worksheets.filter(o => o.parentWorksheetId === worksheet.id), 'rank'),
      worksheetStatusById = responses
        ? WorksheetHelpers.getStudentFacingWorksheetStatuses(worksheets, responses, experiment.progressBasis)
        : {},
      stepSummaries = sortedDependentWorksheets.map((worksheet: Worksheet, index: number) => {
        const
          status = worksheetStatusById[worksheet.id],
          { name } = worksheet,
          hasResponse = responses.some(o => o.worksheetId === worksheet.id),
          buildResponse = status !== 'closed' && !hasResponse
            ? () => {
                void addWorksheetResponse(worksheet, learner, experiment)().then((result) => {
                  if (result.errors) {
                    alert(getBaseErrorString(result.errors, 'Unable to start a response for this worksheet. Please try again and contact support if the problem persists.'))
                  } else {
                    navigate(`/project-step/${worksheet.id}`)
                  }
                })
              }
            : null

        return {
          label: name,
          buildResponse,
          isBuilding: buildResponse && isAdding,
          status,
          index,
          worksheetId: worksheet.id
        }
      }),
      hubStepList = <StepListOuter>
        {stepSummaries.map(summary => {
          return (
            <StyledStepSign
              key={summary.index}
              label={summary.label}
              number={(summary.index + 1).toString()}
              stepStatus={summary.status}
              isLoading={!!summary.isBuilding}
              withShadow
              rectangleOnly
              {...(
                summary.status === 'closed'
                  ? {}
                  : summary.buildResponse
                    ? { onClick: summary.buildResponse }
                    : { linkProps: { to: `/project-step/${summary.worksheetId}` } }
              )}
            />
          )
        })}
      </StepListOuter>,
      hubText = worksheet.hubPageText ? [interpolateString(worksheet.hubPageText, entities, true)] : [],
      stepHomeTexts = sortedDependentWorksheets.reduce<React.ReactNode[]>((memo, o) => {
        if (o.homePageText) {
          return [
            ...memo,
            [
              <SubheadingThree key={o.name}>
                {interpolateString(o.name, entities, true) ?? o.name}
              </SubheadingThree>,
              addEmphasisToString(interpolateString(o.homePageText, entities, true))// ?? o.homePageText
            ]
          ]
        }

        return memo
      }, []),
      infoPanelContent = !isAllEmpty(hubText) || !isAllEmpty(stepHomeTexts)
        ? [...hubText, ...stepHomeTexts].map(s => typeof s === 'string' ? addEmphasisToString(s) : s)
        : undefined,
      shortcutList = infoPanelContent && !isAllEmpty(infoPanelContent)
        ? <InfoPanel heading={`${worksheet.name} Steps`}>
            {infoPanelContent.map((s, i) => <div key={`content-${i}`}>{s}</div>)}
          </InfoPanel>
        : undefined

    return (
      <DoubleContent
        spaceEvenly
        primaryContent={hubStepList}
        secondaryContent={shortcutList}
      />
    )
  }

export default HubStep
