import { cloneDeep, forEach, fromPairs, keyBy, mapValues, merge, pick, toPairs } from 'lodash'
import { isAllEmpty } from '~/utils/testers'
import { formatDate } from '~/utils/formatters'
import { isResponseAvialableForReview, doesResponseRequireUrgentAction } from '~/features/worksheetResponse/helpers'
import { DYNAMIC_LABELS } from '~/features/experiment/types'
import { SYSTEM_FIELDS } from '~/features/experiment/systemFields'
import { comparableInputVarieties, isListSection, isPagedSection } from '~/features/questionnaire/types'
import { allFieldsFromQuestionnaire } from '~/features/questionnaire/helpers'

import type { DynamicLabels, Experiment } from '~/features/experiment/types'
import type { AnyWorksheet } from '~/features/worksheet/types'
import type { AnyTemplate } from '~/features/template/types'
import type { WorksheetResponse } from '~/features/worksheetResponse/types'
import type { EntitySchema, SchemaEntry } from '~/form-brain2'
import type { FieldType, InputElement, InputElementVariety, AnyBodyElement } from '~/features/questionnaire/types'

export type ProjectStatus = 'urgent' | 'nonurgent' | 'ok' | 'closed' | 'finished'

export const
  getDynamicString = (experiment: Pick<Experiment, 'dynamicLabels'> | undefined, key: keyof DynamicLabels): string => {
    return experiment?.dynamicLabels?.[key] ?? DYNAMIC_LABELS[key]
  },
  getTimingString = (experiment: Experiment): string => {
    return experiment.closedAt
      ? `Closed ${formatDate(experiment.closedAt)}`
      : experiment.startedAt
        ? `Started ${formatDate(experiment.startedAt)}`
        : `Created ${formatDate(experiment.createdAt)}`
  },
  getStatusFromResponses = (responses: WorksheetResponse[], experiment: Experiment): ProjectStatus => {
    return responses.some(o => doesResponseRequireUrgentAction(o, experiment))
      ? 'urgent'
      : responses.some(isResponseAvialableForReview)
        ? 'nonurgent'
        : 'ok'
  },
  getSchema = (experiment: Experiment, bodyElements: AnyBodyElement[]): EntitySchema => {
    const
      valuesFromBody = getFieldsFromBody(bodyElements),
      valuesFromSchema = {
        ...mapValues(SYSTEM_FIELDS, 'schemaEntry'),
        ...keyBy(experiment.sharedFields, 'fieldName')
      },
      mergedWithSchema = fromPairs(toPairs(valuesFromBody).map(([k, v]: [string, SchemaEntry]) => {
        return valuesFromSchema[k]
          ? [k, merge(cloneDeep(v), valuesFromSchema[k])]
          : [k, v]
      }))

    // console.log({ valuesFromBody, valuesFromSchema, mergedWithSchema })

    return mergedWithSchema
  },
  allFieldsFromExperiment = (allQuestionnaires: AnyWorksheet[] | AnyTemplate[]): ReturnType<typeof allFieldsFromQuestionnaire> => {
    return allQuestionnaires.flatMap(q => allFieldsFromQuestionnaire(q))
  }

const
  fieldTypeLookup: Record<InputElementVariety, FieldType> = {
    TEXT_INPUT: 'STRING',
    NUMBER_INPUT: 'NUMBER',
    DATE_INPUT: 'DATE',
    CHECKBOX_INPUT: 'BOOLEAN',
    SHORT_ANSWER: 'STRING',
    LONG_ANSWER: 'STRING',
    MULTI_SELECT_INPUT: 'STRING:ARRAY',
    MULTI_TEXT_INPUT: 'STRING:ARRAY',
    SELECT_INPUT: 'STRING',
    PARAGRAPHS: 'STRING:ARRAY', // not really, just for typing
    COLOR_PICKER: 'STRING' // not really, just for typing
  },
  getFieldsFromBody = (bodyElements: Array<AnyBodyElement | undefined | string>): EntitySchema => {
    const schema: EntitySchema = {}

    forEach(bodyElements, bodyElement => {
      if (!bodyElement || typeof bodyElement !== 'object') { return }

      const { elementVariety } = bodyElement

      if (isPagedSection(bodyElement)) {
        merge(schema, getFieldsFromBody(bodyElement.pages))
        return
      }

      if (bodyElement.elementVariety === 'PAGE' || isListSection(bodyElement)) {
        merge(schema, getFieldsFromBody([
          ...bodyElement.body,
          'heading' in bodyElement ? bodyElement.heading ?? undefined : undefined
        ]))
        return
      }

      if (elementVariety === 'FIELDSET') {
        merge(schema, getFieldsFromBody(bodyElement.body))
        return
      }

      if (elementVariety === 'TABLE') {
        merge(schema, getFieldsFromBody(
          bodyElement.columnHeaders.filter(o => typeof o !== 'string')
        ))

        bodyElement.rows.map((row) =>
          merge(schema, getFieldsFromBody(
            row.filter(
              o => typeof o !== 'string'
            )
          ))
        )
        return
      }

      if (elementVariety === 'FILL_IN_THE_BLANK') {
        merge(schema, getFieldsFromBody(bodyElement.segments))
        return
      }

      if (elementVariety === 'COLLECTION' || elementVariety === 'SCOPE') {
        schema[bodyElement.fieldName] = {
          ...pick(bodyElement, [
            'requiredWhen',
            'recommendedWhen',
            'validWhen',
            'visibleWhen',
            'readOnlyWhen',
            'isTransient'
          ]),
          fieldName: bodyElement.fieldName,
          fieldType: elementVariety,
          format: getFieldsFromBody([...(bodyElement.formBody ?? [])])
        }
      }

      if (elementVariety === 'SPECIAL') {
        // TODO look up body based on special element - or accept the limits of this method...
        /*
          'printableFormatSettings' - not implemented, from research
          'bodyConditionScoreImage' - not implemented, from research
          'stepFormatInfo' - no fields
          'mascotPreview' - no fields
          'singleBodyElement' - any single from elementFormBodies
          'bodyElementCollection' - any group of elementFormBodies
          'madLibSegments' - any mad lib segments
        */
        // merge(schema, getFieldsFromBody(bodyElement.body))
        return
      }

      if (comparableInputVarieties.includes(elementVariety)) {
        const element = bodyElement as InputElement

        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        schema[element.fieldName] = {
          ...pick(bodyElement, [
            'fieldName',
            'requiredWhen',
            'recommendedWhen',
            'validWhen',
            'visibleWhen',
            'readOnlyWhen',
            'isTransient'
          ])
        } as SchemaEntry

        if (elementVariety === 'SELECT_INPUT' || elementVariety === 'MULTI_SELECT_INPUT' || elementVariety === 'MULTI_TEXT_INPUT') {
          const listElement = bodyElement

          if (listElement.customOption) {
            schema[element.fieldName].fieldType = 'STRING'
          } else {
            schema[element.fieldName].fieldType = 'ENUM'
          }

          if (listElement.list && !isAllEmpty(listElement.list)) {
            schema[element.fieldName].options = listElement.list.map(el => {
              return typeof el === 'string'
                ? { optionValue: el }
                : el
            })
          }
        } else {
          schema[element.fieldName].fieldType = fieldTypeLookup[(bodyElement as InputElement).elementVariety]
        }
      }
    })

    return schema
  }
