import { SystemFieldGroups, SystemFieldNames } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

export type DependentVariableCategory = (
  typeof DependentVariableCategories[keyof typeof DependentVariableCategories]
)

const
  DependentVariableCategories = {
    VEGETABLE: 'vegetable',
    ROW_CROP: 'row crop',
    COVER_CROP: 'cover crop',
    SOIL: 'soil',
    OTHER: 'other'
  } as const,
  title = 'Dependent variable category',
  description = 'This can be used to narrow down the list of suggested dependent variables in a fill-in-the-blank format.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.dependentVariableCategory,
    fieldType: 'ENUM',
    invalidates: [
      SystemFieldNames.dependentVariable,
      SystemFieldNames.subject
    ],
    options: [
      { optionValue: DependentVariableCategories.VEGETABLE },
      { optionValue: DependentVariableCategories.ROW_CROP },
      { optionValue: DependentVariableCategories.COVER_CROP },
      { optionValue: DependentVariableCategories.SOIL },
      { optionValue: DependentVariableCategories.OTHER, optionLabel: '(other)' }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.dependentVariableCategory,
    variableGroup: SystemFieldGroups.QUESTION,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails,
  DependentVariableCategories
}
