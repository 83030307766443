import * as React from 'react'
import styled from 'styled-components'

interface SubheadingOneProps {
  className?: string
  children: React.ReactNode
}

const
  SubheadingOneText = styled.div`
    align-items: center;
    font-size: 1.333rem; // 20px
    line-height: 1;
    color: ${p => p.theme.colors.headingText};
    ${p => p.theme.headingFont}
  `,
  SubheadingOne: React.FC<SubheadingOneProps> = ({ className, children }) => {
    return (
      <SubheadingOneText data-cy="subheading-one" className={className}>
        {children}
      </SubheadingOneText>
    )
  }

export default SubheadingOne
