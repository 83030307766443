import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'
import { EXPERIMENT_LOCATIONS } from './location'

const
  PLANTING_FORMATS = {
    GROUP_GROUND: 'groups in the ground',
    INDIVIDUAL_GROUND: 'individual in the ground',
    GROUP_CONTAINER: 'groups in containers',
    INDIVIDUAL_CONTAINER: 'individual in containers'
  } as const,
  title = 'Growing format (groups or individual)',
  description = 'This determines whether subjects for the experiment will be single plants or groups of plants, which effects the choices offered throughout the experiment.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.plantingFormat,
    invalidates: [
      SystemFieldNames.plantSpacingUnit,
      SystemFieldNames.plantSpacingArea,
      SystemFieldNames.fertilizerRateArea
    ],
    fieldType: 'ENUM',
    options: [
      {
        optionValue: PLANTING_FORMATS.INDIVIDUAL_CONTAINER,
        optionLabel: 'individual plants (one plant in each container)',
        visibleWhen: [{
          valueName: SystemFieldNames.location,
          conditionType: 'equals',
          testValue: EXPERIMENT_LOCATIONS.CONTAINER
        }]
      },
      {
        optionValue: PLANTING_FORMATS.GROUP_CONTAINER,
        optionLabel: 'groups of plants (more than one plant in each container)',
        visibleWhen: [{
          valueName: SystemFieldNames.location,
          conditionType: 'equals',
          testValue: EXPERIMENT_LOCATIONS.CONTAINER
        }]
      },
      {
        optionValue: PLANTING_FORMATS.INDIVIDUAL_GROUND,
        optionLabel: 'individual plants (in the ground)',
        visibleWhen: [{
          valueName: SystemFieldNames.location,
          conditionType: 'equals',
          testValue: EXPERIMENT_LOCATIONS.GROUND
        }]
      },
      {
        optionValue: PLANTING_FORMATS.GROUP_GROUND,
        optionLabel: 'groups of plants (entire plots, rows, or beds)',
        visibleWhen: [{
          valueName: SystemFieldNames.location,
          conditionType: 'equals',
          testValue: EXPERIMENT_LOCATIONS.GROUND
        }]
      }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.plantingFormat,
    variableGroup: SystemFieldGroups.WHAT_WHERE,
    shouldFollow: [SystemFieldNames.location],
    isRequired: true,
    optionValuesReadOnly: true,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails,
  PLANTING_FORMATS
}
