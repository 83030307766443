import * as React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { DOCUMENT_TITLES, HEADER_TEXTS } from '~/utils/strings'
import { getSortedTopLevelWorksheets } from '~/features/worksheet/helpers'
import { selectExperimentById, selectWorksheetById, selectWorksheetsByExperimentId } from '~/features/entity/selectors'
import { useAppSelector, useEntityData, useDeleteEntity } from '~/hooks'
import BASE_THEME from '~/components/BaseTheme'
import { Button, HeadingOne, Link, SubheadingOne, SizedLoadingIcon } from '~/components/atoms'
import { ErrorMessage } from '~/components/molecules'
import { StepSettings } from '~/components/organisms'
import { BrandedPage, FlexibleMain } from '~/components/layouts'
import { ErrorPage, Ul, Li, LeadIn, ContactUsMessage } from '~/components/pages/NotFoundPage'
import DeleteConfirmationComplex from '~/components/organisms/DeleteConfirmationComplex'

import type { AnyWorksheet, Experiment } from '~/features'

const
  Main = styled(FlexibleMain)`
    min-height: 500px;
    margin-bottom: 500px;
  `,
  StyledLoadingIcon = styled(SizedLoadingIcon)`
    margin-top: 20px;
  `,
  UpperRightDeleteComplex = styled(DeleteConfirmationComplex)`
    position: absolute;
    top: ${p => p.theme.emBaseSpacing * 1.55}em; // 17px
    right: ${p => p.theme.emBaseSpacing * 1.55}em; // 17px
  `,
  TeacherStepPage: React.FC = () => {
    const
      { id } = useParams(),
      navigate = useNavigate(),
      { dataStatus, isLoading } = useEntityData(),
      { deleteEntity, isDeleting } = useDeleteEntity(),
      worksheet = useAppSelector(selectWorksheetById(id)),
      experiment = useAppSelector(selectExperimentById(worksheet?.experimentId)),
      parentWorksheet = useAppSelector(selectWorksheetById(worksheet?.parentWorksheetId)),
      allWorksheets = useAppSelector(selectWorksheetsByExperimentId(experiment?.id)),
      sortedTopLevelWorksheets = getSortedTopLevelWorksheets(allWorksheets),
      stepNumber = worksheet
        ? sortedTopLevelWorksheets.indexOf(worksheet) + 1
        : '?',
      prevExperimentId = React.useRef(experiment?.id),
      experimentName = experiment ? experiment.name : undefined,
      projectPath = experiment ? `/projects/${experiment?.id}/settings` : undefined,
      deleteStep = (): void => {
        if (!id || !prevExperimentId.current) { return }

        deleteEntity({ type: 'worksheet', id }).then(
          () => { navigate(`/projects/${prevExperimentId.current as string}/settings`) },
          () => {}
        )
      }

    React.useEffect(() => {
      if (experiment?.id && experiment.id !== prevExperimentId.current) {
        prevExperimentId.current = experiment.id
      }
    }, [experiment?.id])

    if (dataStatus === 'initializing' || dataStatus === 'uninitialized') {
      return <BrandedPage documentTitle={DOCUMENT_TITLES.loading} headerText={HEADER_TEXTS.teaching} whichHome="teaching" showLoader />
    } else if ((!experiment || !worksheet) && dataStatus !== 'error') {
      return <ErrorPage errorTitle="Step Not Found" message="Step Not Found">
        <LeadIn>What happened?</LeadIn>
        <Ul>
          <Li>Check the web address for typos</Li>
          <Li>The group you are looking for may have been deleted</Li>
          <Li>You may not have access to this group</Li>
        </Ul>

        <LeadIn>Return to <Link to="/projects">All Groups</Link></LeadIn>
        <ContactUsMessage />
      </ErrorPage>
    }

    return <ThemeProvider theme={BASE_THEME}>
      <BrandedPage
        documentTitle={`Step Settings${worksheet?.name ? ` | ${worksheet.name}` : ''} | AgConnect`}
        whichHome="teaching"
        headerText={HEADER_TEXTS.teaching}>
        <Main
          heading={!worksheet
            ? <HeadingOne>Project Step</HeadingOne>
            : parentWorksheet
              ? <HeadingOne>{parentWorksheet.name} Step: {worksheet.name}</HeadingOne>
              : <HeadingOne>Step {stepNumber}: {worksheet.name}</HeadingOne>}
          subheading={<SubheadingOne>{experimentName}</SubheadingOne>}
          topActions={projectPath ? <Button label="Group Settings" withIcon="arrowL" color="back" size="medium" linkProps={{ to: projectPath }} /> : undefined}
          absoluteContent={
            worksheet && !isLoading && !worksheet?.releasedAt
              ? <UpperRightDeleteComplex
                triggerLabel="Delete Step"
                subheading={`Delete: ${worksheet.name}`}
                onConfirmDelete={deleteStep}
                isDisabled={false}
                isProcessing={!!isDeleting}
                overrideButtonProps={{ size: 'medium' }}
              />
              : undefined
          }
          >
          {dataStatus === 'error'
            ? <ErrorMessage>
              There was a problem retrieving the step. Please try again later and contact support if the problem persists.
            </ErrorMessage>
            : isLoading
              ? <StyledLoadingIcon />
              : worksheet?.releasedAt
                ? <ErrorMessage>This step has been published and cannot be edited.</ErrorMessage>
                : <StepSettings worksheet={worksheet as AnyWorksheet} experiment={experiment as Experiment} allWorksheets={allWorksheets} />}
        </Main>
      </BrandedPage>
    </ThemeProvider>
  }

export default TeacherStepPage
