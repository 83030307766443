import React from 'react'
import type { ListWorksheet, PagedWorksheet } from '~/features'
import { ContentBody, ProgressText, StyledContentFooter, StyledContentHeader, WorksheetPage } from '../PagedStep/PageForm'
import { BasicCross, Icon } from '~/components/atoms'
import { Adder, EntryListWrapper } from '../ListStep/ListStepEntryList'
import { CardHeader, TabGroup, CardBody, ExpandedRowOuter, StyledCardFooter } from '~/components/molecules'
import { WideButton } from '../ListStep/ListEntryForm'
import StepSectionTabs from '../PagedStep/StepSectionTabs'

interface Props {
  worksheet: ListWorksheet | PagedWorksheet
  setSelectedSection?: (sectionId?: string) => void
  notFound?: boolean // TODO handle in UI
}

const BlankPreview: React.FC<Props> = ({ worksheet, setSelectedSection }) => {
  if (worksheet.format === 'PAGED') {
    const pagedWorksheet: PagedWorksheet = worksheet

    return <>
      {pagedWorksheet.sections.length > 1
        ? <TabGroup>
          <StepSectionTabs {...(setSelectedSection ? { onTabClick: setSelectedSection } : { readOnly: true })} {...{ worksheet, sectionIndex: -1, farthestReachedSectionIndex: -1 }} />
        </TabGroup>
        : null }
      <WorksheetPage>
        <StyledContentHeader
          subheading="Question:"
          heading="Choose a section to see a preview"
          icon={<Icon content="?" degreesRotation={-8.66} />}
        />
        <ContentBody />
        <StyledContentFooter
          items={[
            <ProgressText
              key="progress"
              className="content-footer-separator">
              ?
            </ProgressText>
          ]}
        />
      </WorksheetPage>
    </>
  }

  return <EntryListWrapper>
    <ExpandedRowOuter $isInline $hasFooter>
      <CardHeader
        icon={<Icon content="1" color="understate" />}
        heading="Choose a section to see a preview"
      />
      <CardBody $isInline />
      <StyledCardFooter
        contentLeft={
          <WideButton
            color="caution"
            size="small"
            label="--------"
          />
        }
        contentRight={
          <WideButton
            color="forward"
            withIcon="checkmark"
            size="small"
            label="--------"
          />
        } />
    </ExpandedRowOuter>
    <Adder
      label=""
      icon={
        <Icon
          content={BasicCross}
          color="understate"
          frameStyle="circle"
      />}
      orientation="up"
    />
  </EntryListWrapper>
}

export default BlankPreview
