import * as React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { get, pick } from 'lodash'
import { Form } from '~/form-brain2'
import { TEACHING_HELP_EMAIL, TEACHING_HELP_PHONE_NUMBER, TEACHING_HELP_URL } from '~/utils/strings'
import { userLoggedOut } from '~/features/session/sessionSlice'
import { experimentFormResolvers } from '~/features'
import { useFormVitalsSubscription, useSession, useUpdateEntity } from '~/hooks'
import { Button, Link } from '~/components/atoms'
import { FormRenderer } from '~/components/molecules'
import { SuccessMessage } from '~/components/organisms/TeacherLogInForm'

import type { TextInput, User } from '~/features'
import type { EntityObject, EntitySchema, FormOnSubmitFn } from '~/form-brain2'

const
  Small = styled.div`
    margin: 0;
    font-size: 0.85rem;
  `,
  Footnote = styled.p`
    text-align: center;
    margin-top: ${p => p.theme.emBaseSpacing}em;
  `,
  HR = styled.hr`
    margin: ${p => p.theme.emBaseSpacing}em 0;
    border-color: ${p => p.theme.colors.borderAlt};
  `,
  SubmitButton = styled(Button)`
    align-self: flex-start;
  `,
  LogoutButton = styled(Button)`
    align-self: center;
  `,
  formName = 'updateUsername',
  schema: EntitySchema = {
    unconfirmedEmail: {
      fieldName: 'unconfirmedEmail',
      fieldType: 'STRING'
    }
  },
  body: TextInput[] = [{
    id: 'oldEmail',
    elementVariety: 'TEXT_INPUT',
    fieldName: 'email',
    inputType: 'email',
    label: 'Current Email:',
    readOnlyWhen: [{ conditionType: 'always' }]
  }, {
    id: 'newEmail',
    elementVariety: 'TEXT_INPUT',
    fieldName: 'unconfirmedEmail',
    inputType: 'email',
    label: 'New Email:'
  }],
  AccountSettings: React.FC = () => {
    const
      dispatch = useDispatch(),
      navigate = useNavigate(),
      { user, isTeacher, accountTypeString } = useSession(),
      { formStatus, subscribeToFormStatus } = useFormVitalsSubscription(),
      { updateEntity, meta } = useUpdateEntity(),
      token = get(meta, 'mailInfo.token') as string | undefined,
      logOut: React.MouseEventHandler<HTMLButtonElement> = () => {
        dispatch(userLoggedOut())
        navigate(isTeacher ? '/educate' : '/')
      },
      onSubmit: FormOnSubmitFn = ({ values }) => updateEntity({
        newValues: pick(values, 'unconfirmedEmail') as { unconfirmedEmail: string },
        entity: user as User,
        customPath: 'current_user'
      })

    return <>
      {formStatus === 'success' && user?.unconfirmedEmail
        ? token
          ? <p>
              The requested can only be used a test account. You can confirm the new email with <Link to={`/access/${token}`}>this link</Link>
            </p>
          : <SuccessMessage>
            <p>An email with a verification link has been sent to {user.unconfirmedEmail}. We recommend verifying your email immediately. The email on your account will not change until your verify the new email.</p>
            <Small>Trouble finding the message? Check your spam folder.</Small>
            <Small>Typo? Enter the correct email and try again.</Small>
          </SuccessMessage>
        : null}
      <Form
        formId={formName}
        entitySchema={schema}
        formBody={body}
        initialValues={user as unknown as EntityObject}
        onSubmitCallback={onSubmit}
        FormRenderer={FormRenderer}
        subscribeToFormStatus={subscribeToFormStatus}
        formOptions={{ RootFormRenderer: FormRenderer }}
        {...experimentFormResolvers}
      />
      <SubmitButton
        type="submit"
        isLoading={formStatus === 'processing'}
        form={formName}
        label="Update Email"
        color="forward"
        size="medium"
      />
      <HR />
      <LogoutButton isDisabled={formStatus === 'processing' } onClick={logOut} label="Log Out" color="caution" />
      <Footnote>
        If you would like to close your account, contact support by calling {TEACHING_HELP_PHONE_NUMBER} or emailing <Link reloadDocument to={TEACHING_HELP_URL}>{TEACHING_HELP_EMAIL}</Link>
        <br />
        Account type: {accountTypeString}
      </Footnote>
    </>
  }

export default AccountSettings
