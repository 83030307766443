import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { DashedLine, AlertText, HeadingOne, SubheadingOne } from '~/components/atoms'
import CardFooter from '~/components/molecules/CardFooter'

interface DialogueContainerProps {
  footerContent: React.ReactNode
  heading?: string
  subheading?: string
  body?: React.ReactNode
  className?: string
}
const
  DialogueContainerOuter = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    overflow: visible;

    padding: ${p => p.theme.emBaseSpacing * 1.5}em; // 37 to 40px
    padding-bottom: 0;
    border-radius: ${p => p.theme.pxBorderRadius * 1.5}px; // 10px
    background: ${p => p.theme.colors.bodyBg};
  `,
  FooterContentWrapper = styled(CardFooter)`
    margin-bottom: -20px;
    margin-top: ${p => p.theme.emBaseSpacing * 2}em;
  `,
  HeadingWrapper = styled(HeadingOne)`
    flex: 1 1 auto;
    line-height: 1;
  `,
  SubheadingWrapper = styled(SubheadingOne)`
    flex: 1 1 auto;
  `,
  BodyWrapper = styled(AlertText)`
    flex: 1 1 auto;
    text-align: left;
    white-space: pre-line;
  `,
  HR = styled(DashedLine)`
    margin-top: ${p => p.theme.emBaseSpacing * 1.15}em;
    margin-bottom: ${p => p.theme.emBaseSpacing * 1.25}em;
  `,
  DialogueContainer: React.FC<DialogueContainerProps> = ({ footerContent, heading, subheading, body, className }) => {
    const THEME = useTheme()

    return <DialogueContainerOuter className={className} data-cy="dialogue-container">
      {subheading
        ? <SubheadingWrapper>{subheading}</SubheadingWrapper>
        : null}
      {heading
        ? <HeadingWrapper>{heading}</HeadingWrapper>
        : null}
      {(heading && body) ?? (subheading && body)
        ? <HR color={THEME.colors.borderAlt} />
        : null}
      {body
        ? <BodyWrapper color="understate">{body}</BodyWrapper>
        : null}
      <FooterContentWrapper contentCenter={footerContent} />
    </DialogueContainerOuter>
  },
  SmallMessageContainer = styled(DialogueContainer)`
    width: ${p => p.theme.emMediumContentWidth}em;
    maxWidth: 100%;
  `

export default DialogueContainer
export { SmallMessageContainer }
