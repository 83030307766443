import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

export type IndependentVariableCategory = (
  typeof IndependentVariableCategories[keyof typeof IndependentVariableCategories]
)

const
  IndependentVariableCategories = {
    SEED: 'seed',
    PLANTING: 'planting',
    FERTILIZER: 'fertilizer',
    IRRIGATION: 'irrigation',
    OTHER: 'other'
  } as const,
  title = 'Independent variable category',
  description = 'This can be used to narrow down the list of suggested independent variables in a fill-in-the-blank format.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.independentVariableCategory,
    fieldType: 'ENUM',
    invalidates: [SystemFieldNames.independentVariable],
    options: [
      { optionValue: IndependentVariableCategories.SEED },
      { optionValue: IndependentVariableCategories.PLANTING },
      { optionValue: IndependentVariableCategories.FERTILIZER },
      { optionValue: IndependentVariableCategories.IRRIGATION },
      {
        optionValue: IndependentVariableCategories.OTHER,
        optionLabel: '(other cultivation practice)'
      }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.independentVariableCategory,
    variableGroup: SystemFieldGroups.QUESTION,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails,
  IndependentVariableCategories
}
