import * as React from 'react'
import styled from 'styled-components'
import { outlineRatio } from '~/components/atoms/Icons/iconConstants'

interface IconTextProps {
  text: string
  textColor: string
  pxFontSize: number
  strokeColor?: string
  className?: string
}

const
  Text = styled.text`
    ${p => p.theme.headingFont}
  `,
  IconText: React.FC<IconTextProps> = ({ text, textColor, pxFontSize, strokeColor = null, className }) => {
    const
      numExtraCharacters = text.length - 1,
      adjustedFontSize = pxFontSize * Math.max(1 - (numExtraCharacters * 0.2), 0.4),
      strokeProps = {
        stroke: strokeColor ?? 'none',
        paintOrder: 'stroke',
        strokeWidth: `${pxFontSize * outlineRatio}px`
      }

    return (
      <Text
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={`${adjustedFontSize}px`}
        className={className}
        data-cy="icon-text"
        fill={textColor}
        {...(strokeColor ? strokeProps : {})}>
        {text}
      </Text>
    )
  }

export default IconText
