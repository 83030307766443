import * as React from 'react'
import { groupBy, values } from 'lodash'
import styled from 'styled-components'
import { ExperimentHelpers, WorksheetHelpers } from '~/features'
import { useAppSelector } from '~/hooks'
import { selectExperiments, selectLearners, selectWorksheetResponses, selectWorksheets } from '~/features/entity/selectors'
import { CheckmarkIcon, Icon, Button, BasicTriangle } from '~/components/atoms'
import { CardHeader } from '~/components/molecules'

import type { Experiment, ProjectStatus } from '~/features'

const
  ListItem = styled(CardHeader)`
    margin-bottom: 1em;
    box-shadow: 1px 1.5px 4px 0px ${p => p.theme.colors.shadow};
  `,
  LeftButton = styled(Button)`
    align-self: flex-end;
    margin-bottom: ${p => p.theme.emBaseSpacing}em;
  `,
  iconByStatus = {
    ok: <Icon color="forward" frameStyle="circle" degreesRotation={90} content={BasicTriangle} accessibleDescription="No action needed" />,
    closed: <Icon color="disabled" frameStyle="circle" degreesRotation={90} content={BasicTriangle} accessibleDescription="Project has not started" />,
    nonurgent: <Icon color="forward" frameStyle="circle" content="?" accessibleDescription="Response(s) available to review" />,
    urgent: <Icon color="attention" frameStyle="circle" content="?" accessibleDescription="Response or request needs review" />,
    finished: <CheckmarkIcon accessibleDescription="Project has ended" color="understate" />
  },
  ProjectList: React.FC = () => {
    const
      [showInactive, setShowInactive] = React.useState<boolean>(false),
      experimentsById = useAppSelector(selectExperiments),
      learnersById = useAppSelector(selectLearners),
      responsesById = useAppSelector(selectWorksheetResponses),
      worksheetsById = useAppSelector(selectWorksheets),

      experiments = values(experimentsById),
      learnersByExperiment = groupBy(values(learnersById), 'experimentId'),
      responsesByExperiment = groupBy(values(responsesById), response => response.experimentId),
      inactiveExperiments = experiments.filter(o => o.closedAt),
      activeExperiments = experiments.filter(o => !o.closedAt),

      statusByExperiment = activeExperiments.reduce<Record<string, ProjectStatus>>(
        (accumulator, currentValue) => {
          accumulator[currentValue.id] = (
            currentValue.closedAt
              ? 'finished'
              : !currentValue.startedAt
                  ? 'closed'
                  : ExperimentHelpers.getStatusFromResponses(
                    (responsesByExperiment[currentValue.id] || []).filter(o => WorksheetHelpers.getDoesWorksheetRequireResponse(worksheetsById[o.id])),
                    experimentsById[currentValue.id]
                  )
          )
          return accumulator
        },
        {}
      ),
      getListItemForExperiment = (experiment: Experiment): React.ReactNode => {
        const
          learners = learnersByExperiment[experiment.id] ?? [],
          subheading = ExperimentHelpers.getTimingString(experiment),
          content = learners.length
            ? learners.map(learner => learner.displayId).join(', ')
            : undefined

        return (
          <ListItem
            key={experiment.id}
            icon={iconByStatus[statusByExperiment[experiment.id] ?? 'finished']}
            heading={experiment.name}
            subheading={subheading}
            accessibleDescription={`Click to go to ${experiment.name}`}
            linkProps={{ to: `/projects/${experiment.id}` }}
            content={content}
          />
        )
      }

    return <>
      {activeExperiments.map(experiment => getListItemForExperiment(experiment))}
      {!inactiveExperiments.length
        ? null
        : showInactive
          ? <>
            <LeftButton size="small" color="back" label="Hide inactive groups" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShowInactive(false) }} />
            {inactiveExperiments.map(experiment => getListItemForExperiment(experiment))}
          </>
          : <>
            <LeftButton size="small" color="back" label="Show inactive groups" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShowInactive(true) }} />
          </>}
    </>
  }

export default ProjectList
