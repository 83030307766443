import { findIndex, findLast, pick } from 'lodash'
import { getSubjectList } from '~/features/experiment/fieldSelectors/treatmentSelectors'

import type { EntityObject } from '~/form-brain2'
import type { FieldName } from '~/types/utilities'
import type { ListEntry, RepeatingSectionElement, SpecialListSectionElement, SpecialSectionId } from '~/features'

const
  carryOverFieldsBySpecialSectionId: Record<SpecialSectionId, FieldName[]> = {
    PLAN_ACTIVITY: ['isRepeating'],
    PLAN_MEASUREMENT: [],
    MATERIAL: [],
    SET_UP_STEP: [],
    MEASUREMENT_RECORD: ['date', 'measurementSubject'],
    ACTIVITY_RECORD: ['date'],
    OBSERVATION_RECORD: ['date'],
    // DATA_VISUALIZATION: [],
    STATS_TEST: [],
    SUMMARY_STATS: []
  },
  getCarryOverFields = (section: RepeatingSectionElement): FieldName[] => {
    if (section.carryOverFields) {
      return section.carryOverFields
    }

    const specialSectionId = (section as SpecialListSectionElement).specialSectionId

    if (specialSectionId) {
      return carryOverFieldsBySpecialSectionId[specialSectionId]
    }

    return []
  },
  getNewEntryInitialValues = (section: RepeatingSectionElement, entries: ListEntry[], otherEntities: EntityObject[]): ListEntry => {
    const
      previousEntry = findLast(entries, o => o.sectionId === section.id),
      carryOverFields = getCarryOverFields(section),
      newEntry: ListEntry = {
        sectionId: section.id,
        ...(previousEntry
          ? pick(previousEntry, carryOverFields)
          : {})
      }

    if (newEntry.measurementSubject) {
      const
        subjectList = getSubjectList(otherEntities),
        prevSubjectIndex = findIndex(subjectList, o => o.optionValue === newEntry.measurementSubject)

      newEntry.measurementSubject = subjectList[(prevSubjectIndex + 1) % subjectList.length].optionValue
    }

    return newEntry
  }

export default getNewEntryInitialValues
