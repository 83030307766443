import * as React from 'react'
import styled from 'styled-components'
import { cloneDeep, pullAt, uniq } from 'lodash'
import { isAllEmpty } from '~/utils/testers'
import { formatValueForInput, inputIdFromBodyElement } from '~/utils/formatters'
import { BaseInput, BasicCross, Icon, WithUnderline } from '~/components/atoms'
import FormFieldWrapper from '~/components/molecules/FormElements/FormFieldWrapper'
import { compoundInputWrapperStyles } from './CompoundInputWrapper'
import Select from './Select'
import Combobox from './Combobox'

import type { AttrVal, AttrValArray, BodyElement } from '~/form-brain2'
import type { InputProps } from '~/types/forms'
import type { MultiTextInput } from '~/features'

const
  Wrapper = styled.div`
    ${compoundInputWrapperStyles}
    row-gap: ${p => p.theme.emSmallSpacing}em;
    column-gap: 0px;
  `,
  AdderIcon = styled(Icon)`
    margin-left: ${p => p.theme.emSmallSpacing}em;
    width: 27px;
  `,
  StyledInput = styled(BaseInput)`
    flex: 1 0 auto;
  `,
  RemoverIcon = styled(Icon)`
    width: 0.7em;
    flex: 0 0 0.7em;
  `,
  RemoverIconOuter = styled.div<{ $hasError?: boolean }>`
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-right: ${p => p.theme.emSmallSpacing}em;
    margin-left: ${p => p.theme.emSmallSpacing / 2}em;

    &:not(:hover) {
      .list-maker-remover-icon-loud {
        display: none;
        transform: scale(0);
      }
    }

    &:hover {
      .list-maker-remover-icon-quiet {
        display: none;
      }

      .list-maker-remover-icon-loud {
        transform: scale(2.7);
      }
    }
  `,
  ListMakerRemover: React.FC<{ onClick: React.MouseEventHandler, hasError?: boolean }> = ({ onClick, hasError }) => {
    return <RemoverIconOuter data-cy="list-maker-remover" $hasError={hasError}>
      <RemoverIcon content={BasicCross} degreesRotation={45} color="understate" frameStyle="plain" onClick={onClick} className="list-maker-remover-icon-quiet" />
      <RemoverIcon content={BasicCross} degreesRotation={45} color="caution" onClick={onClick} className="list-maker-remover-icon-loud" />
    </RemoverIconOuter>
  },
  ListMaker: React.FC<InputProps & { className?: string }> = (inputProps) => {
    const
      { name, updateAttr, currentValue, className, isReadOnly, isDisabled, pathToAttr, list, placeholder } = inputProps,
      bodyElement = inputProps.bodyElement as MultiTextInput,
      { allowDuplicates } = bodyElement,
      inputId = inputIdFromBodyElement(bodyElement, pathToAttr),
      currentValues: AttrValArray | AttrVal = !currentValue
        ? ['']
        : Array.isArray(currentValue)
          ? currentValue
          : [currentValue],
      wrapperInputProps = !allowDuplicates && uniq(currentValues).length !== currentValues.length
        ? {
            ...inputProps,
            errors: [...(inputProps.errors ?? []), 'cannot contain duplicates'],
            showError: true
          }
        : inputProps,
      addValue = (): void => {
        updateAttr([...currentValues, ''])
      },
      updateValue = (i: number) => (newValue: AttrVal | AttrValArray) => {
        const newValues = cloneDeep(currentValues)
        newValues[i] = Array.isArray(newValue) ? newValue[0] : newValue /** This is just some BS for typing */

        updateAttr(newValues)
      },
      removeValue = (i: number) => () => {
        const newValues = cloneDeep(currentValues)
        pullAt(newValues, [i])

        updateAttr(newValues)
      }

    return <FormFieldWrapper className={className} inputId={inputId} inputProps={wrapperInputProps} noWrapper>
      <Wrapper
        id={inputId}
        role="listbox"
        aria-multiselectable
        aria-readonly={isReadOnly}
        data-cy="list-maker">
        {currentValues.map((value, i) => {
          const
            hasError = !allowDuplicates && currentValues.some((s, j) => s === value && j !== i),
            customList = (list ?? []).map((o, j) => ({
              ...o,
              disabled: !!o.disabled || (!allowDuplicates && o.value !== value && currentValues.includes(o.value))
            })),
            commonProps: React.ComponentProps<typeof Select> = {
              ...inputProps,
              showHint: false,
              showLabel: false,
              showError: true,
              list: customList,
              errors: hasError ? wrapperInputProps.errors : undefined,
              currentValue: value,
              updateAttr: updateValue(i),
              pathToAttr: `${pathToAttr}.${i}`,
              bodyElement: { ...bodyElement, naturalWidth: true } as unknown as BodyElement,
              name: `${name}[${i}]`,
              bare: true
            }

          return [
            isAllEmpty(customList)
              ? <WithUnderline key={commonProps.pathToAttr}>
                  <StyledInput
                    name={commonProps.name}
                    type="text"
                    $hasError={hasError}
                    id={`${inputId}-${i}`}
                    value={value ? formatValueForInput(value as unknown as AttrVal, 'TEXT_INPUT') : undefined}
                    onChange={e => { updateValue(i)(e.currentTarget.value) }}
                    readOnly={isReadOnly}
                    disabled={isDisabled}
                    placeholder={isReadOnly ? '(Blank)' : placeholder}
                    data-cy="text-input"
                  />
                </WithUnderline>
              : bodyElement.customOption
                ? <Combobox key={commonProps.pathToAttr} {...commonProps} />
                : <Select key={commonProps.pathToAttr} {...commonProps} />,
            isReadOnly
              ? null
              : <ListMakerRemover key={`remover-${i}`} onClick={removeValue(i)} hasError={hasError} />
          ]
        })}
        {isReadOnly
          ? null
          : <AdderIcon content={BasicCross} onClick={addValue} className="list-maker-adder" />}
      </Wrapper>
    </FormFieldWrapper>
  }

export default ListMaker
