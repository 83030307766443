import * as React from 'react'
import styled, { css } from 'styled-components'
import { Link, type LinkProps } from 'react-router-dom'
import {
  pxMinTabWidth,
  pxTabHorizontalPadding,
  pxIconWidth,
  textColorKeys,
  backgroundColorKeys,
  iconColorLookup,
  remTabHeight
} from '~/components/atoms/Tab/tabConstants'
import type { TabRole, TabIconColor } from '~/components/atoms/Tab/tabConstants'
import { useClickOnKeyPress } from '~/hooks'

interface CoreProps {
  label?: string
  IconComponent?: React.ComponentType<{ color?: TabIconColor }>
  tabRole?: TabRole
  className?: string
}

interface OnClickProps {
  accessibleDescription: string
  onClick: React.MouseEventHandler<HTMLDivElement>
}

interface WithLinkProps {
  accessibleDescription: string
  linkProps: LinkProps
}

type TabProps = CoreProps | (CoreProps & OnClickProps) | (CoreProps & WithLinkProps)

interface TabChildStyleProps {
  $tabRole: TabRole
}

const
  outerStyles = css<TabChildStyleProps>`
    flex: 0 ${p => p.$tabRole === 'active' ? 0 : 1} auto;
    overflow: hidden;
    min-width: ${pxMinTabWidth}px;
    height: ${remTabHeight}rem; // (43px (Figma height) - 6px (Figma overlap)) / 15px (base font size)

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: ${pxTabHorizontalPadding}px;

    border-radius: 7.5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 0px ${pxTabHorizontalPadding}px;
    user-select: none;
    
    background: ${p => p.theme.colors[backgroundColorKeys[p.$tabRole]]};
  `,
  TabOuter = styled.div`
     ${outerStyles as any /* this is just to suppress a type error */}
     cursor: ${p => p.onClick ? 'pointer' : 'auto'};
  `,
  TabOuterLink = styled(Link)`
    ${outerStyles as any /* this is just to suppress a type error */}
    cursor: pointer;
  `,
  TextWrapper = styled.div<TabChildStyleProps>`
    flex: 0 1 auto;

    font-size: 1.15rem; // 17.3px / 15px
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    color: ${p => p.theme.colors[textColorKeys[p.$tabRole]]};
    ${p => p.theme.headingFont};
  `,
  IconWrapper = styled.div`
    flex: 0 0 auto;
    width: ${pxIconWidth}px;
    margin-bottom: 1px;  // From figma, to visually compensate for drop shadow in centering
  `,
  Tab: React.FC<TabProps> = ({ className, label, tabRole = 'inactive', IconComponent, ...otherProps }) => {
    const
      title = 'accessibleDescription' in otherProps ? otherProps.accessibleDescription : undefined,
      elementRef = useClickOnKeyPress(),
      sharedProps = {
        $tabRole: tabRole,
        title: title ?? label,
        role: 'tab',
        'data-cy': 'tab',
        'aria-label': title,
        'aria-selected': tabRole === 'active' ? true : tabRole === 'inactive' ? false : undefined,
        ...(tabRole === 'disabled' ? { disabled: true } : {}),
        className
      },
      content = <>
        {IconComponent
          ? <IconWrapper>
            <IconComponent color={iconColorLookup[tabRole]}/>
          </IconWrapper>
          : null}
        {label
          ? <TextWrapper $tabRole={tabRole}>
            {label}
          </TextWrapper>
          : null}
      </>

    if ('linkProps' in otherProps) {
      return (
        <TabOuterLink
          {...sharedProps}
          {...otherProps.linkProps}>
          {content}
        </TabOuterLink>
      )
    } else {
      return (
        <TabOuter
          {...sharedProps}
          ref={'onClick' in otherProps ? elementRef : undefined}
          tabIndex={'onClick' in otherProps ? 0 : -1}
          onClick={'onClick' in otherProps ? otherProps.onClick : undefined}>
          {content}
        </TabOuter>
      )
    }
  }

export type { TabRole, TabIconColor }
export default Tab
