import * as React from 'react'
import { isEqual, sortBy } from 'lodash'
import { isEmptyValue } from '~/utils/testers'
import { checkConditions } from '~/utils/conditions'
import { formDefaultValidateObject } from '~/utils/validators'
import { getDynamicString } from '~/features/experiment/helpers'
import { getSubjectList } from '~/features/experiment/fieldSelectors/treatmentSelectors'
import { allFieldsFromFormBody, allFieldsFromQuestionnaire } from '../questionnaire/helpers'
import { interpolateString } from './calculatedValues'

import type {
  FormProps,
  EntityObject,
  FormOnAttrChangeFn,
  ContextAndResolvers,
  ListValue
} from '~/form-brain2'
import type { AnyInputElement, InputElement, MultiTextInput, SelectInput } from '~/features/questionnaire/types/inputElementTypes'
import type { ListOption } from '~/features/questionnaire/types/fieldTypes'
import type { DynamicLabels } from '~/features/experiment/types'
import type { CollectionElement, RepeatingSectionElement, SpecialListSectionElement } from '../questionnaire/types'
import { formBodyForSpecialSection } from './specialSections'

const
  addEmphasisToString = (rawString: string | undefined): Array<string | JSX.Element> | undefined => {
    if (!rawString) { return undefined }

    const
      splitString: string[] = rawString.split('**'),
      initialEmphasis = rawString.slice(0, 2) === '**'

    return splitString.map((segment, i) => (
      (initialEmphasis ? i + 1 : i) % 2
        ? <strong key={`strong-${i}`}>{segment}</strong>
        : segment
    ))
  },
  interpolateStringInForm = (testString: string | undefined, contextAndResolvers: ContextAndResolvers): string | undefined => {
    const
      { formOptions, values } = contextAndResolvers,
      { otherEntities, permitMissingInterpolation } = formOptions

    // Current values goes first so that it will be tested before a stale version
    return interpolateString(testString, [values, ...(otherEntities ?? [])], permitMissingInterpolation)
  },
  resolveKey: FormProps['resolveKey'] = ({ bodyElement /*, contextAndResolvers */ }) => {
    return (bodyElement as InputElement).fieldName
  },
  resolveLabel: FormProps['resolveLabel'] = ({ bodyElement, contextAndResolvers /*, schemaEntry */ }) => {
    return interpolateStringInForm((bodyElement as InputElement).label, contextAndResolvers)
  },
  resolveHint: FormProps['resolveHint'] = ({ bodyElement, contextAndResolvers /*, schemaEntry */ }) => {
    return interpolateStringInForm((bodyElement as InputElement).hint, contextAndResolvers)
  },
  resolveUnit: FormProps['resolveUnit'] = ({ bodyElement, contextAndResolvers /*, schemaEntry */ }) => {
    return interpolateStringInForm((bodyElement as InputElement).unit, contextAndResolvers)
  },
  resolvePlaceholder: FormProps['resolvePlaceholder'] = (props) => {
    const
      { placeholder, elementVariety } = props.bodyElement as InputElement,
      { customPlaceholder } = props.bodyElement as SelectInput,
      dynamicLabels: Partial<DynamicLabels> = props.contextAndResolvers.formOptions.dynamicLabels ?? {}

    if (placeholder) { return interpolateStringInForm(placeholder, props.contextAndResolvers) }
    if (customPlaceholder) { return interpolateStringInForm(customPlaceholder, props.contextAndResolvers) }

    return elementVariety === 'LONG_ANSWER'
      ? getDynamicString({ dynamicLabels }, 'longTextPlaceholder')
      : elementVariety === 'SHORT_ANSWER' || elementVariety === 'TEXT_INPUT'
        ? getDynamicString({ dynamicLabels }, 'shortTextPlaceholder')
        : elementVariety === 'NUMBER_INPUT'
          ? getDynamicString({ dynamicLabels }, 'numberPlaceholder')
          : elementVariety === 'SELECT_INPUT'
            ? getDynamicString({ dynamicLabels }, 'selectPlaceholder')
            : undefined
  },
  resolveList: FormProps['resolveList'] = ({ bodyElement, schemaEntry, contextAndResolvers }) => {
    let elementOptions = (bodyElement as SelectInput).list ?? schemaEntry?.options

    // if (bodyElement.fieldName === 'fertilizerRateUnit') {
    //   console.log('resolveList', { elementOptions, schemaEntry, bodyElement })
    // }

    if ((bodyElement as InputElement).fieldName === 'measurementSubject') {
      elementOptions = getSubjectList(
        contextAndResolvers.formOptions.otherEntities as EntityObject[],
        contextAndResolvers.formOptions.experiment
      )
    }

    if ((bodyElement as InputElement).fieldName === 'parentWorksheetId') {
      const
        allWorksheets = contextAndResolvers.formOptions.worksheetsThisExperiment ?? [],
        currentWorksheet = contextAndResolvers.values,
        otherHubWorksheets = allWorksheets.filter(o => o.id !== currentWorksheet.id && o.format === 'HUB')

      elementOptions = otherHubWorksheets.map(w => ({
        optionValue: w.id,
        optionLabel: w.name
      }))
    }

    if ((bodyElement as InputElement).fieldName === 'parentTemplateId') {
      const
        allTemplates = contextAndResolvers.formOptions.templatesThisSet ?? [],
        currentTemplate = contextAndResolvers.values,
        otherHubTemplates = allTemplates.filter(o => o.id !== currentTemplate.id && o.format === 'HUB')

      elementOptions = otherHubTemplates.map(w => ({
        optionValue: w.id,
        optionLabel: w.name
      }))
    }

    if ((bodyElement as SelectInput).fieldName === 'entryLabelField') {
      const
        section = contextAndResolvers.values as unknown as RepeatingSectionElement | SpecialListSectionElement,
        fields = allFieldsFromFormBody(
          'specialSectionId' in section && section.specialSectionId
            ? formBodyForSpecialSection(section)
            : section.body
        )

      elementOptions = fields.reduce<ListOption[]>((memo, { fieldName, description, inputVariety }) => {
        /** If you change the array below, update the hint for the entryLabelField field */
        if (inputVariety && ['TEXT_INPUT', 'NUMBER_INPUT', 'SHORT_ANSWER', 'LONG_ANSWER'].includes(inputVariety)) {
          return [
            ...memo,
            {
              optionValue: fieldName,
              optionLabel: `${fieldName} "${description}"`
            }
          ]
        } else {
          return memo
        }
      }, [])
    }

    if ((bodyElement as SelectInput).fieldName === 'sortByField') {
      const
        section = contextAndResolvers.values as unknown as RepeatingSectionElement | SpecialListSectionElement,
        fields = allFieldsFromFormBody(
          'specialSectionId' in section && section.specialSectionId
            ? formBodyForSpecialSection(section)
            : section.body
        )

      elementOptions = fields.reduce<ListOption[]>((memo, { fieldName, description, inputVariety }) => {
        /** If you change the array below, update the hint for the sortByField field */
        if (inputVariety && ['TEXT_INPUT', 'NUMBER_INPUT', 'DATE_INPUT', 'SELECT_INPUT', 'CHECKBOX_INPUT', 'SHORT_ANSWER', 'LONG_ANSWER'].includes(inputVariety)) {
          return [
            ...memo,
            {
              optionValue: fieldName,
              optionLabel: `${fieldName} "${description}"`
            }
          ]
        } else {
          return memo
        }
      }, [])
    }

    if ((bodyElement as MultiTextInput).fieldName === 'invalidates') {
      const
        parentBodyElement = contextAndResolvers.values as unknown as AnyInputElement,
        allQuestionnaires = contextAndResolvers.formOptions.worksheetsThisExperiment,
        currentQuestionnaireId = contextAndResolvers.formOptions.currentWorksheetId,
        questionnaire = allQuestionnaires && currentQuestionnaireId
          ? allQuestionnaires.find(o => o.id === currentQuestionnaireId)
          : undefined,
        fields = questionnaire ? allFieldsFromQuestionnaire(questionnaire) : []

      elementOptions = fields.map(({ fieldName, description, inputVariety }) => ({
        optionValue: fieldName,
        optionLabel: `${fieldName} "${description}"`
      })).filter(o => o.optionValue !== parentBodyElement.fieldName)
    }

    /** Should resolve differently for collection element */
    if ((bodyElement as SelectInput).fieldName === 'carryOverFields' && (contextAndResolvers.values as unknown as RepeatingSectionElement).elementVariety === 'LIST_ENTRY_SECTION') {
      const
        section = contextAndResolvers.values as unknown as RepeatingSectionElement | SpecialListSectionElement,
        fields = allFieldsFromFormBody(
          'specialSectionId' in section && section.specialSectionId
            ? formBodyForSpecialSection(section)
            : section.body
        )

      elementOptions = fields.map(({ fieldName, description, inputVariety }) => ({
        optionValue: fieldName,
        optionLabel: `${fieldName} "${description}"`
      }))
    } else if ((bodyElement as SelectInput).fieldName === 'carryOverFields') {
      const
        bodyElementBeingBuilt = contextAndResolvers.values as unknown as Partial<CollectionElement>,
        collectionBody = bodyElementBeingBuilt.formBody,
        fields = collectionBody ? allFieldsFromFormBody(collectionBody) : []

      elementOptions = fields.map(({ fieldName, description, inputVariety }) => ({
        optionValue: fieldName,
        optionLabel: `${fieldName} "${description}"`
      }))
    }

    if (!elementOptions) return []

    const
      { extraOption, noSort, blankOption, missingValueLabel } = bodyElement as SelectInput,
      valueKey = contextAndResolvers.resolveKey({ bodyElement, contextAndResolvers }),
      currentValue = contextAndResolvers.values[valueKey],
      baseOptions = (elementOptions as Array<string | ListOption>).map(el => {
        if (typeof el === 'string') {
          return ({
            value: el,
            label: el
          })
        } else {
          return {
            value: el.optionValue,
            label: el.optionLabel ?? el.optionValue,
            disabled: checkConditions({ contextAndResolvers, conditions: el.disabledWhen, fallback: false, callerScopeValues: contextAndResolvers.values }),
            hidden: !checkConditions({ contextAndResolvers, conditions: el.visibleWhen, fallback: true, callerScopeValues: contextAndResolvers.values })
          }
        }
      }),
      extraOptions = extraOption
        ? [{ value: extraOption, label: extraOption }]
        : [],
      sortedOptions = noSort
        ? [...baseOptions, ...extraOptions]
        : sortBy([...baseOptions, ...extraOptions], 'label'),
      missingOption = !isEmptyValue(currentValue) && missingValueLabel && !sortedOptions.find(o => o.value === currentValue)
        ? [{ value: currentValue as ListValue, label: missingValueLabel, disabledWhen: [{ conditionType: 'always' }] }]
        : [],
      blankOptions = blankOption
        ? [{ value: null as unknown as ListValue, label: blankOption }] // This has to be null since undefined will not be included in JSON, making it impossible to clear a value once chosen
        : []

    // if (bodyElement.fieldName === 'fertilizerRateUnit') {
    //   console.log('resolveList', { baseOptions, elementOptions, schemaEntry, bodyElement })
    // }

    return [
      ...blankOptions,
      ...sortedOptions,
      ...missingOption
    ]
  },
  resolveCriteria: FormProps['resolveCriteria'] = ({ bodyElement, contextAndResolvers, criteria, schemaEntry }) => {
    if (criteria === 'hintDefaultVisible') {
      return (bodyElement as InputElement)?.showHintAlways ?? false
    }

    if (criteria === 'isReadOnly' && (!!contextAndResolvers.formOptions.isReadOnly || !!contextAndResolvers.scopeOptions?.isReadOnly)) {
      return true
    }

    if (criteria === 'isVisible' && bodyElement.nonSummaryOnly && contextAndResolvers.formOptions.isSummary) {
      return false
    }

    if (criteria === 'isVisible' && (bodyElement as SelectInput).hideWhenListEmpty) {
      const list = contextAndResolvers.resolveList({ bodyElement, contextAndResolvers, schemaEntry })

      // eslint-disable-next-line eqeqeq
      if (!list || !list.some(o => o.value != undefined)) {
        return false
      }
    }

    const
      selfName = bodyElement
        ? resolveKey({ bodyElement, contextAndResolvers })
        : undefined,
      conditionsKey = ({
        isVisible: 'visibleWhen',
        isReadOnly: 'readOnlyWhen',
        isRequired: 'requiredWhen',
        isRecommended: 'recommendedWhen'
      } as const)[criteria],
      fallback = {
        isVisible: true,
        isReadOnly: false,
        isRequired: false,
        isRecommended: false
      }[criteria],
      conditionFromBody = conditionsKey in bodyElement
        ? (bodyElement as InputElement)[conditionsKey]
        : undefined,
      conditions = conditionFromBody ?? schemaEntry?.[conditionsKey]

    // if (bodyElement.elementVariety === 'FILL_IN_THE_BLANK') {
    // if (bodyElement.fieldName === 'responsePageMascotVariant') {
    //   console.log('resolveCriteria', { bodyElement, contextAndResolvers, criteria, schemaEntry, conditionsKey, conditions })
    // }

    return checkConditions({
      contextAndResolvers,
      conditions,
      callerName: selfName,
      fallback,
      callerScopeValues: contextAndResolvers.values
    })
  },
  formOnAttrChange: FormOnAttrChangeFn = ({ bodyElement, nextValue, nextValues, contextAndResolvers }) => {
    if (!(bodyElement as InputElement).fieldName) { return nextValues }

    const
      { fieldName, invalidates: elementInvalidates } = bodyElement as InputElement,
      schemaEntry = contextAndResolvers.schema[fieldName] ?? {},
      invalidates = elementInvalidates ?? schemaEntry.invalidates,
      prevValue = contextAndResolvers.values[fieldName]

    if (invalidates && !isEqual(prevValue, nextValue)) {
      nextValues = invalidates.reduce<EntityObject>((memo, key) => {
        return key in nextValues
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          ? {
              ...memo,
              [key]: undefined
            } as EntityObject
          : memo
      }, nextValues)
    }

    return nextValues
  },
  experimentFormResolvers = {
    resolveKey,
    resolveCriteria,
    resolveLabel,
    resolveList,
    resolveHint,
    resolveUnit,
    resolvePlaceholder,
    validateObject: formDefaultValidateObject,
    onAttrChangeCallback: formOnAttrChange
  } as const

export { experimentFormResolvers, interpolateStringInForm, addEmphasisToString }
