import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'
import { IRRIGATION_SCHEMES } from './irrigationScheme'

const
  title = 'Irrigation rate unit',
  description = 'The unit (of time or of volume per area) for the irrigation rate value',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.irrigationRateUnit,
    fieldType: 'STRING',
    options: [
      {
        optionValue: 'minutes',
        visibleWhen: [{
          valueName: SystemFieldNames.irrigationScheme,
          conditionType: 'equals',
          testValue: IRRIGATION_SCHEMES.DURATION
        }]
      },
      {
        optionValue: 'hours',
        visibleWhen: [{
          valueName: SystemFieldNames.irrigationScheme,
          conditionType: 'equals',
          testValue: IRRIGATION_SCHEMES.DURATION
        }]
      },
      {
        optionValue: 'gallons per bed',
        visibleWhen: [{
          valueName: SystemFieldNames.irrigationScheme,
          conditionType: 'equals',
          testValue: IRRIGATION_SCHEMES.VOLUME
        }]
      },
      {
        optionValue: 'quarts per container',
        visibleWhen: [{
          valueName: SystemFieldNames.irrigationScheme,
          conditionType: 'equals',
          testValue: IRRIGATION_SCHEMES.VOLUME
        }]
      }
    ],
    // readOnlyWhen: [
    //   {
    //     valueName: SystemFieldNames.irrigationScheme,
    //     conditionType: 'absent'
    //   }
    // ],
    visibleWhen: [
      // ...ivEqualityCondition(variableName, exclude),
      {
        valueName: SystemFieldNames.irrigationScheme,
        conditionType: 'notEquals',
        testValue: IRRIGATION_SCHEMES.SATURATION
      }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.irrigationRateUnit,
    variableGroup: SystemFieldGroups.CONTROL_VARIABLES,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
