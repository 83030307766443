import { keyBy } from 'lodash'
import { SystemFieldNames, SystemFieldGroups, getControlGroupTitle, getControlGroupDescription, INDEPENDENT_VARIABLES } from './systemFieldConstants'
import { fieldDetails as irrigationMethodName } from './irrigationMethodName'

import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

export interface IrrigationTypeTreatmentFormat {
  irrigationMethodName: string
}

const
  independentVariable = INDEPENDENT_VARIABLES.IRRIGATION_TYPE,
  schemaEntry: QField = {
    fieldName: SystemFieldNames.irrigationTypeControlGroup,
    fieldType: 'SCOPE',
    visibleWhen: [{
      valueName: SystemFieldNames.independentVariable,
      conditionType: 'matches',
      testValue: independentVariable
    }],
    format: keyBy([
      irrigationMethodName.schemaEntry
    ], 'fieldName')
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.irrigationTypeControlGroup,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    shouldPrecede: [SystemFieldNames.irrigationTypeTreatmentGroups],
    isRequired: false,
    title: getControlGroupTitle(independentVariable),
    description: getControlGroupDescription(independentVariable),
    controlGroupFor: independentVariable,
    schemaEntry
  }

export {
  fieldDetails
}
