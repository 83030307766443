import * as React from 'react'
import styled from 'styled-components'
import { Button } from '~/components/atoms'
import { BlurbBuilder, ErrorMessage } from '~/components/molecules'
import { addEmphasisToString, interpolateString, WorksheetResponseHelpers } from '~/features'
import { isEmptyValue } from '~/form-brain2/testers'
import { useLearnerStepContext, useUpdateEntity } from '~/hooks'
import { getBaseErrorString } from '~/utils/formatters'

import type { EntityObject } from '~/form-brain2'
import type { ListWorksheet, WalkthroughStep, ListResponse, WalkthroughCheckpoint } from '~/features'

interface Props {
  checkpoint: WalkthroughCheckpoint
  entities: EntityObject[]
  response: ListResponse
  walkthroughSectionId: string
  currentStop?: 'intro' | 'body' | 'outro'
}

const
  CheckpointButton = styled(Button)`
    align-self: center;
  `,
  IndependentCheckpoint: React.FC<Props> = ({ checkpoint, entities, response, currentStop, walkthroughSectionId }) => {
    const
      { updateEntity, errors, isUpdating } = useUpdateEntity(),
      { worksheet } = useLearnerStepContext(),
      walkthrough = (worksheet as ListWorksheet).walkthrough as WalkthroughStep[],
      { body, checkpointButtonLabel } = checkpoint,
      onCheckpointPass: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        const newValues = WorksheetResponseHelpers.updateWalkthroughProgress({
          currentStop,
          response,
          walkthroughSectionId,
          walkthrough
        })

        void updateEntity({
          entity: response,
          newValues
        })
      }

    return <>
      {body.map((bodyElement, i) => {
        const { paragraphs, ...restOfElement } = bodyElement

        return paragraphs
          ? <BlurbBuilder key={i} {...restOfElement} naturalWidth paragraphs={paragraphs.map(str => addEmphasisToString(interpolateString(str, entities)) ?? [str])} />
          : null
      })}
      {errors && !isEmptyValue(errors)
        ? <ErrorMessage>{getBaseErrorString(errors, 'Unable to update this response. Please try again and contact support if the problem persists.')}</ErrorMessage>
        : null}
      <CheckpointButton
        key="forward"
        size="large"
        color="forward"
        onClick={onCheckpointPass}
        isLoading={isUpdating}
        label={checkpointButtonLabel}
        withIcon="arrowR"
      />
    </>
  }

export default IndependentCheckpoint
