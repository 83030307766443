import * as React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { isEmptyValue } from '~/utils/testers'
import { WorksheetHelpers } from '~/features'
import { useLearnerStepData } from '~/hooks'
import { SizedLoadingIcon } from '~/components/atoms'
import type { QuestionnaireFormat, PlainResponse } from '~/features'

const
  initialRouteByStepFormat: Record<QuestionnaireFormat, string> = {
    PAGED: 'section/0/page/0',
    LIST: 'all',
    HUB: 'hub',
    DOWNLOADS: 'download'
  },
  LearnerStepLandingHandler: React.FC = () => {
    const
      { stepId } = useParams(),
      { worksheet, response } = useLearnerStepData(stepId as string)

    // React.useEffect(() => {
    //   if (!worksheet) {
    //     // noop - won't happen
    //   } else if (worksheet.format === 'PAGED') {
    //     const
    //       { mostRecentPageIndex, mostRecentSectionIndex } = (response as PlainResponse),
    //       startingPosition = isEmptyValue(mostRecentPageIndex)
    //         ? initialRouteByStepFormat.PAGED
    //         : WorksheetHelpers.getPathForPage(
    //           worksheet.id,
    //           mostRecentSectionIndex ?? 0,
    //           mostRecentPageIndex ?? 0
    //         )

    //     navigate(startingPosition, { replace: true })
    //   } else {
    //     navigate(initialRouteByStepFormat[worksheet.format], { replace: true })
    //   }
    // }, [worksheet, response])

    if (!worksheet || !response) {
      return <SizedLoadingIcon />
    } else if (response.eventLog.length) {
      return <Navigate to={'review'} replace />
    } else if (worksheet.format === 'PAGED') {
      const
        { mostRecentPageIndex, mostRecentSectionIndex } = (response as PlainResponse),
        startingPosition = isEmptyValue(mostRecentPageIndex)
          ? initialRouteByStepFormat.PAGED
          : WorksheetHelpers.getPathForPage(
            worksheet.id,
            mostRecentSectionIndex ?? 0,
            mostRecentPageIndex ?? 0
          )

      return <Navigate to={startingPosition} replace />
    } else {
      return <Navigate to={initialRouteByStepFormat[worksheet.format]} replace />
    }
  }

export default LearnerStepLandingHandler
