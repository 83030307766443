export const
  COLOR_CLASSES = ['forward', 'back', 'attention', 'hero', 'understate', 'disabled', 'caution'] as const,
  CALCULATED_VALUE_NAMES = [
    'containerType',
    'fertilizerDescription',
    'measurementUnitDescription',
    'dependentVariableResultDescription',
    'calculatedTotalNumberOfMeasurements',
    'sampleSize',
    'plantsPerSubjectGroup',
    'subjectGroupName',
    'calculatedSeedUnit',
    'seedsPerGroup',
    'calculatedSeedsPerPlant',
    'calculatedSeedsNeeded'
  ] as const
