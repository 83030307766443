import { keyBy } from 'lodash'
import { SystemFieldNames, SystemFieldGroups, getControlGroupTitle, getControlGroupDescription, INDEPENDENT_VARIABLES } from './systemFieldConstants'
import { fieldDetails as fertilizerRateValue } from './fertilizerRateValue'
import { fieldDetails as fertilizerRateUnit } from './fertilizerRateUnit'
import { fieldDetails as fertilizerRateArea } from './fertilizerRateArea'

import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'
import type { FERTILIZER_RATE_AREAS } from './fertilizerRateArea'

export interface FertilizerRateTreatmentFormat {
  fertilizerRateValue: number
  fertilizerRateUnit: string
  fertilizerRateArea: typeof FERTILIZER_RATE_AREAS[keyof typeof FERTILIZER_RATE_AREAS]
}

const
  independentVariable = INDEPENDENT_VARIABLES.FERTILIZER_RATE,
  schemaEntry: QField = {
    fieldName: SystemFieldNames.fertilizerRateControlGroup,
    fieldType: 'SCOPE',
    visibleWhen: [{
      valueName: SystemFieldNames.independentVariable,
      conditionType: 'matches',
      testValue: independentVariable
    }],
    format: keyBy([
      fertilizerRateValue.schemaEntry,
      fertilizerRateUnit.schemaEntry,
      fertilizerRateArea.schemaEntry
    ], 'fieldName')
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.fertilizerRateControlGroup,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    shouldPrecede: [SystemFieldNames.fertilizerRateTreatmentGroups],
    isRequired: false,
    title: getControlGroupTitle(independentVariable),
    description: getControlGroupDescription(independentVariable),
    controlGroupFor: independentVariable,
    schemaEntry
  }

export {
  fieldDetails
}
