import { fill, map, sortBy, upperFirst } from 'lodash'
import { core as ranCore } from 'ranjs'
import { isAllEmpty } from '~/form-brain2/testers'
import { getCalculatedValue, getValueAcrossEntities } from '~/features/experiment/calculatedValues'
import { SystemFieldNames, RANDOMIZATION_METHODS, SYSTEM_FIELDS } from '~/features/experiment/systemFields'

import type { EntityObject } from '~/form-brain2'
import type {
  FertilizerRateTreatmentFormat,
  FertilizerTimingTreatmentFormat,
  FertilizerTypeTreatmentFormat,
  IrrigationRateTreatmentFormat,
  IrrigationTimingTreatmentFormat,
  IrrigationTypeTreatmentFormat,
  PlantingDensityTreatmentFormat,
  SeedVarietyTreatmentFormat,
  CustomVariableTreatmentFormat,
  TreatmentKey,
  ControlTreatmentKey,
  RandomizationMethod
} from '~/features/experiment/systemFields'
import type { Experiment } from '~/features/experiment/types'
import type { ListOption } from '~/features/questionnaire/types'

type TreatmentObject = (
  FertilizerRateTreatmentFormat |
  FertilizerTimingTreatmentFormat |
  FertilizerTypeTreatmentFormat |
  IrrigationRateTreatmentFormat |
  IrrigationTimingTreatmentFormat |
  IrrigationTypeTreatmentFormat |
  CustomVariableTreatmentFormat |
  PlantingDensityTreatmentFormat |
  SeedVarietyTreatmentFormat
)

export interface SubjectObject {
  treatment: string
  replicateNumber: number
  subjectName: string
  rawTreatment: TreatmentObject
  treatmentIndex: number
  treatmentName?: string
  treatmentDescription?: string
  isControl?: boolean
  subjectId: number
  label: string
}

type TreatmentList = Array<{ treatment: string, rawTreatment: TreatmentObject, isControl?: boolean, treatmentIndex: number }>
type SubjectObjectListBeforeLabels = Array<Pick<SubjectObject, 'treatment' | 'treatmentName' | 'rawTreatment' | 'isControl' | 'treatmentIndex'>>

export const
  systemFieldDetailsArr = Object.values(SYSTEM_FIELDS),
  getTreatmentsKey = (entities: EntityObject[]): TreatmentKey => {
    const
      independentVariable = getValueAcrossEntities(SystemFieldNames.independentVariable, entities) as string | undefined,
      detailsForTreatments = independentVariable
        ? systemFieldDetailsArr.find(o => o.treatmentGroupsFor === independentVariable)
        : undefined

    if (detailsForTreatments) {
      return detailsForTreatments.fieldName as TreatmentKey
    } else {
      return SystemFieldNames.customVariableTreatmentGroups
    }
  },
  getControlTreatmentKey = (entities: EntityObject[]): ControlTreatmentKey => {
    const
      independentVariable = getValueAcrossEntities(SystemFieldNames.independentVariable, entities) as string | undefined,
      detailsForControlGroup = independentVariable
        ? systemFieldDetailsArr.find(o => o.controlGroupFor === independentVariable)
        : undefined

    if (detailsForControlGroup) {
      return detailsForControlGroup.fieldName as ControlTreatmentKey
    } else {
      return SystemFieldNames.customVariableControlGroup
    }
  },
  getFullTreatmentList = (entities: EntityObject[]): TreatmentList | undefined => {
    const
      treatmentsKey = getTreatmentsKey(entities),
      controlTreatmentKey = getControlTreatmentKey(entities),
      treatments = getValueAcrossEntities(treatmentsKey, entities, true) as unknown as TreatmentObject[],
      controlTreatment = getValueAcrossEntities(controlTreatmentKey, entities, true) as unknown as TreatmentObject

    if (isAllEmpty(treatments)) { return undefined }

    const allTreatments = [
      ...(controlTreatment ? [controlTreatment] : []),
      ...treatments
    ]

    return getTreatmentStrings(treatmentsKey, allTreatments).map((s, i) => ({
      treatment: s,
      treatmentIndex: i,
      rawTreatment: allTreatments[i],
      isControl: controlTreatment && i === 0
    }))
  },
  getTreatmentStrings = (key: TreatmentKey, treatments: TreatmentObject[]): string[] => {
    if (key === SystemFieldNames.seedVarietyTreatmentGroups) {
      return (treatments as SeedVarietyTreatmentFormat[]).map(o => o.seedVariety)
    }

    if (key === SystemFieldNames.plantingDensityTreatmentGroups) {
      return map(treatments as PlantingDensityTreatmentFormat[], o => (
        `${o.plantSpacingValue?.toString() ?? '?'} ${o.plantSpacingUnit} ${o.plantSpacingArea}`
      ))
    }

    if (key === SystemFieldNames.fertilizerTypeTreatmentGroups) {
      return (treatments as FertilizerTypeTreatmentFormat[]).map(o => o.fertilizerLabel)
    }

    if (key === SystemFieldNames.fertilizerRateTreatmentGroups) {
      return map(treatments as FertilizerRateTreatmentFormat[], o => (
        `${o.fertilizerRateValue?.toString() ?? '?'} ${o.fertilizerRateUnit} ${o.fertilizerRateArea}`
      ))
    }

    if (key === SystemFieldNames.fertilizerTimingTreatmentGroups) {
      return map(treatments as FertilizerTimingTreatmentFormat[], o => {
        if (SystemFieldNames.fertilizerTimingUnit in o) {
          const { fertilizerTimingReference, fertilizerTimingValue, fertilizerTimingUnit } = o

          return `${fertilizerTimingValue?.toString() ?? ''} ${fertilizerTimingUnit ?? ''} ${fertilizerTimingReference}`
        } else {
          return o.fertilizerTimingReference
        }
      })
    }

    if (key === SystemFieldNames.irrigationTypeTreatmentGroups) {
      return (treatments as IrrigationTypeTreatmentFormat[]).map(o => o.irrigationMethodName)
    }

    if (key === SystemFieldNames.irrigationRateTreatmentGroups) {
      return map(treatments as IrrigationRateTreatmentFormat[], o => {
        if (SystemFieldNames.irrigationRateValue in o) {
          const { irrigationRateValue, irrigationRateUnit } = o

          return `${irrigationRateValue.toString() ?? '?'} ${irrigationRateUnit}`
        } else {
          return o.irrigationScheme
        }
      })
    }

    if (key === SystemFieldNames.irrigationTimingTreatmentGroups) {
      return map(treatments as IrrigationTimingTreatmentFormat[], o => {
        const { irrigationTimingValue, irrigationTimingUnit, irrigationTimingCondition } = o

        return `${irrigationTimingValue?.toString() ?? '?'} ${irrigationTimingUnit} ${irrigationTimingCondition}`
      })
    }

    return (treatments as CustomVariableTreatmentFormat[]).map(o => o.customVariableTreatmentLabel)
  },
  getTreatmentObjects = (entities: EntityObject[], experiment?: Experiment): SubjectObjectListBeforeLabels => {
    const
      treatmentList = getFullTreatmentList(entities),
      blindness = getValueAcrossEntities(SystemFieldNames.blindnessMethod, entities) as string | undefined

    if (!treatmentList) { return [] }

    const treatmentObjects = blindness
      ? _generateTreatmentCodes(treatmentList, experiment?.randomnessSeed ?? 'guava')
      : treatmentList

    return treatmentObjects
  },
  getSubjectObjects = (entities: EntityObject[], experiment?: Experiment): SubjectObject[] => {
    const
      seed = experiment?.randomnessSeed ?? 'papayas',
      replicates = getValueAcrossEntities(SystemFieldNames.replicateCount, entities) as string | undefined,
      randomization = getValueAcrossEntities(SystemFieldNames.randomizationMethod, entities) as RandomizationMethod,
      treatmentObjects = getTreatmentObjects(entities, experiment)

    ranCore.seed(seed)

    const
      replicatedTreatments = treatmentObjects.flatMap(o =>
        fill(Array(parseInt(replicates ?? '1')), o).map((el, i) => ({
          ...el,
          replicateNumber: i + 1
        }))
      ),
      randomizedSubjects = randomization === RANDOMIZATION_METHODS.ASSIGN_RANDOMLY || randomization === RANDOMIZATION_METHODS.ASSIGN_RANDOM_BLOCK
        ? ranCore.shuffle(replicatedTreatments)
        : replicatedTreatments,
      blockedRandomizedSubjects = randomization === RANDOMIZATION_METHODS.ASSIGN_RANDOM_BLOCK
        ? sortBy(randomizedSubjects, 'replicateNumber')
        : randomizedSubjects,
      subjectLabel = getCalculatedValue('subjectGroupName', entities) ?? 'Subject'

    return blockedRandomizedSubjects.map((o, i) => {
      const
        name = `${upperFirst(subjectLabel)} ${(i + 1).toString()}`,
        treatment = 'treatmentName' in o ? o.treatmentName : o.treatment,
        treatmentDescription = randomization === RANDOMIZATION_METHODS.ASSIGN_RANDOM_BLOCK
          ? `Block ${o.replicateNumber.toString()}, Treatment ${treatment ?? ''}`
          : `Treatment ${treatment ?? ''}, Replicate ${o.replicateNumber.toString()}`

      return {
        ...o,
        subjectName: name,
        subjectId: i + 1,
        treatmentDescription,
        label: `${name}: ${treatmentDescription}`
      }
    })
  },
  getSubjectList = (entities: EntityObject[], experiment?: Experiment): ListOption[] => {
    const subjectObjects = getSubjectObjects(entities, experiment)

    return subjectObjects.map(o => ({
      optionValue: o.subjectId.toString(),
      optionLabel: o.label
    }))
  }

const
  LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  _generateTreatmentCodes = (treatmentList: TreatmentList, seed: string): SubjectObjectListBeforeLabels => {
    ranCore.seed(seed)

    const
      shuffledList = ranCore.shuffle(treatmentList),
      newLabels = treatmentList.map((s, i) => {
        let
          str = '',
          num = i + 1

        while (num > 0) {
          const remainder = (num - 1) % 26
          str = LETTERS[remainder] + str
          num = Math.floor((num - remainder) / 26)
        }

        return str
      })

    return newLabels.map((str, i) => {
      const originalObject = shuffledList[i]

      return {
        ...originalObject,
        treatmentName: str
      }
    })
  }
