import type { SpecialListSectionElement, AnyPlainOrInputElement } from '~/features/questionnaire/types'
import { MISC_DEFAULTS } from '~/utils/strings'

export interface MeasurementEntry {
  date: string
  measurementValue: string
  measurementSubject: string
}

export const
  getObservationRecordForm = (section: SpecialListSectionElement): AnyPlainOrInputElement[] => [
    {
      id: 'observation-1',
      elementVariety: 'DATE_INPUT',
      fieldName: 'date',
      label: section.whenLabel ?? MISC_DEFAULTS.whenLabel,
      requiredWhen: [{ conditionType: 'always' }]
    },
    {
      id: 'observation-2',
      elementVariety: 'LONG_ANSWER',
      fieldName: 'details',
      label: section.detailsLabel ?? MISC_DEFAULTS.detailsLabel,
      requiredWhen: [{ conditionType: 'always' }]
    }
  ],
  getActivityRecordForm = (section: SpecialListSectionElement): AnyPlainOrInputElement[] => [
    {
      id: 'activity-record-0',
      elementVariety: 'DATE_INPUT',
      fieldName: 'date',
      label: section.whenLabel ?? MISC_DEFAULTS.whenLabel,
      requiredWhen: [{ conditionType: 'always' }]
    },
    {
      id: 'activity-record-1',
      elementVariety: 'SHORT_ANSWER',
      fieldName: 'description',
      label: section.whatLabel ?? 'What did you do?',
      requiredWhen: [{ conditionType: 'always' }]
    },
    {
      id: 'activity-record-2',
      elementVariety: 'LONG_ANSWER',
      fieldName: 'details',
      label: section.detailsLabel ?? MISC_DEFAULTS.detailsLabel
    }
  ],
  getMeasurementRecordForm = (section: SpecialListSectionElement): AnyPlainOrInputElement[] => [
    {
      id: 'measurement-record-0',
      elementVariety: 'DATE_INPUT',
      fieldName: 'date',
      label: section.whenLabel ?? MISC_DEFAULTS.whenLabel,
      requiredWhen: [{ conditionType: 'always' }]
    },
    {
      id: 'measurement-record-1',
      elementVariety: 'SELECT_INPUT',
      fieldName: 'measurementSubject',
      label: section.subjectLabel ?? MISC_DEFAULTS.subjectLabel,
      noSort: true,
      requiredWhen: [{ conditionType: 'always' }]
    },
    {
      id: 'measurement-record-2',
      elementVariety: 'NUMBER_INPUT',
      fieldName: 'measurementValue',
      label: section.measurementLabel ?? MISC_DEFAULTS.measurementLabel,
      unit: '--measurementUnitDescription--',
      requiredWhen: [{ conditionType: 'always' }]
    },
    {
      id: 'measurement-record-3',
      elementVariety: 'LONG_ANSWER',
      fieldName: 'details',
      label: section.detailsLabel ?? MISC_DEFAULTS.detailsLabel
    }
  ]
