import React from 'react'
import { values } from 'lodash'
import useAddEntity from './useAddEntity'
import useAppSelector from './useAppSelector'
import { WorksheetResponseHelpers } from '~/features'
import { selectWorksheetResponses } from '~/features/entity/selectors'
import { wrapErrors } from '~/utils/errorMessages'

import type {
  Experiment,
  Learner,
  Worksheet,
  WorksheetResponse
} from '~/features'
import type { ResultObject } from '~/form-brain2'

interface StepData {
  isAdding?: boolean
  addWorksheetResponse: (worksheet: Worksheet, learner: Learner, experiment: Experiment) => (e?: MouseEvent) => Promise<ResultObject<WorksheetResponse>> | Promise<ResultObject>
}

const
  useAddWorksheetResponse = (): StepData => {
    const
      { addEntity, isAdding } = useAddEntity(),
      [stepError, setStepError] = React.useState<string | undefined>(),
      responsesById = useAppSelector(selectWorksheetResponses),
      addWorksheetResponse = (worksheet: Worksheet, learner: Learner, experiment: Experiment) => (e?: MouseEvent) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        const response = values(responsesById).find(o => o.worksheetId === worksheet.id)

        if (response) { return Promise.resolve({ payload: response } as ResultObject<WorksheetResponse>) } // eslint-disable-line @typescript-eslint/consistent-type-assertions

        return addEntity({
          values: WorksheetResponseHelpers.getDefaultEntity(worksheet, learner.id, experiment),
          type: 'worksheetResponse'
        }).catch((error) => {
          /* keep log */ console.log(error)
          setStepError('Unable to add a response for this step. Please try again later and contact support if the problem continues.')
          return Promise.resolve(wrapErrors([stepError as string]))
        })
      }

    return {
      isAdding,
      addWorksheetResponse
    }
  }

export default useAddWorksheetResponse
