import { fromPairs } from 'lodash'
import { jStat } from 'jstat'
import { floatString } from '~/utils/formatters'

import type { SummaryStatName } from '~/features/questionnaire/types/sectionElementTypes'

const
  pp = (val: number): string => floatString(val, { places: 1 }) ?? 'N/A',
  getSummaryStats = (values: Array<{ value: number, isOutlier?: boolean }>, includedStats: SummaryStatName[]): Record<SummaryStatName, string> => {
    const
      points = values.filter(v => !v.isOutlier).map(v => v.value),
      stats = fromPairs(includedStats.map(statName => {
        const val = statName === 'mean'
          ? pp(jStat.mean(points))
          : statName === 'SD'
            ? pp(jStat.stdev(points))
            : `${pp(jStat.min(points))} - ${pp(jStat.max(points))}`

        return [statName, val]
      })) as Record<SummaryStatName, string>

    return stats
  }

export default getSummaryStats
