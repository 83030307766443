import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import type { BasicIconProps, ColorClass } from '~/types/utilities'

export type IconColor = ColorClass
export type IconFrameStyle = 'plain' | 'circle' | 'outline'
export type IconContent = string | React.ComponentType<BasicIconProps>

type BackgroundColorName = 'forward' | 'back' | 'attention' | 'understateDark' | 'understateLight' | 'cautionStandaloneText'
type TextColorName = 'forwardText' | 'backText' | 'attentionText' | 'attention' | 'understateLight' | 'understateDark' | 'cautionText'
type StandaloneTextColorName = 'forwardStandaloneText' | 'backStandaloneText' | 'attentionStandaloneText' | 'attention' | 'understateLight' | 'understateDark' | 'cautionStandaloneText'

interface SharedOuterStyleTransientProps {
  $degreesRotation?: number
  $transformOrigin?: string
  $clickable?: boolean
}

export const
  ShadowedSvg = styled.svg`
    filter: drop-shadow(1px 1px 2.5px ${p => p.theme.colors.shadow});
  `,
  sharedOuterStyles = css<SharedOuterStyleTransientProps>`
    width: 100%;

    ${// Ridiculous spacing so linter doesn't remove all indentation
      p => (
        p.$degreesRotation
          ? `
              .icon-rotation-target {
                transform: rotate(${p.$degreesRotation}deg);
                transform-origin: ${p.$transformOrigin ?? 'center'}; 
              }
            `
          : ''
      )
    }

    cursor: ${p => p.$clickable ? 'pointer' : 'inherit'};

    :focus {
      outline: 2px solid ${p => p.theme.colors.borderAccentAlt};
    }
  `,
  StandardizedIconOuter = styled.div<SharedOuterStyleTransientProps>`${sharedOuterStyles}`,
  StandardizedIconOuterLink = styled(Link)<SharedOuterStyleTransientProps>`${sharedOuterStyles}`,
  pxIconBaseSize = 50,
  circleBorderRatio = 0.065,
  outlineRatio = 0.14,
  backgroundColorLookup: Record<IconColor, BackgroundColorName> = {
    forward: 'forward',
    back: 'back',
    attention: 'attention',
    hero: 'back',
    understate: 'understateDark',
    disabled: 'understateLight',
    caution: 'cautionStandaloneText'
  },
  textColorLookup: Record<IconColor, TextColorName> = {
    forward: 'forwardText',
    back: 'backText',
    attention: 'attentionText',
    hero: 'attention',
    understate: 'understateLight',
    disabled: 'understateDark',
    caution: 'cautionText'
  },
  standaloneTextColorLookup: Record<IconColor, StandaloneTextColorName> = {
    forward: 'forwardStandaloneText',
    back: 'backStandaloneText',
    attention: 'attentionStandaloneText',
    hero: 'attention',
    understate: 'understateDark',
    disabled: 'understateLight',
    caution: 'cautionStandaloneText'
  }
