import * as React from 'react'
import styled from 'styled-components'

interface BodyTextProps {
  className?: string
  children?: React.ReactNode
  understate?: boolean
}

interface BodyTextVariantProps {
  $understate?: boolean
}

const
  BodyTextOuter = styled.div<BodyTextVariantProps>`
    text-align: left;
    font-size: 1rem; // rem math: 30px / 2 / 15px
    color: ${p => p.$understate ? p.theme.colors.bodyUnderstateText : p.theme.colors.bodyText};
    ${p => p.theme.accentFont}
  `,
  BodyText: React.FC<BodyTextProps> = ({ className, children, understate }) => {
    return (
      <BodyTextOuter $understate={understate} className={className}>
        {children}
      </BodyTextOuter>
    )
  }

export default BodyText
