import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Container type',
  description = 'If plants will be grown in containers, what will those containers be? (For example: pots, buckets, crates)',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.containerType,
    fieldType: 'STRING'
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.containerType,
    variableGroup: SystemFieldGroups.WHAT_WHERE,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
