import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { isEmptyValue } from '~/utils/testers'
import HeadingThree from './HeadingThree'

interface FormLabelProps {
  htmlFor?: string
  label?: string
  showLabel?: boolean
  isRequired?: boolean
  isRecommended?: boolean
  asHeading?: boolean
  asText?: boolean
  className?: string
}

const
  Wrapper = styled.div`
    display: flex;
    flex: 0 1 auto;
    font-weight: bold;
    font-size: 1rem;
    max-width: 100%;
    color: ${p => p.theme.colors.bodyText}; // Needed for indicator etc.
  `,
  Content = styled.div`
    display: flex;
  `,
  LabelHeading = styled(HeadingThree)`
    font-size: 1.15rem;
  `,
  LabelText = styled.label`
    ${p => p.theme.bodyFont};
  `,
  NonLabelText = styled.div`
    ${p => p.theme.bodyFont};
    color: ${p => p.theme.colors.bodyText};
  `,
  Indicator = styled.abbr`
    margin-left: ${p => p.theme.emSmallSpacing / 3}em;
    text-decoration: none;
  `,
  FormLabel: React.FC<FormLabelProps> = ({ htmlFor, label, showLabel, isRequired, isRecommended, asHeading, asText, className }) => {
    const THEME = useTheme()

    if (!showLabel || isEmptyValue(label)) { return null }

    return <Wrapper data-cy="form-label">
      <Content className={className}>
        {asHeading
          ? <LabelHeading>{label}</LabelHeading>
          : asText
            ? <NonLabelText>{label}</NonLabelText>
            : <LabelText htmlFor={htmlFor}>{label}</LabelText>}
        {isRecommended && !isRequired && THEME.showFormFieldRequirednessIndicators
          ? <Indicator title='suggested'>†</Indicator>
          : null}
        {isRequired && THEME.showFormFieldRequirednessIndicators
          ? <Indicator title="required">*</Indicator>
          : null}
      </Content>
    </Wrapper>
  }

export default FormLabel
