import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { Form } from '~/form-brain2'
import { getBaseErrorString } from '~/utils/formatters'
import { selectBasisForTemplateSet, selectTemplatesBySetId } from '~/features/entity/selectors'
import { experimentFormResolvers } from '~/features'
import { useAppSelector, useDeleteEntity, useFormVitalsSubscription, useResetEntity, useTemplateSetFormBody, useUpdateEntity } from '~/hooks'
import { Button, CheckmarkIcon, FormLabel, SettingsContentWrapper, SettingsSectionDivider, SettingsTitleRow, StatusText } from '~/components/atoms'
import { ButtonWithConfirm, ButtonWithMessage, FormRenderer, SubmitButtonComplex } from '~/components/molecules'

import type { EntityObject, FormOnSubmitFn } from '~/form-brain2'
import type { TemplateSet } from '~/features'
import DeleteConfirmationComplex from '../DeleteConfirmationComplex'
import TemplateList from '../TemplateManagement/TemplateList'

interface Props {
  templateSet: TemplateSet
}

const
  formName = 'TemplateSetSettings',
  StyledButton = styled(Button)`
    align-self: flex-start;
  `,
  StyledDeleteConfirmationComplex = styled(DeleteConfirmationComplex)`
    align-self: flex-start;
    margin-top: ${p => p.theme.emSmallSpacing}em;
  `,
  TemplateSetSettings: React.FC<Props> = ({ templateSet }) => {
    const
      THEME = useTheme(),
      navigate = useNavigate(),
      { updateEntity, sourceOfUpdate, isUpdating } = useUpdateEntity(),
      { deleteEntity, isDeleting: isProcessingDelete, errors: deletionErrors } = useDeleteEntity(),
      { resetEntity, isResetting, errors: resetErrors, isSuccess: isResetSuccess } = useResetEntity({
        id: templateSet.id,
        clearStateOnSuccessAfter: 4000,
        type: 'templateSet'
      }),
      { formStatus, subscribeToFormStatus } = useFormVitalsSubscription(),
      { schema, formBody } = useTemplateSetFormBody(),
      basisForTemplateSet = useAppSelector(selectBasisForTemplateSet(templateSet)),
      templates = useAppSelector(selectTemplatesBySetId(templateSet.id)),
      permitPublish = !templateSet.publishedAt && templates.length,
      basedOnTitle = basisForTemplateSet
        ? basisForTemplateSet.title
        : templateSet.basedOnId
          ? '(No longer available)'
          : undefined,
      parsedDeletionError = getBaseErrorString(
        deletionErrors,
        'Unable to delete draft template set. Please try again later and contact support if the problem continues.'
      ),
      onSubmit: FormOnSubmitFn = ({ values, submissionSource }) => updateEntity({
        newValues: values,
        entity: templateSet,
        sourceOfUpdate: submissionSource
      }),
      publishTemplateSet: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        void onSubmit({
          values: { publishedAt: new Date().toISOString() },
          submissionSource: 'publish'
        })
      },
      onConfirmDelete: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }
        if (!templateSet) { return }

        deleteEntity(templateSet)
          .then(() => { navigate('/account/templates') })
          .catch(() => { /* No op, use error message */ })
      },
      archiveTemplateSet: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        void onSubmit({
          values: { archivedAt: new Date().toISOString() },
          submissionSource: 'archive'
        })
      },
      restoreTemplateSet: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        void onSubmit({
          values: { archivedAt: null } as unknown as EntityObject,
          submissionSource: 'restore'
        })
      },
      resetTemplateSet: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        resetEntity()
      },
      formId = `${formName}${(templateSet.updatedAt ?? '').replace(/\D/g, '')}`

    React.useEffect(() => {
      if (isResetSuccess) {
        const timeout = setTimeout(() => { location.reload() }, 3500)

        return () => { clearTimeout(timeout) }
      }
    }, [isResetSuccess])

    return <SettingsContentWrapper>
      <SettingsTitleRow>
        <FormLabel
          label="Template Set Settings"
          showLabel
          asHeading
        />
        <ButtonWithMessage
          messageText={isResetSuccess ? 'Template set has been reset' : undefined}
          icon={isResetSuccess ? <CheckmarkIcon /> : null}
          swapButtonAndMessage
          button={
            <ButtonWithConfirm
              onConfirm={resetTemplateSet}
              buttonProps={{
                size: 'medium',
                label: 'Reset',
                color: 'caution',
                isLoading: isResetting,
                isDisabled: !!isUpdating || isResetSuccess
              }}
              hasSucceeded={isResetSuccess}
              cancelLabel="Cancel"
              confirmLabel="Confirm Reset"
              subheading="Reset templates and settings"
              confirmationBody={`This will delete any changes you have made to the templates in this set and replace them with the ${basedOnTitle ? `templates from ${basedOnTitle}` : 'system base templates'}`}
              errorMessage={resetErrors ? getBaseErrorString(resetErrors, 'An unexpected error occurred') : undefined}
            />
          }
        />
      </SettingsTitleRow>
      <Form
        formId={formId}
        entitySchema={schema}
        formBody={formBody}
        initialValues={templateSet as unknown as EntityObject}
        onSubmitCallback={onSubmit}
        FormRenderer={FormRenderer}
        subscribeToFormStatus={subscribeToFormStatus}
        formOptions={{ RootFormRenderer: FormRenderer }}
        {...experimentFormResolvers}
      />
      <SubmitButtonComplex
        {...{ formId, formStatus }}
        label="Save Changes"
        isLoading={isUpdating && sourceOfUpdate === 'event'}
        isDisabled={!!sourceOfUpdate}
      />

      <SettingsSectionDivider color={THEME.colors.understateDark} />
      <FormLabel
        label="Template Set Availability"
        showLabel
        asHeading
      />
      <StatusText color="accent">
        {templateSet.archivedAt
          ? 'Template set is archived: teachers can no longer create new projects using these templates. This does not effect existing projects in any way.'
          : templateSet.publishedAt
            ? 'Template set is published: teachers can use these templates in their projects.'
            : 'Template set is a draft: this template set is visible to its author, who can also use it to create test projects, but is not visible to teachers.'}
      </StatusText>
      {templateSet.archivedAt
        ? <StyledButton size="small" color="back" label="Un-Archive Template Set" onClick={restoreTemplateSet} isLoading={sourceOfUpdate === 'restore'} isDisabled={isUpdating && sourceOfUpdate !== 'restore'} />
        : templateSet.publishedAt
          ? <StyledButton size="small" color="back" label="Archive Template Set" onClick={archiveTemplateSet} isLoading={sourceOfUpdate === 'archive'} isDisabled={isUpdating && sourceOfUpdate !== 'archive'} />
          : <StyledButton size="small" color="back" label="Publish Template Set" onClick={publishTemplateSet} isLoading={sourceOfUpdate === 'publish'} isDisabled={!permitPublish || (isUpdating && sourceOfUpdate !== 'publish')} />}
      {templateSet.publishedAt
        ? null
        : <StyledDeleteConfirmationComplex
          onConfirmDelete={onConfirmDelete}
          isProcessing={!!isProcessingDelete}
          isDisabled={!!isProcessingDelete}
          triggerLabel='Delete Template Set'
          subheading={`Delete draft: ${templateSet.title}`}
          overrideButtonProps={{
            size: 'small'
          }}
          errorMessage={deletionErrors ? parsedDeletionError : undefined}
        />}
      <SettingsSectionDivider color={THEME.colors.understateDark} />
      <FormLabel
        label="Step Templates"
        showLabel
        asHeading
      />
      <TemplateList isDisabled={isUpdating} templateSet={templateSet} />
    </SettingsContentWrapper>
  }

export default TemplateSetSettings
