import { groupBy, sortBy, upperFirst, values } from 'lodash'
import useAppSelector from './useAppSelector'
import { selectExperiments, selectTemplateSets, selectTemplates, selectWorksheets } from '~/features/entity/selectors'
import { formBodyForSpecialSection, sectionIdVisibilityCondition, specialSectionSpecialDefaultEntryList } from '~/features/experiment/specialSections'
import { getSectionList, getWalkthroughSectionIdOptions } from '~/features/worksheet/helpers'

import type { FormBody } from '~/form-brain2/types'
import type { AnyWorksheet, ListOption, SpecialLayoutType, AnyPlainOrInputElement, Template, Worksheet, SelectInput, VisibilityCondition, AnyListSectionElement } from '~/features'
import type { ObjectId } from '~/types/utilities'

const
  useStepFormBody = (body: AnyPlainOrInputElement[], experimentId?: ObjectId, stepId?: ObjectId): {
    formBody: FormBody
  } => {
    const
      templateSetsById = useAppSelector(selectTemplateSets),
      sortedTemlateSets = sortBy(values(templateSetsById), 'title').filter(o => !o.systemTemplate),
      templatesById = useAppSelector(selectTemplates),
      templatesBySetId: Record<ObjectId, Template[]> = groupBy(values(templatesById), 'templateSetId'),
      experimentsById = useAppSelector(selectExperiments),
      sortedExperiments = sortBy(values(experimentsById), 'name'),
      worksheetsById = useAppSelector(selectWorksheets),
      worksheetsByExperimentId: Record<ObjectId, AnyWorksheet[]> = groupBy(values(worksheetsById), 'experimentId'),
      worksheetsThisExperiment = worksheetsByExperimentId[experimentId ?? ''] ?? [],
      existingSpecialLayoutTypes = worksheetsThisExperiment.reduce<SpecialLayoutType[]>((memo, worksheet) => (
        worksheet.format === 'LIST' && worksheet.specialLayoutType
          ? [...memo, worksheet.specialLayoutType]
          : memo
      ), []),
      existingStep = stepId
        ? worksheetsById[stepId]
        : undefined,
      existingRepeatingSections = existingStep && existingStep.format === 'LIST'
        ? existingStep.repeatingSections
        : undefined,
      allExistingSections: AnyListSectionElement[] = existingStep && existingStep.format === 'LIST'
        ? getSectionList(existingStep)
        : [],
      formBody = body.map(el => {
        if (el.id === 'stepBasedOnTemplate') {
          return {
            ...el,
            list: sortedTemlateSets.flatMap(o => (templatesBySetId[o.id] ?? []).map((template: Template) => ({
              optionValue: template.id,
              optionLabel: `${o.title}: ${template.name}` + (o.publishedAt ? '' : o.authorId ? ' (draft)' : ' (admin-only draft)'),
              hidden: o.archivedAt
            })))
          }
        } else if (el.id === 'stepBasedOnWorksheet') {
          return {
            ...el,
            list: sortedExperiments.filter(o => o.id !== experimentId).flatMap(o => (worksheetsByExperimentId[o.id] ?? []).map((worksheet: Worksheet) => ({
              optionValue: worksheet.id,
              optionLabel: `${o.name} Group: ${worksheet.name} Step`
            })))
          }
        } else if (el.id === 'stepFormat') {
          return {
            ...el,
            list: [
              { optionValue: 'PAGED', optionLabel: 'Paged' },
              { optionValue: 'LIST', optionLabel: 'List' },
              { optionValue: 'HUB', optionLabel: 'Hub' },
              { optionValue: 'DOWNLOADS', optionLabel: 'Downloads' }
            ]
          }
        } else if (el.id === 'listSpecialLayout') {
          const
            initialList = ((el as SelectInput).list ?? []) as ListOption[],
            specialLayoutOptions: ListOption[] = initialList.map(({ optionLabel, optionValue }) => ({
              optionValue,
              optionLabel,
              ...(existingSpecialLayoutTypes.includes(optionValue as SpecialLayoutType) ? { disabledWhen: [{ conditionType: 'always' }] } : {})
            }))

          return {
            ...el,
            list: specialLayoutOptions
          }
        } else if (el.id === 'listStepOptions' && existingRepeatingSections && 'body' in el) {
          /** This can be set here since sections and settings are in separate forms
           * and thus sections will not change once this form is initialized */
          return {
            ...el,
            body: (el.body ?? []).map(formEl => {
              if (formEl.id === 'listDefaultsList' && 'formBody' in formEl) {
                return {
                  ...formEl,
                  formBody: formEl.formBody.flatMap(defaultsListEl => {
                    if (defaultsListEl.id === 'listDefaultSectionId') {
                      return [{
                        ...defaultsListEl,
                        list: existingRepeatingSections.map(o => ({
                          optionValue: o.id,
                          optionLabel: upperFirst(o.entryNameSingular)
                        }))
                      }]
                    } else if (defaultsListEl.id === 'listDefaultAutoEntryKey') {
                      return [{
                        ...defaultsListEl,
                        list: specialSectionSpecialDefaultEntryList(existingRepeatingSections)
                      }]
                    } else if (defaultsListEl.id === 'listDefaultEntryValues' && 'formBody' in defaultsListEl) {
                      return existingRepeatingSections.map(section => {
                        return {
                          ...defaultsListEl,
                          id: `${defaultsListEl.id}-${section.id}`,
                          visibleWhen: [
                            sectionIdVisibilityCondition(section.id),
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            { conditionType: 'present', valueName: 'sectionId' } as VisibilityCondition
                          ],
                          formBody: 'specialSectionId' in section
                            ? formBodyForSpecialSection(section)
                            : section.body
                        }
                      })
                    } else {
                      return [defaultsListEl]
                    }
                  })
                }
              } else if (formEl.id === 'listWalkthroughSettings' && 'formBody' in formEl) {
                return {
                  ...formEl,
                  formBody: formEl.formBody.flatMap(walkthroughListEl => {
                    if (walkthroughListEl.id === 'walkthroughStepSectionId') {
                      return [{
                        ...walkthroughListEl,
                        list: getWalkthroughSectionIdOptions(allExistingSections),
                        visibleWhen: allExistingSections.length
                          ? [{ conditionType: 'always' }] as VisibilityCondition[]
                          : [{ conditionType: 'never' }] as VisibilityCondition[]
                      }]
                    } else {
                      return [walkthroughListEl]
                    }
                  })
                }
              } else {
                return formEl
              }
            })
          }
        } else {
          return el
        }
      })

    return {
      formBody
      // schema
    }
  }

export default useStepFormBody
