import { existingStepSchema } from './baseSchemas'
import type { EntitySchema } from '~/form-brain2'
import type { AnyPlainOrInputElement, QuestionnaireFormat } from '~/features/questionnaire/types'
import { SPECIAL_CONTENT_ID_LIST } from './optionLists'
import { advancedPageFormFields, existingStepFormBody } from './baseFormBodies'

export const
  pagedStepSchema: EntitySchema = {
    ...existingStepSchema,
    specialContentArea: {
      fieldName: 'specialContentArea',
      fieldType: 'SCOPE',
      format: {
        specialContentId: {
          fieldName: 'specialContentId',
          fieldType: 'ENUM'
        }
      },
      visibleWhen: [{ conditionType: 'equals', valueName: 'format', testValue: 'PAGED' as QuestionnaireFormat }]
    }
    // sections: PagedSectionElement[] // For PAGED
  },
  pagedStepFormBody: AnyPlainOrInputElement[] = [
    ...existingStepFormBody,
    {
      id: 'pagedOptions',
      elementVariety: 'COLLAPSIBLE',
      expandButtonLabel: 'Show Paged Format Options',
      collapseButtonLabel: 'Hide Paged Format Options',
      body: [{
        id: 'pagedSpecialContentArea',
        elementVariety: 'SCOPE',
        fieldName: 'specialContentArea',
        formBody: [
          {
            id: 'stepFormat',
            elementVariety: 'SELECT_INPUT',
            fieldName: 'specialContentId',
            noSort: true,
            blankOption: 'None',
            label: 'Special content area:',
            list: SPECIAL_CONTENT_ID_LIST
          }
        ]
      }]
    },
    {
      id: 'pagedAdvancedCollapsible',
      elementVariety: 'COLLAPSIBLE',
      expandButtonLabel: 'Show Advanced Options',
      collapseButtonLabel: 'Hide Advanced Options',
      body: advancedPageFormFields
    }
  ]
