import { keyBy } from 'lodash'
import { SystemFieldNames, SystemFieldGroups, getControlGroupTitle, getControlGroupDescription, INDEPENDENT_VARIABLES } from './systemFieldConstants'
import { fieldDetails as plantSpacingValue } from './plantSpacingValue'
import { fieldDetails as plantSpacingUnit } from './plantSpacingUnit'
import { fieldDetails as plantSpacingArea } from './plantSpacingArea'

import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

export interface PlantingDensityTreatmentFormat {
  plantSpacingValue: number
  plantSpacingUnit: string
  plantSpacingArea: string
}

const
  independentVariable = INDEPENDENT_VARIABLES.PLANTING_DENSITY,
  schemaEntry: QField = {
    fieldName: SystemFieldNames.plantingDensityControlGroup,
    fieldType: 'SCOPE',
    visibleWhen: [{
      valueName: SystemFieldNames.independentVariable,
      conditionType: 'matches',
      testValue: independentVariable
    }],
    format: keyBy([
      plantSpacingValue.schemaEntry,
      plantSpacingUnit.schemaEntry,
      plantSpacingArea.schemaEntry
    ], 'fieldName')
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.plantingDensityControlGroup,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    shouldPrecede: [SystemFieldNames.plantingDensityTreatmentGroups],
    isRequired: false,
    title: getControlGroupTitle(independentVariable),
    description: getControlGroupDescription(independentVariable),
    controlGroupFor: independentVariable,
    schemaEntry
  }

export {
  fieldDetails
}
