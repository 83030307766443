import * as React from 'react'
import styled from 'styled-components'

interface SubheadingTwoProps {
  className?: string
  children: React.ReactNode
}

const
  SubheadingTwoText = styled.div`
    align-items: center;
    font-size: 1rem; // rem math: 30px / 2 / 15px
    color: ${p => p.theme.colors.headingUnderstateText};
    ${p => p.theme.headingFont}
  `,
  SubheadingTwo: React.FC<SubheadingTwoProps> = ({ className, children }) => {
    return (
      <SubheadingTwoText className={className} data-cy="subheading-two">
        {children}
      </SubheadingTwoText>
    )
  }

export default SubheadingTwo
