import React from 'react'
import axios from 'axios'
import { get } from 'lodash'
import { decode } from 'base-64'
import { useDispatch } from 'react-redux'
import { baseUrl } from '~/services/api'
import { getState } from '~/services/store'
import { getSemiRandomString } from '~/utils/strings'
import { isAllEmpty, isEmptyValue } from '~/utils/testers'
import { processAxiosResultForForm } from '~/utils/errorMessages'
import { getSessionType, raceOperationCompleted, raceOperationIgnored, raceOperationStarted, received401Error, tokenRefreshed } from '~/features/session/sessionSlice'
import { entitiesReceived } from '~/features/entity/entitySlice'
import { selectIsWinningOperation, selectUnverifiedAccessToken, selectUnverifiedRefreshToken, selectWinningOperationId, selecteReceived401ForSessionType } from '~/features/session/selectors'
import { selectUserById } from '~/features/entity/selectors'
import useAppSelector from './useAppSelector'

import type { User } from '~/features'
import type { EntityErrors } from '~/form-brain2'
import type { APIEntity } from 'cypress/support/types'
import type { ParsedToken } from '~/features/session/sessionSlice'

interface UseRefreshSession {
  isProcessing?: boolean
  isSessionValid?: boolean
  activeToken?: string
  sessionData?: ParsedToken
  user?: User
  sessionErrors?: EntityErrors
}

interface UseRefreshSessionState {
  isProcessing?: boolean
  hasAttemptedFetchUser?: boolean
  hasAttemptedTokenRefresh?: boolean
  rawErrors?: EntityErrors
}

const
  blankData = {},
  parseJwt = (token?: string): ParsedToken => {
    if (!token || isEmptyValue(token)) return blankData

    const
      content = token.split('.')[1],
      base64Json = content.replace(/-/g, '+').replace(/_/g, '/'),
      decodedObj = decode(base64Json)

    return JSON.parse(decodedObj)
  },
  isTokenStillValid = (token?: string): boolean => {
    if (!token) return false

    const
      expiryCutoff = (new Date().valueOf() / 1000) - 5,
      parsedToken = parseJwt(token)

    return !!parsedToken.exp && parsedToken.exp > expiryCutoff
  },
  userOperationKey = 'GET:currentUser',
  tokenOperationKey = 'refreshToken',
  useRefreshSession = (): UseRefreshSession => {
    const
      dispatch = useDispatch(),
      accessToken = useAppSelector(selectUnverifiedAccessToken),
      refreshToken = useAppSelector(selectUnverifiedRefreshToken),
      received401ForSessionType = useAppSelector(selecteReceived401ForSessionType),
      isAccessTokenValid = isTokenStillValid(accessToken),
      isRefreshTokenValid = isTokenStillValid(refreshToken),
      isSessionValid = isAccessTokenValid || isRefreshTokenValid,
      activeToken = isAccessTokenValid
        ? accessToken
        : isRefreshTokenValid
          ? refreshToken
          : undefined,
      sessionData = activeToken
        ? parseJwt(activeToken)
        : blankData,
      sessionType = getSessionType(sessionData),
      userId = 'user_id' in sessionData ? sessionData.user_id as string : undefined,
      user = useAppSelector(selectUserById(userId)),
      [state, setState] = React.useState<UseRefreshSessionState>({}),
      { isProcessing, rawErrors, hasAttemptedFetchUser, hasAttemptedTokenRefresh } = state,
      hasErrors = !isAllEmpty(rawErrors),
      fetchCurrentUser = React.useCallback((): void => {
        const
          operationId = getSemiRandomString(),
          requestInfo = { key: userOperationKey, operationId }

        setState(_state => ({ ..._state, isProcessing: true }))
        dispatch(raceOperationStarted(requestInfo))

        processAxiosResultForForm(axios({
          method: 'get',
          url: `${baseUrl}current_user`,
          headers: {
            Authorization: `Bearer ${activeToken as string}`
          }
        }))
          .then(({ data }) => {
            const
              entities = get(data, 'data') as Array<APIEntity<User>>,
              isWinningOperation = selectIsWinningOperation(requestInfo)(getState())

            if (isWinningOperation) {
              dispatch(entitiesReceived(entities))
              dispatch(raceOperationCompleted(requestInfo))
            } else {
              dispatch(raceOperationIgnored(requestInfo))
            }

            setState(_state => ({ ..._state, isProcessing: false, hasAttemptedFetchUser: true }))
          })
          .catch(({ errors, data }) => {
            const isWinningOperation = selectIsWinningOperation(requestInfo)(getState())

            dispatch(raceOperationCompleted(requestInfo))

            if (data?.unauthorized) {
              dispatch(received401Error({ sessionType }))
            }

            setState(_state => ({
              ..._state,
              isProcessing: false,
              rawErrors: isWinningOperation ? errors : undefined
            }))
          })
      }, [dispatch, setState, activeToken, sessionType]),
      refreshSession = React.useCallback((): void => {
        const
          operationId = getSemiRandomString(),
          requestInfo = { key: tokenOperationKey, operationId }

        dispatch(raceOperationStarted(requestInfo))
        processAxiosResultForForm(axios({
          method: 'put',
          url: `${baseUrl}sessions`,
          data: { withRefresh: true },
          headers: {
            Authorization: `Bearer ${refreshToken as string}`
          }
        }))
          .then(({ data }) => {
            const
              { accessToken, refreshToken } = get(data, 'meta', {}) as ({ accessToken: string, refreshToken?: string }),
              isWinningOperation = selectIsWinningOperation(requestInfo)(getState())

            if (isWinningOperation) {
              dispatch(tokenRefreshed({ accessToken, refreshToken }))
              dispatch(raceOperationCompleted(requestInfo))
            } else {
              dispatch(raceOperationIgnored(requestInfo))
            }
            setState(_state => ({ ..._state, hasAttemptedTokenRefresh: true }))
          })
          .catch(({ errors, data }) => {
            dispatch(raceOperationCompleted(requestInfo))

            if (data?.unauthorized) {
              dispatch(received401Error({ sessionType }))
            }

            setState(_state => ({ ..._state, hasAttemptedTokenRefresh: true }))
          })
      }, [dispatch, setState, refreshToken, sessionType])

    React.useEffect(() => {
      if (
        !hasAttemptedFetchUser && !received401ForSessionType &&
        !isProcessing && !hasErrors &&
        !selectWinningOperationId(userOperationKey)(getState()) &&
        isSessionValid && userId && !user
      ) {
        fetchCurrentUser()
      }
    }, [fetchCurrentUser, hasAttemptedFetchUser, isProcessing, hasErrors, isSessionValid, userId, user, received401ForSessionType])

    React.useEffect(() => {
      if (
        !hasAttemptedTokenRefresh && !received401ForSessionType &&
        isRefreshTokenValid && !isAccessTokenValid &&
        !selectWinningOperationId(tokenOperationKey)(getState())
      ) {
        refreshSession()
      }
    }, [refreshSession, isAccessTokenValid, isRefreshTokenValid, hasAttemptedTokenRefresh, received401ForSessionType])

    return {
      isProcessing,
      isSessionValid,
      activeToken,
      sessionData,
      user,
      sessionErrors: rawErrors
    }
  }

export default useRefreshSession
