import * as React from 'react'
import styled from 'styled-components'

interface CardFooterProps {
  contentLeft?: React.ReactNode
  contentRight?: React.ReactNode
  contentCenter?: React.ReactNode
  className?: string
}

const
  CardFooterOuter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;

    padding: 10 10 0 0;
    gap: 5px;
  `,
  ContentWrapper = styled.div<{ $justify?: string }>`
    flex: ${p => p.$justify === 'center' ? '1 1 auto' : '0 0 auto'};
    min-width: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${p => p.$justify};
    overflow: hidden;
    
    gap: 5px;
  `,
  CardFooter: React.FC<CardFooterProps> = ({ contentLeft, contentRight, contentCenter, className }) => {
    return (
      <CardFooterOuter className={className}>
        {contentLeft ? <ContentWrapper $justify="flex-start">{contentLeft}</ContentWrapper> : null}
        <ContentWrapper $justify="center">{contentCenter}</ContentWrapper>
        {contentRight ? <ContentWrapper $justify="flex-end">{contentRight}</ContentWrapper> : null}
      </CardFooterOuter>
    )
  }

export default CardFooter
