import { debounce } from 'lodash'
import { useState, useEffect } from 'react'

const
  getWindowWidth = (): number | undefined => window.innerWidth, // || document.documentElement.clientWidth || document.body.clientWidth,
  useWindowWidth = (): number | undefined => {
    const [windowWidth, setWindowSize] = useState<number | undefined>(getWindowWidth())

    useEffect(() => {
      const
        measureWindow = (): void => { setWindowSize(getWindowWidth()) },
        debouncedMeasure = debounce(measureWindow, 150)

      window.addEventListener('resize', debouncedMeasure)
      return () => { window.removeEventListener('resize', debouncedMeasure) }
    }, [])

    return windowWidth
  }

export default useWindowWidth
