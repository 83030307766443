import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

const
  Link = styled(RouterLink)`
    display: inline;
    color: ${p => p.theme.colors.headingAccentText};
    text-decoration-line: underline;

    &:hover {
      text-decoration-style: double;
    }

    &:visited {
      color: ${p => p.theme.colors.headingUnderstateText};
    }
  `

export default Link
