import * as React from 'react'
import styled from 'styled-components'
import { isEqual, last, pick, upperFirst } from 'lodash'
import { FormContext } from '~/form-brain2'
import { isAllEmpty } from '~/utils/testers'
import { inputIdFromBodyElement } from '~/utils/formatters'
import { interpolateStringInForm } from '~/features'
import { BasicCross, FormLabel, Icon } from '~/components/atoms'
import ActionPointer from '~/components/molecules/ActionPointer'
import ErrorNotice from '~/components/molecules/FormElements/ErrorNotice'
import FormElement from '~/components/molecules/FormRenderers/FormElement'

import type { AttrErrors, EntityObject, CollectionRendererProps } from '~/form-brain2'

const
  Outer = styled.span`
    margin-top: ${p => p.theme.emSmallSpacing}em;
  `,
  StyledActionPointer = styled(ActionPointer)<{ $withEnclosedEntries?: boolean }>`
    align-self: center;
    ${p => p.$withEnclosedEntries ? `margin-top: ${p.theme.emBaseSpacing}em;` : ''}
  `,
  CollectionOuter = styled.div<{ $offset?: boolean }>`
    ${p => p.$offset
      ? `
        padding-left: ${p.theme.emBaseSpacing}em;
        border-left: 2px dashed ${p.theme.colors.borderAlt};
        
        margin-left: ${p.theme.emSmallSpacing * 0.66}em;
      `
      : ''}

    margin-bottom: ${p => p.theme.emBaseSpacing}em;
    display: flex;
    flex-direction: column;
  `,
  CollectionRenderer: React.FC<CollectionRendererProps> = ({ name, children, addEntry, collectionOptions, collectionScope, entries }) => {
    const
      formContext = React.useContext(FormContext),
      { values, initialValues, errors, showingAllErrors } = formContext,
      inputId = collectionOptions
        ? inputIdFromBodyElement(collectionOptions, collectionScope)
        : `scope-${name}`,
      label = (
        interpolateStringInForm(collectionOptions?.label, formContext) ??
        upperFirst(interpolateStringInForm(collectionOptions?.entryNamePlural, formContext))
      ),
      adderLabel = collectionOptions?.understatedAdder
        ? `Add a ${interpolateStringInForm(collectionOptions?.entryNameSingular, formContext) as string}`
        : `Add a new ${interpolateStringInForm(collectionOptions?.entryNameSingular, formContext) as string}!`,
      isDirty = !isEqual(values[name], initialValues[name]),
      baseErrorsPresent = !isAllEmpty(errors[`${name}_base`]),
      showBaseError = baseErrorsPresent && (isDirty || showingAllErrors),
      leader = collectionOptions?.leader,
      emptyListElement = collectionOptions?.emptyListelement,
      className = collectionOptions?.className,
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      newEntry = {
        ...(collectionOptions?.defaultEntry ?? {}),
        ...pick(
          last((values[name] as EntityObject[]) ?? []) ?? {},
          collectionOptions?.carryOverFields ?? []
        )
      } as EntityObject,
      canAdd = collectionOptions?.maximumLength && entries?.length
        ? entries.length < collectionOptions?.maximumLength
        : !(collectionOptions?.useAlternateEntryRenderer === 'bodyElementList' && entries?.length)

    React.useEffect(() => {
      if (
        label &&
        collectionOptions?.label &&
        !collectionOptions.hideLabel &&
        formContext.formOptions.setParentHeading
      ) {
        formContext.formOptions.setParentHeading(collectionOptions.label, collectionOptions.parentId)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [label, collectionOptions?.label, collectionOptions?.hideLabel, formContext.formOptions.setParentHeading])

    return <Outer id={inputId} {...(showBaseError ? { 'data-has-error': true } : {})} className={className} data-cy="collection-renderer">
      {label
        ? <FormLabel
            label={label}
            showLabel={!collectionOptions?.hideLabel}
            asHeading={!(collectionOptions?.editInline && !collectionOptions?.labelAsHeading)}
          />
        : null}
      <CollectionOuter $offset={collectionOptions?.offsetEntries}>
        {showBaseError
          ? <ErrorNotice errors={{ _base: errors[`${name}_base`] as AttrErrors }} />
          : null}
        {leader
          ? <FormElement bodyElement={leader} elementIndex={-1} />
          : null}
        {!entries?.length && emptyListElement
          ? <FormElement bodyElement={emptyListElement} elementIndex={-1} />
          : null}
        {children}
        {!!formContext.formOptions.isReadOnly || !!collectionOptions?.isReadOnly || !canAdd
          ? null
          : <StyledActionPointer
            $withEnclosedEntries={collectionOptions?.encloseEntries}
            label={adderLabel}
            orientation={collectionOptions?.understatedAdder ? 'left' : undefined}
            icon={<Icon content={BasicCross} onClick={() => { addEntry(newEntry) }} />}
          />}
      </CollectionOuter>
    </Outer>
  }

export default CollectionRenderer
export { CollectionOuter }
