import React from 'react'
import styled from 'styled-components'
import { BasicDoubleArrow, BasicStar, CheckmarkIcon, Icon, LoadingIcon, Notepad } from '~/components/atoms'
import { WorksheetResponseHelpers } from '~/features'
import { useLearnerStepContext, useUpdateEntity } from '~/hooks'
import { isAllEmpty } from '~/utils/testers'

import type { ClosingSectionElement, OpeningSectionElement, RepeatingSectionElement, ListEntry, ListSectionElement, SpecialListSectionElement, IconName } from '~/features'
import { availableIcons } from '~/components/molecules'

interface BaseIconForEntryProps {
  section?: ListSectionElement
}

interface NewEntryIconProps {
  entry: undefined
  actualIndex: undefined
  nthOfKind: undefined
}

interface ExtantEntryIconProps {
  entry: ListEntry
  actualIndex: number
  nthOfKind: number
}

type IconForEntryProps = (BaseIconForEntryProps & NewEntryIconProps) | (BaseIconForEntryProps & ExtantEntryIconProps)

interface ChecklistIconProps {
  actualIndex: number
  entry: ListEntry
}

const
  StyledLoadingIcon = styled(LoadingIcon)`
    // width: 100%;
    height: 100%;
    margin-left: 10%;
  `,
  ChecklistIcon: React.FC<ChecklistIconProps> = ({ entry, actualIndex }) => {
    const
      { updateEntity, isUpdating: isLoading, errors } = useUpdateEntity(),
      learnerStepContext = useLearnerStepContext(),
      response = learnerStepContext ? learnerStepContext.response : undefined,
      isError = !isAllEmpty(errors),
      toggleEntryChecked: React.MouseEventHandler | undefined = response
        ? (e): void => {
            if (e) { e.preventDefault(); e.stopPropagation() }

            const updatedResponse = WorksheetResponseHelpers.addEntryToListResponse(
              response,
              { ...entry, checked: !entry.checked },
              false,
              actualIndex
            )

            updateEntity({
              entity: response,
              newValues: updatedResponse
            }).catch((error) => {
            /* keep log */ console.log(error)
            })
          }
        : undefined,
      accessibleDescription = !response
        ? undefined
        : isError
          ? 'There was an error while checking or unchecking'
          : entry.checked
            ? 'This item is checked off. Use this icon to uncheck it'
            : 'Use this icon to check this item off the list.',
      actionProps = response
        ? {
            accessibleDescription,
            onClick: toggleEntryChecked
          }
        : {}

    return isLoading
      ? <StyledLoadingIcon />
      : <CheckmarkIcon
        color={isError ? 'caution' : entry?.checked ? 'forward' : 'understate'}
        isChecked={!!entry?.checked}
        {...actionProps}
      />
  },
  IconForEntry: React.FC<IconForEntryProps> = ({ section, nthOfKind, actualIndex, entry }) => {
    if (section && (section as RepeatingSectionElement)?.checkList && entry) {
      return <ChecklistIcon {...{ entry, actualIndex }} />
    }

    if (section && (section as SpecialListSectionElement).specialSectionId === 'SUMMARY_STATS') {
      return <Icon content={Notepad} color="hero" degreesRotation={16} />
    }

    if (section?.elementVariety === 'LIST_OPENER_SECTION') {
      return <Icon content={BasicDoubleArrow} color="hero" accessibleDescription={(section as OpeningSectionElement).title} />
    }

    if (section?.elementVariety === 'LIST_CLOSER_SECTION') {
      return <Icon content={BasicStar} color="hero" accessibleDescription={(section as ClosingSectionElement).title} />
    }

    if ((section as SpecialListSectionElement)?.entryIconContent) {
      const
        specialSection = section as SpecialListSectionElement,
        content = specialSection.entryIconContent as string

      return <Icon content={availableIcons[content as IconName] ?? content} color="back" accessibleDescription={specialSection.entryNameSingular} />
    }

    if (!entry) {
      return <Icon content="?" color="back" />
    }

    return <Icon content={(nthOfKind + 1).toString()} color="back" accessibleDescription={(section as RepeatingSectionElement)?.entryNameSingular} />
  }

export default IconForEntry
