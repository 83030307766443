import { keys, mapValues, pick } from 'lodash'
import type { EntitySchema } from '~/form-brain2'
import type { ElementVariety, FieldType } from '~/features/questionnaire/types'

export const
  baseElementSchema: EntitySchema = {
    id: {
      fieldName: 'id',
      fieldType: 'STRING'
    },
    visibleWhen: {
      fieldName: 'visibleWhen',
      fieldType: 'COLLECTION'
    },
    elementVariety: {
      fieldName: 'elementVariety',
      fieldType: 'ENUM'
    }
  },
  blurbElementSchema: EntitySchema = {
    // elementVariety
    // className
    // printableOnly
    // digitalOnly
    // nonSummaryOnly
    // parentId /** for use any time child needs to refer to its parent uniquely */
    // noWrapper /** System only */
    // naturalWidth /** System only */
    // style
    ...baseElementSchema,
    iconColor: {
      fieldName: 'iconColor',
      fieldType: 'ENUM'
    },
    iconContent: {
      fieldName: 'iconContent',
      fieldType: 'STRING'
    },
    title: {
      fieldName: 'title',
      fieldType: 'STRING'
    },
    withBackground: {
      fieldName: 'withBackground',
      fieldType: 'BOOLEAN'
    },
    location: {
      fieldName: 'location',
      fieldType: 'ENUM'
    },
    paragraphs: {
      fieldName: 'paragraphs',
      fieldType: 'STRING:ARRAY'
    }
  },
  checkpointElementSchema: EntitySchema = {
    ...baseElementSchema,
    checkpointButtonLabel: {
      fieldName: 'checkpointButtonLabel',
      fieldType: 'STRING'
    },
    body: {
      fieldName: 'body',
      fieldType: 'COLLECTION',
      format: blurbElementSchema
    }
  },
  blurbOrCheckpointSchema: EntitySchema = {
    useCheckpoint: {
      fieldName: 'useCheckpoint',
      fieldType: 'BOOLEAN'
    },
    ...mapValues(blurbElementSchema, (v, k) => ({
      ...v,
      visibleWhen: [
        ...(v.visibleWhen ?? []),
        { conditionType: 'equals', valueName: 'entityVariety', testValue: 'BLURB' }
      ]
    })),
    ...mapValues(checkpointElementSchema, (v, k) => ({
      ...v,
      visibleWhen: [
        ...(v.visibleWhen ?? []),
        { conditionType: 'truthy', valueName: 'useCheckpoint' },
        { conditionType: 'equals', valueName: 'entityVariety', testValue: 'PAGE' }
      ]
    }))
  },
  headingSchema: EntitySchema = {
    ...baseElementSchema,
    text: {
      fieldName: 'text',
      fieldType: 'STRING'
    }
  },
  instructionsSchema: EntitySchema = {
    ...baseElementSchema,
    paragraphs: {
      fieldName: 'paragraphs',
      fieldType: 'STRING:ARRAY'
    }
  },
  subheadingSchema: EntitySchema = {
    ...baseElementSchema,
    text: {
      fieldName: 'text',
      fieldType: 'STRING'
    }
  },
  textSchema: EntitySchema = {
    ...baseElementSchema,
    text: {
      fieldName: 'text',
      fieldType: 'STRING'
    },
    isBold: {
      fieldName: 'isBold',
      fieldType: 'BOOLEAN'
    }
  },
  _baseInputSchema: EntitySchema = {
    fieldName: {
      fieldName: 'fieldName',
      fieldType: 'STRING',
      requiredWhen: [{ conditionType: 'always' }]
    },
    label: {
      fieldName: 'label',
      fieldType: 'STRING',
      requiredWhen: [{ conditionType: 'anyOf', valueName: 'elementVariety', testValue: ['LONG_ANSWER', 'SHORT_ANSWER', 'PARAGRAPHS'] }]
    },
    placeholder: {
      fieldName: 'placeholder',
      fieldType: 'STRING'
    },
    hint: {
      fieldName: 'hint',
      fieldType: 'STRING'
    },
    unit: {
      fieldName: 'unit',
      fieldType: 'STRING'
    },
    validWhen: {
      fieldName: 'validWhen',
      fieldType: 'COLLECTION'
    },
    requiredWhen: {
      fieldName: 'requiredWhen',
      fieldType: 'COLLECTION'
    },
    recommendedWhen: {
      fieldName: 'recommendedWhen',
      fieldType: 'COLLECTION'
    },
    readOnlyWhen: {
      fieldName: 'readOnlyWhen',
      fieldType: 'COLLECTION'
    },
    invalidates: {
      fieldName: 'invalidates',
      fieldType: 'STRING:ARRAY'
    },
    hideUnit: {
      fieldName: 'hideUnit',
      fieldType: 'BOOLEAN'
    },
    hideLabel: {
      fieldName: 'hideLabel',
      fieldType: 'BOOLEAN'
    },
    hideError: {
      fieldName: 'hideError',
      fieldType: 'BOOLEAN'
    },
    // hideErrorMessage: { TODO system only?
    //   fieldName: 'hideErrorMessage',
    //   fieldType: 'BOOLEAN'
    // },
    // hideHint: { // TODO system only?
    //   fieldName: 'hideError',
    //   fieldType: 'BOOLEAN'
    // },
    // inlineLabel: { // system only
    //   fieldName: 'hideError',
    //   fieldType: 'BOOLEAN'
    // },
    showHintAlways: {
      fieldName: 'showHintAlways',
      fieldType: 'BOOLEAN'
    }
  },
  textInputSchema: EntitySchema = {
    ...baseElementSchema,
    ..._baseInputSchema
    // inputType is system only
  },
  customTextInputSchema: EntitySchema = {
    ...baseElementSchema,
    ..._baseInputSchema,
    numCols: {
      fieldName: 'numCols',
      fieldType: 'NUMBER'
    },
    numRows: {
      fieldName: 'numRows',
      fieldType: 'NUMBER'
    }
  },
  baseMultipleChoiceSchema: EntitySchema = {
    noSort: {
      fieldName: 'noSort',
      fieldType: 'STRING'
    },
    list: {
      fieldName: 'list',
      fieldType: 'COLLECTION',
      requiredWhen: [{ conditionType: 'always' }],
      format: {
        optionValue: {
          fieldName: 'optionValue',
          fieldType: 'STRING',
          requiredWhen: [{ conditionType: 'always' }]
        },
        optionLabel: {
          fieldName: 'optionLabel',
          fieldType: 'STRING'
        },
        visibleWhen: {
          fieldName: 'visibleWhen',
          fieldType: 'COLLECTION'
        }
      }
    },
    hideWhenListEmpty: {
      fieldName: 'hideWhenListEmpty',
      fieldType: 'STRING'
    }
    // extraOption: { // system only
    //   fieldName: 'extraOption',
    //   fieldType: 'STRING'
    // }
  },
  selectInputSchema: EntitySchema = {
    ...baseElementSchema,
    ..._baseInputSchema,
    blankOption: {
      fieldName: 'blankOption',
      fieldType: 'STRING'
    },
    customOption: {
      fieldName: 'customOption',
      fieldType: 'STRING'
    },
    customPlaceholder: {
      fieldName: 'customPlaceholder',
      fieldType: 'STRING'
    },
    ...baseMultipleChoiceSchema
  },
  multiSelectInputSchema: EntitySchema = {
    ...baseElementSchema,
    ..._baseInputSchema,
    selectNoneOption: {
      fieldName: 'selectNoneOption',
      fieldType: 'STRING'
    },
    selectAllOption: {
      fieldName: 'selectAllOption',
      fieldType: 'STRING'
    },
    customOption: {
      fieldName: 'customOption',
      fieldType: 'STRING'
    },
    customPlaceholder: {
      fieldName: 'customPlaceholder',
      fieldType: 'STRING'
    },
    ...baseMultipleChoiceSchema
  },
  BASE_INPUT_FIELDS_IN_MAD_LIBS: string[] = [
    'label',
    'hint',
    'showHintAlways',
    'hideLabel',
    'hideError'
  ],
  ELEMENT_TYPES_IN_MAD_LIB_SEGMENTS: ElementVariety[] = [
    'TEXT',
    'TEXT_INPUT',
    'NUMBER_INPUT',
    'DATE_INPUT',
    'SELECT_INPUT'
  ],
  FIELDS_IN_MAD_LIB_SEGMENTS = [
    ...keys(textSchema),
    ...keys(baseMultipleChoiceSchema),
    'fieldName',
    'placeholder',
    'invalidates',
    'blankOption',
    'customOption',
    'customPlaceholder'
  ],
  FIELD_TYPES_IN_MAD_LIB_SEGMENTS: FieldType[] = [
    'NUMBER',
    'STRING',
    'DATE',
    'ENUM'
  ],
  madLibElementSchema: EntitySchema = {
    ...baseElementSchema,
    ...pick(_baseInputSchema, BASE_INPUT_FIELDS_IN_MAD_LIBS),
    segments: {
      fieldName: 'segments',
      fieldType: 'COLLECTION',
      requiredWhen: [{ conditionType: 'always' }]
      // schmea handled in MadLibEditor
    }
  },
  BASE_INPUT_FIELDS_IN_COLLECTIONS = [
    'fieldName',
    'label',
    'hideLabel',
    'requiredWhen'
  ],
  fieldsetElementSchema: EntitySchema = {
    ...baseElementSchema,
    encloseContent: {
      fieldName: 'encloseContent',
      fieldType: 'BOOLEAN'
    },
    offsetContent: {
      fieldName: 'offsetContent',
      fieldType: 'BOOLEAN'
    },
    body: {
      fieldName: 'body',
      fieldType: 'COLLECTION',
      requiredWhen: [{ conditionType: 'always' }]
    }
  },
  collectionElementSchema: EntitySchema = {
    ...baseElementSchema,
    ...pick(_baseInputSchema, BASE_INPUT_FIELDS_IN_COLLECTIONS),
    entryNameSingular: {
      fieldName: 'entryNameSingular',
      fieldType: 'STRING',
      requiredWhen: [{ conditionType: 'always' }]
    },
    entryNamePlural: {
      fieldName: 'entryNamePlural',
      fieldType: 'STRING',
      requiredWhen: [{ conditionType: 'always' }]
    },
    minimumLength: {
      fieldName: 'minimumLength',
      fieldType: 'NUMBER'
    },
    maximumLength: {
      fieldName: 'maximumLength',
      fieldType: 'NUMBER'
    },
    editInline: {
      fieldName: 'editInline',
      fieldType: 'BOOLEAN'
    },
    noInitialEntry: {
      fieldName: 'noInitialEntry',
      fieldType: 'BOOLEAN'
    },
    encloseEntries: {
      fieldName: 'encloseEntries',
      fieldType: 'BOOLEAN'
    },
    offsetEntries: {
      fieldName: 'offsetEntries',
      fieldType: 'BOOLEAN'
    },
    carryOverFields: {
      fieldName: 'carryOverFields',
      fieldType: 'STRING:ARRAY'
    },
    leader: {
      fieldName: 'leader',
      fieldType: 'SCOPE',
      format: {}
    },
    formBody: {
      fieldName: 'formBody',
      fieldType: 'COLLECTION',
      requiredWhen: [{ conditionType: 'always' }]
    }
  },
  scopeElementSchema: EntitySchema = {
    ...baseElementSchema,
    ...pick(_baseInputSchema, BASE_INPUT_FIELDS_IN_COLLECTIONS),
    asRow: {
      fieldName: 'asRow',
      fieldType: 'BOOLEAN'
    },
    noInitialEntry: {
      fieldName: 'noInitialEntry',
      fieldType: 'BOOLEAN'
    },
    offsetContent: {
      fieldName: 'offsetContent',
      fieldType: 'BOOLEAN'
    },
    // defaultEntry: EntityObject // system only
    formBody: {
      fieldName: 'formBody',
      fieldType: 'COLLECTION',
      requiredWhen: [{ conditionType: 'always' }]
    }
  }
