import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Fertilizer rate unit',
  description = 'The unit (of weight, volume, etc.) for the amount of fertilizer that will be used.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.fertilizerRateUnit,
    fieldType: 'STRING',
    options: [
      { optionValue: 'lbs', optionLabel: 'pounds (lbs)' },
      { optionValue: 'oz', optionLabel: 'ounces (oz)' },
      { optionValue: 'fl oz', optionLabel: 'fluid ounces (fl oz)' },
      { optionValue: 'g', optionLabel: 'grams (g)' },
      { optionValue: 'Tbsp', optionLabel: 'tablespoons (Tbsp)' },
      { optionValue: 'tsp', optionLabel: 'teaspoons (tsp)' }
    ]
    // visibleWhen: ivEqualityCondition(variableName, exclude)
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.fertilizerRateUnit,
    variableGroup: SystemFieldGroups.CONTROL_VARIABLES,
    // shouldFollow: [SystemFieldNames.fertilizerRateValue],
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
