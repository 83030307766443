import * as React from 'react'
import { useTheme } from 'styled-components'
import { ArrowStraight } from '~/components/atoms'
import CollapsedRow from '~/components/molecules/CollapsedRow'
import { getHeadingForEntry, getSubheadingForEntry } from './helpers'
import ListEntryForm from './ListEntryForm'
import IconForEntry from './IconForEntry'

import type { EntityObject } from '~/form-brain2'
import type { Experiment } from '~/features'
import type { ListEntrySummary, ListStepSelection } from './types'

interface Props {
  entrySummary: ListEntrySummary
  withInlineForm: boolean
  setCurrentSelection: React.Dispatch<React.SetStateAction<ListStepSelection>> | ((el: ListStepSelection) => void)
  currentSelection: ListStepSelection
  otherEntities: EntityObject[]
  experiment: Experiment
  hasActiveWalkthrough?: boolean
}

const
  ListEntryRow: React.FC<Props> = ({ withInlineForm, setCurrentSelection, currentSelection, entrySummary, otherEntities, experiment, hasActiveWalkthrough }) => {
    const
      THEME = useTheme(),
      { entry, isSelected, section, actualIndex, nthOfKind } = entrySummary

    if (isSelected && withInlineForm) {
      return <ListEntryForm {...{ setCurrentSelection, currentSelection, entrySummary, nthOfKind, withInlineForm }} />
    } else if (isSelected) {
      return (
        <CollapsedRow
          $selected
          heading=""
          $closing={section?.elementVariety === 'LIST_CLOSER_SECTION'}
          icon={<ArrowStraight direction="right" color={THEME.colors.back} />}
        />
      )
    } else {
      const
        icon = entry
          ? <IconForEntry {...{ entry, actualIndex: (actualIndex as number), nthOfKind: nthOfKind as number, section }} />
          : <IconForEntry {...{ entry: undefined, actualIndex: undefined, nthOfKind: undefined, section }} />,
        heading = getHeadingForEntry({ nthOfKind, section, entry, otherEntities, experiment }),
        subheading = getSubheadingForEntry({ nthOfKind, section, entry }),
        onClick = entry
          ? () => { setCurrentSelection({ entryIndex: entrySummary.actualIndex as number }) }
          : section && section.elementVariety === 'LIST_CLOSER_SECTION'
            ? () => { setCurrentSelection({ segment: 'LIST_CLOSER_SECTION' }) }
            : undefined

      return (
        <CollapsedRow
          icon={icon}
          heading={heading}
          subheading={subheading}
          onClick={hasActiveWalkthrough ? undefined : onClick}
        />
      )
    }
  }

export default ListEntryRow
