import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { findIndex, maxBy } from 'lodash'
import { emBaseInputHPadding, emBaseInputLineHeight, emBaseInputVPadding, InputUnderline, remBaseInputFontSize } from './BaseInput'
import type { FullOptionList, ListValue } from '~/form-brain2'
import { isEmptyValue } from '~/form-brain2/testers'
import { useFocusOnToggle } from '~/hooks'
import { mergeRefs } from '~/hooks/utils'

interface FauxSelectProps {
  name: string
  id?: string
  selected: ListValue
  disabled?: boolean
  readOnly?: boolean
  onSelect: (val: ListValue) => void
  options: FullOptionList
  innerRef?: React.MutableRefObject<unknown>
  placeholder?: string
  defaultOpen?: boolean
  hasError?: boolean
  errNarrow?: boolean
  naturalWidth?: boolean
  className?: string
}

interface OptionListItemProps {
  label: string | number
  value: ListValue
  innerRef?: ((el: HTMLButtonElement) => void) | React.RefObject<HTMLButtonElement>
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  showCaret?: boolean
  isOption?: boolean
  isSelected?: boolean
  disabled?: boolean
  readOnly?: boolean
  understate?: boolean
  hasError?: boolean
  dataCy?: string
}

const
  noop: React.MouseEventHandler = (e) => { if (e) { e.preventDefault(); e.stopPropagation() } },
  SelectOuter = styled.div`
    position: relative;
  `,
  Spacer = styled.div<{ $errNarrow?: boolean }>`
    height: 0px;
    overflow-y: hidden;
    padding: 0 1em 0 calc(1em + ${p => p.$errNarrow ? -5 : 20}px);
  `,
  DataList = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    background: ${p => p.theme.colors.bodyBg};
    z-index: ${p => p.theme.zLocalTop + 2};
    box-shadow: 1px 1.5px 4px 0px ${p => p.theme.colors.shadow};

    max-height: ${6.4 * 2.5}em;
    overflow-y: auto;
  `,
  nextEnabled = (el: HTMLElement, reverse?: boolean): Element | null => {
    let e: Element | null = el

    // eslint-disable-next-line no-cond-assign
    while (e = reverse ? e.previousElementSibling : e.nextElementSibling) {
      if (!(e as HTMLButtonElement)?.disabled) {
        break
      }
    }

    return (e as HTMLButtonElement)?.disabled
      ? null
      : e
  },
  setupListCloseHandler = (insideElement: HTMLElement | null, closeHandler: ((newVal: boolean) => void)): (() => void) | undefined => {
    if (!insideElement) { return undefined }

    const
      handleClick = (e: MouseEvent): void => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        if (insideElement && e.target && !insideElement.contains(e.target as Node)) {
          closeHandler(false)
        }
      },
      handleKeyPress = (e: KeyboardEvent): void => {
        let doStopPropagation = true
        const focusedOption = document.activeElement?.closest('button')

        if (
          insideElement.contains(document.activeElement) &&
          focusedOption
        ) {
          if (e.key === 'Escape') {
            closeHandler(false)
          } else if (e.key === 'ArrowUp') {
            const prev = nextEnabled(focusedOption, true)

            if (prev && 'focus' in prev) { (prev as HTMLButtonElement).focus() }
          } else if (e.key === 'ArrowDown') {
            const next = nextEnabled(focusedOption)

            if (next && 'focus' in next) { (next as HTMLButtonElement).focus() }
          } else {
            doStopPropagation = false
          }
        } else {
          doStopPropagation = false
        }

        if (e && doStopPropagation) { e.preventDefault(); e.stopPropagation() }
      }

    document.addEventListener('mousedown', handleClick)
    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('mousedown', handleClick)
      document.removeEventListener('keydown', handleKeyPress)
    }
  },
  ListItemButton = styled.button<{ $hasError?: boolean, $noClick?: boolean, $highlight?: boolean }>`
    position: relative;
    width: 100%;
    font-size: ${remBaseInputFontSize}rem;
    font-weight: ${p => p.$highlight ? 'bold' : 'normal'};
    line-height: ${emBaseInputLineHeight * 1.15}em; // 1.35 matched input because inputs get some weird handling
    overflow: hidden;

    display: flex;
    flex-direction: row;
    gap: ${p => p.theme.emSmallSpacing / 2}em;

    padding: ${emBaseInputVPadding}em ${emBaseInputHPadding}em;
    background: ${p => !!p.disabled || p['aria-readonly'] ? 'transparent' : p.$highlight ? p.theme.colors.containerBg : p.theme.colors.bodyBg};
    border: ${p => p.theme.pxBorderWidth}px solid ${p => p.$hasError ? p.theme.colors.cautionStandaloneText : 'transparent'};

    ${p => p.theme.accentFont}
    color: ${p => p.theme.colors.bodyText};
    cursor: ${p => p.$noClick ? 'auto' : 'pointer'};

    :focus {
      outline: 1px solid transparent;
      border-color: ${p => p.$hasError ? p.theme.colors.cautionStandaloneText : p.theme.colors.borderAccentAlt};
      outline: ${p => p.$hasError ? `1px solid ${p.theme.colors.cautionStandaloneText}` : 'none'};
    }

    svg {
      top: ${emBaseInputLineHeight + emBaseInputVPadding + 0.25}em;
    }
  `,
  ListItemText = styled.div<{ $understate?: boolean }>`
    font-size: inherit;
    line-height: inherit;
    color: ${p => p.$understate ? p.theme.colors.understateMed : 'inherit'};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
  CaretIcon = styled.div<{ $understate?: boolean }>`
    color: ${p => p.$understate ? p.theme.colors.understateMed : p.theme.colors.understateDark};
    display: inline-block;
    min-width: 20px;
    font-size: 50px;
    margin-top: -6.5px;
    margin-left: auto;
    font-family: "Patrick Hand", cursive;
    line-height: 5px;
    transform: rotate(180deg);
  `,
  ListItem: React.FC<OptionListItemProps> = ({ label, showCaret, hasError, isOption, innerRef, onClick, value, isSelected, disabled, readOnly, understate, dataCy }) => {
    const THEME = useTheme()

    return (
      <ListItemButton
        role={isOption ? 'option' : undefined}
        data-cy={dataCy}
        onClick={!!disabled || readOnly ? noop : onClick}
        value={value}
        aria-selected={isSelected}
        aria-readonly={readOnly}
        disabled={disabled}
        ref={innerRef}
        $highlight={isSelected && isOption}
        $noClick={!!disabled || readOnly}
        $hasError={hasError}>
        <ListItemText $understate={!!understate || (disabled && !readOnly)}>{label}</ListItemText>
        {showCaret ? <CaretIcon $understate={disabled}>^</CaretIcon> : null}
        <InputUnderline color={THEME.colors.borderAccentAlt} />
      </ListItemButton>
    )
  },
  FauxSelect: React.FC<FauxSelectProps> = ({ id, onSelect, innerRef, options, selected, placeholder, defaultOpen, disabled, readOnly, hasError, errNarrow, naturalWidth, className, ...otherProps }) => {
    const
      [isOpen, setIsOpen] = React.useState(defaultOpen ?? false),
      listRef = React.useRef<HTMLDivElement>(null),
      selectedOptionRef = useFocusOnToggle(isOpen),
      setRefConditionally = (shouldSet: boolean) => (el: HTMLButtonElement): void => {
        if (shouldSet) { selectedOptionRef.current = el }
      },
      buttonOnClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }
        setIsOpen(true)
      },
      onOptionSelect = (value: ListValue): React.MouseEventHandler<HTMLButtonElement> => (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        onSelect(value)
        setIsOpen(false)
      },
      selectedLabel = options.find(o => o.value === selected)?.label ?? selected, // Show selected value even if no longer present in choices
      visibleOptions = options.filter(o => !o.hidden),
      firstVisibleIndex = findIndex(visibleOptions, o => !o.disabled)

    React.useEffect(() => {
      return setupListCloseHandler(listRef.current, setIsOpen)
    }, [isOpen, setIsOpen])

    return (
      <SelectOuter id={id} role="listbox" data-cy="faux-select" className={className} aria-disabled={disabled} aria-readonly={readOnly}>
        {naturalWidth && !isOpen
          ? null
          : <Spacer $errNarrow={errNarrow}>{maxBy(visibleOptions, o => o.label?.toString().length ?? 0)?.label}</Spacer>}
        <ListItem
          showCaret={!readOnly && !disabled}
          dataCy="faux-select-base"
          value={selected}
          hasError={hasError}
          understate={!selectedLabel || disabled}
          onClick={readOnly ? undefined : buttonOnClick} // Make sure nothing happens when readonly since not disabling
          label={visibleOptions.length && visibleOptions.some(o => !isEmptyValue(o.value))
            ? selectedLabel ?? placeholder ?? visibleOptions.find(o => isEmptyValue(o.value))?.label ?? (readOnly ? '(Blank)' : 'Choose an option...')
            : 'None Found'
          }
          disabled={disabled}
          readOnly={!!disabled || readOnly} // Because this is the root, disabled and readonly do the same thing
          innerRef={mergeRefs(setRefConditionally(!isOpen), innerRef)}
        />
        {isOpen
          ? <DataList ref={listRef} data-cy="faux-select-popup">
              {placeholder
                ? <ListItem
                  label={placeholder}
                  isOption
                  value={undefined}
                  disabled
                  showCaret
                />
                : null}
              {visibleOptions.map(({ label, value, disabled }, index) => {
                const isSelected = value === selected

                return <ListItem
                  key={`${value ? value.toString() : 'undefined'}-${index}`}
                  label={(label ?? value) as string}
                  onClick={onOptionSelect(value)}
                  isOption
                  value={value}
                  isSelected={isSelected && !disabled}
                  disabled={disabled}
                  showCaret={index === 0 && !placeholder}
                  innerRef={setRefConditionally(isSelected || (index === firstVisibleIndex && isEmptyValue(selected)))}
                />
              })}
            </DataList>
          : null}
      </SelectOuter>
    )
  }

export default FauxSelect
