import * as React from 'react'
import styled from 'styled-components'
import { Icon } from '~/components/atoms/Icons'

interface ErrorTextProps {
  children: React.ReactNode
  warnOnly?: boolean
  className?: string
}

const
  ErrorTextOuter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  StyledIcon = styled(Icon)`
    width: 30px;
    flex: 0 0 30px;
    margin-right: -8px;
  `,
  Text = styled.div<{ $warnOnly?: boolean }>`
    display: flex;
    justify-content: flex-end;
    padding: 0 ${p => p.theme.emSmallSpacing}em;

    font-size: 1rem;
    line-height: 1.6;

    color: ${p => p.$warnOnly ? p.theme.colors.attentionStandaloneText : p.theme.colors.cautionStandaloneText};
    ${p => p.theme.accentFont};
  `,
  ErrorText: React.FC<ErrorTextProps> = ({ children, warnOnly, className }) => {
    return (
      <ErrorTextOuter className={className} data-cy="error-text">
        <StyledIcon frameStyle="outline" content="!" color={warnOnly ? 'attention' : 'caution'} degreesRotation={-11} />
        <Text $warnOnly={warnOnly} className="error-text-message">{children}</Text>
      </ErrorTextOuter>
    )
  }

export { Text as ErrorTextOnly }
export default ErrorText
