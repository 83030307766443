import * as React from 'react'
import { isEqual } from 'lodash'
import { FormContext } from './FormContext'
import { getContextAndResolvers, getFormFieldDetails } from './helpers'
import type { BodyElement } from '~/form-brain2'
import type { EntityObject, FormFieldRendererComponentType, ToggleFn, UpdateValueFn } from './types'

export interface FormFieldProps {
  bodyElement: BodyElement
  FormFieldRenderer: FormFieldRendererComponentType
}

export const FormField: React.FC<FormFieldProps> = (props) => {
  const
    // Context
    { FormFieldRenderer, bodyElement } = props,
    formContext = React.useContext(FormContext),
    contextAndResolvers = getContextAndResolvers(formContext),
    fieldDetails = getFormFieldDetails({ bodyElement, contextAndResolvers }),

    // State
    [hintToggledOn, setHintToggledOn] = React.useState(fieldDetails.hintDefaultVisible),
    [initialValue] = React.useState(fieldDetails.currentValue),
    isDirty = !isEqual(fieldDetails.currentValue, initialValue),
    showError = isDirty || formContext.showingAllErrors,

    // Props
    toggleHint: ToggleFn = () => { setHintToggledOn(_showHint => !_showHint) },
    updateAttr: UpdateValueFn = (nextValue, silentUpdate) => {
      let nextValues: EntityObject = { ...formContext.values, [fieldDetails.name]: nextValue } // TODO consider changing this back to just new values

      if (formContext.onAttrChangeCallback) {
        nextValues = formContext.onAttrChangeCallback({
          bodyElement,
          nextValue,
          nextValues,
          contextAndResolvers
        })
      }
      if (formContext.updateValues) {
        formContext.updateValues({ newValues: nextValues, silentUpdate })
      }
    }

  return <FormFieldRenderer
    updateAttr={updateAttr}
    showHint={hintToggledOn}
    showError={showError}
    toggleHint={fieldDetails.hint ? toggleHint : undefined}
    isDirty={isDirty}
    formOptions={formContext.formOptions}
    {...fieldDetails}
  />
}

export default FormField
