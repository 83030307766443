import { mapValues, values } from 'lodash'
import { parseNumIfPossible } from '~/utils/formatters'
import { isAllEmpty, isEmptyValue, isNumeric } from '~/utils/testers'
import { getCalculatedValue, getValueAcrossEntities } from '~/features/experiment/calculatedValues'
import { getTreatmentObjects } from '~/features/experiment/fieldSelectors/treatmentSelectors'
import {
  FERTILIZER_TIMING_REFERENCES,
  INDEPENDENT_VARIABLES,
  MEASUREMENT_REPEAT_AFTER_WATERING,
  MEASUREMENT_START_UNITS,
  SCHEDULED_IRRIGATION_TIMING_CONDITION_MATCH,
  SystemFieldNames
} from '~/features/experiment/systemFields'

import type { EntityObject } from '~/form-brain2'
import type { EmptyObject } from '~/types/utilities'
import type { SpecialListSectionElement, MadLibElement, ListQuestionnaireDefault, AnyPlainOrInputElement } from '~/features/questionnaire/types'
import type { ListWorksheet } from '~/features/worksheet/types'
import type { ListEntry } from '~/features/worksheetResponse/types'
import type {
  MeasurementVariables,
  FertilizerTimingTreatmentFormat,
  IrrigationTimingTreatmentFormat
} from '~/features/experiment/systemFields'

export type ScheduleActivity = {
  relativeTime: 'day(s) before start'
  startDay: string
  isRepeating?: undefined
  repeatIntervalDays?: undefined
  hasRepeatEndDay?: undefined
  repeatEndDay?: undefined
} | {
  relativeTime: undefined
  startDay: string
  isRepeating?: undefined
  repeatIntervalDays?: undefined
  hasRepeatEndDay?: undefined
  repeatEndDay?: undefined
} | {
  relativeTime: undefined
  startDay: string
  isRepeating: 'then every'
  repeatIntervalDays: string
  hasRepeatEndDay?: 'yes'
  repeatEndDay?: string
}

/* planting, set-up, gather materials, fertilizing+, watering+, germination, thinning, harvest, measure */
interface CoreSchedule {
  gatherMaterials: number
  setUp: number
  planting: number
  germination: number | undefined
  thinning: number | undefined
  harvestStart: number | undefined
  fertilizer: number | undefined
  waterStart: number | undefined
  waterRepeat: number | undefined
  waterCondition: string | undefined
  lastDay: number | undefined
  measurementStart: number | undefined
  measurementRepeat: number | undefined
  measurementEnd: number | undefined
  treatments?: Array<{
    label: string
    day?: number
    repeat?: number
  }>
}

type InitialSchedule = Omit<CoreSchedule, 'gatherMaterials' | 'setUp'>

export const
  sharedTimingFields: MadLibElement['segments'] = [
    {
      id: 'when-3',
      elementVariety: 'SELECT_INPUT',
      fieldName: 'isRepeating',
      // isTransient: true,
      blankOption: 'once',
      invalidates: ['repeatIntervalDays', 'hasRepeatEndDay', 'repeatEndDay'],
      list: [
        { optionValue: 'then every' }
      ],
      visibleWhen: [{ valueName: 'relativeTime', conditionType: 'absent', conditionScope: 'ownScope' }]
    },
    {
      id: 'when-4',
      elementVariety: 'NUMBER_INPUT',
      fieldName: 'repeatIntervalDays',
      unit: 'days',
      visibleWhen: [{ valueName: 'isRepeating', conditionType: 'present', conditionScope: 'ownScope' }],
      requiredWhen: [{ valueName: 'isRepeating', conditionType: 'present', conditionScope: 'ownScope' }]
    },
    {
      id: 'when-5',
      elementVariety: 'SELECT_INPUT',
      fieldName: 'hasRepeatEndDay',
      invalidates: ['repeatEndDay'],
      // isTransient: true,
      blankOption: 'until the end of the project',
      list: [
        { optionValue: 'yes', optionLabel: 'until day...' }
      ],
      visibleWhen: [{ valueName: 'isRepeating', conditionType: 'present', conditionScope: 'ownScope' }]
    },
    {
      id: 'when-6',
      elementVariety: 'NUMBER_INPUT',
      fieldName: 'repeatEndDay',
      visibleWhen: [
        { valueName: 'isRepeating', conditionType: 'present', conditionScope: 'ownScope' },
        { valueName: 'hasRepeatEndDay', conditionType: 'present', conditionScope: 'ownScope' }
      ],
      requiredWhen: [
        { valueName: 'isRepeating', conditionType: 'present', conditionScope: 'ownScope' },
        { valueName: 'hasRepeatEndDay', conditionType: 'present', conditionScope: 'ownScope' }
      ]
    }
  ],
  getScheduleActivityForm = (section: SpecialListSectionElement): AnyPlainOrInputElement[] => ([
    {
      id: 'plan-activity-1',
      elementVariety: 'SHORT_ANSWER',
      fieldName: 'description',
      label: section.whatLabel ?? 'What will you do?',
      requiredWhen: [{ conditionType: 'always' }]
    },
    {
      id: 'plan-activity-2',
      elementVariety: 'FILL_IN_THE_BLANK',
      label: section.whenLabel ?? 'When?',
      segments: [
        {
          id: 'when-2',
          elementVariety: 'NUMBER_INPUT',
          fieldName: 'startDay',
          requiredWhen: [{ conditionType: 'always' }],
          visibleWhen: [{ valueName: 'relativeTime', conditionType: 'present', conditionScope: 'ownScope' }]
        },
        {
          id: 'when-1',
          elementVariety: 'SELECT_INPUT',
          fieldName: 'relativeTime',
          blankOption: 'On day',
          list: [
            { optionValue: 'day(s) before start' }
          ]
        },
        {
          id: 'when-2',
          elementVariety: 'NUMBER_INPUT',
          fieldName: 'startDay',
          requiredWhen: [{ conditionType: 'always' }],
          visibleWhen: [{ valueName: 'relativeTime', conditionType: 'absent', conditionScope: 'ownScope' }]
        },
        ...sharedTimingFields
      ]
    }
  ]),
  getScheduleMeasurementForm = (section: SpecialListSectionElement): AnyPlainOrInputElement[] => ([
    {
      id: 'plan-measurement-1',
      elementVariety: 'SHORT_ANSWER',
      fieldName: 'description',
      label: section.whatLabel ?? 'What will you measure?',
      requiredWhen: [{ conditionType: 'always' }]
    },
    {
      id: 'plan-measurement-2',
      elementVariety: 'FILL_IN_THE_BLANK',
      label: section.whenLabel ?? 'When?',
      segments: [
        {
          id: 'when-1',
          elementVariety: 'TEXT',
          text: 'On day'
        },
        {
          id: 'when-2',
          elementVariety: 'NUMBER_INPUT',
          fieldName: 'startDay',
          requiredWhen: [{ conditionType: 'always' }]
        },
        ...sharedTimingFields
      ]
    }
  ]),
  getScheduleTimingString = (entry: ListEntry): string | undefined => {
    if (isNumeric(entry.startDay) && parseInt(entry.startDay as string) === 0) {
      return 'Before starting the experiment'
    }

    const vals = (entry.relativeTime as string | undefined)?.includes('before')
      ? [
          entry.startDay,
          entry.relativeTime
        ]
      : [
          isEmptyValue(entry.startDay) ? null : entry.relativeTime ?? 'On day',
          entry.startDay,
          entry.isRepeating,
          entry.repeatIntervalDays,
          (entry.repeatIntervalDays ? 'days' : null),
          (entry.repeatIntervalDays && entry.repeatEndDay ? 'until day' : null),
          (entry.repeatIntervalDays && entry.repeatEndDay ? entry.repeatEndDay : null)
        ]

    return vals.filter(o => o).join(' ')
  },
  scheduleSortFn = (entry: ScheduleActivity): number | undefined => {
    if (!isNumeric(entry.startDay)) { return undefined }

    const numericVal = parseInt(entry.startDay)

    return entry.relativeTime?.match('before')
      ? -numericVal
      : numericVal
  },
  getFertilizerTiming = (fertilizerTiming: FertilizerTimingTreatmentFormat | undefined, events: InitialSchedule): number | undefined => {
    if (!fertilizerTiming) { return undefined }

    const
      { fertilizerTimingReference, fertilizerTimingValue: stringTimingValue, fertilizerTimingUnit } = fertilizerTiming,
      timingValue = parseNumIfPossible(stringTimingValue),
      isWeeks = fertilizerTimingUnit?.includes('week')

    if (fertilizerTimingReference === FERTILIZER_TIMING_REFERENCES.AT_PLANTING) {
      return events.planting
    } else if (fertilizerTimingReference === FERTILIZER_TIMING_REFERENCES.AT_GERMINATION) {
      return events.germination
    } else if (fertilizerTimingReference === FERTILIZER_TIMING_REFERENCES.BEFORE_PLANTING && timingValue != null) {
      return events.planting - (timingValue * (isWeeks ? 7 : 1))
    } else if (fertilizerTimingReference === FERTILIZER_TIMING_REFERENCES.AFTER_PLANTING && timingValue != null) {
      return events.planting + (timingValue * (isWeeks ? 7 : 1))
    }
  },
  getIrrigationRepeat = (irrigationTiming: IrrigationTimingTreatmentFormat | undefined, events: InitialSchedule): number | undefined => {
    const
      irrTimingValue = irrigationTiming?.irrigationTimingValue,
      irrTimingUnit = irrigationTiming?.irrigationTimingUnit,
      numRepeat = parseNumIfPossible(irrTimingValue)

    if (!irrTimingUnit || numRepeat == null) { return undefined }

    const
      multiplier = irrTimingUnit.includes('week')
        ? 7
        : 1

    return numRepeat * multiplier
  },
  getShiftedSchedule = (originalSchedule: InitialSchedule): CoreSchedule => {
    const
      allDays = values(originalSchedule).flatMap(el => {
        if (Array.isArray(el)) {
          return el.map(o => o.day)
        }

        return el
      }).filter(el => typeof el === 'number') as number[],
      earliestDay = Math.min(...allDays),
      differential = 1 - earliestDay,
      shiftedSchedule = mapValues(originalSchedule, (v, k) => {
        if (typeof v === 'number' && !k.match(/repeat/i)) {
          return v + differential
        }

        if (Array.isArray(v)) {
          return v.map(o => ({ ...o, day: typeof o.day === 'number' ? o.day + differential : o.day }))
        }

        return v
      }) as InitialSchedule

    return {
      ...shiftedSchedule,
      gatherMaterials: 0,
      setUp: 1
    }
  },
  getCoreSchedule = (entities: EntityObject[]): CoreSchedule => {
    const
      independentVariable = getValueAcrossEntities(SystemFieldNames.independentVariable, entities),
      fertilizerTiming = getValueAcrossEntities(SystemFieldNames.fertilizerTimingValue, entities, true) as FertilizerTimingTreatmentFormat | undefined,
      doIrrigate = getValueAcrossEntities('howWaterPlants', entities) === 'manual',
      irrigationTiming = getValueAcrossEntities(SystemFieldNames.irrigationTimingValue, entities, true) as IrrigationTimingTreatmentFormat | undefined,
      irrTimingCondition = irrigationTiming?.irrigationTimingCondition,
      measTimingScheme = getValueAcrossEntities(SystemFieldNames.measurementTimingScheme, entities, true) as MeasurementVariables['measurementTimingScheme'] | undefined,
      measStartValue = getValueAcrossEntities(SystemFieldNames.measurementStartValue, entities, true) as MeasurementVariables['measurementStartValue'] | undefined,
      measStartUnit = getValueAcrossEntities(SystemFieldNames.measurementStartUnit, entities, true) as MeasurementVariables['measurementStartUnit'] | undefined,
      measRepeatValue = getValueAcrossEntities(SystemFieldNames.measurementRepeatValue, entities, true) as MeasurementVariables['measurementRepeatValue'] | undefined,
      measRepeatUnit = getValueAcrossEntities(SystemFieldNames.measurementRepeatUnit, entities, true) as MeasurementVariables['measurementRepeatUnit'] | undefined,
      measEndValue = getValueAcrossEntities(SystemFieldNames.measurementEndValue, entities, true) as MeasurementVariables['measurementEndValue'] | undefined,
      measEndUnit = getValueAcrossEntities(SystemFieldNames.measurementEndUnit, entities, true) as MeasurementVariables['measurementEndUnit'] | undefined,
      germinationDay = getValueAcrossEntities(SystemFieldNames.germinationDay, entities) as string | undefined,
      thinningDay = getValueAcrossEntities(SystemFieldNames.thinningDay, entities) as string | undefined,
      seedsPerPlant = getCalculatedValue('calculatedSeedsPerPlant', entities) as string,
      harvestStart = getValueAcrossEntities(SystemFieldNames.harvestDay, entities) as string | undefined,
      endDay = getValueAcrossEntities(SystemFieldNames.endDay, entities) as string | undefined,
      numGerminationDay = parseNumIfPossible(germinationDay),
      numThinningDay = parseInt(seedsPerPlant) > 1 ? parseNumIfPossible(thinningDay) : undefined,
      numHarvestDay = parseNumIfPossible(harvestStart),
      numMeasStartDay = parseNumIfPossible(measStartValue),
      numMeasEndDay = parseNumIfPossible(measEndValue),
      events: InitialSchedule = {
        planting: 1,
        germination: numGerminationDay,
        thinning: !isEmptyValue(numThinningDay) && !isEmptyValue(numGerminationDay)
          ? (numGerminationDay as number) + (numThinningDay as number)
          : undefined,
        harvestStart: !isEmptyValue(numHarvestDay) && !isEmptyValue(numGerminationDay)
          ? (numGerminationDay as number) + (numHarvestDay as number)
          : undefined,
        lastDay: parseNumIfPossible(endDay),

        fertilizer: undefined,
        waterStart: undefined,
        waterRepeat: undefined,
        waterCondition: irrTimingCondition,
        measurementStart: undefined,
        measurementRepeat: undefined,
        measurementEnd: undefined,
        treatments: []
      }

    if (independentVariable !== INDEPENDENT_VARIABLES.FERTILIZER_TIMING) {
      events.fertilizer = getFertilizerTiming(fertilizerTiming, events)
    }

    if (independentVariable !== INDEPENDENT_VARIABLES.IRRIGATION_TIMING && doIrrigate) {
      events.waterStart = events.planting
      events.waterRepeat = getIrrigationRepeat(irrigationTiming, events)
    }

    if (measStartUnit && !isEmptyValue(numMeasStartDay)) {
      if (measStartUnit === MEASUREMENT_START_UNITS.BEFORE_PLANTING) {
        events.measurementStart = events.planting - (numMeasStartDay ?? 0)
      }

      if (measStartUnit === MEASUREMENT_START_UNITS.AFTER_PLANTING) {
        events.measurementStart = events.planting + (numMeasStartDay ?? 0)
      }

      if (measStartUnit === MEASUREMENT_START_UNITS.AFTER_GERMINATION && events.germination) {
        events.measurementStart = events.germination + (numMeasStartDay ?? 0)
      }
    }

    if (measTimingScheme === 'repeating') {
      if (measRepeatUnit) {
        const repeatValue = parseNumIfPossible(measRepeatValue)

        if (measRepeatUnit === MEASUREMENT_REPEAT_AFTER_WATERING && independentVariable !== INDEPENDENT_VARIABLES.IRRIGATION_TIMING) {
          events.measurementRepeat = events.waterRepeat
        }

        if (measRepeatUnit.includes('week') && repeatValue != null) {
          events.measurementRepeat = 7 * repeatValue
        }

        if (measRepeatUnit.includes('day') && repeatValue != null) {
          events.measurementRepeat = repeatValue
        }
      }

      if (measEndUnit && !isEmptyValue(numMeasEndDay)) {
        if (measEndUnit === 'days after planting') {
          events.measurementEnd = events.planting + (numMeasEndDay ?? 0)
        }

        if (measEndUnit === 'days after germination' && events.germination) {
          events.measurementEnd = events.germination + (numMeasEndDay ?? 0)
        }

        if (measEndUnit === 'days after first measurement' && events.measurementStart != null) {
          events.measurementEnd = events.measurementStart + (numMeasEndDay ?? 0)
        }
      }
    }

    if (independentVariable === INDEPENDENT_VARIABLES.FERTILIZER_TIMING) {
      const treatmentObjects = getTreatmentObjects(entities)

      events.treatments = treatmentObjects.map(obj => {
        const
          treatment = obj.rawTreatment,
          fertilizerTiming = (treatment as FertilizerTimingTreatmentFormat),
          timingDay = getFertilizerTiming(fertilizerTiming, events)

        return { label: obj.treatmentName ?? obj.treatment, day: timingDay }
      })
    }

    if (independentVariable === INDEPENDENT_VARIABLES.IRRIGATION_TIMING) {
      const treatmentObjects = getTreatmentObjects(entities)

      events.treatments = treatmentObjects.map(obj => {
        const
          treatment = obj.rawTreatment,
          irrTiming = (treatment as IrrigationTimingTreatmentFormat)

        return {
          label: obj.treatmentName ?? obj.treatment,
          day: events.planting,
          repeat: getIrrigationRepeat(irrTiming, events)
        }
      })
    }

    return getShiftedSchedule(events)
  },
  SCHEDULE_EVENT_DESCRIPTIONS = {
    plant: 'Plant your seeds',
    harvest: 'Earliest harvest date',
    end: 'Planned last day of project',
    germination: 'Seeds expected to have germinated'
  },
  activityAutomaticEntryKeyLabels = {
    'apply treatments': 'Apply Treatments',
    plant: 'Planting',
    water: 'Watering',
    germination: 'Germination',
    thin: 'Thinning',
    fertilize: 'Fertilizer Application',
    harvest: 'Start of Harvest',
    end: 'End of Project'
  },
  measurementAutomaticEntryKeyLabels = {
    measure: 'Taking Measurements'
  },
  getScheduleDefault = (obj: ListQuestionnaireDefault, worksheet: ListWorksheet, otherEntities: EntityObject[]): ScheduleActivity[] | ScheduleActivity | EmptyObject => {
    const
      { automaticEntryKey } = obj,
      independentVariable = getValueAcrossEntities(SystemFieldNames.independentVariable, otherEntities),
      measurementSectionId = worksheet.repeatingSections.find(o => (o as SpecialListSectionElement).specialSectionId === 'PLAN_MEASUREMENT')?.id,
      activitySectionId = worksheet.repeatingSections.find(o => (o as SpecialListSectionElement).specialSectionId === 'PLAN_ACTIVITY')?.id,
      coreSchedule = getCoreSchedule(otherEntities)

    if (automaticEntryKey === 'plant') {
      return {
        description: SCHEDULE_EVENT_DESCRIPTIONS[automaticEntryKey],
        startDay: coreSchedule.planting,
        sectionId: activitySectionId
      }
    }

    if (automaticEntryKey === 'water' && !isEmptyValue(coreSchedule.waterStart)) {
      const description = (coreSchedule.waterCondition === SCHEDULED_IRRIGATION_TIMING_CONDITION_MATCH) || !coreSchedule.waterCondition
        ? 'Water plants'
        : `Water plants ${coreSchedule.waterCondition}`

      return {
        description,
        startDay: coreSchedule.waterStart,
        isRepeating: isEmptyValue(coreSchedule.waterRepeat) ? undefined : 'then every',
        repeatIntervalDays: coreSchedule.waterRepeat,
        sectionId: activitySectionId
      }
    }

    if (automaticEntryKey === 'germination' && !isEmptyValue(coreSchedule.germination)) {
      return {
        description: SCHEDULE_EVENT_DESCRIPTIONS[automaticEntryKey],
        startDay: coreSchedule.germination,
        sectionId: activitySectionId
      }
    }

    const seedsPerPlant = getCalculatedValue('calculatedSeedsPerPlant', otherEntities)

    if (automaticEntryKey === 'thin' && !isEmptyValue(seedsPerPlant)) {
      return {
        description: `Thin from ${seedsPerPlant as string} seedlings to 1`,
        startDay: coreSchedule.thinning,
        sectionId: activitySectionId
      }
    }

    if (automaticEntryKey === 'fertilize' && independentVariable !== INDEPENDENT_VARIABLES.FERTILIZER_TIMING && !isEmptyValue(coreSchedule.fertilizer)) {
      const description = getCalculatedValue('fertilizerDescription', otherEntities)

      return {
        description: description ? `Apply fertilizer: ${description}` : 'Apply fertilizer',
        startDay: coreSchedule.fertilizer,
        sectionId: activitySectionId
      }
    }

    if (automaticEntryKey === 'harvest' && !isEmptyValue(coreSchedule.harvestStart)) {
      return {
        description: SCHEDULE_EVENT_DESCRIPTIONS[automaticEntryKey],
        startDay: coreSchedule.harvestStart,
        sectionId: activitySectionId
      }
    }

    if (automaticEntryKey === 'end' && !isEmptyValue(coreSchedule.lastDay)) {
      return {
        description: SCHEDULE_EVENT_DESCRIPTIONS[automaticEntryKey],
        startDay: coreSchedule.lastDay,
        sectionId: activitySectionId
      }
    }

    if (automaticEntryKey === 'measure') {
      const description = getCalculatedValue('measurementUnitDescription', otherEntities)

      return {
        description: description ? `Measure ${description}` : 'Take measurements',
        sectionId: measurementSectionId,
        startDay: coreSchedule.measurementStart,
        isRepeating: isEmptyValue(coreSchedule.measurementRepeat) ? undefined : 'then every',
        repeatIntervalDays: coreSchedule.measurementRepeat,
        hasRepeatEndDay: isEmptyValue(coreSchedule.measurementEnd) ? undefined : 'yes',
        repeatEndDay: coreSchedule.measurementEnd
      }
    }

    if (automaticEntryKey === 'apply treatments') {
      if (!coreSchedule.treatments || isAllEmpty(coreSchedule.treatments)) {
        /** Decided to take out the stray treatment activity when we don't
         * know where it fits, because it goes to the end and that is confusing */
        return {
          // description: 'Apply treatments'
        }
      } else {
        return coreSchedule.treatments.map(o => ({
          description: `Apply treatment: ${o.label}`,
          startDay: o.day,
          isRepeating: isEmptyValue(o.repeat) ? undefined : 'then every',
          repeatIntervalDays: o.repeat,
          sectionId: activitySectionId
        }))
      }
    }

    return {}
  }
