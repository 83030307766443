import * as React from 'react'
import type { BasicIconProps } from '~/types/utilities'

const
  BasicCircle: React.FC<BasicIconProps> = ({ className, color = 'black', outlineColor }) => {
    return (
      <svg
        className={className}
        viewBox="-1.5 -1.5 37 37"
        xmlns="http://www.w3.org/2000/svg"
        data-cy="basic-circle">
        <circle
          cx="17"
          cy="17"
          r="17"
          fill={color}
          stroke={outlineColor}
          strokeWidth="2.2px" />
      </svg>
    )
  }

export default BasicCircle
