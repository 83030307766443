import * as React from 'react'
import styled from 'styled-components'
import { FormCollection, FormContext, FormField, FormScope, getContextAndResolvers } from '~/form-brain2'
import { isEmptyValue, isAllEmpty } from '~/utils/testers'
import {
  SYSTEM_FIELDS,
  addEmphasisToString,
  comparableInputVarieties,
  interpolateStringInForm
} from '~/features'
import { HeadingThree, HintText, Icon, SubheadingOne } from '~/components/atoms'
import Blurb from '~/components/molecules/Blurb'
import CompoundInputWrapper from '~/components/molecules/FormElements/CompoundInputWrapper'
import BlurbBuilder from '~/components/molecules/FormElements/BlurbBuilder'
import StepFormatInfo from '~/components/molecules/FormElements/StepFormatInfo'
import CollapsibleSection from '~/components/molecules/FormElements/CollapsibleSection'
import MascotPreview from '~/components/molecules/FormElements/MascotPreview'
import MadLibEditor from '~/components/molecules/FormElements/MadLibEditor'
import BodyElementListItem from '~/components/molecules/FormAternates/BodyElementListItem'
import ConditionListItem from '~/components/molecules/FormAternates/ConditionListItem'
import SystemFieldDefaultCollection from '~/components/molecules/FormAternates/SystemFieldDefaultCollection'
import FormFieldRenderer from './FormFieldRenderer'
import ScopeRenderer from './ScopeRenderer'
import CollectionRenderer from './CollectionRenderer'

import type { BodyElement, SchemaEntry } from '~/form-brain2'
import type {
  CollectionElement,
  InstructionsElement,
  BlurbElement,
  MadLibElement,
  ScopeElement,
  TextElement,
  InputElement,
  SubheadingElement,
  SpecialElement,
  QuestionnaireFormat,
  CollapsibleElement,
  HeadingElement,
  FieldsetElement,
  SystemFieldName,
  AnyInputElement,
  VisibilityCondition,
  ListOption
} from '~/features'
import type { FormContextType } from '~/form-brain2/internalTypes'

interface Props {
  bodyElement: BodyElement
  elementIndex: number
}

const
  Instructions = styled(HintText)`
    text-align: left;
    margin-bottom: ${p => p.theme.emBaseSpacing * 0.75}em;
  `,
  Subheading = styled(SubheadingOne)`
    font-size: 0.9em;
    margin-top: ${p => p.theme.emSmallSpacing}em;
    margin-bottom: ${p => p.theme.emBaseSpacing}em;
  `,
  Heading = styled(HeadingThree)`
    font-size: 1.2em;
    margin-top: ${p => p.theme.emSmallSpacing}em;
    margin-bottom: ${p => p.theme.emSmallSpacing}em;
  `,
  Text = styled.label`
    ${p => p.theme.bodyFont};
    color: ${p => p.theme.colors.bodyText};
  `,
  EnclosedFieldset = styled.div`
    padding: ${p => p.theme.emBaseSpacing * 2}em;
    border: 2px solid ${p => p.theme.colors.borderAlt};
  `,
  OffsetFieldset = styled.div`
    padding-left: ${p => p.theme.emBaseSpacing}em;
    border-left: 2px dashed ${p => p.theme.colors.borderAlt};
    
    margin-left: ${p => p.theme.emSmallSpacing * 0.66}em;
  `,
  getIsReadOnly = (formContext: FormContextType, element: AnyInputElement | CollectionElement | ScopeElement, schemaEntry?: SchemaEntry): boolean => {
    const
      readOnlyWhen = element.readOnlyWhen ?? schemaEntry?.readOnlyWhen,
      isSelfReadOnly = !isEmptyValue(readOnlyWhen) && formContext.resolveCriteria({ bodyElement: element, schemaEntry, contextAndResolvers: formContext, criteria: 'isReadOnly' }),
      isScopeReadOnly = !!formContext?.scopeOptions?.isReadOnly

    return isSelfReadOnly || isScopeReadOnly
  },
  collectionFieldsWithSchemaFallback = ['list', 'readOnlyWhen', 'visibleWhen', 'requiredWhen'] as const,
  schemaKeyByBodyElementKey: Record<typeof collectionFieldsWithSchemaFallback[number], keyof SchemaEntry> = {
    list: 'options',
    readOnlyWhen: 'readOnlyWhen',
    visibleWhen: 'visibleWhen',
    requiredWhen: 'requiredWhen'
  },
  FormElement: React.FC<Props> = ({ bodyElement, elementIndex }) => {
    const
      formContext = React.useContext(FormContext),
      schemaEntry = formContext.schema[(bodyElement as InputElement).fieldName ?? ''] ?? {},
      { elementVariety } = bodyElement,
      visibleWhen = bodyElement.visibleWhen ?? schemaEntry.visibleWhen,
      isInput = comparableInputVarieties.includes(elementVariety),
      isVisible = isEmptyValue(visibleWhen) || formContext.resolveCriteria({ bodyElement, schemaEntry, contextAndResolvers: formContext, criteria: 'isVisible' })

    // if (bodyElement.fieldName === 'fertilizerRateUnit') {
    //   console.log({ bodyElement, visibleWhen, isVisible, schemaEntry, values: formContext.values })
    // }

    if (isInput) {
      return <FormField {...{ bodyElement, FormFieldRenderer }} />
    }

    if (!isVisible || !elementVariety) { return null }

    if (elementVariety === 'TEXT') {
      const
        element = bodyElement as TextElement,
        text = interpolateStringInForm(element.text, formContext)

      return <Text data-cy="form-element__text" style={{ fontWeight: (element.isBold ?? true) ? 'bold' : 'normal' }}>{text}</Text>
    }

    if (elementVariety === 'INSTRUCTIONS') {
      const
        element = bodyElement as InstructionsElement,
        paragraphs = (element.paragraphs ?? []).map(
          p => addEmphasisToString(interpolateStringInForm(p, formContext)) ?? p
        )

      if (isAllEmpty(paragraphs)) { return null }

      return <Instructions color="default">
        {paragraphs.length > 1
          ? paragraphs.map((p, i) => <p key={i}>{p}</p>)
          : paragraphs}
      </Instructions>
    }

    if (elementVariety === 'FILL_IN_THE_BLANK') {
      const { segments } = bodyElement as MadLibElement

      return <CompoundInputWrapper bodyElement={bodyElement as MadLibElement} contextAndResolvers={getContextAndResolvers(formContext)}>
        {segments.map((el, i) => <FormElement key={i} bodyElement={{ ...el, hideErrorMessage: true, hideLabel: true, hideHint: true } as InputElement} elementIndex={i} />) /* eslint-disable-line @typescript-eslint/consistent-type-assertions */}
      </CompoundInputWrapper>
    }

    if (elementVariety === 'BLURB') {
      if (formContext.formOptions.withoutBlurbs) { return null }

      const
        element = bodyElement as BlurbElement,
        needsBackgroundIntervention = formContext.formOptions.backdropColorKey === 'containerAccentBg' && element.withBackground,
        paragraphs = element.paragraphs.map(str => addEmphasisToString(interpolateStringInForm(str, formContext)) ?? [str])

      return <BlurbBuilder
        {...element}
        {...(needsBackgroundIntervention ? { withBackground: false } : {})}
        paragraphs={paragraphs}
        location={element.location ?? (elementIndex === 0 ? 'top' : 'center')}
      />
    }

    if (elementVariety === 'SCOPE') {
      const element = bodyElement as ScopeElement

      return <FormScope
        name={element.fieldName}
        scopeOptions={{
          ...element,
          hasValues: !isAllEmpty(formContext.values[element.fieldName]),
          isReadOnly: getIsReadOnly(formContext, element, schemaEntry)
        }}
        formBody={element.formBody ?? []}
        ScopeRenderer={ScopeRenderer}
        ScopeEntryRenderer={
          element.useAlternateEntryRenderer === 'bodyElementList'
            ? BodyElementListItem
            : formContext.formOptions.RootFormRenderer
        }
      />
    }

    if (elementVariety === 'COLLECTION') {
      const
        element = bodyElement as CollectionElement,
        elementFieldName = element.fieldName as typeof collectionFieldsWithSchemaFallback[number],
        isFieldWithSchemaFallback = collectionFieldsWithSchemaFallback.includes(elementFieldName),
        siblingFieldName = formContext.values?.fieldName as SystemFieldName | undefined,
        relevantSystemField = siblingFieldName ? SYSTEM_FIELDS[siblingFieldName] : undefined,
        relevantSchemaKey = schemaKeyByBodyElementKey[elementFieldName],
        relevantSystemValues = (
          relevantSystemField
            ? relevantSystemField.schemaEntry[relevantSchemaKey]
            : undefined
        ) as ListOption[] | VisibilityCondition[] | undefined

      return isFieldWithSchemaFallback && relevantSystemField && relevantSystemValues && !isAllEmpty(relevantSystemValues)
        ? <SystemFieldDefaultCollection
          bodyElement={element}
          systemField={relevantSystemField}
          systemFieldCollectionValues={relevantSystemValues}
          />
        : <FormCollection
            name={element.fieldName}
            collectionOptions={{
              ...element,
              defaultEntries: element.noInitialEntry ? [] : [{}],
              isReadOnly: getIsReadOnly(formContext, element, schemaEntry)
            }}
            formBody={element.formBody ?? []}
            CollectionRenderer={CollectionRenderer}
            CollectionEntryRenderer={
              element.useAlternateEntryRenderer === 'bodyElementList'
                ? BodyElementListItem
                : element.useAlternateEntryRenderer === 'conditionList'
                  ? ConditionListItem
                  : formContext.formOptions.RootFormRenderer
            }
          />
    }

    if (elementVariety === 'HEADING') {
      const element = bodyElement as HeadingElement

      return <Heading>{element.text}</Heading>
    }

    if (elementVariety === 'SUBHEADING') {
      const element = bodyElement as SubheadingElement

      return <Subheading>{element.text}</Subheading>
    }

    if (elementVariety === 'SPECIAL' && (bodyElement as SpecialElement).specialElementId === 'stepFormatInfo') {
      return <StepFormatInfo format={formContext.values.format as QuestionnaireFormat} experiment={formContext.formOptions.experiment} />
    }

    if (elementVariety === 'SPECIAL' && (bodyElement as SpecialElement).specialElementId === 'mascotPreview') {
      return <MascotPreview bodyElement={bodyElement as SpecialElement} values={formContext.values} />
    }

    if (elementVariety === 'SPECIAL' && (bodyElement as SpecialElement).specialElementId === 'madLibSegments') {
      return <MadLibEditor editorElement={bodyElement as SpecialElement} />
    }

    if (elementVariety === 'COLLAPSIBLE') {
      const { body } = bodyElement as CollapsibleElement

      return <CollapsibleSection bodyElement={bodyElement as CollapsibleElement} contextAndResolvers={getContextAndResolvers(formContext)}>
        {body.map((el, i) => <FormElement key={i} bodyElement={el} elementIndex={i} />)}
      </CollapsibleSection>
    }

    if (elementVariety === 'FIELDSET') {
      const
        { body, offsetContent, encloseContent } = bodyElement as FieldsetElement,
        content = body.map((el, i) => <FormElement key={i} bodyElement={el} elementIndex={i} />)

      return encloseContent
        ? <EnclosedFieldset>{content}</EnclosedFieldset>
        : offsetContent
          ? <OffsetFieldset>{content}</OffsetFieldset>
          : content
    }

    // default
    return process.env.NODE_ENV === 'development'
      ? <Blurb content={<pre>{JSON.stringify(bodyElement, null, 2)}</pre>} heading="Form Error: Unknown form element type" />
      : <Blurb icon={<Icon content="!" color="caution" />} heading="Unknown or invalid content" content="Check that all required content settings are filled in. Contact support if you think that this message is being shown despite settings being complete." />
  }

export default FormElement
