import * as React from 'react'
import styled from 'styled-components'
import { HeadingTwo, SubheadingTwo } from '~/components/atoms'

interface ContentHeaderProps {
  heading: string
  subheading?: string
  icon?: React.ReactNode
  className?: string
}

const
  ContentHeaderOuter = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 5.76em;
    gap: ${p => p.theme.emBaseSpacing}em;

    align-items: center;
    justify-content: space-between;
    padding: ${p => p.theme.emBaseSpacing * 1.25}em;

    background: ${p => p.theme.colors.containerAccentBg}
  `,
  Heading = styled(HeadingTwo)`
    line-height: 1;
  `,
  StyledSubheadingTwo = styled(SubheadingTwo)`
    line-height: 1;
  `,
  IconWrapper = styled.div`
    flex: 0 0 ${p => p.theme.pxIconLarge}px;  
    margin-bottom: 2px; // From figma, to visually compensate for drop shadow in centering
  `,
  TextWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.emSmallSpacing}em;

    align-items: flex-start;
  `,
  ContentHeader: React.FC<ContentHeaderProps> = ({ heading, subheading, icon, className }) => {
    return <ContentHeaderOuter className={className} data-cy="content-header">
      <IconWrapper>{icon}</IconWrapper>
      <TextWrapper>
        {subheading
          ? <StyledSubheadingTwo className={className}>{subheading}</StyledSubheadingTwo>
          : null}
        <Heading>{heading}</Heading>
      </TextWrapper>
    </ContentHeaderOuter>
  }

export default ContentHeader
export { ContentHeaderOuter }
