import * as React from 'react'
import styled from 'styled-components'
import { Form } from '~/form-brain2'
import { useFormVitalsSubscription } from '~/hooks'
import {
  experimentFormResolvers,
  WorksheetResponseHelpers,
  ExperimentHelpers,
  interpolateString,
  addEmphasisToString
} from '~/features'
import { DoubleContent } from '~/components/layouts'
import SubmitConfirmationComplex from '~/components/organisms/SubmitConfirmationComplex'
import {
  ContentHeader,
  ContentFooter,
  BlurbBuilder,
  FormRenderer
} from '~/components/molecules'
import {
  Button,
  fontSizeByButtonSize,
  Icon,
  SizedLoadingIcon
} from '~/components/atoms'
import SpecialContentArea from './SpecialContentArea'
import StepSectionTabs from './StepSectionTabs'

import type {
  WorksheetHelpers,
  WorksheetResponse,
  PlainResponse,
  InputElement,
  Experiment,
  PageElement,
  PagedSectionElement,
  Worksheet,
  BlurbElement,
  PagedWorksheet
} from '~/features'
import type {
  EntityObject,
  FormProps
} from '~/form-brain2'

export type PagedFormSubmissionSource = 'FORWARDS' | 'BACKWARDS' | 'SUBMIT'

interface PageFormProps {
  isFetchingData: boolean
  isLoadingData: boolean
  onSubmitCallback: FormProps['onSubmitCallback']
  onSuccessCallback: FormProps['onSuccessCallback']
  experiment: Experiment
  worksheet: Worksheet
  response: WorksheetResponse
  responses: WorksheetResponse[]
  page: PageElement
  section: PagedSectionElement
  pageIndex: number
  sectionIndex: number
  pageId: string
  navDetailsForPage: ReturnType<typeof WorksheetHelpers.getNavDetailsForPage>
  hideSectionTabs?: boolean
  permitMissingInterpolation?: boolean
}

const
  WorksheetPage = styled.div<{ $isCheckpoint?: boolean }>`
    display: flex;
    flex-direction: column;
    min-height: ${p => p.$isCheckpoint ? 'unset' : `${p.theme.emMediumContentWidth * 0.9}em`};
    background: ${p => p.theme.colors.containerDarkBg};
  `,
  StyledContentHeader = styled(ContentHeader)`
    flex: 0 0 auto;
  `,
  HeaderBlurb = styled(BlurbBuilder)`
    flex: 0 0 auto;
    padding: 0.65em;
    max-width: unset;
  `,
  ContentBody = styled.div<{ $noBody?: boolean }>`
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    padding: ${p => p.$noBody ? '0' : '0.9em 1em'};
  `,
  StyledContentFooter = styled(ContentFooter)`
    flex: 0 0 auto;

    @media(max-width: 500px) {
      flex-wrap: wrap;
      height: auto;
    }
  `,
  ProgressText = styled.div`
    ${p => p.theme.bodyFont}
    font-size: ${p => fontSizeByButtonSize.large}em;
    text-transform: uppercase;
    color: ${p => p.theme.colors.understateMed};
    align-self: center;
    padding: 0 ${p => p.theme.emBaseSpacing}em;
    flex: 0 0 auto !important;
  `,
  StyledForm = styled(Form)`
    margin: 0;
  `,
  { getEntitiesAcrossResponses } = WorksheetResponseHelpers,
  { getDynamicString, getSchema } = ExperimentHelpers,
  PageForm: React.FC<PageFormProps> = (props) => {
    const
      // PRops
      {
        isFetchingData,
        isLoadingData,
        onSubmitCallback,
        onSuccessCallback,
        responses,
        response,
        experiment,
        worksheet,
        section,
        sectionIndex,
        page,
        pageIndex,
        pageId,
        navDetailsForPage,
        hideSectionTabs,
        permitMissingInterpolation
      } = props,
      { farthestReachedSectionIndex } = response as PlainResponse,
      { backNav, forwardNav, submitOnForward } = navDetailsForPage,

      // State
      {
        isProcessing,
        formStatus,
        formSubmissionSource,
        subscribeToFormStatus
      } = useFormVitalsSubscription<PagedFormSubmissionSource>(),
      formMethodRef: FormProps['methodRef'] = React.useRef(),
      [pageHeading, setPageHeading] = React.useState<string | undefined>(
        typeof page.heading === 'string'
          ? page.heading
          : undefined
      ),
      realTimePageHeading = React.useRef<string | undefined>(pageHeading),
      setParentHeading = (newHeading: string): void => {
        if (!pageHeading && !realTimePageHeading.current) {
          realTimePageHeading.current = newHeading
          setPageHeading(newHeading)
        }
      },

      // Content
      [initialValues, setInitialValues] = React.useState(response.responses as EntityObject),
      isCheckpoint = page.useCheckpoint,
      forwardButtonOverride = isCheckpoint
        ? page.checkpointButtonLabel
        : undefined,
      onForwardsSubmit: React.MouseEventHandler = (e) => {
        if (formMethodRef.current?.triggerFormSubmission) {
          formMethodRef.current.triggerFormSubmission(e, submitOnForward ? 'SUBMIT' : 'FORWARDS')
        }
      },
      onBackwardsSubmit: React.MouseEventHandler = (e) => {
        if (formMethodRef.current?.triggerFormSubmission) {
          formMethodRef.current.triggerFormSubmission(e, 'BACKWARDS')
        }
      },
      otherEntities = getEntitiesAcrossResponses(responses),
      progress = `${pageIndex + 1} / ${section.pages.length}`,
      nonTextHeader = page.heading && typeof page.heading === 'object' && page.heading.elementVariety === 'BLURB',
      header = nonTextHeader
        ? <HeaderBlurb
          {...page.heading as BlurbElement}
          noWrapper
          withBackground
          paragraphs={
            (page.heading as BlurbElement).paragraphs.map(str =>
              addEmphasisToString(interpolateString(str, otherEntities, permitMissingInterpolation)) ?? [str]
            )
          } />
        : <StyledContentHeader
            subheading={interpolateString(section.subtitle, otherEntities, permitMissingInterpolation) ?? 'Question:'}
            heading={interpolateString(pageHeading, otherEntities, permitMissingInterpolation) ?? ''}
            icon={<Icon content={(pageIndex + 1).toString()} degreesRotation={-8.66} />}
          />,
      formBody = pageHeading && !nonTextHeader
        ? [...page.body].map(el => (
            pageHeading === (el as InputElement).label
              ? { ...el, hideLabel: true }
              : el
          ))
        : page.body,
      specialContentSpec = page.specialContentArea ?? (worksheet as PagedWorksheet).specialContentArea,
      specialContent = specialContentSpec && specialContentSpec.specialContentId === 'RESULTS_EXPLORER' // TODO DEV remove when other areas are implemented
        ? <SpecialContentArea contentId={specialContentSpec.specialContentId} experiment={experiment} responseEntities={otherEntities} />
        : undefined

    React.useEffect(() => {
      setInitialValues(response.responses as EntityObject)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionIndex, pageIndex, page.id])

    return (
      <DoubleContent
        spaceEvenly
        primaryContent={specialContent}
        secondaryContent={<>
          {hideSectionTabs
            ? null
            : <StepSectionTabs {...{ worksheet, sectionIndex, farthestReachedSectionIndex }} />}
          <WorksheetPage $isCheckpoint={isCheckpoint} data-cy="worksheet-page">
            {header}
            <ContentBody data-cy="page-form__content-body" $noBody={isCheckpoint && !formBody.length}>
                {(isLoadingData || isFetchingData) && !formStatus
                  ? <SizedLoadingIcon />
                  : <StyledForm
                    key={pageId}
                    formId={pageId}
                    methodRef={formMethodRef}
                    entitySchema={getSchema(experiment, formBody)}
                    initialValues={initialValues}
                    formBody={formBody}
                    FormRenderer={FormRenderer}
                    formOptions={{
                      dynamicLabels: experiment.dynamicLabels,
                      otherEntities,
                      backdropColorKey: 'containerDarkBg',
                      setParentHeading,
                      permitMissingInterpolation,
                      RootFormRenderer: FormRenderer
                    }}
                    onSubmitCallback={onSubmitCallback}
                    onSuccessCallback={onSuccessCallback}
                    subscribeToFormStatus={subscribeToFormStatus}
                    {...experimentFormResolvers}
                  />}
              </ContentBody>
            <StyledContentFooter
              items={[
                <Button
                  key="back"
                  size="large"
                  color="back"
                  onClick={onBackwardsSubmit}
                  isLoading={isProcessing && formSubmissionSource === 'BACKWARDS'}
                  isDisabled={isLoadingData || isFetchingData || (isProcessing && formSubmissionSource !== 'BACKWARDS')}
                  label={getDynamicString(experiment, backNav.labelKey)}
                  withIcon={'withIcon' in backNav ? backNav.withIcon : 'arrowL'}
                />,
                <ProgressText
                  key="progress"
                  className="content-footer-separator">
                  {progress}
                </ProgressText>,
                submitOnForward
                  ? <SubmitConfirmationComplex
                    key="submit"
                    isLoading={isLoadingData}
                    isDisabled={isFetchingData || isLoadingData || (isProcessing && formSubmissionSource !== 'SUBMIT')}
                    isUpdating={isProcessing && formSubmissionSource === 'SUBMIT'}
                    onConfirmSubmit={onForwardsSubmit}
                   />
                  : <Button
                    key="forward"
                    size="large"
                    color="forward"
                    onClick={onForwardsSubmit}
                    isLoading={isProcessing && formSubmissionSource === 'FORWARDS'}
                    isDisabled={isLoadingData || isFetchingData || (isProcessing && formSubmissionSource !== 'FORWARDS')}
                    label={forwardButtonOverride ?? getDynamicString(experiment, forwardNav.labelKey)}
                    withIcon={'withIcon' in forwardNav ? forwardNav.withIcon : 'arrowR'}
                  />
              ]}
            />
          </WorksheetPage>
        </>} />
    )
  }

export default PageForm
export { ContentBody, WorksheetPage, StyledContentFooter, ProgressText, StyledContentHeader }
