import React from 'react'
import { WorksheetHelpers } from '~/features'
import { EmptyContentPlaceholder } from '~/components/molecules'
import FormBodyForm from '~/components/organisms/StepManagement/Body/FormBodyForm'

import type { AnyPlainOrInputElement, Experiment, ListSectionElement, ListWorksheet, PagedWorksheet, SpecialListSectionElement } from '~/features'
import { RelativeWrapper } from '../../ListStep/StackingSections'

interface Props {
  experiment: Experiment
  worksheet: ListWorksheet
  allWorksheets: Array<ListWorksheet | PagedWorksheet>
  section: ListSectionElement
  updateWorksheet: (updatedWorksheet: ListWorksheet) => void
}

const
  ListSectionBodyForm: React.FC<Props> = ({ experiment, worksheet, allWorksheets, section, updateWorksheet }) => {
    const
      bodyElements = section.body,
      updateBody = (newBody: AnyPlainOrInputElement[]): void => {
        const
          updatedSection: ListSectionElement = {
            ...section,
            body: newBody
          },
          updatedWorksheet = WorksheetHelpers.replaceSectionInWorksheet(worksheet, section, updatedSection)

        updateWorksheet(updatedWorksheet)
      }

    if ((section as SpecialListSectionElement).specialSectionId) {
      return <RelativeWrapper>
        <EmptyContentPlaceholder>
          The content for this special section is managed via settings.
        </EmptyContentPlaceholder>
      </RelativeWrapper>
    }

    return (
      <FormBodyForm
        key={section.id}
        currentWorksheetId={worksheet.id}
        {...{ bodyElements, updateBody, allWorksheets }}
        formTypeName={section.elementVariety === 'LIST_ENTRY_SECTION' ? 'listEntryBodyElements' : undefined}
      />
    )
  }

export default ListSectionBodyForm
