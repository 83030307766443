import { forEach, map } from 'lodash'
import { isAllEmpty } from '~/utils/testers'
import { WorksheetHelpers } from '~/features'
import { getSectionList } from '~/features/worksheet/helpers'

import type { ListEntry, ListResponse, ListWorksheet, SpecialSectionId, Worksheet, WorksheetResponse, SpecialListSectionElement } from '~/features'

export type DownloadableContentFormat = 'text' | 'image' | 'csv'
export type DownloadableContentType = 'pageNotes' | 'experimentSummary' | 'listNotes' | 'schedule' | 'checklist' | 'data' | 'analysis' | 'log'

export interface DownloadableContentItem {
  id: string
  contentType: DownloadableContentType
  label: string
  formats: DownloadableContentFormat[]
  responses?: WorksheetResponse[]
  response?: WorksheetResponse
  entries?: ListEntry[]
  worksheet?: Worksheet
}

export type SelectedOptions = Record<string, DownloadableContentFormat[]>

const
  specialSectionGroups: Array<{ name: string, specialSectionIds: SpecialSectionId[], contentType: DownloadableContentType, formats: DownloadableContentFormat[] }> = [
    {
      name: 'Schedule',
      contentType: 'listNotes',
      formats: ['text'],
      specialSectionIds: ['PLAN_ACTIVITY', 'PLAN_MEASUREMENT'] as SpecialSectionId[]
    },
    {
      name: 'Materials List',
      contentType: 'checklist',
      formats: ['text', 'csv'],
      specialSectionIds: ['MATERIAL'] as SpecialSectionId[]
    },
    {
      name: 'Set-Up List',
      contentType: 'checklist',
      formats: ['text', 'csv'],
      specialSectionIds: ['SET_UP_STEP'] as SpecialSectionId[]
    },
    {
      name: 'Measurements',
      contentType: 'data',
      formats: ['text', 'csv'],
      specialSectionIds: ['MEASUREMENT_RECORD'] as SpecialSectionId[]
    },
    {
      name: 'Observations',
      contentType: 'log',
      formats: ['text', 'csv'],
      specialSectionIds: ['OBSERVATION_RECORD'] as SpecialSectionId[]
    },
    {
      name: 'Activity Log',
      contentType: 'log',
      formats: ['text', 'csv'],
      specialSectionIds: ['ACTIVITY_RECORD'] as SpecialSectionId[]
    },
    {
      name: 'All Analyses',
      contentType: 'analysis',
      formats: ['text'], //, 'image'],
      specialSectionIds: ['SUMMARY_STATS', 'STATS_TEST'] as SpecialSectionId[]
    }
  ],
  getDownloadOptions = (allWorksheets: Worksheet[], responses: WorksheetResponse[]): DownloadableContentItem[] => {
    const
      sortedWorksheets = WorksheetHelpers.getSortedTopLevelWorksheets(allWorksheets),
      downloadOptions = sortedWorksheets.flatMap(worksheet => {
        return getDownloadOptionsForWorksheet(worksheet, allWorksheets, responses)
      })

    return downloadOptions
    // return [
    //   {
    //     id: 'experiment-summary',
    //     label: 'Experiment Design Summary',
    //     contentType: 'experimentSummary',
    //     responses,
    //     formats: ['text']//, 'image']
    //   },
    //   ...downloadOptions
    // ]
  },
  getDownloadOptionsForWorksheet = (worksheet: Worksheet, allWorksheets: Worksheet[], responses: WorksheetResponse[]): DownloadableContentItem[] => {
    const
      relevantResponses = responses.filter(o => o.worksheetId === worksheet.id)

    if (!relevantResponses.length || worksheet.format === 'DOWNLOADS') { return [] }

    if (worksheet.format === 'HUB') {
      const childWorksheets = WorksheetHelpers.getSortedWorksheetsForHubStep(worksheet.id, allWorksheets)

      return childWorksheets.flatMap(w => getDownloadOptionsForWorksheet(w, allWorksheets, responses))
    }

    if (worksheet.format === 'PAGED') {
      return relevantResponses.map(r => ({
        id: r.id,
        contentType: 'pageNotes',
        worksheet,
        response: r,
        responses,
        label: `${worksheet.name} Notes`,
        formats: ['text']
      }))
    }

    return relevantResponses.flatMap(r => {
      const
        listWorksheet = worksheet as ListWorksheet,
        listResponse = r as ListResponse,
        sections = getSectionList(listWorksheet),
        specialContentSections = sections.filter(o => (o as SpecialListSectionElement).specialSectionId) as SpecialListSectionElement[],
        specialContent = specialContentSections.length || listWorksheet.specialLayoutType
          ? getSpecialContentOptions(specialContentSections, listWorksheet, listResponse, responses)
          : [],
        nonSpecialSections = sections.filter(o => !(o as SpecialListSectionElement).specialSectionId),
        nonSpecialSectionIds = map(nonSpecialSections, 'id'),
        entriesForNonSpecialSections = listResponse.responses.entries.filter(o => nonSpecialSectionIds.includes(o.sectionId))

      return [
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        ...(specialContent as DownloadableContentItem[]), // Something goes wrong, doesn't compile if this assertion is removed
        ...(
          !isAllEmpty(entriesForNonSpecialSections)
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            ? [{
                id: listResponse.id,
                contentType: 'listNotes',
                worksheet,
                response: r,
                responses,
                entries: entriesForNonSpecialSections,
                label: `${worksheet.name} Notes`,
                formats: ['text']
              } as DownloadableContentItem]
            : []
        )
      ]
    })
  },
  getSpecialContentOptions = (
    sections: SpecialListSectionElement[],
    worksheet: ListWorksheet,
    response: ListResponse,
    responses: WorksheetResponse[]
  ): DownloadableContentItem[] => {
    const results: DownloadableContentItem[] = []

    forEach(specialSectionGroups, ({ name, specialSectionIds, contentType, formats }, i) => {
      const relevantSections = sections.filter(o => specialSectionIds.includes(o.specialSectionId))

      if (isAllEmpty(relevantSections)) { return null }

      const
        sectionIds = relevantSections.map(o => o.id),
        entries = response.responses.entries.filter(o => sectionIds.includes(o.sectionId))

      if (isAllEmpty(entries)) { return null }

      results.push({
        id: `${response.id}-special-${i}`,
        contentType,
        worksheet,
        response,
        responses,
        entries,
        formats,
        label: `${worksheet.name}: ${name}`
      })
    })

    return results
  }

export default getDownloadOptions
