import { keys } from 'lodash'
import { DateTime } from 'luxon'
import { formatDate } from '~/utils/formatters'
import { getAnalysisValues, getChecklistValues, getDataValues, getFallbackValues, getListNotesValues, getPageNotesValues } from './getValuesForItem'
import getGradesForDownload from './getGradesForDownload'

import type { Learner, Experiment } from '~/features'
import type { SavePointContent, SavePointFileContent } from '~/components/molecules/SavePoint'
import type { DownloadableContentFormat, DownloadableContentItem, SelectedOptions } from './getDownloadOptions'

interface SelectedItem {
  format: DownloadableContentFormat
  item: DownloadableContentItem
}

const
  getContentForItem = ({ format, item }: SelectedItem, experiment: Experiment, learners?: Learner[]): SavePointFileContent => {
    const
      filename = item.label.replace(': ', ' - '),
      learner = learners ? learners.find(o => o.id === item.response?.learnerId) : undefined,
      result: SavePointFileContent = {
        sections: [],
        filename: `${learner ? `${learner.displayId} - ` : ''}${filename} - ${formatDate(DateTime.now(), 'LLLL d hh-mm')}`,
        format: format === 'csv' ? 'csv' : format === 'text' ? 'docx' : 'png'
      }

    switch (item.contentType) {
      case 'pageNotes':
        result.sections = getPageNotesValues(item, result.format, experiment)
        break
      case 'listNotes':
      case 'schedule':
        result.sections = getListNotesValues(item, result.format, experiment)
        break
      // case 'experimentSummary':
      case 'checklist':
        result.sections = getChecklistValues(item, result.format, experiment)
        break
      case 'data':
      case 'log':
        result.sections = format === 'text'
          ? getListNotesValues(item, result.format, experiment)
          : getDataValues(item, result.format, experiment)
        break
      case 'analysis':
        result.sections = getAnalysisValues(item, result.format, experiment)
        break
      default:
        result.sections = getFallbackValues(item, result.format, experiment)
    }

    return result
  },
  getContentForSelectedDownloadOptions = (availableDownloads: DownloadableContentItem[], selectedOptions: SelectedOptions, experiment: Experiment, includeGradesFor?: Learner[]) => (): SavePointContent => {
    const
      selectedItems: SelectedItem[] = [],
      gradesFile: SavePointFileContent | undefined = includeGradesFor
        ? getGradesForDownload(availableDownloads, experiment, includeGradesFor)
        : undefined

    keys(selectedOptions).forEach((k) => {
      selectedOptions[k].forEach(v => {
        const item = availableDownloads.find(o => o.id === k)

        if (item) {
          selectedItems.push({
            format: v,
            item
          })
        }
      })
    })

    if (selectedItems.length > 1 || includeGradesFor) {
      return {
        filename: `${includeGradesFor ? `AgConnect - ${experiment.name} Responses` : 'AgConnect Experiment Notes'} - ${formatDate(DateTime.now(), 'LLLL d hh-mm')}`,
        format: 'zip',
        files: [
          ...(gradesFile ? [gradesFile] : []),
          ...selectedItems.map(o => getContentForItem(o, experiment, includeGradesFor))
        ]
      }
    } else {
      return getContentForItem(selectedItems[0], experiment)
    }
  }

export default getContentForSelectedDownloadOptions
