import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { defaultPage, defaultPagedSection } from '~/features/worksheet/defaultValues'
import { BasicCross, BasicPencil, Button, DashedLine, Icon, SubheadingTwo } from '~/components/atoms'
import { NavAdder, NavRow, NavSubheading, NavWrapper, PageOrListSectionLine, PagedSectionLine, SizedIcon } from './NavWidgets'

import type { PageElement, PagedSectionElement, PagedTemplate, PagedWorksheet } from '~/features'
import { isEmptyValue } from '~/form-brain2/testers'

interface Props {
  selectedSection?: string
  setSelectedSection: (sectionId?: string) => void
  selectedPage?: number
  setSelectedPage: (pageIndex?: number) => void
  questionnaire: PagedTemplate | PagedWorksheet
  // questionnaireGroup: TemplateSet | Experiment
  updateQuestionnaire: (questionnaire: PagedWorksheet | PagedTemplate) => void
}

const
  NavHeading = styled(SubheadingTwo)<{ $standalone?: boolean }>`
    margin: ${p => p.theme.emSmallSpacing}em 0 0 0;
    color: ${p => p.theme.colors.headingText};
    font-size: 1.2rem;
  `,
  HR = styled(DashedLine)<{ $top?: boolean }>`
    width: 96%;
    margin-top: ${p => p.$top ? p.theme.emSmallSpacing : p.theme.emSmallSpacing}em;
    margin-bottom: ${p => p.$top ? p.theme.emSmallSpacing : p.theme.emBaseSpacing}em;
  `,
  getSectionLabel = (section: PagedSectionElement, index: number): string => {
    const
      sectionName = section.title,
      sectionLabel = sectionName
        ? `Section - ${sectionName}`
        : `Section ${index + 1}`

    return sectionLabel
  },
  getPageLabel = (page: PageElement, index: number): string => {
    const rawLabel = typeof page.heading === 'string'
      ? page.heading
      : page.heading?.title

    return rawLabel ?? `Page ${index + 1}: ${page.body.length} item${page.body.length > 1 ? 's' : ''}`
  },
  PagedFormatNavigation: React.FC<Props> = ({ questionnaire, selectedPage, setSelectedPage, selectedSection, setSelectedSection, updateQuestionnaire }) => {
    const
      THEME = useTheme(),
      selectSectionOnClick = (sectionId: string): React.MouseEventHandler => (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }
        setSelectedSection(sectionId)
      },
      clearSectionSelection: React.MouseEventHandler = (e) => {
        if (e) { e.stopPropagation(); e.preventDefault() }
        setSelectedSection()
      },
      selectPage = (pageIndex: number): React.MouseEventHandler => (e) => {
        if (e) { e.stopPropagation(); e.preventDefault() }
        setSelectedPage(pageIndex)
      },
      clearPageSelection: React.MouseEventHandler = (e) => {
        if (e) { e.stopPropagation(); e.preventDefault() }
        setSelectedPage()
      },
      addPagedSection = (insertBeforeIndex: number): React.MouseEventHandler => (e) => {
        if (e) { e.stopPropagation(); e.preventDefault() }

        const insertAfterIndex: number | undefined = insertBeforeIndex === (questionnaire as PagedWorksheet).sections.length
          ? (questionnaire as PagedWorksheet).sections.length - 1
          : undefined

        updateQuestionnaire({
          ...questionnaire,
          sections: (questionnaire as PagedWorksheet).sections.reduce<PagedSectionElement[]>((memo, s, i) => {
            if (i === insertBeforeIndex) {
              return [...memo, defaultPagedSection(), s]
            } else if (i === insertAfterIndex) {
              return [...memo, s, defaultPagedSection()]
            } else {
              return [...memo, s]
            }
          }, [])
        })
      },
      addPage = (currentSection: PagedSectionElement, insertBeforeIndex: number): React.MouseEventHandler => (e) => {
        if (e) { e.stopPropagation(); e.preventDefault() }

        const
          currentPages = currentSection.pages,
          insertAfterIndex: number | undefined = insertBeforeIndex === currentPages.length
            ? currentPages.length - 1
            : undefined,
          updatedSection: PagedSectionElement = {
            ...currentSection,
            pages: currentPages.reduce<typeof currentPages>((memo, s, i) => {
              if (i === insertBeforeIndex) {
                return [...memo, defaultPage(), s]
              } else if (i === insertAfterIndex) {
                return [...memo, s, defaultPage()]
              } else {
                return [...memo, s]
              }
            }, [])
          }

        updateQuestionnaire({
          ...questionnaire,
          sections: (questionnaire as PagedWorksheet).sections.map(s => {
            if (s.id === currentSection.id) {
              return updatedSection
            } else {
              return s
            }
          })
        })
      }

    if (!selectedSection) {
      return <NavWrapper data-cy="step-format-navigation">
        {(questionnaire.sections ?? []).map((section, index) => {
          return [
            index === 0
              ? <NavAdder
                key="first-adder"
                label="Add Section"
                orientation="left"
                icon={<Icon content={BasicCross} color="back" frameStyle="circle" onClick={addPagedSection(index)} />}
              />
              : null,
            <PagedSectionLine
              key={section.id}
              title={getSectionLabel(section, index)}
              onClick={selectSectionOnClick(section.id)}
            />,
            <NavAdder
              key={`adder-${index}`}
              label="Add Section"
              orientation='left'
              icon={<Icon content={BasicCross} color="back" frameStyle="circle" onClick={addPagedSection(index + 1)} />} />
          ]
        })}
      </NavWrapper>
    }

    const
      section: PagedSectionElement | undefined = questionnaire.sections.find(o => o.id === selectedSection),
      sectionLabel = !section
        ? 'Unknown Section'
        : getSectionLabel(section, questionnaire.sections.indexOf(section))

    return <NavWrapper data-cy="step-format-navigation">
      <Button label="All Sections" withIcon="arrowL" size="small" color="back" onClick={clearSectionSelection} />
      <NavHeading $standalone>{sectionLabel}</NavHeading>
      <NavRow onClick={clearPageSelection} $isSelected={isEmptyValue(selectedPage)}>
        <SizedIcon content={BasicPencil} frameStyle="plain" />
        <NavSubheading>Settings</NavSubheading>
      </NavRow>
      <HR orientation='horizontal' color={THEME.colors.understateLight} />
      {(section?.pages ?? []).map((page, index) => ([
        index === 0
          ? <NavAdder
              key="first-adder"
              label="Add Page"
              orientation='left'
              icon={
                <Icon
                  content={BasicCross}
                  color="back"
                  frameStyle="circle"
                  onClick={addPage(section as PagedSectionElement, index)}
                />
              }
            />
          : null,
        <PageOrListSectionLine
          key={page.id}
          title={getPageLabel(page, index)}
          isSelected={index === selectedPage}
          pageOrListSection={page}
          onClick={selectPage(index)} />,
        <NavAdder
          key={`adder-${index}`}
          label="Add Page"
          orientation='left'
          icon={
            <Icon
              content={BasicCross}
              color="back"
              frameStyle="circle"
              onClick={addPage(section as PagedSectionElement, index + 1)}
            />
          }
        />
      ]))}
    </NavWrapper>
  }

export default PagedFormatNavigation
