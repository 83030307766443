import React from 'react'
import styled from 'styled-components'
import { cloneDeep, isEqual } from 'lodash'
import { Form } from '~/form-brain2'
import { experimentFormResolvers } from '~/features'
import { pageSettingsFormBody } from '~/features/worksheet/sectionElementFormDetails'
import { ButtonWithConfirm, FormRenderer } from '~/components/molecules'
import type { EntityObject, SubscribeToFormStatusFn } from '~/form-brain2'
import type { Experiment, ListWorksheet, PageElement, PagedSectionElement, PagedWorksheet, ScopeElement, SelectInput, Worksheet } from '~/features'
import { HintText } from '~/components/atoms'

interface Props {
  experiment: Experiment
  worksheet: PagedWorksheet
  allWorksheets: Array<ListWorksheet | PagedWorksheet>
  section: PagedSectionElement
  updateWorksheet: (updatedWorksheet: PagedWorksheet) => void
  selectedPage: number
  setSelectedPage: (page?: number) => void
}

const
  StyledForm = styled(Form)`
    background: ${p => p.theme.colors.containerBg};
    padding: ${p => p.theme.emSmallSpacing}em;
  `,
  Outer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: ${p => p.theme.emSmallSpacing}em;
  `,
  PageSettingsForm: React.FC<Props> = ({ experiment, worksheet, allWorksheets, section, updateWorksheet, selectedPage, setSelectedPage }) => {
    const
      formBody = pageSettingsFormBody.map(el => {
        if ((el as ScopeElement).fieldName === 'specialContentArea' && worksheet.specialContentArea?.specialContentId) {
          const newElement: ScopeElement = cloneDeep(el) as ScopeElement

          (newElement.formBody[0] as SelectInput).blankOption = 'Same as rest of step'

          return newElement
        } else {
          return el
        }
      }),
      currentPage = section.pages[selectedPage],
      updatePage = (nextValues: EntityObject | undefined, updatedExample?: Worksheet['exampleResponses']): void => {
        updateWorksheet({
          ...worksheet,
          exampleResponses: updatedExample ?? worksheet.exampleResponses,
          sections: worksheet.sections.map(s => {
            if (s.id !== section.id) {
              return s
            } else {
              return {
                ...s,
                pages: s.pages.reduce<PageElement[]>((memo, o, i) => {
                  if (i !== selectedPage) {
                    return [...memo, o]
                  } else if (nextValues) {
                    return [...memo, nextValues as unknown as PageElement]
                  } else {
                    return memo
                  }
                }, [])
              }
            }
          })
        })
      },
      updateUpstream: SubscribeToFormStatusFn = ({ values: nextValues }) => {
        if (!isEqual(currentPage, nextValues)) {
          updatePage(nextValues)
        }
      },
      removePage: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        /** TODO DEV null example response values that will be removed with the page */

        updatePage(undefined)
      },
      canRemove = section.pages.length > 1,
      pageWithHeadingType = React.useMemo<PageElement>(
        () => ({
          ...currentPage,
          _headingType: currentPage._headingType ?? (typeof currentPage?.heading === 'string' ? 'text' : currentPage?.heading ? 'blurb' : '')
        }),
        [currentPage]
      )

    return <Outer>
      <StyledForm
        {...experimentFormResolvers}
        key={`paged-section-page-settings-${currentPage.id}`}
        formId="paged-section-page-settings"
        initialValues={pageWithHeadingType as unknown as EntityObject}
        FormRenderer={FormRenderer}
        formBody={formBody}
        onSubmitCallback={() => Promise.resolve({})}
        subscribeToFormStatus={updateUpstream}
        formOptions={{ RootFormRenderer: FormRenderer, worksheetsThisExperiment: allWorksheets }}
      />
      {canRemove
        ? <ButtonWithConfirm
            onConfirm={removePage}
            confirmationBody='This page will not be removed permanently until you save all changes to the step format. If you want this page back, you can go back to group settings without saving - but you will lose any other changes you have made since you last saved.'
            buttonProps={{ size: 'medium', label: 'Remove Page', color: 'caution' }}
            cancelLabel='Keep Page'
            confirmLabel='Remove Page'
            subheading='Remove page from draft'
          />
        : <HintText color="default">This is the only page. If you want to remove it, add another page first or remove the whole section.</HintText>}
    </Outer>
  }

export default PageSettingsForm
