import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField, VisibilityCondition } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'
import { PLANTING_FORMATS } from './plantingFormat'

const
  title = 'Plant spacing / group size unit',
  description = 'The unit for the plant spacing value.',
  groupFormatCondition: VisibilityCondition = {
    valueName: SystemFieldNames.plantingFormat,
    conditionType: 'anyOf',
    testValue: [PLANTING_FORMATS.GROUP_GROUND, PLANTING_FORMATS.GROUP_CONTAINER]
  },
  schemaEntry: QField = {
    fieldName: SystemFieldNames.plantSpacingUnit,
    fieldType: 'STRING',
    // visibleWhen: [
    //   ...ivEqualityCondition(variableName, exclude),
    //   groupFormatCondition
    // ],
    options: [
      {
        optionValue: 'plants',
        visibleWhen: [groupFormatCondition]
      },
      {
        optionValue: 'seeds',
        visibleWhen: [groupFormatCondition]
      },
      {
        optionValue: 'oz of seed',
        optionLabel: 'ounces (oz) of seed',
        visibleWhen: [groupFormatCondition]
      },
      {
        optionValue: 'g of seed',
        optionLabel: 'grams (g) seed',
        visibleWhen: [groupFormatCondition]
      },
      {
        optionValue: 'lbs of seed',
        optionLabel: 'pounds (lbs) seed',
        visibleWhen: [groupFormatCondition]
      }
      /** From a previous version - now spacing for individually-managed plants is left entirely to custom values */
      // {
      //   optionValue: 'inches',
      //   visibleWhen: [
      //     { valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.INDIVIDUAL_GROUND }
      //   ]
      // },
      // {
      //   optionValue: 'cm',
      //   optionLabel: 'centimeters (cm)',
      //   visibleWhen: [
      //     { valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.INDIVIDUAL_GROUND }
      //   ]
      // }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.plantSpacingUnit,
    variableGroup: SystemFieldGroups.CONTROL_VARIABLES,
    shouldFollow: [SystemFieldNames.plantingFormat],
    includeOtherByDefault: true,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
