import * as React from 'react'
import styled from 'styled-components'
import { isEmptyValue, isAllEmpty } from '~/utils/testers'
import {
  ErrorList,
  Icon,
  BasicCross,
  ErrorText,
  FormLabel,
  HintText,
  WithUnderline
} from '~/components/atoms'
import { addEmphasisToString } from '~/features'

import type { InputProps } from '~/types/forms'
import type { InputElement } from '~/features'

interface FormFieldWrapperProps {
  inputId?: string
  inputProps: InputProps
  children?: React.ReactNode
  className?: string | null
  noWrapper?: boolean
}

const
  Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${p => p.theme.emSmallSpacing}em;
    max-width: 100%;
    width: 100%;
  `,
  Main = styled.div<{ $wrap?: boolean | null }>`
    width: 100%;
    max-width: 100%;
    min-height: 40px;

    display: flex;
    flex-direction: column;
    flex-wrap: ${p => p.$wrap ? 'wrap' : 'nowrap'};
    align-items: flex-start;
    gap: 2px;

    ${p => p.className
      ? null // `.${p.className} { height: 100%; }`
      : null}
  `,
  Unit = styled.div`
    ${p => p.theme.bodyFont};
    font-weight: normal;
  `,
  Hint = styled(HintText)<{ $showHint?: boolean }>`
    opacity: ${p => p.$showHint ? 1 : 0};
    text-align: left;
  `,
  HintIcon = styled(Icon)<{ $spaceLeft?: boolean }>`
    width: 1em;
    flex: 0 0 1em;
    ${p => p.$spaceLeft ? 'margin-left' : 'margin-right'}: ${p => p.theme.emSmallSpacing}em;
  `,
  HintWrapper = styled.div<{ $shrink?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    margin-bottom: ${p => p.theme.emSmallSpacing}em;
    // margin: ${p => p.theme.emSmallSpacing / 3}em 0 ${p => p.theme.emSmallSpacing}em 0; 
    ${p => p.$shrink ? 'height: 0px; margin: 0px;' : ''}
  `,
  InlineWrapper = styled.div<{ $nowrap?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: ${p => p.$nowrap ? 'nowrap' : 'wrap'};
    align-items: center;
    gap: ${p => p.theme.emSmallSpacing}em;
  `,
  FormFieldWrapper: React.FC<FormFieldWrapperProps> = ({ inputId, inputProps, children, className, noWrapper }) => {
    const
      {
        bodyElement, name, toggleHint, label, hint, unit, errors, /* formOptions, */
        isRequired, isRecommended, showHint, showError, showLabel, showUnit
      } = inputProps,
      { className: customClassName, inlineLabel, showHintAlways, hideErrorMessage, hideHint } = bodyElement as InputElement,
      hasVisibleError = showError && errors && !isAllEmpty(errors),
      mainClassName = 'form-element-wrapper-main',
      wrapperProps = {
        id: name,
        className: [className, customClassName].filter(Boolean).join(' '),
        'data-cy': 'form-field-wrapper',
        ...(hasVisibleError ? { 'data-has-error': true } : {})
      },
      labelElement = <FormLabel {...{ label, showLabel, isRequired, isRecommended, htmlFor: inputId }} />,
      errorElement = hasVisibleError && !hideErrorMessage
        ? typeof errors === 'string' // This shouldn't happen but if it does the whole app hangs as ErrorLists rerenders endlessly
          ? <ErrorText aria-live="polite">{errors}</ErrorText>
          : Array.isArray(errors)
            ? <ErrorText aria-live="polite">{errors.join(', ')}</ErrorText>
            : <ErrorText><ErrorList errors={errors} maxLength={3} /></ErrorText>
        : null,
      inputElement = noWrapper
        ? children
        : <WithUnderline withError={!!errorElement}>
          {children}
        </WithUnderline>,
      unitElement = unit && showUnit && !isEmptyValue(unit)
        ? <Unit data-cy="form-field-wrapper__unit">{unit}</Unit>
        : null

    if (inlineLabel) {
      return <Wrapper {...wrapperProps}>
        <Main className={mainClassName}>
          <InlineWrapper $nowrap>
            {inputElement}
            {unitElement}
            {labelElement}
            {!!showHint || !hint || hideHint
              ? null
              : <HintIcon className="hint-icon" color={showHint ? 'back' : 'forward'} onClick={toggleHint} content={showHint ? BasicCross : '?'} degreesRotation={showHint ? 45 : 0} />}
          </InlineWrapper>
          {errorElement}
          {hint && showHint
            ? <HintWrapper>
                <Hint $showHint={showHint} className="form-hint" color="default">{addEmphasisToString(hint) ?? hint}</Hint>
                {showHintAlways
                  ? null
                  : <HintIcon className="hint-icon" color={showHint ? 'back' : 'forward'} onClick={toggleHint} content={showHint ? BasicCross : '?'} degreesRotation={showHint ? 45 : 0} />}
              </HintWrapper>
            : null}
        </Main>
      </Wrapper>
    } else {
      return <Wrapper {...wrapperProps}>
        <Main className={mainClassName} $wrap>
          {hint
            ? <InlineWrapper $nowrap>
              {labelElement}
              {!showHint && !hideHint
                ? <HintIcon className="hint-icon" color="forward" onClick={toggleHint} content="?" />
                : null}
            </InlineWrapper>
            : labelElement}

          {hint
            ? <HintWrapper $shrink={!showHint}>
              <Hint $showHint className="form-hint" color="default">{addEmphasisToString(hint) ?? hint}</Hint>
              {showHint && !showHintAlways ? <HintIcon $spaceLeft className="hint-icon" color="back" onClick={toggleHint} content={BasicCross} degreesRotation={45} /> : null}
            </HintWrapper>
            : null}

          {unitElement
            ? <InlineWrapper>
              {inputElement}
              {unitElement}
            </InlineWrapper>
            : inputElement}

          {errorElement}
        </Main>
      </Wrapper>
    }
  }

export default FormFieldWrapper
