import React from 'react'
import styled from 'styled-components'
import { Tab } from '~/components/atoms'
import { TabGroup } from '~/components/molecules'

import type { WorksheetResponse, Experiment, PagedSectionElement, PagedWorksheet, ListWorksheet } from '~/features'
import PagePreview from './PagePreview'
import PageSettingsForm from './PageSettingsForm'
import PageBodyForm from './PageBodyForm'
import EditorKey from '../EditorKey'

interface Props {
  experiment: Experiment
  worksheet: PagedWorksheet
  allWorksheets: Array<ListWorksheet | PagedWorksheet>
  updateWorksheet: (updatedWorksheet: PagedWorksheet | ListWorksheet) => void
  section: PagedSectionElement
  selectedPage: number
  setSelectedPage: (page?: number) => void
  setSelectedSection: (sectionId?: string) => void
  response: WorksheetResponse
  otherResponses: WorksheetResponse[]
  updateResponse: (updatedResponse: WorksheetResponse) => void
}

type PageEditorTabName = 'page-settings' | 'page-preview' | 'page-header' | 'page-body'

const
  OuterWrapper = styled.div`
    flex: 1 1 0px;
    display: flex;
    flex-direction: column;
  `,
  Wrapper = styled.div`
    padding: ${p => p.theme.emSmallSpacing}em;
    border: 2px solid ${p => p.theme.colors.containerAccentBg};
    flex: 1 0 auto;
  `,
  PageEditor: React.FC<Props> = ({
    experiment,
    allWorksheets,
    worksheet,
    section,
    selectedPage,
    setSelectedPage,
    setSelectedSection,
    response,
    otherResponses,
    updateResponse,
    updateWorksheet
  }) => {
    const [activeTab, setActiveTab] = React.useState<PageEditorTabName>('page-preview')

    return <>
      <OuterWrapper data-cy="page-editor">
        <TabGroup>
          <Tab
            label="Preview"
            tabRole={activeTab === 'page-preview' ? 'active' : 'inactive'}
            onClick={() => { setActiveTab('page-preview') }}
          />
          <Tab
            label="Content"
            tabRole={activeTab === 'page-body' ? 'active' : 'inactive'}
            onClick={() => { setActiveTab('page-body') }}
          />
          <Tab
            label="Settings"
            tabRole={activeTab === 'page-settings' ? 'active' : 'inactive'}
            onClick={() => { setActiveTab('page-settings') }}
          />
        </TabGroup>
        <Wrapper>
          {activeTab === 'page-body'
            ? <PageBodyForm {...{ experiment, worksheet, section, updateWorksheet, selectedPage, setSelectedPage, allWorksheets }} />
            : activeTab === 'page-settings'
              ? <PageSettingsForm {...{ experiment, worksheet, section, updateWorksheet, selectedPage, setSelectedPage, allWorksheets }} />
              : <PagePreview {...{ experiment, worksheet, section, response, otherResponses, updateResponse, selectedPage, setSelectedPage, setSelectedSection }} />}
        </Wrapper>
        <EditorKey />
      </OuterWrapper>
    </>
  }

export default PageEditor
