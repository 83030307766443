import * as React from 'react'
import styled from 'styled-components'
import { FormContext } from '~/form-brain2'
import { BasicCross, Icon } from '~/components/atoms'

import type { BodyElement, CollectionOptions, FormRendererProps } from '~/form-brain2'
import BodyElementListItemClosed from './BodyElementListItemClosed'
import BodyElementListItemOpen from './BodyElementListItemOpen'
import { getLongRandomId } from '~/utils/strings'

const
  AdderIcon = styled(Icon)`
    width: 0.7em;
  `,
  AdderOuter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 4px;

    &:not(:hover) {
      .element-adder-icon-loud {
        display: none;
        transform: scale(0);
      }
    }

    &:hover {
      .element-adder-icon-quiet {
        display: none;
      }

      .element-adder-icon-loud {
        transform: scale(3);
      }
    }
  `,
  ElementAdder: React.FC<{ onClick: React.MouseEventHandler }> = ({ onClick }) => {
    return <AdderOuter data-cy="element-adder">
      <AdderIcon content={BasicCross} color="understate" frameStyle="plain" onClick={onClick} className="element-adder-icon-quiet" />
      <AdderIcon content={BasicCross} color="back" onClick={onClick} className="element-adder-icon-loud" />
    </AdderOuter>
  },
  BodyElementListItem: React.FC<FormRendererProps> = ({ formId, formBody, className, isNested }) => {
    const
      formContext = React.useContext(FormContext),
      { scopeOptions, values } = formContext,
      { entryIndex, addEntry, isEditing, toggleEditing, collectionLength, ...collectionOptions } = scopeOptions as CollectionOptions,
      isFinal = entryIndex === ((collectionLength ?? 0) - 1),
      elementId = `beli_${formId}_${values.id as string | undefined}`,
      adderOnClickForIndex = (i: number): React.MouseEventHandler => (e) => {
        if (e) { e.stopPropagation(); e.preventDefault() }

        if (addEntry) { addEntry({ ...collectionOptions.defaultEntry, id: getLongRandomId() }, i) }
      },
      content = !!isEditing || scopeOptions?.elementVariety === 'SCOPE'
        ? <BodyElementListItemOpen
          key={`edit-element-form-${entryIndex}`}
          {...{ formId, elementId, formBody, className, isNested, toggleEditing: scopeOptions?.elementVariety === 'SCOPE' ? undefined : toggleEditing }}
        />
        : <BodyElementListItemClosed
          key={entryIndex}
          elementId={elementId}
          bodyElement={values as unknown as BodyElement}
          index={entryIndex as number}
          onClick={toggleEditing}
        />

    return scopeOptions?.elementVariety === 'SCOPE'
      ? content
      : [
          <ElementAdder key={`adder-${entryIndex}`} onClick={adderOnClickForIndex(entryIndex as number)} />,
          content,
          isFinal
            ? <ElementAdder key={`second-adder-${entryIndex}`} onClick={adderOnClickForIndex(entryIndex + 1)} />
            : null
        ]
  }

export default BodyElementListItem
