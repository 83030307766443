import * as React from 'react'
import { useTheme } from 'styled-components'
import { useClickOnKeyPress } from '~/hooks'
import { mergeRefs } from '~/hooks/utils'
import {
  pxIconBaseSize,
  backgroundColorLookup,
  ShadowedSvg,
  StandardizedIconOuter,
  StandardizedIconOuterLink
} from '~/components/atoms/Icons/iconConstants'
import { BasicCheckmark } from '~/components/atoms/Shapes'
import type { IconColor } from '~/components/atoms/Icons'
import type { LinkProps } from 'react-router-dom'

type ContentColorName = 'understateLight' | 'understateMed' | 'containerDarkBg' | 'attention' | 'borderStickerAlt' | 'borderSticker'
type IconColorPlus = IconColor | 'success'

interface CoreIconProps {
  color?: IconColorPlus
  degreesRotation?: number
  accessibleDescription?: string
  innerRef?: React.MutableRefObject<any>
  isChecked?: boolean
  className?: string
}

interface OnClickProps {
  accessibleDescription: string
  onClick: React.MouseEventHandler
}

interface WithLinkProps {
  accessibleDescription: string
  linkProps: LinkProps
}

export type IconProps = CoreIconProps | (CoreIconProps & OnClickProps) | (CoreIconProps & WithLinkProps)

const
  contentColorLookup: Record<IconColorPlus, ContentColorName> = {
    forward: 'borderStickerAlt',
    back: 'containerDarkBg',
    attention: 'borderStickerAlt',
    hero: 'attention',
    understate: 'understateLight',
    disabled: 'understateMed',
    caution: 'containerDarkBg',
    success: 'borderSticker'
  },
  CheckmarkIcon: React.FC<IconProps> = ({ color = 'forward', className, degreesRotation, innerRef, ...otherProps }) => {
    const
      THEME = useTheme(),
      elementRef = useClickOnKeyPress(),
      borderColor = color === 'success'
        ? THEME.colors.borderStickerAlt
        : THEME.colors.borderSticker,
      contentColor = THEME.colors[contentColorLookup[color]],
      backgroundColor = color === 'success'
        ? THEME.colors[backgroundColorLookup.forward]
        : THEME.colors[backgroundColorLookup[color]],
      accessibleDescription = 'accessibleDescription' in otherProps ? otherProps.accessibleDescription : undefined,
      isClickable = ('onClick' in otherProps) || ('linkProps' in otherProps),
      sharedWrapperProps = {
        $degreesRotation: degreesRotation,
        $clickable: isClickable,
        'aria-label': accessibleDescription,
        title: accessibleDescription,
        'data-cy': 'checkmark-icon',
        className,
        ...('isChecked' in otherProps ? { 'aria-checked': otherProps.isChecked, role: 'checkbox' } : {})
      },

      // Circle Geometry
      overallCircleWidth = pxIconBaseSize,
      circleRadius = overallCircleWidth / 2,
      borderWidth = overallCircleWidth * 0.10,

      // Inner Geometry
      innerViewBoxWidth = overallCircleWidth * 1.1,
      checkmarkOutlineWidth = 6,
      borderCheckGeometry = {
        x: 0.114 * innerViewBoxWidth,
        y: -0.164 * innerViewBoxWidth,
        width: 0.88 * innerViewBoxWidth
      },
      innerCheckGeometry = {
        x: 0.125 * innerViewBoxWidth,
        y: -0.164 * innerViewBoxWidth,
        width: 0.84 * innerViewBoxWidth
      },

      // Outer Geometry
      outerViewBoxWidth = pxIconBaseSize,
      wrapperGeometry = {
        x: 0.14 * outerViewBoxWidth,
        y: 0.04 * outerViewBoxWidth,
        width: 0.73 * outerViewBoxWidth
      },

      innerContent = (
        <ShadowedSvg data-cy="icon-frame-circle" width="100%" viewBox={`1 1 ${outerViewBoxWidth - 1} ${outerViewBoxWidth - 1}`}>
          <g className='icon-rotation-target'>
            <svg
              width={wrapperGeometry.width}
              x={wrapperGeometry.x}
              y={wrapperGeometry.y}
              viewBox={`0 0 ${innerViewBoxWidth} ${innerViewBoxWidth}`}>
              <svg
                width={borderCheckGeometry.width}
                x={borderCheckGeometry.x}
                y={borderCheckGeometry.y}>
                <BasicCheckmark color={contentColor} outlineColor={borderColor} outlineWidth={checkmarkOutlineWidth} />
              </svg>
              <circle cx={circleRadius} cy={circleRadius} r={circleRadius} fill={borderColor} />
              <circle cx={circleRadius} cy={circleRadius} r={circleRadius - borderWidth} fill={backgroundColor} />
              <svg
                width={innerCheckGeometry.width}
                x={innerCheckGeometry.x}
                y={innerCheckGeometry.y}>
                <BasicCheckmark color={contentColor} />
              </svg>
            </svg>
          </g>
        </ShadowedSvg>
      )

    if ('linkProps' in otherProps) {
      return (
        <StandardizedIconOuterLink
          ref={innerRef}
          {...sharedWrapperProps}
          {...otherProps.linkProps}>
          {innerContent}
        </StandardizedIconOuterLink>
      )
    } else {
      return (
        <StandardizedIconOuter
          {...sharedWrapperProps}
          ref={isClickable ? mergeRefs(elementRef, innerRef) : innerRef}
          tabIndex={isClickable ? 0 : -1}
          onClick={'onClick' in otherProps ? (otherProps.onClick as React.MouseEventHandler) : undefined}>
          {innerContent}
        </StandardizedIconOuter>
      )
    }
  }

export default CheckmarkIcon
