import { keyBy } from 'lodash'
import { isNumeric } from '~/utils/testers'
import { parseDate } from '~/utils/formatters'
import { getSubjectObjects, type SubjectObject } from '~/features/experiment/fieldSelectors/treatmentSelectors'

import type { DateTime } from 'luxon'
import type { Experiment } from '~/features/experiment/types'
import type { EntityObject } from '~/form-brain2'
import type { MeasurementEntry } from '~/features/experiment/specialSections/data'

export interface ExperimentDataPoint {
  date: DateTime
  value: number
  subjectId: string
}

interface Ret {
  dataPoints: ExperimentDataPoint[]
  omittedMeasurements: MeasurementEntry[]
  subjectsById: Record<string, SubjectObject>
}

const
  getDataPoints = (entities: EntityObject[], experiment: Experiment): Ret => {
    const
      measurements = entities.filter(o => 'measurementValue' in o && 'measurementSubject' in o) as unknown as MeasurementEntry[],
      subjectObjects = getSubjectObjects(entities, experiment),
      subjectsById = keyBy(subjectObjects, 'subjectId'),
      dataPoints: ExperimentDataPoint[] = [],
      omittedMeasurements: MeasurementEntry[] = []

    measurements.forEach((o, i) => {
      const
        { date, measurementValue, measurementSubject } = o,
        parsedDate = parseDate(date),
        value = isNumeric(measurementValue) ? parseFloat(measurementValue) : undefined,
        subjectId = measurementSubject,
        subjectObject = subjectObjects.find(o => o.subjectId.toString() === subjectId)

      // eslint-disable-next-line eqeqeq
      if (!parsedDate || (value == undefined) || !subjectId || !subjectObject) {
        /*
        if (omittedMeasurements.length === 0) {
          // TODO Dev report to Sentry?
        }
        */
        omittedMeasurements.push(o)
      } else {
        dataPoints.push({
          date: parsedDate,
          value,
          subjectId
        })
      }
    })

    return {
      dataPoints,
      omittedMeasurements,
      subjectsById
    }
  }

export default getDataPoints
