import * as React from 'react'
import { isAllEmpty } from '~/utils/testers'
import StackingSections, { EmptyList, RelativeWrapper } from '~/components/organisms/ListStep/StackingSections'
import ListStepEntryList from '~/components/organisms/ListStep/ListStepEntryList'
import ListEntryForm from '~/components/organisms/ListStep/ListEntryForm'
import ListInitChoices from './ListInitChoices'
import ListResetComplex from './ListResetComplex'

import type { ListEntrySummary, SpecialLayoutProps } from '../types'
import { SPECIAL_LAYOUT_NAMES } from '~/utils/strings'

const
  MaterialsLayout: React.FC<SpecialLayoutProps> = ({ entries, worksheet, entrySummaries, windowWidth, canStackSections, currentSelection, setCurrentSelection }) => {
    const [startingFromScratch, setStartingFromScratch] = React.useState<boolean | undefined>(isAllEmpty(worksheet.defaults))

    if (!entries.length && !startingFromScratch) {
      return <ListInitChoices
        buildButtonLabel={`Build ${SPECIAL_LAYOUT_NAMES.MATERIALS} from Design`}
        setStartingFromScratch={setStartingFromScratch}
        message="AgConnect can create a list of materials for you based on your experiment design - or you can create your own list of materials from scratch. You can always reset the list of materials if you change your mind."
      />
    }

    const
      entrySummary = entrySummaries.find(o => o.isSelected) as ListEntrySummary,
      listSection = <>
        <ListStepEntryList withInlineForm={canStackSections} {...{ currentSelection, setCurrentSelection, entrySummaries }} />
        {entries.length && canStackSections
          ? <ListResetComplex resetButtonLabel="Reset this List" onSuccess={() => { setStartingFromScratch(undefined); setCurrentSelection(undefined) }} />
          : null}
      </>,
      secondSection = !currentSelection || !entrySummaries
        ? <RelativeWrapper>
            <EmptyList>
              <p>Click any item from the list to see or update it.</p>
            </EmptyList>
            {entries.length
              ? <ListResetComplex resetButtonLabel="Reset this List" onSuccess={() => { setStartingFromScratch(undefined); setCurrentSelection(undefined) }} />
              : null}
          </RelativeWrapper>
        : <ListEntryForm {...{ setCurrentSelection, entrySummary, currentSelection }} />

    return (
      <StackingSections
        canStackSections={canStackSections}
        soleSection={listSection}
        firstSection={listSection}
        secondSection={secondSection}
      />
    )
  }

export default MaterialsLayout
