import * as React from 'react'
import styled from 'styled-components'
import { findLast } from 'lodash'
import { pointOnCircleFromAngle, degreeStopsForStepCount } from '~/utils/geometry'
import { useBoundingBox } from '~/hooks'
import { ProgressRing, pctRingThickness, SizedLoadingIcon } from '~/components/atoms'
import BotanicalOverlay from '~/components/molecules/BotanicalOverlay'
import StepSign, { emStepSignHeight, emStepSignWidth } from '~/components/molecules/StepSign'
import StepPointer, { type StepPointerVariant } from '~/components/molecules/StepPointer/StepPointer'

import type { ObjectId } from '~/types/utilities'
import type { WorksheetStatusForStudent } from '~/features'

export interface StepSummary {
  label: string
  index: number
  isBuilding: boolean
  buildResponse?: () => void
  status: WorksheetStatusForStudent
  worksheetId: ObjectId
}

interface ScientificMethodCycleProps {
  stepSummaries: StepSummary[]
  noResize?: boolean // For testing or to save cycles at fixed size
  className?: string
}

const
  pctCycleWidth = 70,
  ScientificMethodCycleOuter = styled.div<{ $height?: number }>`
    position: relative;
    width: 100%;
    height: ${p => p.$height ? `${p.$height}px` : 'auto'};
    padding: ${(100 - pctCycleWidth) / 2.0}%;
    display: flex;
  `,
  BotanicalUnderlay = styled(BotanicalOverlay)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,
  RingAndStepWrapper = styled.div`
    position: relative;
    width: 100%;
    z-index: ${p => p.theme.zLocalTop};
  `,
  Ring = styled(ProgressRing)`
  `,
  SignParentPositionWrapper = styled.div<{ $coords: [number, number], $fontSize?: number }>`
    position: absolute;
    left: ${p => p.$coords[0]}px;
    top: ${p => p.$coords[1]}px;
    z-index: ${p => p.theme.zLocalTop + 1};
    font-size: ${p => p.$fontSize}px;
  `,
  SignSelfPositionWrapper = styled.div`
    position: relative;
    width: ${emStepSignWidth}em; 
    height: ${emStepSignHeight}em; 
  `,
  StyledStepSign = styled(StepSign)`
    font-size: inherit;

    position: absolute;
    left: -50%; 
    top: -50%; 
  `,
  PositionedStepPointer = styled(StepPointer)<{ $scaleTo: number, $offset: { x: number, y: number } }>`
    position: absolute;
    transform: scale(${p => p.$scaleTo});
    transform-origin: top left;
    left: ${p => p.$offset.x}%;
    top: ${p => p.$offset.y}%;
  `,
  PositionedLoadingIcon = styled(SizedLoadingIcon)`
    // margin-top: 33%;
    align-self: center;
    margin: auto;
  `,
  pointerOffsetVariantAndOffset = (degree: number): { x: number, y: number, variant: StepPointerVariant } => {
    if (degree <= 30) {
      return { x: 23, y: -101, variant: 'SW-tail' }
    } else if (degree <= 90) {
      return { x: 16, y: -130, variant: 'SW-head' }
    } else if (degree <= 180) {
      return { x: 16.3, y: 29.4, variant: 'NW-tail' }
    } else if (degree <= 240) {
      return { x: -86.4, y: 26.5, variant: 'NE-tail' }
    } else if (degree <= 270) {
      return { x: -78.3, y: 14.9, variant: 'NE-head' }
    } else {
      return { x: -101.6, y: -99, variant: 'SE-tail' }
    }
  },
  // iconByStatus = {
  //   inProgress: undefined,
  //   approved: <CheckmarkIcon color='success' degreesRotation={-8} />,
  //   revising: <Icon color="attention" content="!" frameStyle="circle" />,
  //   pending: <Icon color="forward" content="?" frameStyle="circle" />
  // },
  ScientificMethodCycle: React.FC<ScientificMethodCycleProps> = ({ stepSummaries, noResize, className }) => {
    const
      { width, outerElementRef, isResizing } = useBoundingBox<HTMLDivElement>(),
      currentStep = findLast(stepSummaries, ({ status }) => status !== 'closed'),
      currentStepIndex = currentStep?.index ?? 0,
      circleRadius = (width ?? 200) / 2 * (pctCycleWidth / 100),
      // set sign circle on midpoint between outer and inner edges of progress ring
      signCircleRadius = circleRadius * (1 - (pctRingThickness * 0.55)),
      oddNumberOfSteps = !!(stepSummaries.length % 2),
      degreeSignPositions = degreeStopsForStepCount(stepSummaries.length),
      signFontSize = width ? width * 0.032 : 15,
      stepSigns = stepSummaries.map(({ label, index, status, buildResponse, isBuilding, worksheetId }, i) => {
        const
          degreeAngle = degreeSignPositions[index],
          location = pointOnCircleFromAngle({
            degreeAngle,
            // shift center to account for smaller sign circle radius than container width
            cx: signCircleRadius + (circleRadius - signCircleRadius),
            cy: signCircleRadius + (circleRadius - signCircleRadius),
            r: signCircleRadius
          }),
          coords: [number, number] = [
            location[0],
            // Move signs at 12:00 and 6:00 up for better visual alignment
            (index === 0
              ? location[1] - (circleRadius - signCircleRadius)
              : !oddNumberOfSteps && index === (stepSummaries.length / 2)
                  ? location[1] - (circleRadius - signCircleRadius) / 2
                  : location[1])
          ],
          actionProps = status === 'closed' || isBuilding
            ? { isLoading: isBuilding }
            : buildResponse
              ? { onClick: buildResponse }
              : { linkProps: { to: `/project-step/${worksheetId}` } }

        return (
          <SignParentPositionWrapper
            key={`${worksheetId}-${width.toString()}`}
            $fontSize={signFontSize}
            $coords={coords}>
            <SignSelfPositionWrapper>
              {currentStepIndex === index
                ? <PositionedStepPointer
                    $scaleTo={signFontSize / 15}
                    variant={pointerOffsetVariantAndOffset(degreeAngle).variant}
                    $offset={pointerOffsetVariantAndOffset(degreeAngle)}
                  />
                : null}
              <StyledStepSign
                label={label}
                number={(index + 1).toString()}
                stepStatus={status}
                withShadow
                {...actionProps}
              />
            </SignSelfPositionWrapper>
          </SignParentPositionWrapper>
        )
      })

    return <ScientificMethodCycleOuter ref={outerElementRef} $height={width} className={className} data-cy="scientific-method-cycle">
      {isResizing && !noResize ? null : <BotanicalUnderlay />}
      {isResizing && !noResize
        ? <PositionedLoadingIcon color="accent" />
        : <RingAndStepWrapper>
          <Ring totalSteps={stepSummaries.length} currentStep={currentStepIndex} />
          {stepSigns}
        </RingAndStepWrapper>}
    </ScientificMethodCycleOuter>
  }

export default ScientificMethodCycle
