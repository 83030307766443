import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { ArrowCurved, HintText } from '~/components/atoms'
import {
  arrowRotationLookup,
  hintTextRotationLookup,
  arrowDirectionLookup,
  outerDimensionsLookup,
  arrowPositionLookup,
  textPositionLookup
} from '~/components/molecules/StepPointer/stepPointerConstants'
import type { StepPointerVariant } from '~/components/molecules/StepPointer/stepPointerConstants'

interface StepPointerProps {
  className?: string
  variant: StepPointerVariant
}

interface RotatedChildProps {
  $variant: StepPointerVariant
}

const
  StepPointerOuter = styled.div<RotatedChildProps>`
    position: relative;
    height: ${p => outerDimensionsLookup[p.$variant].y}px;
    width: ${p => outerDimensionsLookup[p.$variant].x}px;
  `,
  RotatedArrow = styled(ArrowCurved)<RotatedChildProps>`
    position: absolute;
    height: 61.50px;
    transform: ${p => arrowRotationLookup[p.$variant]};
    top: ${p => arrowPositionLookup[p.$variant].y}px;
    left: ${p => arrowPositionLookup[p.$variant].x}px;
  `,
  RotatedHint = styled(HintText)<RotatedChildProps>`
    position: absolute;
    transform: ${p => hintTextRotationLookup[p.$variant]};
    top: ${p => textPositionLookup[p.$variant].y}px;
    left: ${p => textPositionLookup[p.$variant].x}px;
    white-space: nowrap;
  `,
  StepPointer: React.FC<StepPointerProps> = ({ className, variant }) => {
    const
      THEME = useTheme(),
      content = 'You\'re Here!'

    return (
      <StepPointerOuter $variant={variant} className={className} data-cy="step-pointer">
        <RotatedHint $variant={variant} color={'accent'}>{content}</RotatedHint>
        <RotatedArrow $variant={variant} direction={arrowDirectionLookup[variant]} color={THEME.colors.bodyAccentText}/>
      </StepPointerOuter>
    )
  }

export default StepPointer
export { arrowRotationLookup }
export type { StepPointerVariant }
