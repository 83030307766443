import React from 'react'
import { last } from 'lodash'
import styled from 'styled-components'
import { isAllEmpty } from '~/utils/testers'
import { WorksheetResponseHelpers } from '~/features'
import { useFormVitalsSubscription } from '~/hooks'
import { getEntrySummaries } from '~/components/organisms/ListStep/helpers'
import { WideButton } from '~/components/organisms/ListStep/ListEntryForm'
import { Button } from '~/components/atoms'
import ListEntryFormContent from '~/components/organisms/ListStep/ListEntryFormContent'
import ListEntryRow from '../../ListStep/ListEntryRow'

import type { FormOnSubmitFn, FormOnSuccessFn } from '~/form-brain2'
import type { Experiment, ListEntry, ListResponse, ListSectionElement, ListWorksheet, RepeatingSectionElement, SpecialListSectionElement, WorksheetResponse } from '~/features'
import type { ListEntrySummary, ListStepSelection } from '~/components/organisms/ListStep/types'
import { ErrorBoundary } from 'react-error-boundary'
import PreviewFallbackRenderer from '../PreviewErrorBoundary'
import { InfoPanel } from '~/components/molecules'
import { getDynamicString } from '~/features/experiment/helpers'
import SummaryStatsCard from '../../SpecialContentArea/SummaryStatsCard'
import StatsTestCard from '../../SpecialContentArea/StatsTestCard'

interface Props {
  experiment: Experiment
  worksheet: ListWorksheet
  section: ListSectionElement
  response: ListResponse
  otherResponses: WorksheetResponse[]
  updateResponse: (updatedResponse: WorksheetResponse) => void
}

const
  Outer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  AdderButton = styled(Button)<{ $atBottom?: boolean }>`
    margin: ${p => p.$atBottom ? 0 : p.theme.emSmallSpacing}em 0 ${p => p.theme.emBaseSpacing}em ; 
  `,
  ListWrapper = styled.div`
    [data-cy="open-card"] {
      margin-bottom: 1em;
    }
  `,
  StyledInfoPanel = styled(InfoPanel)`
    margin-bottom: ${p => p.theme.emSmallSpacing}em;
    padding: ${p => p.theme.emSmallSpacing}em;
    background: ${p => p.theme.colors.containerBg};
  `,
  getIndexForEntryUpdate = (section: ListSectionElement, worksheet: ListWorksheet, entries: ListEntry[], entry: ListEntry | undefined): number => {
    if (entry) {
      return entries.indexOf(entry)
    }

    if (section.elementVariety === 'LIST_OPENER_SECTION') {
      return 0
    }

    if (section.elementVariety === 'LIST_CLOSER_SECTION') {
      return entries.length
    }

    return last(entries)?.sectionId === worksheet.closingSection?.id ? entries.length - 1 : entries.length
  },
  fudgeWorksheetForSummaries = (worksheet: ListWorksheet, section: ListSectionElement): ListWorksheet => {
    if (!section) { return worksheet }

    if (section.elementVariety === 'LIST_ENTRY_SECTION') {
      return {
        ...worksheet,
        openingSection: undefined,
        closingSection: undefined,
        repeatingSections: [section as RepeatingSectionElement]
      }
    }

    if (section.elementVariety === 'LIST_OPENER_SECTION') {
      return {
        ...worksheet,
        closingSection: undefined,
        repeatingSections: []
      }
    }

    return {
      ...worksheet,
      openingSection: undefined,
      repeatingSections: []
    }
  },
  ListSectionPreview: React.FC<Props> = ({ experiment, worksheet, section, response, otherResponses, updateResponse }) => {
    const
      [selectedLocalIndex, setSelectedLocalIndex] = React.useState<number>(0),
      { formMethodsRef, formSubmissionSource, subscribeToFormStatus, isProcessing, formStatus } = useFormVitalsSubscription(),
      otherEntities = WorksheetResponseHelpers.getEntitiesAcrossResponses([...otherResponses, response]),
      entries = response.responses.entries,
      relevantEntries = entries.filter(o => o.sectionId === section.id),
      entry = selectedLocalIndex === undefined
        ? relevantEntries[0]
        : relevantEntries[selectedLocalIndex],
      onSaveEntry: React.MouseEventHandler = (e) => {
        if (formMethodsRef.current?.triggerFormSubmission) {
          formMethodsRef.current.triggerFormSubmission(e)
        }
      },
      onSubmitCallback: FormOnSubmitFn = ({ values, submissionSource }) => {
        const updatedResponse = WorksheetResponseHelpers.addEntryToListResponse(
          response,
          values as ListEntry,
          !entry,
          getIndexForEntryUpdate(section, worksheet, entries, entry)
        )
        updateResponse(updatedResponse)
        return Promise.resolve({ submissionSource })
      },
      onSuccessCallback: FormOnSuccessFn = () => { /* No op */ },
      onDeleteCallback = (): void => {
        const newValues = WorksheetResponseHelpers.removeEntryFromListResponse(
          response,
          getIndexForEntryUpdate(section, worksheet, entries, entry)
        )

        setSelectedLocalIndex(selectedLocalIndex - 1 ?? 0)
        updateResponse(newValues)
      },
      resetResponse = (): void => {
        updateResponse({ ...response, responses: { entries: [] } })
      },
      currentSelection: ListStepSelection = section.elementVariety !== 'LIST_ENTRY_SECTION'
        ? { segment: section.elementVariety }
        : entry
          ? { entryIndex: selectedLocalIndex }
          : { segment: 'LIST_ENTRY_SECTION' },
      entrySummaries = getEntrySummaries({ currentSelection, entries: relevantEntries, worksheet: fudgeWorksheetForSummaries(worksheet, section) }),
      selectedEntrySummary = { ...entrySummaries.find(o => o.isSelected) as ListEntrySummary, section },
      fauxSetSelection = (el: ListStepSelection): void => {
        if (el === undefined) {
          /* No op */
        } else {
          setSelectedLocalIndex('entryIndex' in el ? el.entryIndex : 0)
        }
      },
      saveButton = formStatus !== 'clean' && formStatus !== 'cleanAfterSuccess'
        ? <WideButton
            color="forward"
            withIcon="checkmark"
            size="small"
            onClick={onSaveEntry}
            isLoading={isProcessing}
            label={getDynamicString(experiment, '_addExampleData')}
          />
        : null,
      canAdd = (
        (formStatus === 'clean' || formStatus === 'cleanAfterSuccess') &&
        section.elementVariety === 'LIST_ENTRY_SECTION' &&
        !(
          (section as RepeatingSectionElement).maximumLength &&
          relevantEntries.length >= ((section as RepeatingSectionElement).maximumLength as number)
        )
      ),
      adder = canAdd && entry
        ? <Outer key="adder">
          <AdderButton
            label="Add another example"
            color="forward"
            size="small"
            $atBottom={!worksheet.reverseStartAndEnd}
            onClick={() => { setSelectedLocalIndex(relevantEntries.length) }}
          />
        </Outer>
        : null,
      form = <ListEntryFormContent
          key={`list-section-preview-${selectedLocalIndex}`}
          formId={`list-section-preview-${selectedLocalIndex}`}
          {...{
            formMethodsRef,
            formSubmissionSource,
            subscribeToFormStatus,
            onSubmitCallback,
            onSuccessCallback,
            onDeleteCallback,
            experiment,
            section,
            otherEntities,
            isProcessing,
            entries
          }}
          naturalHeight
          permitDelete={section.elementVariety === 'LIST_ENTRY_SECTION' && entries.length > 1 && !!entry}
          entrySummary={selectedEntrySummary}
          rightButton={saveButton}
          neutralBackground={worksheet.specialLayoutType === 'ANALYSIS'}
        />

    React.useEffect(() => {
      if (selectedLocalIndex > entries.length) {
        setSelectedLocalIndex(entries.length)
      }
    }, [selectedLocalIndex, entries.length])

    return <>
      {section.elementVariety === 'LIST_ENTRY_SECTION'
        ? <StyledInfoPanel>Learners can add multiples of each list entry, in any order (unless a walkthrough is added).</StyledInfoPanel>
        : <StyledInfoPanel>Learners will fill out this section once.</StyledInfoPanel>}
      {worksheet.reverseStartAndEnd
        ? adder
        : null}
      <ListWrapper>
        {entrySummaries.map((entrySummary, i) => {
          return <ErrorBoundary fallbackRender={PreviewFallbackRenderer} key={`${entrySummary.actualIndex ?? `noindex-${i}`}-of-${entries.length}`}>
            {(section as SpecialListSectionElement).specialSectionId === 'SUMMARY_STATS'
              ? <SummaryStatsCard
                {...{
                  otherEntities,
                  response,
                  section: section as SpecialListSectionElement,
                  experiment,
                  entry,
                  onSubmitCallback
                }}
                isNewNonWalkthrough={!entrySummary.entry}
              />
              : (section as SpecialListSectionElement).specialSectionId === 'STATS_TEST'
                  ? <StatsTestCard
                    {...{
                      entrySummary,
                      otherEntities,
                      response,
                      section: section as SpecialListSectionElement,
                      experiment,
                      entry,
                      onSubmitCallback,
                      isUpdating: isProcessing
                    }}
                  />
                  : entrySummary.isSelected
                    ? form
                    : <ListEntryRow
                      entrySummary={entrySummary}
                      experiment={experiment}
                      withInlineForm
                      otherEntities={otherEntities}
                      setCurrentSelection={fauxSetSelection}
                      currentSelection={currentSelection}
                    />}
          </ErrorBoundary>
        })}
      </ListWrapper>
      {worksheet.reverseStartAndEnd
        ? null
        : adder}
      {isAllEmpty(response.responses)
        ? null
        : <Button size="small" label="Reset Example Responses" onClick={resetResponse} />}
    </>
  }

export default ListSectionPreview
