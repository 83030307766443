import type { DefaultTheme } from 'styled-components'

const
  // UVM Colors (uvm16090)
  // https://contrast-grid.eightshapes.com/?background-colors=&foreground-colors=%23DDD%0D%0A%23FAFAFA%0D%0A%23FFF%0D%0A%23000%0D%0A%2301533F%0D%0A%23007155%0D%0A%2366AC47%0D%0A%23A7D233%0D%0A%23A7D4EE%0D%0A%2390D4ED%0D%0A%23159FDA%0D%0A%231375AF%0D%0A%23FFD416%0D%0A%23D99B13%0D%0A%23F47D20%0D%0A%23888%0D%0A%23EBEBEB%0D%0A%23CECECE%0D%0A%237F7E7E%0D%0A%23444444%0D%0A&es-color-form__tile-size=compact
  zBase = 1,
  UVM16090 = {
    brgreen: '#66ac47',
    uvmgreen: '#007155',
    ltgreen: '#a7d233',
    dkgreen: '#01533f',
    skyblue: '#a7d4ee',
    midblue: '#90d4ed',
    darkblue: '#159fda',
    deepblue: '#1375af',
    bryellow: '#ffd416',
    yellowochre: '#d99b13',
    orange: '#f47d20',
    midgrey888: '#888',
    yellow: '#ffd416',
    graylight: '#ebebeb',
    graylightmedium: '#cecece',
    graymedium: '#7f7e7e',
    graydark: '#444444',
    white: '#ffffff'
  },
  AGC_COLORS = {
    blueDark: '#1A2D83',
    blueMed: '#5262C9', // Occasionally #6272B8 in design
    blueMedLight: '#BDC5FA', // lavender blue
    blueLight: '#EAECF6', // lavender
    brownDark: '#60451F',
    brownMed: '#A88756',
    grayDark: '#505050',
    grayMedDark: '#727272',
    grayMed: '#ACACAC', // Occasionally #BFBFBF in design for understate text
    grayMedLight: '#CFCECE',
    grayLight: '#E4E4E4',
    greenGrayDark: '#2B6450',
    greenGrayMed: '#408464',
    greenDark: '#0E6949',
    greenMed: '#2EA66C',
    greenLight: '#EAF6EB',
    orangeDark: '#BA4E00',
    orangeMed: '#EE9453',
    pinkDark: '#B1104A',
    pinkMed: '#EE538B',
    redDark: '#90150E', // Check with GT on this one
    redMed: '#EE5C53',
    white: '#FFF',
    yellowDark: '#B18A00',
    yellowMed: '#E4BD33',
    yellowGold: '#FFAC33',
    contrastWhite: '#F5F5F5',
    designBrandDarkGreen: '#1C4131',
    designBrandMedGreen: '#2A6450',
    designBrandLightGreen: '#408464',
    uvmBrandDarkGreen: '#004434', // from uvm website footer
    uvmBrandMedGreen: '#01533F', // from uvm website footer
    uvmBrandLightGreen: UVM16090.uvmgreen // from uvm brand guide, #006b51 or #1F6E41 on current site
  },
  _ = AGC_COLORS,
  SMALL_SPACING = 0.9, // 13.5px
  BASE_THEME: DefaultTheme = {
    pxBorderRadius: 7, // 15
    pxBorderWidth: 1.5, // or 3
    pxBorderWidthThick: 3, // or 6,

    pxShadowBlur: 5,
    pxShadowOffset: 1,

    emBaseSpacing: SMALL_SPACING,
    emSmallSpacing: SMALL_SPACING / 2,

    pxIconSmall: 13.5,
    pxIconMedium: 19,
    pxIconLarge: 31.2,

    pxMaxContentWidth: 960,

    pxFullScreenCutoffWidth: 600,
    pxMinSupportedViewportWidth: 320,
    pxPortraitPrintWidth: 725,
    pxLandscapePrintWidth: 975,

    emMinReadingWidth: 23,
    emMaxReadingWidth: 35, // Min 23em (aka avg 50 char), Max 35em (aka avg 75 characters)
    emLargeContentWidth: 50, // per design: 832px
    emMediumContentWidth: 28, // per design: 475px (TODO small form width)
    emSmallContentWidth: 13.5, // per design: 211.5px

    pxHeaderHeight: 76,

    bodyFont: 'font-family: "Nunito", sans-serif; font-weight: 800;',
    accentFont: 'font-family: "Patrick Hand", cursive;',
    headingFont: 'font-family: "Nunito", sans-serif; font-weight: 800;',

    pxPrintBodyFontSize: 12,
    pxPrintHeadingFontSize: 14,

    zBase,
    zLocalTop: zBase + 1,
    zPageTop: zBase + 10,
    zGlobalTop: zBase + 100,

    showMascot: true,
    showFormFieldRequirednessIndicators: true,

    colors: {
      brandPrimary: _.designBrandLightGreen,
      brandSecondary: _.designBrandDarkGreen,
      brandText: UVM16090.white,

      brandLight: _.designBrandLightGreen,
      brandMed: _.designBrandMedGreen,
      brandDark: _.designBrandDarkGreen,

      bodyBg: _.white,
      bodyText: _.grayDark,
      bodyAccentText: _.blueDark,
      bodyUnderstateText: _.grayMedDark,
      bodyInverseText: _.white,

      translucentDark: 'rgba(28,65,49,0.5)',
      translucentLight: 'rgba(250,250,250,0.8)',
      shadow: 'rgba(0, 0, 0, 0.2)',

      understateLight: _.grayMedLight,
      understateMed: _.grayMed,
      understateDark: _.grayMedDark,

      open: _.greenGrayDark,
      complete: _.greenGrayMed,
      closed: _.grayMedDark,

      border: _.grayDark, // Occasionally #6B6B6B or #BFBFBF in design
      borderAlt: _.grayMed,
      borderAccent: _.blueDark, // Occasionally #1D2454 in design
      borderAccentAlt: _.blueMedLight,
      borderSticker: _.white,
      borderStickerAlt: _.contrastWhite,

      headingText: _.grayDark,
      headingAccentText: _.blueDark,
      headingAccentTextAlt: _.greenDark,
      headingUnderstateText: _.blueMed,

      containerBg: _.contrastWhite,
      containerText: _.grayDark, // Occasionally #6B6B6B in design
      containerAccentBg: _.blueLight,
      containerAccentText: _.grayDark, // Occasionally #727272 in design
      containerAsideBg: _.blueLight,
      containerAsideText: _.grayDark, // Occasionally #727272 in design
      containerDarkBg: _.grayLight,
      containerDarkText: _.grayDark,

      forward: _.greenMed,
      forwardText: _.white,
      forwardStandaloneText: _.greenMed,
      back: _.blueDark,
      backText: _.white,
      backStandaloneText: _.blueDark,
      caution: _.redMed,
      cautionText: _.white,
      cautionStandaloneText: _.redDark,
      attention: _.yellowGold,
      attentionText: _.white,
      attentionStandaloneText: _.blueMed,

      accentOneDark: _.pinkDark,
      accentOneLight: _.pinkMed,
      accentTwoDark: _.orangeDark,
      accentTwoLight: _.orangeMed,
      accentThreeDark: _.yellowDark,
      accentThreeLight: _.yellowMed,
      accentFourDark: _.greenDark,
      accentFourLight: _.greenMed,
      accentFiveDark: _.blueDark,
      accentFiveLight: _.blueMed,
      accentSixDark: _.brownDark,
      accentSixLight: _.brownMed
    }
  }

export default BASE_THEME
export { BASE_THEME, UVM16090, AGC_COLORS }
