import React from 'react'
import styled from 'styled-components'
import { values } from 'lodash'
import { useParams } from 'react-router-dom'
import { WorksheetHelpers } from '~/features'
import { BasicCross, BasicPencil, BasicTriangle, Button, Icon, Table, TBody, TD as _TD, TH, THead, TR } from '~/components/atoms'
import { selectExperimentById, selectWorksheetResponses, selectWorksheetsByExperimentId } from '~/features/entity/selectors'
import { useAppSelector, useUpdateEntity } from '~/hooks'
import { ActionPointer, ErrorNotice } from '../../molecules'

import type { AnyWorksheet, Experiment, Template, Worksheet, WorksheetResponse } from '~/features'
import NewStepForm from '../StepManagement/NewStepForm'

type UpdateReleasedAtFn = (params: { newValue: string | null, worksheet: AnyWorksheet }) => void
type UpdateRankFn<T extends AnyWorksheet | Template> = (params: { worksheet: T, action: 'increment' | 'decrement' }) => void

interface StepRowProps {
  worksheet: AnyWorksheet
  experiment: Experiment
  parentWorksheet?: AnyWorksheet
  responses: WorksheetResponse[]
  updateReleasedAt: UpdateReleasedAtFn
  updateRank: UpdateRankFn<AnyWorksheet>
  canIncrement?: boolean
  canDecrement?: boolean
  isUpdating?: boolean
  isDisabled?: boolean
}

const
  TableIcon = styled(Icon)<{ $disabled?: boolean }>`
    width: 1.2em;
    display: inline-flex;
    // margin-bottom: -2px;
    ${p => p.$disabled ? 'opacity: 0.5; cursor: not-allowed;' : ''}
  `,
  StyledActionPointer = styled(ActionPointer)`
    transform: scale(1.2);
    position: relative;
    right: 5.5em;

    margin-top: 1em;
    margin-bottom: 1em;
  `,
  TD = styled(_TD)<{ $childArrows?: boolean, $childLabel?: boolean }>`
    white-space: pre-line;
    ${p => p.$childArrows ? 'border-right-style: dashed !important;' : ''}
    ${p => p.$childLabel ? 'text-align: right;' : p.$childLabel === false ? 'text-align: left;' : ''}
  `,
  getEntityRankUpdater = <T extends AnyWorksheet | Template>(relatedWorksheets: T[], updateEntity: ReturnType<typeof useUpdateEntity>['updateEntity']): UpdateRankFn<T> =>
    ({ worksheet, action }) => {
      const
        currentIndex = relatedWorksheets.indexOf(worksheet),
        targetPair = [
          relatedWorksheets[currentIndex + (action === 'increment' ? 1 : -2)],
          relatedWorksheets[currentIndex + (action === 'increment' ? 2 : -1)]
        ],
        newRank = targetPair[0] && targetPair[1]
          ? (targetPair[0].rank + targetPair[1].rank) / 2
          : action === 'increment'
            ? targetPair[0] ? targetPair[0].rank + 1 : worksheet.rank
            : targetPair[1] ? targetPair[1].rank - 1 : worksheet.rank

      updateEntity({
        newValues: { rank: newRank },
        entity: worksheet,
        sourceOfUpdate: worksheet.id
      }).catch((error) => {
        /* keep log */ console.log(error)
      })
    },
  StepRow: React.FC<StepRowProps> = ({ worksheet, parentWorksheet, responses, canIncrement, canDecrement, updateReleasedAt, updateRank, isUpdating, isDisabled, experiment }) => {
    const
      label = parentWorksheet
        // ? `${parentWorksheet.name}:\n${worksheet.name}`
        ? `${worksheet.name} (in ${parentWorksheet.name})`
        : `${worksheet.name}${worksheet.format === 'HUB' ? ' (Hub)' : ''}`,
      hasResponses = responses.length,
      showArrows = !worksheet.fixedRank && !hasResponses && (!!canIncrement || canDecrement),
      status = !worksheet.releasedAt
        ? 'Draft'
        : !hasResponses
            ? 'Published'
            : `Has ${responses.length} response${responses.length > 1 ? 's' : ''}`,
      allowDecrement = canDecrement && !isDisabled && !isUpdating && !hasResponses,
      allowIncrement = canIncrement && !isDisabled && !isUpdating && !hasResponses,
      submitAsGroup = worksheet.fixedRank
        ? experiment.experimentStyle === 'group'
        : worksheet.submitAsGroup

    return <TR data-cy="step-row">
      <TD $childArrows={!!parentWorksheet}>
        {showArrows
          ? <TableIcon
            content={BasicTriangle}
            frameStyle="plain"
            color="back"
            $disabled={!allowDecrement}
            accessibleDescription={allowDecrement ? 'Move up' : undefined}
            onClick={allowDecrement ? () => { updateRank({ worksheet, action: 'decrement' }) } : undefined}
          />
          : null}
        {showArrows
          ? <TableIcon
            content={BasicTriangle}
            frameStyle="plain"
            color="back"
            $disabled={!allowIncrement}
            degreesRotation={180}
            accessibleDescription={allowIncrement ? 'Move down' : undefined}
            onClick={allowIncrement ? () => { updateRank({ worksheet, action: 'increment' }) } : undefined}
          />
          : null}
      </TD>
      <TD $childLabel={!!parentWorksheet}>{label}</TD>
      {worksheet.format === 'HUB' || worksheet.format === 'DOWNLOADS'
        ? <TD>N/A</TD>
        : <TD>
          {[
            submitAsGroup ? 'One per group' : 'Fill out individually',
            !worksheet.includeReviewStep ? 'No review' : null,
            worksheet.skipSubmission ? 'No submission' : null
          ].filter(o => o).join('\n')}
         </TD>}
      <TD>{status}</TD>
      <TD>
        {!worksheet.releasedAt // && (!parentWorksheet || parentWorksheet.releasedAt)
          ? <Button
              label="Publish"
              color="forward"
              isLoading={isUpdating}
              isDisabled={isDisabled}
              onClick={!isDisabled ? () => { updateReleasedAt({ worksheet, newValue: new Date().toISOString() }) } : undefined}
            />
          : worksheet.releasedAt && !hasResponses
            ? <Button
                label="Retract"
                color="back"
                isLoading={isUpdating}
                isDisabled={isDisabled}
                onClick={!isDisabled ? () => { updateReleasedAt({ worksheet, newValue: null }) } : undefined}
              />
            : null}
      </TD>
      <TD>
        {!worksheet.releasedAt
          ? <TableIcon content={BasicPencil} frameStyle="plain" color="back" linkProps={{ to: `/steps/${worksheet.id}` }} />
          : null}
      </TD>
    </TR>
  },
  ProjectSteps: React.FC<{ isDisabled?: boolean }> = ({ isDisabled }) => {
    const
      { id } = useParams(),
      [isAdding, setIsAdding] = React.useState(false),
      { updateEntity, sourceOfUpdate, isUpdating, errors } = useUpdateEntity(),
      experiment = useAppSelector(selectExperimentById(id)) as Experiment,
      worksheets = useAppSelector(selectWorksheetsByExperimentId(id)),
      allResponses = useAppSelector(selectWorksheetResponses),
      responses = values(allResponses).filter(o => o.experimentId === id),
      sortedWorksheets = WorksheetHelpers.getSortedTopLevelWorksheets(worksheets),
      updateReleasedAt: UpdateReleasedAtFn = ({ newValue, worksheet }) => {
        updateEntity({
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          newValues: { releasedAt: newValue } as Partial<Worksheet>,
          entity: worksheet,
          sourceOfUpdate: worksheet.id
        }).catch((error) => {
          /* keep log */ console.log(error)
        })
      },
      releaseAll = (): void => {
        updateEntity({
          newValues: {},
          entity: experiment,
          sourceOfUpdate: 'all',
          customPath: `experiments/${experiment.id}/release_all_steps`
        }).catch((error) => {
          /* keep log */ console.log(error)
        })
      }

    return <>
      {errors
        ? <ErrorNotice errors={errors} fallback="Unable to update experiment. Please try again and contact support if the problem persists." />
        : null}
      <Table>
        <THead><TR>
          <TH>Order</TH>
          <TH>Step Name</TH>
          <TH>Configuration</TH>
          <TH>Status</TH>
          <TD>
            {worksheets.find(o => !o.releasedAt)
              ? <Button
                  label="Publish All"
                  color="forward"
                  isLoading={sourceOfUpdate === 'all'}
                  isDisabled={!!isDisabled || (isUpdating && sourceOfUpdate !== 'all')}
                  onClick={!isDisabled ? () => { releaseAll() } : undefined}
                />
              : null}
          </TD>
          <TD />
        </TR></THead>
        <TBody>
          {sortedWorksheets.flatMap((worksheet, i) => {
            const
              subWorksheets = WorksheetHelpers.getSortedWorksheetsForHubStep(worksheet.id, worksheets),
              canIncrement = !worksheet.fixedRank && sortedWorksheets[i + 1] && !sortedWorksheets[i + 1].fixedRank,
              canDecrement = !worksheet.fixedRank && sortedWorksheets[i - 1] && !sortedWorksheets[i - 1].fixedRank

            return [
              <StepRow
                key={worksheet.id}
                worksheet={worksheet}
                isUpdating={sourceOfUpdate === worksheet.id}
                isDisabled={!!isDisabled || (isUpdating && sourceOfUpdate !== worksheet.id)}
                {...{ updateReleasedAt, canIncrement, canDecrement, experiment }}
                updateRank={getEntityRankUpdater(sortedWorksheets, updateEntity)}
                responses={responses.filter(r => r.worksheetId === worksheet.id)}
              />,
              ...subWorksheets.map((subWorksheet, j) => {
                const
                  canInc = !subWorksheet.fixedRank && subWorksheets[j + 1] && !subWorksheets[j + 1].fixedRank,
                  canDec = !subWorksheet.fixedRank && subWorksheets[j - 1] && !subWorksheets[j - 1].fixedRank

                return <StepRow
                  key={subWorksheet.id}
                  worksheet={subWorksheet}
                  parentWorksheet={worksheet}
                  isUpdating={sourceOfUpdate === subWorksheet.id}
                  isDisabled={!!isDisabled || (isUpdating && sourceOfUpdate !== subWorksheet.id)}
                  canIncrement={canInc}
                  canDecrement={canDec}
                  {...{ updateReleasedAt, experiment }}
                  updateRank={getEntityRankUpdater(subWorksheets, updateEntity)}
                  responses={responses.filter(r => r.worksheetId === subWorksheet.id)}
                />
              })
            ]
          })}
        </TBody>
      </Table>
     {isAdding && id
       ? <NewStepForm experimentId={id} onCancel={() => { setIsAdding(false) }} />
       : <StyledActionPointer
          label="Add another step"
          orientation="right"
          icon={<Icon content={BasicCross} color="back" frameStyle="circle" onClick={() => { setIsAdding(true) }} />}
        />}
    </>
  }

export default ProjectSteps
export { getEntityRankUpdater }
