import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'
import { EXPERIMENT_LOCATIONS } from './location'
import { PLANTING_FORMATS } from './plantingFormat'

const
  FERTILIZER_RATE_AREAS = {
    CONTAINER: 'per container',
    PLANT: 'per plant',
    BED: 'per bed',
    CTSQFT: 'per 100 square feet',
    PLOT: 'per plot'
  } as const,
  title = 'Fertilizer rate area',
  description = 'The area for which the corresponding fertilizer amount is specified (row, bed, a number of square feet, etc.)',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.fertilizerRateArea,
    fieldType: 'ENUM',
    // visibleWhen: ivEqualityCondition(variableName, exclude),
    options: [
      {
        optionValue: FERTILIZER_RATE_AREAS.CONTAINER,
        visibleWhen: [
          {
            valueName: SystemFieldNames.location,
            conditionType: 'equals',
            testValue: EXPERIMENT_LOCATIONS.CONTAINER
          }
        ]
      },
      {
        optionValue: FERTILIZER_RATE_AREAS.PLANT,
        visibleWhen: [
          {
            valueName: SystemFieldNames.plantingFormat,
            conditionType: 'anyOf',
            testValue: [
              PLANTING_FORMATS.INDIVIDUAL_GROUND,
              PLANTING_FORMATS.INDIVIDUAL_CONTAINER
            ]
          }
        ]
      },
      {
        optionValue: FERTILIZER_RATE_AREAS.BED,
        visibleWhen: [
          {
            valueName: SystemFieldNames.plantingFormat,
            conditionType: 'equals',
            testValue: PLANTING_FORMATS.INDIVIDUAL_GROUND
          }
        ]
      },
      {
        optionValue: FERTILIZER_RATE_AREAS.CTSQFT,
        visibleWhen: [
          {
            valueName: SystemFieldNames.plantingFormat,
            conditionType: 'equals',
            testValue: PLANTING_FORMATS.GROUP_GROUND
          }
        ]
      },
      {
        optionValue: FERTILIZER_RATE_AREAS.PLOT,
        visibleWhen: [
          {
            valueName: SystemFieldNames.plantingFormat,
            conditionType: 'equals',
            testValue: PLANTING_FORMATS.GROUP_GROUND
          }
        ]
      }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.fertilizerRateArea,
    variableGroup: SystemFieldGroups.CONTROL_VARIABLES,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails,
  FERTILIZER_RATE_AREAS
}
