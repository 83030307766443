import * as React from 'react'
import styled, { css, useTheme } from 'styled-components'
import { StreetSign, CheckmarkIcon, SizedLoadingIcon } from '~/components/atoms'
import { Link, type LinkProps } from 'react-router-dom'
import type { WorksheetStatusForStudent } from '~/features'
import { useClickOnKeyPress } from '~/hooks'
import { RectStreetSign } from '../atoms/Shapes/StreetSign'

interface CoreProps {
  label: string
  number: string
  withShadow?: boolean
  rectangleOnly?: boolean
  isLoading?: boolean
  stepStatus?: WorksheetStatusForStudent
  onClick?: React.MouseEventHandler<HTMLDivElement>
  className?: string
}

interface OnClickProps {
  onClick: React.MouseEventHandler<HTMLDivElement>
}

interface WithLinkProps {
  linkProps: LinkProps
}

type StepSignProps = CoreProps | (CoreProps & OnClickProps) | (CoreProps & WithLinkProps)

const
  emStepSignWidth = 8.4, // 126px
  emStepSignHeight = 4.36, // 65.4px
  outerStyles = css<{ $labelLength?: number }>`
    position: relative;
    height: ${emStepSignHeight}em;
    width: ${p => p.$labelLength ? p.$labelLength * 0.6 : emStepSignWidth}em; 
    font-size: 1rem; // 15px
    
    user-select: none;
  `,
  StepSignOuter = styled.div<{ $labelLength?: number }>`
     ${outerStyles}
     cursor: ${p => p.onClick ? 'pointer' : 'auto'};
  `,
  StepSignOuterLink = styled(Link)<{ $labelLength?: number }>`${outerStyles}`,
  StyledBackground = styled(StreetSign)`
    width: 100%;
    position: absolute;
  `,
  StyledRectBackground = styled(RectStreetSign)`
    width: 100%;
    height: 100%;
    position: absolute;
  `,
  Label = styled.div`
    position: absolute;
    top: 58.5%;
    left: 0;
    width: 100%;
    font-size: 0.8em; // 12px
    line-height: 1.2em; // enough space to show handing glyphs
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 ${p => p.theme.emBaseSpacing}em;
    text-align: center;
    
    color: ${p => p.theme.colors.bodyInverseText};
    ${p => p.theme.headingFont}
  `,
  Number = styled.div`
    position: absolute;
    top: 1%;
    left: 0;
    width: 100%;
    font-size: 1.66em; // 25px
    line-height: 1.66em;
    
    text-align: center;
    color: ${p => p.theme.colors.bodyInverseText};
    ${p => p.theme.headingFont}
  `,
  CompletionIcon = styled(CheckmarkIcon)`
    position: absolute;
    left: 19%;
    bottom: 63%;
    width: 31%; // These numbers all had to change because the checkmark icon has more padding than the placeholder
  `,
  SizedPositionedLoadingIcon = styled(SizedLoadingIcon)<{ $rectangleOnly?: boolean }>`
    position: absolute;
    top: ${p => p.$rectangleOnly ? '46%' : '10%'};
    left: 44%;
  `,
  colorByWorksheetStatus: Record<WorksheetStatusForStudent, 'closed' | 'open' | 'complete'> = {
    closed: 'closed',
    open: 'open',
    approved: 'complete',
    submitted: 'complete'
  },
  StepSign: React.FC<StepSignProps> = ({ label, number, stepStatus = 'closed', withShadow, rectangleOnly, isLoading, className, ...otherProps }) => {
    const
      THEME = useTheme(),
      elementRef = useClickOnKeyPress(),
      isClickable = 'linkProps' in otherProps || 'onClick' in otherProps,
      accessibleDescription = `${isClickable ? 'Go to ' : ''}${label} (${stepStatus})`,
      sharedProps = {
        className,
        title: accessibleDescription,
        'aria-label': accessibleDescription,
        'data-cy': 'step-sign',
        ...(rectangleOnly ? { $labelLength: label.length } : {})
      },
      BackgroundShape = rectangleOnly ? StyledRectBackground : StyledBackground,
      content = <>
        <BackgroundShape withShadow={withShadow} color={THEME.colors[colorByWorksheetStatus[stepStatus]]} borderColor={THEME.colors.borderSticker} />
        {stepStatus === 'approved' && !rectangleOnly
          ? <CompletionIcon color='success' degreesRotation={-8} />
          : null}
        {rectangleOnly && isLoading
          ? <SizedPositionedLoadingIcon color="inverse" $rectangleOnly />
          : <Label>
            {label}
          </Label>}
        {rectangleOnly
          ? null
          : isLoading
            ? <SizedPositionedLoadingIcon color="inverse" />
            : <Number>
              {number}
            </Number>}
      </>

    if ('linkProps' in otherProps) {
      return (
        <StepSignOuterLink
          {...sharedProps}
          {...otherProps.linkProps}>
          {content}
        </StepSignOuterLink>
      )
    } else {
      return (
        <StepSignOuter
          {...sharedProps}
          tabIndex={isClickable ? 0 : -1}
          ref={isClickable ? elementRef : undefined}
          onClick={'onClick' in otherProps ? otherProps.onClick : undefined}>
          {content}
        </StepSignOuter>
      )
    }
  }

export { emStepSignHeight, emStepSignWidth }
export default StepSign
