import * as React from 'react'
import { type BasicIconProps } from '~/types/utilities'

const
  BasicDiamond: React.FC<BasicIconProps> = ({ className, color = 'black', outlineColor }) => {
    return (
      <svg
        className={className}
        viewBox="0 0 41 41"
        xmlns="http://www.w3.org/2000/svg"
        data-cy="basic-diamond">
        <rect
          x="21"
          y="0.5"
          width="26"
          height="26"
          transform="rotate(45 19 0.1875)"
          fill={color}
          stroke={outlineColor}
          strokeWidth="2.4px"
          strokeLinejoin="round"
        />
      </svg>
    )
  }

export default BasicDiamond
