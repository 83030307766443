import type { EntitySchema } from '~/form-brain2'
import type { QuestionnaireFormat } from '~/features/questionnaire/types'

export const
  newStepSchema: EntitySchema = {
    basedOnType: {
      fieldName: 'basedOnType',
      fieldType: 'ENUM'
    },
    basedOnId: {
      fieldName: 'basedOnId',
      fieldType: 'STRING'
    },
    name: {
      fieldName: 'name',
      fieldType: 'STRING',
      requiredWhen: [{ conditionType: 'absent', valueName: 'basedOnType' }]
    },
    format: {
      fieldName: 'format',
      fieldType: 'ENUM',
      requiredWhen: [{ conditionType: 'absent', valueName: 'basedOnType' }]
    },
    specialLayoutType: {
      fieldName: 'specialLayoutType',
      fieldType: 'ENUM',
      visibleWhen: [{ conditionType: 'equals', valueName: 'format', testValue: 'LIST' as QuestionnaireFormat }]
    }
  },
  existingStepSchema: EntitySchema = {
    basedOnType: {
      fieldName: 'basedOnType',
      fieldType: 'ENUM'
    },
    basedOnId: {
      fieldName: 'basedOnId',
      fieldType: 'STRING',
      requiredWhen: [{ conditionType: 'present', valueName: 'basedOnType' }]
    },
    name: {
      fieldName: 'name',
      fieldType: 'STRING',
      requiredWhen: [{ conditionType: 'always' }]
    },
    format: {
      fieldName: 'format',
      fieldType: 'ENUM',
      requiredWhen: [{ conditionType: 'absent', valueName: 'basedOnType' }],
      readOnlyWhen: [{ conditionType: 'always' }]
    },
    specialLayoutType: {
      fieldName: 'specialLayoutType',
      fieldType: 'ENUM',
      visibleWhen: [
        { conditionType: 'equals', valueName: 'format', testValue: 'LIST' as QuestionnaireFormat },
        { conditionType: 'present', valueName: 'specialLayoutType' }
      ],
      readOnlyWhen: [{ conditionType: 'always' }]
    },
    parentWorksheetId: {
      fieldName: 'parentWorksheetId',
      fieldType: 'ENUM',
      visibleWhen: [{ conditionType: 'equals', valueName: 'type', testValue: 'worksheet' }]
    },
    parentTemplateId: {
      fieldName: 'parentTemplateId',
      fieldType: 'ENUM',
      visibleWhen: [{ conditionType: 'equals', valueName: 'type', testValue: 'template' }]
    },
    tiles: {
      fieldName: 'tiles',
      fieldType: 'STRING:ARRAY'
    },
    includeReviewStep: {
      fieldName: 'includeReviewStep',
      fieldType: 'BOOLEAN',
      visibleWhen: [{ conditionType: 'noneOf', valueName: 'format', testValue: ['HUB', 'DOWNLOADS'] as QuestionnaireFormat[] }]
    },
    reviewStepHintText: {
      fieldName: 'reviewStepHintText',
      fieldType: 'STRING',
      visibleWhen: [{ conditionType: 'truthy', valueName: 'includeReviewStep' }]
    },
    reviewStepMascotVariant: {
      fieldName: 'reviewStepMascotVariant',
      fieldType: 'ENUM',
      visibleWhen: [
        { conditionType: 'truthy', valueName: 'includeReviewStep' },
        { conditionType: 'present', valueName: 'reviewStepHintText' },
        { conditionType: 'wordLengthAtLeast', valueName: 'reviewStepHintText', testValue: 0 }
      ]
    },
    skipSubmission: {
      fieldName: 'skipSubmission',
      fieldType: 'BOOLEAN',
      visibleWhen: [{ conditionType: 'noneOf', valueName: 'format', testValue: ['HUB', 'DOWNLOADS'] as QuestionnaireFormat[] }]
    },
    responsePageHintText: {
      fieldName: 'responsePageHintText',
      fieldType: 'STRING',
      visibleWhen: [{ conditionType: 'falsy', valueName: 'skipSubmission' }]
    },
    responsePageMascotVariant: {
      fieldName: 'responsePageMascotVariant',
      fieldType: 'ENUM',
      visibleWhen: [
        { conditionType: 'falsy', valueName: 'skipSubmission' },
        { conditionType: 'present', valueName: 'responsePageHintText' },
        { conditionType: 'wordLengthAtLeast', valueName: 'responsePageHintText', testValue: 0 }
      ]
    },
    homePageText: {
      fieldName: 'homePageText',
      fieldType: 'STRING'
    }
    // testmode?: boolean - TODO find a way to show test vs live
  }
