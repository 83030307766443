import * as React from 'react'
import styled from 'styled-components'
import { omitBy } from 'lodash'
import { isAllEmpty } from '~/utils/testers'

import type { EntityErrors } from '~/form-brain2'

interface ErrorListProps {
  errors: EntityErrors
  maxLength: number
  className?: string
}

const
  DList = styled.dl`
    margin: ${p => p.theme.emSmallSpacing}em 0 0 ${p => p.theme.emBaseSpacing}em;
  `,
  ListItem = styled.div`
    display: flex;
    /*font-size: 0.85em;*/
    margin-bottom: ${p => p.theme.emSmallSpacing / 2}em;
  `,
  Term = styled.dt`
    margin-right: ${p => p.theme.emSmallSpacing / 3}em;
  `,
  Definition = styled.dd`
    margin-left: 0;
  `,
  OverflowMessage = styled.div``,
  ErrorList: React.FC<ErrorListProps> = ({ errors, maxLength, className }: ErrorListProps) => {
    const
      nonEmptyErrors = omitBy(errors, v => isAllEmpty(v)),
      errorKeys = Object.keys(nonEmptyErrors),
      keysToShow = errorKeys.length > maxLength
        ? errorKeys.slice(0, maxLength)
        : errorKeys,
      overflowCount = errorKeys.length - maxLength

    return <DList className={className}>
      {keysToShow.map(k => <ListItem key={k}>
        {k === 'base' // TODO what to do about __base errors?
          ? null
          : <Term>{k}</Term>}
        <Definition>
          {Array.isArray(errors[k])
            ? (errors[k] as string[]).join(', ')
            : <ErrorList errors={(errors[k] as EntityErrors)} maxLength={maxLength} />}
        </Definition>
      </ListItem>)}
      {overflowCount > 0
        ? <OverflowMessage>...and {overflowCount} more.</OverflowMessage>
        : null}
    </DList>
  }

export default ErrorList
