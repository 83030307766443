import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { captureMessage } from '@sentry/react'
import { Form } from '~/form-brain2'
import { selectExperimentById } from '~/features/entity/selectors'
import {
  newStepFormBody,
  defaultListWorksheetValues,
  defaultPagedWorksheetValues,
  defaultHubOrDownloadsWorksheetValues,
  newStepSchema
} from '~/features/worksheet/formHelpers'
import { useAddEntity, useAppSelector, useFormVitalsSubscription, useStepFormBody } from '~/hooks'
import { experimentFormResolvers } from '~/features'
import { Button, DashedLine, FormLabel } from '~/components/atoms'
import { FormRenderer } from '~/components/molecules'

import type { FormOnSubmitFn } from '~/form-brain2'
import type { SpecialLayoutType, Worksheet } from '~/features'
import type { ObjectId } from '~/types/utilities'
import type { EntityObject, FormOnSuccessFn } from '~/form-brain2/types'
import { useNavigate } from 'react-router-dom'

interface NewStepFormProps {
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void
  experimentId: ObjectId
}

const
  formName = 'new-step-form',
  NewStepFormOuter = styled.div`
    margin-bottom: ${p => p.theme.emBaseSpacing}em;
  `,
  FormActions = styled.div`
    display: flex;
    flex-direction: row;

    > :first-child {
      margin-right: auto;
    }
  `,
  HR = styled(DashedLine)`
    margin: ${p => p.theme.emBaseSpacing * 2}em 0;
  `,
  getDefaultEntity = (experimentId: ObjectId): Partial<Worksheet> => ({
    experimentId
  }),
  NewStepForm: React.FC<NewStepFormProps> = ({ onCancel, experimentId }) => {
    const
      THEME = useTheme(),
      navigate = useNavigate(),
      { addEntity } = useAddEntity(),
      { formStatus, subscribeToFormStatus } = useFormVitalsSubscription(),
      { formBody } = useStepFormBody(newStepFormBody, experimentId),
      experiment = useAppSelector(selectExperimentById(experimentId)),
      onSubmit: FormOnSubmitFn = ({ values }) => {
        if (!experiment) {
          captureMessage('Attempted to create a new step without an experiment available')
          return Promise.reject(new Error('There was an unexpected error, Please reload and try again'))
        }
        return addEntity({
          type: 'worksheet',
          values: values.format === 'LIST'
            ? { ...values, ...defaultListWorksheetValues(experiment, values.specialLayoutType as SpecialLayoutType | undefined) }
            : values.format === 'PAGED'
              ? { ...values, ...defaultPagedWorksheetValues(experiment) }
              : values.format === 'DOWNLOADS' || values.format === 'HUB'
                ? { ...values, ...defaultHubOrDownloadsWorksheetValues }
                : values
        })
      },
      onSuccess: FormOnSuccessFn = (result) => {
        const newStep = result.payload as Worksheet | undefined

        if (newStep) {
          navigate(`/steps/${newStep.id}`)
        }
      }

    return <NewStepFormOuter>
      <HR orientation="horizontal" color={THEME.colors.borderAccent} />
      <FormLabel
        label="New Step"
        showLabel
        asHeading
      />
      <Form
        formId={formName}
        formBody={formBody}
        entitySchema={newStepSchema}
        initialValues={getDefaultEntity(experimentId) as unknown as EntityObject}
        onSubmitCallback={onSubmit}
        onSuccessCallback={onSuccess}
        FormRenderer={FormRenderer}
        subscribeToFormStatus={subscribeToFormStatus}
        formOptions={{ RootFormRenderer: FormRenderer }}
        {...experimentFormResolvers}
      />
      <FormActions>
        <Button onClick={onCancel} label="Cancel" color="back" size="small" />
        {!formStatus || ['clean', 'cleanAfterSuccess'].includes(formStatus)
          ? null
          : <Button
            type="submit"
            isLoading={formStatus === 'processing'}
            form={formName}
            label="Continue"
            color="forward"
            size="small"
          />}
      </FormActions>
    </NewStepFormOuter>
  }

export default NewStepForm
