import * as React from 'react'
import styled from 'styled-components'
import { ExperimentHelpers, WorksheetHelpers } from '~/features'
import { useLearnerStepContext, useWindowWidth } from '~/hooks'
import StackingSections, { RelativeWrapper } from './StackingSections'
import SpecialListStepLayout, { SUPPORTED_LAYOUTS } from './SpecialLayouts/SpecialListStepLayout'
import { getEntrySummaries } from './helpers'
import { getActiveWalkthroughDetails } from '~/features/worksheetResponse/helpers'
import { CheckmarkIcon } from '~/components/atoms'
import { Blurb } from '~/components/molecules'
import ListStepEntryList from './ListStepEntryList'
import SecondSection from './SecondSection'

import type { ListEntry, ListSectionElement, ListResponse, ListWorksheet } from '~/features'
import type { ListStepSelection } from './types'

const
  BottomBlurb = styled(Blurb)`
    margin-top: auto;
    min-height: unset;
  `,
  stackingCutoffWidth = 775,
  getHintSource = ({ sections, entries, currentSelection }: {
    sections: ListSectionElement[]
    entries: ListEntry[]
    currentSelection: ListStepSelection
  }): ListSectionElement | undefined => {
    if (!currentSelection) { return undefined }

    const isExistingEntry = 'entryIndex' in currentSelection

    if (isExistingEntry) {
      const entry = entries[currentSelection.entryIndex ?? -1]

      return sections.find(o => o.id === entry?.sectionId)
    }

    const relevantSection = currentSelection.segment === 'LIST_OPENER_SECTION'
      ? sections.find(o => o.elementVariety === 'LIST_OPENER_SECTION')
      : currentSelection.segment === 'LIST_CLOSER_SECTION'
        ? sections.find(o => o.elementVariety === 'LIST_CLOSER_SECTION')
        : undefined

    return relevantSection
  },
  getInitialSelection = ({ entries, worksheet, response }: { entries: ListEntry[], worksheet: ListWorksheet, response: ListResponse }): ListStepSelection => {
    if (entries.length === 0 && worksheet.openingSection) {
      return { segment: 'LIST_OPENER_SECTION' }
    }

    const { wtSection, wtEntry } = getActiveWalkthroughDetails(worksheet, response)

    if (wtEntry) {
      return { entryIndex: entries.indexOf(wtEntry) }
    }

    if (wtSection) {
      return { segment: wtSection.elementVariety }
    }

    return undefined
  },
  ListStep: React.FC = () => {
    const
      windowWidth = useWindowWidth(),
      stepContext = useLearnerStepContext(),
      { setMascotState, experiment } = stepContext,
      worksheet = stepContext.worksheet as ListWorksheet,
      sections = WorksheetHelpers.getSectionList(worksheet),
      entries = (stepContext.response.responses.entries as ListEntry[] | undefined) ?? [],
      canStackSections = !windowWidth || (windowWidth < stackingCutoffWidth),
      [_currentSelection, setCurrentSelection] = React.useState<ListStepSelection>(
        getInitialSelection({ entries, worksheet, response: stepContext.response as ListResponse })
      ),
      entrySummaries = getEntrySummaries({ currentSelection: _currentSelection, entries, worksheet }),
      currentSelection = _currentSelection && 'entryIndex' in _currentSelection && !entrySummaries[_currentSelection.entryIndex]
        ? undefined
        : _currentSelection,
      { wtStep } = getActiveWalkthroughDetails(worksheet, stepContext.response),
      hintSource = getHintSource({ sections, entries, currentSelection })

    React.useEffect(() => {
      if (hintSource?.pageHint) {
        setMascotState(state => ({
          mascotVariant: hintSource.mascotVariant,
          hintText: hintSource.pageHint
        }))
      } else if (!currentSelection) {
        setMascotState(state => ({
          ...state,
          mascotVariant: worksheet.mascotVariant ?? 'pointing',
          hintText: worksheet.pageHint ?? 'You can click on any row to open it up!'
        }))
      } else {
        setMascotState({})
      }
    }, [hintSource, setMascotState, currentSelection, worksheet])

    React.useEffect(() => {
      if (currentSelection !== _currentSelection) {
        setCurrentSelection(undefined)
      }
    }, [currentSelection, _currentSelection])

    React.useEffect(() => {
      if (wtStep) {
        const wtSelection = getInitialSelection({ entries, worksheet, response: stepContext.response as ListResponse })

        if (wtSelection !== _currentSelection) {
          setCurrentSelection(wtSelection)
        }
      }
    /** We want this to change only when the walkthrough step changes,
     * so that it sets the current selection to the one that matches
     * the new step, if needed. There is no other situation where
     * this action should be taken. */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wtStep])

    return <RelativeWrapper>
      {worksheet.specialLayoutType && SUPPORTED_LAYOUTS.includes(worksheet.specialLayoutType)
        ? <SpecialListStepLayout
            {...{
              entrySummaries,
              entries,
              worksheet,
              canStackSections,
              windowWidth,
              currentSelection,
              setCurrentSelection,
              specialLayoutType: worksheet.specialLayoutType
            }}
          />
        : <StackingSections
          canStackSections={canStackSections}
          firstSection={<ListStepEntryList {...{ currentSelection, setCurrentSelection, entrySummaries }} />}
          secondSection={<SecondSection {...{ currentSelection, setCurrentSelection, entrySummaries }} />}
          soleSection={<ListStepEntryList withInlineForm {...{ currentSelection, setCurrentSelection, entrySummaries }} />}
        />}
      {worksheet.skipSubmission
        ? <BottomBlurb
          content={ExperimentHelpers.getDynamicString(experiment, 'nothingToTurnIn')}
          icon={<CheckmarkIcon />}
          withBackground
        />
        : null}
    </RelativeWrapper>
  }

export default ListStep
