import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Timing of first or only measurement',
  description = 'This allows learners to specify when they will start taking measurements. This is used to build a schedule for the experiment and to calculate information like the total number of measurements that will be taken during the experiment.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.measurementStartValue,
    fieldType: 'NUMBER'
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.measurementStartValue,
    variableGroup: SystemFieldGroups.SCHEDULE,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
