import { fieldDetails as dependentVariableCategory } from './dependentVariableCategory'
import { fieldDetails as dependentVariable } from './dependentVariable'
import { fieldDetails as independentVariableCategory } from './independentVariableCategory'
import { fieldDetails as independentVariable } from './independentVariable'
import { fieldDetails as subject } from './subject'
import { fieldDetails as location } from './location'
import { fieldDetails as containerType } from './containerType'
import { fieldDetails as plantingFormat } from './plantingFormat'
import { fieldDetails as seedsPerPlant } from './seedsPerPlant'
import { fieldDetails as replicateCount } from './replicateCount'
import { fieldDetails as blindnessMethod } from './blindnessMethod'
import { fieldDetails as randomizationMethod } from './randomizationMethod'
import { fieldDetails as measurementType } from './measurementType'
import { fieldDetails as measurementTarget } from './measurementTarget'
import { fieldDetails as measurementUnit } from './measurementUnit'
import { fieldDetails as measurementStartValue } from './measurementStartValue'
import { fieldDetails as measurementStartUnit } from './measurementStartUnit'
import { fieldDetails as measurementTimingScheme } from './measurementTimingScheme'
import { fieldDetails as measurementRepeatValue } from './measurementRepeatValue'
import { fieldDetails as measurementRepeatUnit } from './measurementRepeatUnit'
import { fieldDetails as measurementEndValue } from './measurementEndValue'
import { fieldDetails as measurementEndUnit } from './measurementEndUnit'
import { fieldDetails as measurementRelationship } from './measurementRelationship'
import { fieldDetails as germinationDay } from './germinationDay'
import { fieldDetails as thinningDay } from './thinningDay'
import { fieldDetails as harvestDay } from './harvestDay'
import { fieldDetails as endDay } from './endDay'
import { fieldDetails as fertilizerRateValue } from './fertilizerRateValue'
import { fieldDetails as fertilizerRateUnit } from './fertilizerRateUnit'
import { fieldDetails as fertilizerRateArea } from './fertilizerRateArea'
import { fieldDetails as fertilizerTimingReference } from './fertilizerTimingReference'
import { fieldDetails as fertilizerTimingValue } from './fertilizerTimingValue'
import { fieldDetails as fertilizerTimingUnit } from './fertilizerTimingUnit'
import { fieldDetails as fertilizerName } from './fertilizerName'
import { fieldDetails as irrigationScheme } from './irrigationScheme'
import { fieldDetails as irrigationRateValue } from './irrigationRateValue'
import { fieldDetails as irrigationRateUnit } from './irrigationRateUnit'
import { fieldDetails as irrigationTimingValue } from './irrigationTimingValue'
import { fieldDetails as irrigationTimingUnit } from './irrigationTimingUnit'
import { fieldDetails as irrigationTimingCondition } from './irrigationTimingCondition'
import { fieldDetails as irrigationMethodName } from './irrigationMethodName'
import { fieldDetails as plantSpacingValue } from './plantSpacingValue'
import { fieldDetails as plantSpacingUnit } from './plantSpacingUnit'
import { fieldDetails as plantSpacingArea } from './plantSpacingArea'
import { fieldDetails as seedVariety } from './seedVariety'
import { fieldDetails as customVariableTreatmentLabel } from './customVariableTreatmentLabel'

import { fieldDetails as fertilizerRateControlGroup } from './fertilizerRateControlGroup'
import { fieldDetails as fertilizerTimingControlGroup } from './fertilizerTimingControlGroup'
import { fieldDetails as fertilizerTypeControlGroup } from './fertilizerTypeControlGroup'
import { fieldDetails as irrigationRateControlGroup } from './irrigationRateControlGroup'
import { fieldDetails as irrigationTimingControlGroup } from './irrigationTimingControlGroup'
import { fieldDetails as irrigationTypeControlGroup } from './irrigationTypeControlGroup'
import { fieldDetails as plantingDensityControlGroup } from './plantingDensityControlGroup'
import { fieldDetails as seedVarietyControlGroup } from './seedVarietyControlGroup'
import { fieldDetails as customVariableControlGroup } from './customVariableControlGroup'

import { fieldDetails as fertilizerRateTreatmentGroups } from './fertilizerRateTreatmentGroups'
import { fieldDetails as fertilizerTimingTreatmentGroups } from './fertilizerTimingTreatmentGroups'
import { fieldDetails as fertilizerTypeTreatmentGroups } from './fertilizerTypeTreatmentGroups'
import { fieldDetails as irrigationRateTreatmentGroups } from './irrigationRateTreatmentGroups'
import { fieldDetails as irrigationTimingTreatmentGroups } from './irrigationTimingTreatmentGroups'
import { fieldDetails as irrigationTypeTreatmentGroups } from './irrigationTypeTreatmentGroups'
import { fieldDetails as plantingDensityTreatmentGroups } from './plantingDensityTreatmentGroups'
import { fieldDetails as seedVarietyTreatmentGroups } from './seedVarietyTreatmentGroups'
import { fieldDetails as customVariableTreatmentGroups } from './customVariableTreatmentGroups'

import type { SystemFieldDetails, SystemFieldName } from './systemFieldConstants'

const
  SYSTEM_FIELDS: Record<SystemFieldName, SystemFieldDetails> = {
    // Question
    dependentVariableCategory,
    dependentVariable,
    independentVariableCategory,
    independentVariable,

    // What and Where
    subject,
    location,
    containerType,
    plantingFormat,
    seedsPerPlant,

    // Experiment design
    replicateCount,
    blindnessMethod,
    randomizationMethod,
    measurementType,
    measurementTarget,
    measurementUnit,
    measurementTimingScheme,

    // Schedule
    measurementStartValue,
    measurementStartUnit,
    measurementRepeatValue,
    measurementRepeatUnit,
    measurementEndValue,
    measurementEndUnit,
    measurementRelationship,
    germinationDay,
    thinningDay,
    harvestDay,
    endDay,

    // Cultivation
    fertilizerRateValue,
    fertilizerRateUnit,
    fertilizerRateArea,
    fertilizerTimingReference,
    fertilizerTimingValue,
    fertilizerTimingUnit,
    fertilizerName,
    irrigationScheme,
    irrigationRateValue,
    irrigationRateUnit,
    irrigationTimingValue,
    irrigationTimingUnit,
    irrigationTimingCondition,
    irrigationMethodName,
    plantSpacingValue,
    plantSpacingUnit,
    plantSpacingArea,
    seedVariety,
    customVariableTreatmentLabel,

    // Controls and independent variables
    fertilizerRateControlGroup,
    fertilizerTimingControlGroup,
    fertilizerTypeControlGroup,
    irrigationRateControlGroup,
    irrigationTimingControlGroup,
    irrigationTypeControlGroup,
    plantingDensityControlGroup,
    seedVarietyControlGroup,
    customVariableControlGroup,

    fertilizerRateTreatmentGroups,
    fertilizerTimingTreatmentGroups,
    fertilizerTypeTreatmentGroups,
    irrigationRateTreatmentGroups,
    irrigationTimingTreatmentGroups,
    irrigationTypeTreatmentGroups,
    plantingDensityTreatmentGroups,
    seedVarietyTreatmentGroups,
    customVariableTreatmentGroups
  }

interface NonRepeatingMeasurementVariables {
  measurementType: string
  measurementTarget?: string
  measurementUnit?: string
  measurementStartValue?: number
  measurementStartUnit?: string
  measurementTimingScheme?: 'once'
  // Remaining values only for repeating timing scheme
  measurementRepeatValue?: undefined
  measurementRepeatUnit?: undefined
  measurementEndValue?: undefined
  measurementEndUnit?: undefined
  measurementRelationship?: undefined
}

interface RepeatingMeasurementVariables {
  measurementType: string
  measurementTarget?: string
  measurementUnit?: string
  measurementStartValue?: number
  measurementStartUnit?: string
  measurementTimingScheme?: 'repeating'
  measurementRepeatValue?: number
  measurementRepeatUnit?: string
  measurementEndValue?: number
  measurementEndUnit?: string
  measurementRelationship?: string
}

export type MeasurementVariables = NonRepeatingMeasurementVariables | RepeatingMeasurementVariables

export type { BuiltInIndependentVariable, TreatmentKey, ControlTreatmentKey } from './systemFieldConstants'
export type { FertilizerRateTreatmentFormat } from './fertilizerRateControlGroup'
export type { FertilizerTimingTreatmentFormat } from './fertilizerTimingControlGroup'
export type { FertilizerTypeTreatmentFormat } from './fertilizerTypeControlGroup'
export type { IrrigationRateTreatmentFormat } from './irrigationRateControlGroup'
export type { IrrigationTimingTreatmentFormat } from './irrigationTimingControlGroup'
export type { IrrigationTypeTreatmentFormat } from './irrigationTypeControlGroup'
export type { PlantingDensityTreatmentFormat } from './plantingDensityControlGroup'
export type { SeedVarietyTreatmentFormat } from './seedVarietyControlGroup'
export type { CustomVariableTreatmentFormat } from './customVariableControlGroup'

export { SystemFieldNames, INDEPENDENT_VARIABLES, TREATMENT_KEYS, CONTROL_TREATMENT_KEYS, SYSTEM_FIELD_KEYS } from './systemFieldConstants'
export { DependentVariableCategories, type DependentVariableCategory } from './dependentVariableCategory'
export { IndependentVariableCategories, type IndependentVariableCategory } from './independentVariableCategory'
export { PLANTING_FORMATS } from './plantingFormat'
export { EXPERIMENT_LOCATIONS } from './location'
export { SCHEDULED_IRRIGATION_TIMING_CONDITION_MATCH } from './irrigationTimingCondition'
export { RANDOMIZATION_METHODS, type RandomizationMethod } from './randomizationMethod'
export { MEASUREMENT_START_UNITS } from './measurementStartUnit'
export { MEASUREMENT_TIMING_SCHEMES, type MeasurementTimingScheme } from './measurementTimingScheme'
export { MEASUREMENT_REPEAT_AFTER_WATERING } from './measurementRepeatUnit'
export { FERTILIZER_RATE_AREAS } from './fertilizerRateArea'
export { FERTILIZER_TIMING_REFERENCES } from './fertilizerTimingReference'
export { IRRIGATION_SCHEMES } from './irrigationScheme'

export type {
  SystemFieldDetails,
  SystemFieldName
}

export {
  SYSTEM_FIELDS
}

/** ADDITIONAL VARIABLES FOR A FUTURE VERSION

  SEED_TREATMENT: 'seed treatment',
      option: ['seed', INDEOENDENT_VARIABLES.SEED_TREATMENT, 'treatment'],

      treatmentFormat: [
        {
          fieldName: SystemFieldNames.seedTreatmentLabel,
          fieldType: 'STRING',
          visibleWhen: [{ valueName: SystemFieldNames.independentVariable, conditionType: 'notEquals', testValue: INDEOENDENT_VARIABLES.SEED_TREATMENT }]
        },
        {
          fieldName: SystemFieldNames.seedTreatmentValue,
          fieldType: 'NUMBER',
          visibleWhen: [{ valueName: SystemFieldNames.independentVariable, conditionType: 'notEquals', testValue: INDEOENDENT_VARIABLES.SEED_TREATMENT }]
        },
        {
          fieldName: SystemFieldNames.seedTreatmentUnit,
          fieldType: 'STRING', // days, hours, oz
          visibleWhen: [{ valueName: SystemFieldNames.independentVariable, conditionType: 'notEquals', testValue: INDEOENDENT_VARIABLES.SEED_TREATMENT }]
        },
      ],

      ...getTreatmentFormat(INDEOENDENT_VARIABLES.SEED_TREATMENT, true),

  PLANTING_DEPTH: 'planting depth',
      option: ['planting', INDEOENDENT_VARIABLES.PLANTING_DEPTH, 'depth'],

      treatmentFormat: [
        {
          fieldName: SystemFieldNames.seedDepth,
          fieldType: 'NUMBER',
          visibleWhen: [{ valueName: SystemFieldNames.independentVariable, conditionType: exclude ? 'notEquals' : 'equals', testValue: INDEOENDENT_VARIABLES.SEED_DEPTH }]
        },
        {
          fieldName: SystemFieldNames.seedDepthUnit,
          fieldType: 'STRING',
          visibleWhen: [{ valueName: SystemFieldNames.independentVariable, conditionType: exclude ? 'notEquals' : 'equals', testValue: INDEOENDENT_VARIABLES.SEED_DEPTH }]
        }
      ],

  PLANTING_TIMING: 'planting timing',
      option: ['planting', INDEOENDENT_VARIABLES.PLANTING_TIMING, 'timing'],

      treatmentFormat: [
        {
          fieldName: SystemFieldNames.plantingTiming,
          fieldType: 'ENUM',
          visibleWhen: [{ valueName: SystemFieldNames.independentVariable, conditionType: exclude ? 'notEquals' : 'equals', testValue: INDEOENDENT_VARIABLES.PLANTING_TIMING }]
        }
      ],
 */
