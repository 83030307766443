import React from 'react'
import styled from 'styled-components'
import { Tab } from '~/components/atoms'
import { TabGroup } from '~/components/molecules'
import ListSectionPreview from './ListSectionPreview'

import type { Experiment, ListResponse, ListSectionElement, ListWorksheet, PagedWorksheet, WorksheetResponse } from '~/features'
import ListSectionSettingsForm from './ListSectionSettingsForm'
import EditorKey from '../EditorKey'
import ListSectionBodyForm from './ListSectionBodyForm'

interface Props {
  experiment: Experiment
  allWorksheets: Array<ListWorksheet | PagedWorksheet>
  worksheet: ListWorksheet
  updateWorksheet: (updatedWorksheet: ListWorksheet) => void
  section: ListSectionElement
  response: ListResponse
  otherResponses: WorksheetResponse[]
  updateResponse: (updatedResponse: WorksheetResponse) => void
}

type ListEditorTabName = 'list-section-preview' | 'list-section-body' | 'list-section-settings'

const
  OuterWrapper = styled.div`
    flex: 1 1 0px;
  `,
  Wrapper = styled.div`
    padding: ${p => p.theme.emSmallSpacing}em;
    border: 2px solid ${p => p.theme.colors.containerAccentBg};
  `,
  ListSectionEditor: React.FC<Props> = ({ experiment, worksheet, allWorksheets, response, otherResponses, section, updateResponse, updateWorksheet }) => {
    const [activeTab, setActiveTab] = React.useState<ListEditorTabName>('list-section-preview')

    return <>
      <OuterWrapper data-cy="list-section-editor">
        <TabGroup>
          <Tab
            label="Preview"
            tabRole={activeTab === 'list-section-preview' ? 'active' : 'inactive'}
            onClick={() => { setActiveTab('list-section-preview') }}
          />
          <Tab
            label="Content"
            tabRole={activeTab === 'list-section-body' ? 'active' : 'inactive'}
            onClick={() => { setActiveTab('list-section-body') }}
          />
          <Tab
            label="Settings"
            tabRole={activeTab === 'list-section-settings' ? 'active' : 'inactive'}
            onClick={() => { setActiveTab('list-section-settings') }}
          />
        </TabGroup>
        <Wrapper>
          {activeTab === 'list-section-body'
            ? <ListSectionBodyForm {...{ experiment, worksheet, section, updateWorksheet, allWorksheets }} />
            : activeTab === 'list-section-settings'
              ? <ListSectionSettingsForm {...{ experiment, worksheet, section, updateWorksheet, allWorksheets }} />
              : <ListSectionPreview {...{ experiment, worksheet, response, otherResponses, section, updateResponse }} />}
        </Wrapper>
        <EditorKey />
      </OuterWrapper>
    </>
  }

export default ListSectionEditor
