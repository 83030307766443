import React from 'react'
import styled from 'styled-components'
import PageHint, { type MascotVariant } from '~/components/molecules/PageHint'
import type { SpecialElement } from '~/features'
import type { EntityObject } from '~/form-brain2'
import { isEmptyValue } from '~/utils/testers'

interface Props {
  bodyElement: SpecialElement
  values: EntityObject
}

const
  pxMascotHeight = 170,
  NaturalMascot = styled(PageHint)<{ $positionBelow?: boolean }>`
    z-index: 0;
    position: static;
    height: ${pxMascotHeight}px;
    width: 90%;
    // min-width: 290px;
    align-self: flex-end;
    margin: 30px 0px 20px 0px;

    ${p => p.$positionBelow
      ? ''
      : `
        @media(min-width: 650px) {
          margin-top: -25px;
        }
      `
    }
  `,
  MascotPreview: React.FC<Props> = ({ bodyElement, values }) => {
    const
      textKey = bodyElement.textKey,
      variantKey = bodyElement.variantKey,
      text = textKey ? values[textKey] as string : undefined,
      variant = variantKey ? values[variantKey] as MascotVariant : undefined

    if (!text || isEmptyValue(text) || !variant) { return null }

    return <NaturalMascot content={text} mascotVariant={variant} openByDefault $positionBelow={!!bodyElement.positionBelow} />
  }

export default MascotPreview
