import styled from 'styled-components'

const
  Main = styled.main`
    position: relative;
    width: 100%;
    
    border-radius: ${p => p.theme.pxBorderRadius * 3.5}px; // 25px
    padding: ${p => p.theme.emBaseSpacing * 1.25}em; // 17px

    background: ${p => p.theme.colors.bodyBg};
  `

export default Main
