import * as React from 'react'
import { fromPairs, isEmpty, sortBy, values } from 'lodash'
import { useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { WorksheetHelpers, WorksheetResponseHelpers } from '~/features'
import { selectExperimentById, selectLearners, selectWorksheetResponses, selectWorksheets } from '~/features/entity/selectors'
import { useAppSelector } from '~/hooks'
import { FauxSelect } from '~/components/atoms'
import { EmptyContentPlaceholder, ResponseRow, SavePoint } from '~/components/molecules'
import { RelativeWrapper } from '~/components/organisms/ListStep/StackingSections'
import { getDownloadButton } from '~/components/organisms/DownloadsStep/DownloadsTable'
import getDownloadOptions from '~/components/organisms/DownloadsStep/helpers/getDownloadOptions'
import getContentForSelectedDownloadOptions from '~/components/organisms/DownloadsStep/helpers/getContentForSelectedDownloadOptions'

import type { Experiment, Worksheet, WorksheetResponseStatus } from '~/features'
import type { FullOptionList } from '~/form-brain2'
import { isAllEmpty } from '~/form-brain2/testers'

const
  ResponsesOuter = styled.div`
    background: ${p => p.theme.colors.bodyBg};
    align-self: stretch;
    padding: 1em ${p => p.theme.emSmallSpacing}em;
  `,
  P = styled.p`
    margin-top: 20px;
  `,
  Filters = styled.div`
    width: auto;
    margin-bottom: 1.6em;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${p => p.theme.emSmallSpacing}em;
    align-items: center;
    padding: ${p => p.theme.emSmallSpacing}em ${p => p.theme.emBaseSpacing}em;
    background: ${p => p.theme.colors.containerBg};
    box-shadow: 1px 1.5px 4px 0px ${p => p.theme.colors.shadow};
  `,
  Label = styled.div`
    font-size: 0.9rem;
  `,
  ProjectResponses: React.FC = () => {
    const
      { id } = useParams(),
      [searchParams] = useSearchParams(),
      initialLearnerFilter = searchParams.get('learnerId') ?? undefined,
      [selectedFilters, setSelectedFilters] = React.useState<{ learnerId?: string, status?: string, worksheetId?: string }>({ learnerId: initialLearnerFilter }),
      responsesById = useAppSelector(selectWorksheetResponses),
      responses = values(responsesById).filter(o => o.experimentId === id),
      learnersById = useAppSelector(selectLearners),
      learners = values(learnersById).filter(o => o.experimentId === id),
      worksheetsById = useAppSelector(selectWorksheets),
      worksheets = values(worksheetsById).filter(o => o.experimentId === id),
      experiment = useAppSelector(selectExperimentById(id)),
      sortedResponses = sortBy(
        responses,
        [
          o => worksheetsById[o.worksheetId]?.rank,
          o => o.learnerId ? learnersById[o.learnerId]?.displayId : ''
        ]
      ).filter(o => {
        const worksheet = worksheets?.find(worksheet => worksheet.id === o.worksheetId)

        return worksheet
      })

    if (isEmpty(responses)) {
      return <ResponsesOuter>
        <EmptyContentPlaceholder>
          <P>{'There\'s nothing here yet!'}</P>
          <P>{'Once the group starts working on the project, you will be able to see their in-progress responses.'}</P>
        </EmptyContentPlaceholder>
      </ResponsesOuter>
    }

    const
      learnerOptions: FullOptionList = [
        { value: undefined, label: 'Author: Any' },
        { value: '__group', label: 'Author: Whole Group' },
        ...sortBy(learners.map(o => ({
          value: o.id,
          label: `Author: ${o.displayId}`,
          disabled: !responses.find(r => r.learnerId === o.id)
        })), 'label')
      ],
      responseStatusesById = fromPairs(responses.map(response => {
        const
          worksheet = worksheetsById[response.worksheetId],
          relevantResponses = sortedResponses.filter(r => r.learnerId ? r.learnerId === response.learnerId : true)

        return [response.id, WorksheetHelpers.getResponseStatusForWorksheet(worksheet, worksheets, relevantResponses)]
      })),
      presentStatuses = Object.values(responseStatusesById),
      statusOptions: FullOptionList = [
        { value: undefined, label: 'Status: Any' },
        ...sortBy(Object.keys(WorksheetResponseHelpers.statusLabelByTeacherStatus).map(k => ({
          value: k,
          label: `Status: ${WorksheetResponseHelpers.statusLabelByTeacherStatus[k as WorksheetResponseStatus]}`,
          disabled: !presentStatuses.includes(k as WorksheetResponseStatus)
        })), 'label')
      ],
      sortedTopLevelWorksheets = WorksheetHelpers.getSortedTopLevelWorksheets(worksheets),
      getWorksheetOptions = (worksheet: Worksheet): FullOptionList => [
        ...(worksheet.format === 'HUB' || worksheet.format === 'DOWNLOADS'
          ? []
          : [{
              value: worksheet.id,
              label: `Worksheet: ${worksheet.name}`,
              disabled: !responses.find(r => r.worksheetId === worksheet.id)
            }]),
        ...(worksheet.format === 'HUB' ? WorksheetHelpers.getSortedWorksheetsForHubStep(worksheet.id, worksheets).flatMap(subWorksheet => getWorksheetOptions(subWorksheet)) : [])
      ],
      worksheetOptions: FullOptionList = [
        { value: undefined, label: 'Worksheet: Any' },
        ...sortedTopLevelWorksheets.flatMap(o => getWorksheetOptions(o))
      ],
      filteredResponses = sortedResponses.filter(response => {
        if (selectedFilters.learnerId === '__group' && response.learnerId) { return false }
        if (selectedFilters.learnerId && selectedFilters.learnerId !== '__group' && (selectedFilters.learnerId !== response.learnerId)) { return false }
        if (selectedFilters.status && (selectedFilters.status !== responseStatusesById[response.id])) { return false }
        if (selectedFilters.worksheetId && (selectedFilters.worksheetId !== response.worksheetId)) { return false }
        return true
      }),
      downloadButtonLabel = `Download${isAllEmpty(selectedFilters) ? '' : ' Selected'} Responses & Grades`,
      availableDownloads = getDownloadOptions(worksheets, filteredResponses),
      fauxSelectedDownloadOptions = fromPairs(availableDownloads.map(o => [o.id, o.formats]))

    return <ResponsesOuter>
      <Filters>
        <Label>Show</Label>
        <FauxSelect
          errNarrow
          name="learnerId"
          selected={selectedFilters.learnerId}
          options={learnerOptions}
          onSelect={newValue => { setSelectedFilters(vals => ({ ...vals, learnerId: newValue })) }}
        />
        <FauxSelect
          errNarrow
          name="status"
          selected={selectedFilters.status}
          options={statusOptions}
          onSelect={newValue => { setSelectedFilters(vals => ({ ...vals, status: newValue })) }} />
        <FauxSelect
          errNarrow
          name="worksheetId"
          selected={selectedFilters.worksheetId}
          options={worksheetOptions}
          onSelect={newValue => { setSelectedFilters(vals => ({ ...vals, worksheetId: newValue })) }} />
      </Filters>
      {filteredResponses.map(response => {
        return (
          <ResponseRow
            key={response.id}
            experiment={experiment}
            learners={learners}
            response={response}
            statusForTeacher={responseStatusesById[response.id]}
            worksheets={worksheets}
          />
        )
      })}
      {!filteredResponses.length
        ? <RelativeWrapper>
          <EmptyContentPlaceholder>
            <P>No responses match the selected filters</P>
          </EmptyContentPlaceholder>
        </RelativeWrapper>
        : null}
      <SavePoint
        key={[filteredResponses.length.toFixed(), ...Object.values(selectedFilters)].sort().join(',')}
        content={getContentForSelectedDownloadOptions(availableDownloads, fauxSelectedDownloadOptions, experiment as Experiment, learners)}
        TriggerComponent={getDownloadButton(downloadButtonLabel, !filteredResponses.length)} />
    </ResponsesOuter>
  }

export default ProjectResponses
