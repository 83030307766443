import React from 'react'
import styled from 'styled-components'
import { Button } from '~/components/atoms'
import { experimentFormResolvers, WorksheetHelpers } from '~/features'
import { getEventLogSummaries } from '~/features/worksheetResponse/helpers'
import { Form } from '~/form-brain2'
import { useUpdateEntity } from '~/hooks'
import { isAllEmpty } from '~/utils/testers'
import { ErrorNotice, FormRenderer } from '../molecules'
import { LogOuter, EntryRow, EntryRowCell, EntryText, ResultSet, ResultLabel, ResultText } from '../molecules/ResponseEventLog'

import type { EntityObject, EntitySchema, FormOnSubmitFn } from '~/form-brain2'
import type { AnyPlainOrInputElement, Experiment, ResponseEventLog as LogEntryType, Worksheet, WorksheetResponse } from '~/features'

interface Props {
  eventLog: LogEntryType[]
  experiment: Experiment
  response: WorksheetResponse
  worksheets: Worksheet[]
  worksheet: Worksheet
  responses: WorksheetResponse[]
}
const
  FormRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  FormActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  StyledButton = styled(Button)`
    margin-left: ${p => p.theme.emSmallSpacing}em;
  `,
  formId = 'responseEventComment',
  formSchema: EntitySchema = {
    grade: {
      fieldName: 'grade',
      fieldType: 'STRING'
    },
    comment: {
      fieldName: 'comment',
      fieldType: 'STRING'
    }
  },
  denyFormBody: AnyPlainOrInputElement[] = [
    {
      id: 'responseComment',
      elementVariety: 'LONG_ANSWER',
      label: 'Notes for learner:',
      placeholder: 'Click here to start typing your comments (optional)',
      fieldName: 'comment'
    }
  ],
  approveFormBody: AnyPlainOrInputElement[] = [
    {
      id: 'responseGrade',
      elementVariety: 'TEXT_INPUT',
      label: 'Grade:',
      placeholder: 'Enter a grade (optional)',
      fieldName: 'grade'
    },
    ...denyFormBody
  ],
  TeacherResponseEventLog: React.FC<Props> = ({ eventLog, experiment, response, worksheets, responses, worksheet }) => {
    const
      reversedEventLog = [...eventLog].reverse(),
      [showCommentForm, setShowCommentForm] = React.useState<'approve' | 'deny'>(),
      { updateEntity, sourceOfUpdate, isUpdating } = useUpdateEntity(),

      [, nextResponse] = WorksheetHelpers.getFollowingWorksheetResponseAndStatus(worksheet, worksheets, responses, experiment.progressBasis),
      nextStepStarted = !isAllEmpty(nextResponse?.responses),

      eventLogSummaries = getEventLogSummaries(reversedEventLog, experiment),
      respondToReopen: (doApprove: boolean) => React.MouseEventHandler = (doApprove) => (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        const
          relevantLogIndex = reversedEventLog.findIndex(o => o.eventType === 'studentRequestsReopen'),
          relevantLog: LogEntryType = reversedEventLog[relevantLogIndex],
          updatedLogEntry: LogEntryType = ({
            ...relevantLog,
            outcomeType: doApprove ? 'approved' : 'denied',
            outcomeOccurredAt: new Date().toISOString()
          }),
          updatedLog = reversedEventLog.map((el, index) => index === relevantLogIndex ? updatedLogEntry : el)

        updateEntity({
          entity: response,
          newValues: { eventLog: updatedLog.reverse() },
          sourceOfUpdate: doApprove ? 'allowReopen' : 'denyReopen'
        }).then(
          () => { window.scrollTo(0, 0) /** Path doesn't change but should go to top anyway since state changes so much */ },
          () => { alert('There was a problem completing this request. Please try again later and contact support if the problem continues.') }
        )
      },
      submitComments: FormOnSubmitFn = ({ values }) => {
        const
          relevantLogIndex = reversedEventLog.findIndex(o => o.eventType === 'submitted'),
          relevantLog: LogEntryType = reversedEventLog[relevantLogIndex],
          updatedLogEntry: LogEntryType = ({
            ...relevantLog,
            ...(values as { comment?: string, grade?: string }),
            outcomeType: showCommentForm === 'approve' ? 'approved' : 'denied',
            outcomeOccurredAt: new Date().toISOString()
          }),
          updatedLog = reversedEventLog.map((el, index) => index === relevantLogIndex ? updatedLogEntry : el)

        return updateEntity({
          entity: response,
          newValues: { eventLog: updatedLog.reverse() },
          sourceOfUpdate: 'commentForm'
        })
      }

    React.useEffect(() => {
      setShowCommentForm(undefined)
    }, [response.updatedAt])

    return <LogOuter data-cy="teacher-response-event-log">
      {eventLogSummaries.map((eventLogSummary, index) => {
        const
          { eventString, outcomeString, isReadyToGrade, hasPendingRequest, logEntry } = eventLogSummary,
          { grade, comment } = logEntry ?? {},
          secondContent = hasPendingRequest && !showCommentForm
            ? <>
               <StyledButton size="small" color="forward" label="Allow" onClick={respondToReopen(true)} isLoading={sourceOfUpdate === 'allowReopen'} isDisabled={isUpdating && sourceOfUpdate !== 'allowReopen'} />
               <StyledButton size="small" color="back" label="Deny" onClick={respondToReopen(false)} isLoading={sourceOfUpdate === 'denyReopen'} isDisabled={isUpdating && sourceOfUpdate !== 'denyReopen'} />
             </>
            : isReadyToGrade && !showCommentForm
              ? <>
                 <StyledButton size="small" color="forward" label="Grade & Approve" onClick={() => { setShowCommentForm('approve') }} isDisabled={isUpdating} />
                 <StyledButton size="small" color="back" label="Request Revision" onClick={() => { setShowCommentForm('deny') }} isDisabled={isUpdating} />
               </>
              : outcomeString
                ? <EntryText color="default">{outcomeString}</EntryText>
                : null

        return [
          hasPendingRequest && nextStepStarted && worksheet.fixedRank
            ? <ErrorNotice key={`${index}-warning`} errors={{ _base: ['Warning: This learner has started work on the next step, allowing them to change their responses from this step may result in unexpected behavior. Proceed with caution.'] }} />
            : null,
          <EntryRow key={index} data-cy="teacher-response-event-log-entry-row">
            <EntryRowCell>
              <EntryText color="default">{eventString}</EntryText>
            </EntryRowCell>
            {secondContent
              ? <EntryRowCell>
                {secondContent}
              </EntryRowCell>
              : null}
          </EntryRow>,
          showCommentForm && isReadyToGrade
            ? <FormRow key={formId}>
              <Form
                formId={formId}
                initialValues={logEntry as EntityObject | undefined}
                formBody={showCommentForm === 'approve' ? approveFormBody : denyFormBody}
                FormRenderer={FormRenderer}
                entitySchema={formSchema}
                formOptions={{
                  dynamicLabels: experiment.dynamicLabels,
                  RootFormRenderer: FormRenderer
                }}
                onSubmitCallback={submitComments}
                {...experimentFormResolvers}
              />
              <FormActions>
                <StyledButton size="small" color="caution" label="Cancel" onClick={() => { setShowCommentForm(undefined) }} isDisabled={isUpdating} />
                <StyledButton size="small" color="forward" label={showCommentForm === 'approve' ? 'Approve' : 'Request Revision'} type="submit" form={formId} isLoading={sourceOfUpdate === 'commentForm'} />
              </FormActions>
            </FormRow>
            : null,
          !showCommentForm && grade
            ? <ResultSet key={`${index}-grade`}>
              <ResultLabel>Grade</ResultLabel>
              <ResultText>{grade}</ResultText>
             </ResultSet>
            : null,
          !showCommentForm && comment
            ? <ResultSet key={`${index}-comment`}>
              <ResultLabel>Notes</ResultLabel>
              <ResultText>{comment}</ResultText>
             </ResultSet>
            : null
        ]
      })}
    </LogOuter>
  }

export default TeacherResponseEventLog
