import * as React from 'react'
import styled from 'styled-components'
import trueJQuery from 'jquery'
import { FormContext } from '~/form-brain2'
import { isAllEmpty } from '~/utils/testers'
import ErrorNotice from '~/components/molecules/FormElements/ErrorNotice'
import FormElement from '~/components/molecules/FormRenderers/FormElement'
import { BasicCross, Button, ErrorText, Icon } from '~/components/atoms'
import { compoundInputWrapperStyles } from '../FormElements/CompoundInputWrapper'

import type { FormRendererProps, CollectionOptions, ScopeOptions, FormOptions } from '~/form-brain2'
import type { InputElement } from '~/features'
import { isEqual, uniq } from 'lodash'

const
  FormProper = styled.form`
    margin-bottom: ${p => p.theme.emBaseSpacing}em;
  `,
  FormBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  `,
  NestedForm = styled.div<{ $asRow?: boolean, $enclosed?: boolean }>`
    margin-bottom: ${p => p.theme.emSmallSpacing}em;

    > .form-body {
      ${p => p.$asRow ? compoundInputWrapperStyles : ''}
      ${p => p.$enclosed ? `padding: ${p.theme.emBaseSpacing * 2}em; border: 2px solid ${p.theme.colors.borderAlt}` : ''}
    }
  `,
  DeleteIcon = styled(Icon)`
    width: 27px;
    margin-left: auto;
  `,
  DeleteButton = styled(Button)`
    // width: 27px;
    margin-left: auto;
  `,
  getShowDeleteButton = (isNested: boolean | undefined, scopeOptions: ScopeOptions | CollectionOptions | undefined, formOptions: FormOptions): boolean => {
    const
      hasFn = scopeOptions?.removeEntry,
      isReadOnly = !!scopeOptions?.isReadOnly || formOptions.isReadOnly,
      isExempt = (scopeOptions as CollectionOptions)?.useAlternateEntryRenderer === 'bodyElementList',
      minLength = (scopeOptions as CollectionOptions)?.minimumLength,
      collectionLength = (scopeOptions as CollectionOptions)?.collectionLength,
      minLengthExceeded = !minLength || !collectionLength || (collectionLength > minLength)

    return !!(isNested && hasFn && minLengthExceeded && !isExempt) && !isReadOnly
  },
  FormRenderer: React.FC<FormRendererProps> = ({ formId, formBody, className, isNested }) => {
    const
      formContext = React.useContext(FormContext),
      formBodyRef = React.useRef<HTMLFormElement | null>(null),
      { showingAllErrors, errors, triggerFormSubmission } = formContext,
      [childrenHaveErrors, setChildrenHaveErrors] = React.useState<boolean>(false),
      baseErrorsPresent = !isAllEmpty(errors._base ?? errors.base),
      isDirty = !isEqual(formContext.values, formContext.initialValues),
      showBaseError = childrenHaveErrors || (baseErrorsPresent && (!isNested || isDirty || showingAllErrors)),
      isFullCollectionEntry = isNested && formContext.scopeOptions && 'collectionLength' in formContext.scopeOptions && !formContext.scopeOptions.editInline,
      content = <>
        {showBaseError
          ? <ErrorNotice errors={errors} />
          : null}
        <FormBody className="form-body">
          {formBody.map((o, i) => (
            <FormElement
              key={`${i}-${o.elementVariety}`}
              bodyElement={o}
              elementIndex={i} />
          ))}
          {!getShowDeleteButton(isNested, formContext.scopeOptions, formContext.formOptions)
            ? undefined
            : isFullCollectionEntry
              ? <DeleteButton
                color="caution"
                size="small"
                label={`Remove ${(formContext.scopeOptions as CollectionOptions).entryNameSingular ?? 'row'}`}
                onClick={(formContext.scopeOptions as ScopeOptions).removeEntry}
              />
              : <DeleteIcon
                accessibleDescription="Delete row"
                content={BasicCross}
                degreesRotation={45}
                color="caution"
                frameStyle="circle"
                onClick={(formContext.scopeOptions as ScopeOptions).removeEntry}
              />}
        </FormBody>
      </>

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => {
      const errorsPresent = trueJQuery(`#${formId} [data-has-error=true]`).length > 0

      if (errorsPresent !== childrenHaveErrors) {
        setChildrenHaveErrors(errorsPresent)
      }
    })

    React.useEffect(() => {
      if (
        formBodyRef.current &&
        (childrenHaveErrors || baseErrorsPresent)
      ) {
        formBodyRef.current.previousElementSibling
          ? formBodyRef.current.previousElementSibling.scrollIntoView()
          : formBodyRef.current.scrollIntoView()
      }
    }, [childrenHaveErrors, baseErrorsPresent, formBodyRef])

    // TODO DEV additional structure to use animations when switching between pages of the form?
    if (isNested) {
      const
        asRow = !!(formContext.scopeOptions as ScopeOptions)?.asRow || !!(formContext.scopeOptions as CollectionOptions)?.editInline,
        nestedErrors = formContext.scopeOptions?.elementVariety
          ? uniq((formBody.filter(o => (o as InputElement).fieldName) as InputElement[]).map((o, i) =>
            (showingAllErrors || !isEqual(formContext.values[o.fieldName], formContext.initialValues[o.fieldName])) ? formContext.errors[o.fieldName] : undefined
          ).filter(o => o).flat())
          : []

      return (
        <NestedForm
          id={formId}
          data-cy="nested-form"
          className={['nested-form', className].filter(o => o).join(' ')}
          {...(showBaseError ? { 'data-has-error': true } : {})}
          $asRow={asRow}
          $enclosed={isFullCollectionEntry && (formContext.scopeOptions as CollectionOptions)?.encloseEntries}>
          {content}
          {asRow && !isAllEmpty(nestedErrors)
            ? <ErrorText aria-live="polite">{nestedErrors.join(', ')}</ErrorText>
            : null}
        </NestedForm>
      )
    }

    return <FormProper id={formId} noValidate className={className} onSubmit={triggerFormSubmission} ref={formBodyRef}>
      {content}
    </FormProper>
  }

export default FormRenderer
