import * as React from 'react'
import styled from 'styled-components'

interface TabGroupProps {
  children?: React.ReactNode
  className?: string
}

const
  TabGroupOuter = styled.div`
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: auto;
    gap: ${p => p.theme.emSmallSpacing * 1.25}em; // 9px
    width: 100%;
  `,
  TabGroup: React.FC<TabGroupProps> = ({ className, children }) => {
    return <TabGroupOuter className={className} data-cy="tab-group" role="tablist">
      {children}
    </TabGroupOuter>
  }

export default TabGroup
