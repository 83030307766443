import * as React from 'react'
import styled, { css, useTheme } from 'styled-components'
import { DashedLine as _DashedLine } from './Shapes'
import { LoadingIcon } from './Icons'

const
  ListItem = styled.div<{ $clickable?: boolean, $understate?: boolean, $emphasize?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${p => p.theme.colors.bodyBg};
    border-radius: ${p => p.theme.pxBorderRadius}px;
    border: 1px solid ${p => p.$emphasize ? p.theme.colors.forwardStandaloneText : p.theme.colors.borderAccentAlt};
    // height: 2.2em;
    padding: 2px;
    margin-bottom: 6px;
    ${p => p.$clickable ? 'cursor: pointer;' : ''}
    ${p => p.$understate ? 'opacity: 0.6;' : ''}
  `,
  ListItemCell = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
    gap: 0.5em;
    user-select: none;
  `,
  listItemIconStyles = css`
    width: 20px;
    margin-left: auto;
  `,
  ListItemLoadingIcon = styled(LoadingIcon)`
    width: 1em;
    margin-left: auto;
  `,
  DashedLine = styled(_DashedLine)`
    max-height: 2.2em;
  `,
  ListItemCellDivider: React.FC = () => {
    const THEME = useTheme()

    return <DashedLine orientation="vertical" color={THEME.colors.borderAccentAlt} />
  }

export { ListItem, ListItemCell, listItemIconStyles, ListItemLoadingIcon, ListItemCellDivider }
