import type { RootState as State } from '~/services/store'
import type { RaceOperationInfo } from './sessionSlice'

export const
  selectUnverifiedAccessToken = (state: State): string | undefined => state.session.accessToken,
  selectUnverifiedRefreshToken = (state: State): string | undefined => state.session.refreshToken,
  selectHasInitializedData = (state: State): boolean => state.session.hasInitializedData,
  selecteReceived401ForSessionType = (state: State): State['session']['received401ForSessionType'] => state.session.received401ForSessionType,
  selectWinningOperationId = (key: string) => (state: State): string | undefined => state.session.winningOperationIds[key],
  selectIsWinningOperation = ({ key, operationId }: RaceOperationInfo) =>
    (state: State): boolean => selectWinningOperationId(key)(state) === operationId
