import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  FERTILIZER_TIMING_REFERENCES = {
    BEFORE_PLANTING: 'before planting',
    AT_PLANTING: 'at planting',
    AFTER_PLANTING: 'after planting',
    AT_GERMINATION: 'at germination',
    AFTER_GERMINATION: 'after germination'
  } as const,
  title = 'Fertilizer timing period',
  description = 'The part of the growth cycle in which fertilizer will be applied. When this is on its own, it will be applied to all subjects. When part of a treatment, it will be applied to subjects receiving that treatment.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.fertilizerTimingReference,
    fieldType: 'STRING',
    options: [
      { optionValue: FERTILIZER_TIMING_REFERENCES.BEFORE_PLANTING },
      { optionValue: FERTILIZER_TIMING_REFERENCES.AT_PLANTING },
      { optionValue: FERTILIZER_TIMING_REFERENCES.AFTER_PLANTING },
      { optionValue: FERTILIZER_TIMING_REFERENCES.AT_GERMINATION },
      { optionValue: FERTILIZER_TIMING_REFERENCES.AFTER_GERMINATION }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.fertilizerTimingReference,
    variableGroup: SystemFieldGroups.CONTROL_VARIABLES,
    optionValuesReadOnly: true,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails,
  FERTILIZER_TIMING_REFERENCES
}
