import React from 'react'
import axios from 'axios'
import { get } from 'lodash'
import { baseUrl } from '~/services/api'
import { processAxiosResultForForm } from '~/utils/errorMessages'

import type { AttrErrors, EntityErrors } from '~/form-brain2'

interface UseLogIn {
  resetState: () => void
  onLogIn: (email: string) => void
  isLoggingIn: boolean
  successEmail?: string
  errors?: AttrErrors
  token?: string
}

interface UseLogInState {
  isLoggingIn: boolean
  token?: string
  logInErrors?: EntityErrors
  successEmail?: string
}

const
  useLogIn = (): UseLogIn => {
    const
      [state, setState] = React.useState<UseLogInState>({ isLoggingIn: false }),
      { isLoggingIn, logInErrors, token, successEmail } = state,
      emailErrors = (logInErrors?.base ?? logInErrors?.email) as AttrErrors,
      resetState = (): void => {
        setState({ isLoggingIn: false })
      },
      onLogIn = (email: string): void => {
        setState({ isLoggingIn: true })

        processAxiosResultForForm(axios({
          method: 'post',
          url: `${baseUrl}sessions/new`,
          data: { email }
        }))
          .then(({ data }) => {
            setState({
              isLoggingIn: false,
              token: get(data, 'meta.mailInfo.token') as string | undefined,
              successEmail: get(data, 'meta.email') as string | undefined
            })
          })
          .catch(({ errors }) => {
            setState({
              isLoggingIn: false,
              logInErrors: errors
            })
          })
      }

    return {
      onLogIn,
      isLoggingIn,
      resetState,
      successEmail,
      errors: emailErrors,
      token
    }
  }

export default useLogIn
