import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { degreeStopsForStepCount, pointOnCircleFromAngle } from '~/utils/geometry'

interface ProgressRingProps {
  currentStep: number
  totalSteps: number
  className?: string
}

const
  pxOuterRadius = 169,
  pxRingThickeness = 22,
  pctRingThickness = 22 / 169,
  ProgressRingOuter = styled.svg`
  `,
  getNextIntervalMidpoint = (stops: number[], index: number): number => {
    const
      thisStop = stops[index],
      nextStop = stops[index + 1] ?? 360

    return (thisStop + nextStop) / 2
  },
  ProgressRing: React.FC<ProgressRingProps> = ({ className, currentStep, totalSteps }) => {
    const
      THEME = useTheme(),
      outerRadius = pxOuterRadius,
      ringThickness = pxRingThickeness,
      innerRadius = outerRadius - ringThickness,
      circleCenter = outerRadius,
      degreeStops = degreeStopsForStepCount(totalSteps),
      terminateArcAtDegree = degreeStops[currentStep],
      positionArrowAtDegree = getNextIntervalMidpoint(degreeStops, currentStep),
      largeArcFlag = terminateArcAtDegree > 180 ? 1 : 0,
      startingPoint = [outerRadius, 0],
      outerEndingPoint = pointOnCircleFromAngle({ degreeAngle: terminateArcAtDegree, cx: circleCenter, cy: circleCenter, r: outerRadius }),
      innerStartingPoint = pointOnCircleFromAngle({ degreeAngle: terminateArcAtDegree, cx: circleCenter, cy: circleCenter, r: innerRadius }),
      arcPath = `
        M ${startingPoint[0]} ${startingPoint[1]}
        A ${outerRadius} ${outerRadius} 0 ${largeArcFlag} 1 ${outerEndingPoint[0]} ${outerEndingPoint[1]}
        L ${innerStartingPoint[0]} ${innerStartingPoint[1]}
        A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 0 ${startingPoint[0]} ${ringThickness}
        L ${startingPoint[0]} ${startingPoint[1]}
        Z
      `,
      maskId = `progress-ring-mask-${currentStep}-${totalSteps}`

    return <ProgressRingOuter className={className} viewBox="0 0 338 338" data-cy="progress-ring">
      <defs>
        <mask id={maskId}>
          <rect width="100%" height="100%" fill="white" />
          <circle cx={circleCenter} cy={circleCenter} r={innerRadius} fill="black" />
          <g style={{ transform: `rotate(${positionArrowAtDegree}deg)`, transformOrigin: 'center' }}>
            <svg viewBox="0 0 46 78" height="26px" y="-2" x="0">
              <path d="M46 39L7 78L0 71L32 39L0 7L7 0L46 39Z" fill="black" />
            </svg>
          </g>
        </mask>
      </defs>
      <circle cx={circleCenter} cy={circleCenter} r={outerRadius} fill={THEME.colors.understateLight} mask={`url(#${maskId})`} />
      {currentStep > 0
        ? <path d={arcPath} fill={THEME.colors.open} />
        : null}
    </ProgressRingOuter>
  }

export default ProgressRing
export { pctRingThickness }
