import * as React from 'react'
import styled from 'styled-components'
import {
  Icon,
  SubheadingTwo,
  ErrorTextOnly
} from '~/components/atoms'
import type { EntityErrors } from '~/form-brain2'

interface ErrorNoticeProps {
  errors: EntityErrors
  fallback?: string
}

const
  Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${p => p.theme.emSmallSpacing}em;
  `,
  GeneralNotice = styled(SubheadingTwo)`
    margin-left: ${p => p.theme.emSmallSpacing}em;
  `,
  WarningIcon = styled(Icon)`
    width: 1.5em;
  `,
  ErrorNotice: React.FC<ErrorNoticeProps> = ({ errors, fallback }) => {
    const baseError = errors?._base ?? errors?.base

    return <Row data-cy="error-notice">
      <WarningIcon content="!" color="caution" />
      {baseError
        ? <ErrorTextOnly>{(baseError as string[]).join(', ')}</ErrorTextOnly>
        : <GeneralNotice>{fallback ?? 'Please correct the problems below and try again.'}</GeneralNotice>}
    </Row>
  }

export default ErrorNotice
