import * as React from 'react'
import styled from 'styled-components'

interface HeadingThreeProps {
  className?: string
  children: React.ReactNode
}

const
  HeadingThreeText = styled.h3`
    align-items: center;
    font-size: 1.333rem; // rem math: 40px/ 2 / 15px
    color: ${p => p.theme.colors.headingText};
    ${p => p.theme.headingFont}
  `,
  HeadingThree: React.FC<HeadingThreeProps> = ({ className, children }) => {
    return (
      <HeadingThreeText className={className} data-cy="heading-three">
        {children}
      </HeadingThreeText>
    )
  }

export default HeadingThree
