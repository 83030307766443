import * as React from 'react'
import type { BasicIconProps } from '~/types/utilities'

const
  extraRingFilletPath = 'M30.4526 4.44141H32.8387V7.04454H30.4526V4.44141Z',
  paperShadowPath = 'M31.8686 4.44141H7.96035C5.31969 4.44141 3.17871 6.39131 3.17871 8.7963V34.9257C3.17871 37.3306 5.31969 39.2805 7.96035 39.2805H31.8686C34.5092 39.2805 36.6502 37.3306 36.6502 34.9257V8.7963C36.6502 6.39131 34.5092 4.44141 31.8686 4.44141Z',
  paperPath = 'M29.4779 4.44141H5.56973C2.92907 4.44141 0.788086 6.39131 0.788086 8.7963V34.9257C0.788086 37.3306 2.92907 39.2805 5.56973 39.2805H29.4779C32.1186 39.2805 34.2596 37.3306 34.2596 34.9257V8.7963C34.2596 6.39131 32.1186 4.44141 29.4779 4.44141Z',
  paperLinesPath = 'M30.6735 16.4168C30.6735 17.0189 30.1392 17.5056 29.4781 17.5056H5.56992C4.91006 17.5056 4.37451 17.0189 4.37451 16.4168C4.37451 15.8159 4.91006 15.3281 5.56992 15.3281H29.4781C30.1392 15.3281 30.6735 15.8159 30.6735 16.4168ZM30.6735 21.8605C30.6735 22.4625 30.1392 22.9492 29.4781 22.9492H5.56992C4.91006 22.9492 4.37451 22.4625 4.37451 21.8605C4.37451 21.2584 4.91006 20.7717 5.56992 20.7717H29.4781C30.1392 20.7717 30.6735 21.2584 30.6735 21.8605ZM30.6735 27.3041C30.6735 27.9061 30.1392 28.3928 29.4781 28.3928H5.56992C4.91006 28.3928 4.37451 27.9061 4.37451 27.3041C4.37451 26.702 4.91006 26.2154 5.56992 26.2154H29.4781C30.1392 26.2154 30.6735 26.702 30.6735 27.3041ZM21.1103 32.7477C21.1103 33.3498 20.5759 33.8364 19.9148 33.8364H5.56992C4.91006 33.8364 4.37451 33.3498 4.37451 32.7477C4.37451 32.1456 4.91006 31.659 5.56992 31.659H19.9148C20.5759 31.659 21.1103 32.1456 21.1103 32.7477Z',
  ringGapPathA = 'M6.56966 9.59378C5.73048 9.59378 5.05029 8.86869 5.05029 7.97267C5.05029 7.07774 5.73048 6.35156 6.56966 6.35156C7.40764 6.35156 8.08783 7.07774 8.08783 7.97267C8.08783 8.8676 7.40764 9.59378 6.56966 9.59378Z',
  ringGapPathB = 'M13.742 9.59378C12.9028 9.59378 12.2227 8.86869 12.2227 7.97267C12.2227 7.07774 12.9028 6.35156 13.742 6.35156C14.58 6.35156 15.2602 7.07774 15.2602 7.97267C15.2602 8.8676 14.58 9.59378 13.742 9.59378Z',
  ringGapPathC = 'M20.9144 9.59378C20.0752 9.59378 19.395 8.86869 19.395 7.97267C19.395 7.07774 20.0752 6.35156 20.9144 6.35156C21.7524 6.35156 22.4338 7.07774 22.4338 7.97267C22.4326 8.8676 21.7524 9.59378 20.9144 9.59378Z',
  ringGapPathD = 'M28.0872 9.59378C27.2481 9.59378 26.5679 8.86869 26.5679 7.97267C26.5679 7.07774 27.2481 6.35156 28.0872 6.35156C28.9252 6.35156 29.6066 7.07774 29.6066 7.97267C29.6054 8.8676 28.9252 9.59378 28.0872 9.59378Z',
  ringPathA = 'M7.41472 7.79888C7.34897 7.2763 6.96763 6.93662 6.54326 6.69165C5.96349 6.35741 5.34785 5.59422 5.34785 4.64812C5.34785 3.44617 6.14997 2.36724 7.14097 2.36724C8.13196 2.36724 8.93408 3.35254 8.93408 4.44126H11.3201C11.295 2.26382 9.43735 0.28125 7.14097 0.28125C4.82904 0.28125 2.95703 2.26164 2.95703 4.64159C2.95703 6.65899 4.20624 8.31167 6.02804 8.91591C6.93655 9.2164 7.49122 8.42926 7.41472 7.79888Z',
  ringPathB = 'M14.5871 7.79888C14.5213 7.2763 14.14 6.93662 13.7156 6.69165C13.1359 6.35741 12.5202 5.59422 12.5202 4.64812C12.5202 3.44617 13.3223 2.36724 14.3133 2.36724C15.3043 2.36724 16.1064 3.35254 16.1064 4.44126H18.4925C18.4686 2.26382 16.6097 0.28125 14.3133 0.28125C12.0014 0.28125 10.1294 2.26164 10.1294 4.64159C10.1294 6.65899 11.3786 8.31167 13.2004 8.91591C14.1089 9.2164 14.6636 8.42926 14.5871 7.79888Z',
  ringPathC = 'M21.7597 7.79888C21.694 7.2763 21.3126 6.93662 20.8895 6.69165C20.3097 6.35741 19.694 5.59422 19.694 4.64812C19.694 3.44617 20.4962 2.36724 21.4872 2.36724C22.4782 2.36724 23.2803 3.35254 23.2803 4.44126H25.6663C25.6424 2.26382 23.7835 0.28125 21.4872 0.28125C19.1752 0.28125 17.3032 2.26164 17.3032 4.64159C17.3032 6.65899 18.5524 8.31167 20.3742 8.91591C21.2815 9.2164 21.8362 8.42926 21.7597 7.79888Z',
  ringPathD = 'M28.9321 7.79888C28.8663 7.2763 28.485 6.93662 28.0618 6.69165C27.482 6.35741 26.8664 5.59422 26.8664 4.64812C26.8664 3.44617 27.6685 2.36724 28.6595 2.36724C29.6505 2.36724 30.4526 3.35254 30.4526 4.44126H32.8387C32.8148 2.26382 30.9559 0.28125 28.6595 0.28125C26.3476 0.28125 24.4756 2.26164 24.4756 4.64159C24.4756 6.65899 25.7248 8.31167 27.5466 8.91591C28.4539 9.2164 29.0086 8.42926 28.9321 7.79888Z',
  Notepad: React.FC<BasicIconProps> = ({ className, color = 'black', outlineColor, colorClass = 'back' }) => {
    const
      lineColor = '#99AAB5',
      ringColor = ['understate', 'disabled'].includes(colorClass) ? lineColor : '#66757F',
      ringGapColor = '#292F33',
      paperColor = '#CCD6DD',
      shadowColor = ['understate', 'disabled'].includes(colorClass) ? lineColor : '#55ACEE',
      coloredPaths = (extraProps?: { stroke?: string, strokeWidth?: number }): React.ReactNode => {
        return <>
          <path d={extraRingFilletPath} fill={ringColor} />
          <path d={paperShadowPath}fill={shadowColor} {...extraProps} />
          <path d={paperPath} fill={paperColor} {...extraProps} />
          <path d={paperLinesPath} fill={lineColor} {...extraProps} />
          <path d={ringGapPathA} fill={ringGapColor} {...extraProps} />
          <path d={ringGapPathB} fill={ringGapColor} {...extraProps} />
          <path d={ringGapPathC} fill={ringGapColor} {...extraProps} />
          <path d={ringGapPathD} fill={ringGapColor} {...extraProps} />
          <path d={ringPathA} fill={ringColor} {...extraProps} />
          <path d={ringPathB} fill={ringColor} {...extraProps} />
          <path d={ringPathC} fill={ringColor} {...extraProps} />
          <path d={ringPathD} fill={ringColor} {...extraProps} />
        </>
      }

    return (
      <svg
        className={className}
        viewBox={outlineColor ? '-6.5 -5 50 50' : '-1.5 0 40 40'}
        xmlns="http://www.w3.org/2000/svg"
        data-cy="notepad">
        {outlineColor
          ? coloredPaths({
            stroke: outlineColor,
            strokeWidth: 10
          })
          : null}
        {coloredPaths()}
      </svg>
    )
  }

export default Notepad
