import * as React from 'react'
import styled from 'styled-components'
import { sortBy } from 'lodash'
import { Form } from '~/form-brain2'
import { isEmptyValue } from '~/utils/testers'
import { getBaseErrorString } from '~/utils/formatters'
import { experimentFormResolvers } from '~/features'
import { useFormVitalsSubscription, useAdminUserSearch, useAdminSummary } from '~/hooks'
import { getAccountTypeString } from '~/hooks/useSession'
import { Button, HeadingThree, SubheadingOne, Table, TBody, TD, TR } from '~/components/atoms'
import { EmptyContentPlaceholder, ErrorMessage, FormRenderer } from '~/components/molecules'
import { RelativeWrapper } from '~/components/organisms/ListStep/StackingSections'
import AccountManagementModal from './AccountManagementModal'

import type { EntitySchema, FormOnSubmitFn } from '~/form-brain2'
import type { AdminSummary } from '~/features/admin/adminSlice'
import type { TextInput } from '~/features'

const
  ListItem = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.4em;
  `,
  SubmitButton = styled(Button)`
    align-self: flex-start;
  `,
  BR = styled.div`
    width: 100%;
    height: 0.5rem;
  `,
  Subheading = styled(SubheadingOne)`
    margin-top: ${p => p.theme.emBaseSpacing}em;
  `,
  summaryItems: Array<null | [key: keyof AdminSummary, label: string]> = [
    ['numUsers', 'Total Users'],
    ['numResearchers', 'Researchers'],
    ['numTeachers', 'Teachers'],
    null,
    ['numUsersActiveLastSixMonths', 'Users active in the last 6 months'],
    ['numResearchersActiveLastSixMonths', 'Researchers active in the last 6 months'],
    ['numTeachersActiveLastSixMonths', 'Teachers active in the last 6 months'],
    null,
    ['numNewUsersLastSixMonths', 'New users in the last 6 months'],
    ['numNewResearchersLastSixMonths', 'New researchers in the last 6 months'],
    ['numNewTeachersLastSixMonths', 'New teachers in the last 6 months'],
    null,
    ['numProjects', 'Research Survey Projects'],
    ['numParticipants', 'Survey Participants'],
    ['numSurveyResponses', 'Survey Responses'],
    null,
    ['numExperiments', 'Learner Projects'],
    ['numLearners', 'Learners'],
    ['numWorksheetResponses', 'Learner Worksheet Responses']
  ],
  formName = 'userSearch',
  schema: EntitySchema = {
    emailSearchTerm: {
      fieldName: 'emailSearchTerm',
      fieldType: 'STRING'
    }
  },
  body: TextInput[] = [{
    id: 'email',
    elementVariety: 'TEXT_INPUT',
    fieldName: 'emailSearchTerm',
    inputType: 'email',
    label: 'Search by email:',
    placeholder: 'Enter an email or part of an email'
  }],
  AdminDashboard: React.FC = () => {
    const
      [selectedUserId, setSelectedUserId] = React.useState<string>(),
      { formStatus, subscribeToFormStatus, formMethodsRef } = useFormVitalsSubscription(),
      { users, searchForUsers, isSearching, errors } = useAdminUserSearch(),
      { adminSummary } = useAdminSummary(),
      selectedUser = users && selectedUserId
        ? users.find(o => o.id === selectedUserId)
        : undefined,
      onSubmit: FormOnSubmitFn = ({ values }) => {
        setSelectedUserId(undefined)
        return searchForUsers(values.emailSearchTerm as unknown as string)
      },
      onUpdateUser = (): void => {
        if (formMethodsRef.current?.triggerFormSubmission) {
          formMethodsRef.current.triggerFormSubmission()
        }
      }

    return <>
      <HeadingThree>Usage Summary</HeadingThree>
      <em>Note: These numbers do not include test accounts or their data</em>
      <BR />
      {summaryItems.map((val, index) => {
        if (!val) { return <BR key={index} /> }

        const [key, label] = val

        return <ListItem key={key}>
          <span>{label}:</span>
          {isEmptyValue(adminSummary[key])
            ? <span>?</span>
            : <strong>{adminSummary[key]}</strong>}
        </ListItem>
      })}
      <br />
      <HeadingThree>Manage Users</HeadingThree>
      <Form
        formId={formName}
        entitySchema={schema}
        formBody={body}
        methodRef={formMethodsRef}
        onSubmitCallback={onSubmit}
        FormRenderer={FormRenderer}
        subscribeToFormStatus={subscribeToFormStatus}
        formOptions={{ RootFormRenderer: FormRenderer }}
        {...experimentFormResolvers}
      />
      <SubmitButton
        type="submit"
        isLoading={formStatus === 'processing'}
        form={formName}
        label="Search"
        color="back"
        size="medium"
      />
      {isSearching
        ? null
        : <Subheading>Search Results</Subheading>}
      {isSearching
        ? null
        : errors
          ? <ErrorMessage>
            {getBaseErrorString(errors, 'There was a problem fetching users')}
          </ErrorMessage>
          : isEmptyValue(users)
            ? <RelativeWrapper>
                <EmptyContentPlaceholder colorKey="bodyBg" opacity={0.75}>
                  <span>{
                    users
                      ? 'No matching users found'
                      : 'Use the search above to find and manage an account for someone who has contacted support'
                  }</span>
                </EmptyContentPlaceholder>
              </RelativeWrapper>
            : <Table>
              <TBody>
                {sortBy(users, 'email').map(user => (
                  <TR key={user.id}>
                    <TD>{user.email}</TD>
                    <TD>{getAccountTypeString(!!user.isAdmin, !!user.isResearcher, !!user.isTeacher, false, user.id)}</TD>
                    <TD><Button size="small" color="back" label="Manage" onClick={() => { setSelectedUserId(user.id) }} /></TD>
                  </TR>
                ))}
              </TBody>
            </Table>}
      {selectedUser
        ? <AccountManagementModal user={selectedUser} onClose={() => { setSelectedUserId(undefined) }} onSuccess={onUpdateUser} />
        : null}
    </>
  }

export default AdminDashboard
