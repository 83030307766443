import * as React from 'react'
import { comparableInputVarieties } from '~/features/questionnaire/types'
import { getFormFieldDetails } from '~/form-brain2'
import FormFieldWrapper from './FormFieldWrapper'
import { isEmptyValue } from '~/utils/testers'

import type { ContextAndResolvers, ToggleFn, AttrErrors } from '~/form-brain2'
import type { InputElement, MadLibElement } from '~/features/questionnaire/types'
import styled, { css } from 'styled-components'
import { isEqual, uniq } from 'lodash'

interface Props {
  bodyElement: MadLibElement
  contextAndResolvers: ContextAndResolvers
  children: React.ReactNode
}

const
  compoundInputWrapperStyles = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5em;

    > * {
      white-space: nowrap;
      margin: 0;
    }

    > [data-cy="form-field-wrapper"]:not(:only-child) {
      width: unset;
    }
  `,
  InnerWrapper = styled.div`
    ${compoundInputWrapperStyles}
  `,
  CompoundInputWrapper: React.FC<Props> = ({ bodyElement, contextAndResolvers, children }) => {
    const
      { segments } = bodyElement,
      { showingAllErrors, formOptions } = contextAndResolvers,
      [showHint, setShowHint] = React.useState<boolean>(!!bodyElement.showHintAlways),
      toggleHint: ToggleFn = () => { setShowHint(_showHint => !_showHint) },
      inputSegments = segments.filter(o => comparableInputVarieties.includes(o.elementVariety)) as InputElement[],
      selfSpecs = {
        bodyElement,
        label: contextAndResolvers.resolveLabel({ bodyElement, contextAndResolvers }),
        showLabel: !bodyElement.hideLabel,
        showHint: showHint && !bodyElement.hideHint,
        isDisabled: formOptions.unsubmittable
      },
      ownHint = contextAndResolvers.resolveHint({ bodyElement, contextAndResolvers }),
      inputSpecs = inputSegments.map((o) => getFormFieldDetails({ bodyElement: o, contextAndResolvers })),
      allValues = inputSpecs.map(o => o.currentValue),
      [initialValues] = React.useState(allValues),
      isDirty = allValues.some((o, i) => o !== initialValues[i]),
      errors = uniq(inputSpecs.map((o, i) =>
        (showingAllErrors || !isEqual(o.currentValue, initialValues[i])) ? o.errors : undefined
      ).filter(o => o).flat()),
      wrapperProps = {
        updateAttr: () => {},
        noWrapper: true,
        toggleHint,
        formOptions,
        name: `compoundInput-${bodyElement.id}`,
        pathToAttr: '' as string,
        hint: ownHint ?? inputSpecs.map(o => o.hint).filter(o => !isEmptyValue(o)).join(', '),
        showError: isDirty || showingAllErrors,
        errors: errors as AttrErrors,
        isVisble: inputSpecs.some(o => o.isVisible),
        isRequired: inputSpecs.some(o => o.isRequired),
        isRecommended: inputSpecs.some(o => o.isRecommended),
        ...selfSpecs
      }

    React.useEffect(() => {
      if (
        wrapperProps.label &&
        bodyElement.label &&
        wrapperProps.showLabel &&
        formOptions.setParentHeading
      ) {
        formOptions.setParentHeading(bodyElement.label, bodyElement.parentId)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wrapperProps.label, bodyElement.label, wrapperProps.showLabel, formOptions.setParentHeading])

    return <FormFieldWrapper inputProps={wrapperProps} noWrapper>
      <InnerWrapper data-cy="compound-input-wrapper">
        {children}
      </InnerWrapper>
    </FormFieldWrapper>
  }

export { compoundInputWrapperStyles }
export default CompoundInputWrapper
