import { DependentVariableCategories } from './dependentVariableCategory'
import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField, VisibilityCondition } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  showUnlessDVCIn = (dvcsToMatch: string[]): VisibilityCondition[] => [{
    valueName: SystemFieldNames.dependentVariableCategory,
    conditionType: 'noneOf',
    testValue: dvcsToMatch
  }],
  title = 'Subject',
  description = 'This is what the learner(s) will grow during their experiment. The choice of subject does not change anything about how the experient works. It is used to tailor language and pictures so they are more relevant to the learner.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.subject,
    fieldType: 'STRING',
    options: [
      ...['beans', 'carrots', 'eggplants', 'pumpkins', 'tomatoes'].map(value => ({
        optionValue: value,
        visibleWhen: showUnlessDVCIn([
          DependentVariableCategories.ROW_CROP,
          DependentVariableCategories.COVER_CROP
        ])
      })),
      ...['oats', 'rye', 'wheat'].map(value => ({
        optionValue: value,
        visibleWhen: showUnlessDVCIn([DependentVariableCategories.VEGETABLE])
      })),
      ...['corn'].map(value => ({
        optionValue: value,
        visibleWhen: showUnlessDVCIn([DependentVariableCategories.COVER_CROP])
      })),
      ...['soybeans', 'sunflowers', 'field peas'].map(value => ({
        optionValue: value,
        visibleWhen: showUnlessDVCIn([
          DependentVariableCategories.VEGETABLE,
          DependentVariableCategories.COVER_CROP
        ])
      })),
      ...['alfalfa', 'clover', 'buckwheat', 'vetch', 'sorghum-sudangrass'].map(value => ({
        optionValue: value,
        visibleWhen: showUnlessDVCIn([
          DependentVariableCategories.VEGETABLE,
          DependentVariableCategories.ROW_CROP
        ])
      }))
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.subject,
    variableGroup: SystemFieldGroups.WHAT_WHERE,
    isRequired: true,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
