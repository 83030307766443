import * as React from 'react'
import styled from 'styled-components'

interface HintTextProps {
  className?: string
  children?: React.ReactNode
  color: 'accent' | 'default'
}

interface HintTextOuterProps {
  $color: 'accent' | 'default'
}

const
  HintTextOuter = styled.div<HintTextOuterProps>`
    color: ${p => p.theme.colors[p.$color === 'accent' ? 'bodyAccentText' : 'bodyText']};
    ${p => p.theme.accentFont}
    font-size: 1.051rem; // rem math: 31.53px / 2 / 15px
    text-align: center;
    line-height: 1.42;

    strong {
      color: black;
      font-weight: normal;
      text-decoration: underline;
    }
  `,
  HintText: React.FC<HintTextProps> = ({ className, children, color }) => {
    return <HintTextOuter $color={color} className={className} data-cy="hint-text">
      {children}
    </HintTextOuter>
  }

export default HintText
