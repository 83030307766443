import { keyBy } from 'lodash'
import { SystemFieldNames, SystemFieldGroups, getControlGroupTitle, getControlGroupDescription, INDEPENDENT_VARIABLES } from './systemFieldConstants'
import { fieldDetails as irrigationTimingValue } from './irrigationTimingValue'
import { fieldDetails as irrigationTimingUnit } from './irrigationTimingUnit'
import { fieldDetails as irrigationTimingCondition } from './irrigationTimingCondition'

import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

export interface IrrigationTimingTreatmentFormat {
  irrigationTimingValue: number
  irrigationTimingUnit: string
  irrigationTimingCondition: string
}

const
  independentVariable = INDEPENDENT_VARIABLES.IRRIGATION_TIMING,
  schemaEntry: QField = {
    fieldName: SystemFieldNames.irrigationTimingControlGroup,
    fieldType: 'SCOPE',
    visibleWhen: [{
      valueName: SystemFieldNames.independentVariable,
      conditionType: 'matches',
      testValue: independentVariable
    }],
    format: keyBy([
      irrigationTimingValue.schemaEntry,
      irrigationTimingUnit.schemaEntry,
      irrigationTimingCondition.schemaEntry
    ], 'fieldName')
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.irrigationTimingControlGroup,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    shouldPrecede: [SystemFieldNames.irrigationTimingTreatmentGroups],
    isRequired: false,
    title: getControlGroupTitle(independentVariable),
    description: getControlGroupDescription(independentVariable),
    controlGroupFor: independentVariable,
    schemaEntry
  }

export {
  fieldDetails
}
