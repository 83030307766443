import * as React from 'react'
import styled from 'styled-components'
import { difference } from 'lodash'
import { FormContext, FormScope } from '~/form-brain2'
import { getLongRandomId } from '~/utils/strings'
import { isAllEmpty } from '~/utils/testers'
import { SYSTEM_FIELDS } from '~/features'
import { BasicCross, FormLabel, Icon } from '~/components/atoms'
import ActionPointer from '~/components/molecules/ActionPointer'
import FormElement from '~/components/molecules/FormRenderers/FormElement'
import ScopeRenderer from '~/components/molecules/FormRenderers/ScopeRenderer'
import { compoundInputWrapperStyles } from '~/components/molecules/FormElements/CompoundInputWrapper'

import type { EntityObject } from '~/form-brain2'
import type { SystemFieldName, InputElement, ScopeElement, SpecialElement } from '~/features'
import BodyElementListItem from '../FormAternates/BodyElementListItem'

interface Props {
  editorElement: SpecialElement
}

const
  PreviewWrapper = styled.div<{ $hasSelected?: boolean }>`
    ${compoundInputWrapperStyles}
    gap: ${p => p.theme.emSmallSpacing}em;
    align-items: stretch;
    margin-bottom: ${p => p.$hasSelected ? 0 : p.theme.emBaseSpacing}em;
  `,
  SegmentWrapper = styled.div<{ $understate?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;

    border: 2px solid ${p => p.theme.colors.understateLight};
    background: ${p => p.theme.colors.containerBg};
    border-radius: ${p => p.theme.pxBorderRadius}px;
    padding: ${p => p.theme.emSmallSpacing / 2}em;

    opacity: ${p => p.$understate ? '0.5' : '1'};

    [data-cy="icon"] {
      scale: 0.8;
    }

    &:hover {
      [data-cy="icon"] {
        scale: 1.1;
      }
    }

    [data-cy="form-field-wrapper"],
    [data-cy="form-element__text"] {
      pointer-events: none;

      + [data-cy="hidden-field-message"] {
        display: none;
      }
    }
  `,
  StyledActionPointer = styled(ActionPointer)`
    width: unset;
  `,
  StyledIcon = styled(Icon)`
    width: 1em;
    margin-left: ${p => p.theme.emSmallSpacing}em;
  `,
  HiddenFieldMessage = styled.div`
    color: ${p => p.theme.colors.understateMed};
  `,
  MadLibEditor: React.FC<Props> = ({ editorElement }) => {
    const
      formContext = React.useContext(FormContext),
      { updateValues, values } = formContext,
      [selectedId, setSelectedId] = React.useState<string | undefined>(undefined),
      segments: EntityObject[] = (formContext.values.segments ?? []) as EntityObject[],
      segmentIds: string[] = segments.map(o => o.id) as string[],
      prevSegmentIds = React.useRef(segmentIds),
      selectedSegment = segments.find(o => o.id === selectedId),
      selectedIndex = selectedSegment ? segments.indexOf(selectedSegment) : -1,
      addSegment: React.MouseEventHandler | undefined = updateValues
        ? (e): void => {
            if (e) { e.preventDefault(); e.stopPropagation() }

            const newSegment: EntityObject = { id: getLongRandomId() }

            updateValues({
              newValues: {
                ...values,
                segments: [
                  ...segments,
                  newSegment
                ]
              }
            })
          }
        : undefined,
      removeSegment = updateValues
        ? (indexToRemove: number): React.MouseEventHandler => (e) => {
            if (e) { e.preventDefault(); e.stopPropagation() }

            updateValues({
              newValues: {
                ...values,
                segments: segments.reduce<EntityObject[]>((memo, o, i) => (i === indexToRemove ? memo : [...memo, o]), [])
              }
            })
          }
        : undefined,
      fauxScopeElement: ScopeElement = {
        id: 'mad-lib-segment-scope',
        elementVariety: 'SCOPE',
        fieldName: `segments[${selectedIndex}]`,
        offsetContent: true,
        hideDeleteButton: true,
        formBody: []
      }

    React.useEffect(() => {
      if (prevSegmentIds.current.length < segmentIds.length) {
        setSelectedId(difference(segmentIds, prevSegmentIds.current)[0])
      } else if (selectedId && !segmentIds.includes(selectedId)) {
        setSelectedId(undefined)
      }

      prevSegmentIds.current = segmentIds
    }, [segmentIds, selectedId])

    return <>
      <FormLabel
        label="Fill-In Segments"
        showLabel
        asText
      />
      <PreviewWrapper $hasSelected={!!selectedSegment}>
        {segments.map((el, i) => {
          const systemField = 'fieldName' in el && el.fieldName
            ? SYSTEM_FIELDS[el.fieldName as SystemFieldName]
            : undefined

          if (systemField) {
            formContext.schema[systemField.fieldName] = systemField.schemaEntry
          }

          return <SegmentWrapper
            $understate={selectedSegment && el !== selectedSegment}
            key={(el.id as string | undefined) ?? i}
            onClick={() => { el === selectedSegment ? setSelectedId(undefined) : setSelectedId(el.id as string) }}
          >
            {el.elementVariety
              /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
              ? <FormElement bodyElement={{ ...el, hideErrorMessage: true, hideLabel: true } as InputElement} elementIndex={i} />
              : <HiddenFieldMessage>(configure this segment below)</HiddenFieldMessage>}
            {el.elementVariety
              ? <HiddenFieldMessage data-cy="hidden-field-message">(hidden)</HiddenFieldMessage>
              : null}
            {removeSegment
              ? <StyledIcon content={BasicCross} degreesRotation={45} color="caution" frameStyle="plain" onClick={removeSegment(i)} />
              : null}
          </SegmentWrapper>
        })}
        <StyledActionPointer
          label="Add a segment"
          orientation="left"
          icon={<Icon content={BasicCross} onClick={addSegment} />}
        />
      </PreviewWrapper>
      {selectedSegment
        ? <FormScope
            name={fauxScopeElement.fieldName}
            key={selectedId ?? 'unknown'}
            scopeOptions={{
              ...fauxScopeElement,
              hasValues: !isAllEmpty(selectedSegment),
              isMadLibSegment: true
            }}
            formBody={fauxScopeElement.formBody}
            ScopeRenderer={ScopeRenderer}
            ScopeEntryRenderer={BodyElementListItem}
          />
        : null}
    </>
  }

export default MadLibEditor
