import type { FieldName } from '~/types/utilities'
import type { QField } from '~/features/questionnaire/types'

export const
  SystemFieldNames = {
    independentVariableCategory: 'independentVariableCategory',
    independentVariable: 'independentVariable',
    dependentVariableCategory: 'dependentVariableCategory',
    dependentVariable: 'dependentVariable',
    subject: 'subject',
    location: 'location',
    containerType: 'containerType',
    plantingFormat: 'plantingFormat',
    seedsPerPlant: 'seedsPerPlant',
    replicateCount: 'replicateCount',
    blindnessMethod: 'blindnessMethod',
    randomizationMethod: 'randomizationMethod',
    measurementType: 'measurementType',
    measurementTarget: 'measurementTarget',
    measurementUnit: 'measurementUnit',
    measurementStartValue: 'measurementStartValue',
    measurementStartUnit: 'measurementStartUnit',
    measurementTimingScheme: 'measurementTimingScheme',
    measurementRepeatValue: 'measurementRepeatValue',
    measurementRepeatUnit: 'measurementRepeatUnit',
    measurementEndValue: 'measurementEndValue',
    measurementEndUnit: 'measurementEndUnit',
    measurementRelationship: 'measurementRelationship',
    germinationDay: 'germinationDay',
    thinningDay: 'thinningDay',
    harvestDay: 'harvestDay',
    endDay: 'endDay',
    fertilizerRateValue: 'fertilizerRateValue', // was fertilizerRate
    fertilizerRateUnit: 'fertilizerRateUnit',
    fertilizerRateArea: 'fertilizerRateArea',
    fertilizerTimingReference: 'fertilizerTimingReference', // was fertilizerTiming.timingReference
    fertilizerTimingValue: 'fertilizerTimingValue', // was fertilizerTiming.timingValue
    fertilizerTimingUnit: 'fertilizerTimingUnit', // was fertilizerTiming.timingUnit
    fertilizerName: 'fertilizerName', // was fertilizerLabel
    irrigationScheme: 'irrigationScheme',
    irrigationRateValue: 'irrigationRateValue', // was irrigationRate
    irrigationRateUnit: 'irrigationRateUnit',
    irrigationTimingValue: 'irrigationTimingValue', // was irrigationTiming.timingValue
    irrigationTimingUnit: 'irrigationTimingUnit', // was irrigationTiming.timingUnit
    irrigationTimingCondition: 'irrigationTimingCondition', // was irrigationTiming.timingCondition
    irrigationMethodName: 'irrigationMethodName', // was irrigationMethod
    plantSpacingValue: 'plantSpacingValue', // 'Plant spacing / seeding rate' // was plantSpacing
    plantSpacingUnit: 'plantSpacingUnit',
    plantSpacingArea: 'plantSpacingArea',
    seedVariety: 'seedVariety',
    customVariableTreatmentLabel: 'customVariableTreatmentLabel', // was treatmentLabel
    fertilizerRateControlGroup: 'fertilizerRateControlGroup', // was fertilizerRateControl
    fertilizerTimingControlGroup: 'fertilizerTimingControlGroup', // was fertilizerRateControl
    fertilizerTypeControlGroup: 'fertilizerTypeControlGroup', // was fertilizerRateControl
    irrigationRateControlGroup: 'irrigationRateControlGroup', // was irrigationRateControl
    irrigationTimingControlGroup: 'irrigationTimingControlGroup', // was irrigationTimingControl
    irrigationTypeControlGroup: 'irrigationTypeControlGroup', // was irrigationTypeControl
    plantingDensityControlGroup: 'plantingDensityControlGroup', // was plantingDensityControl
    seedVarietyControlGroup: 'seedVarietyControlGroup', // was seedVarietyControl
    customVariableControlGroup: 'customVariableControlGroup', // was customVariableControl
    fertilizerRateTreatmentGroups: 'fertilizerRateTreatmentGroups', // was fertilizerRateTreatments
    fertilizerTimingTreatmentGroups: 'fertilizerTimingTreatmentGroups', // was fertilizerTimingTreatments
    fertilizerTypeTreatmentGroups: 'fertilizerTypeTreatmentGroups', // was fertilizerTypeTreatments
    irrigationRateTreatmentGroups: 'irrigationRateTreatmentGroups', // was irrigationRateTreatments
    irrigationTimingTreatmentGroups: 'irrigationTimingTreatmentGroups', // was irrigationTimingTreatments
    irrigationTypeTreatmentGroups: 'irrigationTypeTreatmentGroups', // was irrigationTypeTreatments
    plantingDensityTreatmentGroups: 'plantingDensityTreatmentGroups', // was plantingDensityTreatments
    seedVarietyTreatmentGroups: 'seedVarietyTreatmentGroups', // was seedVarietyTreatments
    customVariableTreatmentGroups: 'customVariableTreatmentGroups' // was customVariableTreatments
  } as const,
  SYSTEM_FIELD_KEYS = Object.values(SystemFieldNames),
  INDEPENDENT_VARIABLES = {
    SEED_VARIETY: 'seed variety',
    PLANTING_DENSITY: 'planting density',
    FERTILIZER_TYPE: 'fertilizer type',
    FERTILIZER_RATE: 'fertilizer rate',
    FERTILIZER_TIMING: 'fertilizer timing',
    IRRIGATION_TYPE: 'irrigation type',
    IRRIGATION_RATE: 'irrigation rate',
    IRRIGATION_TIMING: 'irrigation timing'
  } as const,
  TREATMENT_KEYS = [
    SystemFieldNames.fertilizerRateTreatmentGroups,
    SystemFieldNames.fertilizerTimingTreatmentGroups,
    SystemFieldNames.fertilizerTypeTreatmentGroups,
    SystemFieldNames.irrigationRateTreatmentGroups,
    SystemFieldNames.irrigationTimingTreatmentGroups,
    SystemFieldNames.irrigationTypeTreatmentGroups,
    SystemFieldNames.plantingDensityTreatmentGroups,
    SystemFieldNames.seedVarietyTreatmentGroups,
    SystemFieldNames.customVariableTreatmentGroups
  ] as const,
  CONTROL_TREATMENT_KEYS = [
    SystemFieldNames.fertilizerRateControlGroup,
    SystemFieldNames.fertilizerTimingControlGroup,
    SystemFieldNames.fertilizerTypeControlGroup,
    SystemFieldNames.irrigationRateControlGroup,
    SystemFieldNames.irrigationTimingControlGroup,
    SystemFieldNames.irrigationTypeControlGroup,
    SystemFieldNames.plantingDensityControlGroup,
    SystemFieldNames.seedVarietyControlGroup,
    SystemFieldNames.customVariableControlGroup
  ] as const,
  SystemFieldGroups = {
    QUESTION: 'questionVariables',
    WHAT_WHERE: 'whatAndWhereVariables',
    EXP_DESIGN: 'experimentDesignVariables',
    SCHEDULE: 'scheduleVariables',
    CONTROL_VARIABLES: 'controlVariables',
    OTHER: 'uncategorizedVariables'
  } as const,
  getControlGroupTitle = (variableName: string): string => {
    return `Control group for ${variableName} variable`
  },
  getControlGroupDescription = (variableName: string): string => {
    return `An optional baseline treatment to compare other treatments to (available when independent variable is ${variableName})`
  },
  getTreatmentGroupsTitle = (variableName: string): string => {
    return `Treatment groups for ${variableName} variable`
  },
  getTreatmentGroupsDescription = (variableName: string): string => {
    return `The list of treatments that will be used in the experiment (available when independent variable is ${variableName})`
  }

export type SystemFieldName = (
  typeof SystemFieldNames[keyof typeof SystemFieldNames]
)

export type BuiltInIndependentVariable = typeof INDEPENDENT_VARIABLES[keyof typeof INDEPENDENT_VARIABLES]
export type TreatmentKey = typeof TREATMENT_KEYS[number]
export type ControlTreatmentKey = typeof CONTROL_TREATMENT_KEYS[number]

export interface SystemFieldDetails {
  fieldName: SystemFieldName
  title: string
  description?: string
  variableGroup: SystemFieldGroup
  isRequired: boolean
  schemaEntry: QField
  controlGroupFor?: BuiltInIndependentVariable
  treatmentGroupsFor?: BuiltInIndependentVariable
  shouldFollow?: FieldName[]
  shouldPrecede?: FieldName[]
  optionValuesReadOnly?: boolean
  includeOtherByDefault?: boolean
}

export type SystemFieldGroup = (
  typeof SystemFieldGroups[keyof typeof SystemFieldGroups]
)
