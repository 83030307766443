import * as React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Navigate, useSearchParams } from 'react-router-dom'
import { DOCUMENT_TITLES, HEADER_TEXTS, ABOUT_TEXT } from '~/utils/strings'
import { useSession } from '~/hooks'
import { BrandedPage, FlexibleMain } from '~/components/layouts'
import { HeadingOne, Icon } from '~/components/atoms'
import { Blurb, InfoPanel } from '~/components/molecules'
import TeacherLogInForm from '~/components/organisms/TeacherLogInForm'
import TEACHING_THEME from '../TeachingTheme'

const
  OrientationBlurb = styled(Blurb)`
    ul {
      padding-left: 1.5em;
    }
  `,
  heading = <HeadingOne color="alt">Welcome to AgConnect</HeadingOne>,
  blurbContent = <>
    <p>
      If you are a...
    </p>
    <ul>
      <li>student or learner, contact your teacher for a login link</li>
      <li>teacher, use this form to log in</li>
    </ul>
  </>,
  about = <InfoPanel heading="About AgConnect">
    {ABOUT_TEXT.teachingHome.map(s => <p key={s}>{s}</p>)}
  </InfoPanel>,
  GlobalHomePage: React.FC = () => {
    const
      [searchParams] = useSearchParams(),
      { learnerId, isSessionValid, isTeacher } = useSession(),
      pageError = searchParams.get('errors'),
      primaryContent = <>
        <OrientationBlurb
          withBackground
          icon={<Icon color="forward" content="?" frameStyle="circle" />}
          heading="How can I access my stuff?"
          content={blurbContent} />
        <TeacherLogInForm pageError={pageError ?? undefined} />
      </>

    return <ThemeProvider theme={TEACHING_THEME}>
      <BrandedPage
        documentTitle={DOCUMENT_TITLES.public}
        headerText={HEADER_TEXTS.public}
        whichHome="all">
        <FlexibleMain
          noHR
          heading={heading}
          primaryContent={primaryContent}
          secondaryContent={about} />
      </BrandedPage>
      {isSessionValid && learnerId
        ? <Navigate replace to="/project-home" />
        : null}
      {isSessionValid && isTeacher
        ? <Navigate replace to="/projects" />
        : null}
    </ThemeProvider>
  }

export default GlobalHomePage
