import * as React from 'react'
import { useTheme } from 'styled-components'
import {
  pxIconBaseSize,
  backgroundColorLookup,
  standaloneTextColorLookup,
  textColorLookup,
  StandardizedIconOuter,
  StandardizedIconOuterLink
} from '~/components/atoms/Icons/iconConstants'
import CircularIcon from '~/components/atoms/Icons/CircularIcon'
import IconText from '~/components/atoms/Icons/IconText'

import type { LinkProps } from 'react-router-dom'
import type { IconColor, IconFrameStyle, IconContent } from '~/components/atoms/Icons/iconConstants'
import { useClickOnKeyPress } from '~/hooks'
import { mergeRefs } from '~/hooks/utils'

interface CoreIconProps {
  content: IconContent
  color?: IconColor
  frameStyle?: IconFrameStyle
  degreesRotation?: number
  accessibleDescription?: string
  innerRef?: React.MutableRefObject<any>
  className?: string
}

interface OnClickProps {
  accessibleDescription: string
  onClick: React.MouseEventHandler
}

interface WithLinkProps {
  accessibleDescription: string
  linkProps: LinkProps
}

export type IconProps = CoreIconProps | (CoreIconProps & OnClickProps) | (CoreIconProps & WithLinkProps)

const
  Icon: React.FC<IconProps> = ({ content, color = 'back', frameStyle = 'circle', degreesRotation, className, innerRef, ...otherProps }) => {
    let innerContent = null
    const
      THEME = useTheme(),
      clickableRef = useClickOnKeyPress(),
      textColor = frameStyle === 'plain'
        ? THEME.colors[standaloneTextColorLookup[color]]
        : THEME.colors[textColorLookup[color]],
      backgroundColor = THEME.colors[backgroundColorLookup[color]],
      borderColor = THEME.colors.borderSticker,
      accessibleDescription = 'accessibleDescription' in otherProps ? otherProps.accessibleDescription : undefined,
      isClickable = ('onClick' in otherProps) || ('linkProps' in otherProps),
      sharedWrapperProps = {
        $degreesRotation: degreesRotation,
        $clickable: isClickable,
        'aria-label': accessibleDescription,
        title: accessibleDescription,
        'data-cy': 'icon',
        onClick: 'onClick' in otherProps ? otherProps.onClick : undefined,
        className
      }

    if (frameStyle === 'circle') {
      innerContent = (
        <CircularIcon
          content={content}
          backgroundColor={backgroundColor}
          contentColor={textColor}
          colorClass={color}
          borderColor={borderColor}
        />
      )
    } else if (typeof content === 'string') {
      innerContent = (
        <svg
          viewBox={`0 0 ${pxIconBaseSize + 2} ${pxIconBaseSize + 2}`}>
          <IconText
            text={content}
            textColor={textColor}
            strokeColor={frameStyle === 'outline' ? backgroundColor : undefined}
            pxFontSize={frameStyle === 'outline' ? (pxIconBaseSize / 1.24) : frameStyle === 'plain' ? (pxIconBaseSize / 1.12) : pxIconBaseSize}
            className="icon-rotation-target"
          />
        </svg>
      )
    } else {
      const ContentIcon = content

      innerContent = (
        <ContentIcon
          color={textColor}
          className="icon-rotation-target"
          colorClass={color}
          outlineColor={frameStyle === 'outline' ? backgroundColor : undefined}
        />
      )
    }

    if ('linkProps' in otherProps) {
      return <StandardizedIconOuterLink
        ref={innerRef}
        {...sharedWrapperProps}
        {...otherProps.linkProps}>
        {innerContent}
      </StandardizedIconOuterLink>
    } else {
      return <StandardizedIconOuter
        {...sharedWrapperProps}
        role={isClickable ? 'button' : undefined}
        ref={isClickable ? mergeRefs(clickableRef, innerRef) : innerRef}
        tabIndex={isClickable ? 0 : undefined}>
        {innerContent}
      </StandardizedIconOuter>
    }
  }

export default Icon
export type { IconColor, IconFrameStyle }
