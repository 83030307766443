import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { BotanicalDense, BotanicalLinear, BotanicalSparse } from '~/components/atoms'

interface BotanicalFrameProps {
  className?: string
}

const
  BotanicalFrameOuter = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 1.328;

    border: 12.66px solid ${p => p.theme.colors.containerBg};
    background: ${p => p.theme.colors.bodyBg};
  `,
  StyledBotanicalDense = styled(BotanicalDense)`
    position: absolute;
    aspect-ratio: 0.612;
    width: 16.46%;
    left: 8%;
    top: 3.46%;

    transform: rotate(43.66deg);
  `,
  StyledBotanicalSparse = styled(BotanicalSparse)`
    position: absolute;
    aspect-ratio: 0.562;
    width: 18.47%;
    right: 4.44%;
    top: 6.92%;

    transform: rotate(12.15deg);
  `,
  StyledBotanicalLinear = styled(BotanicalLinear)`
    position: absolute;
    aspect-ratio: 0.247;
    width: 8.85%;
    left: 30%;
    bottom: 7.19%;

    transform: scaleY(-1) rotate(60.94deg);
  `,
  BotanicalFrame: React.FC<BotanicalFrameProps> = ({ className }) => {
    const theme = useTheme()
    return <BotanicalFrameOuter className={className}>
      <StyledBotanicalDense color={theme.colors.containerAccentBg} />
      <StyledBotanicalSparse color={theme.colors.containerAccentBg} />
      <StyledBotanicalLinear color={theme.colors.containerAccentBg} />
    </BotanicalFrameOuter>
  }

export default BotanicalFrame
