import { pick, sortBy, values } from 'lodash'
import useAppSelector from './useAppSelector'
import { newProjectFormBody, projectLabelsFormBody, projectSchema, projectSettingsFormBody } from '~/features/experiment/formContent'
import { selectExperiments, selectResponsesByExperimentId, selectTemplateSets } from '~/features/entity/selectors'
import type { EntitySchema, FormBody } from '~/form-brain2/types'
import type { InputElement } from '~/features'
import type { ObjectId } from '~/types/utilities'

const
  VARIANTS = ['new', 'settings', 'labels'] as const,
  useProjectFormBody = (variant: typeof VARIANTS[number], experimentId?: ObjectId): {
    formBody: FormBody
    schema: EntitySchema
  } => {
    const
      templateSetsById = useAppSelector(selectTemplateSets),
      sortedTemplateSets = sortBy(values(templateSetsById), 'title').filter(o => !o.systemTemplate),
      experimentsById = useAppSelector(selectExperiments),
      sortedExperiments = sortBy(values(experimentsById), 'name'),
      responses = useAppSelector(selectResponsesByExperimentId(experimentId)),
      body = variant === 'labels'
        ? projectLabelsFormBody
        : variant === 'new'
          ? newProjectFormBody
          : projectSettingsFormBody,
      schema = pick(projectSchema, (body.filter(o => 'fieldName' in o) as InputElement[]).map(o => o.fieldName)),
      formBody = body.map(el => {
        if (el.id === 'projectTemplate') {
          return {
            ...el,
            list: sortedTemplateSets.map(o => ({
              optionValue: o.id,
              optionLabel: o.publishedAt ? o.title : o.authorId ? `${o.title} (draft)` : `${o.title} (admin-only draft)`,
              hidden: o.archivedAt
            }))
          }
        } else if (el.id === 'projectBasedOnExperiment') {
          return {
            ...el,
            list: sortedExperiments.filter(o => o.id !== experimentId).map(o => ({
              optionValue: o.id,
              optionLabel: o.name
            }))
          }
        } else if ((el as InputElement).fieldName === 'experimentStyle' && responses.length) {
          return {
            ...el,
            readOnlyWhen: [{ conditionType: 'always' }]
          }
        } else {
          return el
        }
      })

    return {
      formBody,
      schema
    }
  }

export default useProjectFormBody
