import { difference } from 'lodash'
import type { HTMLInputTypeAttribute } from 'react'
import type { BodyElement } from '~/form-brain2'
import type { ValidationCondition, VisibilityCondition } from './conditionTypes'
import type { ListOption, QField } from './fieldTypes'

export const InputElementVarieties = ['TEXT_INPUT', 'NUMBER_INPUT', 'DATE_INPUT', 'SELECT_INPUT', 'CHECKBOX_INPUT', 'SHORT_ANSWER', 'LONG_ANSWER', 'PARAGRAPHS', 'MULTI_SELECT_INPUT', 'MULTI_TEXT_INPUT', 'COLOR_PICKER'] as const

export type InputElementVariety = typeof InputElementVarieties[number]

export const InputElementVarietiesWithPlaceholder = difference(InputElementVarieties, ['DATE_INPUT', 'CHECKBOX_INPUT', 'COLOR_PICKER', 'MULTI_SELECT_INPUT'])
export const InputElementVarietiesWithUnit = difference(InputElementVarietiesWithPlaceholder, ['LONG_ANSWER', 'SHORT_ANSWER'])

export type AnyInputElement = (
  CustomInput |
  TextInput |
  DateInput |
  SelectInput |
  MultiSelectInput |
  MultiTextInput |
  CheckboxInput |
  ColorPickerInput
)

export interface InputElement extends BodyElement {
  elementVariety: InputElementVariety
  fieldName: QField['fieldName']
  label?: string
  hint?: string
  unit?: string
  placeholder?: string
  // list?:
  validWhen?: ValidationCondition[]
  requiredWhen?: VisibilityCondition[]
  recommendedWhen?: VisibilityCondition[]
  readOnlyWhen?: VisibilityCondition[]
  invalidates?: Array<QField['fieldName']>
  hideUnit?: boolean
  hideLabel?: boolean
  hideError?: boolean
  hideErrorMessage?: boolean
  hideHint?: boolean
  inlineLabel?: boolean
  showHintAlways?: boolean
}

export interface CustomInput extends InputElement {
  elementVariety: 'SHORT_ANSWER' | 'LONG_ANSWER' | 'PARAGRAPHS'
  // label: string
  numCols?: number
  numRows?: number
}

export interface TextInput extends InputElement {
  elementVariety: 'TEXT_INPUT' | 'NUMBER_INPUT'
  inputType?: HTMLInputTypeAttribute
}

export interface DateInput extends InputElement {
  elementVariety: 'DATE_INPUT'
}

export interface SelectInput extends InputElement {
  elementVariety: 'SELECT_INPUT'
  blankOption?: string
  customOption?: string
  customPlaceholder?: string
  extraOption?: string
  missingValueLabel?: string
  noSort?: boolean
  list?: string[] | ListOption[]
  hideWhenListEmpty?: boolean
  naturalWidth?: boolean
  errNarrow?: boolean
}

export interface MultiSelectInput extends InputElement {
  elementVariety: 'MULTI_SELECT_INPUT'
  selectNoneOption?: string
  selectAllOption?: string
  customOption?: string
  customPlaceholder?: string
  extraOption?: string
  noSort?: boolean
  list?: string[] | ListOption[]
  hideWhenListEmpty?: boolean
}

/** Currently system only */
export interface MultiTextInput extends Omit<SelectInput, 'elementVariety'> {
  elementVariety: 'MULTI_TEXT_INPUT'
  allowDuplicates?: boolean
}

export interface CheckboxInput extends InputElement {
  elementVariety: 'CHECKBOX_INPUT'
}

export interface ColorPickerInput extends InputElement {
  elementVariety: 'COLOR_PICKER'
  contentKey?: string
}
