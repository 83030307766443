import * as React from 'react'
import { FormContext } from '~/form-brain2'
import { isEmptyValue } from '~/utils/testers'
import { allFieldsFromExperiment } from '~/features/experiment/helpers'
import { getConditionDetails, getConditionFormBody } from '~/features/questionnaire/conditionHelpers'
import { ComparativeConditions, IndependentConditions } from '~/features'

import type { FormRendererProps, CollectionOptions } from '~/form-brain2'
import type { AnyWorksheet, ConditionType, VisibilityCondition } from '~/features'
import type { ConditionCategory } from '~/features/questionnaire/conditionHelpers'
import styled from 'styled-components'
import { BasicCross, Button, Icon } from '~/components/atoms'
import { toPairs } from 'lodash'

const
  ButtonRowOuter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 0 auto;
  `,
  ButtonRowInner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    flex: 1 1 0px;
    margin-bottom: ${p => p.theme.emBaseSpacing}em;
    margin-top: ${p => p.theme.emBaseSpacing}em;
  `,
  DeleteIcon = styled(Icon)`
    width: 27px;
    margin-left: auto;
  `,
  StyledButton = styled(Button)`
    margin: 0;
    margin-right: ${p => p.theme.emBaseSpacing}em;
    margin-bottom: ${p => p.theme.emSmallSpacing}em;
  `,
  conditionCategoryLabels: Record<ConditionCategory, string> = {
    explicit: 'Test the content of an answer',
    independent: 'Test the format of an answer',
    referenced: 'Compare two answers',
    absolute: 'Always or never'
  },
  getInitialConditionCategory = (condition: Partial<VisibilityCondition>): ConditionCategory | undefined => {
    if (condition.conditionType === 'always' || condition.conditionType === 'never') {
      return 'absolute'
    }

    if (
      (!condition.conditionType || ([...ComparativeConditions] as ConditionType[]).includes(condition.conditionType)) &&
      'testValueName' in condition &&
      !isEmptyValue(condition.testValueName)
    ) {
      return 'referenced'
    }

    if (
      (!!condition.conditionType && ([...ComparativeConditions] as ConditionType[]).includes(condition.conditionType)) ||
      (!condition.conditionType && 'testValue' in condition && !isEmptyValue(condition.testValue))
    ) {
      return 'explicit'
    }

    if (condition.conditionType && ([...IndependentConditions] as ConditionType[]).includes(condition.conditionType)) {
      return 'independent'
    }

    return undefined
  },
  blankWorksheetArray: AnyWorksheet[] = [],
  ConditionListItem: React.FC<FormRendererProps> = ({ formId, className, isNested }) => {
    const
      formContext = React.useContext(FormContext),
      FormRenderer = formContext.formOptions.RootFormRenderer,
      collectionOptions = formContext.scopeOptions as CollectionOptions | undefined,
      item = formContext.values as unknown as VisibilityCondition,
      [conditionCategory, setConditionCategory] = React.useState<ConditionCategory | undefined>(
        getInitialConditionCategory(item)
      ),
      worksheetsThisExperiment = formContext.formOptions?.worksheetsThisExperiment ?? blankWorksheetArray,
      allFieldDetails = React.useMemo(() => (
        allFieldsFromExperiment(worksheetsThisExperiment)
      ), [worksheetsThisExperiment]),
      conditionDetails = React.useMemo(() => (
        getConditionDetails({ condition: item, allFieldDetails, formContext })
      ), [item, allFieldDetails, formContext]),
      formBody = React.useMemo(() => (
        conditionCategory
          ? getConditionFormBody({ conditionDetails, allFieldDetails, collectionOptions, conditionCategory })
          : []
      ), [conditionDetails, allFieldDetails, collectionOptions, conditionCategory])

    return (
      conditionCategory
        ? <FormRenderer {...{ formId, formBody, isNested, className }} />
        : <ButtonRowOuter>
          <ButtonRowInner>
            {toPairs(conditionCategoryLabels).map(([value, label]) => (
              <StyledButton
                key={value}
                color="back"
                size="small"
                label={label}
                onClick={() => { setConditionCategory(value as ConditionCategory) }}
              />
            ))}
          </ButtonRowInner>
          {collectionOptions?.removeEntry && !collectionOptions?.isReadOnly
            ? <DeleteIcon
              accessibleDescription="Delete row"
              content={BasicCross}
              degreesRotation={45}
              color="caution"
              frameStyle="circle"
              onClick={collectionOptions.removeEntry}
            />
            : null}
        </ButtonRowOuter>
    )
  }

export default ConditionListItem
