import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import { IRRIGATION_SCHEMES, fieldDetails as irrigationScheme } from './irrigationScheme'
import { fieldDetails as irrigationRateUnit } from './irrigationRateUnit'

import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Irrigation rate (value)',
  description = `This is a numeric value indicating how much a crop will be irrigated. See also "${irrigationScheme.title}" and "${irrigationRateUnit.title}".`,
  schemaEntry: QField = {
    fieldName: SystemFieldNames.irrigationRateValue,
    fieldType: 'NUMBER',
    readOnlyWhen: [
      {
        valueName: SystemFieldNames.irrigationScheme,
        conditionType: 'absent'
      }
    ],
    visibleWhen: [
      // ...ivEqualityCondition(variableName, exclude),
      {
        valueName: SystemFieldNames.irrigationScheme,
        conditionType: 'notEquals',
        testValue: IRRIGATION_SCHEMES.SATURATION
      }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.irrigationRateValue,
    variableGroup: SystemFieldGroups.CONTROL_VARIABLES,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
