import { isEqual } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { Form } from '~/form-brain2'
import { HintText, remTabHeight } from '~/components/atoms'
import { ButtonWithConfirm, FormRenderer } from '~/components/molecules'
import { experimentFormResolvers } from '~/features'
import { pagedSectionFormBody } from '~/features/worksheet/sectionElementFormDetails'
import type { EntityObject, SubscribeToFormStatusFn } from '~/form-brain2'
import type { Experiment, ListWorksheet, PagedSectionElement, PagedWorksheet } from '~/features'

interface Props {
  worksheet: PagedWorksheet
  allWorksheets: Array<ListWorksheet | PagedWorksheet>
  section: PagedSectionElement
  experiment: Experiment
  updateWorksheet: (updatedWorksheet: PagedWorksheet) => void
}

const
  Wrapper = styled.div`
    flex: 1 1 0px;
    padding: ${p => p.theme.emSmallSpacing}em;
    border: 2px solid ${p => p.theme.colors.containerAccentBg};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${remTabHeight * 0.9}rem;
  `,
  StyledForm = styled(Form)`
    background: ${p => p.theme.colors.containerBg};
    padding: ${p => p.theme.emSmallSpacing}em;
  `,
  PagedSectionEditor: React.FC<Props> = ({ worksheet, allWorksheets, section, experiment, updateWorksheet }) => {
    const
      indexOfSection = worksheet.sections.indexOf(section),
      formBody = pagedSectionFormBody.map(o => {
        return 'fieldName' in o && o.fieldName === 'title'
          ? { ...o, placeholder: `Default: Section ${indexOfSection + 1}` }
          : o
      }),
      updateSection = (nextValues: EntityObject | undefined): void => {
        updateWorksheet({
          ...worksheet,
          sections: worksheet.sections.reduce<PagedSectionElement[]>((memo, o) => {
            if (o.id !== section.id) {
              return [...memo, o]
            } else if (nextValues) {
              return [...memo, nextValues as unknown as PagedSectionElement]
            } else {
              return memo
            }
          }, [])
        })
      },
      updateUpstream: SubscribeToFormStatusFn = ({ values: nextValues }) => {
        if (!isEqual(section, nextValues)) {
          updateSection(nextValues)
        }
      },
      removeSection: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        updateSection(undefined)
      },
      canRemove = worksheet.sections.length > 1

    return <Wrapper>
      <StyledForm
        {...experimentFormResolvers}
        formId="paged-section-settings"
        initialValues={section as unknown as EntityObject}
        FormRenderer={FormRenderer}
        formBody={formBody}
        onSubmitCallback={() => Promise.resolve({})}
        subscribeToFormStatus={updateUpstream}
        formOptions={{ RootFormRenderer: FormRenderer, worksheetsThisExperiment: allWorksheets }}
      />
      {canRemove
        ? <ButtonWithConfirm
          onConfirm={removeSection}
          confirmationBody='This section will not be removed permanently until you save all changes to the step format. If you want this section back, you can leave the page without saving - but you will lose any other changes you have made since you last saved.'
          buttonProps={{ size: 'medium', label: 'Remove Section', color: 'caution' }}
          cancelLabel='Keep Section'
          confirmLabel='Remove Section'
          subheading='Remove section from draft'
          />
        : <HintText color="default">This is the only section. If you want to remove it, add another section first.</HintText>}
    </Wrapper>
  }

export default PagedSectionEditor
