import type { ClosingSectionElement, OpeningSectionElement, PageElement, PagedSectionElement, RepeatingSectionElement, SpecialLayoutType, SpecialSectionId } from '../questionnaire/types'
import type { Experiment } from '~/features/experiment/types'
import type { ListWorksheet, PagedWorksheet } from './types'
import { getSemiRandomString, getShortRandomId } from '~/utils/strings'
import type { TemplateSet } from '~/features/template/types'
import { defaultsForMaterialsLayout, getDefaultMaterialChecklistItemSection } from './defaultsForSpecialLayouts/defaultsForMaterialsLayout'
import { defaultsForScheduleLayout, getDefaultScheduledActivitySection, getDefaultScheduledMeasurementSection } from './defaultsForSpecialLayouts/defaultsForScheduleLayout'
import { defaultsForSetUpLayout, getDefaultSetUpStepSection } from './defaultsForSpecialLayouts/defaultsForSetUpLayout'
import { defaultsForDataCollectionLayout, getDefaultActivityLogSection, getDefaultMeasurementLogSection, getDefaultObservationLogSection } from './defaultsForSpecialLayouts/defaultsForDataCollectionLayout'
import { defaultsForAnalysisLayout, getDefaultStatsTestSection, getDefaultSummaryStatsSection } from './defaultsForSpecialLayouts/defaultsForAnalysisLayout'

export const
  defaultOpeningSection = (specialSectionId?: SpecialSectionId): OpeningSectionElement => {
    if (specialSectionId === 'SUMMARY_STATS') {
      return getDefaultSummaryStatsSection()
    }

    return {
      id: getSemiRandomString(),
      elementVariety: 'LIST_OPENER_SECTION',
      title: 'Intro',
      body: [{
        id: getSemiRandomString(),
        fieldName: getShortRandomId(['opening', 'Question']),
        elementVariety: 'LONG_ANSWER',
        label: 'Your opening question here'
      }]
    }
  },
  defaultRepeatingSection = (specialSectionId?: SpecialSectionId, placeholderQuestion?: string): RepeatingSectionElement => {
    if (specialSectionId === 'PLAN_ACTIVITY') {
      return getDefaultScheduledActivitySection()
    } else if (specialSectionId === 'PLAN_MEASUREMENT') {
      return getDefaultScheduledMeasurementSection()
    } else if (specialSectionId === 'MATERIAL') {
      return getDefaultMaterialChecklistItemSection()
    } else if (specialSectionId === 'SET_UP_STEP') {
      return getDefaultSetUpStepSection()
    } else if (specialSectionId === 'MEASUREMENT_RECORD') {
      return getDefaultMeasurementLogSection()
    } else if (specialSectionId === 'OBSERVATION_RECORD') {
      return getDefaultObservationLogSection()
    } else if (specialSectionId === 'ACTIVITY_RECORD') {
      return getDefaultActivityLogSection()
    } else if (specialSectionId === 'STATS_TEST') {
      return getDefaultStatsTestSection()
    }

    return {
      id: getSemiRandomString(),
      elementVariety: 'LIST_ENTRY_SECTION',
      entryNamePlural: 'change us',
      entryNameSingular: 'change me',
      body: [{
        id: getSemiRandomString(),
        fieldName: getShortRandomId(),
        elementVariety: 'LONG_ANSWER',
        label: placeholderQuestion ?? 'Your question here'
      }]
    }
  },
  defaultClosingSection = (specialSectionId?: SpecialSectionId): ClosingSectionElement => {
    return {
      id: getSemiRandomString(),
      elementVariety: 'LIST_CLOSER_SECTION',
      title: 'Finishing Up',
      body: [{
        id: getSemiRandomString(),
        fieldName: getShortRandomId(['closing', 'Question']),
        elementVariety: 'LONG_ANSWER',
        label: 'Your first closing question here'
      }]
    }
  },
  defaultSectionsForLayout = (specialLayoutType?: SpecialLayoutType): Partial<ListWorksheet> => {
    if (specialLayoutType === 'MATERIALS') {
      return defaultsForMaterialsLayout()
    } else if (specialLayoutType === 'SCHEDULE') {
      return defaultsForScheduleLayout()
    } else if (specialLayoutType === 'SET_UP') {
      return defaultsForSetUpLayout()
    } else if (specialLayoutType === 'DATA') {
      return defaultsForDataCollectionLayout()
    } else if (specialLayoutType === 'ANALYSIS') {
      return defaultsForAnalysisLayout()
    } else {
      return { repeatingSections: [defaultRepeatingSection(undefined, 'Your first question here')] }
    }
  },
  defaultListWorksheetValues = (experiment: Experiment | TemplateSet, specialLayoutType?: SpecialLayoutType): Partial<ListWorksheet> => {
    return {
      includeReviewStep: true,
      submitAsGroup: false,
      testmode: experiment.testmode,
      ...defaultSectionsForLayout(specialLayoutType)
    }
  },
  defaultPagedSection = (placeholderQuestion?: string): PagedSectionElement => {
    return {
      id: getSemiRandomString(),
      elementVariety: 'SECTION',
      pages: [defaultPage(placeholderQuestion)]
    }
  },
  defaultPage = (placeholderQuestion?: string): PageElement => {
    return {
      id: getSemiRandomString(),
      elementVariety: 'PAGE',
      body: [{
        id: getSemiRandomString(),
        elementVariety: 'LONG_ANSWER',
        fieldName: getShortRandomId(),
        label: placeholderQuestion ?? 'Your question here'
      }]
    }
  },
  defaultPagedWorksheetValues = (experiment: Experiment | TemplateSet): Partial<PagedWorksheet> => {
    return {
      includeReviewStep: true,
      submitAsGroup: false,
      testmode: experiment.testmode,
      sections: [defaultPagedSection('Your first question here')]
    }
  },
  defaultHubOrDownloadsWorksheetValues = { skipSubmission: true }
