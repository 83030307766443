import React from 'react'
import styled from 'styled-components'
import { WorksheetHelpers } from '~/features'
import { BasicCross, BasicPencil, BasicTriangle, Icon, Table, TBody, TD as _TD, TH, THead, TR } from '~/components/atoms'
import { selectTemplatesBySetId } from '~/features/entity/selectors'
import { useAppSelector, useUpdateEntity } from '~/hooks'
import { ActionPointer, ErrorNotice } from '../../molecules'

import type { Template, TemplateSet } from '~/features'
import NewTemplateForm from './NewTemplateForm'
import { getEntityRankUpdater } from '../ProjectManagement/ProjectSteps'

interface TemplateRowProps {
  template: Template
  templateSet: TemplateSet
  parentTemplate?: Template
  updateRank: ReturnType<typeof getEntityRankUpdater<Template>>
  canIncrement?: boolean
  canDecrement?: boolean
  isUpdating?: boolean
  isDisabled?: boolean
}

const
  TableIcon = styled(Icon)<{ $disabled?: boolean }>`
    width: 1.2em;
    display: inline-flex;
    // margin-bottom: -2px;
    ${p => p.$disabled ? 'opacity: 0.5; cursor: not-allowed;' : ''}
  `,
  StyledActionPointer = styled(ActionPointer)`
    transform: scale(1.2);
    position: relative;
    right: 5.5em;

    margin-top: 1em;
    margin-bottom: 1em;
  `,
  TD = styled(_TD)<{ $childArrows?: boolean, $childLabel?: boolean }>`
    white-space: pre-line;
    ${p => p.$childArrows ? 'border-right-style: dashed !important;' : ''}
    ${p => p.$childLabel ? 'text-align: right;' : p.$childLabel === false ? 'text-align: left;' : ''}
  `,
  TemplateRow: React.FC<TemplateRowProps> = ({ template, templateSet, parentTemplate, updateRank, canIncrement, canDecrement, isUpdating, isDisabled }) => {
    const
      label = parentTemplate
        ? `${template.name} (in ${parentTemplate.name})`
        : `${template.name}${template.format === 'HUB' ? ' (Hub)' : ''}`,
      showArrows = !template.fixedRank && (!!canIncrement || canDecrement),
      allowDecrement = canDecrement && !isDisabled && !isUpdating,
      allowIncrement = canIncrement && !isDisabled && !isUpdating

    return <TR>
      {templateSet.publishedAt
        ? null
        : <TD $childArrows={!!parentTemplate}>
          {showArrows
            ? <TableIcon
              content={BasicTriangle}
              frameStyle="plain"
              color="back"
              $disabled={!allowDecrement}
              accessibleDescription={allowDecrement ? 'Move up' : undefined}
              onClick={allowDecrement ? () => { updateRank({ worksheet: template, action: 'decrement' }) } : undefined}
            />
            : null}
          {showArrows
            ? <TableIcon
              content={BasicTriangle}
              frameStyle="plain"
              color="back"
              $disabled={!allowIncrement}
              degreesRotation={180}
              accessibleDescription={allowIncrement ? 'Move down' : undefined}
              onClick={allowIncrement ? () => { updateRank({ worksheet: template, action: 'increment' }) } : undefined}
            />
            : null}
        </TD>}
      <TD $childLabel={!!parentTemplate}>{label}</TD>
      {template.format === 'HUB' || template.format === 'DOWNLOADS'
        ? <TD>N/A</TD>
        : <TD>{template.fixedRank ? 'Same as experiment' : 'Teacher\'s choice'}</TD>}
      {template.format === 'HUB' || template.format === 'DOWNLOADS'
        ? <TD>N/A</TD>
        : <TD>
          {[
            !template.includeReviewStep ? 'No review' : null,
            template.skipSubmission ? 'No submission' : null
          ].filter(o => o).join('\n')}
         </TD>}
      {!templateSet.publishedAt
        ? <TD><TableIcon content={BasicPencil} frameStyle="plain" color="back" linkProps={{ to: `/templates/${template.id}` }} /></TD>
        : null}
    </TR>
  },
  TemplateList: React.FC<{ templateSet: TemplateSet, isDisabled?: boolean }> = ({ templateSet, isDisabled }) => {
    const
      [isAdding, setIsAdding] = React.useState(false),
      { updateEntity, sourceOfUpdate, isUpdating, errors } = useUpdateEntity(),
      templates = useAppSelector(selectTemplatesBySetId(templateSet.id)),
      sortedTemplates = WorksheetHelpers.getSortedTopLevelWorksheets(templates)

    return <>
      {errors
        ? <ErrorNotice errors={errors} fallback="Unable to update template set. Please try again and contact support if the problem persists." />
        : null}
      <Table data-cy="template-list">
        <THead><TR>
          {templateSet.publishedAt ? null : <TH>Order</TH>}
          <TH>Step Name</TH>
          <TH>Individual or Group</TH>
          <TH>Configuration</TH>
          {templateSet.publishedAt ? null : <TD />}
        </TR></THead>
        <TBody>
          {sortedTemplates.flatMap((template, i) => {
            const
              subTemplates = WorksheetHelpers.getSortedWorksheetsForHubStep(template.id, templates),
              canIncrement = !template.fixedRank && sortedTemplates[i + 1] && !sortedTemplates[i + 1].fixedRank,
              canDecrement = !template.fixedRank && sortedTemplates[i - 1] && !sortedTemplates[i - 1].fixedRank

            return [
              <TemplateRow
                key={template.id}
                template={template}
                isUpdating={sourceOfUpdate === template.id}
                isDisabled={!!isDisabled || (isUpdating && sourceOfUpdate !== template.id)}
                {...{ canIncrement, canDecrement, templateSet }}
                updateRank={getEntityRankUpdater(sortedTemplates, updateEntity)}
              />,
              ...subTemplates.map((subTemplate, j) => {
                const
                  canInc = !subTemplate.fixedRank && subTemplates[j + 1] && !subTemplates[j + 1].fixedRank,
                  canDec = !subTemplate.fixedRank && subTemplates[j - 1] && !subTemplates[j - 1].fixedRank

                return <TemplateRow
                  key={subTemplate.id}
                  template={subTemplate}
                  parentTemplate={template}
                  isUpdating={sourceOfUpdate === subTemplate.id}
                  isDisabled={!!isDisabled || (isUpdating && sourceOfUpdate !== subTemplate.id)}
                  canIncrement={canInc}
                  canDecrement={canDec}
                  {...{ templateSet }}
                  updateRank={getEntityRankUpdater(subTemplates, updateEntity)}
                />
              })
            ]
          })}
        </TBody>
      </Table>
      {isAdding
        ? <NewTemplateForm templateSet={templateSet} onCancel={() => { setIsAdding(false) }} />
        : templateSet.publishedAt
          ? null
          : <StyledActionPointer
            label="Add another step"
            orientation="right"
            icon={<Icon content={BasicCross} color="back" frameStyle="circle" onClick={() => { setIsAdding(true) }} />}
          />}
    </>
  }

export default TemplateList
