import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  IRRIGATION_SCHEMES = {
    SATURATION: 'until soil is saturated',
    VOLUME: 'with a specific amount of water',
    DURATION: 'for a specific amount of time'
  } as const,
  title = 'Irrigation scheme',
  description = 'How will the amount of irrigation/watering be quantified: amount of water, amount of time, or watering until soil is saturated? When this is on its own, it will apply to all subjects. When part of a treatment, it will apply to subjects receiving that treatment.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.irrigationScheme,
    fieldType: 'STRING',
    // visibleWhen: ivEqualityCondition(variableName, exclude),
    invalidates: [
      SystemFieldNames.irrigationRateValue,
      SystemFieldNames.irrigationRateUnit
    ],
    options: [
      { optionValue: IRRIGATION_SCHEMES.SATURATION },
      { optionValue: IRRIGATION_SCHEMES.VOLUME },
      { optionValue: IRRIGATION_SCHEMES.DURATION }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.irrigationScheme,
    variableGroup: SystemFieldGroups.CONTROL_VARIABLES,
    optionValuesReadOnly: true,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails,
  IRRIGATION_SCHEMES
}
