import * as React from 'react'
import type { BasicIconProps } from '~/types/utilities'

const
  BasicDoubleArrow: React.FC<BasicIconProps> = ({ className, color = 'black', outlineColor }) => {
    return (
      <svg
        className={className}
        viewBox={outlineColor ? '-19.5 -18 103 103' : '-15 -18 94 94' }
        xmlns="http://www.w3.org/2000/svg"
        data-cy="basic-double-arrow">
       {outlineColor
         ? <>
            <polygon
              fill={outlineColor}
              stroke={outlineColor}
              strokeWidth="9px"
              strokeLinejoin="round"
              points="0,0 46.65,29 0,58"
            />
            <polygon
              fill={outlineColor}
              stroke={outlineColor}
              strokeWidth="9px"
              strokeLinejoin="round"
              points="31.72,0 78.37,29 31.72,58" />
          </>
         : null}
        <polygon fill={color} points="0,0 46.65,29 0,58" />
        <polygon fill={color} points="31.72,0 78.37,29 31.72,58" />
      </svg>
    )
  }

export default BasicDoubleArrow
