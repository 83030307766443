import * as React from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { DOCUMENT_TITLES, HEADER_TEXTS } from '~/utils/strings'
import { useLearnerStepData } from '~/hooks'
import { ExperimentHelpers, WorksheetResponseHelpers } from '~/features'
import { ErrorPage, Ul, Li, LeadIn, ContactUsMessage } from '~/components/pages/NotFoundPage'
import { BrandedPage, FlexibleMain } from '~/components/layouts'
import { QuickAccessTile } from '~/components/organisms'
import { PageHint, ErrorMessage } from '~/components/molecules'
import { HeadingOne, Link, SubheadingOne, Button } from '~/components/atoms'

import type { StepMascotState, LearnerStepContextType } from '~/hooks'
import type { TileId } from '~/features'

const
  { getDynamicString } = ExperimentHelpers,
  LearnerProjectStepPage: React.FC = () => {
    const { stepId } = useParams()

    return <_LearnerProjectStepPage key={stepId} />
  },
  _LearnerProjectStepPage: React.FC = () => {
    const
      { stepId } = useParams(),
      navigate = useNavigate(),
      {
        isLoading,
        stepIsAvailable,
        forbidden,
        learner,
        experiment,
        worksheet,
        worksheets,
        response,
        responses
      } = useLearnerStepData(stepId as string),
      [openTile, setOpenTile] = React.useState<TileId | undefined>(undefined),
      [{ mascotVariant, hintText }, setMascotState] = React.useState<StepMascotState>({}),
      allResourcesPresent = !!(experiment && worksheet && learner && worksheets && responses),
      status = response
        ? WorksheetResponseHelpers.getResponseStatus(response)
        : undefined,
      outletContext = React.useMemo<Partial<LearnerStepContextType>>(
        () => ({ learner, worksheet, worksheets, experiment, response, responses, setMascotState, isLoadingData: isLoading, isFetchingData: isLoading }),
        [learner, worksheet, worksheets, experiment, response, responses, setMascotState, isLoading]
      )

    React.useEffect(() => {
      if (stepId && status && (status !== 'inProgress' && status !== 'revising')) {
        navigate(`/project-step/${stepId}/response`, { replace: true })
      }
    }, [status, navigate, stepId])

    // Shouldn't be necessart re: key set by parent
    // React.useEffect(() => {
    //   if (mascotVariant || hintText) { setMascotState({}) }
    // }, [stepId])

    if (isLoading) {
      return <BrandedPage documentTitle={DOCUMENT_TITLES.loading} headerText={HEADER_TEXTS.teaching} whichHome="teaching" showLoader />
    }

    if (!allResourcesPresent || forbidden) {
      return <ErrorPage errorTitle="Step Not Found" message="Step Not Found">
        <LeadIn>What happened?</LeadIn>
        <Ul>
          <Li>The step you are looking for may have moved</Li>
          <Li>The step you are looking for may not be available anymore</Li>
          <Li>You may not have access to this step</Li>
        </Ul>

        <LeadIn>What next?</LeadIn>
        <Ul>
          <Li>Return to <Link to="/project-home">{getDynamicString(experiment, 'learnerProjectHome')}</Link></Li>
          <Li>No luck? Contact your teacher for help.</Li>
          <Li><ContactUsMessage question="Still stuck?" /></Li>
        </Ul>
      </ErrorPage>
    }

    const
      parentWorksheet = worksheet.parentWorksheetId
        ? worksheets.find(o => o.id === worksheet.parentWorksheetId)
        : undefined,
      topActions = worksheet.parentWorksheetId
        ? <Button label={parentWorksheet ? parentWorksheet.name : getDynamicString(experiment, 'backToHub')} color="back" size="medium" withIcon="arrowL" linkProps={{ to: `/project-step/${worksheet.parentWorksheetId}` }} />
        : <Button label={getDynamicString(experiment, 'backToHome')} color="back" size="medium" withIcon="arrowL" linkProps={{ to: '/project-home' }} />,
      heading = <HeadingOne>{worksheet.name}</HeadingOne>,
      subheading = <SubheadingOne>{
        parentWorksheet
          ? `${parentWorksheet.name}:`
          : `Step: ${WorksheetResponseHelpers.getStepNumber(worksheet, worksheets)}`
      }</SubheadingOne>,
      hint = hintText
        ? <PageHint key={window.location.pathname} content={hintText} mascotVariant={mascotVariant ?? 'pointing'} openByDefault={!!window.location.pathname.match(/\/response$/)} />
        : null,
      tiles = worksheet.tiles
        ? worksheet.tiles.map((tileId, i) => (
          <QuickAccessTile
            key={tileId}
            index={i}
            tileCount={worksheet.tiles?.length as number}
            allResponses={responses}
            { ...{ tileId, openTile, setOpenTile, experiment } } />
        ))
        : undefined

    return (
      <BrandedPage
        documentTitle={`${experiment.name} | AgConnect`}
        whichHome="teaching"
        headerText={['AgConnect', experiment.name]}>
        <FlexibleMain {...{ topActions, heading, subheading, absoluteContent: hint, infoTiles: tiles }}>
          {!stepIsAvailable
            ? <ErrorMessage>
              {'This step isn\'t available yet; try finishing the previous steps first.'}
              <br />
              <br />
              {'If you think this step should be available already, check with your teacher.'}
            </ErrorMessage>
            : !response
                ? <ErrorMessage>{`There was a problem retrieving your response for this step. Please return to the ${getDynamicString(experiment, 'learnerProjectHome')} to try again and contact support if the problem persists.`}</ErrorMessage>
                : <Outlet context={outletContext as LearnerStepContextType} />}
        </FlexibleMain>
      </BrandedPage>
    )
  }

export default LearnerProjectStepPage
