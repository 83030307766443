import { isAllEmpty, isEmptyValue } from '~/utils/testers'
import { stringifyValue, type SavePointDataSection } from './helpers'
import { difference, flatten, uniq } from 'lodash'

const metaKeys = ['label', 'value', 'unit', 'h1', 'h2', 'h3', 'h4']

export function csvFromSavePointData (section: SavePointDataSection[], title: string | undefined, stringOnly: true): Promise<null | string>
export function csvFromSavePointData (section: SavePointDataSection[], title: string | undefined): Promise<null | Blob>

export function csvFromSavePointData (section: SavePointDataSection[], _: string | undefined, stringOnly?: boolean): Promise<null | Blob | string> {
  if (isEmptyValue(section)) return Promise.resolve(null)

  let keys: string[], headerLabels: string[]
  const
    result: string[] = [],
    columnDelimiter = ',',
    rowDelimiter = '\n'

  section.forEach(({ data, headers, _section }) => {
    if (_section && !isEmptyValue(_section)) result.push('', stringifyValue(_section, 'csv'))

    if (!headers || isEmptyValue(headers)) {
      keys = uniq(flatten(data.filter(o => !Array.isArray(o)).map(Object.keys)))

      if (difference(keys, metaKeys).length) {
        headerLabels = keys.map(h => stringifyValue(h, 'csv'))
      } else {
        keys = ['label', 'value-unit']
      }
    } else {
      keys = headers.map(h => h[0])
      headerLabels = headers.map(h => stringifyValue(h[1], 'csv'))
    }

    if (!isEmptyValue(headerLabels)) result.push(headerLabels.join(columnDelimiter))

    data.forEach(o => {
      if (Array.isArray(o)) {
        result.push(o.map(v => stringifyValue(v, 'csv')).join(columnDelimiter))
      } else if (o.h1 || o.h2 || o.h3 || o.h4) {
        result.push('', stringifyValue(o.h1 ?? o.h2 ?? o.h3 ?? o.h4, 'csv'))
      } else {
        result.push(keys.map(k => {
          return k === 'value-unit'
            ? '"' + [stringifyValue(o.value, 'docx'), stringifyValue(o.unit, 'docx')].filter(o => !isEmptyValue(o)).join(' ') + '"'
            : stringifyValue(o[k], 'csv')
        }).join(columnDelimiter))
      }
    })
  })

  if (isAllEmpty(result[0])) result.shift()

  return Promise.resolve(
    stringOnly
      ? result.join(rowDelimiter)
      : new Blob([result.join(rowDelimiter)], { type: 'data:text/csv;charset=utf-8' })
  )
}
