import * as React from 'react'
import { ListStepOuter } from '~/components/organisms/ListStep/StackingSections'
import ListStepEntryList from '~/components/organisms/ListStep/ListStepEntryList'

import type { SpecialLayoutProps } from '../types'

const
  AnalysisLayout: React.FC<SpecialLayoutProps> = (props) => {
    const
      { entries, entrySummaries, currentSelection, setCurrentSelection } = props,
      listSection = <ListStepEntryList key={JSON.stringify(entries)} withInlineForm {...{ currentSelection, setCurrentSelection, entrySummaries }} />

    return (
      <ListStepOuter>
        {listSection}
      </ListStepOuter>
    )
  }

export default AnalysisLayout
