import { DateTime } from 'luxon'
import { formatDate } from '~/utils/formatters'
import { type Learner, type Experiment, type WorksheetResponse, WorksheetResponseHelpers } from '~/features'
import type { SavePointFileContent, SavePointRow, SavePointValue } from '~/components/molecules/SavePoint'
import type { DownloadableContentItem } from './getDownloadOptions'

const
  GRADES_HEADERS: Array<[string, SavePointValue]> = [
    ['stepTitle', 'Step'],
    ['authorTitle', 'Author'],
    ['responseStatus', 'Status'],
    ['responseGrade', 'Grade'],
    ['responseNotes', 'Notes']
  ],
  getGradesForDownload = (availableDownloads: DownloadableContentItem[], experiment: Experiment, learners: Learner[]): SavePointFileContent => {
    const
      filename = `Grades for ${experiment.name} - ${formatDate(DateTime.now(), 'LLLL d hh-mm')}`,
      rows = availableDownloads.filter(o => !!o.response).map((item): SavePointRow => {
        const
          stepTitle = item.label.replace(': ', ' - '),
          response = item.response as WorksheetResponse,
          authorTitle = response?.learnerId
            ? learners.find(o => o.id === response.learnerId)?.displayId ?? 'Unkown Author'
            : 'Whole Group',
          responseStatus = WorksheetResponseHelpers.getResponseStatus(response),
          resultEvent = WorksheetResponseHelpers.getLastResultEvent(response),
          responseGrade = resultEvent?.grade ?? '',
          responseNotes = resultEvent?.comment ?? ''

        return {
          stepTitle,
          authorTitle,
          responseStatus: WorksheetResponseHelpers.statusLabelByTeacherStatus[responseStatus],
          responseGrade,
          responseNotes
        }
      }),
      result: SavePointFileContent = {
        sections: [{
          data: rows,
          headers: GRADES_HEADERS
        }],
        filename,
        format: 'csv'
      }

    return result
  }

export default getGradesForDownload
