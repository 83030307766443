import styled from 'styled-components'

export const
  Table = styled.table`
    border-collapse: collapse;
    ${p => p.theme.accentFont}

    td:not(:last-child), th:not(:last-child) {
      border-right: 2px solid ${p => p.theme.colors.borderAccent};
    }
  `,
  TBody = styled.tbody`
    tr:not(:last-child) {
      td, th {
        border-bottom: 2px dashed ${p => p.theme.colors.borderAccentAlt};
      }
    }
  `,
  THead = styled.thead`
    td, th {
      border-bottom: 2px solid ${p => p.theme.colors.borderAccent};
    }
  `,
  TR = styled.tr<{ $withBackground?: boolean, $noBottomBorder?: boolean }>`
    ${p => p.$withBackground ? `background: ${p.theme.colors.containerAccentBg};` : ''}

    td, th {
      ${p => p.$noBottomBorder ? 'border-bottom: none !important;' : ''}
    }
  `,
  TH = styled.th`
    text-align: center;
    line-height: 1;
    color: ${p => p.theme.colors.bodyUnderstateText};
    font-weight: normal;
    padding: ${p => p.theme.emSmallSpacing}em ${p => p.theme.emBaseSpacing}em;

    @media(max-width: 450px) {
      padding: 4px;
    }
  `,
  TD = styled.td`
    text-align: center;
    line-height: 1;
    color: ${p => p.theme.colors.bodyAccentText};
    padding: ${p => p.theme.emSmallSpacing}em ${p => p.theme.emBaseSpacing}em;

    @media(max-width: 450px) {
      padding: 4px;
    }
  `
