import { SPECIAL_LAYOUT_NAMES } from '~/utils/strings'
import type { SpecialContentId, SpecialLayoutType, TileId } from '../questionnaire/types'
import type { MascotVariant } from '~/components/molecules'

export const
  MASCOT_VARIANTS_LIST: Array<{ optionValue: MascotVariant, optionLabel: string }> = [{
    optionValue: 'knapsack',
    optionLabel: 'Walking with knapsack'
  }, {
    optionValue: 'pointing',
    optionLabel: 'Standing and pointing'
  }, {
    optionValue: 'recording',
    optionLabel: 'Writing on notepad'
  }, {
    optionValue: 'sitting',
    optionLabel: 'Sitting and thinking'
  }],
  TILES_LIST: Array<{ optionValue: TileId, optionLabel: string }> = [{
    optionValue: 'QUESTION',
    optionLabel: 'My Question: Experiment variables as a question'
  }, {
    optionValue: 'TREATMENTS',
    optionLabel: 'Treatments: List of treatments (with codenames when present)'
  }, {
    optionValue: 'SUBJECTS',
    optionLabel: 'Subjects: List of subjects and their treatments'
  }],
  SPECIAL_CONTENT_ID_LIST: Array<{ optionValue: SpecialContentId, optionLabel: string }> = [{
    optionValue: 'RESULTS_EXPLORER',
    optionLabel: 'Results Explorer: Browse analysis results from a previous step'
  }],
  SPECIAL_LAYOUT_TYPE_LIST: Array<{ optionValue: SpecialLayoutType, optionLabel: string }> = [{
    optionValue: 'MATERIALS',
    optionLabel: SPECIAL_LAYOUT_NAMES.MATERIALS
  }, {
    optionValue: 'SCHEDULE',
    optionLabel: SPECIAL_LAYOUT_NAMES.SCHEDULE
  }, {
    optionValue: 'SET_UP',
    optionLabel: SPECIAL_LAYOUT_NAMES.SET_UP
  }, {
    optionValue: 'DATA',
    optionLabel: SPECIAL_LAYOUT_NAMES.DATA
  }, {
    optionValue: 'ANALYSIS',
    optionLabel: SPECIAL_LAYOUT_NAMES.ANALYSIS
  }],
  ICON_CONTENT_LIST = [{
    optionValue: 'check',
    optionLabel: 'Checkmark Icon'
  }, {
    optionValue: 'square',
    optionLabel: 'Square Icon'
  }, {
    optionValue: 'diamond',
    optionLabel: 'Diamond Icon'
  }, {
    optionValue: 'circle',
    optionLabel: 'Circle Icon'
  }, {
    optionValue: 'triangle',
    optionLabel: 'Triangle Icon'
  }, {
    optionValue: 'pentagon',
    optionLabel: 'Pentagon Icon'
  }, {
    optionValue: 'asterisk',
    optionLabel: 'Asterisk Icon'
  }, {
    optionValue: 'bobble',
    optionLabel: 'Bobble Icon'
  }, {
    optionValue: 'angle',
    optionLabel: 'Angle Brace Icon'
  }, {
    optionValue: 'notepad',
    optionLabel: 'Notepad Icon'
  }, {
    optionValue: 'flask',
    optionLabel: 'Chemistry Flask Icon'
  }]
