import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import { PLANTING_FORMATS } from './plantingFormat'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'

const
  title = 'Measurement target (who)',
  description = 'What part of the plant or planting area will the measurement be taken on. This choice does not change anything about how the experiment works. It is used to give instructions and label data recording forms.',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.measurementTarget,
    fieldType: 'STRING',
    options: [
      {
        optionValue: 'entire plant',
        visibleWhen: [
          {
            valueName: SystemFieldNames.dependentVariable,
            conditionType: 'matches',
            testValue: 'yield'
          },
          {
            valueName: SystemFieldNames.measurementType,
            conditionType: 'notMatches',
            testValue: 'count'
          },
          {
            valueName: SystemFieldNames.plantingFormat,
            conditionType: 'anyOf',
            testValue: [
              PLANTING_FORMATS.INDIVIDUAL_GROUND,
              PLANTING_FORMATS.INDIVIDUAL_CONTAINER
            ]
          }
        ]
      },
      {
        optionValue: 'entire stand',
        visibleWhen: [
          {
            valueName: SystemFieldNames.dependentVariable,
            conditionType: 'matches',
            testValue: 'yield'
          },
          {
            valueName: SystemFieldNames.measurementType,
            conditionType: 'notMatches',
            testValue: 'count'
          },
          {
            valueName: SystemFieldNames.plantingFormat,
            conditionType: 'anyOf',
            testValue: [
              PLANTING_FORMATS.GROUP_GROUND,
              PLANTING_FORMATS.GROUP_CONTAINER
            ]
          }
        ]
      },
      {
        optionValue: 'harvested crop',
        visibleWhen: [{
          valueName: SystemFieldNames.dependentVariable,
          conditionType: 'matches',
          testValue: 'yield'
        }]
      }
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.measurementTarget,
    variableGroup: SystemFieldGroups.EXP_DESIGN,
    isRequired: false,
    includeOtherByDefault: true,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
