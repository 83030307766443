import { random, times, upperFirst } from 'lodash'
import { generate as generateWords } from 'random-words'
import type { SpecialLayoutType } from '~/features'

export const
  RESEARCH_HELP_PHONE_NUMBER = '(802) 524-6501',
  RESEARCH_HELP_URL = 'mailto:heather.darby@uvm.edu',
  TEACHING_HELP_PHONE_NUMBER = '(802) 656-7622',
  TEACHING_HELP_URL = 'mailto:lindsey.ruhl@uvm.edu',
  TEACHING_HELP_EMAIL = 'lindsey.ruhl@uvm.edu',
  TEACHING_HELP_CONTACT = 'Lindsey Ruhl',

  CONDITIONAL_QUESTION_ICON = '⚲',
  MISSING_INTERPOLATION_PLACEHOLDER = 'MISSING RESPONSE',

  DOCUMENT_TITLES = {
    public: 'AgConnect | A Citizen Science Platform Connecting Students, Farmers, and Researchers',
    loading: 'Loading... | AgConnect'
  },
  HEADER_TEXTS = {
    public: ['UVM Extension', 'AgConnect Teaching & Research Platform'],
    teaching: ['AgConnect', 'Teaching & Learning Tools']
  },
  ABOUT_TEXT = {
    teachingHome: [
      `Developed by the University of Vermont, AgConnect is a teacher-controlled, online platform which guides students through experimental design and the scientific method with a focus on tabletop and field based agricultural experiments. If you have questions about this project, please contact Lindsey Ruhl at ${TEACHING_HELP_EMAIL} or ${TEACHING_HELP_PHONE_NUMBER}.`,
      'AgConnect’s teaching tools were developed with generous funding from the United States Department of Agriculture, National Institute of Food and Agriculture, Award No. 2020-67038-31043.'
    ]
  },
  MISC_DEFAULTS = {
    tTestLabel: 'Student\'s T-Test (Compare any two treatments)',
    anovaLabel: 'ANOVA (Compare all treatments)',
    tTestAlpha: 0.05,
    anovaAlpha: 0.05,
    whenLabel: 'When?',
    detailsLabel: 'Notes:',
    measurementLabel: 'Measurement:',
    subjectLabel: 'Who or Where:'
  } as const,
  ELEMENT_TYPES = {
    HEADING: 'Heading',
    SUBHEADING: 'Subheading',
    TEXT: 'General text',
    INSTRUCTIONS: 'Instructions',
    BLURB: 'Informational blurb',
    TABLE: 'Table',
    VALUE: 'Labelled value',
    COLLAPSIBLE: 'Collapsible section',
    FIELDSET: 'Grouped section',
    SPECIAL: 'Special section',
    TEXT_INPUT: 'Question: Text',
    NUMBER_INPUT: 'Question: Number',
    DATE_INPUT: 'Question: Date',
    SELECT_INPUT: 'Multiple Choice (Choose one)',
    CHECKBOX_INPUT: 'Checkable box',
    SHORT_ANSWER: 'Question: Short answer',
    LONG_ANSWER: 'Question: Long answer',
    MULTI_SELECT_INPUT: 'Multiple Choice (Choose more than one)',
    MULTI_TEXT_INPUT: 'Text List (List of words or short answrs)',
    FILL_IN_THE_BLANK: 'Fill in the blank(s)',
    PARAGRAPHS: 'Question: Long text with paragraphs',
    COLOR_PICKER: 'Icon color picker',
    COLLECTION: 'List (Repeating group of questions)',
    SCOPE: 'Subsection (Group of questions)'
  } as const,
  SPECIAL_LAYOUT_NAMES: Record<SpecialLayoutType, string> = {
    ANALYSIS: 'Data Analysis',
    DATA: 'Data Collection',
    MATERIALS: 'Materials Checklist',
    SCHEDULE: 'Project Schedule',
    SET_UP: 'Set-up Checklist'
  },
  getSemiRandomString = (): string => (
    times(20, () => random(35).toString(36)).join('')
  ),
  getLongRandomId = (): string => {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
      ((c as any) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> (c as any) / 4).toString(16)
    )
  },
  getShortRandomId = (words?: string[]): string => {
    const
      numbers = (random(1, 2, true) * 1000).toFixed(),
      strings = words ?? generateWords({ exactly: 2 }).map((s, i) => i === 0 ? s : upperFirst(s))

    return `${strings.join('')}${numbers}`
  }
