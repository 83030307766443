import React from 'react'
import { isEmpty, uniq } from 'lodash'
import InteractiveRowList from '~/components/molecules/InteractiveRowList'
import styled from 'styled-components'
import { Button, ErrorText, HintText } from '~/components/atoms'
import { SavePoint } from '~/components/molecules'
import DownloadsRow from './DownloadsRow'
import getContentForSelectedDownloadOptions from './helpers/getContentForSelectedDownloadOptions'
import { isEmptyValue } from '~/utils/testers'

import type { Experiment, Worksheet, WorksheetResponse } from '~/features'
import type { SavePointTriggerProps } from '~/components/molecules/SavePoint'
import type { DownloadableContentItem, SelectedOptions } from './helpers/getDownloadOptions'

interface Props {
  experiment: Experiment
  worksheets: Worksheet[]
  responses: WorksheetResponse[]
  availableDownloads: DownloadableContentItem[]
}

const
  List = styled(InteractiveRowList)`
    margin: ${p => p.theme.emBaseSpacing}em 0;
    display: flex;
    flex-direction: column;
  `,
  ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: ${p => p.theme.emSmallSpacing}em;
  `,
  StyledButton = styled(Button)`
    font-size: 1.1rem;
    align-self: end;
    margin-left: 16px;
  `,
  getDownloadButton = (label?: string, isDisabled?: boolean): React.FC<SavePointTriggerProps> => {
    const DownloadButton: React.FC<SavePointTriggerProps> = ({ doSave, isLoading, error, warning }) => {
      if (isDisabled) {
        return <ButtonRow>
          <StyledButton size="small" label={label ?? 'Download'} isDisabled />
        </ButtonRow>
      }

      return <ButtonRow>
        {error && !isEmpty(error)
          ? <ErrorText>{error}</ErrorText>
          : warning && !isEmptyValue(warning)
            ? <ErrorText warnOnly>{warning}</ErrorText>
            : null}
        <StyledButton size="small" label={label ?? 'Download'} color={error ? 'caution' : 'back'} onClick={doSave} isLoading={isLoading} />
      </ButtonRow>
    }

    return DownloadButton
  },
  DownloadsTable: React.FC<Props> = ({ availableDownloads, experiment }) => {
    const
      [selectedOptions, setSelectedOptions] = React.useState<SelectedOptions>({}),
      includedTypes = uniq(availableDownloads.flatMap(o => o.formats)).sort()

    return (
      <List
        heading="Download Your Work">
        {availableDownloads.map(o =>
          <DownloadsRow
            key={o.id}
            {...{
              setSelectedOptions,
              selectedOptions,
              includedTypes,
              downloadableContentItem: o
            }}
          />
        )}
        {isEmptyValue(selectedOptions)
          ? <ButtonRow>
            <HintText color="accent">Choose something to download</HintText>
            <StyledButton size="small" label="Download" isDisabled />
          </ButtonRow>
          : <SavePoint
            key={Object.keys(selectedOptions).sort().join(',')}
            content={getContentForSelectedDownloadOptions(availableDownloads, selectedOptions, experiment)}
            TriggerComponent={getDownloadButton()} />}
      </List>
    )
  }

export default DownloadsTable
export { getDownloadButton }
