import React from 'react'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import { csvFromSavePointData } from './csvFromSavePointData'
import type { FileBuilderFn, SavePointContent, SavePointFileFormat, SavePointTriggerProps } from './helpers'
import docFromSavePointData from './docFromSavePointData'
import pngFromSavePointData from './pngFromSavePointData'

interface SavePointProps {
  content: SavePointContent | (() => SavePointContent)
  TriggerComponent: React.FC<SavePointTriggerProps>
}

const
  builderByFormat: Record<SavePointFileFormat, FileBuilderFn> = {
    csv: csvFromSavePointData,
    docx: docFromSavePointData,
    png: pngFromSavePointData
  },
  /**
    Trigger generation and download of CSV from data.
    Content can be a single array for a single file or an object for a zipped file (file name keys, content values).
    See types for format details and stories for examples
  */
  SavePoint: React.FC<SavePointProps> = ({ content, TriggerComponent }) => {
    const
      [state, setState] = React.useState<{ isLoading: boolean, error?: string, warning?: string }>({ isLoading: false }),
      doSave = (): void => {
        let filename: string, p: Promise<Blob | null>, zipHasContent: boolean, zipHasSkipped: boolean

        setState({ isLoading: true })

        const
          generatedContent = typeof content === 'function' ? content() : content,
          isZip = generatedContent.format === 'zip'

        if (isZip) {
          const zip = new JSZip()

          filename = (generatedContent.filename ?? 'download') + '.zip'

          p = Promise.all(
            generatedContent.files.map((el, i) => {
              return builderByFormat[el.format](el.sections, el.filename, true).then(content => {
                if (content) {
                  zipHasContent = true

                  zip.file(
                    (el.filename ?? `download-${i + 1}`) + `.${el.format}`,
                    content ?? '',
                    el.format === 'png' ? { base64: true } : {}
                  )
                } else {
                  zipHasSkipped = true
                }
              })
            })
          ).then(() => zip.generateAsync({ type: 'blob' }))
        } else {
          filename = (generatedContent.filename ?? 'download') + `.${generatedContent.format}`
          p = builderByFormat[generatedContent.format](generatedContent.sections, generatedContent.filename)
        }

        void p.then(blob => {
          if (blob && (!isZip || zipHasContent)) {
            FileSaver.saveAs(blob, filename)
            setState({ isLoading: false, warning: zipHasSkipped ? 'Some empty files were skipped' : undefined })
          } else {
            setState({ isLoading: false, error: isZip ? 'Nothing to download; all files are empty' : 'Nothing to download; file is empty' })
          }
        })
      }

    return <TriggerComponent doSave={doSave} isLoading={state.isLoading} error={state.error} warning={state.warning} />
  }

export default SavePoint
