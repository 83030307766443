import * as React from 'react'
import { EmptyList, RelativeWrapper } from './StackingSections'
import ListEntryForm from './ListEntryForm'
import type { ListEntrySummary, ListStepSelection } from './types'

interface Props {
  setCurrentSelection: React.Dispatch<React.SetStateAction<ListStepSelection>>
  currentSelection?: ListStepSelection
  entrySummaries: ListEntrySummary[]
}

const
  SecondSection: React.FC<Props> = ({ currentSelection, setCurrentSelection, entrySummaries }) => {
    const entrySummary = entrySummaries.find(o => o.isSelected) as ListEntrySummary

    if (!currentSelection || !entrySummary) {
      return <RelativeWrapper>
        <EmptyList>
          <p>Click any item from the list to see or update it.</p>
        </EmptyList>
      </RelativeWrapper>
    }

    return <ListEntryForm {...{ setCurrentSelection, entrySummary, currentSelection }} />
  }

export default SecondSection
