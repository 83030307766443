import * as React from 'react'
// import styled from 'styled-components'
import { isAllEmpty } from '~/utils/testers'
// import { HeadingTwo } from '~/components/atoms'
import StackingSections, { EmptyList, RelativeWrapper } from '~/components/organisms/ListStep/StackingSections'
import ListStepEntryList from '~/components/organisms/ListStep/ListStepEntryList'
import ListInitChoices from './ListInitChoices'
import ListResetComplex from './ListResetComplex'

import type { ListEntrySummary, SpecialLayoutProps } from '../types'
import ListEntryForm from '../ListEntryForm'
import { SPECIAL_LAYOUT_NAMES } from '~/utils/strings'

const
  // ColumnHeading = styled(HeadingTwo)`
  //   margin-bottom: ${p => p.theme.emBaseSpacing}em;
  // `,
  ScheduleLayout: React.FC<SpecialLayoutProps> = ({ entries, worksheet, entrySummaries, windowWidth, canStackSections, currentSelection, setCurrentSelection }) => {
    const [startingFromScratch, setStartingFromScratch] = React.useState<boolean | undefined>(isAllEmpty(worksheet.defaults))

    if (!entries.length && !startingFromScratch) {
      return <ListInitChoices
        buildButtonLabel={`Build ${SPECIAL_LAYOUT_NAMES.SCHEDULE} from Design`}
        setStartingFromScratch={setStartingFromScratch}
        message="AgConnect can create a schedule for you based on your experiment design - or you can create your own schedule from scratch. You can always reset the schedule if you change your mind."
      />
    }

    /* This version is for the side-by-side schedule
    const
      stackSections = canStackSections && (!windowWidth || (windowWidth < 825)),
      sectionOne = <div>
        <ColumnHeading>Planned Activity</ColumnHeading>
        <ListStepEntryList withInlineForm {...{ currentSelection, setCurrentSelection, entrySummaries }} />
      </div>,
      sectionTwo = <div>
        <ColumnHeading>Schedule</ColumnHeading>
        <RelativeWrapper>
          <EmptyList>
            <p>Coming soon: AgConnect will generate a day-by-day schedule based on your planned activity!</p>
          </EmptyList>
        </RelativeWrapper>
        {entries.length
          ? <ListResetComplex resetButtonLabel="Reset this List" onSuccess={() => { setStartingFromScratch(undefined); setCurrentSelection(undefined) }} />
          : null}
      </div>

    return (
      <StackingSections
        canStackSections={stackSections}
        soleSection={<>
          {sectionOne}
          {sectionTwo}
        </>}
        firstSection={sectionOne}
        secondSection={sectionTwo}
      />
    )
    */
    const
      entrySummary = entrySummaries.find(o => o.isSelected) as ListEntrySummary,
      listSection = <>
        <ListStepEntryList withInlineForm={canStackSections} {...{ currentSelection, setCurrentSelection, entrySummaries }} />
        {entries.length && canStackSections
          ? <ListResetComplex resetButtonLabel="Reset this List" onSuccess={() => { setStartingFromScratch(undefined); setCurrentSelection(undefined) }} />
          : null}
      </>,
      secondSection = !currentSelection || !entrySummaries
        ? <RelativeWrapper>
            <EmptyList>
              <p>Click any item from the list to see or update it.</p>
            </EmptyList>
            {entries.length
              ? <ListResetComplex resetButtonLabel="Reset this List" onSuccess={() => { setStartingFromScratch(undefined); setCurrentSelection(undefined) }} />
              : null}
          </RelativeWrapper>
        : <ListEntryForm {...{ setCurrentSelection, entrySummary, currentSelection }} />

    return (
      <StackingSections
        canStackSections={canStackSections}
        soleSection={listSection}
        firstSection={listSection}
        secondSection={secondSection}
      />
    )
  }

export default ScheduleLayout
