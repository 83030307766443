import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { StyledActualButton, StyledLinkButton } from '~/components/atoms/Button/StyledButton'
import { ArrowStraight, ButtonBackground } from '~/components/atoms/Shapes'
import { CheckmarkIcon, LoadingIcon } from '~/components/atoms/Icons'
import { textColorKeys, standaloneTextColorKeys } from '~/components/atoms/Button/buttonConstants'

import type { LinkProps } from 'react-router-dom'
import type { ButtonColor, ButtonSize, ButtonIcon } from '~/components/atoms/Button/buttonConstants'

interface CoreProps {
  label: string
  color?: ButtonColor
  size?: ButtonSize
  withIcon?: ButtonIcon
  isLoading?: boolean
  isDisabled?: boolean
  className?: string
}

interface SubmitProps {
  type: 'submit'
  form: string
}

interface OnClickProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

interface WithLinkProps {
  linkProps: LinkProps
}

export type ButtonProps = (CoreProps & OnClickProps) | (CoreProps & WithLinkProps) | (CoreProps & SubmitProps)

/* TODO DEV - refactor into individual sizes - since so many variants have been added, the all-in-one version of the code has become sprawling and hard to grok */

const
  StyledBackground = styled(ButtonBackground)`
    position: absolute;
    width: 100%;
    height: 2em;
  `,
  ButtonContentWrapper = styled.div<{ $size: ButtonSize }>`
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 0 0.5em;

    line-height: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    ${p => p.$size === 'large' ? 'justify-content: center;' : ''}
  `,
  arrowSizeLookup: Record<ButtonSize, string> = {
    small: '1.474em',
    medium: '1.56em',
    large: '1.457em'
  },
  ArrowWrapper = styled.div<{ $size: ButtonSize, $direction: 'left' | 'right' }>`
    width: ${p => arrowSizeLookup[p.$size]};
    margin-${p => p.$direction === 'right' ? 'left' : 'right'}: 0.15em;

    > svg {
      transform: rotate(${p => p.$direction === 'left' ? 3 : -3}deg);
    }
  `,
  checkmarkDimensionLookup: Record<ButtonSize, string> = {
    small: 'width: 1.84em; left: -0.59em; top: -0.737em;',
    medium: 'width: 1.875em; left: -0.59em; top: -0.75em',
    large: 'width: 1.41em; left: -0.446em; -0.564em;'
  },
  CheckmarkWrapper = styled.div<{ $size: ButtonSize }>`
    position: absolute;
    ${p => checkmarkDimensionLookup[p.$size]}
  `,
  ButtonText = styled.div<{ $size: ButtonSize, $withIcon?: ButtonIcon }>`
    flex: ${p => p.$size === 'large' ? 0 : 1} 1 auto;
    line-height: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 ${p => p.$withIcon === 'checkmark' ? (p.$size === 'large' ? 0.2 : 1) : 0.5}em;
  `,
  StyledLoadingIcon = styled(LoadingIcon)<{ $size: ButtonSize }>`
    margin-top: ${p => p.$size === 'small' ? '-1px' : '-1.5px'};
    width: 60%; //75%;
  `,
  Button: React.FC<ButtonProps> = ({ className, label, size = 'medium', color = 'back', withIcon, isLoading, isDisabled, ...otherProps }) => {
    const
      THEME = useTheme(),
      isInactive = !!isLoading || isDisabled, // Logical or is the desired action here
      textColor = size === 'large'
        ? THEME.colors[standaloneTextColorKeys[color]]
        : THEME.colors[textColorKeys[color]],
      sharedWrapperProps = {
        $color: color,
        $size: size,
        $isLoading: isInactive,
        $isDisabled: isDisabled,
        'aria-disabled': isDisabled,
        'data-cy': 'button',
        className
      },
      content = <>
        {size === 'medium'
          ? <StyledBackground className="button-background-svg" />
          : null}
        <ButtonContentWrapper className="button-content-wrapper" $size={size}>
          {!isInactive && withIcon === 'checkmark' && size !== 'large'
            ? <CheckmarkWrapper $size={size}>
              <CheckmarkIcon color={color} />
            </CheckmarkWrapper>
            : null}
          {!isInactive && withIcon === 'arrowL'
            ? <ArrowWrapper $size={size} $direction="left" >
              <ArrowStraight direction="left" color={textColor} />
            </ArrowWrapper>
            : null}
          <ButtonText className="button-text" $size={size} $withIcon={withIcon}>
            {label}
          </ButtonText>
          {isLoading
            ? <ArrowWrapper $size={size} $direction="right">
              <StyledLoadingIcon $size={size} />
            </ArrowWrapper>
            : withIcon === 'arrowR' || (withIcon === 'checkmark' && size === 'large')
              ? <ArrowWrapper $size={size} $direction="right">
                {withIcon === 'checkmark' && size === 'large'
                  ? <CheckmarkIcon color={isDisabled ? 'understate' : color} />
                  : <ArrowStraight direction="right" color={isDisabled ? THEME.colors.understateDark : textColor} />}
              </ArrowWrapper>
              : null}
        </ButtonContentWrapper>
      </>

    if ('linkProps' in otherProps) {
      return <StyledLinkButton
        {...sharedWrapperProps}
        {...otherProps.linkProps}
      >
        {content}
      </StyledLinkButton>
    } else {
      return <StyledActualButton
        {...sharedWrapperProps}
        {...('form' in otherProps ? { type: 'submit', form: otherProps.form } : {})}
        onClick={'onClick' in otherProps ? otherProps.onClick : undefined}
      >
        {content}
      </StyledActualButton>
    }
  }

export default Button
