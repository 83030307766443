import * as React from 'react'
import styled from 'styled-components'
import { isEmptyValue } from '~/utils/testers'
import { inputIdFromBodyElement } from '~/utils/formatters'
import { COLOR_CLASSES } from '~/types/constants'
import { useClickOnKeyPress } from '~/hooks'
import { baseInputStyles, Icon, type IconContent } from '~/components/atoms'
import FormFieldWrapper from '~/components/molecules/FormElements/FormFieldWrapper'
import { availableIcons } from '~/components/molecules/FormElements/availableIcons'

import type { ListValue } from '~/form-brain2'
import type { InputProps } from '~/types/forms'
import type { ColorPickerInput, IconName } from '~/features'
import type { ColorClass } from '~/types/utilities'

const
  Wrapper = styled.div`
    ${baseInputStyles as any /* this is just to suppress a type error */}
    flex: 1 0 100%;
    flex-wrap: wrap;
    padding-top: 0;
    padding-bottom: 0;
    background: unset;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${p => p.theme.emSmallSpacing}em;
  `,
  Opt = styled.button`
    flex: 0 0 ${p => p.theme.pxIconLarge + 16}px;
    padding: 8px;
    ${p => p['aria-selected'] ? `background: ${p.theme.colors.understateDark};` : ''}
    cursor: pointer;
  `,
  StyledIcon = styled(Icon)`
    tab-index: unset;
  `,
  PickerOption: React.FC<{
    colorName: ColorClass
    isSelected?: boolean
    isReadOnly?: boolean
    isDisabled?: boolean
    content: IconContent
    onSelect: React.MouseEventHandler
  }> = ({ colorName, isSelected, isReadOnly, isDisabled, content, onSelect }) => {
    const
      elementRef = useClickOnKeyPress<HTMLButtonElement>()

    return (
      <Opt
        key={colorName}
        value={colorName}
        role="option"
        aria-selected={isSelected}
        disabled={isDisabled}
        ref={elementRef}
        onClick={onSelect}>
        <StyledIcon
          color={colorName}
          content={content}
          frameStyle='circle'
        />
      </Opt>
    )
  },
  ColorPicker: React.FC<InputProps & { className?: string, iconContentName?: string }> = (inputProps) => {
    const
      { updateAttr, currentValue, className, isReadOnly, isDisabled, iconContentName, pathToAttr } = inputProps,
      bodyElement = inputProps.bodyElement as ColorPickerInput,
      inputId = inputIdFromBodyElement(bodyElement, pathToAttr),
      selected = isEmptyValue(currentValue)
        ? undefined
        : currentValue as ListValue,
      onOptionSelect = (value: ColorClass): React.MouseEventHandler => (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        updateAttr(value)
      },
      iconContent = !iconContentName
        ? undefined
        : iconContentName in availableIcons
          ? availableIcons[iconContentName as IconName]
          : iconContentName

    return <FormFieldWrapper className={className} inputId={inputId} inputProps={inputProps} noWrapper>
      <Wrapper
        id={inputId}
        role="listbox"
        data-cy="color-picker">
        {COLOR_CLASSES.map((colorName, index) => (
          <PickerOption
            key={colorName}
            isSelected={selected === colorName}
            content={iconContent ?? (index + 1).toString()}
            {...{ isReadOnly, isDisabled }}
            colorName={colorName}
            onSelect={
              !!isDisabled || isReadOnly
                ? () => {}
                : onOptionSelect(colorName)
            }
          />
        ))}
      </Wrapper>
    </FormFieldWrapper>
  }

export default ColorPicker
