import * as React from 'react'
import styled from 'styled-components'
import { ExperimentHelpers } from '~/features'
import { Button, type ButtonProps } from '~/components/atoms'
import {
  DialogueContainer,
  ModalOverlay,
  ErrorMessage
} from '~/components/molecules'

import type { Experiment } from '~/features'

interface Props {
  isProcessing: boolean
  isDisabled: boolean
  triggerLabel: string
  onConfirmDelete: React.MouseEventHandler | (() => void)
  subheading?: string
  experiment?: Experiment
  errorMessage?: string
  overrideButtonProps?: Partial<ButtonProps>
  className?: string
}

const
  StyledDialogueContainer = styled(DialogueContainer)`
    width: ${p => p.theme.emMediumContentWidth}em;
    maxWidth: 100%;
  `,
  { getDynamicString } = ExperimentHelpers,
  DeleteConfirmationComplex: React.FC<Props> = ({ isProcessing, isDisabled, onConfirmDelete, triggerLabel, subheading, experiment, errorMessage, overrideButtonProps, className }) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    experiment = experiment ?? { dynamicLabels: {} } as Experiment

    const
      [isDeleting, setIsDeleting] = React.useState(false),
      confirmModal = <ModalOverlay wholePage>
        <StyledDialogueContainer
          subheading={subheading}
          heading="Are you sure?"
          body={
            errorMessage
              ? <ErrorMessage>{errorMessage}</ErrorMessage>
              : getDynamicString(experiment, 'preDeleteWarning')
          }
          footerContent={
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Button
                label={getDynamicString(experiment, 'cancelDeleteButton')}
                isDisabled={isProcessing}
                onClick={() => { setIsDeleting(false) }}
                color="back"
                size="medium"
              />
              <Button
                label={getDynamicString(experiment, 'confirmDeleteButton')}
                isLoading={isProcessing}
                onClick={onConfirmDelete}
                color="caution"
                size="medium"
              />
            </div>
          }
        />
      </ModalOverlay>

    return <>
      <Button
        isDisabled={isDisabled || isProcessing}
        onClick={() => { setIsDeleting(true) }}
        color="caution"
        size="large"
        label={triggerLabel}
        className={className}
        {...(overrideButtonProps ?? {})}
      />
      {isDeleting ? confirmModal : null}
    </>
  }

export default DeleteConfirmationComplex
