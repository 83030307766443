import * as React from 'react'
import type { BasicIconProps } from '~/types/utilities'

interface Props extends BasicIconProps {
  outlineWidth?: number
}

const
  BasicCheckmark: React.FC<Props> = ({ className, color = 'black', outlineColor, outlineWidth = 3 }) => {
    const
      doubleOutlineWidth = 2 * outlineWidth,
      lengthA = 24.75,
      lengthB = 43.0427,
      thickness = 7.875,
      positionA = { x: 5.28906, y: 17.7705 },
      positionB = { x: 13.5645, y: 34.4512 },
      cornerRadius = 3.9375,
      baseBoxWidth = 44,
      baseBoxX = 1.5,
      baseBoxY = -2

    return (
      <svg
        className={className}
        viewBox={`${baseBoxX - outlineWidth} ${baseBoxY - outlineWidth} ${baseBoxWidth + doubleOutlineWidth} ${baseBoxWidth + doubleOutlineWidth}`} // "-1.5 -5 50 50"
        xmlns="http://www.w3.org/2000/svg"
        data-cy="basic-checkmark">
        {outlineColor
          ? <rect
              x={positionA.x - outlineWidth}
              y={positionA.y - outlineWidth}
              width={lengthA + doubleOutlineWidth}
              height={thickness + doubleOutlineWidth}
              rx={cornerRadius + outlineWidth}
              transform="rotate(38.6 5.28906 17.7705)"
              fill={outlineColor}
            />
          : null}
        {outlineColor
          ? <rect
              x={positionB.x - outlineWidth}
              y={positionB.y - outlineWidth}
              width={lengthB + doubleOutlineWidth}
              height={thickness + doubleOutlineWidth}
              rx={cornerRadius + outlineWidth}
              transform="rotate(-51.4 13.5645 34.4512)"
              fill={outlineColor}
            />
          : null}
        <rect
          x={positionA.x}
          y={positionA.y}
          width={lengthA}
          height={thickness}
          rx={cornerRadius}
          transform="rotate(38.6 5.28906 17.7705)"
          fill={color}
        />
        <rect
          x={positionB.x}
          y={positionB.y}
          width={lengthB}
          height={thickness}
          rx={cornerRadius}
          transform="rotate(-51.4 13.5645 34.4512)"
          fill={color}
        />
      </svg>
    )
  }

export default BasicCheckmark
