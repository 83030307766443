import * as React from 'react'
import styled from 'styled-components'
import {
  DashedLine,
  HeadingTwo,
  LoadingIcon
} from '~/components/atoms'
import { ActionPointer } from '~/components/molecules'

interface Props {
  children: React.ReactNode
  heading?: string
  cornerIcon?: React.ReactNode
  pointerText?: string
  headerContent?: React.ReactNode
  className?: string
}

const
  TopRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: auto;
    align-items: center;
    margin-bottom: 1em;
  `,
  Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${p => p.theme.colors.bodyBg};
    border-radius: ${p => p.theme.pxBorderRadius}px;
    border: 1px solid ${p => p.theme.colors.borderAccentAlt};
    // height: 2.2em;
    padding: 2px;
    margin-bottom: 6px;
  `,
  FixedCell = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
    gap: 0.5em;
    user-select: none;
  `,
  GrowingCell = styled(FixedCell)`
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  `,
  IconWrapper = styled.span`
    width: 20px;
    margin-left: auto;
  `,
  RowLoadingIcon = styled(LoadingIcon)`
    width: 1em;
    margin-left: auto;
  `,
  StyledPointer = styled(ActionPointer)`
    width: unset;
    margin-left: auto;
    transform: scale(1.2);
    transform-origin: right;
  `,
  DividerLine = styled(DashedLine)`
    max-height: 2.2em;
  `,
  InteractiveRowList: React.FC<Props> = ({ heading, cornerIcon, pointerText, headerContent, children, className }) => {
    return <div className={className}>
      {heading ?? cornerIcon
        ? <TopRow>
            <HeadingTwo>{heading}</HeadingTwo>
            {cornerIcon
              ? <StyledPointer
                  label={pointerText ?? ''}
                  orientation="right"
                  icon={<IconWrapper>{cornerIcon}</IconWrapper>}
                />
              : null}
        </TopRow>
        : null}
      {headerContent}
      {children}
    </div>
  }

export { Row, FixedCell, GrowingCell, RowLoadingIcon, DividerLine }
export default InteractiveRowList
