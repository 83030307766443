import * as React from 'react'
import styled, { keyframes, useTheme, css } from 'styled-components'
import { SpeechBubble, Icon } from '~/components/atoms'
import mascotSitting from '~/assets/mascotSitting.png'
import mascotKnapsack from '~/assets/mascotKnapsack.png'
import mascotRecording from '~/assets/mascotRecording.png'
import mascotPointing from '~/assets/mascotPointing.png'
import useCountdownToTrue from '~/hooks/useCountdownToCall'

import type { ToggleFn } from '~/form-brain2'
import { useFocusOnToggle } from '~/hooks'

export type MascotVariant = 'sitting' | 'knapsack' | 'recording' | 'pointing'

export interface PageHintProps {
  content: string
  mascotVariant: MascotVariant
  openByDefault?: boolean
  className?: string
}

const
  mascotVariantLookup = {
    sitting: mascotSitting,
    knapsack: mascotKnapsack,
    recording: mascotRecording,
    pointing: mascotPointing
  },
  mascotPositionRightLookup = {
    sitting: 58,
    knapsack: 94,
    recording: 60,
    pointing: 65
  },
  iconPositionRightLookup = {
    sitting: 71,
    knapsack: 102,
    recording: 68,
    pointing: 87
  },
  iconPositionTopLookup = {
    sitting: 12,
    knapsack: 34,
    recording: 5,
    pointing: 8
  },
  StyledIcon = styled(Icon)<{ $mascotVariant: MascotVariant, $noMascot: boolean }>`
    width: 36px;
    position: absolute;
    top: ${p => p.$noMascot ? 10 : iconPositionTopLookup[p.$mascotVariant]}px;
    right: ${p => p.$noMascot ? 14 : iconPositionRightLookup[p.$mascotVariant]}px;
    z-index: ${p => p.theme.zLocalTop};
  `,
  PageHintOuter = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: ${p => p.theme.zLocalTop + 1};
  `,
  PageHintInner = styled.div`
    position: relative;
  `,
  StyledBubble = styled(SpeechBubble)<{ $mascotVariant: MascotVariant, $noMascot: boolean }>`
    position: absolute;
    top: ${p => p.$noMascot ? 10 : -18}px;
    right: ${p => p.$noMascot ? 10 : mascotPositionRightLookup[p.$mascotVariant]}px;
  `,
  wiggleAnimation = keyframes`
      0% { transform: rotate(0deg); }
     88% { transform: rotate(0deg); }
     90% { transform: rotate(3deg); }
     92% { transform: rotate(-6deg); }
     94% { transform: rotate(0deg); }
     96% { transform: rotate(3deg); }
     98% { transform: rotate(-6deg); }
    100% { transform: rotate(0deg); }
  `,
  jumpAnimation = keyframes`
      0% { transform: translate(0); }
     88% { transform: translate(0); }
     91% { transform: translate(0 ,-6px); }
     94% { transform: translate(0); }
     97% { transform: translate(0 ,-6px); }
    100% { transform: rotate(0deg); }
  `,
  MascotImg = styled.img<{ $isWiggling?: boolean, $isSitting?: boolean }>`
    max-width: unset;
    width: 124.5px; // Measured the width from toe to toe of one mascot in figma, same distance in png image, calculated scale applied to entire image width
    position: absolute;
    right: -11.5px;
    top: -15px;

    ${p => !p.$isWiggling
      ? ''
      : p.$isSitting
        ? css`
          animation-name: ${wiggleAnimation};
        `
        : css`
          animation-name: ${jumpAnimation};
        `}

    animation-duration: 6000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    cursor: pointer;
  `,
  PageHint: React.FC<PageHintProps> = ({ content, mascotVariant, openByDefault, className }) => {
    const
      THEME = useTheme(),
      [isOpen, setIsOpen] = React.useState(openByDefault ?? false),
      toggleRef = useFocusOnToggle(isOpen),
      toggleIsOpen: ToggleFn = () => { setIsOpen(currentValue => !currentValue) },
      showMascot = THEME.showMascot,
      [isWiggling, resetCountdown] = useCountdownToTrue()

    React.useEffect(() => {
      setIsOpen(openByDefault ?? false)
      resetCountdown()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mascotVariant, content, openByDefault, setIsOpen])

    return <PageHintOuter className={className} data-cy="page-hint">
      <PageHintInner>
        {isOpen
          ? <StyledBubble
              content={content}
              innerRef={toggleRef}
              accessibilityDescription="Click to close this hint"
              onClick={toggleIsOpen}
              $mascotVariant={mascotVariant}
              $noMascot={!showMascot}
            />
          : <StyledIcon
              color="forward"
              content="?"
              innerRef={toggleRef}
              frameStyle="circle"
              onClick={toggleIsOpen}
              degreesRotation={-17.6}
              $mascotVariant={mascotVariant}
              $noMascot={!showMascot}
            />}
        {showMascot
          ? <MascotImg
              src={mascotVariantLookup[mascotVariant]}
              onClick={toggleIsOpen}
              $isWiggling={isWiggling && !isOpen}
              $isSitting={mascotVariant === 'sitting'}
            />
          : null}
      </PageHintInner>
    </PageHintOuter>
  }

export default PageHint
