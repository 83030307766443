import { faker } from '@faker-js/faker'
import { camelCase, cloneDeep, difference, findIndex, findLast, last, merge } from 'lodash'
import { isEmptyValue } from '~/utils/testers'
import { parseDate } from '~/utils/formatters'
import { getSectionsById, getSortedTopLevelWorksheets } from '../worksheet/helpers'
import { getDynamicString } from '../experiment/helpers'

import type { EntityType, ObjectId } from '~/types/utilities'
import type { ListWorksheet, Worksheet } from '../worksheet/types'
import type {
  WorksheetResponse,
  ResponseEventLog,
  ListEntry,
  ListResponse,
  PlainResponse
} from './types'
import type { EntityObject } from '~/form-brain2'
import type { DynamicLabels, Experiment, ListSectionElement, Template, TemplateSet, WalkthroughStep } from '..'

export type WorksheetResponseStatus = 'inProgress' | 'approved' | 'revising' | 'submitted' | 'studentRequestsReopen'

export const
  statusLabelByTeacherStatus: Record<WorksheetResponseStatus, string> = {
    inProgress: 'In Progress',
    revising: 'Revising',
    submitted: 'Turned In',
    studentRequestsReopen: 'Requested to Make Changes',
    approved: 'Approved'
  },
  getDefaultEntity = (
    worksheet: Worksheet,
    learnerId: ObjectId,
    experiment: Experiment
  ): WorksheetResponse => {
    const
      walkthrough = (worksheet as ListWorksheet).walkthrough,
      defaultResponseFormat = worksheet.format !== 'LIST'
        ? {}
        : walkthrough?.length
          ? { entries: [{ sectionId: walkthrough[0].sectionId }] }
          : { entries: [] },
      submitAsGroup = worksheet.fixedRank
        ? experiment.experimentStyle === 'group'
        : worksheet.submitAsGroup

    return {
      id: faker.database.mongodbObjectId(),
      type: 'worksheetResponse',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      worksheetId: worksheet.id,
      experimentId: worksheet.experimentId,
      learnerId: submitAsGroup ? undefined : learnerId,
      eventLog: [],
      responses: defaultResponseFormat
    }
  },
  addSubmissionLog = (response: WorksheetResponse): WorksheetResponse => {
    return {
      ...response,
      eventLog: [
        ...response.eventLog,
        {
          eventType: 'submitted',
          eventOccurredAt: new Date().toISOString()
        }
      ]
    }
  },
  updateProgressIndices = (
    response: PlainResponse,
    { nextPageIndex, nextSectionIndex }: { nextPageIndex: number, nextSectionIndex: number }
  ): PlainResponse => {
    const
      previousFarthest = response.farthestReachedSectionIndex ?? 0,
      updatedResponse = {
        ...response,
        mostRecentPageIndex: nextPageIndex,
        mostRecentSectionIndex: nextSectionIndex,
        farthestReachedSectionIndex: nextSectionIndex > previousFarthest
          ? nextSectionIndex
          : previousFarthest
      }

    return updatedResponse
  },
  updateWalkthroughProgress = ({ currentStop, response, walkthroughSectionId, walkthrough }: {
    currentStop: 'intro' | 'body' | 'outro' | undefined
    response: ListResponse
    walkthroughSectionId: string
    walkthrough: WalkthroughStep[]
  }): ListResponse => {
    const
      currentWTSectionIndex = findIndex(walkthrough, o => o.sectionId === walkthroughSectionId),
      outro = walkthrough[currentWTSectionIndex].outro,
      newStatus: 'intro complete' | 'body complete' | 'complete' = (
        currentStop === 'intro'
          ? 'intro complete'
          : currentStop === 'body' && (outro && outro.elementVariety === 'PAGE' && !outro.showWithPrevious)
            ? 'body complete'
            : 'complete'
      ),
      updatedWalkthroughItem = {
        [camelCase(walkthroughSectionId)]: newStatus
      },
      newEntries = newStatus === 'complete' && currentWTSectionIndex < walkthrough.length - 1
        ? [{ sectionId: walkthrough[currentWTSectionIndex + 1].sectionId }]
        : []

    return merge(cloneDeep(response), {
      walkthroughProgress: updatedWalkthroughItem,
      responses: {
        entries: [...response.responses.entries, ...newEntries]
      }
    })
  },
  addEntryToListResponse = (
    response: WorksheetResponse,
    newEntry: ListEntry,
    isAdding: boolean,
    entryIndex: number
  ): WorksheetResponse => {
    const
      oldEntries = (response as ListResponse).responses.entries,
      mutableEntries = [...(oldEntries ?? [])]

    mutableEntries.splice(entryIndex, isAdding ? 0 : 1, newEntry)

    return {
      ...response,
      responses: {
        entries: mutableEntries
      }
    }
  },
  removeEntryFromListResponse = (
    response: WorksheetResponse,
    entryIndex: number
  ): WorksheetResponse => {
    const
      oldEntries = (response as ListResponse).responses.entries,
      mutableEntries = [...(oldEntries ?? [])]

    mutableEntries.splice(entryIndex, 1)

    return {
      ...response,
      responses: {
        entries: mutableEntries
      }
    }
  },
  getEntitiesAcrossResponses = (worksheetResponses: Array<WorksheetResponse | EntityObject>): EntityObject[] => {
    const entities = worksheetResponses.reduce<EntityObject[]>((memo, response) => {
      if (typeof response !== 'object') {
        return memo
      }

      if (!response.responses) {
        return memo.concat(response as EntityObject)
      }

      if (
        typeof response.responses === 'object' &&
        'entries' in response.responses &&
        Array.isArray(response.responses.entries)
      ) {
        return memo.concat(...response.responses.entries as EntityObject[])
      }

      if (isEmptyValue(response.responses)) {
        return memo
      } else {
        return memo.concat(response.responses as EntityObject)
      }
    }, [])

    return entities
  },
  getStepNumber = (
    worksheet: Worksheet,
    allWorksheets: Worksheet[]
  ): number => {
    return getSortedTopLevelWorksheets(allWorksheets).indexOf(worksheet) + 1
  },
  getOpenLogs = (response: WorksheetResponse): ResponseEventLog[] => (
    response.eventLog.filter(eventLog => !eventLog.outcomeType)
  ),
  getIsEntryOvercome = (eventLog: ResponseEventLog[], logEntry: ResponseEventLog, index: number, isAscending?: boolean): boolean => {
    const { outcomeType } = logEntry

    if (difference(eventLog, [logEntry]).find(o => o.eventType === 'submitted' && o.outcomeType === 'approved')) { return true }

    return !outcomeType && eventLog.slice(
      isAscending ? index + 1 : 0,
      isAscending ? undefined : index
    ).some(o =>
      (o.eventType === 'studentRequestsReopen' && o.outcomeType === 'approved') ||
      (o.eventType === 'submitted' && o.outcomeType)
    )
  },
  getLiveLogs = (eventLog: ResponseEventLog[]): ResponseEventLog[] => (
    eventLog.filter((o, index) => (
      !getIsEntryOvercome(eventLog, o, index, true) && // This log was made moot by a subsequent log
      !(o.eventType === 'studentRequestsReopen' && o.outcomeType === 'denied') // Student request denied is a no-op
    ))
  ),
  getLastResultEvent = (response: WorksheetResponse): ResponseEventLog | undefined => {
    const
      logs = getLiveLogs(response.eventLog),
      lastResultEvent = findLast(logs, o => !!o.grade) ?? findLast(logs, o => !!o.comment)

    return lastResultEvent
  },
  getResponseStatus = (response: WorksheetResponse): WorksheetResponseStatus => {
    const
      logs = getLiveLogs(response.eventLog),
      lastLog = last(logs)

    if (!lastLog) {
      return 'inProgress'
    } else if (lastLog.eventType === 'studentRequestsReopen') {
      if (!lastLog.outcomeType) {
        return 'studentRequestsReopen'
      } else { // Must be approved since denied was filtered out
        return 'inProgress'
      }
    } else { // Must be submitted since there are only two choices
      if (lastLog.outcomeType === 'approved') {
        return 'approved'
      } else if (lastLog.outcomeType === 'denied') {
        return 'revising'
      } else {
        return 'submitted'
      }
    }
  },
  getDominantResponseStatus = (
    responses: WorksheetResponse[],
    hasMissing?: boolean
  ): WorksheetResponseStatus | undefined => {
    const allStatuses = responses.map(r => getResponseStatus(r))

    if (!allStatuses.length) { return undefined }
    if (hasMissing) { return 'inProgress' }

    if (allStatuses.some(s => s === 'revising')) { return 'revising' }
    if (allStatuses.every(s => s === 'approved')) { return 'approved' }
    if (allStatuses.every(s => s === 'approved' || s === 'submitted')) { return 'submitted' }
    if (allStatuses.some(s => s === 'studentRequestsReopen')) { return 'studentRequestsReopen' }

    return 'inProgress'
  },
  isResponseAvialableForReview = (response: WorksheetResponse): boolean => {
    const openLogs = getOpenLogs(response)

    return openLogs.some(o => (
      o.eventType === 'submitted' &&
      !o.outcomeType &&
      !getIsEntryOvercome(response.eventLog, o, response.eventLog.indexOf(o), true)
    ))
  },
  doesResponseRequireUrgentAction = (response: WorksheetResponse, experiment: Experiment): boolean => {
    const openLogs = getOpenLogs(response)

    return openLogs.some(o => (
      o.eventType === 'studentRequestsReopen' &&
      !o.outcomeType &&
      !getIsEntryOvercome(response.eventLog, o, response.eventLog.indexOf(o), true)
    ) || (
      experiment.progressBasis === 'approval' &&
      isResponseAvialableForReview(response)
    ))
  },
  getActiveWalkthroughDetails = (worksheet: ListWorksheet, response: WorksheetResponse): WTDetails => {
    /* The walkthrough system is a hot mess - it was an 11th-hour addition */
    const walkthrough = worksheet.walkthrough

    if (!walkthrough) { return {} }

    const
      wtProgress = response.walkthroughProgress ?? {},
      wtStep = walkthrough.find(o => wtProgress[camelCase(o.sectionId)] !== 'complete'),
      wtSection = wtStep ? getSectionsById(worksheet)[wtStep.sectionId] : undefined

    if (!wtStep || !wtSection) {
      return {}
    }

    const
      currentStatus = wtProgress[camelCase(wtStep.sectionId)],
      { intro, outro } = wtStep,
      includeIntroStop = intro?.elementVariety === 'PAGE',
      includeBodyStop = (
        intro?.elementVariety === 'BLURB' ||
        (outro?.elementVariety === 'PAGE' && !outro.showWithPrevious)
      ),
      includeOutroStop = outro?.elementVariety === 'PAGE',
      wtStops = [
        ...(includeIntroStop ? ['intro'] : []),
        ...(includeBodyStop ? ['body'] : []),
        ...(includeOutroStop ? ['outro'] : [])
      ] as Array<'intro' | 'body' | 'outro'>,
      wtEntry = wtSection && response.responses.entries
        ? (response as ListResponse).responses.entries.find(o => o.sectionId === wtSection.id)
        : undefined,
      wtStop = !wtStep
        ? undefined
        : !currentStatus
            ? wtStops[0]
            : currentStatus === 'intro complete'
              ? wtStops[wtStops.findIndex(s => s === 'intro') + 1]
              : currentStatus === 'body complete'
                ? wtStops[wtStops.findIndex(s => s === 'body') + 1]
                : undefined // only remaining status is complete

    return {
      wtStep,
      wtSection,
      wtEntry,
      wtStop
    }
  },
  lookupEventKey: Record<string, keyof DynamicLabels> = {
    submitted: 'eventSubmitted',
    studentRequestsReopen: 'eventStudentRequestsReopen'
  },
  lookupOutcomeKey: Record<string, Record<string, keyof DynamicLabels>> = {
    submitted: {
      approved: 'outcomeSubmissionApproved',
      denied: 'outcomeSubmissionDenied'
    },
    studentRequestsReopen: {
      approved: 'outcomeRequestApproved',
      denied: 'outcomeRequestDenied',
      pending: 'outcomeRequestPending'
    }
  },
  getEventLogSummaries = (eventLog: ResponseEventLog[], experiment: Experiment): Array<{ logEntry?: ResponseEventLog, eventString: string, outcomeString?: string, isOvercome: boolean, hasPendingRequest: boolean, isReadyToGrade: boolean }> => {
    if (!eventLog?.length) {
      return [{
        eventString: getDynamicString(experiment, 'eventNotYetSubmitted'),
        isOvercome: false,
        hasPendingRequest: false,
        isReadyToGrade: false
      }]
    }

    return eventLog.map((logEntry, index) => {
      const
        { eventType, eventOccurredAt, outcomeType, outcomeOccurredAt } = logEntry,
        eventDate = parseDate(eventOccurredAt),
        eventDateString = eventDate ? eventDate.toRelativeCalendar() : undefined,
        outcomeDate = outcomeOccurredAt ? parseDate(outcomeOccurredAt) : undefined,
        outcomeDateString = outcomeDate ? outcomeDate.toRelativeCalendar() : undefined,
        eventString = [
          getDynamicString(experiment, lookupEventKey[eventType]),
          eventDateString ? `(${eventDateString})` : undefined
        ].filter(o => o).join(' '),
        isOvercome = getIsEntryOvercome(eventLog, logEntry, index),
        hasPendingRequest = !outcomeType && !isOvercome && eventType === 'studentRequestsReopen',
        isReadyToGrade = !outcomeType && !isOvercome && eventType === 'submitted',
        outcomeString = (!!outcomeType || hasPendingRequest) && !isOvercome
          ? [
              getDynamicString(experiment, lookupOutcomeKey[eventType][outcomeType ?? 'pending']),
              outcomeDateString ? `(${outcomeDateString})` : undefined
            ].filter(o => o).join(' ')
          : undefined

      return {
        logEntry,
        eventString,
        outcomeString,
        isOvercome,
        hasPendingRequest,
        isReadyToGrade
      }
    })
  },
  getExampleResponses = ({ questionnaires, questionnaireGroup }: { questionnaires: Array<Worksheet | Template>, questionnaireGroup: Experiment | TemplateSet }): WorksheetResponse[] => {
    return questionnaires.map((questionnaire, i) => ({
      worksheetId: questionnaire.id,
      experimentId: questionnaireGroup.id,
      responses: questionnaire.exampleResponses ?? (questionnaire.format === 'LIST' ? { entries: [] } : {}),
      eventLog: [],
      id: `example-response-${i}`,
      type: 'worksheetResponse' as EntityType,
      createdAt: '',
      updatedAt: ''
    }))
  }

interface WTDetails {
  wtStep?: WalkthroughStep
  wtSection?: ListSectionElement
  wtEntry?: ListEntry
  wtStop?: 'intro' | 'body' | 'outro'
}
