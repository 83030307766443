import * as React from 'react'
import styled, { useTheme, keyframes } from 'styled-components'
import { BotanicalSparse } from '~/components/atoms/Shapes'

export type LoadingIconColor = 'understate' | 'accent' | 'inverse'

interface LoadingIconProps {
  className?: string
  color?: LoadingIconColor
}

const
  spinAnimation = keyframes`
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  `,
  AnimatedShape = styled(BotanicalSparse)`
    animation-name: ${spinAnimation};
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  `,
  LoadingIcon: React.FC<LoadingIconProps> = ({ className, color = 'understate' }) => {
    const
      THEME = useTheme(),
      shapeColor = color === 'understate'
        ? THEME.colors.understateMed
        : color === 'inverse'
          ? THEME.colors.bodyInverseText
          : THEME.colors.bodyAccentText

    return <AnimatedShape color={shapeColor} className={className} />
  },
  SizedLoadingIcon = styled(LoadingIcon)`
    width: 12%;
    margin: auto;
  `

export { SizedLoadingIcon }
export default LoadingIcon
