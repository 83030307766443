import * as React from 'react'
import styled, { useTheme } from 'styled-components'
import { DashedLine } from '~/components/atoms/Shapes'

interface ContentFooterProps {
  items: React.ReactNode[]
  divideItems?: boolean
  className?: string
}

interface ContentFooterOuterProps {
  $numItems: number
}

const
  emHeight = 3.83,
  ContentFooterOuter = styled.div<ContentFooterOuterProps>`
    height: ${emHeight}em;
    
    display: flex;
    flex-direction: row;
    align-items: stretch;

    background: ${p => p.theme.colors.containerBg};

    > :not(.content-footer-separator) {
      flex-grow: 1 !important;
      flex-shrink: 1 !important;
      flex-basis: ${p => 100 / p.$numItems}% !important;
      min-width: unset !important;
    }
  `,
  Separator = styled(DashedLine)`
    height: ${emHeight}em;
  `,
  ContentFooter: React.FC<ContentFooterProps> = ({ items, divideItems = true, className }) => {
    const
      THEME = useTheme(),
      numItems = items.length

    return (
      <ContentFooterOuter
        $numItems={numItems}
        className={className}
        data-cy="content-footer">
        {items.flatMap((item, index) => {
          return [
            item,
            (divideItems && (index < numItems - 1))
              ? <Separator
                  key={index}
                  orientation="vertical"
                  color={THEME.colors.containerDarkBg}
                  className="content-footer-separator"
                />
              : null
          ]
        })}
      </ContentFooterOuter>
    )
  }

export default ContentFooter
