import * as React from 'react'
import { ErrorMessage } from '~/components/molecules'
import ScheduleLayout from './ScheduleLayout'
import MaterialsLayout from './MaterialsLayout'
import SetUpLayout from './SetUpLayout'
import AnalysisLayout from './AnalysisLayout'

import type { SpecialLayoutProps } from '../types'
import type { SpecialLayoutType } from '~/features'

const
  SUPPORTED_LAYOUTS: SpecialLayoutType[] = [
    'SCHEDULE',
    'MATERIALS',
    'SET_UP',
    'ANALYSIS'
  ],
  SpecialListStepLayout: React.FC<SpecialLayoutProps & { specialLayoutType: SpecialLayoutType }> = (props) => {
    switch (props.specialLayoutType) {
      case 'SCHEDULE':
        return <ScheduleLayout {...props} />
      case 'MATERIALS':
        return <MaterialsLayout {...props} />
      case 'SET_UP':
        return <SetUpLayout {...props} />
      case 'ANALYSIS':
        return <AnalysisLayout {...props} />
      default:
        return <ErrorMessage>
          There was a problem displaying this page. Please try again later and contact support if the problem persists.
        </ErrorMessage>
    }
  }

export { SUPPORTED_LAYOUTS }
export default SpecialListStepLayout
