import * as React from 'react'

interface PageProps {
  documentTitle: string
  children: React.ReactNode
  className?: string
  dataCy?: string
}

const Page: React.FC<PageProps> = ({ documentTitle, children, className, dataCy }) => {
  React.useEffect(() => {
    document.title = documentTitle
  }, [documentTitle])

  return <div className={className} data-cy={dataCy}>
    {children}
  </div>
}

export default Page
