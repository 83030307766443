import React from 'react'
import { uniq } from 'lodash'
import styled from 'styled-components'
import { isEmptyValue, isAllEmpty } from '~/utils/testers'
import { useSignUp } from '~/hooks'
import { Button, ErrorText, Link } from '~/components/atoms'
import { FormRenderer, TextInput } from '~/components/molecules'

import type { InputProps } from '~/types/forms'
import type { TextInput as TextInputType } from '~/features'

const
  LogInWrapper = styled.div`
    padding: ${p => p.theme.emBaseSpacing}em ${p => p.theme.emBaseSpacing}em;
  `,
  Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 10px;
  `,
  StyledButton = styled(Button)`
    margin-top: 2.75em;
  `,
  StyledTextInput = styled(TextInput)<{ $hasErrors?: boolean }>`
    width: unset;

    input {
      min-width: 250px;
      ${p => p.$hasErrors ? '' : `border-color: ${p.theme.colors.borderAlt};`}
    }
  `,
  SuccessMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items; center;
    text-align: center;
    margin-top: ${p => p.theme.emBaseSpacing}em;

    p {
      margin-bottom: ${p => p.theme.emBaseSpacing}em;
    }

    .request-account-text {
      margin-top: ${p => p.theme.emBaseSpacing}em;
    }
  `,
  Small = styled.div`
    margin: 0;
    font-size: 0.85rem;
  `,
  formOptions = { RootFormRenderer: FormRenderer },
  emailInputClassName = '_log-in-email-input',
  inputBodyElement: TextInputType = {
    id: 'teacherEmail',
    fieldName: 'email',
    elementVariety: 'TEXT_INPUT',
    hideErrorMessage: true
  },
  TeacherLogInForm: React.FC<{ pageError?: string }> = ({ pageError: initialPageError }) => {
    const
      [email, setEmail] = React.useState<string>(''),
      [pageError, setPageError] = React.useState<string | undefined>(initialPageError),
      { onSignUp, resetState, isProcessing, errors, token, successEmail } = useSignUp('teacher'),
      onChange: InputProps['updateAttr'] = (val) => {
        resetState(); setPageError(undefined)
        setEmail(val as string)
      },
      handleKeyPress = React.useCallback((e: KeyboardEvent) => {
        if (document.activeElement?.closest(`.${emailInputClassName}`) && email?.length && e && e.key === 'Enter') {
          onSignUp(email)
        }
      }, [onSignUp, email]),
      onClick: React.MouseEventHandler = (e) => {
        if (e) { e.preventDefault(); e.stopPropagation() }

        onSignUp(email)
      },
      hasErrors = !!(errors && !isAllEmpty(errors)) || !isAllEmpty(pageError),
      requestAccountText = <p className="request-account-text">
        If you already have an account, go to <Link to="/educate">log in</Link>.
      </p>

    React.useEffect(() => {
      document.addEventListener('keyup', handleKeyPress)

      return () => {
        document.removeEventListener('keyup', handleKeyPress)
      }
    }, [handleKeyPress])

    return <LogInWrapper>
      <Row>
        <StyledTextInput
          $hasErrors={!isEmptyValue(errors)}
          name="email"
          pathToAttr="email"
          currentValue={email}
          errors={errors}
          updateAttr={onChange}
          bodyElement={inputBodyElement}
          formOptions={formOptions}
          isRequired
          isReadOnly={isProcessing}
          isDirty={!isEmptyValue(email)}
          showLabel
          showError
          className={emailInputClassName}
          label="Account Email:"
          placeholder="Enter the email you want to use"
        />
        <StyledButton
          label="Sign Up"
          color="back"
          size="small"
          isLoading={isProcessing}
          onClick={onClick}
        />
      </Row>
      {token
        ? <p>
            The requested can only be used a test account. You can log in to the test account with <Link to={`/access/${token}`}>this link</Link>
          </p>
        : successEmail
          ? <SuccessMessage>
              <p>An email with a verification link has been sent to <strong>{successEmail}</strong></p>
              <Small>Trouble finding the message? Check your spam folder.</Small>
              <Small>Typo? Enter the correct email above and try again.</Small>
              {requestAccountText}
            </SuccessMessage>
          : hasErrors
            ? <ErrorText>{pageError ?? uniq(pageError ?? errors).join(', ')}</ErrorText>
            : requestAccountText}
    </LogInWrapper>
  }

export default TeacherLogInForm
