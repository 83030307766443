import type { BodyElement, EntityObject } from '~/form-brain2'
import type { ColorClass, FieldName, ValueName } from '~/types/utilities'
import type { VisibilityCondition } from './conditionTypes'
import type { AnyInputElement, CustomInput, DateInput, SelectInput, TextInput } from './inputElementTypes'

export const InputLikeVarieties = ['FILL_IN_THE_BLANK', 'COLLECTION', 'SCOPE', 'COLLAPSIBLE', 'FIELDSET'] as const

export const PlainElementVarieties = [...InputLikeVarieties, 'SPECIAL', 'HEADING', 'SUBHEADING', 'TEXT', 'INSTRUCTIONS', 'BLURB', 'VALUE', 'TABLE'] as const
export type PlainElementVariety = typeof PlainElementVarieties[number]

export type AnyPlainElement = (
  TextElement |
  HeadingElement |
  SubheadingElement |
  InstructionsElement |
  BlurbElement |
  ValueElement |
  TableElement |
  CollapsibleElement |
  MadLibElement |
  CollectionElement |
  ScopeElement |
  FieldsetElement |
  SpecialElement
)

export type AnyPlainOrInputElement = AnyPlainElement | AnyInputElement

export interface TextElement extends BodyElement {
  elementVariety: 'TEXT'
  text: string
  isBold?: boolean
}

export interface HeadingElement extends BodyElement {
  elementVariety: 'HEADING'
  text: string
}

export interface SubheadingElement extends BodyElement {
  elementVariety: 'SUBHEADING'
  text: string
}

export interface InstructionsElement extends BodyElement {
  elementVariety: 'INSTRUCTIONS'
  paragraphs: string[]
}

export interface BlurbElement extends BodyElement {
  elementVariety: 'BLURB'
  iconColor: ColorClass
  iconContent: IconName | string
  title?: string
  withBackground?: boolean
  location?: 'bottom' | 'top' | 'center'
  paragraphs: string[]
  noWrapper?: boolean
  naturalWidth?: boolean
}
export type IconName = 'check' | 'square' | 'diamond' | 'circle' | 'triangle' | 'pentagon' | 'asterisk' | 'bobble' | 'angle' | 'notepad' | 'flask'

export interface ValueElement extends BodyElement {
  elementVariety: 'VALUE'
  label: string
  valueName: ValueName
}

export interface CollapsibleElement extends BodyElement {
  elementVariety: 'COLLAPSIBLE'
  expandButtonLabel: string
  collapseButtonLabel: string
  body: AnyPlainOrInputElement[]
  defaultExpanded?: boolean
}

export interface TableElement extends BodyElement {
  elementVariety: 'TABLE'
  label?: string
  columnWidths: number[]
  columnHeaders: Array<string | AnyPlainOrInputElement>
  rows: Array<Array<string | AnyPlainOrInputElement>>
}

export type MadLibSegment = TextInput | DateInput | SelectInput | TextElement | CustomInput
export interface MadLibElement extends BodyElement {
  elementVariety: 'FILL_IN_THE_BLANK'
  label?: string
  hint?: string
  showHintAlways?: boolean
  hideLabel?: boolean
  hideError?: boolean
  hideErrorMessage?: boolean
  hideHint?: boolean
  segments: MadLibSegment[]
}

export interface CollectionElement extends BodyElement {
  elementVariety: 'COLLECTION'
  fieldName: string
  entryNameSingular: string
  entryNamePlural: string
  formBody: AnyPlainOrInputElement[]
  minimumLength?: number
  maximumLength?: number
  /** If no label is given, the plural entry name will be used */
  label?: string
  editInline?: boolean
  hideLabel?: boolean
  labelAsHeading?: boolean
  firstEntryLeadIn?: string
  carryOverFields?: FieldName[]
  leader?: AnyPlainOrInputElement
  understatedAdder?: boolean
  emptyListelement?: AnyPlainElement
  requiredWhen?: VisibilityCondition[]
  readOnlyWhen?: VisibilityCondition[]
  noInitialEntry?: boolean
  encloseEntries?: boolean
  offsetEntries?: boolean
  defaultEntry?: EntityObject
  defaultEntries?: EntityObject[]
  useAlternateEntryRenderer?: undefined | 'bodyElementList' | 'conditionList'
}

export interface ScopeElement extends BodyElement {
  elementVariety: 'SCOPE'
  fieldName: string
  formBody: AnyPlainOrInputElement[]
  hideLabel?: boolean
  showLabelWithAdder?: boolean
  label?: string
  asRow?: boolean
  requiredWhen?: VisibilityCondition[]
  readOnlyWhen?: VisibilityCondition[]
  noInitialEntry?: boolean
  hideDeleteButton?: boolean
  defaultEntry?: EntityObject
  offsetContent?: boolean
  useAlternateEntryRenderer?: undefined | 'bodyElementList'
}

export interface FieldsetElement extends BodyElement {
  elementVariety: 'FIELDSET'
  body: AnyPlainOrInputElement[]
  encloseContent?: boolean
  offsetContent?: boolean
}

export const SpecialElementIds = [
  'printableFormatSettings',
  'bodyConditionScoreImage',
  'stepFormatInfo',
  'mascotPreview',
  'singleBodyElement',
  'bodyElementCollection',
  'madLibSegments'
] as const

export type SpecialElementId = typeof SpecialElementIds[number]

export interface SpecialElement extends BodyElement {
  elementVariety: 'SPECIAL'
  specialElementId: SpecialElementId
  fieldName?: string // singleBodyElement, bodyElementCollection
  textKey?: string // mascotPreview
  variantKey?: string // mascotPreview
  positionBelow?: boolean // mascotPreview
  readOnlyWhen?: VisibilityCondition[]
  body?: never
}
