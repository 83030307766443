import * as React from 'react'
import styled from 'styled-components'
import { ExperimentHelpers } from '~/features'
import { useLearnerStepContext } from '~/hooks'
import { Button, type ButtonProps } from '~/components/atoms'
import {
  DialogueContainer,
  ModalOverlay,
  ErrorMessage
} from '~/components/molecules'

interface Props {
  isLoading: boolean
  isDisabled: boolean
  isUpdating: boolean
  closeOnSubmit?: boolean
  onConfirmSubmit: (e: any) => void
  errorMessage?: string
  overrideButtonProps?: Partial<ButtonProps>
}

const
  StyledDialogueContainer = styled(DialogueContainer)`
    width: ${p => p.theme.emMediumContentWidth}em;
    maxWidth: 100%;
  `,
  { getDynamicString } = ExperimentHelpers,
  SubmitConfirmationComplex: React.FC<Props> = ({ isLoading, isDisabled, isUpdating, closeOnSubmit, onConfirmSubmit, errorMessage, overrideButtonProps }) => {
    const
      { worksheet, experiment } = useLearnerStepContext(),
      [isSubmitting, setIsSubmitting] = React.useState(false),
      submissionLabel = getDynamicString(experiment, 'submitStep'),
      onSubmit: React.MouseEventHandler = (e) => {
        onConfirmSubmit(e)
        if (closeOnSubmit) { setIsSubmitting(false) }
      },
      confirmModal = <ModalOverlay wholePage>
        <StyledDialogueContainer
          subheading={`${submissionLabel} ${worksheet.name}`}
          heading="Are you sure?"
          body={
            errorMessage
              ? <ErrorMessage>{errorMessage}</ErrorMessage>
              : getDynamicString(experiment, 'preSubmitWarning')
          }
          footerContent={
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Button
                label={getDynamicString(experiment, 'cancelSubmitButton')}
                isDisabled={isUpdating}
                onClick={() => { setIsSubmitting(false) }}
                color="back"
                size="medium"
              />
              <Button
                label={getDynamicString(experiment, 'confirmSubmitButton')}
                isLoading={isUpdating}
                onClick={onSubmit}
                color="caution"
                size="medium"
              />
            </div>
          }
        />
      </ModalOverlay>

    return <>
      <Button
        isDisabled={isDisabled || (isLoading && !closeOnSubmit)}
        isLoading={isLoading && closeOnSubmit}
        onClick={() => { setIsSubmitting(true) }}
        key="forward"
        withIcon="checkmark"
        color="forward"
        size="large"
        label={submissionLabel}
        {...(overrideButtonProps ?? {})}
      />
      {isSubmitting ? confirmModal : null}
    </>
  }

export default SubmitConfirmationComplex
