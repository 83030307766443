// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;800);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Patrick+Hand);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

* {
  line-height: calc(1em + 0.8rem);
}

html, body, #root {
  height: 100%;
  font-family: "Lato", sans-serif;
}

body {
  -webkit-font-smoothing: antialiased; /* not needed anywhere but Mac in 2023 */
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

a {
  text-decoration: none;
  display: block;
}

input, button, textarea, select {
  font: inherit;
  border: none;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root {
  isolation: isolate;
}

[data-cy-root], .inner-test-wrapper {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAIA;EACE,sBAAsB;AACxB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,YAAY;EACZ,+BAA+B;AACjC;;AAEA;EACE,mCAAmC,EAAE,wCAAwC;AAC/E;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd","sourcesContent":["@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400);\n@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;800);\n@import url(https://fonts.googleapis.com/css2?family=Patrick+Hand);\n\n*, *::before, *::after {\n  box-sizing: border-box;\n}\n\n* {\n  margin: 0;\n}\n\n* {\n  line-height: calc(1em + 0.8rem);\n}\n\nhtml, body, #root {\n  height: 100%;\n  font-family: \"Lato\", sans-serif;\n}\n\nbody {\n  -webkit-font-smoothing: antialiased; /* not needed anywhere but Mac in 2023 */\n}\n\nimg, picture, video, canvas, svg {\n  display: block;\n  max-width: 100%;\n}\n\na {\n  text-decoration: none;\n  display: block;\n}\n\ninput, button, textarea, select {\n  font: inherit;\n  border: none;\n}\n\np, h1, h2, h3, h4, h5, h6 {\n  overflow-wrap: break-word;\n}\n\n#root {\n  isolation: isolate;\n}\n\n[data-cy-root], .inner-test-wrapper {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
