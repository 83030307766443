import * as React from 'react'
import styled, { css } from 'styled-components'
import { max, sumBy } from 'lodash'
import { isAllEmpty } from '~/utils/testers'
import { inputIdFromBodyElement } from '~/utils/formatters'
import { remBaseInputFontSize, baseInputStyles } from '~/components/atoms'
import FormFieldWrapper from '~/components/molecules/FormElements/FormFieldWrapper'

import type { InputProps, BackdropColorKey } from '~/types/forms'
import type { CustomInput } from '~/features'

const
  pxDashLength = 4,
  backgroundGradient1 = css`top, ${p => p.theme.colors.bodyBg} calc(2em - 1.5px), transparent 0`,
  backgroundGradient2 = css`left, ${p => p.theme.colors.borderAccentAlt} ${pxDashLength}px, ${p => p.theme.colors.bodyBg} 0`,
  backgroundGradientReadonly1 = css<{ $backdropColorKey?: BackdropColorKey }>`top, ${p => p.theme.colors[p.$backdropColorKey ?? 'containerBg']} 31px, transparent 0`,
  backgroundGradientReadonly2 = css<{ $backdropColorKey?: BackdropColorKey }>`left, ${p => p.theme.colors.borderAccentAlt} ${pxDashLength}px, ${p => p.theme.colors[p.$backdropColorKey ?? 'containerBg']} 0`,
  backgroundSize = css`${pxDashLength * 2}px 2em`,
  Input = styled.textarea<{ $hasError?: boolean | null, $backdropColorKey?: BackdropColorKey }>`
    ${baseInputStyles}

    flex: 1 1 auto;
    font-size: ${remBaseInputFontSize}rem;
    line-height: 2;
    width: ${p => p.cols ? 'auto' : '100%'};
    max-width: 100%;
    resize: ${p => p.readOnly ? 'none' : 'auto'};

    white-space: pre-line;

    padding: 0.3em 0.5em;

    background-image:
      -webkit-linear-gradient(${backgroundGradient1}),
      -webkit-linear-gradient(${backgroundGradient2});

    background-image:
      -moz-linear-gradient(${backgroundGradient1}),
      -moz-linear-gradient(${backgroundGradient2});

    background-attachment: local;

    -webkit-background-size: ${backgroundSize};
    background-size: ${backgroundSize};

    border-color: ${p => p.$hasError ? p.theme.colors.cautionStandaloneText : p.theme.colors[p.$backdropColorKey ?? 'containerBg']};
    
    &[readonly], &[disabled] {
      background-image:
        -webkit-linear-gradient(${backgroundGradientReadonly1}),
        -webkit-linear-gradient(${backgroundGradientReadonly2});

      background-image:
        -moz-linear-gradient(${backgroundGradientReadonly1}),
        -moz-linear-gradient(${backgroundGradientReadonly2});

      background-attachment: local;

      -webkit-background-size: ${backgroundSize};
      background-size: ${backgroundSize};
    }
  `,
  SHORT_ANSWER_ROWS = 1,
  LONG_ANSWER_ROWS = 3,
  Textarea: React.FC<InputProps & { className?: string }> = (inputProps) => {
    const
      { name, currentValue, updateAttr, errors, showError, formOptions, isReadOnly, isDisabled, placeholder, pathToAttr } = inputProps,
      bodyElement = inputProps.bodyElement as CustomInput,
      { elementVariety, numCols, numRows } = bodyElement,
      inputId = inputIdFromBodyElement(bodyElement, pathToAttr),
      sizingProps = {
        ...(
          numCols
            ? { cols: numCols }
            // : !!isReadOnly || isDisabled
            //     ? { cols: 45 }
            : {}
        ),
        rows: numRows ?? max([
          (typeof currentValue === 'string' ? currentValue.length : Array.isArray(currentValue) ? sumBy(currentValue, 'length') : 0) / (numCols ?? 45), /* TODO dev - consider using the page width to set a multiplier here as this is too many lines at most non-mobile widths */
          (!!isReadOnly || !!isDisabled || elementVariety === 'SHORT_ANSWER' ? SHORT_ANSWER_ROWS : LONG_ANSWER_ROWS)
        ])
      },
      inputValue = typeof currentValue === 'string'
        ? currentValue
        : typeof currentValue === 'number' || typeof currentValue === 'boolean'
          ? currentValue.toString()
          : elementVariety === 'PARAGRAPHS' && Array.isArray(currentValue)
            ? currentValue.join('\n\n')
            : '',
      onChange: React.ChangeEventHandler<HTMLTextAreaElement> = e => {
        elementVariety !== 'PARAGRAPHS'
          ? updateAttr(e.currentTarget.value)
          : e.currentTarget.value.length > inputValue.length
            ? updateAttr(e.currentTarget.value?.split(/\n+/))
            : updateAttr(e.currentTarget.value?.split(/\n{2,}/))
      },
      hasError = showError && !isAllEmpty(errors)

    return <FormFieldWrapper className={inputProps.className} inputId={inputId} inputProps={inputProps} noWrapper>
      <Input
        name={name}
        id={inputId}
        {...sizingProps}
        $hasError={hasError}
        value={inputValue}
        onChange={onChange}
        readOnly={isReadOnly}
        disabled={isDisabled}
        $backdropColorKey={formOptions.backdropColorKey}
        placeholder={isReadOnly ? '(Blank)' : placeholder ?? undefined}
        data-cy="textarea" />
    </FormFieldWrapper>
  }

export default Textarea
export { SHORT_ANSWER_ROWS, LONG_ANSWER_ROWS }
