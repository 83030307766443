import { SystemFieldNames, SystemFieldGroups } from './systemFieldConstants'
import type { QField } from '~/features/questionnaire/types'
import type { SystemFieldDetails } from './systemFieldConstants'
import { PLANTING_FORMATS } from './plantingFormat'

const
  title = 'Plant spacing / group size scope',
  description = 'The area for which the corresponding spacing value is specified (per group, per row, per container, etc.)',
  schemaEntry: QField = {
    fieldName: SystemFieldNames.plantSpacingArea, // per container, per container, between plants , per 100 square feet
    fieldType: 'STRING',
    // visibleWhen: [
    //   ...ivEqualityCondition(variableName, exclude),
    //   groupFormatCondition
    // ],
    options: [
      {
        optionValue: 'container',
        visibleWhen: [{
          valueName: SystemFieldNames.plantingFormat,
          conditionType: 'equals',
          testValue: PLANTING_FORMATS.GROUP_CONTAINER
        }]
      },
      {
        optionValue: 'group',
        visibleWhen: [{
          valueName: SystemFieldNames.plantingFormat,
          conditionType: 'anyOf',
          testValue: [
            PLANTING_FORMATS.GROUP_CONTAINER,
            PLANTING_FORMATS.GROUP_GROUND
          ]
        }]
      },
      {
        optionValue: 'plot',
        visibleWhen: [
          {
            valueName: SystemFieldNames.plantingFormat,
            conditionType: 'equals',
            testValue: PLANTING_FORMATS.GROUP_GROUND
          }
        ]
      },
      {
        optionValue: 'row',
        visibleWhen: [
          {
            valueName: SystemFieldNames.plantingFormat,
            conditionType: 'equals',
            testValue: PLANTING_FORMATS.GROUP_GROUND
          }
        ]
      },
      {
        optionValue: 'bed',
        visibleWhen: [
          {
            valueName: SystemFieldNames.plantingFormat,
            conditionType: 'equals',
            testValue: PLANTING_FORMATS.GROUP_GROUND
          }
        ]
      }
      /** From a previous version - now spacing for individually-managed plants is left entirely to custom values */
      // {
      //   optionValue: 'between plants',
      //   visibleWhen: [
      //     { valueName: SystemFieldNames.plantingFormat, conditionType: 'equals', testValue: PLANTING_FORMATS.INDIVIDUAL_GROUND }
      //   ]
      // },
    ]
  },
  fieldDetails: SystemFieldDetails = {
    fieldName: SystemFieldNames.plantSpacingArea,
    variableGroup: SystemFieldGroups.CONTROL_VARIABLES,
    isRequired: false,
    title,
    description,
    schemaEntry
  }

export {
  fieldDetails
}
