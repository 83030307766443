import React from 'react'
import styled from 'styled-components'
import { Form } from '~/form-brain2'
import { ExperimentHelpers, experimentFormResolvers, formBodyForSpecialSection } from '~/features'
import { getHeadingForEntry, getNewEntryInitialValues } from './helpers'
import { FormRenderer, ExpandedRow } from '~/components/molecules'
import DeleteConfirmationComplex from '~/components/organisms/DeleteConfirmationComplex'
import IconForEntry from './IconForEntry'

import type { Experiment, ListEntry, ListSectionElement, RepeatingSectionElement, SpecialListSectionElement } from '~/features'
import type { EntityObject, FormMethodRef, FormOnSubmitFn, FormOnSuccessFn, SubscribeToFormStatusFn } from '~/form-brain2'
import type { ListEntrySummary } from './types'

interface Props {
  experiment: Experiment
  otherEntities: EntityObject[]
  entries: ListEntry[]
  section?: ListSectionElement

  entrySummary: ListEntrySummary
  functionalIndex?: number
  headerPicker?: React.ReactNode
  isInline?: boolean
  neutralBackground?: boolean
  naturalHeight?: boolean
  permitDelete?: boolean
  isProcessing?: boolean
  onDeleteCallback?: React.MouseEventHandler
  leftButton?: React.ReactNode
  rightButton?: React.ReactNode

  formId: string
  formMethodsRef: FormMethodRef
  formSubmissionSource?: string
  subscribeToFormStatus?: SubscribeToFormStatusFn
  onSubmitCallback: FormOnSubmitFn
  onSuccessCallback: FormOnSuccessFn
}

const
  DeleteButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: auto;
  `,
  ListEntryFormContent: React.FC<Props> = (props) => {
    const
      {
        formId, formMethodsRef, isInline, entries,
        functionalIndex, entrySummary, section,
        otherEntities, experiment, headerPicker, naturalHeight,
        neutralBackground, leftButton, rightButton, formSubmissionSource,
        onSubmitCallback, onSuccessCallback, subscribeToFormStatus,
        permitDelete, isProcessing, onDeleteCallback
      } = props,
      { entry, actualIndex, nthOfKind } = entrySummary,
      formBody = (section as SpecialListSectionElement)?.specialSectionId
        ? formBodyForSpecialSection(section as SpecialListSectionElement)
        : section?.body,
      heading = headerPicker ?? getHeadingForEntry({ ...entrySummary, section, otherEntities, experiment }),
      deletionName = entry
        ? getHeadingForEntry({ section, entry, nthOfKind, otherEntities, experiment })
        : undefined

    return (
      <ExpandedRow
        {...{
          isInline,
          functionalIndex,
          neutralBackground,
          leftButton,
          rightButton,
          naturalHeight,
          heading
        }}
        headerIcon={entry
          ? <IconForEntry section={section} entry={entry} nthOfKind={nthOfKind as number} actualIndex={actualIndex as number} />
          : <IconForEntry section={section} entry={undefined} nthOfKind={undefined} actualIndex={undefined} />
        }
      >
        {section && formBody // There will always be a formBody if there is a relevantSection, this is for expedient typing
          ? <>
            <Form
                {...{ formId }}
                key={formId}
                initialValues={entry ?? getNewEntryInitialValues(section as RepeatingSectionElement, entries, otherEntities)}
                formBody={formBody}
                FormRenderer={FormRenderer}
                methodRef={formMethodsRef}
                entitySchema={ExperimentHelpers.getSchema(experiment, formBody)}
                formOptions={{
                  experiment,
                  dynamicLabels: experiment.dynamicLabels,
                  otherEntities,
                  backdropColorKey: neutralBackground ? 'containerBg' : 'containerAccentBg',
                  RootFormRenderer: FormRenderer
                }}
                onSubmitCallback={onSubmitCallback}
                onSuccessCallback={onSuccessCallback}
                subscribeToFormStatus={subscribeToFormStatus}
                {...experimentFormResolvers}
              />
            {permitDelete && onDeleteCallback
              ? <DeleteButtonWrapper>
                <DeleteConfirmationComplex
                  triggerLabel={(section as RepeatingSectionElement).deleteButtonLabel ?? 'Delete this item'}
                  subheading={deletionName ? `Delete: ${deletionName}` : undefined}
                  overrideButtonProps={{ size: 'small' }}
                  isProcessing={!!isProcessing && formSubmissionSource !== 'SAVE'}
                  isDisabled={!!isProcessing}
                  onConfirmDelete={onDeleteCallback}
                />
              </DeleteButtonWrapper>
              : null}
            </>
          : null}
      </ExpandedRow>
    )
  }

export default ListEntryFormContent
